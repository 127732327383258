import { css } from "@emotion/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Grid, Icon, Segment } from "semantic-ui-react";
import { SimulationBody } from "../../../../model/dto/body/simulation-body";
import { EcheancierSimulationResponse, FichierAnalyseVillyzResponse, ProfilCode, SimulationResponse } from "../../../../model/dto/response";
import { Profil } from "../../../../model/entities/profil";
import { StatutProjet } from "../../../../model/entities/statut-projet";
import { TypeEmprunt } from "../../../../model/entities/type-emprunt";
import Projet from "../../../../model/projet";
import { AuthenticationState } from "../../../../model/state/authentication-state";
import { projetService } from "../../../../services/projet-service";
import { publicService } from "../../../../services/public-service";
import { ApplicationState } from "../../../../store";
import { LightEditableBlock } from "../../../generic/editable-block/light-editable-block";
import { InputField } from "../../../generic/input-field";
import { FinancementGlobalForm } from "../../forms/financement-global-form";
import { SocialMediaShareBar } from "../../social-media-share-bar";
import { SubmitButton } from "../../../generic/submit-button";
import { investmentService } from "../../../../services/investment-service";
import { LoadingSpinner } from "../../../generic/loading-spinner";
import { arrayBuffer } from "stream/consumers";

const amountTitleStyle = css({
  margin: 0,
  fontWeight: "bold",
  opacity: 0.7,
  fontSize: ".9em",
});

const amountValueStyle = css({
  fontWeight: "bold",
  fontSize: "1.1em",
  marginBottom: ".5em",
});

const blockTitle = css({ textAlign: "center", marginTop: 0 });

type FieldData = {
  value?: any,
  name?: string,
  isValid?: boolean
}

type Form = {
  montant: FieldData
}

type Props = {
  project: Projet;
  montant?: string;
  nonEditListCode: string[];
  profile: ProfilCode;
  isPageDon?: boolean;
  reload: () => void;
};

export default function SidebarInfos({ project, montant, profile, nonEditListCode, isPageDon, reload }: Props) {
  const [form, setForm] = useState<Form>({
    montant: { value: montant, name: 'montant', isValid: true}
  })
  const [simulatedInvestmentAmount, setSimulatedInvestmentAmount] = useState(0);
  const [isSimulationNotValid, setIsSimulationNotValid] = useState(false)
  const [isProjetSuivi, setIsProjetSuivi] = useState(false)

  const [montantReductionImpot, setMontantReductionImpot] = useState<number>(0)

  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  useEffect(() => {
    if (loginProps.isAuthenticated && loginProps.oauth?.profilCode === Profil.INVESTISSEUR) {
      projetService.isProjetSuivi(project.Id!, loginProps?.oauth)
        .then(response => {
          setIsProjetSuivi(response.IsAbo!)
        })
    }
    else {
      setIsProjetSuivi(false)
    }
  }, [])

  async function simulateInvestment(e: React.ChangeEvent<HTMLInputElement>, fieldName?: string) {
    const investedAmount = parseInt(e.target.value, 10);
    const fieldValue: string = e.target.value;
    const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
    setForm({ ...form,  ...newField});
    setIsSimulationNotValid(false)
    if(e.target.value !== ""){
      setMontantReductionImpot((investedAmount * 25) / 100)
    }
    else{
      setMontantReductionImpot(0)
    }
    const body: SimulationBody = {
      MontantSimulation: investedAmount * 100
    }
    if (investedAmount) {
      const response: SimulationResponse = await projetService.getSimulatedInvestment(
        body,
        project.Id!
      );
      setSimulatedInvestmentAmount(response.MontantPrevisionnelPercu!);
    } else {
      setSimulatedInvestmentAmount(0);
    }
  }

  const onDownloadSuccess = (response: FichierAnalyseVillyzResponse) => {

    const byteCharacters = atob(response.File!);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: '' });

    const aEle = document.createElement('a');     // Create a label
    const href = window.URL.createObjectURL(blob);       // Create downloaded link
    aEle.href = href;
    aEle.download = "Analyse Crédit.pdf";  // File name after download
    document.body.appendChild(aEle);
    aEle.click();     // Click to download
    document.body.removeChild(aEle); // Download complete remove element
    window.URL.revokeObjectURL(href)
  }

  const onSimulateDetail = (response: EcheancierSimulationResponse) => {
    const byteCharacters = atob(response.EcheancierFile!);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob(
      [byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
    const aEle = document.createElement('a');     // Create a label
    const href = window.URL.createObjectURL(blob);       // Create downloaded link
    aEle.href = href;
    aEle.download = "echeancier.xlsx";  // File name after download
    document.body.appendChild(aEle);
    aEle.click();     // Click to download
    document.body.removeChild(aEle); // Download complete remove element
    window.URL.revokeObjectURL(href)
  }

  const validateMandatory = (value: any): boolean => {
    if (!value || value === "") {
      return false
    }
    return true
  }

  const statutSharingDisplayed = [
    StatutProjet.BIENTOT_DISPONIBLE,
    StatutProjet.PUBLIE,
    StatutProjet.CLOTURE,
    StatutProjet.DEBOUCLAGE_POSSIBLE,
    StatutProjet.DEBOUCLAGE_DEMANDE,
    StatutProjet.DEBOUCLAGE_VALIDE,
    StatutProjet.DEBOUCLAGE_FINALISE,
    StatutProjet.FINANCE
  ]

  const bodySimulation: SimulationBody = {
    MontantSimulation: form.montant.value * 100
  }

  return (
    <>
      {!isPageDon && (
        <Segment>
          <h4 css={blockTitle}>Simulateur financier</h4>
          <LoadingSpinner area="simulateur" height={200}>
            <Grid>
              {isSimulationNotValid && (
                <Grid.Row>
                  <p css={{
                    color: "#F03E3E",
                    fontSize: "10px"
                  }}>Veuillez renseigner un montant à simuler</p>
                </Grid.Row>
              )}
              <Grid.Column 
                width={7} 
                verticalAlign="middle"
              >
                <Grid.Row>
                  <p>En investissant :</p>
                </Grid.Row>
                <Grid.Row>
                  <div 
                    css={{
                      display: "flex", 
                      flexDirection: "row", 
                      alignItems:"center"
                    }}
                  > 
                    <Form.Field>
                      <InputField
                        validateField={validateMandatory}
                        onChange={simulateInvestment}
                        inputCss={{ 
                          border:"1px solid #828282",
                          borderRadius: "5px ", 
                          margin: "0 5px 0 0",
                          height:"30px",
                          maxWidth:100,
                        }}
                        data={form.montant}
                      />
                    </Form.Field>
                    <strong>€</strong>
                  </div>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column 
                width={2} 
                textAlign="center"
                css={{
                  padding:"0 !important"
                }}
              >
                <div 
                  css={{
                    height:"100%",
                    width:"100%",
                    textAlign:"center",
                    paddingTop:"35px",
                    paddingLeft:"25px"
                  }}
                >
                  <Icon
                    name="arrow alternate circle right"
                    size="big"
                    css={{
                      color: "#16586F"
                    }}
                  />
                </div>
              </Grid.Column>
              <Grid.Column 
                width={7} 
                textAlign="center"
                verticalAlign="middle"
              >
                <Grid.Row>
                  <p>Je reçois :</p>
                </Grid.Row>
                <Grid.Row>
                  <div
                    css={{color:"#009E3F"}}
                  >
                    <strong> {simulatedInvestmentAmount} € </strong>
                  </div>
                </Grid.Row>
              </Grid.Column>
            </Grid>
            {project?.TypeEmpruntCode === TypeEmprunt.TITRES &&
              <div
                css={{
                  fontSize: "1rem",
                  background: "#D1E9F7",
                  padding: "0.5em",
                  borderRadius: "0.3rem",
                  borderStyle: "solid",
                  borderWidth: "thin",
                  color: "#517FB1",
                  marginTop: "1em",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textAlign:"center"
                }}
              >
                <Icon
                  name="lightbulb outline"
                  size="big"
                />
                Vous pouvez réduire jusqu'à {montantReductionImpot} € votre impôt sur le revenu.
              </div>
            }
            <div
              css={{
                fontStyle: "italic",
                textAlign: "center",
                color:"#828282",
                fontSize: "0.9rem",
                marginTop: "1em"
              }}
            >
              <p>
                Montants bruts estimés avant fiscalité et hors éventuels retards ou
                défauts de paiement et intérêts calculés sur le capital restant dû à chaque échéance
              </p>
              <SubmitButton 
                label="Voir le détail de l'échéancier"
                action={() => publicService.simulateEcheancierPrevisionnel(bodySimulation, project.Id!)}
                onActionSuccess={onSimulateDetail}
                validateForm={() => form.montant.value !== undefined}
                onFormNotValid={() => setIsSimulationNotValid(true)}
                loaderArea="simulateur"
              />
            </div>
          </LoadingSpinner>
        </Segment>
      )}
      

      {statutSharingDisplayed.find(statut => statut === project.StatutProjetCode) && (
        <Segment>
          <h4 css={blockTitle}>Partager le projet</h4>
          <SocialMediaShareBar
            url={window.location.href}
            text={`Projet Villyz | ${project.Titre} est en financement`}
          />
        </Segment>
      )}

      <Segment>
        <LightEditableBlock
          title="Financement du projet"
          editable={(profile === ProfilCode.PORTEUR_PROJET && !(nonEditListCode.includes(project.StatutProjetCode!) || nonEditListCode.includes(project.StatutDonProjetCode!))) || profile === ProfilCode.VILLYZ}
          editor={(close) => (
            <FinancementGlobalForm
              projet={project}
              onSubmitSuccess={() => {
                close();
                reload();
              }}
            />
          )}
        >
          <>
            <h4 css={blockTitle}>Financement du projet</h4>

            <p css={amountTitleStyle}>Investissement citoyen</p>
            <p css={amountValueStyle}>
              {project.MontantFinancementCitoyenDemande?.toLocaleString("fr-FR") ?? "0"}{" "}€
            </p>
            <p css={amountTitleStyle}>Financement bancaire</p>
            <p css={amountValueStyle}>
              {project.MontantFinancementBancaire?.toLocaleString("fr-FR") ?? "0"}{" "}€
            </p>
            <p css={amountTitleStyle}>Subvention</p>
            <p css={amountValueStyle}>
              {project.MontantFinancementSubvention?.toLocaleString("fr-FR") ?? "0"}{" "}€
            </p>
            <p css={amountTitleStyle}>Autofinancement</p>
            <p css={amountValueStyle}>
              {project.MontantAutofinancement?.toLocaleString(
                "fr-FR"
              ) ?? "0"}{" "}
              €
            </p>
            <p css={amountTitleStyle}>Autre financement</p>
            <p css={amountValueStyle}>
              {project.MontantFinancementAutre?.toLocaleString(
                "fr-FR"
              ) ?? "0"}{" "}
              €
            </p>
            <p css={amountTitleStyle}>Montant total du projet</p>
            <p css={amountValueStyle}>
              {((project.MontantFinancementAutre ?? 0) + (project.MontantAutofinancement ?? 0) +
                (project.MontantFinancementSubvention ?? 0) + (project.MontantFinancementBancaire ?? 0)
                + (project.MontantFinancementCitoyenDemande ?? 0))
                .toLocaleString("fr-FR")}{" "}€
            </p>

          </>
        </LightEditableBlock>
      </Segment>
      <Segment>
        <h4 css={blockTitle}>Contact</h4>
        <p>
          En cas de question n'hésitez pas à nous contacter sur{" "}
          {profile == ProfilCode.PORTEUR_PROJET ? <a href="mailto:collectivite@villyz.fr">collectivite@villyz.fr</a>
            : <a href="mailto:contact@villyz.fr">contact@villyz.fr</a>}
        </p>
      </Segment>
    </>
  );
}
