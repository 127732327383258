import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { Collectivite } from "../../../model/entities/profil";
import { Option, getDropdownOptions, getDropdownOptionsWithCode } from "./helper";

type Props = {
  type: Collectivite;
  label?: string;
  initialCode?: number;
  useCodeUnique: boolean;
  autocomplete?: string;
  submitted?: boolean,
  error?: string,
  onChange: (value: any, isValid: boolean) => void;
  // onChange?: (event: React.ChangeEvent<HTMLInputElement>, fieldName?: string) => void,
  validateField?: (newValue: any) => boolean
};

export function CollectiviteSelector({ type, label, initialCode, useCodeUnique, error, submitted, autocomplete, onChange, validateField }: Props) {
  const [clue, setClue] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [code, setCode] = useState(initialCode?.toString())
  const [isValid, setIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string|undefined>(error)

  useEffect(()=>{
      setCode(initialCode?.toString())
      setClue("")
  }, [initialCode])
  
  useEffect(() => {
    if(submitted) {
      setIsValid(code && code !== "" ? true : false)
    }

    if(code) {
      setIsLoading(true)
      getDropdownOptionsWithCode(type, code, useCodeUnique)
        .then((opt) => setOptions(opt))
        .finally(() => setIsLoading(false))
    }
    else {
      getDropdownOptions(type, "", useCodeUnique)
        .then((opt) => {
          setOptions(opt)
        })
    }

    if (clue.length < 3) {
      return;
    }

    if(!code) {
      setIsLoading(true);
      getDropdownOptions(type, clue, useCodeUnique)
        .then((opt) => setOptions(opt))
        .finally(() => setIsLoading(false));
    }
  }, [type, clue, code, submitted]);

  const onInputChange = (value: string) => {
    if(validateField) {
        let message = validateField(value)
        if(!message) {
            setIsValid(false)
            setErrorMessage(error)
            if(onChange) {
              onChange(value, false)
          }
        }
        else {
            setIsValid(true)
            setErrorMessage(undefined)
            if(onChange) {
              onChange(value, true)
          }
        }
    }
    else {
      if(onChange) {
          onChange(value, true)
      }
  }
}

  return (
    <>
      {label && <label>{label}</label>}
      <Dropdown
        selection
        clearable
        placeholder="Recherche..."
        noResultsMessage="3 charactères min."
        search
        options={options}
        autocomplete={autocomplete}
        loading={loading}
        value={code}
        onSearchChange={(_, { searchQuery }) => {
          setClue(searchQuery)
          setCode(undefined)
        }}
        onChange={(_, { value }) => {
          if(value === "") {
            setClue("")
          }
          setCode(value?.toString())
          onInputChange(value?.toString() ?? "")
        }}
      />
      {!isValid && (
          <div css={{
              color: "#F03E3E",
              fontSize: "10px",
              marginTop: "4px"
          }}>
              {errorMessage}
          </div>
      )}
    </>
    
  );
}
