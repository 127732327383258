import React from "react"
import { Stepper } from "react-form-stepper"
import { StepDTO } from "react-form-stepper/dist/components/Step/StepTypes"
import './horizontal-timeline.css'


interface HorizontalStepperProps {
    elements: StepDTO[],
    activeStep: number | undefined
}

export function HorizontalStepper({elements, activeStep}: HorizontalStepperProps) {
    const styleConfig = {
        activeBgColor:"#2c3c54",
        activeTextColor:"#fff",
        completedBgColor:"#2c3c54",
        completedTextColor:"#fff",
        inactiveBgColor:"#aeb6bf",
        inactiveTextColor:"#fff",
        size:"2em",
        circleFontSize:"1rem",
        labelFontSize:"0.9rem",
        fontWeight:"bold",
        borderRadius:"50%",
    }

    const connectorStyleConfig = {
        activeColor:"#2c3c54",
        disabledColor:"#aeb6bf",
        completedColor:"#2c3c54",
        size:5,
        style:"solid"
    }

    return (
        <Stepper
            steps={elements}
            activeStep={activeStep}
            style={{
                width:"100%",
            }}
            connectorStateColors
            styleConfig={styleConfig}
            connectorStyleConfig={connectorStyleConfig}
        />
    )
}