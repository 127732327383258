import React, { useEffect, useMemo, useState } from "react"
import{ CollectivitePostPublique } from "../../../model/entities/collectivite-post-publique"
import { PostForm } from "../../projet/forms/post-form"
import { EditableBlock } from "../../generic/editable-block/editable-block"
import { publicService } from "../../../services/public-service"
import { projetService } from "../../../services/projet-service"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../../store"
import { PostReactionBody } from "../../../model/dto/body/post-reaction-body"
import { addDays, differenceInDays, format, parseISO } from "date-fns";
import css from "@emotion/css"
import { HeaderLined } from "../../generic/header-lined"
import { stepperUnnumbered } from "../../pages/styles"
import parse from "html-react-parser"
import ShowMoreText from "react-show-more-text"
import { Container, Grid, Icon, Segment } from "semantic-ui-react"
import { EventPostBox } from "../Event-Post-Box"
import ShowMore from 'react-show-more-button';
import { Profil } from "../../../model/entities/profil"
import './post-item.css';

interface Props {
   projectId: number;
   post: CollectivitePostPublique;
   handleAuthenticationModal: (bool: boolean)=>void;
   reload: ()=>void;
}
const DateStyle = css({
    margin: 0,
    fontWeight: "bold",
    opacity: 0.5,
    fontSize: ".6em",
  });

export function PostItem({projectId, post, handleAuthenticationModal, reload}: Props) {
    const [numberOfReactions, setNumberOfReactions] = useState<number>(0);
    const [isLiked, setIsLiked] = useState(false);
    const [expand, setExpand] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Voir plus')

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(()=>{
        if(loginProps.oauth) {
            projetService.getReactionsByUserId(post.Id!, loginProps.oauth).then(
                (response)=>{
                    setIsLiked(response.IsLiked!)
                }
            );
        }

        publicService.getPostReactions(projectId, post.Id!).then(
            (response)=>{
               setNumberOfReactions(response.NumberOfReactions!)
            }
        );
    }, []);

    const handleHideOrExpand = () => {
        setExpand(!expand);
    };

    const handleClick = ()=>{
        if(loginProps.isAuthenticated) {
            const body: PostReactionBody = {
                Id: post.Id,
                React: !isLiked 
            }
            projetService.reactToPost(body, post.Id!, loginProps?.oauth).then(()=>{
                publicService.getPostReactions(projectId, post.Id!).then(
                    (response)=>{
                       setNumberOfReactions(response.NumberOfReactions!)
                    }
                );
            });
            setIsLiked(!isLiked)
        }
        else {
            handleAuthenticationModal(true)
        }
    }

    const [imgClass, setImgClass] = useState("initial-img")

    const wysiwygContainerStyle = css({
        padding: "5px 0px",
        iframe: {
          aspectRatio: '560 / 315',
          maxWidth: '100%',
          height: 'auto',
        },
        img: {
          maxWidth: '100%',
          height: 'auto !important',
          display: "inline"
        },
        h1:{
            span: {
                whiteSpace: "normal",
            }
        }
      })

    return (
    <Container>
        <EditableBlock 
            title={post.Titre}
            withMargin={false}
            deleteIcon="trash"
            editable={loginProps.oauth?.profilCode==Profil.PORTEUR_PROJET || loginProps.oauth?.profilCode==Profil.VILLYZ_USER}
            iconCss={{
            position: "absolute",
            right: "78px",
            top: "5px",
            zIndex: 10
            }}
            editor={(close) => (
                <PostForm 
                    projectId={projectId} 
                    data={post}
                    close={close}
                    reload={reload}
                />
            )}
            onRemoveValidate={(isRemoved: boolean) => {
                if(isRemoved) {
                    projetService.deletePost(
                        post.Id!,
                        loginProps?.oauth
                    )
                    .then(response => {
                        reload();
                    })
                    
                }
            }}
         >
            <div css={DateStyle}>
            {format(new Date(post.DateCreation!), "dd/MM/yyyy")}
            </div> 
            <div css={{display: 'flex', flexDirection: "column", alignItems: "center" }}>
            </div> 
            <div>
                    {/* <>
                        {parse(post.DescriptionPost ?? '')}
                        {post.ImagePost && (
                            <img 
                                src={`${process.env.REACT_APP_BASE_URL}/public/projets/${post.Id}/picture`}
                                css={{
                                    minHeight: "200px"
                                }}
                            />
                        )}
                    </> */}
                <ShowMore 
                    maxHeight={300}
                    button={<button className={"btn btn-primary"}>{buttonLabel}</button>}
                    onChange={(showValue: boolean) => {
                        if(showValue) {
                            setButtonLabel('Voir plus')
                        }
                        else {
                            setButtonLabel('Cacher')
                        }
                    }}
                >
                    <div css={wysiwygContainerStyle}>
                        {parse(post.DescriptionPost ?? '')}
                        {post.ImagePost && (
                            <div css={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <img 
                                className={imgClass}
                                src={`${process.env.REACT_APP_BASE_URL}/public/projets/${post.Id}/picture`}
                                css={{
                                    maxHeight: '420px',
                                    maxWidth: '700px',
                                    objectFit: 'cover',
                                    width: '100%'
                                }}
                                onLoad={(e) => {
                                    setImgClass("loaded-img")
                                }}
                            />
                            </div>
                            
                        )}
                    </div>
                </ShowMore>
            </div>
            <div css={{ display: "flex", flexDirection:"column", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
                <div css={{width:"40px", paddingLeft:"7.5px"}}>
                    <Icon 
                        css={{
                            cursor: 'pointer'
                        }} 
                        color="blue" 
                        size="large"
                        name={isLiked ? "heart" : "heart outline"} 
                        onClick={handleClick}
                    />
                </div>
                {numberOfReactions}
            </div>
        </EditableBlock>
    </Container>            
    );
}

