import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownProps} from "semantic-ui-react";

export type DropdownElement = {
  text: string,
  value: string | number
}

type FieldData = {
  name?: string,
  value?: any,
  isValid?: boolean
}

type Props = {
  placeholder?: string,
  submitted?: boolean,
  //champs + saisie de l'utilisateur
  data: FieldData,
  error?: string,
  // la liste des données à sélectionner
  options: DropdownElement[],
  onChange?: (value: any) => void,
  validateField?: (value: any) => boolean
};

export function MultipleDropdownSelection({placeholder, options, onChange, validateField, submitted, error, data }:Props) {

  const [isValid, setIsValid] = useState(data.isValid)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (!submitted) return;

    const isValid = validateField?.(data.value) ?? false;
    setIsValid(isValid);

    if (!isValid && error) {
      setErrorMessage(error);
    }
  }, [submitted]);

  const onValueChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if(validateField && !validateField(data.value)) {
      setIsValid(false)
    }
    else {
      setIsValid(true)
    }

    if(onChange) {
      onChange(data.value)
    }
  }

  return (
    <Dropdown 
      fluid
      selection
      multiple
      search
      value = {data.value}
      placeholder={placeholder}
      options = {options}
      onChange={onValueChange}
    />
  );
}