import React from "react";
import { MultipleFileRegistrationResponse, OnboardingBusinessResponse } from "../../model/dto/response";
import { RemerciementOnboardingCitoyen } from "./remerciement-onboarding-citoyen";
import { SubscriptionCollectiviteAdresses } from "./subscription-collectivite-adresses";
import { SubscriptionIdentiteRepresentantForm } from "./subscription-identite-representant-form";
import { WorkflowType } from "../../model/entities/workflow-type";
import { FlowStep } from "../../model/entities/flow-step";

type Props = {
  activeStepKey: string,
  lastStepResponse?:OnboardingBusinessResponse,
  onSubmitVerification: (response: OnboardingBusinessResponse ) => void,
  onSubmitPiecesJustificatives: (response: MultipleFileRegistrationResponse) => void,
  onNextStep: (response: Response) => void
}

export function SubscriptionInvestisseurBusinessForm({
  activeStepKey, 
  lastStepResponse,
  onSubmitVerification, 
  onSubmitPiecesJustificatives, 
  onNextStep
}: Props) {

  if(activeStepKey ===FlowStep.IDENTITE_REPRESENTANT_LEGAL) {
    return (
      <div>
        <SubscriptionIdentiteRepresentantForm
          isOnboarding={true}
          onSubmitSuccess={onSubmitVerification}
          onSkipStep={onNextStep}
          workflowTypeCode={WorkflowType.ONBOARDING_INVESTISSEUR_SOCIETE}
        />
      </div>
    )
  }

  if(activeStepKey === FlowStep.ADRESSES) {
    return (
      <div>
        <SubscriptionCollectiviteAdresses
          onSubmitSuccess={onSubmitVerification}
          onSkipStep={onNextStep}
          workflowTypeCode={WorkflowType.ONBOARDING_INVESTISSEUR_SOCIETE}
          lastStepResponse={lastStepResponse}
        />
      </div>
    )
  } 

  if(activeStepKey === FlowStep.REMERCIEMENT) {
    return (
      <div>
        <RemerciementOnboardingCitoyen />
      </div>
    )
  }

  return (
    <div></div>
  )
}