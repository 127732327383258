import React, { useEffect, useState } from "react"
import { Button, Container } from "semantic-ui-react"
import { PaginatedTable } from "../../../generic/paginated-table/paginated-table"
import { investmentService } from "../../../../services/investment-service"
import { useSelector } from "react-redux"
import { AuthenticationState } from "../../../../model/state/authentication-state"
import { ApplicationState } from "../../../../store"
import { RemboursementHistoryListResponse, RemboursementStatutsListResponse } from "../../../../model/dto/response"
import { NavLink } from "react-router-dom"
import { LoadingSpinner } from "../../../generic/loading-spinner"
import { trackPromise } from "react-promise-tracker"
import { format } from "date-fns"
import { RemboursementUserDtoItem } from "../../../../model/dto/remboursement-user-dto-item"
import { referenceService } from "../../../../services/reference-service"
import { StatutRemboursement } from "../../../../model/entities/statut-remboursement"

type Props = {

}

export function RemboursementPane({}: Props) {
    const [histoRemboursement, setHistoRemboursement] = useState<RemboursementUserDtoItem[]>()
    const [remboursementStatutsList, setRemboursementStatutsList] = useState<StatutRemboursement[]>([])

    const [isLoading, setIsLoading] = useState(false)

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(() => {
        setIsLoading(true);
        trackPromise(investmentService.getRemboursementList(loginProps?.oauth)
        .then((response: RemboursementHistoryListResponse) => {
            if(response.IsTraitementOk) {
                setHistoRemboursement(response.HistoRemboursementUserList)
                setIsLoading(false)
            }
        }))

        referenceService.getRemboursementStatutsList(loginProps?.oauth)
        .then((response: RemboursementStatutsListResponse) => {
            setRemboursementStatutsList(response.RemboursementStatutsList!)
        })
    }, [])

    return (
        <Container>
            <h1 className="py-8 text-3xl">Mes remboursements</h1>
            <LoadingSpinner height={100} />
            {!isLoading && histoRemboursement?.length && (
                <PaginatedTable 
                    elements={histoRemboursement}
                    elementsPerPage={5}
                    headers={
                        [
                            'Projet',
                            'Identifiant opération',
                            'Statut remboursement',
                            'Montant',
                            'Date de remboursement'
                        ]
                    }
                    columnSelector={(element: RemboursementUserDtoItem) => {
                        return {
                            values: [
                                element.TitreProjet,
                                element.Investissement,
                                remboursementStatutsList?.find(statut => statut.Code === element.StatutRemboursement)?.Libelle,
                                element.Montant ? element.Montant / 100 + '€' : '',
                                element.DateRemboursement ? format(new Date(element.DateRemboursement), "dd/MM/yyyy") : '',
                            ]
                        }
                    }}
                />
            ) || (
                <div className="text-center p-8 text-xl">
                    <span className="font-bold">
                        Vous n'avez pas encore effectué de demande de remboursement
                    </span>
                </div>
            )}
            <div className="text-center py-8">
                <Button as={NavLink} to="/projets" size="big" primary>
                    Découvrir les projets
                </Button>
            </div>
        </Container>
        
    )
}