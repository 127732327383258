import React, {useState} from 'react'
import Projet from '../../model/projet'
import { CreateProjetWorkflowForm } from './forms/create-projet-workflow'
import SelectTypeCollecteForm from './forms/select-type-collecte-form'
import { AuthenticationState } from '../../model/state/authentication-state'
import { TypeCollecte } from '../../model/entities/type-collecte'
import { WorkflowType } from '../../model/entities/workflow-type'
import { ProjetDetailResponse } from '../../model/dto/response'

type Props = {
    newProjet: Projet,
    loginProps?: AuthenticationState,
    onNextStep: (stepKey: string) => void
}

export default function SwitchCreateProjetWorflow({newProjet, loginProps, onNextStep} : Props) {
    const [workflowTypeCode, setWorkflowTypeCode] = useState<string >('')
    const [projet, setProjet] = useState<Projet>(newProjet)

    const returnWorflowTypeCodeByTypeCollecteCode = (code : string | undefined) => {
        if(code === undefined){
            return ''
        }
        return code === TypeCollecte.EMPRUNT_CITOYEN ? WorkflowType.CREATION_PROJET_EMPRUNT :
               code === TypeCollecte.DON ? WorkflowType.CREATION_PROJET_DON : 
               code === TypeCollecte.COMBINAISON_EMPRUNT_DON ? WorkflowType.CREATION_PROJET_COMBINE : 
               ''
    }

    const onSubmitTypeCollecteSuccess = (response : ProjetDetailResponse) => {
        if(response.IsTraitementOk){
            setWorkflowTypeCode(returnWorflowTypeCodeByTypeCollecteCode(response.Projet?.TypeCollecteCode))
            onNextStep(response.NextStepKey!)
            setProjet(response.Projet!)
        }
    }

  return (
    <div>{projet && projet.TypeCollecteCode !== null ? 
        <CreateProjetWorkflowForm
            projet={projet}
            onNextStep={onNextStep}
            loginProps={loginProps}
            workflowTypeCode={workflowTypeCode && workflowTypeCode}
         />
         :
         <SelectTypeCollecteForm 
            projet={projet}
            loginProps={loginProps}
            onSubmitSuccess={onSubmitTypeCollecteSuccess}
         />
    }
        
    </div>
  )
}
