import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Header, Icon, Modal, Segment, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { PostCreationResponse, ProfilCode } from "../../model/dto/response";
import Projet from "../../model/projet";
import { AuthenticationState } from "../../model/state/authentication-state";
import { projetService } from "../../services/projet-service";
import { ApplicationState } from "../../store";
import { SubmitButton } from "../generic/submit-button";
import{CollectivitePostPublique} from "../../model/entities/collectivite-post-publique";
import { publicService } from "../../services/public-service";
import { PostForm } from "../projet/forms/post-form";
import {PostItem} from "./detail/post-item";
import { addDays, differenceInDays, format, parseISO } from "date-fns";
import { css } from "@emotion/core";
import { GenericPost } from "./detail/generic-post"
import { Profil } from "../../model/entities/profil";
import { displayPartsToString } from "typescript";

interface Props {
  project: Projet;
}

export function Pane({project}: Props) {
    const [newPost, setNewPost] = useState(false);
    const [listPosts, setListPosts] = useState<CollectivitePostPublique[]>([]);
    const [postId, setPostId] = useState<number>();
    const [open, setOpen] = useState(false)
    const [reload, setReload] = useState(true)
    
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    
    useEffect(()=>{
      if(reload){
        if (loginProps.oauth?.profilCode=== Profil.PORTEUR_PROJET)
        {
         projetService.getPostList(project.Id!,loginProps.oauth)
         .then(
           (response)=>{
              if(response) setListPosts(response.PostsList!)
           }
         );

         setReload(false);
        }
        else {
          publicService.getPostList(project.Id!)
          .then(
            (response)=>{
               if(response) setListPosts(response.PostsList!)
            }
          );
          
          setReload(false);
        }
      }
    }, [reload])


    return (
        <>
            <div css={{display: "flex", justifyContent: "space-around",marginBottom: "30px"}}>
            {(loginProps.oauth?.profilCode === Profil.PORTEUR_PROJET || loginProps.oauth?.profilCode === Profil.VILLYZ_USER) && (
                <SubmitButton 
                   label= "Nouvelle Publication" 
                   loaderArea = "modal"
                   onActionSuccess={(response: PostCreationResponse) => {
                     setPostId(response.PostId);
                     setNewPost(true);
                   }} 
                   action={()=>projetService.createPost(project.Id!, loginProps.oauth)}
                />
            )}
            </div> 

            {(listPosts && listPosts.length > 0) ? listPosts.map((post)=>{
              return (
                <>
                  <GenericPost 
                    key={post.Id} 
                    post={post} 
                    handleAuthenticationModal={(bool: boolean)=>setOpen(bool)}
                    reload={()=>setReload(true)} 
                  />
                  <div css={{
                    display: [...listPosts].pop() === post ? "none" : "block", 
                    borderLeft:"0.1rem solid rgb(20 18 67 / 60%)", 
                    height:"40px", 
                    position:"relative", 
                    left:"50%", 
                    marginLeft:"-0.5px", 
                    top:"0"
                    }}
                  >
                  </div>
                </>
              )})
            :
              <Segment inverted secondary color='blue' textAlign="center" size="big" padded='very'>
                <Icon name="newspaper" size="big"/>
                <span css={{display:"inline-block", margin:"10px"}}>Aucune Publication Disponible</span>
              </Segment>
            }
            
            <Modal open={newPost} closeOnDimmerClick={false}>
                <Modal.Content>
                    <Modal.Description>
                      <PostForm 
                        projectId={project.Id!} 
                        postId={postId!} 
                        reload={() => setReload(true)} 
                        close={() => setNewPost(false)}
                        onCancel={() => {
                          projetService.deletePost(postId!, loginProps?.oauth)
                          .then(response => {
                            if(response.IsTraitementOk) {
                              setReload(true)
                            }
                          })
                        }}
                      />
                    </Modal.Description>
                </Modal.Content>
            </Modal> 

            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Header content='Réagir à un post' />
                <Modal.Content>
                    <p>
                    Merci de vous <a href="/login"> connecter </a> ou vous <a href="/login">inscrire</a> pour suivre l'actualité du projet
                    </p>
                </Modal.Content>
            </Modal>
        </>
    ) 


}

