import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { ProjectDetail } from "../components/projet/detail/project-detail";

type Params = {
  slug?: string
}

export function ProjetDetailRouter() {
    let { slug } = useParams<Params>();
    let { search } = useLocation();
    let params = new URLSearchParams(search);
    let investementSucceded = params.get('transactionId') !== undefined && params.get('transactionId') !== null || params.get('single_immediate_payment_id') !== undefined && params.get('single_immediate_payment_id') !== null;
    return (
        <ProjectDetail slug={slug} investementSucceded={investementSucceded} isPageDon={false}/>
    );
}
