import { css } from "@emotion/core";
import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton, 
} from "react-share";
import { Icon } from "semantic-ui-react";

const socialButtonSpacing = css`
  margin: 5px;
`;

type Props = {
  url: string;
  text: string;
  size?: "large" | "small";
  round?: boolean;
};

export function SocialMediaShareBar({
  url,
  text,
  size = "large",
  round = true,
}: Props) {
  return (
    <div css={{ padding: "5px", display: "flex", justifyContent: "center" }}>
      <FacebookShareButton
        css={socialButtonSpacing}
        quote={text}
        hashtag="Villyz"
        url={url}
      >
        <Icon circular={round} color="purple" size={size} name="facebook f" />
      </FacebookShareButton>
      <TwitterShareButton css={socialButtonSpacing} title={text} url={url}>
        <Icon circular={round} color="purple" size={size} name="twitter" />
      </TwitterShareButton>
      <LinkedinShareButton
        css={socialButtonSpacing}
        title={text}
        summary="Un projet à découvrir sur Villyz"
        url={url}
      >
        <Icon circular={round} color="purple" size={size} name="linkedin" />
      </LinkedinShareButton>
      <WhatsappShareButton css={socialButtonSpacing} title={text} url={url}>
        <Icon circular={round} color="purple" size={size} name="whatsapp" />
      </WhatsappShareButton>
    </div>
  );
}
