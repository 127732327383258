import React, { useMemo, useState } from "react"
import { Button, Form, Icon } from "semantic-ui-react"
import { InputField } from "../../generic/input-field"
import { investmentService } from "../../../services/investment-service"
import { PayoutCitoyenbody } from "../../../model/dto/body/payout-citoyen-body"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { ApplicationState } from "../../../store"
import { useSelector } from "react-redux"
import { FormErrorHeader } from "../../generic/form-error-header"
import { SubmitButton } from "../../generic/submit-button"
import { parse } from "path"
import { DefaultResponse } from "../../../model/dto/response"

type Props = {
    walletValue: number,
    onSubmitSuccess: (response: Response) => void
    handleRetraitFonds?: ()=>void
}

type FieldData = {
    value?: any,
    name: string,
    isValid?: boolean,
    key?: string
};
    
type FormData = {
    montantRetrait: FieldData,
    iban: FieldData
}

export function RetraitFondsModal({walletValue, onSubmitSuccess, handleRetraitFonds}: Props) {
    const [form, setForm] = useState<FormData>({
        montantRetrait: {value: '', name: 'montantRetrait', isValid: true},
        iban: {value: '', name: 'iban', isValid: true}
    })

    const [isFormError, setIsFormError] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [errorBackPayoutMessage, setErrorBackPayoutMessage] = useState<string | undefined>()


    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean, key?: string): void => {
        const fieldValue: string = e.target.value;
        setIsFormError(false)
        setForm({...form, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid, key: key}})
    }

    const handleMontantChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean, key?: string): void => {
        const fieldValue: string = e.target.value;
        const parsedValue = fieldValue.replace(',', '.')
        setIsFormError(false)
        setForm({...form, [fieldName!]: {value: parsedValue, name: fieldName, isValid: isValid, key: key}})
    }

    const validateMandatory = (value : any) => {
        if (value) {
            const regex = /^[0-9A-Z]{27}$/g;
            return( regex.test(value))
        } else {
            return false
        }
    }
    
    const emptyMontantMessage = "Merci de renseigner un montant non nul"
    const tooHightMontantMessage = "Merci de renseigner le montant de votre retrait inférieur ou égal à votre solde"
    const errorMessage = form.montantRetrait.value === '0' || !form.montantRetrait.value ? emptyMontantMessage : tooHightMontantMessage

    const validateMontant = (value : any) => {
        if (value > 0) {
            const regex = /^\d+((\.|,)\d+)?/g;
            const valueMontant = parseFloat(value.replace(',', '.'));
            return ( regex.test(value) && valueMontant <= walletValue)
        } else {
            return false
        }
    }

    const isFormValid = (): boolean => {
        // Si l'utilisateur est bloqué, on ne valide jamais le formulaire
        if(loginProps.user?.IsBloque ?? false) {
            return false
        }
        return validateMontant(form.montantRetrait.value) && validateMandatory(form.iban.value)
    }

    const onFormNotValid = (): void => {
        if(loginProps.user?.IsBloque ?? false) {
            return
        }

        setIsFormError(true)
    }

    const body: PayoutCitoyenbody = {
        MontantRetrait: Math.round(parseFloat(form.montantRetrait.value) * 100),
        Iban: form.iban.value
    }

    return (
        <>
            <div className="flex flex-col px-4 text-center text-xl text-gray-400">
                <span className="font-bold text-3xl">{walletValue}€</span>
                <span>
                    <Icon name="money" size="big" />
                    Somme disponible
                </span>
            </div>

            <Form>
                <FormErrorHeader 
                    message="Vous ne pouvez pas effectuer de retrait car votre compte a été bloqué, veuillez vous rapprocher du support Villyz"
                    displayed={loginProps.user?.IsBloque ?? false}
                />
                <FormErrorHeader 
                    message="Veuillez corriger les champs en erreur"
                    displayed={isFormError}
                />
                <FormErrorHeader
                    message={errorBackPayoutMessage ?? ''}
                    displayed={errorBackPayoutMessage !== undefined}
                />
                <Form.Field width={16}>
                    <InputField 
                        data={form.montantRetrait}
                        label="Montant du retrait (en euros)"
                        onChange={handleMontantChange}
                        validateField = {validateMontant}
                        error={errorMessage}
                        submitted={formSubmitted}
                    />
                    </Form.Field>
                    <Form.Field width={16}>
                        <InputField 
                            data={form.iban}
                            label="IBAN du compte à créditer"
                            onChange={handleInputChange}
                            error="Merci de renseigner votre IBAN à 27 caractères alphanumériques sans espace"
                            validateField = {validateMandatory}
                            submitted={formSubmitted}
                        />
                    </Form.Field>
                    <SubmitButton 
                        data={body}
                        label="Valider"
                        onPreSubmit={() => {setFormSubmitted(true)}}
                        action={() => investmentService.askForPayoutCitoyen(body, loginProps?.oauth)}
                        validateForm={isFormValid}
                        onFormNotValid={onFormNotValid}
                        onActionSuccess={()=>{
                            handleRetraitFonds?.()
                            
                        }}
                         onActionFailure={(response:DefaultResponse)=> setErrorBackPayoutMessage(response.Error)}
                    />
            </Form>
        </>
    )
}