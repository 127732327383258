import React from "react"
import { StatutProjet } from "../../model/entities/statut-projet"
import { CategorieEtablissement } from "../../model/entities/categorie-etablissement"
import { Popup } from "semantic-ui-react"
import { css } from "@emotion/core"

interface Props {
    statut: string
    daysUntilEnd?: string
    categorieEtablissementCode?: string
    categorieEtablissementLibelle?: string
}

export function ProjetStatut({statut, daysUntilEnd, categorieEtablissementCode, categorieEtablissementLibelle}: Props) {

    const statutsClotures = [
        StatutProjet.CLOTURE,
        StatutProjet.DEBOUCLAGE_DEMANDE,
        StatutProjet.DEBOUCLAGE_POSSIBLE,
        StatutProjet.DEBOUCLAGE_VALIDE,
        StatutProjet.DEBOUCLAGE_FINALISE
    ]

    const containerStyles = css({
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "8px",
        marginRight:"auto",
        marginLeft:"10px",
        alignItems:"flex-start",
        position:"absolute",
        zIndex:2
    });

    const bientotDisponibleTextColor = '#A43901'
    const bientotDisponibleBgColor = '#FFCDB2'
    const aVenirBgColor = '#FFF275'
    const aVenirTextColor = '#836417'

    return (
        <>
            {statut === StatutProjet.BIENTOT_DISPONIBLE && categorieEtablissementCode && 
            (categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE || categorieEtablissementCode === CategorieEtablissement.CHU || 
            categorieEtablissementCode === CategorieEtablissement.SOCIETE ) && 
                <div css={containerStyles}>
                    <div>
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE &&
                            <Popup 
                                content='Collectivité locale : Les collectivités locales sont des emprunteurs publics très contrôlés qui présentent un risque de défaut faible. Pour en savoir, rendez vous sur notre page “Risques”.' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: `${bientotDisponibleTextColor} 1px solid`, 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: bientotDisponibleBgColor, 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: `1px solid ${bientotDisponibleTextColor}`,
                                                marginRight: "0 !important",
                                                color: bientotDisponibleTextColor
                                            }}>
                                                Bientôt disponible
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.CHU &&
                            <Popup 
                                content='Centre hospitalier : Les centres hospitaliers sont des emprunteurs publics contrôlés qui présentent un risque de défaut relativement faible. Pour en savoir, rendez vous sur notre page “Risques”.' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: `${bientotDisponibleTextColor} 1px solid`, 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: bientotDisponibleBgColor, 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: `1px solid ${bientotDisponibleTextColor}`,
                                                marginRight: "0 !important",
                                                color: bientotDisponibleTextColor
                                            }}>
                                                Bientôt disponible
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.SOCIETE &&
                            <Popup 
                                content='Société à impact : Les sociétés à impact sont des entreprises privées qui entrainent un impact positif sur le développement des territoires. Elles sont plus risquées que des collectivités ou des centres hospitaliers' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: `${bientotDisponibleTextColor} 1px solid`, 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                        color: bientotDisponibleTextColor
                                    }}>
                                        <span 
                                            css={{
                                                background: bientotDisponibleBgColor, 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: `1px solid ${bientotDisponibleTextColor}`,
                                                marginRight: "0 !important",
                                            }}>
                                                Bientôt disponible
                                        </span>

                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                    </div>
                </div>
            }

            {statut === StatutProjet.A_VENIR &&
            categorieEtablissementCode && 
            (categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE || categorieEtablissementCode === CategorieEtablissement.CHU || 
            categorieEtablissementCode === CategorieEtablissement.SOCIETE ) && 
                <div css={containerStyles}>
                    <div>
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE &&
                            <Popup 
                                content='Collectivité locale : Les collectivités locales sont des emprunteurs publics très contrôlés qui présentent un risque de défaut faible. Pour en savoir, rendez vous sur notre page “Risques”.' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: `${aVenirTextColor} 1px solid`, 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: aVenirBgColor, 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: `1px solid ${aVenirTextColor}`,
                                                marginRight: "0 !important",
                                                color: aVenirTextColor
                                            }}>
                                                A venir
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.CHU &&
                            <Popup 
                                content='Centre hospitalier : Les centres hospitaliers sont des emprunteurs publics contrôlés qui présentent un risque de défaut relativement faible. Pour en savoir, rendez vous sur notre page “Risques”.' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: `${aVenirTextColor} 1px solid`, 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: aVenirBgColor, 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: `1px solid ${aVenirTextColor}`,
                                                marginRight: "0 !important",
                                                color: aVenirTextColor
                                            }}>
                                                A venir
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.SOCIETE &&
                            <Popup 
                                content='Société à impact : Les sociétés à impact sont des entreprises privées qui entrainent un impact positif sur le développement des territoires. Elles sont plus risquées que des collectivités ou des centres hospitaliers' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: `${aVenirTextColor} 1px solid`, 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                        color: aVenirTextColor
                                    }}>
                                        <span 
                                            css={{
                                                background: aVenirBgColor, 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: `1px solid ${aVenirTextColor}`,
                                                marginRight: "0 !important",
                                            }}>
                                                A venir
                                        </span>

                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                    </div>
                </div>

            }

            {statut === StatutProjet.PUBLIE && categorieEtablissementCode && 
            (categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE || categorieEtablissementCode === CategorieEtablissement.CHU || 
            categorieEtablissementCode === CategorieEtablissement.SOCIETE ) &&
                <div css={containerStyles}>
                    <div>
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE &&
                            <Popup 
                                content='Collectivité locale : Les collectivités locales sont des emprunteurs publics très contrôlés qui présentent un risque de défaut faible. Pour en savoir, rendez vous sur notre page “Risques”.' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: " #16586F 1px solid", 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: "#BEE4F2", 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: "1px solid #16586F",
                                                marginRight: "0 !important",
                                                color: "#16586F"
                                            }}>
                                                J{daysUntilEnd}
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.CHU &&
                            <Popup 
                                content='Centre hospitalier : Les centres hospitaliers sont des emprunteurs publics contrôlés qui présentent un risque de défaut relativement faible. Pour en savoir, rendez vous sur notre page “Risques”.' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: " #16586F 1px solid", 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: "#BEE4F2", 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: "1px solid #16586F",
                                                marginRight: "0 !important",
                                                color: "#16586F"
                                            }}>
                                                J{daysUntilEnd}
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                }
                            />
                        }
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.SOCIETE &&
                            <Popup 
                                content='Société à impact : Les sociétés à impact sont des entreprises privées qui entrainent un impact positif sur le développement des territoires. Elles sont plus risquées que des collectivités ou des centres hospitaliers.' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: " #16586F 1px solid", 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: "#BEE4F2", 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: "1px solid #16586F",
                                                marginRight: "0 !important",
                                                color: "#16586F"
                                            }}>
                                                J{daysUntilEnd}
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                    </div>
                </div>
            }

            {(statutsClotures.find(status => status === statut)) && categorieEtablissementCode && 
            (categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE || categorieEtablissementCode === CategorieEtablissement.CHU || 
            categorieEtablissementCode === CategorieEtablissement.SOCIETE ) &&
                <div css={containerStyles}>
                    <div>
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE &&
                            <Popup 
                                content='Collectivité locale : Les collectivités locales sont des emprunteurs publics très contrôlés qui présentent un risque de défaut faible. Pour en savoir, rendez vous sur notre page “Risques”.' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: " #155724 1px solid", 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: "#C3E6CB", 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: "1px solid #155724",
                                                marginRight: "0 !important",
                                                color:"#155724"
                                            }}>
                                                Clôturé
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.CHU &&
                            <Popup 
                                content='Centre hospitalier : Les centres hospitaliers sont des emprunteurs publics contrôlés qui présentent un risque de défaut relativement faible. Pour en savoir, rendez vous sur notre page “Risques”.' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: " #155724 1px solid", 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: "#C3E6CB", 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: "1px solid #155724",
                                                marginRight: "0 !important",
                                                color:"#155724"
                                            }}>
                                                Clôturé
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.SOCIETE &&
                            <Popup 
                                content='Société à impact : Les sociétés à impact sont des entreprises privées qui entrainent un impact positif sur le développement des territoires. Elles sont plus risquées que des collectivités ou des centres hospitaliers' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: " #155724 1px solid", 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: "#C3E6CB", 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: "1px solid #155724",
                                                marginRight: "0 !important",
                                                color:"#155724"
                                            }}>
                                                Clôturé
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                    </div>
                </div>
            }

            {statut === StatutProjet.FINANCE && categorieEtablissementCode && 
            (categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE || 
            categorieEtablissementCode === CategorieEtablissement.CHU || 
            categorieEtablissementCode === CategorieEtablissement.SOCIETE ) &&
                <div css={containerStyles}>
                    <div>
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.COLLECTIVITE &&
                            <Popup 
                                content='Collectivité locale : Les collectivités locales sont des emprunteurs publics très contrôlés qui présentent un risque de défaut faible. Pour en savoir, rendez vous sur notre page “Risques”.' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: " #155724 1px solid", 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: "#C3E6CB", 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: "1px solid #155724",
                                                marginRight: "0 !important",
                                                color:"#155724"
                                            }}>
                                                Succès
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.CHU &&
                            <Popup 
                                content='Centre hospitalier : Les centres hospitaliers sont des emprunteurs publics contrôlés qui présentent un risque de défaut relativement faible. Pour en savoir, rendez vous sur notre page “Risques”.' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: " #155724 1px solid", 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: "#C3E6CB", 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: "1px solid #155724",
                                                marginRight: "0 !important",
                                                color:"#155724"
                                            }}
                                        >
                                            Succès
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        }
                        {categorieEtablissementCode && categorieEtablissementCode === CategorieEtablissement.SOCIETE &&
                            <Popup 
                                content='Société à impact : Les sociétés à impact sont des entreprises privées qui entrainent un impact positif sur le développement des territoires. Elles sont plus risquées que des collectivités ou des centres hospitaliers' 
                                trigger={
                                    <div css={{
                                        display:"flex", 
                                        border: " #155724 1px solid", 
                                        borderRadius: "5px",
                                        fontSize:"14px",
                                        fontWeight: "bold", 
                                        lineHeight: "1em",
                                    }}>
                                        <span 
                                            css={{
                                                background: "#C3E6CB", 
                                                padding: "5px 10px 5px 10px", 
                                                borderRadius: "5px 0 0 5px", 
                                                borderRight: "1px solid #155724",
                                                marginRight: "0 !important",
                                                color:"#155724"
                                            }}>
                                                Succès
                                        </span>
                                        <span css={{
                                            background: "#ffffff", 
                                            padding: "5px 10px 5px 10px", 
                                            borderRadius: "0 5px 5px 0", 
                                            color:"#000000 !important",
                                        }}>
                                            {categorieEtablissementLibelle}
                                        </span>
                                    </div>
                                } 
                            />
                        } 
                    </div>
                </div>
            }

            {statut === StatutProjet.SUSPENDED && 
                <div css={containerStyles}>
                    <div css={{
                        background: "#E4E4E4",
                        color: "#5B6269",
                        padding: "5px 10px 5px 10px",
                        borderRadius: "5px",
                        fontWeight: "bold"
                    }}>
                        Différée
                    </div>
                </div>
            }
        </>
    )}