export interface ModePaiement {
    Code?: string,
    Libelle?: string
}

const CARTE_BANCAIRE = "CB"
const VIREMENT_CLASSIQUE = "VC"
const INITIATION_VIREMENT = "IV"
const REINVESTISSEMENT_WALLET = "RW"

export const ModePaiement = {
    CARTE_BANCAIRE,
    VIREMENT_CLASSIQUE,
    INITIATION_VIREMENT,
    REINVESTISSEMENT_WALLET
}