export interface StatutEcheance {
    Code?: string,
    Libelle?: string
}

const PREVUE = "PRE"
const INITIEE = "INI"
const VALIDEE = "VAL"
const ERREUR = "ERR"

export const StatutEcheance = {
    PREVUE,
    INITIEE,
    VALIDEE,
    ERREUR
}