import React from "react"
import { Tab } from 'semantic-ui-react'
import { ActiviteMangoPayDashboard } from './tab/activite-mangopay-dashboard'
import { ParametresAdmin } from "./tab/parametres-admin"
import { DeclarationImpots } from "./tab/declaration-impots"
import { UploadFichierReference} from "./tab/upload-fichier-referentiel"
import { ActiviteTrueLayerDashboard } from "./tab/activite-truelayer-dashboard"
import { Utilisateurs } from "./tab/utilisateurs"
import { ReportingInvestisseurs } from "./tab/reporting-investisseurs"
import { ReportingUtilisateurs } from "./tab/reporting-utilisateurs"
import { ReportingKYC } from "./tab/reporting-kyc"
import { Reporting } from "./tab/reporting"
import { TraitementTrueLayerDashboard } from "./tab/traitement-truelayer-dashboard"
import { TraitementMangoPayDashboard } from "./tab/traitement-mangopay-dashboard"
import { ParametresBanquesDashboard } from "./tab/parametres-banques-dashboard"

const panes = [
  {
    menuItem: 'Activité MangoPay',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <ActiviteMangoPayDashboard />
            </Tab.Pane>
        )
    },
  },
  {
    menuItem: 'Traitement MangoPay',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <TraitementMangoPayDashboard />
            </Tab.Pane>
        )
    },
  },
  {
    menuItem: 'Activité TrueLayer',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <ActiviteTrueLayerDashboard />
            </Tab.Pane>
        )
    },
  },
  {
    menuItem: 'Traitement TrueLayer',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <TraitementTrueLayerDashboard />
            </Tab.Pane>
        )
    },
  },
  {
    menuItem: 'Paramètres admins',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <ParametresAdmin />
            </Tab.Pane>
        )
    },
  },
  {
    menuItem: 'Déclaration impots',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <DeclarationImpots />
            </Tab.Pane>
        )
    },
  },
  {
    menuItem: 'Paramètres banques',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <ParametresBanquesDashboard />
            </Tab.Pane>
        )
    },
  },
  {
    menuItem: 'Utilisateurs',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <Utilisateurs />
            </Tab.Pane>
        )
    },
  },
  {
    menuItem: 'Reporting',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <Reporting />
            </Tab.Pane>
        )
    },
  },
  {
    menuItem: 'Reporting Investisseurs',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <ReportingInvestisseurs />
            </Tab.Pane>
        )
    }
  },
  {
    menuItem: 'Reporting Utilisateurs',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <ReportingUtilisateurs />
            </Tab.Pane>
        )
    },
  },
  {
    menuItem: 'Reporting KYC',
    render: () => {
        return (
            <Tab.Pane attached={false} css={{
                background: 'transparent !important',
                border: 'none !important'
            }}>
                <ReportingKYC />
            </Tab.Pane>
        )
    },
  }
]

const Tabs = () => (
  <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
)

type Props = {

}

export function DashboardAdminDetail({}: Props) {
    return (
        <>
            <div 
                className="container page-header" 
                css={{
                    marginTop: '80px'
                }}
            >
                Dashboard admin
            </div>
            <Tabs />
        </>
    )
}