import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Form, Grid, Icon, Segment } from "semantic-ui-react"
import { TrueLayerConfigBody } from "../../../model/dto/body/truelayer-config-body"
import { BankProvider } from "../../../model/entities/bank-provider"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { investmentService } from "../../../services/investment-service"
import { ApplicationState } from "../../../store"
import { CheckBoxSimpleField } from "../../generic/checkbox-simple-field/checkbox-simple-field"
import { SubmitButton } from "../../generic/submit-button"
import { BankProviderSelector } from "../../investment/bank-provider-selector"
import { DropdownElement, DropdownListFieldNew } from "../../generic/dropdown-list-field"

type Props = {

}

type FieldData = {
    value?: any;
    name?: string;
    isValid?: boolean;
};

type Form = {
    isProviderActive: FieldData,
    selectedRealBank: FieldData
}

export function ParametresBanquesDashboard({}: Props) {
    const [selectedProvider, setSelectedProvider] = useState<BankProvider | undefined>()
    const [bankProviderList, setBankProviderList] = useState<BankProvider[]>([])
    const [isValidationDisplayed, setIsValidationDisplayed] = useState<boolean>(false)
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
    const [reload, setReload] = useState<boolean>(true)
    const [form, setForm] = useState<Form>({
        isProviderActive: {value: false, name: 'isProviderActive', isValid: true},
        selectedRealBank: {value: false, name: 'banque', isValid: true}
    })

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(() => {
        if(reload) {
            investmentService.getAllTruelayerProviders(loginProps?.oauth)
            .then(response => {
                if (response.IsTraitementOk) {
                    setBankProviderList(response.BankList!)
                    setReload(false)
                }
            })
        }
    }, [reload])

    const realBanks = [
        { value: 'stet-caisse-d-epargne', text: 'Caisse d\'Épargne' },
        { value: 'stet-banque-postale', text: 'Banque Postale' },
        { value: 'stet-credit-agricole', text: 'Crédit Agricole' },
        { value: 'stet-banque-populaire', text: 'Banque Populaire' }
    ];
      
    const onSelectProvider = (provider?: BankProvider) => {
        setSelectedProvider?.(provider)
        setForm({...form, isProviderActive: {value: provider?.IsActive, name: 'isProviderActive', isValid: true}})
        setIsValidationDisplayed(false)
        setReload(true)
    }

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        fieldName?: string,
        isValid?: boolean
      ): void => {
        const fieldValue: string = e.target.value;
    
        if (fieldName) {
          const newField: FieldData = {
            [fieldName]: { value: fieldValue, name: fieldName, isValid: isValid },
          };
          setForm({ ...form, ...newField });
        }
    };

    const onFormSubmitSuccess = () => {
        setIsValidationDisplayed(true)
    }

    const validateMandatory = (value: any)=>{
        return value && value != ""
   }

    const body: TrueLayerConfigBody = {
        ProviderId: selectedProvider?.ProviderId,
        IsActive: form.isProviderActive.value,
        SelectedRealBank: form.selectedRealBank.value
    }
    
    return (
        <div>
            <h1>Paramètres banques</h1>
            <Grid columns={2}>
                <Grid.Row>
                <Grid.Column>
                    <Grid columns={1}>
                        {bankProviderList.map(bankProvider => {
                            return (
                                <Grid.Column css={{
                                    paddingTop: "0px !important",
                                    paddingBottom: "0px !important",
                                    paddingRight: "0px !important"
                                }}>
                                    <BankProviderSelector
                                        provider={bankProvider}
                                        selectedProvider={selectedProvider}
                                        onSelectProvider={onSelectProvider}
                                    />
                                </Grid.Column>
                            )
                        })}
                    </Grid>
                </Grid.Column>
                <Grid.Column>
                    {selectedProvider && (
                        <div>
                            {isValidationDisplayed && (
                                <Segment css={{position:"relative", border:"2px solid #BED3AF !important", backgroundColor:"#E0F3D4 !important", display:"flex", flexDirection:"row", alignItems:"center"}}>
                                    <div css={{flexBasis: "10%", alignSelf:"flex-start"}}>
                                    <Icon name="check" css={{color:"#5D7150"}} size="huge"/>
                                    </div>
                                    <div css={{flexBasis: "90%"}}>
                                    <p 
                                        css={{
                                        color:"#5D7150", 
                                        fontSize:"16px", 
                                        fontWeight:"bold"}}
                                    >
                                        Paramétrage sauvegardé
                                    </p>
                                    </div>
                                </Segment>
                            )}
                            <div>{selectedProvider.DisplayName}</div>
                            <CheckBoxSimpleField
                                data={form.isProviderActive}
                                label="Cette banque est utilisable avec Truelayer"
                                onChange={(value: any, isValid: boolean) => {
                                    handleInputChange(
                                    { target: { value } } as any,
                                    form.isProviderActive.name,
                                    isValid
                                    );
                                }}
                            />
                            <div css={{marginTop: '15px'}}>
                                <Form>
                                    <Form.Field>
                                        <DropdownListFieldNew
                                            datasource={realBanks.map(bank => {
                                                return {
                                                    text: bank.text,
                                                    value: bank.value
                                                } as DropdownElement
                                            })}
                                            field={form.selectedRealBank}
                                            label="Sélectionnez une banque réelle :"
                                            onChange={(value: any) => {
                                                setForm({...form, selectedRealBank: {name: 'banque', value: value, isValid: true}})
                                            }}
                                            placeholder="Choisir une banque"
                                            maxWidth={200}
                                            submitted={formSubmitted}
                                            validateField={validateMandatory}
                                            error="Veuillez choisir une banque"
                                        />
                                    </Form.Field>
                                </Form>
                            </div>
                        </div>
                    ) || (
                        <div>
                        </div>
                    )}
                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <SubmitButton
                            label="Valider"
                            action={() => investmentService.saveTruelayerConfigForProvider(body, loginProps?.oauth)}
                            onActionSuccess={onFormSubmitSuccess}
                            onPreSubmit={() => setFormSubmitted(true)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}  