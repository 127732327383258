import React from "react";
import {
  useParams
} from "react-router-dom";
import { InvestFormV2 } from "../components/investment/invest-form-v2";

type Params = {
  projetId?: string
}

export function ProjetInvestRouterV2() {
    let { projetId } = useParams<Params>();
    let id: number = projetId ? +projetId : 0
    return (
      <InvestFormV2 projetId={id} isDon={false} />
    );
}