import React from "react";
import { SubscriptionIdentiteRepresentantForm } from "../subscription-identite-representant-form";
import { SubscriptionCollectiviteAdresses } from "../subscription-collectivite-adresses";
import { OnboardingInvestisseurMoralPorteurProjetDocumentListForm } from "../onboarding-investisseur-moral-porteur-projet-document-list-form";
import { SubscriptionCollectiviteFormEnding } from "../subscription-collectivite-form-ending";
import { FlowStep } from "../../../model/entities/flow-step";
import { WorkflowType } from "../../../model/entities/workflow-type";
import { MultipleFileRegistrationResponse, OnboardingCollectiviteResponse } from "../../../model/dto/response";

type OnboardingOrganisationProps = {
    activeStepKey: string,
    lastStepResponse?:OnboardingCollectiviteResponse,
    onSubmitVerification: (response: OnboardingCollectiviteResponse) => void,
    onSubmitPiecesJustificatives: (response: MultipleFileRegistrationResponse) => void,
    onNextStep: (response: Response) => void
}

export function OnboardingOrganisation({activeStepKey, lastStepResponse, onSubmitVerification, onSubmitPiecesJustificatives, onNextStep}: OnboardingOrganisationProps){
    if(activeStepKey === FlowStep.IDENTITE_REPRESENTANT_LEGAL) {
        return (
            <div>
                <SubscriptionIdentiteRepresentantForm
                    isOnboarding={true}
                    onSubmitSuccess={onSubmitVerification}
                    onSkipStep={onNextStep}
                    workflowTypeCode={WorkflowType.ONBOARDING_PORTEUR_ORGANISATION}
                />
            </div>
        )
    }

    if(activeStepKey === FlowStep.ADRESSES) {
        return (
            <div>
                <SubscriptionCollectiviteAdresses
                    onSubmitSuccess={onSubmitVerification}
                    onSkipStep={onNextStep}
                    workflowTypeCode={WorkflowType.ONBOARDING_PORTEUR_ORGANISATION}
                    lastStepResponse={lastStepResponse}
                />
            </div>
        )
    }

    if(activeStepKey === FlowStep.PIECES_JUSTIFICATIVES) {
        return (
            <div>
                <OnboardingInvestisseurMoralPorteurProjetDocumentListForm 
                    onActionSuccess={onSubmitPiecesJustificatives}
                    onSkipStep={onNextStep}
                    isOnboarding={true}
                    workflowTypeCode={WorkflowType.ONBOARDING_PORTEUR_ORGANISATION}
                />
            </div>
        )
    }

    if(activeStepKey === FlowStep.COMPLET) {
        return (
            <div>
                <SubscriptionCollectiviteFormEnding />
            </div>
        )
    }

    return (
        <div></div>
    )
}
