import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { MangoPayEventHistory } from "../../../model/entities/mangopay-event-history"
import { mangopayService } from "../../../services/mangopay-service"
import { ApplicationState } from "../../../store"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { PaginatedTable, ElementDescriptor } from "../../generic/paginated-table/paginated-table"
import { MangoPaySimpleHookMessage } from "../../../model/entities/mangopay-simple-hook-message"

type Props = {

}

export function ActiviteMangoPayDashboard({}: Props) {
    const[simpleHookMessages, setSimpleHookMessages] = useState<MangoPaySimpleHookMessage[]>([])

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(() => {
        mangopayService.getMangoPayEventsHistory(loginProps?.oauth)
        .then(response => {
            setSimpleHookMessages(response.History!)
        })
    }, [])

    return (
        <>
            <PaginatedTable 
                elements={simpleHookMessages}
                elementsPerPage={10}
                headers={
                    [
                        'Id',
                        'Date de réception', 
                        'Evènement reçu', 
                        'Id de la ressource MangoPay', 
                        'Nombre d\'échecs', 
                        'Date du dernier traitement'
                    ]
                }
                columnSelector={(element: MangoPaySimpleHookMessage) => {
                    return {
                        values: [
                            element.Id,
                            element.DateReceived,
                            element.HookEvent,
                            element.ResourceId,
                            element.ErrorCount,
                            element.DateLastTreated
                        ]
                    }
                }}
            />
        </>
    )
}