import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Container, Icon } from "semantic-ui-react"

type Props = {

}

export function Empty({}: Props) {
    const navigate = useNavigate()
    return (
        <Container css={{
            marginTop: '80px'
        }}>
            <h1>
                Aucun contenu
            </h1>
            <div css={{
                marginTop: '30px',
                textAlign: 'center'
            }}>
                <Icon
                    name="exclamation triangle"
                    color="purple"
                    size="massive"
                />
                <div css={{
                    fontSize: '15px',
                    display: 'inline-block'
                }}>
                    <div>
                        Il n'y a rien à l'URL recherchée
                    </div>
                </div>
            </div>
            <div css={{
                marginTop: '20px',
                textAlign: 'center'
            }}>
                <div>
                    <Button color="blue" size="big" onClick={() => navigate(`/`)}>
                        Retour à l'accueil
                    </Button>
                </div>
            </div>
        </Container>
    )
}