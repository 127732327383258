import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AdresseMailRegistrationBody } from "../../model/dto/body/adresse-mail-registration-body";
import { AuthenticationState } from "../../model/state/authentication-state";
import { userService } from "../../services/authentication-redux-service";
import { ApplicationState } from "../../store";
import { Field } from "../generic/field";
import { SubmitButton } from "../generic/submit-button";

type Props = {
  userId?: number;
  onSubmitSuccess: (response: Response) => void
}

type FieldData = {
  value?: any,
  name?: string,
  isValid?: boolean
};

type FormData = {
  Mail: FieldData,
}

export function ChangeMailFormAdmin({
  userId,
  onSubmitSuccess
}: Props) {
  const loginProps: AuthenticationState = useSelector<
        ApplicationState,
        AuthenticationState
    >((state) => state.authentication);
    
  const [form, setForm] = useState<FormData>({
    Mail : { name : "Mail", value : "", isValid: true},
  })
  const [isFormError, setIsFormError] = useState(false)
  const [mailError, setMailError] = useState(false)
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fieldValue: string = e.target.value;
    const fieldName = e.target.name;
    const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName, isValid: true } };
  
    setForm({ ...form,  ...newField});
    setIsFormError(false)
}

  const handleMailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setMailError(false)
    handleInputChange(e)
  }
   
  const body: AdresseMailRegistrationBody = {
    Mail : form.Mail.value,
    UserId : userId!
}

  return (
    <div>
    <Field 
        className="form-group"
        fieldName="Mail"
        label="Nouvelle adresse mail"
        value={form.Mail.value}
        onChange={handleMailChange}
    />
    <div className="form-group">
        Attention, une fois déconnecté l'utilisateur devra utiliser cette nouvelle adresse pour se connecter. il faut aussi valider cette adresse avec le mail automatique qui lui sera adressée.
    </div>
    <SubmitButton 
        data={body}
        label="Valider"
        action={() => userService.registerMailByAdmin(body, loginProps?.oauth?.userId, loginProps?.oauth)}
        onActionSuccess={onSubmitSuccess}
    />
</div>
  );
}

 