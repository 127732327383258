import React, { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { FormErrorHeader } from '../../generic/form-error-header'
import { CheckBoxElement, CheckBoxListField } from '../../generic/checkbox-list-field'
import { TypeCollecte } from '../../../model/entities/type-collecte'
import { trackPromise } from 'react-promise-tracker'
import { TypeCollecteListResponse } from '../../../model/dto/response'
import { referenceService } from '../../../services/reference-service'
import { SubmitButton } from '../../generic/submit-button'
import { projetService } from '../../../services/projet-service'
import { ProjetTypeCollecteBody } from '../../../model/dto/body/projet-type-collecte-body'
import Projet from '../../../model/projet'
import { AuthenticationState } from '../../../model/state/authentication-state'

type Props = {
    isEditing?: boolean,
    projet: Projet,
    loginProps?: AuthenticationState,
    isPageDon?: boolean,
    onSubmitSuccess?: (response: Response) => void,
}

type FieldData = {
    value?: any,
    name?: string,
    isValid?: boolean
}

type Form = {
    typeCollecte: FieldData,
}

// I need to call the workflowType
// I need an arrat for type of collecte


export default function SelectTypeCollecteForm({isEditing, projet, loginProps, isPageDon, onSubmitSuccess}: Props) {
    const [isFormError, setIsFormError] = useState(false)
    const [typeCollecteList, setTypeCollecteList] = useState<TypeCollecte[]>([])
    const [form, setForm] = useState<Form>({
        typeCollecte: {value: typeCollecteList ? typeCollecteList.map(type => {return type.Code}) : [], name: 'typeCollecte', isValid: true},
    })
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    
    useEffect(()=> {
        trackPromise(referenceService.getAllTypeOfCollecte()
        .then((response: TypeCollecteListResponse) => {
            setTypeCollecteList(response.TypeCollecteList!)
        }))
    },[])

    const validateCheckboxList = (keys: any[]): boolean => {
        return keys.length !== 0
    }

    const isFormValid = (): boolean => {
        return validateCheckboxList(form.typeCollecte.value)
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
    }

    const onPreSubmit = () : void => {
        setIsFormSubmitted(true)
    }

    const body: ProjetTypeCollecteBody= isEditing && isPageDon ? {
        TypeCollecteCode: form.typeCollecte.value,
        IsPageDonCombine: isPageDon && form.typeCollecte.value === TypeCollecte.COMBINAISON_EMPRUNT_DON,
        IsEditing: isEditing
    }
 :  {
        TypeCollecteCode: form.typeCollecte.value,
        IsEditing: isEditing
    }


    const infoDeleteDon = <p>Une page projet pour un emprunt citoyen va être créée, <span style={{fontWeight:'bold'}}>attention, votre projet contient déjà une page mécénat d'entreprise qui ne sera plus visible si vous validez ce choix.</span></p>
    const infoDeleteEmprunt = <p>Une page projet pour le mécénat d'entreprise va être créée, <span style={{fontWeight:'bold'}}>attention, votre projet contient déjà une page emprunt citoyen qui ne sera plus visible si vous validez ce choix.</span></p>
    const addEmpruntPageInfo = <p>Une page projet pour l'emprunt citoyen va être créée</p>
    const addDonPageInfo = <p>Une page projet pour l'emprunt citoyen va être créée</p>
    const combinedInfo = <p>Une page projet pour le mécénat d'entreprise et une autre pour l'emprunt citoyen vont être créées. Ces pages seront liées</p>

    const returnEditingInfos = (value: string) => {
        if(value === projet.TypeCollecteCode){
            return
        }
        else{
            if(value === TypeCollecte.COMBINAISON_EMPRUNT_DON){
                if(!projet.TypeCollecteCode || projet.TypeCollecteCode === null){
                    return combinedInfo
                }else {
                    if(projet.TypeCollecteCode === TypeCollecte.DON){
                        return addDonPageInfo
                    }
                    else if(projet.TypeCollecteCode === TypeCollecte.EMPRUNT_CITOYEN){
                        return addEmpruntPageInfo
                    }
                    else return ''
                }   
            }
            if(value === TypeCollecte.EMPRUNT_CITOYEN){
                if(projet.TypeCollecteCode === TypeCollecte.DON || projet.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON){
                    return infoDeleteDon
                }
                return ''
            }
            if(value === TypeCollecte.DON){
                if(projet.TypeCollecteCode === TypeCollecte.EMPRUNT_CITOYEN || projet.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON){
                    return infoDeleteEmprunt
                }
                return ''
            }
        }
    }

  return (
    <Form>
          <FormErrorHeader 
                message="Veuillez corriger les champs en erreur"
                displayed={isFormError}
            />
            <h3>Veuillez indiquer le type de collecte pour votre projet</h3>
            <CheckBoxListField
                data={form.typeCollecte}
                label="Type de collecte"
                submitted={isFormSubmitted}
                elements={(typeCollecteList && typeCollecteList.map(type => {
                    return {
                        key: type.Code,
                        label: type.Libelle
                    } as CheckBoxElement
                })) || []}
                onChange={(key: string, isChecked?: boolean) => {
                    if(isChecked) {
                        setForm({typeCollecte: {value: key, name: 'typeCollecte', isValid: true}})
                        setIsFormError(false)
                    }
                    else {
                        const { value } = form.typeCollecte
                        const index = value.indexOf(key)
                        if(index !== -1) {
                            value.splice(index, 1)
                        }
                        setForm({typeCollecte: {value: value, name: 'typeCollecte', isValid: value.length !== 0}})
                    }
                }}
                validateFieldMultiple={validateCheckboxList}
                error="Merci de renseigner le type de collecte pour votre projet"
                vertical
             />
             <div css={{
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }} 
                className="button-bar">
                    <SubmitButton 
                        data={body}
                        label="Valider"
                        action={() => projetService.saveTypeCollecteProjet(body, loginProps?.oauth, projet && projet.Id)}
                        onActionSuccess={onSubmitSuccess}
                        onFormNotValid={onFormNotValid}
                        validateForm={isFormValid}
                        onPreSubmit={onPreSubmit}
                    />
                </div>
                {isEditing && 
                <div>
                   {returnEditingInfos(form.typeCollecte.value)}
                </div>}
    </Form>
  )
}
