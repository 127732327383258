import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { ApplicationState } from "../../store"
import { AuthenticationState } from "../../store/authentication/types"
import { Form, TextAreaProps } from "semantic-ui-react"
import { CheckBoxElement, CheckBoxListField } from "../generic/checkbox-list-field"
import Projet from "../../model/projet"
import { ActionVillyzProjet } from "../../model/entities/action-villyz-projet"
import { referenceService } from "../../services/reference-service"
import { ActionVillyzProjetListResponse, DefaultResponse } from "../../model/dto/response"
import { SubmitButton } from "../generic/submit-button"
import { projetService } from "../../services/projet-service"
import { FormErrorHeader } from "../generic/form-error-header"
import { AnalyseVillyzBody } from "../../model/dto/body/analyse-villyz-body"
import { LoadingSpinner } from "../generic/loading-spinner"
import { FileUpload } from "../generic/file-upload"
import { DocumentFinance } from "../../model/entities/document-finance"
import { AnalyseVillyzMultipleDocuments } from "../../model/dto/body/analyse-villyz-multiple-document-body"
import { TypePieceFinance } from "../../model/entities/type-piece-finance"
import { StatutProjet } from "../../model/entities/statut-projet"
import { SimpleButton } from "../generic/simple-button";
import { TypeEmprunt } from "../../model/entities/type-emprunt"
import { InputField } from "../generic/input-field"
import { LexicalInputArea } from "../generic/lexical-input-area"
import { LexicalEnvContext } from "../../contexts"

type Props = {
    loginProps?: AuthenticationState,
    projet: Projet,
    isPageDon?: boolean,
    onSubmitSuccess: (response: Response) => void
}

type FieldData = {
    name?: string,
    value?: any,
    isValid?: boolean
}

type FormData = {
    actionVillyz: FieldData,
    tauxCommission: FieldData,
    analysePieceList : DocumentFinance[],
}

type Form = {
    analyseRisque: FieldData,
}

const EtapePossible = {
    Document : "Document",
    Information : "Information" 
}

const statutProjetCode = [
    StatutProjet.PUBLIE,
    StatutProjet.BIENTOT_DISPONIBLE,
    StatutProjet.CLOTURE,
    StatutProjet.DEBOUCLAGE_DEMANDE,
    StatutProjet.DEBOUCLAGE_VALIDE,
    StatutProjet.DEBOUCLAGE_POSSIBLE,
    StatutProjet.DEBOUCLAGE_FINALISE,
    StatutProjet.FINANCE,
    StatutProjet.SUSPENDED
]

function AnalyseFinanciereForm({loginProps, projet, isPageDon, onSubmitSuccess}: Props) {
    const tauxCommisionValue = isPageDon ? projet?.TauxCommissionDon : projet?.TauxCommission
    const analyseRisqueValue = isPageDon ? projet?.CritereSelectionDon : projet?.AnalyseRisque
    const actionVillyzValue = isPageDon ? projet?.ActionVillyzDonCode : projet?.ActionVillyzCode
    const editorStateValue = isPageDon ? projet.CritereSelectionDonLexicalState ?? "" : projet.AnalyseRisqueLexcicalEditorState ?? ""

    const [form, setForm] = useState<FormData>({
        analysePieceList: [],
        tauxCommission: {name: 'tauxCommission', value: tauxCommisionValue, isValid: true},
        actionVillyz: {name: 'actionVillyz', value: actionVillyzValue, isValid: true},
    })
    
    const [wysiwygForm, setWysiwygForm] = useState<Form>({
        analyseRisque: {name: 'analyseRisque', value: analyseRisqueValue, isValid: true}
    })

    const [file, setFile] = useState<FieldData | undefined>(undefined)

    const [actionVillyzList, setActionVillyzList] = useState<ActionVillyzProjet[]>([])

    const [isFormError, setIsFormError] = useState(false)

    const [isFormDocumentSubmitted, setIsFormDocumentSubmitted] = useState(false)
    const [isFormInfoSubmitted, setIsFormInfoSubmitted] = useState(false)

    const [pane, setPane] = useState(EtapePossible.Document)

    const [editorState, setEditorState] = useState(editorStateValue)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName, isValid: isValid } };
      
        setForm({ ...form,  ...newField});
        setIsFormError(false)
      }
    
    useEffect(() => {
        referenceService.getAllActionVillyzProjet(loginProps?.oauth)
        .then(response => {
            return response.json() as Promise<ActionVillyzProjetListResponse>
        })
        .then(response => {
            if(response.IsTraitementOk) {
                setActionVillyzList(response.ActionVillyzProjetsList!)
            }
        })
    }, [])

    const validateMandatory = (value: any): boolean => {
        return value && value !== ""
    }

    const validateHTMLInput = (value: String): boolean => {
        return(!(value==="<p><br></p>"))
    }

    const isFormValid = (): boolean => {
        return validateMandatory(form.actionVillyz.value)
            && validateMandatory(wysiwygForm.analyseRisque.value)
    }

    const validateForm = () => {
        if(isPageDon){
            if(statutProjetCode.includes(projet.StatutDonProjetCode!)){
                return form.analysePieceList.length > 0;
            }
            else{
                return form.analysePieceList.length === 3;
            }
        }else {
            if(statutProjetCode.includes(projet.StatutProjetCode!)){
                return form.analysePieceList.length > 0;
            }
    
            if(projet.TypeEmpruntCode === TypeEmprunt.TITRES){
                return form.analysePieceList.length === 5;
            }
            else{
                return form.analysePieceList.length === 4;
            }
        }
       
      }

    const handleInputAreaChange = (props: TextAreaProps, state: string, fieldName?: string, isValid?: boolean): void => {
        const fieldValue = props.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
        setWysiwygForm({ ...wysiwygForm,  ...newField});
        setEditorState(state)
        setIsFormError(false)
    }

    const validateDocument = (file? : File) => {
        if(!file) {
            return false
        }

        const acceptedExtensions = ["pdf"];
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        if (!acceptedExtensions.includes(fileExtension!)) {
            return false
        }

        return true
    }

    const validateTemplate = (file?: File) => {
        if(!file) {
            return false
        }

        const acceptedExtensions = ["html"];
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        if (!acceptedExtensions.includes(fileExtension!)) {
            return false
        }

        return true
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, isVerso?: boolean, isAlreadyUploaded?: boolean) => {
        const element = e.target;
        if (element.files && element.files[0] && validateDocument(element.files[0])) {
            setFile({name : "fichierAnalyse", value : element.files[0], isValid : true});
        }
    }
    
    const onChangePiece = (e: React.ChangeEvent<HTMLInputElement>) => {
        const element = e.target as HTMLInputElement;
        if (element.files) {
            if(element.name === TypePieceFinance.ANALYSE_VILLYZ) {
            const analysePiece:
            DocumentFinance = {
              Document: element.files[0],
              TypePiece: TypePieceFinance.ANALYSE_VILLYZ,
              projetId: projet.Id,
            };
            setForm({...form, analysePieceList : [...form.analysePieceList, analysePiece ]});
            } else {
            const analysePiece:
            DocumentFinance = {
                Document: element.files[0],
                TypePiece: TypePieceFinance.INFORMATIONS_CLE,
                projetId: projet.Id,
            };
            setForm({...form, analysePieceList : [...form.analysePieceList, analysePiece]});
            } 
            setIsFormError(false)
        }
        if (isFormDocumentSubmitted) {
            validateForm();
        }
    }
    const onChangeSecondPiece = (e: React.ChangeEvent<HTMLInputElement>) => {
        const element = e.target as HTMLInputElement;
        if (element.files) {
            if(element.name === TypePieceFinance.CONVENTION_MANDAT)  {
                const analysePiece:
                DocumentFinance = {
                    Document: element.files[0],
                    TypePiece: TypePieceFinance.CONVENTION_MANDAT,
                    projetId: projet.Id,
                };
                setForm({...form, analysePieceList : [...form.analysePieceList, analysePiece]});
            } 
            else if(element.name === TypePieceFinance.EXTRAIT_JUDICIAIRE) {
                const analysePiece:
                DocumentFinance = {
                    Document: element.files[0],
                    TypePiece: TypePieceFinance.EXTRAIT_JUDICIAIRE,
                    projetId: projet.Id,
                };
                setForm({...form, analysePieceList : [...form.analysePieceList, analysePiece]});
            }
            else if(element.name === TypePieceFinance.TEMPLATE_HTML_CONTRAT) {
                const analysePiece:
                DocumentFinance = {
                    Document: element.files[0],
                    TypePiece: TypePieceFinance.TEMPLATE_HTML_CONTRAT,
                    projetId: projet.Id,
                };
                setForm({...form, analysePieceList : [...form.analysePieceList, analysePiece]});
            }
            setIsFormError(false)
        }
        if (isFormDocumentSubmitted) {
            validateForm();
        }
    }

   
    const onFormNotValid = (): void => {
        setIsFormError(true)
    }

    const onPreSubmit = () : void => {
        if (pane === EtapePossible.Document) {
            setIsFormDocumentSubmitted(true)
        } else {
            setIsFormInfoSubmitted(true)
        }
    }

    const onSubmitError = (exception : any): void => {
        console.log(exception.message)
    }

    const onSubmitSuccessDocument = (): void => {
        setPane(EtapePossible.Information)
    }

    const bodyInfo: AnalyseVillyzBody = isPageDon ? {
        ActionVillyzCode: form.actionVillyz.value,
        AnalyseRisque: wysiwygForm.analyseRisque.value,
        TauxCommission: form.tauxCommission.value,
        LexicalEditorState: editorState,
        IsEmprunt : false
    } : 
    {
        ActionVillyzCode: form.actionVillyz.value,
        AnalyseRisque: wysiwygForm.analyseRisque.value,
        TauxCommission: form.tauxCommission.value,
        LexicalEditorState: editorState,
        IsEmprunt : true
    }

    const bodyDocument : AnalyseVillyzMultipleDocuments= {
        analysePieceList : form.analysePieceList,
    }
    
    return ( <>
    {pane === EtapePossible.Document && (
        <Form>
            <LoadingSpinner area="modal">
                <FormErrorHeader 
                    message="Veuillez corriger les champs en erreur"
                    displayed={isFormError}
                />
                <Form.Field>
                    {!isPageDon && 
                    <FileUpload 
                        name={TypePieceFinance.INFORMATIONS_CLE}
                        libelle="Fiche d'informations clé (document pdf)" 
                        isLimited={true} 
                        submitted = {isFormDocumentSubmitted}
                        onChange={onChangePiece}
                        validateField={validateDocument}
                /> }
                <FileUpload 
                    name={TypePieceFinance.ANALYSE_VILLYZ}
                    libelle="Analyse Financière Villyz (document pdf)" 
                    isLimited={true} 
                    submitted = {isFormDocumentSubmitted}
                    onChange={onChangePiece}
                    validateField={validateDocument}
                />  
                <h2>Documents Justificatifs </h2>
                <hr css={{borderTop:"4px dimgrey"}}/>
                <FileUpload 
                    name={TypePieceFinance.CONVENTION_MANDAT}
                    libelle="Convention de mandat" 
                    isLimited={true} 
                    submitted = {isFormDocumentSubmitted}
                    onChange={onChangeSecondPiece}
                    validateField={validateDocument}
                /> 
                <FileUpload 
                    name={TypePieceFinance.EXTRAIT_JUDICIAIRE}
                    libelle="Extrait de casier judiciaire" 
                    isLimited={true} 
                    submitted = {isFormDocumentSubmitted}
                    onChange={onChangeSecondPiece}
                    validateField={validateDocument}
                />
                {
                    projet.TypeEmpruntCode === TypeEmprunt.TITRES && 
                    <FileUpload 
                        name={TypePieceFinance.TEMPLATE_HTML_CONTRAT}
                        libelle="Template HTML du contrat" 
                        isLimited={true} 
                        submitted = {isFormDocumentSubmitted}
                        onChange={onChangeSecondPiece}
                        validateField={validateTemplate}
                    />
                }     
            </Form.Field>
            </LoadingSpinner>
            <div  
                className="button-bar">
                    <SubmitButton 
                        data={bodyDocument}
                        label="Valider"
                        loaderArea="modal"
                        action={() => projetService.publishAnalyseVillyzMultipleDocuments(projet.Id, loginProps?.oauth,bodyDocument)}
                        onActionSuccess={onSubmitSuccessDocument}
                        onActionFailure={onSubmitError}
                        onFormNotValid={onFormNotValid}
                        validateForm={validateForm}
                        onPreSubmit={onPreSubmit}
                    />
                    {
                        statutProjetCode.includes(projet.StatutProjetCode!) && 
                        <SimpleButton 
                            label= "Suivant"
                            onClick={()=>setPane(EtapePossible.Information)}
                        />
                    }
            </div>
        </Form>
    )}
    {pane === EtapePossible.Information && (
        <Form>
            <LoadingSpinner area="modal">
            <FormErrorHeader 
                message="Veuillez corriger les champs en erreur"
                displayed={isFormError}
            />
            <Form.Field width={16}>
                <CheckBoxListField 
                    data={form.actionVillyz}
                    label="Action villyz"
                    submitted={isFormInfoSubmitted}
                    elements={actionVillyzList && actionVillyzList.map(secteur => {
                        return {
                            key: secteur.Code,
                            label: secteur.Libelle
                        } as CheckBoxElement
                    }) || []}
                    spaceBetween
                    onChange={(key: string, isChecked?: boolean) => {
                        if(isChecked) {
                            setForm({...form, actionVillyz: {value: key, name: 'actionVillyz', isValid: true}})
                        }
                        else {
                            setForm({...form, actionVillyz: {value: undefined, name: 'actionVillyz', isValid: false}})
                        }
                    }}
                    error="Champ obligatoire"
                    validateField={validateMandatory}
                />
            </Form.Field>
            <Form.Field width={16}>
                <LexicalEnvContext.Provider value={
                    {
                        uploadUrl: `${process.env.REACT_APP_BASE_URL}/api/${loginProps?.oauth?.userId}/projets/${projet?.Id}/upload-rich-text-media`,
                        readUrl: `${process.env.REACT_APP_BASE_URL}/public/projets/${projet?.Id}/rich-text-media`,
                        bearerToken: loginProps?.oauth?.access_token || ""
                    }}>
                    <LexicalInputArea
                        data={wysiwygForm.analyseRisque}
                        initialEditor={editorState}
                        label="Description détaillée de l'analyse"
                        error="Merci de décrire votre analyse"
                        submitted={isFormInfoSubmitted}
                        onChange={handleInputAreaChange}
                        validateField={validateHTMLInput}
                    />
                </LexicalEnvContext.Provider>
            </Form.Field>
            <Form.Field width={8}>
                <InputField 
                    data={form.tauxCommission}
                    label="Taux de commission (en %)"
                    error="Merci de remplir la commission"
                    submitted={isFormInfoSubmitted}
                    onChange={handleInputChange}
                    validateField={validateMandatory}
                />
            </Form.Field>
            
            </LoadingSpinner>
            <div
                className="button-bar">
                    <SubmitButton
                        loaderArea="modal" 
                        data={bodyInfo}
                        label="Valider"
                        action={() => projetService.publishAnalyseVillyzInfo(bodyInfo, projet.Id, loginProps?.oauth)}
                        onActionSuccess={onSubmitSuccess}
                        onActionFailure={onSubmitError}
                        onFormNotValid={onFormNotValid}
                        validateForm={isFormValid}
                        onPreSubmit={onPreSubmit}
                    />
            </div>
        </Form>
    )}  
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedAnalyseForm = connect(mapStateToProps, null)(AnalyseFinanciereForm);
export { ConnectedAnalyseForm as AnalyseFinanciereForm };