import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon, Segment, SemanticICONS, Tab } from "semantic-ui-react";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import { DescriptionDocumentResponse, DescriptionDocumentsListResponse, FichierAnalyseVillyzResponse, ProfilCode } from "../../../../model/dto/response";
import Projet from "../../../../model/projet";
import { AuthenticationState } from "../../../../model/state/authentication-state";
import { publicService } from "../../../../services/public-service";
import { ApplicationState } from "../../../../store";
import { LightEditableBlock } from "../../../generic/editable-block/light-editable-block";
import { Pane } from "../../../place_publique/placepublique-pane";
import { DescriptionProjetForm } from "../../forms/description-projet-form";
import { InfosGeneralesProjetForm } from "../../forms/infos-generales-projet-form";
import { ProjectInfos } from "../project-detail";
import { AnalyseFinanciereForm } from "../../../ecsp/analyse-financiere-form";
import { StatutProjet } from "../../../../model/entities/statut-projet";
import parse from "html-react-parser"
import { DocumentsUploadForm } from "../../forms/documents-upload-form";
import { ProjetLocalisation } from "../../projet-localisation";
import { css } from "@emotion/core";
import { CritereSelectionTab } from "./critere-selection-tab";

export const sectorsIcons: Record<string, { icon: SemanticICONS; label: string ; color: SemanticCOLORS}> = {
  SPL: { icon: "basketball ball", label: "Sport et loisirs", color:"orange" },
  EDU: { icon: "graduation cap", label: "Education" , color:"blue" },
  TOU: { icon: "camera retro", label: "Tourisme", color:"yellow"  },
  STE: { icon: "heart", label: "Santé" , color:"pink" },
  LCS: { icon: "home", label: "Logement et cohésion sociale", color:"grey"  },
  CPA: { icon: "university", label: "Culture & patrimoine" , color:"brown" },
  TRA: { icon: "train", label: "Transport" , color:"teal" },
  ECO: { icon: "leaf", label: "Ecologie", color:"green" }
};

export const labelsPictos: Record<string, { img: string; label: string }> = {
  BBC: {
    img: "/img/labels/label_BBC.png",
    label: "Label Bâtiment Basse Consommation",
  },
  HQE: {
    img: "/img/labels/label_HQE.png",
    label: "Label Haute Qualité Environnementale",
  }
};

const statutTermines = [
  StatutProjet.CLOTURE, 
  StatutProjet.DEBOUCLAGE_DEMANDE, 
  StatutProjet.DEBOUCLAGE_POSSIBLE,
  StatutProjet.DEBOUCLAGE_VALIDE,
  StatutProjet.DEBOUCLAGE_FINALISE,
  StatutProjet.BIENTOT_DISPONIBLE,
  StatutProjet.PUBLIE,
  StatutProjet.FINANCE,
  StatutProjet.REFUSE
]

type Props = {
  profile: ProfilCode;
  project: Projet;
  etablissementNom?: string;
  typeCategorieEtablissementCode?: string;
  etablissementCategorie?: string;
  etablissementDepartementCode?: string;
  etablissementNomLocalite?: string;
  nonEditListCode: string[];
  projectInfos: ProjectInfos;
  savedEditorState?: string;
  isPageDon?: boolean;
  reload: () => void;
};

export default function GeneralInfos({
  profile,
  project,
  etablissementNom,
  typeCategorieEtablissementCode,
  etablissementCategorie,
  etablissementDepartementCode,
  etablissementNomLocalite,
  nonEditListCode,
  projectInfos,
  isPageDon,
  reload
}: Props): JSX.Element {
  
  const [documentsNames, setDocumentsNames] = useState<string[]>([]);
  const [isLogoDispo, setIsLogoDispo] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const navigate = useNavigate();
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  const onDownloadSuccess = (response: FichierAnalyseVillyzResponse, filename: string) => {

    const byteCharacters = atob(response.File!);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: ''});
    
    const aEle = document.createElement('a');     // Create a label
    const href = window.URL.createObjectURL(blob);       // Create downloaded link
    aEle.href = href;
    aEle.download = filename;  // File name after download
    document.body.appendChild(aEle);
    aEle.click();     // Click to download
    document.body.removeChild(aEle); // Download complete remove element
    window.URL.revokeObjectURL(href)
  }

  const onDownloadSuccessDescriptionDocument = (response: DescriptionDocumentResponse) => {

    const byteCharacters = atob(response.File!);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: ''});
    
    const aEle = document.createElement('a');     // Create a label
    const href = window.URL.createObjectURL(blob);       // Create downloaded link
    aEle.href = href;
    aEle.download = response.Name!+".pdf";  // File name after download
    document.body.appendChild(aEle);
    aEle.click();     // Click to download
    document.body.removeChild(aEle); // Download complete remove element
    window.URL.revokeObjectURL(href)
  }

  const onClick = () => {
    if (project.Id) 
    {
      publicService.getAnalyseFile(project.Id).then(
        (response) => {
          onDownloadSuccess(response, "Analyse_financiere.pdf")
        }
      );
    }
  }

  const onClickInfos = () => {
    if (project.Id) 
    {
      publicService.getInformationsFile(project.Id).then(
        (response) => {
          onDownloadSuccess(response, "Informations_cles.pdf")
        }
      );
    }
  }

  const onClickDownloadCritere = () => {
    // TODO : backend à préparer
    if (project.Id) 
    {
      publicService.getAnalyseFile(project.Id).then(
        (response) => {
          onDownloadSuccess(response, "Analyse_financiere.pdf")
        }
      )
      ;
    }
  }

  const onDocumentClick = (documentName: string) => {
      publicService.getDescriptionDocument(project.Id!, documentName).then(
        (reponse: DescriptionDocumentResponse) => {
          onDownloadSuccessDescriptionDocument(reponse)
        }
      )
  }
    
  useEffect(() => {
    const timestamp = new Date().getTime(); 
    
    publicService.checkLogoExistance(project.EtablissementId!).then(
      (response) => {
        if(response.Exist){
          setIsLogoDispo(response.Exist);
          setLogoUrl(`${process.env.REACT_APP_BASE_URL}/public/get-logo/${project.EtablissementId!}?t=${timestamp}`)
        }
        else{
          setIsLogoDispo(response.Exist);
        }
      }
    )

    publicService.getDescriptionDocumentsByProjectId(project.Id!).then(
      (response: DescriptionDocumentsListResponse)=>{
          setDocumentsNames(response.DescriptionDocuments!)
      }
    )
  },[])

  const wysiwygContainerStyle = css({
    iframe: {
      aspectRatio: '560 / 315',
      maxWidth: '100%',
      height: 'auto',
    },
    img: {
      maxWidth: '100%',
      height: 'auto !important',
      display: "inline"
    },
    h1:{
      span: {
        whiteSpace: "normal",
      }
    }
  })

  const adminListCodeNonEdit = [
    StatutProjet.FINANCE, 
    StatutProjet.CLOTURE,
    StatutProjet.ANNULE, 
    StatutProjet.REFUSE
  ];

  const panes = [
    {
      menuItem: 'Description',
      render: () => {
        return (
            <Segment>
              <LightEditableBlock
                title="Description"
                editable={(profile === ProfilCode.PORTEUR_PROJET && !(nonEditListCode.includes(project.StatutProjetCode!) || (nonEditListCode.includes(project.StatutDonProjetCode!)))) || profile===ProfilCode.VILLYZ}
                editor={(close) => (
                  <DescriptionProjetForm
                    projet={project}
                    intialEditorState={project.LexcicalEditorState}
                    onSubmitSuccess={() => {
                      close();
                      reload();
                    }}
                  ></DescriptionProjetForm>
                  
                )}
              >
                <>
                  <div css={wysiwygContainerStyle}>
                     {parse(project.DescriptionLong ?? '')}
                  </div>
                </>
              </LightEditableBlock>
            </Segment>
        );
      }
    },
    {
      menuItem: 'Place Publique',
      render: () => {
        if(statutTermines.includes(project.StatutProjetCode!)){
          return (
            <Tab.Pane attached={false} css={{
                background: '#FCFAF8 !important',
                border: 'none !important'
            }}>
              <Pane project={project}/>
            </Tab.Pane>
          )     
        }
        else{
          return(
            <Segment inverted secondary color='blue' textAlign="center" size="big" padded='very'>
              La place publique apparaîtra dès que le projet aura été validé par nos équipes
            </Segment>
          )
        }
      }
    },
    {
      menuItem: isPageDon ? 'Critères de sélection' : 'Analyse Financière',
      render: () => {
          return (
            <Segment>
            <LightEditableBlock
              title={isPageDon ? 'Critère de sélection' : 'Analyse Financière'}
              editable={profile === ProfilCode.VILLYZ}
              editor={(close) => (
                <AnalyseFinanciereForm
                  projet={project}
                  isPageDon={isPageDon}
                  onSubmitSuccess={() => {
                  close();
                  reload();
                }}
                />
              )}
            >
              <> 
                {isPageDon && (
                  <CritereSelectionTab
                    project={project}
                    onDownloadCritereDocument={onClickDownloadCritere}
                  />
                ) || (
                  <>
                    {project.FichierInformationsClesName ?
                    <div>
                      <div css={{
                        fontWeight:"bolder",
                        fontSize:"17px",
                        color:"#16586F",
                        marginBottom: "1em"
                      }}>
                        Fiche d'informations clés sur l'investissement
                      </div>
                      <p css={{fontFamily:"Source Sans Pro"}}>
                      Cette fiche présente un certain nombre d'information sur le porteur du projet et son projet telles que les
                      chiffres clés, les caractéristiques de l'offre de financement participatif, les conditions de réussite de la 
                      levée, les risques ou encore les frais.
                      </p>
                      <div css={{marginLeft:"50px",marginBottom:"15px", cursor:"pointer"}}onClick={() => onClickInfos()}>
                        <Icon css={{color:"#A6A6A6"}} size='big' name='file pdf outline'/> 
                        <span css={{fontSize :"1,125 rem",fontFamily : "Source Sans Pro ",color :"#A6A6A6",fontWeight :"normal"}}>Fiche d'informations clés</span>
                      </div>
                    </div>:<div></div>}
                    <div css={{
                      fontWeight:"bolder",
                      fontSize:"17px",
                      color:"#16586F",
                      marginBottom: "1em"
                    }}>
                      Analyse Financière
                    </div>
                    <div css={{marginLeft:"5px",marginBottom:"15px"}}>
                      {project.AnalyseRisque ? 
                        <div dangerouslySetInnerHTML={{__html: project.AnalyseRisque!}}>
                        </div> :
                        <p css={{fontFamily:"Source Sans Pro" }}>
                          Cette partie est réservée à l'analyse crédit que Villyz effectue
                          avant la publication du projet aux citoyens. Elle vous sera
                          communiquée en amont.
                        </p>
                      }
                    </div>
                    {project.AnalyseRisque ? 
                      <div css={{marginLeft:"50px",marginBottom:"15px", cursor: "pointer"}} onClick={() => onClick()}>
                        <Icon css={{color:"#A6A6A6"}} size='big' name='file pdf outline'/> 
                        <span css={{fontSize :"1,125 rem",fontFamily : "Source Sans Pro ",color :"#A6A6A6",fontWeight :"normal"}}>Analyse Financière</span>
                      </div> : <div></div> }
                  </>
                )}
              </>
            </LightEditableBlock>
          </Segment>
          )
      }
    },
    {
      menuItem: 'Documents',
      render: () => {
        return (
          <Segment>
              <LightEditableBlock
                title="Documents"
                editable={profile === ProfilCode.VILLYZ}
                editor={(close) => (
                  <DocumentsUploadForm
                    loginProps={loginProps}
                    projet={project}
                    documents={documentsNames}
                    close={close}
                    reload={reload}
                  />
                )}
              />
              <div css={{
                  fontWeight:"bolder",
                  fontFamily:"Source Sans Pro",
                  fontSize:"17px",
                  color:"#16586F",
                  marginBottom: "1em"
                }}>
                  Documents
              </div>
              {
                documentsNames && documentsNames.length > 0 
                ?
                  documentsNames.map(documentName => (
                    <div css={{marginLeft:"50px",marginBottom:"15px", cursor: "pointer"}} onClick = {()=>onDocumentClick(documentName)}>
                      <Icon css={{color:"#A6A6A6"}} size='big' name='file pdf outline'/> 
                      <span css={{fontSize :"1,125 rem",fontFamily : "Source Sans Pro ",color :"#A6A6A6",fontWeight :"normal"}}>{documentName}</span>
                    </div>
                  ))
                :
                  <div></div>
              }
          </Segment>
        )
      }
    }
  ]

    return(
      <>
        <Segment>
          <LightEditableBlock
            title="Informations générales"
            editable={(profile === ProfilCode.PORTEUR_PROJET && !(nonEditListCode.includes(project.StatutProjetCode!) || nonEditListCode.includes(project.StatutDonProjetCode!))) 
            || (profile===ProfilCode.VILLYZ && 
              (isPageDon ? !(adminListCodeNonEdit.includes(project.StatutDonProjetCode!))  : !(adminListCodeNonEdit.includes(project.StatutProjetCode!))))}
            editor={(close) => (
              <InfosGeneralesProjetForm
                projet={project}
                labelProjetList={projectInfos.labels}
                secteurProjetList={projectInfos.sectors}
                onSubmitSuccess={() => {
                  close();
                  reload();
                }}
                isPageDon={isPageDon}
              />
            )}
          >
            <>
              <div css={{ marginBottom: "10px", display: "flex", alignItems: "center"}}>
                {projectInfos.sectors?.map((sector) => {
                  if (!sector.SecteurCode) {
                    return;
                  }
                  
                  const couleurLogo = sectorsIcons[sector.SecteurCode].color ? sectorsIcons[sector.SecteurCode].color! : "blue";
                  return (
                    <Icon
                      key={sector.SecteurCode}
                      name={sectorsIcons[sector.SecteurCode].icon}
                      title={sectorsIcons[sector.SecteurCode].label}
                      color={sectorsIcons[sector.SecteurCode].color}
                      size="big"
                    />
                  );
                })}
                
                {projectInfos.labels?.map((label) => {
                  if (!label.LabelCode || !labelsPictos[label.LabelCode]) {
                    return;
                  }
  
                  return (
                    <img
                      key={label.LabelCode}
                      src={labelsPictos[label.LabelCode].img}
                      title={labelsPictos[label.LabelCode].label}
                      css={{ height: "29px", width: "auto" }}
                      alt="logo label"
                    />
                  );
                })}
                
              </div>
              <div css={{ display: "flex", flexDirection:"row", alignItems:"center", marginTop: !project.Titre ? "40px" : "0px"}}>
                  <div css={{flexBasis: "85%"}}>
                    <h2 css={{margin: ".7rem 0", fontSize: "2rem"}}>{project.Titre}</h2>
                    <p css = {{fontSize: "1.2rem"}}>{project.DescriptionCourt}</p>
                    <div css={{fontSize: "1.2rem", display:"flex"}}>
                      <Icon name="map marker alternate" color="red"/>  
                      <ProjetLocalisation 
                        typeCollectiviteCode={typeCategorieEtablissementCode} 
                        categorieEtablissement={etablissementCategorie} 
                        nomEtablissement={etablissementNom}  
                        codeDepartement={etablissementDepartementCode} 
                        nomLocalite={etablissementNomLocalite}                   
                      />
                    </div>
                  </div>
                  {isLogoDispo ?
                        <div css={{height: "80px", flexBasis: "15%"}}>
                          <img 
                              src={logoUrl}
                              css={{ 
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain"
                              }}
                              alt="logo de l'url"
                          /> 
                        </div>
                        :
                        profile !== ProfilCode.PORTEUR_PROJET && ProfilCode.VILLYZ ?
                          <div></div>
                          :
                          <div css={{display: "flex", justifyContent: "center", alignItems:"center", flexBasis: "20%", cursor:"pointer"}} onClick={() => navigate('/profil')}>
                              <strong>Ajouter un logo</strong>
                              <Icon
                                    name="upload" 
                                    size="big" 
                                    css = {{color: "#005870"}}
                              />
                          </div>
                  }
              </div>
            </>
          </LightEditableBlock>
        </Segment>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} /> 
      </>
    )
}

