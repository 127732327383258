import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { MultipleFileRegistrationResponse, OnboardingBusinessResponse, OnboardingCitoyenResponse, OnboardingCollectiviteResponse, OnboardingInvestisseurResponse, OnboardingOrganisationResponse, OnboardingSEMResponse, ProfilCode, WorkflowProcessResultResponse } from "../../model/dto/response"
import { TypeInvestisseur } from "../../model/entities/types-investisseur"
import { AuthenticationState } from "../../model/state/authentication-state"
import { referenceService } from "../../services/reference-service"
import { ApplicationState } from "../../store"
import { HorizontalTimeline, TimelineElement } from "../generic/horizontal-timeline"
import { SubscriptionInvestisseurBusinessForm } from "./subscription-investisseur-business-form"
import { SubscriptionInvestisseurOrganisationForm } from "./subscription-investisseur-organisation-form"
import { SubscriptionInvestisseurParticulierForm } from "./subscription-investisseur-particulier-form"
import { WorkflowType } from "../../model/entities/workflow-type"
import { FlowStep } from "../../model/entities/flow-step"

type Props = {
  subscriptionStepResponse:OnboardingInvestisseurResponse
}

export function SwitchOnboardingTypeInvestisseur({subscriptionStepResponse}: Props) {
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
  const [elements, setElements] = useState<TimelineElement[]>([])
  const [activeStepKey, setActiveStepKey] = useState<string>(subscriptionStepResponse.NextStepKey!) 
  const [lastStepResponse, setLastStepResponse] = useState<OnboardingCitoyenResponse | OnboardingBusinessResponse | OnboardingOrganisationResponse | OnboardingCollectiviteResponse | OnboardingSEMResponse>()


    useEffect(() => {
      //---- Investisseur Business (Société) ----//  
      if(loginProps.user?.ProfilCode === ProfilCode.INVESTISSEUR &&
        loginProps?.user.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseur.SOCIETE) {
          const body = {
            WorkflowTypeCode: WorkflowType.ONBOARDING_INVESTISSEUR_SOCIETE
          }
          referenceService.getWorkflowStepListByWorkflowTypeCode(body)
          .then(response => {
            const workflowListRes: TimelineElement[] | undefined = response.WorkflowStepList && 
            response.WorkflowStepList.map(res => {
              return {
              key: res.Key,
              dataMainInfo: res.Libelle,
              order:res.Order
            } as TimelineElement
          })
          setElements(workflowListRes ?? [])
        })
      }
      //---- Investisseur Organisation (Collectivité et Association) ----//
        else if (loginProps.user?.ProfilCode === ProfilCode.INVESTISSEUR &&
          (loginProps.user.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseur.ASSOCIATION || 
          loginProps.user.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseur.COLLECTIVITE)) {
            const body = {
              WorkflowTypeCode: WorkflowType.ONBOARDING_INVESTISSEUR_MORAL
            }
            referenceService.getWorkflowStepListByWorkflowTypeCode(body)
              .then(response => {
                const workflowListRes: TimelineElement[] | undefined = response.WorkflowStepList && 
                response.WorkflowStepList.map(res => {
                  return {
                    key: res.Key,
                    dataMainInfo: res.Libelle,
                    order:res.Order
                  } as TimelineElement
                })
                setElements(workflowListRes ?? [])
            })
          }
          //---- Investisseur personne physique ----//
          else if(loginProps.user?.ProfilCode === ProfilCode.INVESTISSEUR && !loginProps.user.Etablissement) {
            const body = {
              WorkflowTypeCode: WorkflowType.ONBOARDING_CITOYEN
            }
            referenceService.getWorkflowStepListByWorkflowTypeCode(body)
            .then(response => {
              const workflowListRes: TimelineElement[] | undefined = response.WorkflowStepList && 
              response.WorkflowStepList.map(res => {
                return {
                  key: res.Key,
                  dataMainInfo: res.Libelle,
                  order:res.Order
                } as TimelineElement
              })
              setElements(workflowListRes ?? [])
            })
          }
    }, [])

    if(loginProps.user?.ProfilCode === ProfilCode.INVESTISSEUR &&
      loginProps?.user.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseur.SOCIETE) { 
            
      const onNextStepSuccess = (response: OnboardingBusinessResponse) => {
        if(response.IsTraitementOk) {
            setActiveStepKey(response.NextStepKey!)
            setLastStepResponse(response)
        }
      }

      const onSubmitPiecesJustificativesSuccess = (response: MultipleFileRegistrationResponse) => {
        if(response.IsTraitementOk) {
          setActiveStepKey(response.NextStepKey!)
        }
      }

      const onNextStep = (response: WorkflowProcessResultResponse) => {
        if(response.IsTraitementOk){
          setActiveStepKey(response.NextFlowStepCode!)
          setLastStepResponse(response)
        }
      }

      return (
        <>
          <HorizontalTimeline
            elements={elements}
            activeElementKey={activeStepKey}
            lastElementKey={FlowStep.REMERCIEMENT}
            backgroundColor="#fff !important"
          />
          <SubscriptionInvestisseurBusinessForm
            activeStepKey={activeStepKey}
            onSubmitVerification={onNextStepSuccess}
            onSubmitPiecesJustificatives={onSubmitPiecesJustificativesSuccess}
            onNextStep={onNextStep}
            lastStepResponse={lastStepResponse}
          />
        </>
      )
    } 
    else if (loginProps.user?.ProfilCode === ProfilCode.INVESTISSEUR &&
    (loginProps.user.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseur.ASSOCIATION || 
    loginProps.user.Etablissement?.TypeInvestisseurMoraleCode === TypeInvestisseur.COLLECTIVITE)) {

      const onNextStepSuccess = (response: OnboardingOrganisationResponse) => {
        if(response.IsTraitementOk) {
          setActiveStepKey(response.NextStepKey!)
          setLastStepResponse(response)
        }
      }

      const onSubmitPiecesJustificativesSuccess = (response: MultipleFileRegistrationResponse) => {
        if(response.IsTraitementOk) {
          setActiveStepKey(response.NextStepKey!)
        }
      }

      const onNextStep = (response: WorkflowProcessResultResponse) => {
        if(response.IsTraitementOk){
          setActiveStepKey(response.NextFlowStepCode!)
          setLastStepResponse(response)
        }
      }

      return (
          <>
            <HorizontalTimeline
              elements={elements}
              activeElementKey={activeStepKey}
              lastElementKey={FlowStep.COMPLET}
              backgroundColor="#fff !important"
            />
            <SubscriptionInvestisseurOrganisationForm
              activeStepKey={activeStepKey}
              onNextStep={onNextStep}
              onSubmitVerification={onNextStepSuccess}
              onSubmitPiecesJustificatives={onSubmitPiecesJustificativesSuccess}
              lastStepResponse={lastStepResponse}
            />
          </>
      )
    } else if (loginProps.user?.ProfilCode === ProfilCode.INVESTISSEUR && !loginProps.user?.Etablissement) {

      const onNextStepSuccess = (response: OnboardingCitoyenResponse) => {
        if(response.IsTraitementOk) {
          setActiveStepKey(response.NextStepKey!)
          setLastStepResponse(response)
        }
      }

      const onSubmitPiecesJustificativesSuccess = (response: MultipleFileRegistrationResponse) => {
        if(response.IsTraitementOk) {
          setActiveStepKey(response.NextStepKey!)
        }
      }

      const onNextStep = (response: WorkflowProcessResultResponse) => {
        if(response.IsTraitementOk){
          setActiveStepKey(response.NextFlowStepCode!)
          setLastStepResponse(response)
        }
      }

      return (
        <>
          <HorizontalTimeline
            elements={elements}
            activeElementKey={activeStepKey}
            lastElementKey={FlowStep.REMERCIEMENT}
            backgroundColor="#fff !important"
          />
          <SubscriptionInvestisseurParticulierForm 
            activeStepKey={activeStepKey}
            onSubmitAdressePostal={onNextStepSuccess}
            onSubmitEtatCivil={onNextStepSuccess}
            onSubmitExperienceInvestissement={onNextStepSuccess}
            onSubmitPiecesJustificative={onSubmitPiecesJustificativesSuccess}
            onNextStep={onNextStep}
            lastStepResponse={lastStepResponse}
          />
        </>
      )
    }
    return (
      <div></div>
    )
}