import React from "react";
import { Grid, Image } from "semantic-ui-react";
import { FormErrorHeader } from "../generic/form-error-header";
import { SubmitButton } from "../generic/submit-button";
import { AttentePaiementPane } from "./attente-paiement-pane";
import { ModePaiement } from "../../model/entities/mode-paiement";
import { InvestResponse } from "../../model/dto/response";

type Props = {
  montant?: number | string;
  projetId?: number;
  errorMessage?: string;
  isErrorDisplayed?: boolean;
  showAttentePaiement: boolean;
  montantDisponibleVillyz?: number,
  isDon?: boolean,
  onSubmitImpossible?: (message: string) => void;
  onClickAttentePaiement?: () => void;
};

export function CarteBancairePane({
  montant,
  projetId,
  errorMessage,
  isErrorDisplayed,
  montantDisponibleVillyz,
  showAttentePaiement,
  isDon,
  onSubmitImpossible,
  onClickAttentePaiement,
}: Props) {


  const onInvestSuccessHandler = (response: InvestResponse) => {
    window.location.href = response.RedirectURL!
  }

  const calculateMontantWithWallet = (montant: number | string | undefined, montantDisponibleVillyz: number | undefined ) => {
    if(montant){
      if(montantDisponibleVillyz){
        if(typeof(montant) === 'string'){
          return parseInt(montant) - montantDisponibleVillyz
        }
        else {
          return montant - montantDisponibleVillyz
        }
      }
      return montant
    }
  }

  return (
    <div style={{padding:"10px"}}>
      <FormErrorHeader message={errorMessage ?? ""} displayed={isErrorDisplayed ?? false} />
        {showAttentePaiement ? ( 
          <AttentePaiementPane 
            montant={montant}
            projetId={projetId}
            modePaiement={ModePaiement.CARTE_BANCAIRE}
            onSubmitImpossible={onSubmitImpossible}
            onInvestSuccess={onInvestSuccessHandler}
            isDon={isDon}
          />
        ) 
        : 
        (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {!isDon ?
                  <h3 style={{width:"100%"}}>Montant total de l'investissement : {montant} €</h3>
                  :
                  <h3 style={{width:"100%"}}>Montant total du don : {montant} €</h3>
                }
                {montantDisponibleVillyz !== 0 && !isDon &&
                  <p style={{width:"100%", lineHeight:1}}>Dont {montantDisponibleVillyz} € de votre compte Villyz  </p>
                }
                {montantDisponibleVillyz !== undefined &&  montantDisponibleVillyz > 0 && !isDon &&
                  <h3 style={{width:"100%", marginTop:"0px"}}>Reste à payer : {calculateMontantWithWallet(montant, montantDisponibleVillyz)} €</h3>
                }
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <p>
                  Toutes les transactions sont sécurisées via le protocole 3D Secure.
                  Assurez-vous qu'il soit actif sur votre carte bancaire.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <div
                css={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <Image size="tiny" src="/img/logos/powered-mangopay-2.png" css={{ marginInline: "8px" }} />
              </div>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <SubmitButton
                  label="Procédez au paiement"
                  onClick={onClickAttentePaiement}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )
      }
    </div>
  );
}
