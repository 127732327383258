import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';

type Props = {
  height?: number,
  display?: React.ReactNode,
  children?: React.ReactNode,
  area?: string
}
 
export const LoadingSpinner = ({height, children, display, area}: Props) => {
    const { promiseInProgress } = usePromiseTracker({area: area});
    return (
      <>
        {promiseInProgress && (
          <div
            style={{
              width: "100%",
              height: "100",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Loader type="ThreeDots" color="#005870" height={height ?? 500} width={100} />
          </div>
        ) || (children || display) || (<div></div>)}
      </>
    )
};