import React, { useEffect, useMemo, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "semantic-ui-react";
import { UsersListResponse } from "../../../model/dto/response";
import { UserInfoAdmin } from "../../../model/dto/util/user-info-admin";
import { Profil } from "../../../model/entities/profil";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { userService } from "../../../services/authentication-redux-service";
import { ApplicationState } from "../../../store";
import { InputField } from "../../generic/input-field";
import { PaginatedTable } from "../../generic/paginated-table/paginated-table";
 
type FieldData = {
    value?: any,
    name?: string,
    isValid?: boolean
};

export function Utilisateurs() {
    const [usersList, setUsersList] = useState<UserInfoAdmin[]>([]);
    const [isLoading, setIsLoading] = useState(false)
    const [searchField, setSearchField] = useState<FieldData>({value: "", isValid: true, name: "searchField"})

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    
    const navigate = useNavigate(); 
    const onButtonClick = (user?: UserInfoAdmin): void => {
        if(user?.IsPhysique)
        {
            navigate(`/citoyens/${user.UserId}`);
        }
        else
        {
            navigate(`/collectivites/${user!.UserId}`);
        }
    };

    const filteredUserList = useMemo(() => {
        if(searchField?.value !== undefined && searchField?.value !== "") {
            return usersList.filter(user => user.Mail?.includes(searchField?.value))
        }
        
        return usersList
    }, [searchField, usersList])
    
    useEffect(() => {
        if(loginProps?.oauth?.profilCode === Profil.VILLYZ_USER) {
            setIsLoading(true)
            trackPromise(userService.getAllUsers(loginProps?.oauth?.userId, loginProps?.oauth)
            .then((container: UsersListResponse) => {
                setUsersList(container.UsersList!)
                setIsLoading(false)
            }));
        }
      }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
        const fieldValue: string = e.target.value;
        setSearchField({value: fieldValue, name: fieldName, isValid: isValid})
    } 

    return (
        <> 
            <Form>
                <Form.Field>
                    <InputField
                        data={searchField}
                        label="Filtrer"
                        onChange={handleInputChange}
                        css={{
                            marginBottom: "20px"
                        }}
                    />
                </Form.Field>
            </Form>
            <PaginatedTable 
                    elements={filteredUserList}
                    elementsPerPage={10}
                    headers={
                        [
                            'Adresse email',
                            'Nom', 
                            'Prénom',
                            'Type', 
                            'Localisation', 
                            'Statut KYC',
                            'Actions' 
                        ]
                    }
                    columnSelector={(element: UserInfoAdmin) => {
                        return {
                            values: [
                                element?.Mail,
                                element?.Nom,
                                element?.Prenom,
                                element?.Type,
                                element?.Localisation,
                                element?.StatutKyc,
                                <div>
                                <Button circular  icon='pencil' onClick={() => onButtonClick(element)}/>,
                                <Button circular  icon='user delete' />
                                </div>
                            ]
                        }
                    }}
            />
        </>
    )
}