import React, { useMemo, useState } from "react";
import { Button, Card, Header, Icon, Modal, SemanticICONS } from "semantic-ui-react";

type Props = {
  editable?: boolean;
  title?: string;
  editButtonColor?: any;
  display?: React.ReactNode;
  children?: React.ReactNode;
  deleteIcon?: SemanticICONS;
  iconCss?: any;
  editor: (close: () => void,action: string) => React.ReactNode;
  withMargin?:boolean;
  onRemoveValidate?: (isRemoved: boolean) => void
};

export function EditableBlock({
  title,
  editButtonColor,
  display,
  children,
  deleteIcon,
  iconCss = {},
  editor,
  onRemoveValidate,
  editable = true,
  withMargin=true,
}: Props) {
  const [isEdited, setIsEdited] = useState(false);
  const [isToBeRemoved, setIsToBeRemoved] = useState(false);
  const [action, setAction] = useState("");

  const css = useMemo(() => {
    if(!withMargin)
    {
      return {
       marginBottom:"0px !important"
      }
    }
    else 
    {
      return { }
    }
     
  }, []);

  const handleYesclick = () => {
    setIsToBeRemoved(false)
    if(onRemoveValidate) {
      onRemoveValidate(true)
    }
  }

  const handleNoClick = () => {
    setIsToBeRemoved(false)
    if(onRemoveValidate) {
      onRemoveValidate(false)
    }
  }

  return (
    <>
      <Card fluid css={css}>
        <Card.Content>
          {editable && (
           <div>
              <div
                css={{ 
                  float: "right", 
                  cursor: "pointer", "&:hover": { transform: "scale(1.2)"},
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #ACACAC",
                  borderRadius: "10px",
                  padding: "1px 8px",
                  marginTop: "-0.3rem",
                }}
                onClick={() =>{
                  setIsEdited(true)
                  setAction("edit");
                }}
              >
                <span 
                  css={{
                    fontSize:"14px",  
                    fontWeight:"bold",
                    color: editButtonColor
                  }}
                > 
                  Modifier
                </span>
                <span css={{marginLeft:"0.36rem"}}>
                  <Icon 
                    floated="right" 
                    css= {{color: editButtonColor}}
                    name="pencil" 
                    size="small"
                  />
                </span>
            </div>
            {deleteIcon && (
              <div
                css={{
                  ...iconCss,
                  float: "right",
                  marginRight:"30px",
                  padding: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                }}
                onClick={() => {
                  setAction("remove")
                  setIsToBeRemoved(true);
                }}
              >
                <Icon floated="right" color="red" name={deleteIcon} />
              </div>
            )}
          </div>
          )}
          {title && (<Card.Header>{title}</Card.Header>)}
          <Card.Description>{display || children}</Card.Description>
        </Card.Content>
      </Card>
      {
        action == "edit" ?

      <Modal onClose={() => setIsEdited(false)} open={isEdited} closeOnDimmerClick={false}>
        {title && (<Modal.Header>{title}</Modal.Header>)}
        <Modal.Content>
          <Modal.Description>
            {editor(() => setIsEdited(false), action)}
          </Modal.Description>
        </Modal.Content>
      </Modal>
      :
      <Modal
       open={isToBeRemoved}
      >
        <Header icon='archive' content='Supprimer un post ' />
        <Modal.Content>
            <p> 
              Voulez-vous vraiment supprimer votre post ? 
            </p>
          <Modal.Actions>
            <Button color='red' onClick={handleNoClick}>
              <Icon name='remove' /> Non
            </Button>
            <Button color='blue' onClick={handleYesclick}>
               <Icon name='checkmark' /> Oui
            </Button>
          </Modal.Actions>
        </Modal.Content>
      </Modal>
      }
    </>
  );
}
