import { css } from "@emotion/core";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { ReponseQuestionProposition } from "../../model/entities/reponse-question-proposition";

type Props = {
    reponse: ReponseQuestionProposition,
    idSelectedResponseList?: number[],
    onBoxClick?: (response: ReponseQuestionProposition, isSelected: boolean) => void,
    onStateChanged?: (responseId: number, isSelected: boolean) => void
  };
export function ReponseBox({
    reponse,
    idSelectedResponseList,
    onBoxClick,
    onStateChanged
}: Props)
{
    const [isSelected, setIsSelected] = useState<boolean>(false)
    const styleUnSelected = css({
        marginRight: "0.5em",
        marginBottom: "0.5em",
        background: "white",
        color: "#000",
        borderStyle: "solid",
        maxWidth:"80%",
        borderWidth: "1px",
        borderColor:"gainsboro",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize:"14px",
        textAlign:"center",
        padding: "5px",
        minWidth: "200px"
    })

    const styleSelected = css({
        marginRight: "0.5em",
        marginBottom: "0.5em",
        background: "white",
        fontWeight:"bold",
        color: "#005870",
        borderStyle: "solid",
        maxWidth:"80%",
        borderWidth: "1px",
        borderColor:"#005870",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize:"14px",
        textAlign:"center",
        padding:"5px",
        minWidth: "200px",
    })

    useEffect(() => {
        if(idSelectedResponseList?.find(id => id === reponse.Id))
        {
            setIsSelected(true)
        }
        else
        {
            setIsSelected(false)
            if(onStateChanged) {
                onStateChanged(reponse.Id, false)
            }
        }
       
    }, [idSelectedResponseList])
    
    const style = useMemo(
        () => {
            if (isSelected)
            {
                return styleSelected
            } 
                return styleUnSelected             
        },
        [isSelected]
      );

   
    const handleOptionClick = (response: ReponseQuestionProposition) => {
        if (!isSelected)
        {
            setIsSelected(true)
            if(onBoxClick)
            {
                onBoxClick(reponse, true)
            }
             
        }
        if(isSelected)
        {
            setIsSelected(false)
            if(onBoxClick)
            {
                onBoxClick(response, false)
            }
        }
      
    }
    return (
        <>
            <div onClick={() => handleOptionClick(reponse)} css={style}> 
                {reponse.Libelle}
            </div>        
        </>
    )

}