import { addDays, differenceInDays, format, parseISO } from "date-fns";
import React, { useMemo } from "react";
import { ProfilCode } from "../../../../model/dto/response";
import { StatutProjet } from "../../../../model/entities/statut-projet";
import Projet from "../../../../model/projet";




type Props = {
  statusCode?: string;
  profile: ProfilCode;
  project: Projet;
  isDon?: boolean; 
};

export default function Status({ statusCode, profile, project, isDon }: Props) {
  const daysUntilEnd = useMemo(() => {
    if (
      !project?.DateDebutFinancementCitoyen ||
      !project?.DureeFinancementCitoyen
    ) {
      return "-";
    }

    const startDate = parseISO(project.DateDebutFinancementCitoyen);
    const endDate = addDays(startDate, project.DureeFinancementCitoyen);
    return new Intl.NumberFormat("fr-FR", {
      style: "decimal",
    }).format(differenceInDays(new Date(), endDate));
  }, [project]);

  const endDate = useMemo(() => {
    if (
      !project?.DateDebutFinancementCitoyen ||
      !project?.DureeFinancementCitoyen
    ) {
      return null;
    }

    const startDate = parseISO(project.DateDebutFinancementCitoyen);
    return addDays(startDate, project.DureeFinancementCitoyen);
  }, [project]);

  let formattedEndDate = ""
  if(endDate) {
    formattedEndDate = format(endDate, "dd/MM/yyyy")
  }
  const annulationDate = project.DateAnnulation && format(parseISO(project.DateAnnulation.toString()),"dd/MM/yy");

  const daysAfterSuspension = useMemo(() => {
    if (!project?.DateSuspension) 
    {
      return undefined;
    }
    const startDate = parseISO(project.DateSuspension.toString())
    return new Intl.NumberFormat("fr-FR", {
      style: "decimal",
    }).format(differenceInDays(new Date(),startDate));
  }, [project]);

  const config = [
    {
      code: "INC",
      name: "incomplet",
      description:
        "Merci de renseigner l'ensemble des informations avant de le publier à Villyz.",
      bgColor: "#fcf8e3",
      textColor: "#8a6d3b",
    },
    {
      code: "COM",
      name: "complet",
      description:
        "Vous pouvez dès à présent le publier à Villyz pour validation avant d'être publié aux citoyens.",
      bgColor: "#dff0d8",
      textColor: "#3c763d",
    },
    {
      code: "ETR",
      name: "en traitement",
      description:
        "Merci pour votre publication à Villyz. Nous analysons la validité du projet sous 72 heures.",
      bgColor: "#d9edf7",
      textColor: "#31708f",
    },
    {
      code: "ICA",
      name: "informations complémentaires attendue",
      description:
        "Villyz vous contactera très prochainement pour finaliser la publication de votre projet.",
      bgColor: "#fcf8e3",
      textColor: "#8a6d3b",
    },
    {
      code: "REF",
      name: "refusé",
      description:
        "N'hésitez pas à nous contacter pour comprendre les raisons du refus de Villyz à publier le projet.",
      bgColor: "#f2dede",
      textColor: "#a94442",
    },
    {
      code: "AVE",
      name: "visible sur la plateforme",
      description:
        "Les citoyens peuvent savoir qu'un projet sera bientôt prochainement disponible pour son financement.",
      bgColor: "#FFF275",
      textColor: "#836417",
    },
    {
      code: "PAP",
      name: "validé par Villyz !",
      description:
        "Il est d'ores et déja visible par les citoyens sur la plateforme et son financement commencera à la date définie.   ",
      bgColor: "#FFCDB2",
      textColor: "#A43901",
    },
    {
      code: "PUB",
      name: "publié",
      description: "Il est visible aux citoyens pour investissement jusqu'au "+ formattedEndDate,
      bgColor: "#dff0d8",
      textColor: "#3c763d",
    },
    {
      code: "CLO",
      name: "cloturé",
      description: "Son financement est terminé. Félicitations !",
      bgColor: "#dff0d8",
      textColor: "#3c763d",
    },
    {
      code: "DBP",
      name: "en déblocage possible",
      description: "Vous pouvez dès à présent demander le déblocage des fonds ou l'annulation si l'objectif n'est pas atteint. Villyz traitera ensuite votre demande.",
      bgColor: "#d9edf7",
      textColor: "#31708f",
    },
    {
      code: "DBD",
      name: "en demande de déblocage",
      description: "Villyz vous contactera très prochainement pour finaliser le contrat de prêt de votre projet.",
      bgColor: "#d9edf7",
      textColor: "#31708f",
    },
    {
      code: "DBV",
      name: "demande de déblocage validée",
      description: "Vous pouvez finaliser le déblocage. Villyz s'occupera ensuite des opérations pour la récupération de vos fonds",
      bgColor: "#d9edf7",
      textColor: "#31708f",
    },
    {
      code: "DBF",
      name: "demande de déblocage finalisée",
      description: "Vous serez notifiés par mail dès que les fonds vous sont transférés et vos échéances commenceront dès lors",
      bgColor: "#d9edf7",
      textColor: "#31708f",
    },
    {
      code: "FIN",
      name: "financé",
      description: `Les fonds vous ont été transférés. ${!isDon ? "Les citoyens investisseurs seront remboursés à chaque échéance" : ""}`,
      bgColor: "#dff0d8",
      textColor: "#3c763d",
    },
    {
      code: "ANN",
      name: "annulé",
      description: "Vous n'avez pas souhaité recevoir les fonds collectés, la levée a été annulée le " +annulationDate+ " L'investissement des citoyens leur est remboursé.",
      bgColor: "#f2dede",
      textColor: "#a94442",
    },
    {
      code: "SUS",
      name: "suspendu",
      description: "La levée a été suspendu depuis " + daysAfterSuspension + " jours. La raison de la  suspension est : " +project.RaisonSuspension+ " . ",
      bgColor: "#f2dede",
      textColor: "#a94442",
    },
  ];
  
  const status = config.find((c) => c.code === statusCode);
  

  if (!status || ![ProfilCode.PORTEUR_PROJET,ProfilCode.VILLYZ].includes(profile)) {
    return null;
  }

  return (
    <div
      css={{
        color: status.textColor,
        background: status.bgColor,
        padding: "20px",
        marginBottom: "20px",
        fontSize: "1.3em",
      }}
    >
      {![StatutProjet.INFORMATION_MANQUANTE,StatutProjet.DEBOUCLAGE_VALIDE, StatutProjet.DEBOUCLAGE_FINALISE, StatutProjet.SUSPENDED].includes(status.code) && (
        <p css={{ margin: 0 }}>
        Votre projet est{" "}
        <span css={{ fontWeight: 600, fontSize: "1.2em" }}>{status.name}</span>
      </p>
      )}
      {status.code === StatutProjet.INFORMATION_MANQUANTE && (
        <p css={{ margin: 0 }}>
        Votre projet nécessite  {"  "}
        <span css={{ fontWeight: 600, fontSize: "1.2em" }}>des informations complémentaires</span>
        {"  "} avant d'être validé.
        </p>
      )}
      {status.code === StatutProjet.DEBOUCLAGE_VALIDE && (
        <p css={{ margin: 0 }}>
        Le {"  "}
        <span css={{ fontWeight: 600, fontSize: "1.2em" }}>débouclage</span>
        {"  "}  de votre collecte est accepté par Villyz.
        </p>
        
      )}
      {status.code === StatutProjet.DEBOUCLAGE_FINALISE && (
        <p css={{ margin: 0 }}>
        Le {"  "}
        <span css={{ fontWeight: 600, fontSize: "1.2em" }}>débouclage</span>
        {"  "}  de votre collecte est finalisé.
        </p>
        
      )}
      {status.code === StatutProjet.SUSPENDED  && (
        <p css={{ margin: 0 }}>
        Votre projet est{" "}
        <span css={{ fontWeight: 600, fontSize: "1.2em" }}>{status.name}</span>
      </p>
      )}
      <p>
        {status.description}
      </p>
    </div>
  );
}
