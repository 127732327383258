import React, { useEffect, useState } from "react";
import { ButtonLink } from "../generic/button-link";
import { AuthenticationState } from "../../model/state/authentication-state";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { Icon } from "semantic-ui-react";
import { UserInfoResponse } from "../../model/dto/response";
import { userService } from "../../services/authentication-redux-service";
import { OnboardingStatus } from "../../model/entities/onboarding-status";

interface Props {
  onActionSuccess?: () => void;
}

export function RemerciementOnboardingCitoyen({}: Props) {
  const [userInfo, setUserInfo] = useState<UserInfoResponse>();
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>((state) => state.authentication);

  useEffect(() => {
    userService
      .getUserInfo(loginProps?.oauth?.userId, loginProps?.oauth)
      .then((response) => {
        setUserInfo(response);
      });
  }, [loginProps?.oauth]);

  const infoBox = {
    backgroundColor: "#D0EAFE",
    color: "#000000",
    padding: "10px 20px",
    display: "flex",
    borderRadius: "4px",
    maxWidth: 'auto',
    margin: '0 auto',
  };

  const isPartiellementComplet = loginProps?.user?.OnboardingStatus?.Code === OnboardingStatus.PARTIELLEMENT_COMPLET;
  const isPresqueComplet = loginProps?.user?.OnboardingStatus?.Code === OnboardingStatus.PRESQUE_COMPLET;
  const isMailValid = loginProps?.user?.IsMailValid;
  const isJustifPiecesDone = userInfo?.IsPiecesJustifDone || userInfo?.Etablissement?.IsPieceJustifDone;

  return (
    <div>
      <div css={{ textAlign: "center", fontWeight: "bold" }}>
        <p css={{ fontSize: "20px", marginBottom: "8px" }}>C’est parti !</p>

        {isPartiellementComplet && isMailValid && (
          <div css={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <p css={{ marginBottom: '0', textAlign: 'left' }}>
              Vous pourrez investir après soumission de votre état civil et de votre adresse postale.
            </p>
          </div>
        )}

        {isPartiellementComplet && !isMailValid && (
          <div css={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <div css={{ marginLeft: "8px" }}>
              <p css={{ marginBottom: '0', textAlign: 'left' }}>
                Nous vous avons transmis un mail avec un lien sur lequel cliquer afin de vérifier que l’adresse mail de votre compte vous appartient effectivement.
              </p>
              <p css={{ fontStyle: "italic", textAlign: 'left' }}>
                (Astuce : si vous ne l’avez pas reçu, pensez à vérifier vos spams). Vous pourrez investir après soumission de votre état civil et de votre adresse postale.
              </p>
            </div>
          </div>
        )}

        {isPresqueComplet && isMailValid && !isJustifPiecesDone && (
          <div css={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
              <p css={{ marginBottom: '0', textAlign: 'left', marginLeft: "8px" }}>
                Vous pouvez commencer à investir dès à présent. Attention pensez à finaliser votre inscription en transmettant une copie de votre pièce d’identité depuis votre page profil.
              </p>
          </div>
        )}

        {isPresqueComplet && !isMailValid && !isJustifPiecesDone && (
          <div css={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <div css={{ marginLeft: "8px" }}>
              <p css={{ marginBottom: '0', textAlign: 'left' }}>
                Nous vous avons transmis un mail avec un lien sur lequel cliquer afin de vérifier que l’adresse mail de votre compte vous appartient effectivement (Astuce : si vous ne l’avez pas reçu, pensez à vérifier vos spams). Vous pouvez commencer à investir dès à présent.
              </p>
              <p css={{ textAlign: 'left' }}>
                Attention pensez à finaliser votre inscription en transmettant une copie de votre pièce d’identité depuis votre page profil.
              </p>
            </div>
          </div>
        )}

        {isPresqueComplet && isMailValid && isJustifPiecesDone && (
          <div css={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <p css={{ marginBottom: '0', textAlign: 'left', marginLeft: "8px"  }}>
              Vous pouvez commencer à investir dès à présent.
            </p>
          </div>
        )}

        {isPresqueComplet && !isMailValid && isJustifPiecesDone && (
          <div css={infoBox}>
            <Icon size="big" name="lightbulb outline" css={{ color: "#3C95E7" }} />
            <p css={{ marginBottom: '0', textAlign: 'left', marginLeft: "8px"  }}>
            Nous vous avons transmis un mail avec un lien sur lequel cliquer afin de vérifier que l’adresse mail de votre compte vous appartient effectivement (Astuce : si vous ne l’avez pas reçu, pensez à vérifier vos spams). Vous pouvez commencer à investir dès à présent.
            </p>
          </div>
        )}

        <div css={{ marginTop: "18px" }}>
          <ButtonLink label="Découvrir les projets" to="/projets" />
        </div>
      </div>
    </div>
  );
}
