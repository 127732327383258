import { trackPromise } from "react-promise-tracker";
import { DefaultResponse, DescriptionDocumentResponse, DescriptionDocumentsListResponse, EcheancierSimulationResponse, EtablissementMairieResponse, FichierAnalyseVillyzResponse, FichierInformationsCleResponse, LogoExistanceResponse, PostListResponse, PostReactionsResponse, ProjetDetailResponse, ProjetItemResponse, ProjetListResponse, ProjetsCardResponse, SocialProofResponse } from "../model/dto/response";
import { treatHttpResponseCode } from "./http-helper";
import { ProjetListItem } from "../model/dto/projet-list-item";
import { NewsLetterBody } from "../model/dto/body/inscription-user-newsletter-body";
import { SimulationBody } from "../model/dto/body/simulation-body";

export const publicService = {
  getProjetItemById,
  getProjetBySlug,
  getProjetList,
  getAnalyseFile,
  getCollectiviteNameByProjetId,
  getPostList,
  getPostReactions,
  checkLogoExistance,
  getInformationsFile,
  getDescriptionDocumentsByProjectId,
  getDescriptionDocument,
  getEtablissementMairieById,
  getSocialProof,
  inscriptionNewsLetter,
  getHomeProjects,
  getProjetItemForPorteurProjet,
  simulateEcheancierPrevisionnel
}

function getProjetItemById(projetId: number): Promise<ProjetItemResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
    "Content-Type": "application/json"
    },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/${projetId}/item`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetItemResponse>
  })
}

function getEtablissementMairieById(etablissementId: number): Promise<EtablissementMairieResponse> {
  const requestOptions = {
      method: "GET",
      headers: {
      "Content-Type": "application/json"
      },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/public/etablissement/${etablissementId}`, requestOptions)
  .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<EtablissementMairieResponse>
  })
}

function getProjetBySlug(slug?: string, isPageDon?: boolean): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
  };
  return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/` + slug + `?isPageDon=${isPageDon}`, requestOptions))
}

function getProjetList(isMecenatTabClicked?: boolean): Promise<ProjetListResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
  };
  
  return fetch(`${process.env.REACT_APP_BASE_URL}/public/projets?isMecenat=${isMecenatTabClicked}`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetListResponse>
  })
}

function getHomeProjects(): Promise<ProjetListItem[]> {
  const requestOptions = {
      method: 'GET',
      headers: { 
          'Content-Type': 'application/json'
      },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/home`, requestOptions)
  .then((response) => response.json() as Promise<ProjetListItem[]>)
}

function getProjetItemForPorteurProjet(): Promise<ProjetsCardResponse> {
  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json'
    },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/public/projet/porteur-de-projet`, requestOptions)
  .then((response) => response.json() as Promise<ProjetsCardResponse>)
}

function getCollectiviteNameByProjetId(projetId: number): Promise<string> {
    const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      };
    
    return fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/` + projetId + "/nom-collectivite", requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<string>
    })
}

function getAnalyseFile(projetId: number): Promise<FichierAnalyseVillyzResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json'
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/analyse-villyz-fichier/${projetId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<FichierAnalyseVillyzResponse>
    })
}

function getPostList(projectId: number): Promise<PostListResponse> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
       
      },
    };
  
    return fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/${projectId}/allposts`, requestOptions)
    .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<PostListResponse>
    })
  }

  function getPostReactions(projectId: number, postId: number): Promise<PostReactionsResponse>{
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };
  
    return fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/${projectId}/posts/${postId}/allReactions`, requestOptions)
    .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<PostReactionsResponse>
    })
  }


function checkLogoExistance(etablissementId: number): Promise<LogoExistanceResponse> {
  const requestOptions = {
    method: 'GET',
    headers: { 
        'Content-Type': 'application/json',
    }
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/public/check-logo/${etablissementId}`, requestOptions)
  .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<LogoExistanceResponse>
  })
}

function getInformationsFile(projetId: number): Promise<FichierInformationsCleResponse> {
  const requestOptions = {
      method: 'GET',
      headers: { 
          'Content-Type': 'application/json'
      }
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/informations-cle-fichier/${projetId}`, requestOptions)
  .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<FichierInformationsCleResponse>
  })
}

function getDescriptionDocumentsByProjectId(projetId: number): Promise<DescriptionDocumentsListResponse>{
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  };
  
  return fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/${projetId}/get-description-documents-names`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DescriptionDocumentsListResponse>
  })
}

function getDescriptionDocument(projetId: number, documentName: string): Promise<DescriptionDocumentResponse>{
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/${projetId}/get-description-document/${documentName}`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DescriptionDocumentResponse>
  })
}

function getSocialProof(): Promise<SocialProofResponse> {
  const requestOptions = {
      method: 'GET',
      headers: { 
          'Content-Type': 'application/json'
      },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/public/home/social-proof`, requestOptions)
  .then((response) => response.json() as Promise<SocialProofResponse>)
}

function inscriptionNewsLetter(body: NewsLetterBody): Promise<DefaultResponse> {
  const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
  };

  return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/public/user/inscription-newsletter`, requestOptions)
  .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<DefaultResponse>
  }))
}

function simulateEcheancierPrevisionnel(body: SimulationBody, projetId: number): Promise<EcheancierSimulationResponse> {
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/${projetId}/simulation-detail`, requestOptions)
  .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<EcheancierSimulationResponse>
  }))
}