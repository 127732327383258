import React, { useEffect, useState } from "react";

type FieldData = {
  value?: any;
  name?: string;
  isValid?: boolean;
  key?: string;
};

type Props = {
  data: FieldData;
  label?: string;
  error?: string;
  submitted?: boolean;
  editable?: boolean;
  maxLength?: number;
  noStyle?: boolean;
  placeholder?:string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName?: string,
    isValid?: boolean,
    key?: string
  ) => void;
  validateField?: (value: any) => boolean;
  disabled?: boolean,
  inputCss?: any,
  type?: string;
  required?: boolean;
  autocomplete?: string; 
  icon?: React.ReactNode
};

export function InputField({
data,
label,
error,
submitted,
editable,
maxLength,
noStyle,
placeholder,
onChange,
validateField,
disabled,
inputCss,
type,
required,
autocomplete,
icon

}:Props) {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (!submitted) return;

    const isValid = validateField?.(data.value) ?? false;
    setIsValid(isValid);

  }, [submitted]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validateField) {
      let message = validateField(event.target.value);
      if (!message) {
        setIsValid(false);
        if (onChange) {
          if (data.key) {
            onChange(event, data.name, false, data.key);
          } else {
            onChange(event, data.name, false);
          }
        }
      } else {
        setIsValid(true);
        if (onChange) {
          if (data.key) {
            onChange(event, data.name, true, data.key);
          } else {
            onChange(event, data.name, true);
          }
        }
      }
    } else {
      if (onChange) {
        if (data.key) {
          onChange(event, data.name, true, data.key);
        } else {
          onChange(event, data.name, true);
        }
      }
    }
  };

  return (
    <>
      <label>{label}</label>
      {(editable === undefined || editable) && (
        <div style={{position:'relative'}}>
          <input
            maxLength={maxLength}
            css={{...inputCss}}
            type={type}
            required={required}
            autoComplete={autocomplete}
            name={data.name}
            className={noStyle ? "" : "form-group"}
            placeholder={placeholder}
            value={data.value}
            onChange={onInputChange}
            disabled={disabled}
          />
          {icon && (
            <div 
              style={{ 
                position: 'absolute', 
                right: '8px', 
                top: '62%', 
                transform: 'translateY(-50%)',
                zIndex: 1
              }}
            >
              {icon}
            </div>
          )}
        </div>
      )}

      {!isValid && (
        <div
          style={{
            color: "#F03E3E",
            fontSize: "10px",
            marginTop: "4px",
          }}
        >
          {error}
        </div>
      )}
    </>
  );
}
