import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Icon } from "semantic-ui-react";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { SubmitButton } from "../generic/submit-button";
import { Investissementbody } from "../../model/dto/body/investissement-body";
import { investmentService } from "../../services/investment-service";
import { DefaultResponse, InvestResponse } from "../../model/dto/response";
import { BankProvider, Realbanks } from "../../model/entities/bank-provider";
import { useMemo } from "react";
import { LinkButton } from "../generic/link-button";
import { CheckBoxSimpleField } from "../generic/checkbox-simple-field/checkbox-simple-field";

type FieldData = {
    name?: string;
    value?: any;
    isValid?: boolean;
};

type FormData = {
    isChecked: FieldData;
};

type Props = {
    bankName?: string;
    body?: Investissementbody;
    projetId?: number;
    onSubmitImpossible?: (message: string) => void;
    onInvestVCLink?: () => void;
};

export function StrongAuthenticationInformationPane({
    bankName,
    body,
    projetId,
    onSubmitImpossible,
    onInvestVCLink,
}: Props) {
    const loginProps: AuthenticationState = useSelector<
        ApplicationState,
        AuthenticationState
    >((state) => state.authentication);

    const [formSubmitted, setFormSubitted] = useState(false);
    const [isFormError, setIsFormError] = useState(false);
    const [form, setForm] = useState<FormData>({
        isChecked: { value: "", name: "isChecked", isValid: true },
    });

    const onSubmitSuccess = (response: InvestResponse) => {
        if (response.IsTraitementOk) {
            window.location.href = response.RedirectURL!;
        }
    };

    const onSubmitFailure = (response: DefaultResponse) => {
        if (onSubmitImpossible) {
            onSubmitImpossible(response.Error ?? "");
        }
    };

    const isPreSubmitted = () => {
        setFormSubitted(true);
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        fieldName?: string,
        isValid?: boolean
    ): void => {
        const fieldValue: string = e.target.value;
        setIsFormError(false);
        setForm({
            ...form,
            [fieldName!]: {
                value: fieldValue,
                name: fieldName,
                isValid: isValid,
            },
        });
    };

    const validateMandatory = (value: any): boolean => {
        return value && value !== "";
    };

    const isFormValid = (): boolean => {
        return validateMandatory(form.isChecked.value);
    };

    let enSavoirPlusLink = "";
    let authName = "";
    let displayName = "";

    if (bankName === Realbanks.BANQUE_POSTALE) {
        enSavoirPlusLink =
            "https://www.labanquepostale.fr/particulier/comptes-et-cartes/espaces-clients/securite/certicode-plus.html";
        authName = "Certicode plus";
        displayName = "Banque Postale";
    } else if (bankName === Realbanks.CREDIT_AGRICOLE) {
        enSavoirPlusLink =
            "https://www.credit-agricole.fr/particulier/compte/service-bancaire/securipass.html";
        authName = "SécuriPass";
        displayName = "Crédit Agricole";
    } else if (bankName === Realbanks.CAISSE_EPARGNE) {
        enSavoirPlusLink =
            "https://www.caisse-epargne.fr/comptes-cartes/securpass/#anchor-dans-le-detail";
        authName = "Secur’Pass";
        displayName = "Caisse d'épargne";
    } else if (bankName === Realbanks.BANQUE_POPULAIRE) {
        enSavoirPlusLink =
            "https://www.banquepopulaire.fr/comptes-cartes/protection-des-operations-bancaires-securpass/";
        authName = "Secur’Pass";
        displayName = "Banque Populaire";
    } else {
        authName = "l’authentification forte";
    }

    const label = useMemo(() => {
        if (bankName === Realbanks.CREDIT_AGRICOLE) {
            return `Je confirme avoir téléchargé l’application mobile et activé ${authName} auprès du ${displayName}`;
        } else if (
            bankName === Realbanks.BANQUE_POPULAIRE ||
            bankName === Realbanks.BANQUE_POSTALE ||
            bankName === Realbanks.CAISSE_EPARGNE
        ) {
            return `Je confirme avoir téléchargé l’application mobile et activé ${authName} auprès de la ${displayName}`;
        } else {
            return "Je confirme avoir téléchargé l’application mobile et activé l’authentification forte";
        }
    }, [bankName, authName, displayName]);

    const flexContainer = {
        display: "flex",
        marginBottom: "12px",
    };

    const buttonFlexContainer = {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: "18px",
    };

    const linkStyles = {
        color: "#005877",
        fontWeight: 600,
        cursor: "pointer",
        fontStyle: "italic",
        textDecoration: "underline",
    };

    const bankListSpecificView = [
        Realbanks.BANQUE_POPULAIRE,
        Realbanks.BANQUE_POSTALE,
        Realbanks.CAISSE_EPARGNE,
        Realbanks.CREDIT_AGRICOLE,
    ];

    return (
        <div css={{ padding: "0 20px" }}>
            <div>
                {bankName === Realbanks.BANQUE_POSTALE && (
                    <Grid>
                        <Grid.Column>
                            <div>
                                <div
                                    css={{
                                        backgroundColor: "#D0EAFE",
                                        color: "#000000",
                                        padding: "5px 15px",
                                        display: "flex",
                                        alignItems: "center",
                                        borderRadius: "4px",
                                        marginTop: "12px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <Icon
                                        name="lightbulb outline"
                                        size="big"
                                        css={{
                                            color: "#3C95E7",
                                        }}
                                    />
                                    <p>
                                        Pensez à vérifier votre plafond,
                                        généralement la Banque Postale
                                        n’autorise pas les virements supérieurs
                                        à 4 000€
                                    </p>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid>
                )}
                <div>
                    <p
                        css={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginBottom: "0",
                        }}
                    >
                        Virement simplifié
                    </p>
                    <div
                        css={{
                            padding: "5px",
                            background: "#fff",
                            border: "2px solid #30BF71",
                            borderRadius: "3px",
                            display: "flex",
                            alignItems: "center",
                            marginTop: "6px",
                            marginBottom: "14px",
                        }}
                    >
                        <div>
                            <Icon
                                name="lightning"
                                size="big"
                                css={{ color: "#30BF71" }}
                            />
                        </div>
                        <div>
                            <p css={{ fontSize: "14px", fontWeight: "bold" }}>
                                Connexion directe avec votre banque, aussi
                                rapide que par carte bancaire
                            </p>
                            <p
                                css={{
                                    marginTop: "-13px",
                                    color: "#808080",
                                    fontSize: "14px",
                                }}
                            >
                                Paiement 100% sécurisé
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    {(bankListSpecificView.find((bnk) => bnk === bankName) && (
                        <p css={{ fontWeight: "bold", marginBottom: "0" }}>
                            Activez {authName} sur {displayName}
                        </p>
                    )) || (
                        <p
                            css={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                marginBottom: "0",
                            }}
                        >
                            Activez l’authentification forte
                        </p>
                    )}
                    <p
                        css={{
                            fontStyle: "italic",
                            fontSize: "14px",
                            marginBottom: "14px !important",
                        }}
                    >
                        Si vous ne le faites pas, votre investissement échouera.
                    </p>
                </div>
                <div>
                    <div css={flexContainer}>
                        <Icon
                            size="large"
                            name="mobile alternate"
                            css={{ color: "#005877" }}
                        />
                        {(bankListSpecificView.find(
                            (bnk) => bnk === bankName
                        ) && (
                            <p css={{ fontSize: "14px" }}>
                                Accédez à votre application mobile {displayName}
                            </p>
                        )) || (
                            <p css={{ fontSize: "14px" }}>
                                Télécharger l’application mobile de votre banque
                            </p>
                        )}
                    </div>
                    <div css={flexContainer}>
                        <Icon
                            size="large"
                            name="shield"
                            css={{ color: "#005877" }}
                        />
                        {(bankListSpecificView.find(
                            (bnk) => bnk === bankName
                        ) && (
                            <p css={{ fontSize: "14px" }}>
                                Activer {authName} depuis votre application
                            </p>
                        )) || (
                            <p css={{ fontSize: "14px" }}>
                                Vérifier que l’authentification forte est
                                activée
                            </p>
                        )}
                    </div>
                    {bankListSpecificView.find((bnk) => bnk === bankName) && (
                        <div css={flexContainer}>
                            <Icon
                                size="large"
                                name="lightbulb outline"
                                css={{ color: "#005877" }}
                            />
                            <a
                                href={enSavoirPlusLink}
                                css={linkStyles}
                                target="_blank"
                            >
                                En savoir plus sur le {authName}
                            </a>
                        </div>
                    )}
                </div>
                <div>
                    <p
                        css={{
                            fontWeight: "bold",
                            margin: "20px 0px 3px !important",
                            fontSize: "16px",
                        }}
                    >
                        Impossible d’activer {authName} ?
                    </p>
                    <p css={{ fontSize: "14px" }}>
                        Vous pouvez réaliser votre investissement par{" "}
                        <strong>virement classique</strong>
                    </p>
                </div>
                <div css={{ marginTop: "14px" }}>
                    <CheckBoxSimpleField
                        data={form.isChecked}
                        label={label}
                        error="Si vous n’avez pas d’application bancaire nous vous invitons à investir par virement classique"
                        submitted={formSubmitted}
                        onChange={(value: any, isValid: boolean) => {
                            handleInputChange(
                                { target: { value } } as any,
                                form.isChecked.name,
                                isValid
                            );
                        }}
                        validateField={validateMandatory}
                    />
                </div>
                <div css={buttonFlexContainer}>
                    <LinkButton
                        label="Virement classique"
                        onClick={onInvestVCLink}
                    />
                    <SubmitButton
                        label="Continuer vers votre banque"
                        name="paymentButton"
                        data={body}
                        action={() =>
                            investmentService.invest(
                                projetId!,
                                body!,
                                loginProps?.oauth
                            )
                        }
                        onActionSuccess={onSubmitSuccess}
                        onActionFailure={onSubmitFailure}
                        validateForm={isFormValid}
                        onPreSubmit={isPreSubmitted}
                    />
                </div>
            </div>
        </div>
    );
}
