import React, { useState, useEffect, useRef } from "react";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { AuthenticationState } from "../../model/state/authentication-state";
import { CheckWalletAndBankingAliasResponse, InvestResponse } from "../../model/dto/response";
import { Investissementbody } from "../../model/dto/body/investissement-body";
import { userService } from "../../services/authentication-redux-service";
import { investmentService } from "../../services/investment-service";
import {Loader} from "semantic-ui-react";
import { FormErrorHeader } from "../generic/form-error-header";

type Props = {
    montant?: number | string
    projetId?: number
    modePaiement: string,
    isDon?: boolean,
    onSubmitImpossible?: (message: string) => void
    onInvestSuccess?: (response : InvestResponse)=> void
}

export function AttentePaiementPane({montant, projetId, modePaiement,isDon, onSubmitImpossible, onInvestSuccess}: Props) {
    const isPolling = useRef(true)
    const [isLoading, setIsLoading] = useState(true);
    const [isTimeout, setIsTimeout] = useState(true)
    const [responseError, setResponseError] = useState<string | undefined>();

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication);

    const onSubmitSuccess = (response: InvestResponse): void => {
        if (response.IsTraitementOk) {
            if(onInvestSuccess) {
                onInvestSuccess(response)
            }
        }
        if(response.IsTraitementOk === false){
            setIsLoading(false)
            setResponseError(response.Error)
        }
    };
    const body: Investissementbody = {
        Montant: montant?.toString(),
        ModePaiementCode: modePaiement,
        IsDon: isDon,
    };

    const fetchData = async () => {
        const response : CheckWalletAndBankingAliasResponse = await userService.checkWalletAndBankingAlias(loginProps?.user?.UserId, loginProps?.oauth)
        if(response.IsMangoPayObjectCreated === true) {
            isPolling.current = false;
            setIsTimeout(false)
        }
    };

    useEffect(() => {
        fetchData()
        const timeout = setTimeout(() => {
            setIsLoading(false);
            setIsTimeout(true)
            isPolling.current = false

        }, 60000);

        const poll = setInterval(() => {
            if(isPolling.current === true) {
                fetchData();
            }else{
                clearInterval(poll)
            }
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
       
    }, [isPolling]);

    const fetchInvest = async () => {
        const investResponse = await investmentService.invest(projetId!, body!, loginProps?.oauth)
        if (investResponse) {
            onSubmitSuccess(investResponse);
        }
    };

    useEffect(() => {
        if (isPolling.current === false && !isTimeout) {
            fetchInvest()
        }
    }, [isPolling, isTimeout]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    {isLoading ?
                        (
                            <div style={{ padding: "25px", minHeight: "100px"}}>
                                <Loader active inline="centered" />
                             
                                <p style={{marginTop:"10px"}}>Veuillez patienter quelques instants pendant que nous initialisons votre paiement...</p>
                            </div>
                        )
                        :
                        responseError === undefined || !responseError ?
                        (
                            <p>L'opération prend plus de temps que prévu. Veuillez nous excuser de la gêne occasionnée et réessayer d'ici quelques minutes. En cas de difficultés, n'hésitez pas à nous contacter à l'adresse <strong>investisseurs@villyz.fr</strong></p>
                        ) : 
                        (                
                            <FormErrorHeader message={responseError} displayed={responseError !== null} withoutMarginBottom />
                        )
}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
