import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Grid } from "semantic-ui-react"
import { BankProvider } from "../../model/entities/bank-provider"
import { AuthenticationState } from "../../model/state/authentication-state"
import { projetService } from "../../services/projet-service"
import { ApplicationState } from "../../store"
import { BankProviderSelector } from "./bank-provider-selector"

type Props = {
    selectedProvider?: BankProvider | undefined
    setSelectedProvider?: (bankProvider: BankProvider | undefined) => void
}

export function SelectBankPane({ selectedProvider, setSelectedProvider }: Props) {
    const [bankProviderList, setBankProviderList] = useState<BankProvider[]>([])
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(() => {
        projetService.getBankProviders(loginProps?.oauth)
            .then(response => {
                if (response.IsTraitementOk) {
                    setBankProviderList(response.ProviderList!)
                }
            })
    }, [])

    const onSelectProvider = (provider?: BankProvider) => {
        setSelectedProvider?.(provider)
    }

    return (
        <>
            <h3 css={{margin: "0"}}>Veuillez sélectionner votre banque</h3>
            <div css={{
                padding: "5px",
                fontSize: "12px",
                fontStyle: "italic",
                marginBottom: "10px"
            }}>
                Si votre banque n’est pas dans la liste, vous pouvez contacter investisseurs@villyz.fr pour effectuer un virement.
            </div>
            <Grid columns={1}>
                <div css={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    maxHeight: "170px",
                    width: "100%"
                }}>
                    {bankProviderList.map(bankProvider => {
                        return (
                            <Grid.Column css={{
                                paddingTop: "0px !important",
                                paddingBottom: "0px !important",
                                paddingRight: "0px !important"
                            }}>
                                <BankProviderSelector
                                    provider={bankProvider}
                                    selectedProvider={selectedProvider}
                                    onSelectProvider={onSelectProvider}
                                />
                            </Grid.Column>
                        )
                    })}
                </div>
            </Grid>
        </>
    )
}