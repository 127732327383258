import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { Grid, Icon } from "semantic-ui-react";
import { InvestResponse } from "../../model/dto/response";
import { AuthenticationState } from "../../model/state/authentication-state";
import { investmentService } from "../../services/investment-service";
import { ApplicationState } from "../../store";
import { SocialMediaShareBar } from "../projet/social-media-share-bar";

type Props = {
    loginProps?: AuthenticationState,
    transactionId: number,
    mandateId?: number,
    isDirectDebit: boolean
}

function RemerciementInvestissement({loginProps, transactionId, mandateId, isDirectDebit}: Props) {
    const [investResponse, setInvestResponse] = useState({})

    return (
        <Grid stackable>
            <Grid.Row centered>
                <Grid.Column width={10}>
                <div css={{
                    display: "block",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginTop: "40px"
                }}
                >
                    <div css={{marginBottom: "20px"}}>
                        Merci !
                    </div>
                    <div
                        css={{marginBottom: "20px"}}
                    >
                        Félicitations pour votre engagement citoyen !
                    </div>
                    <div css={{marginBottom: "20px"}}>
                        Votre paiement a bien été pris en compte, vous pouvez suivre son statut dans votre tableau de bord.
                    </div>
                    <div css={{marginBottom: "20px"}}>
                        Faites connaître le projet sur les réseaux sociaux !
                    </div>
                    {/* <div>
                        <SocialMediaShareBar
                            url={`http://villyz.fr/`}
                            text={`Projet Villyz`}
                        />
                    </div> */}
                </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedRemerciementInvestissement = connect(mapStateToProps, null)(RemerciementInvestissement);
export { ConnectedRemerciementInvestissement as RemerciementInvestissement };