export interface BankProvider {
    ProviderId?: string,
    LogoUrl?: string,
    IconUrl?: string,
    DisplayName?: string,
    Country?: string,
    HasBranches?: boolean,
    IsActive?: boolean,
    ProviderMockId?: string
}

const CAISSE_EPARGNE: string = "stet-caisse-d-epargne";
const BANQUE_POSTALE: string = "stet-banque-postale";
const CREDIT_AGRICOLE: string = "stet-credit-agricole";
const BANQUE_POPULAIRE: string = "stet-banque-populaire";

export const Realbanks = {
    CAISSE_EPARGNE,
    BANQUE_POSTALE,
    CREDIT_AGRICOLE,
    BANQUE_POPULAIRE
};