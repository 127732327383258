import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { Container, Grid } from "semantic-ui-react";
import { EcheanceListResponse } from "../../../../model/dto/response";
import { AuthenticationState } from "../../../../model/state/authentication-state";
import { investmentService } from "../../../../services/investment-service";
import { ApplicationState } from "../../../../store";
import { PaginatedTable } from "../../../generic/paginated-table/paginated-table";
import { format } from "date-fns"
import { StatutEcheance } from "../../../../model/entities/statut-echeance";
import { EcheanceDtoItem } from "../../../../model/dto/echeance-dto-item";
import { LoadingSpinner } from "../../../generic/loading-spinner";
import { trackPromise } from "react-promise-tracker";

type Props = {

}

export function EcheanceCitoyenTable({}: Props) {
    const [histoRemboursement, setHistoRemboursement] = useState<
    EcheanceDtoItem[]
    >([]);

    const [histoRemboursementFuture, setHistoRemboursementFuture] = useState<
    EcheanceDtoItem[]
    >([]);

    const [isLoading, setIsLoading] = useState(false)

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    
    const mappingStatut = [
        {
            code: StatutEcheance.PREVUE,
            message: 'Prévue'
        },
        {
            code: StatutEcheance.INITIEE,
            message: 'Initiée'
        },
        {
            code: StatutEcheance.VALIDEE,
            message: 'Validée'
        },
        {
            code: StatutEcheance.ERREUR,
            message: 'En erreur'
        }
    ]

    useEffect(() => {
        setIsLoading(true)
        trackPromise(investmentService.getRecentPastEcheance(loginProps?.oauth)
          .then((response: EcheanceListResponse) => {
                setHistoRemboursement(response.RemboursementList!)
                setIsLoading(false)
          }));

        trackPromise(investmentService.getNearFutureEcheance(loginProps?.oauth)
          .then((response: EcheanceListResponse) => {
                setHistoRemboursementFuture(response.RemboursementList!)
                setIsLoading(false)
          }));
      }, []);
      
    return (
        <Grid>
            <Grid.Column width={9}>
                <Container>
                    <h1 className="py-8 text-3xl" css={{textAlign: 'center'}}>Mes dernières échéances</h1>
                    <LoadingSpinner height={100} />
                    {!isLoading && histoRemboursement?.length ? (
                        <PaginatedTable 
                        elements={histoRemboursement}
                        elementsPerPage={5}
                        headers={
                            [
                                'Projet', 
                                'Date d\'échéance prévue',
                                'Statut échéance',
                                'Montant',
                                'Identifiant opération',
                                'Identifiant échéance'
                            ]
                        }
                        columnSelector={(element: EcheanceDtoItem) => {
                            return {
                                values: [
                                    element.TitreProjet,
                                    element.DateEcheance ? format(new Date(element.DateEcheance), "dd/MM/yyyy") : '',
                                    mappingStatut.find(s => s.code === element.StatutEcheanceCode)?.message,
                                    element.Montant ? element.Montant / 100 + '€' : '',
                                    element.OperationId,
                                    element.EcheanceId
                                ]
                            }
                        }}
                        />
                    ) : (
                        <div className="text-center p-8 text-xl">
                        <span className="font-bold">
                            Vous n'avez encore aucune échéance passée
                        </span>
                        <p>
                            Vos échéances passées apparaîtront ici
                        </p>
                        </div>
                    )}
                </Container>
            </Grid.Column>
            <Grid.Column width={7}>
            <Container>
                    <h1 className="py-8 text-3xl" css={{textAlign: 'center'}}>Mes prochaines échéances</h1>
                    <LoadingSpinner height={100} />
                    {!isLoading && histoRemboursementFuture?.length ? (
                        <PaginatedTable 
                        elements={histoRemboursementFuture}
                        elementsPerPage={5}
                        headers={
                            [
                                'Projet', 
                                'Date d\'échéance prévue',
                                'Montant',
                                'Identifiant échéance'
                            ]
                        }
                        columnSelector={(element: EcheanceDtoItem) => {
                            return {
                                values: [
                                    element.TitreProjet,
                                    element.DateEcheance ? format(new Date(element.DateEcheance), "dd/MM/yyyy") : '',
                                    element.Montant ? element.Montant / 100 + '€' : '',
                                    element.EcheanceId
                                ]
                            }
                        }}
                        />
                    ) : (
                        <div className="text-center p-8 text-xl">
                        <span className="font-bold">
                            Vous n'avez encore aucune échéance prévue
                        </span>
                        <p>
                            Vos prochaines échéances apparaîtront ici
                        </p>
                        </div>
                    )}
                </Container>
            </Grid.Column>
        </Grid>
    )
}