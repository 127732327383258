import React, { useEffect, useState } from "react"
import { Icon, Popup } from "semantic-ui-react"
import { SemanticCOLORS, SemanticICONS } from "semantic-ui-react/dist/commonjs/generic"
import { StatutPieceJustificative } from "../../model/entities/statut-piece-justificative"

type Props = {
    statutPieceCode?: string
}

export function StatutPieceIcon({statutPieceCode}: Props) {
    const [iconName, setIconName] = useState<SemanticICONS>("exclamation circle")
    const [iconColor, setIconColor] = useState<SemanticCOLORS>("blue")
    const [iconMessage, setIconMessage] = useState("")

    useEffect(() => {
        if(statutPieceCode === StatutPieceJustificative.NON_ENVOYE) {
            setIconMessage("Pièce non transmise")
            setIconColor("grey")
            setIconName("exclamation circle")
        }
                
        if(statutPieceCode === StatutPieceJustificative.TRANSMISE || statutPieceCode === StatutPieceJustificative.EN_TRAITEMENT) {
            setIconMessage("Pièce en cours de traitement")
            setIconColor("blue")
            setIconName("wait")
        }
        
        if(statutPieceCode === StatutPieceJustificative.VALIDE) {
            setIconMessage("Pièce validée")
            setIconColor("green")
            setIconName("check circle")
        }
                
        if(statutPieceCode === StatutPieceJustificative.EN_ERREUR) {
            setIconMessage("Pièce en erreur")
            setIconColor("red")
            setIconName("exclamation circle")
        }
      }, [statutPieceCode]);

    return (
        <Popup 
            content={iconMessage}
            trigger={
                <Icon
                    name={iconName}
                    className="last-input"
                    size="huge"
                    color={iconColor}
                />
            }
        />
    )
}