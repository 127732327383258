export interface TypeInvestisseurMorale {
  Code: string;
  Denomination: string;
}

const ASSOCIATION: string = "ASC";
const COLLECTIVITE: string = "COL";
const SOCIETE: string = "SOC";

export const TypeInvestisseurMorale = {
  ASSOCIATION,
  COLLECTIVITE,
  SOCIETE,
};


