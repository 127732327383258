import { trackPromise } from "react-promise-tracker";

export function uploadImage(
  uploadUrl: string,
  readUrl: string,
  bearerToken: string,
  file: File,
  onUploaded: (src: string | null) => void
) {
  if (file) {
    const formData = new FormData();
    formData.append("image", file);

    trackPromise(fetch(uploadUrl, {
      method: "POST",
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + bearerToken
      }
    })
      .then((response) => response.json())
      .then((data) => {
        // Get the returned src property
        onUploaded(`${readUrl}?fileName=${data.FileName}`);
      })
      .catch((error) => {
        console.error(error);
        onUploaded(null);
      }), "dialog-image");
  }
}
