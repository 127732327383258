import React, { useEffect, useState } from "react"
import { EditableBlock } from '../../generic/editable-block/editable-block'
import { userService } from "../../../services/authentication-redux-service"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { ApplicationState } from "../../../store"
import { useSelector } from "react-redux"
import { AdminParams } from "../../../model/entities/admin-params"
import { ParamAdminForm } from "./param-admin-form"

type Props = {

}

type FieldData = {
    value?: any,
    name: string,
    isValid?: boolean,
    key?: string
};
    
type FormData = {
    decalageBatchCloture: FieldData,
    decalageBatchEcheance: FieldData,
    ancienneteHistoEvenement: FieldData
}

export function ParametresAdmin({}: Props) {
    const [paramsList, setParamsList] = useState<AdminParams[]>([])
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(() => {
        userService.getAllAdminParams(loginProps?.oauth?.userId, loginProps?.oauth)
        .then(response => {
            if(response.IsTraitementOk) {
                setParamsList(response.AdminParamsList!)
            }
        })
    }, [])

    const onFormSubmit = (data: AdminParams[]) => {
        setParamsList(data)
    }

    return (
        <>
            <EditableBlock
                title="Paramètres Admin"
                editor={(close) => {
                    return (
                        <ParamAdminForm
                            params={paramsList}
                            onFormSubmit={(data) => {
                                onFormSubmit(data)
                                close()
                            }}
                        />
                    )
                }}
            >
                <>
                    <div css={{
                        marginTop: '20px',
                        marginBottom: '10px'
                    }}>
                        Décalage batch publication/clôture projet (en jours) : {paramsList.find(p => p.Key === AdminParams.DECALAGE_BATCH_CLOTURE_KEY)?.Value}
                    </div>
                    <div css={{
                        marginBottom: '10px'
                    }}>
                        Décalage batch résolution échéance (en jours) : {paramsList.find(p => p.Key === AdminParams.DECALAGE_CALCUL_ECHEANCE_KEY)?.Value}
                    </div>
                    <div css={{
                        marginBottom: '10px'
                    }}>
                        Ancienneté de la recherche d'évènements MangoPay (en jours) : {paramsList.find(p => p.Key === AdminParams.NB_JOUR_RECHERCHE_EVT_MANGOPAY_KEY)?.Value}
                    </div>
                    <div css={{
                        marginBottom: '10px'
                    }}>
                        Décalage en jour du batch pour l'annulation des projets en débouclage possible depuis trop longtemps (en jours) : {paramsList.find(p => p.Key === AdminParams.DECALAGE_BATCH_CANCEL_PROJET_KEY)?.Value}
                    </div>
                    <div css={{
                        marginBottom: '10px'
                    }}>
                        Email de redirection pour les tests : {paramsList.find(p => p.Key === AdminParams.EMAIL_TEST_KEY)?.Value}
                    </div>
                    <div css={{
                        marginBottom: '10px'
                    }}>
                        Décalage post automatique anniversaire projet (en jours) : {paramsList.find(p => p.Key === AdminParams.DECALAGE_POST_AUTO)?.Value}
                    </div>
                    <div css={{
                        marginBottom: '10px'
                    }}>
                        Délai d'expiration d'un virement (en heures) : {paramsList.find(p => p.Key === AdminParams.DELAI_VIREMENT_EXPIRED)?.Value}
                    </div>
                    <div css={{
                        marginBottom: '10px'
                    }}>
                        TrueLayer actif ? {paramsList.find(p => p.Key === AdminParams.IS_TRUELAYER_ACTIVE)?.Value === 'true' ? 'Oui' : 'Non'}
                    </div>
                    <div css={{
                        marginBottom: '10px'
                    }}>
                        Montant mise en avant paiement CB : {paramsList.find(p => p.Key === AdminParams.MONTANT_CB_EN_AVANT)?.Value}{' '}€
                    </div>
                    <div css={{
                        marginBottom: '10px'
                    }}>
                        Délai d'expiration réservation virement (jours) : {paramsList.find(p => p.Key === AdminParams.DELAI_EXPIRATION_RESERVATION_KEY)?.Value}
                    </div>
                </>
            </EditableBlock>
            
        </>
    )
}