import { trackPromise} from 'react-promise-tracker';
import { OAuthResponse } from "../model/authentication/oauth-response"
import { AdminParamsListResponse, DefaultResponse, IsKYCValidatedResponse, MailUpdateResponse, MultipleFileRegistrationResponse, OnboardingCitoyenResponse, OnboardingCollectiviteResponse, TypePiecesListForUserResponse, UserInfoResponse, UserPiecesJustificativesListResponse, UsersListResponse, UserQuestionReponseListResponse, ResultatTestConnaissanceResponse, OnboardingSEMResponse, BeneficiairesListResponse, ReportingInfosResponse, OnboardingInvestisseurResponse, InscriptionUserNewsLetterResponse, CheckWalletAndBankingAliasResponse, CalculateMontantCompteVillyzResponse, CalculateUserSumPrevisionnelForProjectResponse, DelaiReservationVirementResponse } from "../model/dto/response";
import { EtatCivilRegistrationBody } from "../model/dto/body/etat-civil-registration-body";
import { UserPieceJustificative } from '../model/entities/user-piece-justificative';
import { RegisterIdentificationCollectiviteBody } from "../model/dto/body/register-identification-collectivite-body"
import { RegisterVerificationCollectiviteBody } from "../model/dto/body/register-verification-collectivite-body"
import { RegisterRepresentantsCollectiviteBody } from "../model/dto/body/register-representant-collectivite-body"
import { RegisterAdressePostaleBody } from "../model/dto/body/register-adresse-postale-body"
import { TypePieceJustificative } from '../model/entities/type-piece-justificative';
import { RegisterAdresseCollectiviteBody } from "../model/dto/body/register-adresse-collectivite-body"
import { EtablissementPieceJustificative } from "../model/entities/etablissement-piece-justificative"
import { AdminParamsBody } from "../model/dto/body/admin-params-body"
import { treatHttpResponseCode, jsonPostTrackedJsonFetch } from './http-helper';
import { EtatCivilRegistrationAdminBody } from '../model/dto/body/etat-civile-registration-admin-body';
import { RegisterAdressePostaleAdminBody } from '../model/dto/body/register-adresse-postale-admin-body';
import { AdresseMailRegistrationBody } from '../model/dto/body/adresse-mail-registration-body';
import { RegisterVerificationCollectiviteAdminBody } from '../model/dto/body/register-verification-collectivite-admin-body';
import { RegisterAdresseCollectiviteAdminBody } from '../model/dto/body/register-adresse-collectivite-admin-body';
import { RegisterIdentificationCollectiviteAdminBody } from '../model/dto/body/register-identification-collectivite-admin-body';
import { KycInformationsBody } from '../model/dto/body/KYC-informations-body';
import { HistoInvestInformationsBody } from '../model/dto/body/histo-invest-informations-body';
import { HistoRemboursementBody } from '../model/dto/body/histo-remboursement-body';
import { HistoRetraitBody } from '../model/dto/body/histo-retrait-body';
import { UpdateProfilStatusBody } from '../model/dto/body/update-profil-status-body';
import { RegisterExperienceInvestissementBody } from "../model/dto/body/register-experience-investissement-body"
import { SaveValeurPatrimonialeBody } from '../model/dto/body/save-valeur-patrimoniale-body';
import { RegisterBeneficiairesSEMBody } from '../model/dto/body/register-beneficiaires-sem';
import { RegisterIdentificationSEMBody } from '../model/dto/body/register-identification-sem-body';
import { RegisterIdentificationTypeInvestisseurBody } from '../model/dto/body/register-identification-type-investisseur-body';
import { ValidateModificationByAdminBody } from '../model/dto/body/validate-modification-by-admin-body';
import { BlockUserBody } from '../model/dto/body/block-user-body';
import { ResendValidationEmailBody } from '../model/dto/body/resend-validation-email-body'
import { ValidationMailWithCodeBody } from '../model/dto/body/validation-mail-with-code-body';

export const userService = {
    login2,
    logout,
    getTypePieceForUser,
    getKYCStatut,
    getUserPiecesJustifList,
    getAllAdminParams,
    registerEtatCivil,
    registerNewAccount,
    registerIdentificationCollectivite,
    registerVerificationCollectivite,
    registerRepresentantsCollectivite,
    registerAdressePostale,
    registerAdresseEtablissement,
    registerAdresseRepresentant,
    registerMultipleGenericFiles,
    registerMultipleTypedFiles,
    registerAllAdminParams,
    validateEmail,
    authenticatedAction,
    uploadLogo,
    validateModifications,
    getAllUsers,
    registerIdentificationTypeInvestisseur,
    registerEtatCivilByAdmin,
    registerAdressePostaleByAdmin,
    registerMultipleTypedFilesAdmin,
    getUserPiecesJustifListForAdmin,
    getTypePieceUserForAdmin,
    getUserInfoForAdmin,
    registerMailByAdmin,
    registerVerificationCollectiviteByAdmin,
    registerAdresseEtablissementByAdmin,
    registerAdresseRepresentantByAdmin,
    registerIdentificationCollectiviteByAdmin,
    registerRepresentantsCollectiviteByAdmin,
    updateKycInformationsByAdmin,
    updateHistoInvestInfoByAdmin,
    updateHistoRemboursementInfoByAdmin,
    updateHistoRetraitInfoByAdmin,
    updateEventHistory,
    GenerateUtilisateursReportCSV,
    GenerateKYCReportCSV,
    registerExperienceInvestissement,
    getUserQuestionReponseList,
    getResultatTestConnaissance,
    saveValeurPatrimoniale,
    registerIdentificationSEM,
    registerBeneficiairesSEM,
    getBeneficiaires,
    inscriptionUserNewsLetter,
    getUserInfo,
    validateModificationsByAdmin,
    changeBlockStatus,
    SendValidationMailWithCode,
    checkWalletAndBankingAlias,
    calculateMontantCompteVillyz,
    calculateUserSumPrevisionnelForProject,
    getDelaiReservationVirement
};

function login2(mail: string, password: string): Promise<OAuthResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
        }, 
        body: 'grant_type=password&username=' + encodeURIComponent(mail) + '&password=' + encodeURIComponent(password)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/Token`, requestOptions)
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('oauth_user');
    localStorage.removeItem('user_info');
}

function validateEmail(validationCode: string){
    return jsonPostTrackedJsonFetch<DefaultResponse>("/public/user/validate-email", {
        validationCode
    })
}

function authenticatedAction(oauth: OAuthResponse | undefined) {
    return {
        resendValidationEmail(body : ResendValidationEmailBody, userId: number| undefined){
            return jsonPostTrackedJsonFetch<DefaultResponse>(`/api/${userId}/user/resendValidationEmail`, body, oauth)
        }
    }
}

function SendValidationMailWithCode(body : ValidationMailWithCodeBody, userId: number| undefined){
    return jsonPostTrackedJsonFetch<DefaultResponse>(`/api/${userId}/user/resendValidationEmail`, body)
}


function registerNewAccount(body: any): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/public/user/register`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    }))
}

function getUserInfo(userId: number | undefined, oauth: OAuthResponse | undefined): Promise<UserInfoResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/info`, requestOptions)
        .then(response => {
            treatHttpResponseCode(response.status)
            return response.json() as Promise<UserInfoResponse>
        })
}

function registerEtatCivil(body: EtatCivilRegistrationBody, userId: number| undefined, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-etat-civil`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    }))

}

function registerIdentificationCollectivite(body: RegisterIdentificationCollectiviteBody, userId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingCollectiviteResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-identification-collectivite/${oauth?.etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCollectiviteResponse>
    })
}

function registerIdentificationTypeInvestisseur(body: RegisterIdentificationTypeInvestisseurBody, userId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingInvestisseurResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-identification-type-investisseur`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingInvestisseurResponse>
    })
}

function registerVerificationCollectivite(body: RegisterVerificationCollectiviteBody, userId: number| undefined, etablissementId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingCollectiviteResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-verification-collectivite/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCollectiviteResponse>
    }))
}

function registerRepresentantsCollectivite(body: RegisterRepresentantsCollectiviteBody, userId: number| undefined, etablissementId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingCollectiviteResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-representants-collectivite/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCollectiviteResponse>
    }))
}

function registerAdressePostale(body: RegisterAdressePostaleBody, userId: number| undefined, oauth: OAuthResponse | undefined) : Promise<OnboardingCitoyenResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-adresse-postale`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCitoyenResponse>
    }))
}

function registerMultipleGenericFiles(fileList: FileList | undefined, userId: number| undefined, oauth: OAuthResponse | undefined): Promise<OnboardingCollectiviteResponse> {
    let formData = new FormData()

    if(fileList) {
        for(let i = 0; i < fileList.length; i++) {
            formData.append("file" + i, fileList[i]);
        }
        
        formData.append("user", oauth!.userMail!);
        formData.append("typePiece", TypePieceJustificative.GENERIC);
    
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + oauth?.access_token 
            },
            body: formData
        };
    
        return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-multiple-pieces-justif`, requestOptions)
        .then(response => {
            treatHttpResponseCode(response.status)
            return response.json() as Promise<OnboardingCollectiviteResponse>
        })
    }
    else {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + oauth?.access_token 
            }
        };
    
        return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-multiple-pieces-justif`, requestOptions)
        .then(response => {
            treatHttpResponseCode(response.status)
            return response.json() as Promise<OnboardingCollectiviteResponse>
        }))
    }
}

function registerAdresseEtablissement(body: RegisterAdresseCollectiviteBody, userId: number| undefined, etablissementId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingCollectiviteResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-adresse-etablissement/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCollectiviteResponse>
    }))
}

function registerAdresseRepresentant(body: RegisterAdresseCollectiviteBody, userId: number| undefined, etablissementId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingCollectiviteResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-adresse-representant/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCollectiviteResponse>
    }))
}

function getTypePieceForUser(userId: number| undefined, oauth: OAuthResponse | undefined): Promise<TypePiecesListForUserResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/type-piece`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TypePiecesListForUserResponse>
    })
}

function getKYCStatut(userId: number| undefined, oauth: OAuthResponse | undefined): Promise<IsKYCValidatedResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/kyc-validated`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<IsKYCValidatedResponse>
    })
}

function getUserPiecesJustifList(userId: number | undefined, oauth: OAuthResponse | undefined): Promise<UserPiecesJustificativesListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/pieces-justif-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TypePiecesListForUserResponse>
    }))
}

function registerMultipleTypedFiles(files: (UserPieceJustificative | EtablissementPieceJustificative)[], userId: number| undefined, oauth: OAuthResponse | undefined, workflowTypeCode: string): Promise<MultipleFileRegistrationResponse> {
    let formData = new FormData()
    let compteur = 0;
    for(let i = 0; i < files.length; i++) {
        if (!files[i].DocumentVerso) {
            formData.append("file" + compteur, files[i].Document!);
            formData.append("typePiece" + compteur, files[i].TypePieceByProfilId!.toString());
        } else {
            formData.append("file" + compteur, files[i].Document!);
            formData.append("typePiece" + compteur, files[i].TypePieceByProfilId!.toString());
            compteur++;
            formData.append("file" + compteur, files[i].DocumentVerso!);
            formData.append("typePiece" + compteur, files[i].TypePieceByProfilId!.toString());
        }
        compteur++;
    }
    
    formData.append("user", oauth!.userMail!);
    

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: formData
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-typed-piece-list?workflowTypeCode=${workflowTypeCode}`, requestOptions)
    .then(response => {
        //treatHttpResponseCode(response.status)
        return response.json() as Promise<MultipleFileRegistrationResponse>
    }))
}

function registerAllAdminParams(body: AdminParamsBody, userId: number | undefined, oauth: OAuthResponse | undefined): Promise<AdminParamsListResponse> {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    }

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/admin-params`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<AdminParamsListResponse>
    }))
}

function getAllAdminParams(userId: number | undefined, oauth: OAuthResponse | undefined): Promise<AdminParamsListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/admin-params`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<AdminParamsListResponse>
    }))
}

async function uploadLogo(data: File, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
    let formData = new FormData();
    formData.append("file", data);
    formData.append("body", oauth?.etablissementId?.toString()!);
  
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + oauth?.access_token,
      },
      body: formData,
    };
  
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/etablissement/${oauth?.etablissementId}/upload-logo`, requestOptions)
    .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<DefaultResponse>
    })
}
function validateModifications(oauth: OAuthResponse | undefined, userId: number|undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/validate-modifications`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    }))
}
function getAllUsers(userId: number | undefined, oauth: OAuthResponse | undefined): Promise<UsersListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/all-users`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<UsersListResponse>
    }))
}
function registerEtatCivilByAdmin(body: EtatCivilRegistrationAdminBody, userId: number| undefined, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-etat-civil-admin`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    }))

}

function registerAdressePostaleByAdmin(body: RegisterAdressePostaleAdminBody, userId: number| undefined, oauth: OAuthResponse | undefined) : Promise<OnboardingCitoyenResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-adresse-postale-admin`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCitoyenResponse>
    }))
}

function registerMultipleTypedFilesAdmin(files: (UserPieceJustificative | EtablissementPieceJustificative)[], userId: number| undefined, currentUserId: number| undefined,  oauth: OAuthResponse | undefined): Promise<MultipleFileRegistrationResponse> {
    let formData = new FormData()
    let compteur = 0;
    for(let i = 0; i < files.length; i++) {
        if (!files[i].DocumentVerso) {
            formData.append("file" + compteur, files[i].Document!);
            formData.append("typePiece" + compteur, files[i].TypePieceByProfilId!.toString());
        } else {
            formData.append("file" + compteur, files[i].Document!);
            formData.append("typePiece" + compteur, files[i].TypePieceByProfilId!.toString());
            compteur++;
            formData.append("file" + compteur, files[i].DocumentVerso!);
            formData.append("typePiece" + compteur, files[i].TypePieceByProfilId!.toString());
        }
        compteur++;
    }
    
    formData.append("user", oauth!.userMail!);
    

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: formData
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/${currentUserId}/register-typed-piece-list-admin`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<MultipleFileRegistrationResponse>
    }))
}

function getUserPiecesJustifListForAdmin(userId: number, oauth: OAuthResponse | undefined): Promise<UserPiecesJustificativesListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/user/pieces-justif-list/` + userId, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TypePiecesListForUserResponse>
    }))
}
function getTypePieceUserForAdmin(currentUserId: number| undefined, oauth: OAuthResponse | undefined): Promise<TypePiecesListForUserResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/user/type-piece/` + currentUserId, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TypePiecesListForUserResponse>
    })
}
function getUserInfoForAdmin(currentUserId: number | undefined, oauth: OAuthResponse | undefined): Promise<UserInfoResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/user/info/` + currentUserId, requestOptions)
        .then(response => {
            treatHttpResponseCode(response.status)
            return response.json() as Promise<UserInfoResponse>
        })
}
function registerMailByAdmin(body: AdresseMailRegistrationBody, userId: number| undefined, oauth: OAuthResponse | undefined): Promise<MailUpdateResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-adresse-mail-admin`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<MailUpdateResponse>
    }))

}
function registerVerificationCollectiviteByAdmin(body: RegisterVerificationCollectiviteAdminBody, userId: number| undefined, etablissementId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingCollectiviteResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-verification-collectivite-admin/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCollectiviteResponse>
    }))
}

function registerAdresseEtablissementByAdmin(body: RegisterAdresseCollectiviteAdminBody, userId: number| undefined, etablissementId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingCollectiviteResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-adresse-etablissement-admin/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCollectiviteResponse>
    }))
}

function registerAdresseRepresentantByAdmin(body: RegisterAdresseCollectiviteAdminBody, userId: number| undefined, etablissementId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingCollectiviteResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-adresse-representant-admin/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCollectiviteResponse>
    }))
}
function registerIdentificationCollectiviteByAdmin(body: RegisterIdentificationCollectiviteAdminBody, userId: number | undefined, etablissementId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingCollectiviteResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-identification-collectivite-admin/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCollectiviteResponse>
    })
}
function registerRepresentantsCollectiviteByAdmin(body: RegisterRepresentantsCollectiviteBody, userId: number| undefined, etablissementId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingCollectiviteResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-representants-collectivite-admin/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCollectiviteResponse>
    }))
}
function updateKycInformationsByAdmin(body:KycInformationsBody, userId: number| undefined, currentDocumentId: number| undefined, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/update-kyc-informations/` + currentDocumentId, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    }))

}

function updateHistoInvestInfoByAdmin(body:HistoInvestInformationsBody, userId: number| undefined, currentHistoInvestId: number| undefined, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/update-histo-invest-informations/` + currentHistoInvestId, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    }))

}

function updateHistoRemboursementInfoByAdmin(body:HistoRemboursementBody, userId: number| undefined, currentHistoRemboursementId: number| undefined, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/update-histo-remboursement-informations/` + currentHistoRemboursementId, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    }))

}

function updateHistoRetraitInfoByAdmin(body:HistoRetraitBody, userId: number| undefined, currentHistoRetraitId: number| undefined, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/update-histo-retrait-informations/` + currentHistoRetraitId, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    }))

}

function updateEventHistory(body: UpdateProfilStatusBody, profilId: number, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/user/event-history/${profilId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    })
}

function  GenerateUtilisateursReportCSV(oauth: OAuthResponse | undefined): Promise<ReportingInfosResponse> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + oauth?.access_token
      },
    };
  
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/user/utilisateurs-reporting`, requestOptions)
    .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<ReportingInfosResponse>
    })
  }
  
  function  GenerateKYCReportCSV(oauth: OAuthResponse | undefined): Promise<ReportingInfosResponse> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + oauth?.access_token
      },
    };
  
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/user/kyc-reporting`, requestOptions)
    .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<ReportingInfosResponse>
    })
  }


function registerExperienceInvestissement(body: RegisterExperienceInvestissementBody, userId: number| undefined, oauth: OAuthResponse | undefined) : Promise<OnboardingCitoyenResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-experience-investissement`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingCitoyenResponse>
    })
}

function getUserQuestionReponseList(userId: number | undefined, oauth: OAuthResponse | undefined): Promise<UserQuestionReponseListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/question-reponses-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<UserQuestionReponseListResponse>
    })
}

function getResultatTestConnaissance(userId: number | undefined, oauth: OAuthResponse | undefined): Promise<ResultatTestConnaissanceResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/resultat-test-connaissances`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<ResultatTestConnaissanceResponse>
    })
}

function saveValeurPatrimoniale(body: SaveValeurPatrimonialeBody, userId: number| undefined, oauth: OAuthResponse | undefined): Promise<OnboardingCitoyenResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/save-valeur-patrimoniale`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    }))

}

function registerIdentificationSEM(body: RegisterIdentificationSEMBody, userId: number| undefined, etablissementId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingSEMResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-identification-sem/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingSEMResponse>
    }))
}

function registerBeneficiairesSEM(body: RegisterBeneficiairesSEMBody, userId: number| undefined, etablissementId: number | undefined, oauth: OAuthResponse | undefined)
: Promise<OnboardingSEMResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/register-beneficiaires-sem/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<OnboardingSEMResponse>
    }))
}

function getBeneficiaires(userId: number| undefined, oauth: OAuthResponse | undefined): Promise<BeneficiairesListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/beneficiaires`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<BeneficiairesListResponse>
    })
}

function inscriptionUserNewsLetter(userId: number| undefined, oauth: OAuthResponse | undefined): Promise<InscriptionUserNewsLetterResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token 
        },
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/inscription-newsletter`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<InscriptionUserNewsLetterResponse>
    }))
}

function validateModificationsByAdmin(body: ValidateModificationByAdminBody, oauth: OAuthResponse | undefined, userId: number|undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/validate-modifications-by-admin`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    }))
}

function changeBlockStatus(body: BlockUserBody, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/user/block`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    }))
}

function checkWalletAndBankingAlias(userId: number| undefined, oauth: OAuthResponse | undefined): Promise<CheckWalletAndBankingAliasResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/check-wallet-and-banking-alias`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<CheckWalletAndBankingAliasResponse>
    })
}

function calculateMontantCompteVillyz(userId: number| undefined, oauth: OAuthResponse | undefined): Promise<CalculateMontantCompteVillyzResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/montant-compte-villyz`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<CalculateMontantCompteVillyzResponse>
    })
}

function calculateUserSumPrevisionnelForProject(userId: number| undefined, oauth: OAuthResponse | undefined, projectId: number, isDon: boolean): Promise<CalculateUserSumPrevisionnelForProjectResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/user-sum-previsionnel-for-project/${projectId}?isDon=${isDon}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<CalculateUserSumPrevisionnelForProjectResponse>
    })
}

function getDelaiReservationVirement(userId: number | undefined, oauth: OAuthResponse | undefined): Promise<DelaiReservationVirementResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    }

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/delai-reservation-virement`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DelaiReservationVirementResponse>
    }))
}