
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { ProfilCode } from "../../model/dto/response";
import { OnboardingStatus } from "../../model/entities/onboarding-status";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { ButtonLink } from "../generic/button-link";
import { SimpleButton } from "../generic/simple-button";
import { userService } from "../../services/authentication-redux-service";
import { ResendValidationEmailBody } from "../../model/dto/body/resend-validation-email-body";

type Props = {
  status?: string,
  profil?: string,
  isMailValid?: boolean,
  wallet?: string,
  mangoPayId?: string
};

export function ProjetDisclaimerProfil({status, profil, isMailValid, wallet, mangoPayId}: Props) {

  const loginProps: AuthenticationState = useSelector<ApplicationState,AuthenticationState>((state) => state.authentication);
  const [sent, setSent] = useState(false)
  const [showSentMessage, setShowSentMessage] = useState(false)

  const boxInfo = {
    display: "flex",
    alignContent: "center",
    background: "#FCF8E3",
    border:"solid 1px #ACACAC",
    borderRadius: "4px",
    padding: "15px 30px",
    marginBottom: "1.56rem"
  }

  const title = {
    color: "#8A6D3B",
    fontSize: "25px",
    fontStyle: "bold"
  }

  const description = {
    color: "#8A6D3B",
    fontSize: "16px",
    fontStyle: "bold"
  } 

  const body : ResendValidationEmailBody = {
    IsClassique : true
  }

  return (
    <>
      {profil === ProfilCode.INVESTISSEUR &&
      (status === OnboardingStatus.PRESQUE_COMPLET || status === OnboardingStatus.ERREUR) && 
      !isMailValid &&
        <div css={boxInfo}>
         <div 
            css={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1.4rem !important",
            }}
          >
            <Icon name='warning sign' size='huge' css={{color: "#8A6D3B"}}/>
          </div>
          <div css={{marginLeft: "1.2rem"}}>
            <h2 css={title}>
              Valider votre adresse email en un clin d’œil pour investir
            </h2>
            <p css={description}>
              Pour valider votre adresse email, vous n’avez qu’à cliquer sur le bouton dans l’email que nous vous avons envoyé. Si vous ne le trouvez pas, pensez à vérifier vos spams.
            ​</p>
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
            {
              !sent && !showSentMessage && (
                <SimpleButton 
                label="Renvoyer un email de validation" 
                onClick={() => 
                  userService.authenticatedAction(loginProps?.oauth).resendValidationEmail(body, loginProps.user?.UserId).then(response => {
                    if (response.IsTraitementOk) {
                      setSent(true)
                      setShowSentMessage(true)
                    }
                  })
                } 
              />
              )
            }
            {
              sent && showSentMessage && (
                <div style={{}}>
                  <p style={{fontWeight:"bold", color: "#8A6D3B"}}>
                    Un nouvel email vient de vous être envoyé (pensez à vérifier vos spams)
                  </p>
              </div>
              )
            }
            </div>
          </div>
        </div>
      } 

      {profil === ProfilCode.INVESTISSEUR && status === OnboardingStatus.PRESQUE_COMPLET && isMailValid && !wallet && !mangoPayId &&
        (
          <div css={boxInfo}>
            <div 
              css={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1.4rem !important",
              }}
            >
              <Icon name='warning sign' size='huge' css={{color: "#8A6D3B"}}/>
            </div>
            <div css={{marginLeft: "1.2rem"}}>
              <h2 css={title}>
                Plus que quelques instants
              </h2>
              <p css={description}>
                Vos informations sont en cours de traitement auprès de notre prestataire, veuillez rafraichir la page d'ici quelques instants et vous pourrez investir</p>
              <div
                css={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
              </div>
            </div>
          </div>
        )
      }

      {profil === ProfilCode.INVESTISSEUR && status === OnboardingStatus.PARTIELLEMENT_COMPLET &&
        <div css={boxInfo}>
          <div 
            css={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1.4rem !important",
            }}
          >
            <Icon name='warning sign' size='huge' css={{color: "#8A6D3B"}}/>
          </div>
          <div css={{marginLeft: "1.2rem"}}>
            <h2 css={title}>
              Restez avec nous…            
            </h2>
            <p css={description}>
              Vous devez soumettre vos informations d’identité et votre adresse postale pour pouvoir investir.            
            ​</p>
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ButtonLink 
                label="Compléter mon profil"
                to="/profil"
              />
            </div>
          </div>
        </div>
      }

      {profil === ProfilCode.INVESTISSEUR && status === OnboardingStatus.COMPLET && !isMailValid &&
        <div css={boxInfo}>
          <div 
            css={{
              display: "flex",
              alignItems: "flex-end",
              marginBottom: "0.4rem !important"
            }}
          >
            <Icon name='warning sign' size='huge' css={{color: "#8A6D3B"}}/>
          </div>
          <div css={{marginLeft: "1.2rem"}}>
            <h2 css={title}>
              Restez avec nous…
            </h2>
            <p css={description}>
              Votre profil est en cours de validation, vous devez cependant encore confirmer votre adresse email. Nous vous avons envoyé un email au moment de votre inscription, pensez à vérifier vos "spams" si vous ne le trouvez pas.
              Vous pouvez aussi redemander un email de vérification depuis votre page profil.
            ​</p>
          </div>
        </div>
      }

      {profil === ProfilCode.INVESTISSEUR && status === OnboardingStatus.VAL_EMAIL_A_FAIRE && !isMailValid &&
        <div css={boxInfo}>
          <div 
            css={{
              display: "flex",
              alignItems: "flex-end",
              marginBottom: "0.4rem !important",
            }}
          >
            <Icon name='warning sign' size='huge' css={{color: "#8A6D3B"}}/>
          </div>
          <div css={{marginLeft: "1.2rem"}}>
            <h2 css={title}>
              C’est parti !
            </h2>
            <p css={description}>
              Il ne vous reste plus qu'à valider votre adresse email pour pouvoir investir. Nous vous avons envoyé un email au moment de votre inscription, pensez à vérifier vos "spams" si vous ne le trouvez pas.
              Vous pouvez aussi redemander un email de vérification depuis votre page profil.
            ​</p>
          </div>
        </div>
      }        
    </>
  );
}
