import React, { useState } from "react"
import { OnboardingCollectiviteResponse } from "../../model/dto/response"
import { SubscriptionCollectiviteFormIdentification } from "./subscription-collectivite-form-identification";
import { SwitchOnboardingTypeEtablissement } from "./porteurs/switch-onboarding-type-etablissement";

interface Props {
    onFinishOnboarding?: () => void,
    onSubmitIdentificationSuccess?: () => void
}

export function SubscriptionCollectiviteForm({onFinishOnboarding, onSubmitIdentificationSuccess}: Props) {
    const [isIdentiteDone, setIsIdentiteDone] = useState<boolean>(false)
    const [subscriptionStepResponse, setSubscriptionStepResponse] = useState<OnboardingCollectiviteResponse>()

    const onSubmitIdentification = (response: OnboardingCollectiviteResponse) => {
        if(onSubmitIdentificationSuccess) {
            onSubmitIdentificationSuccess()
        }

        setIsIdentiteDone(true)
        setSubscriptionStepResponse(response)
    }

    if(!isIdentiteDone) {
        return (
            <div>
                <SubscriptionCollectiviteFormIdentification 
                    isOnboarding={true}
                    onSubmitSuccess={onSubmitIdentification}
                />
            </div>
        )
    }
    else {
        return (
            <SwitchOnboardingTypeEtablissement 
            subscriptionStepResponse={subscriptionStepResponse!}
            />
        )
    }
}