import React from "react";
import { EditableBlock } from "../../generic/editable-block/editable-block";
import { KYCReportingForm } from "../form/kyc-reporting-form";

export function ReportingKYC() {
    
    return (
        <> 
            <>
                <EditableBlock
                    title="Reporting KYC"
                    editor={() => null}
                    editable={false}
                >
                    <KYCReportingForm />
                </EditableBlock>
            </>
        </>
    )
}