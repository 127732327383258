import React from "react";
import { Slide } from "react-slideshow-image";
import "./slideshow.css";
import "react-slideshow-image/dist/styles.css";
import { ProjetResources } from "../../model/entities/projet-resources";

const proprietes = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
};

interface SlideshowState {
  source?: ProjetResources[];
  projetId: number;
}

interface SlideshowProps {
  source?: number[];
  projetId?: number;
  maxHeight?: string;
}

export function Slideshow({ source, projetId, maxHeight }: SlideshowProps) {
  if (!source) {
    return null;
  }

  return (
    <div className="slide-container">
      <Slide
      autoplay={source.length > 1 ? true : false}
      arrows={source.length > 1 ? true : false}
      >
        {source
          .filter((e) => e !== null)
          .map((e, idx) => {
            const src = `${process.env.REACT_APP_BASE_URL}/public/projets/${projetId}/picture/${e}`;
            return (
              <div className="each-slide" key={idx}>
                <img className="loading" style={{ objectFit: "cover", maxHeight }} src={src} />
              </div>
            );
          })}
      </Slide>
    </div>
  );
}

// export class Slideshow2 extends React.Component<
//   SlideshowProps,
//   SlideshowState
// > {
//   constructor(props: SlideshowProps) {
//     super(props);

//     this.state = {
//       projetId: 0,
//       source: props.source,
//     };
//   }

//   render() {
//     if (this.props.source) {
//       return (
//         <div className="containerSlide">
//           <Slide {...proprietes}>
//             {this.props.source.map((element) => {
//               let imageUrl: string = "";
//               if (!element.Document?.toString().startsWith("blob")) {
//                 imageUrl += "data:image/png;base64,";
//               }
//               imageUrl += element.Document;
//               return (
//                 <div className="each-slide">
//                   <div>
//                     <img src={imageUrl} alt="img" />
//                   </div>
//                 </div>
//               );
//             })}
//           </Slide>
//         </div>
//       );
//     } else {
//       return <div></div>;
//     }
//   }
// }
