import { css } from "@emotion/core";

export const sectionHeader = css({
  position: "relative",
  "&.ui.header": { paddingBottom: "80px" },
  "::after": {
    display: "block",
    position: "absolute",
    height: "3px",
    width: "50px",
    content: '""',
    background: "#005870",
    left: "calc(50% - 25px)",
    bottom: "65px",
  },
});

export const sectionHeaderPrimary = css({
  "::after": { background: "white" },
  "&.ui.header": { color: "white" },
});

export const section = css({
  padding: "35px 0",
});

export const primaryColorVillyz = "#005870";
export const secondaryColorVillyz = "#692244";
export const pageBackground = "#ECF0F1";
export const secondaryColorBackground = "#E0E8EB";

export const sectionPrimary = css({
  background: "#005870",
  color: "white",
});

export const header = css({
  backgroundImage: "url('/img/bg-header.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "scroll",
  minHeight: "450px",
});

export const bigApplicationHeader = css({
  "@media (max-width: 990px)": {
    display: "none",
  },
});

export const smallApplicationHeader = css({
  display: "none",
  "@media (max-width: 990px)": {
    display: "block",
  },
});

export const stepperUnnumbered = css({
  ul: {
    listStyleType: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    counterReset: "step",
    li: {
      position: "relative",
      padding: "20px 0 30px 50px",
      ":before": {
        position: "absolute",
        left: "0px",
        top: "10px",
        content: "''",
        counterIncrement: "step",
        width: "40px",
        height: "40px",
        border: "1px solid #005870",
        display: "block",
        borderRadius: "50%",
        lineHeight: "37px",
        background: "white",
        color: "#005870",
        fontWeight: "bold",
      },
      ":after": {
        content: '""',
        position: "absolute",
        width: "3px",
        height: "100%",
        background: "#005870",
        top: "15px",
        left: "19px",
        zIndex: -1,
      },
      ":last-child:after": {
        content: "none",
      },
    },
  },
});

export const stepper = css({
  ul: {
    listStyleType: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    counterReset: "step",
    paddingLeft: "0px !important",
    li: {
      position: "relative",
      padding: "20px 0px 30px 50px",
      ":before": {
        position: "absolute",
        fontSize: "18px",
        left: "0px",
        top: "10px",
        content: "counter(step)",
        counterIncrement: "step",
        width: "40px",
        height: "40px",
        border: "1px solid #005877",
        display: "block",
        borderRadius: "50%",
        lineHeight: "34px",
        background: "#005877 !important",
        color: "#FCFAF8",
        textAlign: "center",
        fontWeight: "bold",
      },
      ":after": {
        content: '""',
        position: "absolute",
        width: "3px",
        height: "100%",
        background: "#005870",
        top: "15px",
        left: "19px",
        zIndex: -1,
      },
      ":last-child:after": {
        content: "none",
      },
    },
  },
});

