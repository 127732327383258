import { OAuthResponse } from "../../model/authentication/oauth-response";
import { AnyAction } from 'redux'
import { UserInfoResponse } from "../../model/dto/response";

export interface AuthenticationState {
    isAuthenticated: boolean;
    isLoggingIn: boolean;
    isAuthenticationFailed: boolean;
    oauth?: OAuthResponse;
    user?: UserInfoResponse;
}

export const REQUEST_LOGIN = 'REQUEST_LOGIN'

interface RequestLoginAction extends AnyAction {
  type: typeof REQUEST_LOGIN
  user: UserInfoResponse,
  authResponse: OAuthResponse
}

export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL'

interface LoginSuccessfulAction extends AnyAction {
  type: typeof LOGIN_SUCCESSFUL
  user: UserInfoResponse,
  authResponse: OAuthResponse
}

export const LOGIN_FAILURE = 'LOGIN_FAILURE'

interface LoginFailureAction extends AnyAction {
  type: typeof LOGIN_FAILURE
  user: UserInfoResponse,
  authResponse: OAuthResponse
}

export const LOGOUT = 'LOGOUT'

interface LogoutAction extends AnyAction {
  type: typeof LOGOUT
  user: UserInfoResponse,
  authResponse: OAuthResponse
}

export const CHANGE_IDENTITY = 'CHANGE_IDENTITY'

interface ChangeIdentityAction extends AnyAction {
  type: typeof CHANGE_IDENTITY
  user: UserInfoResponse,
  authResponse: OAuthResponse
}

export const REQUEST_IDENTITY = 'REQUEST_IDENTITY'

interface RequestIdentityAction extends AnyAction {
  type: typeof REQUEST_IDENTITY
  user: UserInfoResponse,
  authResponse: OAuthResponse
}

export type AuthenticationAction = RequestLoginAction | LoginFailureAction | LoginSuccessfulAction | LogoutAction | ChangeIdentityAction | RequestIdentityAction;