import React, { useEffect, useState } from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import './checkbox-simple-field.css'

type FieldData = {
    name?: string;
    value?: boolean;
    isValid?: boolean;
};

type Props = {
    data: FieldData;
    label: string;
    error?: string;
    submitted?: boolean;
    onChange?: (value: any, isValid: boolean) => void;
    validateField?: (value: any) => boolean;
    inlineFlex?: boolean;
    toggle?: boolean;
    isInfoToggle?: boolean;
};

export function CheckBoxSimpleField({
    data,
    label,
    submitted,
    error,
    onChange,
    validateField,
    inlineFlex,
    toggle,
    isInfoToggle
}: Props) {
    const [isValid, setIsValid] = useState(true);
    const [selectedValue, setSelectedValue] = useState(data.value);

    useEffect(() => {
        setSelectedValue(data.value);
    }, [data.value]);

    useEffect(() => {
        if (submitted && validateField) {
            setIsValid(validateField(selectedValue));
        }
    }, [submitted]);

    const onCheckboxClick = (
        event: React.FormEvent<HTMLInputElement>,
        data: CheckboxProps
    ) => {
        setSelectedValue(data.checked);
        if (validateField && !validateField(data.checked)) {
            setIsValid(false);
            if (onChange) {
                onChange(data.checked, false);
            }
        } else {
            setIsValid(true);
            if (onChange) {
                onChange(data.checked, true);
            }
        }
    };

    return (
        <div
            style={{
                display: inlineFlex ? "inline-flex" : "block",
            }}
        >{
            isInfoToggle ? (  <Checkbox
            checked={selectedValue}
            className={`field ${data.value ? "info-red" : "info-green"}`}
            label={label}
            onChange={onCheckboxClick}
            toggle={toggle}
        />) : 
        (  <Checkbox
                checked={selectedValue}
                className="field "
                label={label}
                onChange={onCheckboxClick}
                toggle={toggle}
            />)
        }
          

            {!isValid && (
                <div
                    css={{
                        color: "#F03E3E",
                        fontSize: "10px",
                        marginTop: "4px",
                    }}
                >
                    {error}
                </div>
            )}
        </div>
    );
}
