import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";
import { secondaryColorVillyz } from "./pages/styles";
import { useScroll } from "./pages/useScroll";

export function UserConnectionUnAuthMobile() {
  const { scrollY } = useScroll();

  const isFloatingMenu = scrollY > 70;

  return (
    <Dropdown
      className="right"
      icon={null}
      floating
      trigger={
        <Icon 
            name="bars" 
            size="large" 
            css={{
                transition: "fill 0.3s ease",
                color: isFloatingMenu ? secondaryColorVillyz : "white",
            }}  
        />
        }
      direction="left"
      css={{marginRight : "20px"}}
    >
        <Dropdown.Menu>
            <Dropdown.Item 
                as={Link} 
                to="/engagements"
            >
                Nos Engagements
            </Dropdown.Item>
            <Dropdown.Item 
                as={Link} 
                to="/projets"
            >
                Projets
            </Dropdown.Item>
            <Dropdown.Item 
                as={Link} 
                to="/collectivites"
            >
                Porteur de projet
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item 
                as={Link} 
                to="/connexion"
            >
             Connexion
            </Dropdown.Item>
            <Dropdown.Item 
                as={Link} 
                to="/inscription"
            >
             Inscription
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
  );
}




