import {
  CHUListResponse,
  CommuneListResponse,
  DepartementListResponse,
  EpciListResponse,
  RegionListResponse,
} from "../../../model/dto/response";
import { Commune } from "../../../model/entities/commune";
import { Departement } from "../../../model/entities/departement";
import { Collectivite } from "../../../model/entities/profil";
import { Region } from "../../../model/entities/region";
import { referenceService } from "../../../services/reference-service";
import { CommuneDTO } from "../../../model/dto/util/commune-dto"
import { CHU } from "../../../model/entities/chu";

const API_CALL = {
  [Collectivite.COMMUNE]: {
    serviceList: referenceService.getCommuneList,
    service: referenceService.getCommuneByCode,
    selector: (r: CommuneListResponse) => r.CommuneList,
  },
  [Collectivite.DEPARTEMENT]: {
    serviceList: referenceService.getDepartementList,
    service: referenceService.getDepartementByCode,
    selector: (r: DepartementListResponse) => r.DepartementList,
  },
  [Collectivite.REGION]: {
    serviceList: referenceService.getRegionList,
    service: referenceService.getRegionByCode,
    selector: (r: RegionListResponse) => r.RegionList,
  },
  [Collectivite.EPCI]: {
    serviceList: referenceService.getEpciList,
    service: referenceService.getEpciByCode,
    selector: (r: EpciListResponse) => r.EpciList,
  },
  [Collectivite.CHU]: {
    serviceList: referenceService.getCHUList,
    service: referenceService.getCHUByCode,
    selector: (r: CHUListResponse) => r.CHUList,
  },
};

export type Option = { key: string; text: string; value: string };

export async function getDropdownOptions(
  type: Collectivite,
  clue: string,
  useCodeUnique: boolean
): Promise<Option[]> {
  const getter = API_CALL[type];

  if(clue.length < 3) {
    return [];
  }

  if (!getter) {
    console.error(`Unknow collectivite type: ${type}`);
    return [];
  }

  const response = await getter.serviceList(clue);
  const result = await response.json();

  const collectivites = getter.selector(result);
  if (!Array.isArray(collectivites)) {
    return [];
  }

  if(type == Collectivite.COMMUNE) {
    return (collectivites as CommuneDTO[]).map((c) => ({
      key: c.CodeUnique,
      text: c.Designation + " (" + c.CodeDepartement + ")",
      value: useCodeUnique ? c.CodeUnique : c.Id.toString(),
    }));
  }

  return (collectivites as (Departement | Region | Commune)[]).map((c) => ({
    key: c.CodeUnique,
    text: c.Designation,
    value: useCodeUnique ? c.CodeUnique : c.Id.toString(),
  }));
}

export async function getDropdownOptionsWithCode(
  type: Collectivite,
  code: string,
  useCodeUnique: boolean
): Promise<Option[]> {
  const getter = API_CALL[type];

  if (!getter) {
    console.error(`Unknow collectivite type: ${type}`);
    return [];
  }

  const response = await getter.service(code);
  const result = await response.json();

  const collectivites = getter.selector(result);
  if (!Array.isArray(collectivites)) {
    return [];
  }

  if(type == Collectivite.COMMUNE) {
    return (collectivites as CommuneDTO[]).map((c) => ({
      key: c.CodeUnique,
      text: c.Designation + " (" + c.CodeDepartement + ")",
      value: useCodeUnique ? c.CodeUnique : c.Id.toString(),
    }));
  }

  // if (type == Collectivite.CHU) {
  //    return (collectivites as CHU[]).map((chu) => ({
  //       key: chu.Id?.toString(),
  //       text: chu.Designation,
  //       value: chu.Id,
  //    }));
  // }

  return (collectivites as (Departement | Region)[]).map((c) => ({
    key: c.CodeUnique,
    text: c.Designation,
    value: useCodeUnique ? c.CodeUnique : c.Id.toString(),
  }));
}