import React from "react"
import { MultipleFileRegistrationResponse, OnboardingOrganisationResponse } from "../../model/dto/response"
import { ExperienceInvestissementForm } from "../ecsp/experience-investissement-form"
import { OnboardingInvestisseurMoralPorteurProjetDocumentListForm } from "./onboarding-investisseur-moral-porteur-projet-document-list-form"
import { RemerciementOnboardingCitoyen } from "./remerciement-onboarding-citoyen"
import { SubscriptionCollectiviteAdresses } from "./subscription-collectivite-adresses"
import { SubscriptionIdentiteRepresentantForm } from "./subscription-identite-representant-form"
import { WorkflowType } from "../../model/entities/workflow-type"
import { FlowStep } from "../../model/entities/flow-step"

type Props = {
  activeStepKey: string,
  lastStepResponse?: OnboardingOrganisationResponse,
  onSubmitVerification: (response: OnboardingOrganisationResponse ) => void,
  onSubmitPiecesJustificatives: (response: MultipleFileRegistrationResponse) => void,
  onNextStep: (respone: Response) => void
}

export function SubscriptionInvestisseurOrganisationForm ({
  activeStepKey, 
  lastStepResponse,
  onSubmitVerification, 
  onSubmitPiecesJustificatives, 
  onNextStep
}: Props) {

  if(activeStepKey === FlowStep.IDENTITE_REPRESENTANT_LEGAL) {
    return (
      <div>
        <SubscriptionIdentiteRepresentantForm
          isOnboarding={true}
          onSubmitSuccess={onSubmitVerification}
          onSkipStep={onNextStep}
          workflowTypeCode={WorkflowType.ONBOARDING_INVESTISSEUR_MORAL}
        />
      </div>
    )
  }

  if(activeStepKey === FlowStep.ADRESSES) {
    return (
      <div>
        <SubscriptionCollectiviteAdresses
          onSubmitSuccess={onSubmitVerification}
          onSkipStep={onNextStep}
          workflowTypeCode={WorkflowType.ONBOARDING_INVESTISSEUR_MORAL}
        />
      </div>
    )
  } 
 
  if(activeStepKey === FlowStep.EXPERIENCE_INVESTISSEMENT) {
    return (
      <div>
        <ExperienceInvestissementForm
          isOnboarding={true}
          onSkipStep={onNextStep}
          onSubmitSuccess={onSubmitVerification}
          workflowTypeCode={WorkflowType.ONBOARDING_INVESTISSEUR_MORAL}
      />   
      </div>
    )
  }

  if(activeStepKey === FlowStep.PIECES_JUSTIFICATIVES) {
    return (
      <div>
        <OnboardingInvestisseurMoralPorteurProjetDocumentListForm  
          onActionSuccess={onSubmitPiecesJustificatives}
          onSkipStep={onNextStep}
          isOnboarding={true}
          workflowTypeCode={WorkflowType.ONBOARDING_INVESTISSEUR_MORAL}
        />
      </div>
    )
  }

  if(activeStepKey === FlowStep.COMPLET) {
    return (
      <div>
        <RemerciementOnboardingCitoyen />
      </div>
    )
  }

  return (
    <div></div>
  )
}