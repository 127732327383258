import { css } from "@emotion/core";
import React, { useMemo } from "react"

type Props = {
    // Début du texte, non souligné
    startText: string,

    // Suite du texte, souligné
    underlinedText: string,

    // Taille de la police en px, pas possible de faire en autre unité pour l'instant
    fontSize: number,

    // Ajouter un décalage personnalisé par rapport à celui calculé par défaut pour le soulignage
    offset?: number,

    textColor?: string,

    //pour choisir le type de header si h1 ou h2
    isH1?:boolean
}

export function CustomUnderlinedText({ startText, underlinedText, fontSize, offset, textColor, isH1 }: Props) {
    const lineOffset = useMemo(() => {
        let computedOffset = -10 - (fontSize - 24) / 2
        if (offset) {
            computedOffset = computedOffset - offset
        }
        return computedOffset + "px"
    }, [])

    const fontSizeWithUnit = useMemo(() => {
        return fontSize + "px"
    }, [])

    const underlinedHeader = css({
        display: 'flex',
        fontFamily:"merriweather",
        h1: {
          fontSize: fontSizeWithUnit,
          color: textColor ?? 'black',
          marginBottom: '20px',
          fontFamily:"merriweather",
          span: {
            display: 'inline-block',
            fontSize: fontSizeWithUnit,
            zIndex: 1,
            ":before": {
                content: 'url("https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/assetUnderline.svg")',
                display: 'block',
                position: 'relative',
                bottom: lineOffset,
                height: '0',
            },
          },
        },
        h2: {
            fontSize: fontSizeWithUnit,
            color: textColor ?? 'black',
            marginBottom: '20px',
            fontFamily:"merriweather",
            span: {
              display: 'inline-block',
              fontSize: fontSizeWithUnit,
              zIndex: 1,
              ":before": {
                  content: 'url("https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/assetUnderline.svg")',
                  display: 'block',
                  position: 'relative',
                  bottom: lineOffset,
                  height: '0',
              },
            },
          },
      });

    return (
        <div css={underlinedHeader}>
            {isH1 ? 
            <h1>{startText}&nbsp;<span>{underlinedText}</span></h1> :
            <h2>{startText}&nbsp;<span>{underlinedText}</span></h2>
        }
        </div>
    )
}