export interface SubFlowStep{
    Code : string,
    Libelle : string
}

const OBJECTIFS_INVESTISSEMENT: string = "OIN";
const EXPERIENCE_INVESTISSEMENT: string = "EIN";
const CONNAISSANCE_RISQUES:string = "CRS";
const ADRESSE_ETABLISSEMENT: string = "ADE";
const ADRESSE_REPRESENTANT_LEGAL: string = "ADR";

export const SubFlowStep = {
    OBJECTIFS_INVESTISSEMENT,
    EXPERIENCE_INVESTISSEMENT,
    CONNAISSANCE_RISQUES,
    ADRESSE_ETABLISSEMENT,
    ADRESSE_REPRESENTANT_LEGAL
}