import React, { useState } from "react"
import { Form } from "semantic-ui-react";
import { CheckBoxSimpleField } from "../../generic/checkbox-simple-field/checkbox-simple-field";
import { SubmitButton } from "../../generic/submit-button";
import { userService } from "../../../services/authentication-redux-service";
import { BlockUserBody } from "../../../model/dto/body/block-user-body";
import { AuthenticationState } from "../../../store/authentication/types";
import { ApplicationState } from "../../../store";
import { useSelector } from "react-redux";

type Props = {
    userId: number
    isBloque: boolean
    onSubmitSuccess: () => void
}

type FieldData = {
    value?: any;
    name?: string;
    isValid?: boolean;
};

type FormData = {
    isBloque: FieldData
}

export function BlockAccountModal({
    userId,
    isBloque,
    onSubmitSuccess
}: Props) {
    const [formData, setFormData] = useState<FormData>({
        isBloque: { value: isBloque, name: 'isBloque', isValid: true }
    })

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const handleInputChangeNew = (
        e: React.ChangeEvent<HTMLInputElement>,
        fieldName?: string,
        isValid?: boolean
    ): void => {
        const fieldValue: string = e.target.value;

        if (fieldName) {
            const newField: FieldData = {
                [fieldName]: {
                    value: fieldValue,
                    name: fieldName,
                    isValid: isValid,
                },
            };
            setFormData({ ...formData, ...newField });
        }
    };

    const body: BlockUserBody = {
        UserId: userId,
        IsBloque: formData.isBloque.value
    }

    return ( 
    <Form>
        <Form.Field width={16}>
            <CheckBoxSimpleField 
                data={formData.isBloque}
                toggle
                label="Bloquer ou débloquer le compte"
                onChange={(value) =>
                    handleInputChangeNew(
                        { target: { value } } as any,
                        formData.isBloque.name
                    )}
            />
        </Form.Field>
        <SubmitButton 
            label="Valider"
            action={() => userService.changeBlockStatus(body, loginProps?.oauth)}
            onActionSuccess={onSubmitSuccess}
        />
    </Form>
    )
}