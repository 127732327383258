import {isBefore, isSameDay, subYears} from 'date-fns'

export function getDateString(timestamp: Date | undefined): string {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    if (!timestamp) {
        return "";
    }
    let date = new Date(timestamp);
    return date.toLocaleDateString('fr-FR', options as any);
}

export function makeDownloadFromBlob(aBlob: Blob, fileName: string){
    // Create blob link to download
    const url = window.URL.createObjectURL(aBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link and the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url)
}

export function base64ToBlob(base64EncodedString: string, mimeType = ""){
    const byteCharacters = atob(base64EncodedString);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: mimeType});
    return blob;
}

export function makeDownloadFromBase64(base64EncodedString: string,fileName: string, mimeType = ""){
    const resultingBlob = base64ToBlob(base64EncodedString,mimeType);
    makeDownloadFromBlob(resultingBlob, fileName);
}

export function isLegalAgeDate (date: Date)  {
    const dateNow = new Date(Date.now())
    const majorityDateBasedOnDateNow = subYears(dateNow, 18)
    return isBefore(date, majorityDateBasedOnDateNow) || isSameDay(date, majorityDateBasedOnDateNow)
}

export const convertDateToMaskPicker = (date: string) => {
    if(date){
        let newDate = date.split('T')[0].split('-').reverse().join('')
        return newDate
    }      
}

export const calculateEndOfCollecte = (projetDate: string | undefined, duree: number | undefined) => {
    if(projetDate && duree){
        let collecteDate= new Date(projetDate)
        collecteDate.setDate(collecteDate.getDate() + duree)
        return collecteDate.toLocaleDateString()
    }
    return ''
}