import React, { useState } from "react"
import { connect } from "react-redux"
import { ApplicationState } from "../../store";
import { OnboardingBusinessResponse, OnboardingCollectiviteResponse, OnboardingOrganisationResponse, OnboardingSEMResponse, UserInfoResponse } from "../../model/dto/response";
import { AuthenticationState } from "../../model/state/authentication-state";
import { SubscriptionAdresseEtablissement } from "./subscription-adresse-etablissement";
import { SubscriptionAdresseRepresentant } from "./subscription-adresse-representant";
import { SubFlowStep } from "../../model/entities/sub-flow-step";

type FieldData = {
    value?: any,
    error?: string,
    isValid?: boolean
};

type FormData = {
    numeroRue: FieldData,
    libelleVoie: FieldData,
    adresseLigne2: FieldData,
    codePostal: FieldData,
    commune: FieldData,
    communeId: FieldData
}

type Props = {
    userInfo?: UserInfoResponse,
    loginProps?: AuthenticationState,
    workflowTypeCode?: string,
    lastStepResponse?: OnboardingBusinessResponse | OnboardingCollectiviteResponse | OnboardingOrganisationResponse | OnboardingSEMResponse
    onSubmitSuccess: (response: Response) => void,
    onsubmitError?: () => void,
    onSkipStep?: (response: Response) => void
}

function SubscriptionCollectiviteAdresses({onSubmitSuccess,  onSkipStep, workflowTypeCode, lastStepResponse}: Props) {
    const [typeAdresse, setTypeAdresse] = useState<string>(lastStepResponse?.NextFormStep || SubFlowStep.ADRESSE_ETABLISSEMENT) 

    const onSubmitEtablissementSuccess = (): void => {
        setTypeAdresse(SubFlowStep.ADRESSE_REPRESENTANT_LEGAL)
    }
    
    return (
        <>
            {(typeAdresse === SubFlowStep.ADRESSE_ETABLISSEMENT || typeAdresse === null) && (
                <SubscriptionAdresseEtablissement 
                    isOnboarding={true}
                    onSkipOnboarding={onSkipStep}
                    onSubmitSuccess={onSubmitEtablissementSuccess}
                    workflowTypeCode={workflowTypeCode}
                />
            )}
            {typeAdresse === SubFlowStep.ADRESSE_REPRESENTANT_LEGAL && (
                <SubscriptionAdresseRepresentant 
                    isOnboarding={true}
                    onSkipOnboarding={onSkipStep}
                    onSubmitSuccess={onSubmitSuccess}
                    workflowTypeCode={workflowTypeCode}
                />
            )}
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedSubscriptionCollectiviteAdresses = connect(mapStateToProps, null)(SubscriptionCollectiviteAdresses);
export { ConnectedSubscriptionCollectiviteAdresses as SubscriptionCollectiviteAdresses };