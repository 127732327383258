import {
    $isTextNode,
    DOMConversion,
    DOMConversionMap,
    DOMConversionOutput,
    TextNode,
    SerializedTextNode
} from 'lexical';
  
export class ExtentedTextNode extends TextNode {
  
    static getType(): string {
      return 'extended-text';
    }
  
    static clone(node: ExtentedTextNode): ExtentedTextNode {
      return new ExtentedTextNode(node.__text, node.__key);
    }
  
    static importDOM(): DOMConversionMap | null {
      const importers = TextNode.importDOM();
      return {
        ...importers,
        span: () => ({
          conversion: patchStyleConversion(importers?.span),
          priority: 1
        })
      };
    }
  
    static importJSON(serializedNode: SerializedTextNode): TextNode {
      return TextNode.importJSON(serializedNode);
    }
  
    exportJSON(): SerializedTextNode {
      return super.exportJSON();
    }
}
  
function patchStyleConversion(
    originalDOMConverter?: (node: HTMLElement) => DOMConversion | null
): (node: HTMLElement) => DOMConversionOutput | null {
    return (node) => {
        const original = originalDOMConverter?.(node);
        if (!original) {    
            return null;
        }
        const originalOutput = original.conversion(node);
    
        if (!originalOutput) {
            return originalOutput;
        }
    
        const backgroundColor = node.style.backgroundColor;
        const color = node.style.color;
    
        return {
            ...originalOutput,
            forChild: (lexicalNode, parent) => {
            const originalForChild = originalOutput?.forChild ?? ((x) => x);
            const result = originalForChild(lexicalNode, parent);
            if ($isTextNode(result)) {
                const style = [
                backgroundColor ? `background-color: ${backgroundColor}` : null,
                color ? `color: ${color}` : null
                ]
                .filter((value) => value != null)
                .join('; ');
                if (style.length) {
                return result.setStyle(style);
                }
            }
            return result;
            }
        };
    };
}