import * as React from "react";
import Editor from "./lexical/Editor";
import { SettingsContext } from "./lexical/context/SettingsContext";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { SharedHistoryContext } from "./lexical/context/SharedHistoryContext";
import { SharedAutocompleteContext } from "./lexical/context/SharedAutocompleteContext";
import PlaygroundEditorTheme from "./lexical/themes/PlaygroundEditorTheme";
import PlaygroundNodes from "./lexical/nodes/PlaygroundNodes";
import { useEffect, useState } from "react";
import { TextNode } from "lexical";
import { ExtentedTextNode } from "./lexical/nodes/ExtendedTextNode";

type FieldData = {
    value?: any;
    name?: string;
    isValid?: boolean;
};

type Props = {
    data: FieldData;
    label: string;
    error?: string;
    submitted?: boolean;
    editable?: boolean;
    initialEditor?: string;
    onChange?: (props: any, state: string, fieldName?: string, isValid?: boolean) => void;
    validateField?: (value: any) => boolean;
};

export function LexicalInputArea({
    data,
    label,
    submitted,
    editable = true,
    error,
    initialEditor,
    onChange,
    validateField,
  }: Props) {

    const initialConfig = {
        editorState: undefined,
        namespace: data.name || label,
        nodes: [
            ...PlaygroundNodes,
            { replace: TextNode, with: (node: TextNode) => new ExtentedTextNode(node.__text, node.__key) }
        ],
        onError: (error: Error) => {
          throw error;
        },
        theme: PlaygroundEditorTheme,
    };

    const [initialContent, setInitialContent] = useState("");

    useEffect(() => {
        setInitialContent(data.value || "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isValid, setIsValid] = useState(true);

    const handleChange = (value: string, state: string) => {
        if (onChange) {
            onChange({value}, state, data.name, validateField ? validateField(value) : true);
        }

        if (validateField && submitted) {
            setIsValid(validateField(value));
        }
    }

    useEffect(() => {
        if(submitted && validateField ) {
            setIsValid(validateField(data.value));
        }
    }, [data.value, submitted, validateField])

    return <SettingsContext>
        <LexicalComposer initialConfig={initialConfig}>
            <SharedHistoryContext>
                <SharedAutocompleteContext>
                    <label>{label}</label>
                    {editable ? (
                        <div className="editor-shell">
                            <Editor initialContent={initialContent} initialEditor={initialEditor ?? ""} onChange={handleChange} />
                        </div> 
                    ) : ( 
                        <div>{data.value}</div>
                    )}
                    {!isValid && (
                        <div css={{
                            color: "#F03E3E",
                            fontSize: "10px"
                        }}>
                            {error}
                        </div>
                    )}
                </SharedAutocompleteContext>
            </SharedHistoryContext>
        </LexicalComposer>
    </SettingsContext>;
}