import React from "react"
import { CollectivitePostPublique } from "../../../model/entities/collectivite-post-publique"
import { EventPostBox } from "../Event-Post-Box"
import { PostItem } from "./post-item"

type Props = {
    post: CollectivitePostPublique;
    handleAuthenticationModal: (bool: boolean)=>void;
    reload: ()=>void;
}

export function GenericPost({post, handleAuthenticationModal, reload}: Props) {
    return (
        (post.TypeEvenementWithPalierId && (
            <EventPostBox
                post={post}            
            />
        ) || (
            <PostItem 
                key={post.Id}
                projectId={post.ProjetId!} 
                post ={post} 
                handleAuthenticationModal={handleAuthenticationModal}
                reload = {reload}
            />
        )
    ))  
}