import React from "react";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Icon, Image } from "semantic-ui-react";
import { AuthenticationState, LOGOUT } from "../store/authentication/types";
import { userService } from "../services/authentication-redux-service";
import { Profil } from "../model/entities/profil";
import { useScroll } from "./pages/useScroll";
import { secondaryColorVillyz} from "./pages/styles"


type Props = {
  loginProps?: AuthenticationState;
  logout: () => void;
};

function UserConnectionInformationMobile({ loginProps, logout }: Props) {
  const navigate = useNavigate()
  const { scrollY } = useScroll();

  const isFloatingMenu = scrollY > 70;
  function onLogout() {
    userService.logout();
    logout();
    navigate('/')
  }

  if (!loginProps?.oauth) {
    return null;
  }

  const dashboardRedirect = () => {
    if(loginProps.oauth?.profilCode === Profil.INVESTISSEUR) {
      return "/dashboard/citoyen"
    }
    if(loginProps.oauth?.profilCode === Profil.PORTEUR_PROJET) {
      return "/dashboard/collectivite"
    }
    if(loginProps.oauth?.profilCode === Profil.VILLYZ_USER) {
      return "/dashboard/admin"
    }

    return ""
  }

  return (
    <Dropdown
      className="right"
      icon={null}
      floating
      trigger={
        <Icon 
            name="bars" 
            size="large" 
            css={{
                transition: "fill 0.3s ease",
                color: isFloatingMenu ? secondaryColorVillyz : "white",
            }}  
        />
        }
      direction="left"
      css={{marginRight : "20px"}}
    >
        <Dropdown.Menu>
            <div css={{ padding: "10px" }} onClick={(e) => e.stopPropagation()}>
            <div
                css={{
                display: "flex",
                alignItems: "center",
                color: "black",
                }}
            >
                <Image src="https://picsum.photos/28/28" avatar />
                <div css={{ paddingLeft: "5px" }}>
                <div css={{ fontWeight: "bold" }}>
                    {loginProps.oauth.userPrenom} {loginProps.oauth.userNom}
                </div>
                <span>{loginProps.oauth.userMail}</span>
                </div>
            </div>
            </div>
            <Dropdown.Divider />
            <Dropdown.Item 
                as={Link} 
                to="/engagements"
            >
                Nos Engagements
            </Dropdown.Item>
            <Dropdown.Item 
                as={Link} 
                to="/projets"
            >
                Projets
            </Dropdown.Item>
            <Dropdown.Item 
                as={Link} 
                to="/collectivites"
            >
                Collectivités
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item 
                text="Profil" 
                as={Link} 
                to="/profil" 
            />
            <Dropdown.Item
                text="Tableau de bord"
                as={Link}
                to={dashboardRedirect()}
            />
            <Dropdown.Item text="Se déconnecter" onClick={onLogout} />
        </Dropdown.Menu>
    </Dropdown>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedUserConnectionInformationMobile = connect(
  mapStateToProps,
  (dispatch) => ({
    logout: () => dispatch({ type: LOGOUT }),
  })
)(UserConnectionInformationMobile);
export { ConnectedUserConnectionInformationMobile as UserConnectionInformationMobile };
