import React from "react"

type Props = {
    label: string,
    onlyBlack?: boolean,
    onClick?: () => void
}

export function LinkButton({label, onlyBlack, onClick}: Props) {

    const btnStyles = {
        color: onlyBlack ? "#000000" : "#005870",
        fontWeight: 600,
        cursor: 'pointer', 
        textDecoration: 'underline',
        fontSize: '14px'
    };  
   
    return (
        <div className="button-action center">
            <button type="button" css={btnStyles} onClick={onClick}>{label}</button>
        </div>
    )
}
