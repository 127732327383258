import React, { useState } from 'react'
import { VirementPane } from './virement-pane'
import { BankProvider } from '../../model/entities/bank-provider';
import { StrongAuthenticationInformationPane } from './strong-authentication-information-pane';
import { Investissementbody } from '../../model/dto/body/investissement-body';
import { VirementClassiquePane } from './virement-classique-pane';

type Props = {
    montant?: number | string,
    projetId?: number,
    errorMessage?: string,
    isErrorDisplayed?: boolean,
    showAttentePaiement: boolean,
    hasUserInvestimentsByVirementEnCours: boolean,
    isDon?: boolean,
    onSelectBranch?: (provider: BankProvider) => void,
    onSubmitImpossible?: (message: string) => void,
    onClickAttentePaiement?: () => void;
}

enum VirementStep {
    BANK_SELECTION,
    STRONG_AUTHENTICATION,
    VIREMENT_CLASSIQUE
}

export function SwitchVirementStep({
    montant, 
    projetId, 
    errorMessage, 
    isErrorDisplayed, 
    showAttentePaiement,
    hasUserInvestimentsByVirementEnCours,
    isDon,
    onSelectBranch, 
    onSubmitImpossible,
    onClickAttentePaiement
}: Props) {
    const [virementStep, setVirementStep] = useState<VirementStep>(VirementStep.BANK_SELECTION)
    const [bodyVirement, setBodyVirement] = useState<Investissementbody>()
    const [selectedProvider, setSelectedProvider] = useState<BankProvider>()

    return(
        <>
            {virementStep === VirementStep.BANK_SELECTION && (
                <VirementPane
                    montant={montant}
                    projetId={projetId}
                    errorMessage={errorMessage}
                    isErrorDisplayed={isErrorDisplayed}
                    onSelectBranch={onSelectBranch}
                    onSubmitImpossible={onSubmitImpossible}
                    onContinueInitiation={(body: Investissementbody, provider?: BankProvider) => {
                        setBodyVirement(body)
                        setSelectedProvider(provider)
                        setVirementStep(VirementStep.STRONG_AUTHENTICATION)
                    }}
                    isDon={isDon}
                />
            )}
            {virementStep === VirementStep.STRONG_AUTHENTICATION && (
                <StrongAuthenticationInformationPane 
                    body={bodyVirement}
                    projetId={projetId} 
                    bankName={process.env.REACT_APP_ENVIRONMENT === "TEST" ? selectedProvider?.ProviderMockId : selectedProvider?.ProviderId}
                    onSubmitImpossible={onSubmitImpossible}
                    onInvestVCLink={() => setVirementStep(VirementStep.VIREMENT_CLASSIQUE)} 
                />
            )}
            {virementStep === VirementStep.VIREMENT_CLASSIQUE && (
                <VirementClassiquePane 
                    onSubmitImpossible={onSubmitImpossible} 
                    montant={montant?.toString()} 
                    projetId={projetId} 
                    showAttentePaiement={showAttentePaiement}
                    onClickAttentePaiement={onClickAttentePaiement}
                    hasUserInvestimentsByVirementEnCours={hasUserInvestimentsByVirementEnCours}
                />
            )}
        </>
    )
}