import React from 'react'
import { Image } from 'semantic-ui-react'
import './feedback-signature.css'

type Props = {
    src:string,
    name:string,
    genre:string,
}
export default function FeedbackSignature(props: Props) {
    const {src, name, genre} = props
  return  (
    <div className='feedback-container-block'>
        <div className='feedback-image-container'>
            <Image className='feedback-image' src={src} alt={genre === "homme" ? "avatar d'homme" : "avatar d'une femme"}/>
        </div>       
        <p className="feedback-name" >{name}</p>       
    </div>
  )
}
