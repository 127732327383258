import React, {useEffect, useMemo, useState } from "react";
import {useSelector} from "react-redux";  
import { Form } from "semantic-ui-react";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { ApplicationState } from "../../../store";
import { DropdownListFieldNew } from "../../generic/dropdown-list-field";
import { InputField } from "../../generic/input-field";
import { SubmitButton } from "../../generic/submit-button";
import { GenerateVirementClassiqueBody } from "../../../model/dto/body/GenerateVirementClassiqueBody";
import { projetService } from "../../../services/projet-service";
import { ProjetListItem } from "../../../model/dto/projet-list-item";
import { investmentService } from "../../../services/investment-service";
import { GenerateVirementClassiqueResponse, InvestissementHistoryResponse } from "../../../model/dto/response";
import { SimpleButton } from "../../generic/simple-button";
import { StatutInvestissement } from "../../../model/entities/statut-investissement";

interface Props {
    userId?: number;
    investHistoryList?: InvestissementHistoryResponse[],
    onSubmitSuccess?: () => void;
    onCloseForm?: () => void;
}

type FieldData = {
  value?: any,
  name?: string,
  isValid?: boolean
};

type FormData = {
    Montant: FieldData,
    ProjetId: FieldData
}

export function GenerateVirementClassique({userId, investHistoryList, onSubmitSuccess, onCloseForm} : Props) {

    const [form, setForm] = useState<FormData>({
        Montant : { name : "Montant", value : '' , isValid: true},
        ProjetId: { name: "ProjetId", value: '', isValid: true}
    })

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const [projetPubliesList, setProjetPubliesList] = useState<ProjetListItem[]>([])
    const [isFormDiplayed, setIsFormDiplayed] = useState<boolean>(true)
    const [iban, setIban] = useState<string>("")
    const [wireReference, setWireReference] = useState<string>("")

    const montantMax = useMemo(() => {
        const project = projetPubliesList.find(prj => prj.Id == form.ProjetId.value)
        if(!project || !investHistoryList) {
            return 0
        }

        const montantMaxCollecte = project.MontantMaximalFinancement! - project.MontantEnCours!

        const investissementEnCoursList = investHistoryList.filter(hip => hip.ProjetId === form.ProjetId.value && (
            hip.StatutInvestissement?.Code === StatutInvestissement.INITIE
            || hip.StatutInvestissement?.Code === StatutInvestissement.EN_COURS
            || hip.StatutInvestissement?.Code === StatutInvestissement.REUSSI
        ))

        const montantInvestissementEnCours = investissementEnCoursList.length > 0 ? investissementEnCoursList.map(hip => hip.MontantInvesti!).reduce((sum, current) => {
            return sum + current
        }) : 0

        const montantMaxInvestissement = project.MontantMaximalInvestissement! - montantInvestissementEnCours

        return Math.min(montantMaxCollecte, montantMaxInvestissement)
    }, [form.ProjetId.value])

    useEffect(() => {
        projetService.getProjetPublies(loginProps?.oauth)
        .then((response) => {
            setProjetPubliesList(response.ProjetList!)
        });
    }, [])
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
        const fieldValue: string = e.target.value;
        setForm({...form, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid}})
    } 

    const onVirementClassiqueSuccess = (response : GenerateVirementClassiqueResponse) => {
        setIban(response.IBAN!)
        setWireReference(response.WireReference!)
        setIsFormDiplayed(false)
    }

    const body: GenerateVirementClassiqueBody = {
        Montant: form.Montant.value,
        UserId :  userId!,
        ProjetId: form.ProjetId.value
    }
   
    return (
        <>
            {isFormDiplayed && (
                <>
                    <div css={{
                        marginBottom: '10px'
                    }}>
                        <p css={{
                            fontSize: '16px',
                            fontWeight: 'bold'
                        }}>
                            Montant max : {montantMax / 100}€
                        </p>
                    </div>
                    <Form>
                        <Form.Field>
                            <DropdownListFieldNew
                                label="Titre projet"
                                field={form.ProjetId}
                                placeholder="Titre projet"
                                datasource={projetPubliesList.map((c) => ({
                                    text: c.Titre!,
                                    value: c.Id!,
                                }))}
                                onChange={(value) =>
                                    handleInputChange(
                                    { target: { value } } as any,
                                        form.ProjetId.name
                                    )
                                }                        
                            />
                        </Form.Field>
                        <Form.Field>
                            <InputField 
                                data={form.Montant}
                                label="Montant"
                                onChange={handleInputChange}
                            />
                        </Form.Field>
                        <SubmitButton 
                            data={body} 
                            label="Valider"
                            action={() => investmentService.generateVirementClassique(loginProps?.oauth, form.ProjetId.value, body)}
                            onActionSuccess={onVirementClassiqueSuccess} 
                            loaderArea="modal"
                        />
                    </Form>
                </>
            )
            ||
            (
                <div>
                    <p>Voici les informations à transmettre à l'utilisateur</p>
                    <p>IBAN : {iban}</p>
                    <p>Référence : {wireReference}</p>
                    <SimpleButton 
                        label="Fermer"
                        onClick={onCloseForm}
                    />
                </div>
            )
            }
        </>
    );
}

 