import { OAuthResponse } from "../model/authentication/oauth-response";
import { RecentlyTreatedMangoPayEventResponse, RecentlyTreatedTrueLayerEventsListResponse, RecentMangoPayEventResponse, RecentTrueLayerEventListResponse } from "../model/dto/response";
import { treatHttpResponseCode } from "./http-helper";

export const mangopayService = {
    getMangoPayEventsHistory,
    getTruelayerEventsHistory,
    getTruelayerTreatedEventsHistory,
    getMangoPayTreatedEventsHistory
}

function getMangoPayEventsHistory(oauth: OAuthResponse | undefined): Promise<RecentMangoPayEventResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/mangopay/events-history`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<RecentMangoPayEventResponse>
    })
}

function getTruelayerEventsHistory(oauth: OAuthResponse | undefined): Promise<RecentTrueLayerEventListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/truelayer/events-history`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<RecentTrueLayerEventListResponse>
    })
}

function getTruelayerTreatedEventsHistory(oauth: OAuthResponse | undefined): Promise<RecentlyTreatedTrueLayerEventsListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/truelayer/treated-events-history`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<RecentlyTreatedTrueLayerEventsListResponse>
    })
}

function getMangoPayTreatedEventsHistory(oauth: OAuthResponse | undefined): Promise<RecentlyTreatedMangoPayEventResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/mangopay/treated-events-history`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<RecentlyTreatedMangoPayEventResponse>
    })
}