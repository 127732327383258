import React, { useState } from "react";
import { Icon, Modal, SemanticICONS } from "semantic-ui-react";

type Props = {
  editable?: boolean;
  title: string;
  display?: React.ReactNode;
  children?: React.ReactNode;
  icon?: SemanticICONS;
  deleteIcon?: SemanticICONS;
  containElements?: boolean;
  iconCss?: any;
  editor: (close: () => void, action: string) => React.ReactNode;
};

export function LightEditableBlock({
  title,
  display,
  children,
  editor,
  icon = "pencil",
  deleteIcon,
  containElements,
  iconCss = {},
  editable = true,
}: Props) {
  const [isToBeEdited, setIsToBeEdited] = useState(false);
  const [isToBeRemoved, setIsToBeRemoved] = useState(false);
  const [action, setAction] = useState("");

  return (
    <>
      {editable && (
        <div>
           <div
              css={{
                position: "relative",
                ...iconCss,
                float: "right",
                padding: "5px",
                cursor: "pointer",
                zIndex: 10,
                "&:hover": {
                  transform: "scale(1.2)",
                },
              }}
              onClick={() => {
                setAction("edit")
                setIsToBeEdited(true);
              }}
            >
               <Icon floated="right" color="blue" name={icon} />
            </div>
            {deleteIcon && 
              <div
                css={{
                  ...iconCss,
                  float: "right",
                  marginRight:"30px",
                  padding: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.2)",
                  },
                  visibility: containElements ? 'visible' : 'hidden'
                }}
                onClick={() => {
                  setAction("remove")
                  setIsToBeRemoved(true);
                }}
              >
                <Icon floated="right" color="red" name={deleteIcon} />
              </div>
            }
        </div>
      )}
      {display || children}
      {
        action === "edit" ? 
          <Modal onClose={() => setIsToBeEdited(false)} open={isToBeEdited}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                {editor(() => setIsToBeEdited(false), action)}
              </Modal.Description>
            </Modal.Content>
          </Modal>
        :
          <Modal onClose={() => setIsToBeRemoved(false)} open={isToBeRemoved}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                {editor(() => setIsToBeRemoved(false),  action)}
              </Modal.Description>
            </Modal.Content>
          </Modal>
      }
    </>
  );
}
