export interface OnboardingStatus {
    Code?: string,
    Libelle?: string
}

const VALIDE: string = "VAL"
const COMPLET: string = "COM"
const ERREUR: string = "ERR"
const VAL_EMAIL_A_FAIRE : string = "VAA"
const PRESQUE_COMPLET : string = "PRC"
const PARTIELLEMENT_COMPLET : string = "PAC"

export const OnboardingStatus = {
    VALIDE,
    ERREUR,
    COMPLET,
    VAL_EMAIL_A_FAIRE,
    PRESQUE_COMPLET,
    PARTIELLEMENT_COMPLET
}