import React from "react";
import {
    Container,
    Grid,
    GridColumn,
    Icon,
    Image,
} from "semantic-ui-react";
import "./section-objectif-dev-durable.css";

export default function SectionObjectifDevDurable() {
    return (
        <Container id="section-objectif-dev-durable-main-container">
            <h2 className="section-objectif-dev-durable-title ">
            Contribuez avec votre épargne aux objectifs de développement durable de l’ONU
            </h2>
            <Container className="section-objectif-dev-durable-illustration-container">
                <Grid centered>
                    <GridColumn
                        className="section-objectif-dev-durable-objectifs-container"
                        mobile={16}
                        tablet={9}
                        computer={7}                      
                    >
                        <p className="section-objectif-dev-durable-objectif">
                            <Icon name="check circle outline" style={{ color: "green" }} />
                            <span className="section-objectif-dev-durable-objectif-span">
                                Objectif 3 : BONNE SANTÉ ET BIEN-ÊTRE
                            </span>
                        </p>
                        <p  className="section-objectif-dev-durable-objectif">
                            <Icon name="check circle outline" style={{ color: "green" }} />
                            <span className="section-objectif-dev-durable-objectif-span">
                                Objectif 4 : ÉDUCATION DE QUALITÉ
                            </span>
                        </p>
                        <p  className="section-objectif-dev-durable-objectif">
                            <Icon name="check circle outline" style={{ color: "green" }} />
                            <span className="section-objectif-dev-durable-objectif-span">
                            Objectif 5 : ÉGALITÉ ENTRE LES SEXES
                            </span>
                        </p>
                        <p  className="section-objectif-dev-durable-objectif">
                            <Icon name="check circle outline" style={{ color: "green" }} />
                            <span className="section-objectif-dev-durable-objectif-span">
                            Objectif 6 : EAU PROPRE ET ASSAINISSEMENT
                            </span>
                        </p>
                        <p  className="section-objectif-dev-durable-objectif">
                            <Icon name="check circle outline" style={{ color: "green" }} />
                            <span className="section-objectif-dev-durable-objectif-span">
                            Objectif 7 : ÉNERGIE PROPRE ET D'UN COÛT ABORDABLE
                            </span>
                        </p>
                        <p  className="section-objectif-dev-durable-objectif">
                            <Icon name="check circle outline" style={{ color: "green" }} />
                            <span className="section-objectif-dev-durable-objectif-span">
                            Objectif 11 : VILLES ET COMMUNAUTÉS DURABLES
                            </span>
                        </p>
                    </GridColumn>
                    <GridColumn mobile={16} tablet={6} computer={6}>
                        <Image
                            src="/img/nos-engagements/camembert_dev_durable.png"
                            style={{ margin: "auto", paddingBottom:"10px",  }}
                            alt="diagramme logo des objectifs de développement durable de l'ONU"
                        />
                    </GridColumn>
                </Grid>
            </Container>
        </Container>
    );
}
