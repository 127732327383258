import * as React from "react";
import { AppMainRouter } from "./routing/app-main-router"
import TagManager from "react-gtm-module"

export class Application extends React.Component {
    render() {
        const tagManagerArgs = {
            gtmId: process.env.REACT_APP_GOOGLE_TAG_ID ?? ''
        }

        TagManager.initialize(tagManagerArgs)
        return (
            <div css={{
                height: '100%'
            }}>
                <AppMainRouter />
            </div>
        )
    }
}
  
export default Application;
