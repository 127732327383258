import { Dropdown, DropdownProps } from 'semantic-ui-react'
import React, { useEffect, useState } from "react"

export type DropdownElement = {
  text: string,
  value: string | number
}

type FieldData = {
  name?: string,
  value?: any,
  isValid?: boolean
}

type Props = {
  label: string,
  field: FieldData,
  submitted?: boolean,
  placeholder: string
  datasource: DropdownElement[],
  filterable?: boolean
  error?: string,
  maxWidth?: any,
  autocomplete?: string,
  onChange?: (value: any) => void
  validateField?: (value: any) => boolean
}

export function DropdownListFieldNew({label, field, submitted, datasource, placeholder, filterable, error, maxWidth, autocomplete, onChange, validateField}: Props) {
  const [isValid, setIsValid] = useState(field.isValid)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (!submitted) return;

    const isValid = validateField?.(field.value) ?? false;
    setIsValid(isValid);

    if (!isValid && error) {
      setErrorMessage(error);
    }
  }, [submitted]);

  const onValueChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if(validateField && !validateField(data.value)) {
      setIsValid(false)
    }
    else {
      setIsValid(true)
    }

    if(onChange) {
      onChange(data.value)
    }
  }

  return (
    <>
      <label>{label}</label>
      {filterable && (
        <Dropdown
          css={{
            maxWidth: `${maxWidth}px`
        }}
          placeholder={placeholder}
          options={datasource}
          value={field.value}
          onChange={onValueChange}
          autocomplete={autocomplete}
      />
      ) || (
        <Dropdown
          css={{
            maxWidth: `${maxWidth}px`
        }}
          placeholder={placeholder}
          fluid
          selection
          options={datasource}
          value={field.value}
          onChange={onValueChange}
          autocomplete={autocomplete}
      />
      )}
      
      {!isValid && (
          <div css={{
              color: "#F03E3E",
              fontSize: "10px"
          }}>
              {errorMessage}
          </div>
      )}
    </>
  )
}