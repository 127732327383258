import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { AuthenticationState } from '../../store/authentication/types'
import { Profil } from '../../model/entities/profil'
import { trackPromise } from 'react-promise-tracker'
import { projetService } from '../../services/projet-service'
import { ProjetListItem } from '../../model/dto/projet-list-item'
import { publicService } from '../../services/public-service'
import { ProfilCode, ProjetDetailResponse } from '../../model/dto/response'
import Projet from '../../model/projet'
import { ProjetListHeader } from './projet-list-header'
import { Card, Container, Modal, Tab, TabProps } from 'semantic-ui-react'
import { PourBientotScreen } from '../generic/c-est-pour-bientot'
import { LoadingSpinner } from '../generic/loading-spinner'
import { ProjetLine } from './projet-line'
import { SubmitButton } from '../generic/submit-button'
import { StatutProjet } from '../../model/entities/statut-projet'
import { FlowStep } from '../../model/entities/flow-step'
import SwitchCreateProjetWorflow from './switch-create-projet-worflow'

type Props = {}

export function ProjetListNew({}: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const [projetList, setProjetList] = useState<ProjetListItem[]>([])
    const [error, setError] = useState("")
    const [creationStep, setCreationStep] = useState<string | null>()
    const [isEdited, setIsEdited] = useState(false)
    const [newProjet, setNewProjet] = useState<Projet>()
    const [reload, setReload] = useState(true)
    const [index, setIndex] = useState<number>(loginProps?.oauth?.etablissementId !== undefined && loginProps?.oauth?.etablissementId.toString() !== "" ? 1 : 0);
    const [isMecenatTabClicked, setIsMecenatTabClicked] = useState(loginProps?.oauth?.etablissementId !== undefined && loginProps?.oauth?.etablissementId.toString() !== "" ? true : false);

    useEffect(() => {
        if(reload) {
            if(loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET) {
                trackPromise(projetService.getProjetList(loginProps?.oauth, isMecenatTabClicked)
                .then(data => {
                    if (data) {
                        setProjetList(data.ProjetList!)
                    }
                })
                .catch(ex => {
                    setError(ex)
                }));
            } else if(loginProps?.oauth?.profilCode === Profil.VILLYZ_USER) {
                trackPromise(projetService.getProjetAdmin(loginProps?.oauth, isMecenatTabClicked)
                .then(data => {
                    if (data) {
                        setProjetList(data.ProjetList!)
                    }
                })
                .catch(ex => {
                    setError(ex)
                }));
            }
            else {
                trackPromise(publicService.getProjetList(isMecenatTabClicked)
                .then(response => {
                    setProjetList(response.ProjetList!)
                })
                .catch(ex => {
                    setError(ex)
                }));
            }
            setReload(false)
        }
    }, [reload])

    const stepTitle = useMemo(() => {
        if(!creationStep) {
            return "Type de collecte"
        }
        if(creationStep === FlowStep.INFORMATIONS_GENERALES) {
            return "Informations générales"
        }
            
        if(creationStep === FlowStep.DESCRIPTION) {
            return "Description du projet"
        }
            
        if(creationStep === FlowStep.FINANCEMENT_GLOBAL) {
            return "Financement du projet"
        }

        if(creationStep === FlowStep.FINANCEMENT_PARTICIPATIF) {
            return "Financement participatif"
        }

        if(creationStep === FlowStep.PERIODE_DE_COLLECTE) {
            return "Période de collecte"
        }

        if(creationStep === FlowStep.FINALISATION) {
            return "Finalisation"
        }
            
       return ""
    }, [creationStep])

    const onNewProjetClick = (response: ProjetDetailResponse) => {
        setIsEdited(true)
        setNewProjet(response.Projet)
        //setCreationStep(response.NextStepKey!)
    }

    const updateStep = (stepKey: string) => {
        setCreationStep(stepKey)
    }

    const isCardClickable = (projet: ProjetListItem) => {
        if(projet.StatutProjetCode === StatutProjet.A_VENIR || projet.StatutDonProjetCode === StatutProjet.A_VENIR){
            if(loginProps?.user?.ProfilCode === ProfilCode.PORTEUR_PROJET && projet.EtablissementId === loginProps?.user?.Etablissement?.Id){
                return true
            }
            else if(loginProps?.oauth?.profilCode === ProfilCode.VILLYZ){
                return true
            }
            return false
        }
        return true
    }

    const handleTabChange = (e: any, data: TabProps) => {
        const newIndex = data.activeIndex as number;
        setIndex(newIndex);
        setIsMecenatTabClicked(newIndex === 1);
        setReload(true);
    };

    const panes = [
        {
            menuItem: "Investissement Citoyen",
            render: () => {
                return (
                    <Tab.Pane attached={false} css={{background: 'transparent !important', border: 'none !important'}}>
                        <LoadingSpinner>
                            {projetList.length > 0 ?
                                <Card.Group itemsPerRow={3} doubling stackable>
                                    {projetList.map(projetItem => 
                                        <ProjetLine 
                                            key={projetItem.Id} 
                                            projet={projetItem} 
                                            isCardClickable={isCardClickable(projetItem)}
                                            reload={()=> setReload(true)} 
                                            isDon={isMecenatTabClicked}
                                        />
                                    )}
                                </Card.Group>
                            : 
                                <PourBientotScreen title="Publiez votre 1er projet !"/>
                            }
                        </LoadingSpinner>
                    </Tab.Pane>
                )
            },     
        },
        {
            menuItem: "Mécénat d'entreprise",
            render: () => {
                return (
                <Tab.Pane attached={false} css={{ background: 'transparent !important', border: 'none !important' }}>
                    <LoadingSpinner>
                        {projetList.length > 0 ? (
                            <Card.Group itemsPerRow={3} doubling stackable>
                                {projetList.map((projetItem) => (
                                    <ProjetLine
                                        key={projetItem.Id}
                                        projet={projetItem}
                                        isCardClickable={isCardClickable(projetItem)}
                                        isDon={isMecenatTabClicked}
                                        reload={() => setReload(true)}
                                    />
                                    ))}
                            </Card.Group>
                            ) : (
                                loginProps.user?.ProfilCode === ProfilCode.PORTEUR_PROJET ? 
                            <PourBientotScreen title="Publiez votre 1er projet !" /> : <PourBientotScreen title="Le 1er projet de mécénat sera bientôt disponible!" />
                        )}
                    </LoadingSpinner>
                </Tab.Pane>
              );
            },
        },
    ]

    return (
        <>
            <ProjetListHeader />
            <Container css={{marginTop: "40px"}}>
                <Tab menu={{ secondary: true, pointing: true }} 
                    panes={panes} 
                    onTabChange={handleTabChange}
                    activeIndex={index}
                />
                <div css={{margin: "20px 0"}}>
                    {loginProps && loginProps.oauth?.profilCode === ProfilCode.PORTEUR_PROJET && (
                    <div css={{textAlign : "center"}}>
                        <SubmitButton 
                            label="Créer un nouveau projet"
                            action={() => projetService.createProjet(loginProps?.oauth)}
                            onActionSuccess={onNewProjetClick}
                            
                        />
                        <Modal closeOnDocumentClick={false} css={{overflowX: "hidden"}} onClose={() => setIsEdited(false)} open={isEdited}>
                            <Modal.Header>{stepTitle}</Modal.Header>
                            <Modal.Content>
                            <Modal.Description>
                                <SwitchCreateProjetWorflow 
                                    newProjet={newProjet!}
                                    onNextStep={updateStep}
                                    loginProps={loginProps}
                                />
                            </Modal.Description>
                            </Modal.Content>
                        </Modal>
                    </div>
                    )}
                </div>
            </Container>
        </>
    )
}