import React from "react";
import { section } from "./styles";
import { Container, Table } from "semantic-ui-react";

export function Protection() {
  return (
    <div>
      <div css={[section]}>
        <Container>
          <h1>Politique de Protection des Données​</h1>
          <h2>Sous-titre – mis à jour le XX/YY/ZZZ​</h2>

          <p>
            En tant que responsable de traitement, la Société Villyz, Société
            par Actions Simplifiée (SAS) au capital social de 4 202€,
            immatriculée au RCS de Paris sous le numéro 884 826 520, dont le
            siège social se situe 35 avenue Reille à Paris (75014) («{" "}
            <strong>Villyz</strong> » ou « <strong>Nous</strong> »), s'engage à
            protéger la confidentialité et la sécurité de vos Données
            Personnelles, en conformité avec la réglementation applicable à la
            protection des données à caractère personnel, et notamment le
            Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27
            avril 2016 relatif à la protection des personnes physiques à l'égard
            du traitement des données à caractère personnel et à la libre
            circulation de ces données (« <strong>RGPD</strong> »).
          </p>

          <p>
            Cette Politique de confidentialité vise à expliquer comment nous
            recueillons, utilisons, protégeons et divulguons vos Données
            Personnelles lorsque vous naviguez sur le « <strong>Site</strong> »
            www.villyz.fr et/ou lorsque vous utilisez les «{" "}
            <strong>Services</strong> » de Villyz, à savoir les Services que
            nous proposons, ayant pour objet de permettre (i) l'intermédiation
            entre les porteurs de projets et les utilisateurs via le Site aux
            fins de faciliter leur interaction et de promouvoir les projets
            présentés sur le Site, (ii) d’accompagner les porteurs de projet
            dans la conduite d’actions d’information à destination des prêteurs
            une fois la collecte réalisée et (iii) la collecte des contributions
            par l'intermédiation du Site aux fins de permettre aux utilisateurs
            d'apporter leur soutien aux porteurs de projets et de financer la
            réalisation des projets présentés sur le Site.
          </p>

          <p>
            En utilisant les Services, vous reconnaissez avoir pris connaissance
            de cette Politique de confidentialité.
          </p>

          <p>
            Pour toute demande concernant notre Politique de confidentialité ou
            la protection de vos Données Personnelles, n’hésitez pas à nous
            contacter, en nous écrivant à l’adresse suivante : rgpd@villyz.fr.
          </p>

          <h2>1. Quelles informations collectons-nous ?</h2>

          <p>
            Lorsque vous naviguez sur le Site www.villyz.fr, que vous créez un
            compte et/ou que vous utilisez les Services proposés par Villyz,
            nous collectons des informations vous identifiant personnellement («
            <strong>Données Personnelles</strong> »). Il peut s’agir des données
            suivantes :
          </p>

          <ul>
            <li>
              - Données de contact : nom, prénom, adresses email et postale,
              numéros de téléphone fixe et/ou mobile,., date de naissance,
              nationalité et résidence fiscale.
            </li>

            <li>
              - Données d’identification et d’authentification : copie d’un
              document d’identité, identifiant du compte et mot de passe.
            </li>

            <li>
              - Données financières, bancaires et comptables : RIB, données
              financières, données comptables, documents financiers
              complémentaires, données transactionnelles, mandat ID,
              commissions.
            </li>

            <li>
              - Informations liées au projet pouvant contenir des Données
              Personnelles.
            </li>

            <li>- Toute correspondance que nous échangeons.</li>
          </ul>

          <p>
            La décision de fournir vos Données Personnelles est volontaire.
            Toutefois, si vous choisissez de ne pas fournir les informations
            demandées, vous ne pourrez pas utiliser les Services.
          </p>

          <p>
            En principe, nous collectons vos Données Personnelles directement
            auprès de vous. Dans certains cas, notamment pour l’analyse de
            données, nous collectons des Données Personnelles vous concernant
            auprès de nos partenaires bancaires ou auprès de tiers tels que vos
            collaborateurs. Enfin, certaines Données Personnelles sont
            collectées depuis des ressources accessibles au public (par exemple,
            les documents obtenus auprès de la CADA).
          </p>

          <h2>2. Pourquoi traitons-nous vos Données Personnelles ?</h2>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Finalité(s) du traitement </Table.HeaderCell>
                <Table.HeaderCell>
                  Base(s) juridique(s) du traitement{" "}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <p>
                    Création d’un compte en ligne et gestion du compte
                    (notamment, personnalisation de la page personnelle du
                    citoyen)
                  </p>

                  <p>Utilisation des Services</p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Notre intérêt légitime à vous fournir un accès sécurisé à
                    notre plateforme en ligne, conformément à l’article 6 (1)
                    (f) du RGPD
                  </p>

                  <p>
                    Exécution d'un contrat ou exécution de mesures
                    précontractuelles, conformément à l’article 6 (1) (b) du
                    RGPD
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p>Prospection commerciale et newsletter</p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Notre intérêt légitime à communiquer avec vous sur de
                    nouveaux projets à financer, conformément à l’article 6 (1)
                    (f) du RGPD. Vous pouvez vous opposer à ce traitement au
                    moment de la collecte de vos Données Personnelles et à tout
                    moment par la suite.
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p>
                    Vérifications concernant un projet, un porteur de projet ou
                    un investisseur, ou leur personne de contact{" "}
                  </p>
                  <p>Détection, prévention et traitement des fraudes </p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Respect d’une obligation légale, conformément à l’article 6
                    (1) (c) du RGPD
                  </p>
                  <p>
                    Notre intérêt légitime à effectuer des vérifications sur les
                    projets soumis, les porteurs de projets et si strictement
                    nécessaire, les investisseurs, conformément à l’article 6
                    (1) (f) du RGPD
                  </p>
                  <p>
                    Données Personnelles manifestement rendues publiques par les
                    personnes concernées, conformément à l’article 9 (2) (e) du
                    RGPD 
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p>
                    Gestion de la relation contractuelle et/ou commerciale avec
                    un prospect, un investisseur ou un porteur de projet (ou
                    leur personne de contact)
                  </p>
                  <p>
                    Communication aux investisseurs concernant le projet pour
                    lequel ils investissent
                  </p>
                  <p>Présentation des projets</p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Notre intérêt légitime à gérer notre relation contractuelle
                    et commerciale, conformément à l’article 6 (1) (f) du RGPD
                  </p>
                  <p>
                    Respect d’une obligation légale, conformément à l’article 6
                    (1) (c) du RGPD
                  </p>
                  <p>
                    Exécution d'un contrat ou exécution de mesures
                    précontractuelles, conformément à l’article 6 (1) (b) du
                    RGPD
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p>Gestion des transactions</p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Notre intérêt légitime à vous fournir les Services,
                    conformément à l’article 6 (1) (f) du RGPD
                  </p>
                  <p>
                    Respect d’une obligation légale, conformément à l’article 6
                    (1) (c) du RGPD
                  </p>
                  <p>
                    Exécution d'un contrat ou exécution de mesures
                    précontractuelles, conformément à l’article 6 (1) (b) du
                    RGPD
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p>
                    Gestion des litiges, phase contentieuse et précontentieuse
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Notre intérêt légitime à faire valoir nos droits et intérêts
                    en justice, conformément à l’article conformément à
                    l’article 6 (1) (f) du RGPD
                  </p>
                  <p>
                    Données Personnelles manifestement rendues publiques par les
                    personnes concernées, conformément à l’article 9 (2) (e) du
                    RGPD
                  </p>
                  <p>
                    Traitement est nécessaire à la constatation, à l'exercice ou
                    à la défense d'un droit en justice, conformément à l’article
                    9 (2) (f) du RGPD
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p>
                    Réalisation de statistiques, notamment, pour l’estimation du
                    potentiel de levée
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Notre intérêt légitime à effectuer des analyses de données,
                    conformément à l’article 6 (1) (f) du RGPD
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p>Respect des lois et application de la loi </p>

                  <p>
                    Réponse à une autorité compétente, conformément au droit
                    applicable
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Respect d’une obligation légale, conformément à l’article 6
                    (1) (c) du RGPD
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <p>
                    Garantie et maintien de la sécurité matérielle et technique
                    concernant les Services et applicatifs Villyz, notamment par
                    la détection, la prévention ou le traitement des incidents
                    de sécurité
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Notre intérêt légitime à garantir une sécurité physique et
                    technique, conformément à l’article 6 (1) (f) du RGPD
                  </p>
                  <p>
                    Respect d’une obligation légale, conformément à l’article 6
                    (1) (c) du RGPD
                  </p>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <p>
            Dans le cadre de nos activités, nous traitons vos Données
            Personnelles pour les finalités et sur les bases juridiques
            suivantes :
          </p>

          <h2>
            3. Comment sont protégées les données personnelles que nous
            collectons ?
          </h2>

          <p>
            Villyz s'engage à protéger la sécurité de vos Données Personnelles,
            conformément à la réglementation applicable à la protection des
            données à caractère personnel. A ce titre, nous utilisons ce que
            nous croyons être les meilleures mesures de sécurité, conçues pour
            protéger vos Données Personnelles contre tout accès non autorisé et
            déployons les efforts nécessaires pour sécuriser vos Données
            Personnelles contre tout accès non autorisé. Nous prenons des
            mesures techniques et organisationnelles appropriées, notamment en
            ce qui concerne les développements techniques et la mise en place de
            mesures spécifiques comme le système d’accès par mot de passe.
          </p>

          <p>
            De même, nous avons mis en place des mesures de protection pour
            assurer la confidentialité, la sécurité et l’intégrité de vos
            Données Personnelles. Nous veillons par exemple à restreindre
            l’accès aux données à certains de nos collaborateurs qui ont été
            formés à l’observation de règles en matière de confidentialité. Nous
            veillons en outre à stocker vos Données Personnelles dans des
            environnements d’exploitation sécurisés. Ces mesures tiennent compte
            de la sensibilité des données que nous recueillons, traitons et
            stockons et de l’état actuel de la technologie.
          </p>

          <p>
            Toutefois, aucune méthode de transmission par Internet ni aucune
            méthode de stockage n'est sûre à 100%. Ainsi, même si nous faisons
            nos meilleurs efforts pour protéger vos Données Personnelles, nous
            ne maîtrisons pas les risques liés au fonctionnement d'Internet et
            attirons votre attention sur d'éventuels risques en terme de pertes
            ponctuelles de données ou d'atteinte à la confidentialité des
            données transitant via ce réseau. En tout état de cause, nous nous
            engageons à notifier dans les meilleurs délais et, si possible, 72
            heures au plus tard après en avoir pris connaissance, toute
            violation de Données Personnelles, dans les conditions fixées aux
            articles 33 et 34 du RGPD.
          </p>

          <p>
            De même, il vous incombe de protéger la sécurité de votre compte, de
            votre identifiant et de votre mot de passe, tel que précisé dans nos
            Conditions Générales d’Utilisation [insérer un lien hypertexte vers
            les CGU].
          </p>

          <h2>
            4. Les finalités de traitement et la durée de conservation de vos
            données personnelles
          </h2>

          <p>
            Dans le cadre de l’utilisation du site et des services de Villyz,
            vos Données Personnelles sont utilisées pour les finalités suivantes
            :
          </p>

          <ul>
            <li>
              - L'exécution de nos obligations, légales ou règlementaires,
              telles que la lutte contre le blanchiment des capitaux et le
              financement du terrorisme. A ce titre, les données du Client sont
              conservées en base d’archive chiffrée pour une durée de 5 ans à
              compter de la fin de la relation client. Les données ne sont alors
              accessibles qu’à la demande d’une autorité de contrôle judiciaire
              ou administrative ;
            </li>

            <li>
              - La protection de l'intérêt légitime de Villyz notamment dans le
              cadre de la lutte contre la fraude et la cybercriminalité et
              conservées à ce titre pour une durée d’1 an. En cas de fraude
              avéré, les données relatives à la fraude sont conservées pour une
              durée maximale de 5 ans à compter de la clôture du dossier de
              fraude. Pour les personnes inscrites sur une liste des fraudeurs
              avérés, les données les concernant sont supprimées passé le délai
              de 5 ans à compter de la date d'inscription sur cette liste.
              Lorsqu'une procédure judiciaire est engagée, les données sont
              conservées jusqu'au terme de la procédure judiciaire. Elles sont
              ensuite archivées selon les durées légales de prescription
              applicables ;
            </li>

            <li>
              - L'exécution des missions relatives à l’activité d'intermédiaire
              en financement participatif, de courtier en opération de banque et
              de services de paiement par Villyz, la gestion des Utilisateurs,
              Porteurs de projet et Visiteurs et l’accès au site. Les données
              personnelles sont conservées pour une durée de 3 ans à compter de
              la dernière connexion ou contact avec Villyz. Dans le cas où
              Villyz constate une inactivité́ d’une durée supérieure à 1 an de la
              part de l’Utilisateur ou Porteurs de projet. Ce dernier sera
              sollicité afin de lui proposer la suppression de son compte et de
              ses données personnelles y afférentes ;
            </li>

            <li>
              - Les données et informations relatives à la gestion de la
              relation client et de vos échanges avec Villyz sont conservées
              pour une durée maximale de 5 ans à compter de votre dernier
              contact avec Villyz. Ces données sont archivées à des fins de
              contentieux ;
            </li>

            <li>
              - L'amélioration de la relation clientèle à des fins de
              propositions personnalisées et commerciales et par l’intermédiaire
              de la newsletter sous réserve de votre consentement, et conservées
              à ce titre pendant la durée de la relation commerciale. Vous
              pouvez également exercer vos droits dans les conditions et à
              l’adresse rgpd@villyz.fr ;
            </li>

            <li>
              - La collecte de vos données personnelles pour le compte de
              Mangopay, prestataire de service de paiement de Villyz et agréé
              par l’Autorité de Contrôle Prudentiel et de Résolution ACPR, qui
              traite les données pour l’exécution des transactions, l’ouverture
              et la gestion des comptes de paiement ainsi que leurs propres
              obligations de lutte anti-blanchiment et financement du
              terrorisme. Mangopay effectue des traitements de données
              personnelles dont Villyz est co-responsable du traitement ;
            </li>

            <li>
              - La collecte de Données à des fins statistiques relatives à votre
              utilisation de nos services. À cette fin, nous collectons des
              données nécessaires à la réalisation de statistiques d’audience,
              de mesures de performance correspondant aux obligations légales et
              réglementaires et de prospection commerciale. Ces données sont
              anonymisés.
            </li>
          </ul>

          <h2>
            5. Quels sont vos droits en matière d’accès à vos données
            personnelles et de contrôle de leur utilisation ?
          </h2>

          <p>
            Conformément à la réglementation applicable à la protection des
            données à caractère personnel, vous disposez d’un droit d’accès, de
            rectification et d’effacement ou de portabilité de vos Données
            Personnelles, d’en déterminer leur sort après votre décès, ainsi que
            d’un droit de vous opposer au traitement ou à en demander la
            limitation.
          </p>

          <p>
            Vous disposez également du droit de retirer votre consentement pour
            les traitements fondés sur cette base juridique.
          </p>

          <p>
            Ces droits s’exercent auprès de Villyz en adressant un e-mail à
            cette adresse :rgpd@villyz.fr.
          </p>

          <p>
            Enfin, vous disposez du droit d’introduire une réclamation auprès de
            la CNIL ou toute autre autorité de protection, si vous estimez que
            le traitement de vos données n’est pas effectué conformément aux
            dispositions applicables. Pour en savoir plus, vous pouvez visiter
            cette section du site de la CNIL.
          </p>

          <p>
            Afin que Villyz puisse satisfaire cette demande, vous devrez lui
            faire parvenir les éléments nécessaires à votre identification :
            nom, prénom, e-mail et éventuellement adresse postale.
          </p>

          <h2>6. A QUI COMMUNIQUONS-NOUS VOS DONNEES PERSONNELLES ?</h2>

          <p>
            Nous préservons la confidentialité de vos Données Personnelles en
            toutes hypothèses.
          </p>

          <p>
            Pour les besoins et la bonne exécution du service, vos Données
            Personnelles sont communiquées à des sous-traitants ou partenaires.
            Dans ce cadre, nous nous assurons que vos Données Personnelles sont
            traitées dans le respect de la RGPD. Nous choisissons avec soin nos
            sous-traitants et demeurons en tout état de cause responsables du
            traitement de vos données à caractère personnel par nos
            sous-traitants et partenaires ;
          </p>

          <p>
            Nous transmettons vos Données Personnelles à notre Prestataire de
            service de paiement, Mangopay afin de réaliser la gestion des fonds
            et des transactions dans le respect des lois et obligations en
            matière de lutte contre la fraude, le blanchiment et le financement
            du terrorisme. Les informations ainsi transmises le sont dans le
            respect des conditions générales d’utilisation défini par le
            Prestataire de service de paiement : XXXXXX.
          </p>

          <p>
            Vos Données Personnelles peuvent également être communiquées à des
            autorités administratives et judiciaires compétentes ou plus
            généralement à tous tiers en faisant la demande pour satisfaire ses
            obligations légales et réglementaires.
          </p>

          <p>
            Les données relatives à l’identité, le montant, l’email, le code
            postal ainsi que le pays du prêteur sont communiqués au Porteur de
            projet à des fins de bonne exécution du contrat. Ces données sur
            visible uniquement sur la page du Porteur de projet, sauf à ce que
            le Prêteur demande à rester anonyme. Les Porteurs de projet ont pour
            obligation de conserver ses informations confidentielles
          </p>

          <p>
            Enfin, vos Données Personnelles sont transmises à nos sous-traitants
            (hébergeurs,WWWW impliqués dans le traitement. Nos sous-traitants
            sont tenus au respect des obligations énumérées dans le RGPD et
            notamment de sécurité et de confidentialité. Nous choisissons avec
            soin nos sous-traitants et demeurons en tout état de cause
            responsables du traitement de vos données à caractère personnel par
            nos sous-traitants ;
          </p>

          <p>
            Le Porteur de projet donne l’autorisation à Villyz d’exploiter le
            Projet à des fins d’illustration, de communication et de promotion.
            Le Porteur de projet accepte que des informations relatives à sa
            commune et à ses Données Personnelles soient exploitées pour la
            durée de réussite du Projet et pour sa seule finalité
          </p>

          <h2>7. TRANSFERT DES DONNEES HORS DE L’UNION EUROPEENN</h2>

          <p>
            Vos Données Personnelles, peuvent être transférées vers des pays
            situés hors de l'Union Européenne. Ces transferts se font dans le
            respect des règles spécifiques qui visent à assurer la protection et
            la sécurité des données à caractère personnel. En cas de transferts
            de vos Données Personnelles vers un pays hors de l’Union Européenne,
            Villyz s’engage à mettre en place toutes les garanties appropriées
            disponibles, au regard de la réglementation en vigueur, pour
            garantir la sécurité de ces transferts.
          </p>

          <p>
            À l'occasion des opérations de paiement réalisées auprès du
            prestataire de services de paiement choisi par la Villyz, vos
            Données Personnelles peuvent être transférées vers des pays hors de
            l’Union européenne afin de réaliser les opérations nécessaires au
            respect des obligations en matière de sécurité des transactions, de
            lutte contre la fraude, le blanchiment d’argent et le terrorisme.
          </p>

          <h2>8. COOKIES ET AUTRES TRACEURS</h2>

          <h4>Que sont les cookies et pourquoi sont-ils utilisés ?</h4>

          <p>
            Comme la plupart des autres sites Internet, notre Site utilise des
            cookies. Les cookies sont de petits fichiers textes, composés
            généralement de lettres et de chiffres, stockés sur votre
            ordinateur, tablette, appareil mobile ou autre dispositif lorsque
            vous accédez à un site Internet depuis ledit appareil. Les cookies
            sont acceptés par votre navigateur Internet et permettent
            l’identification de votre appareil.Nous n’utilisons à ce jour que
            des cookies strictement nécessaires et des cookies fonctionnels, qui
            n’effectuent aucun tracking ni aucune analyse d’audience. Ces
            cookies assurent le bon fonctionnement de la plateforme et des
            services. Il ne vous ait pas possible de les refuser, sans eux, nous
            ne pourrions pas vous assurer une utilisation du site.
          </p>

          <p>
            Les cookies fonctionnels permettent la mémorisation des
            renseignements que vous avez saisis ou des options que vous avez
            choisies. Ils sont conçus pour améliorer et personnaliser les
            fonctionnalités de notre site. Ces cookies vous permettent également
            d’optimiser votre utilisation de notre site une fois que vous êtes
            connecté(e).
          </p>

          <p>
            Les cookies de fonctionnalité ne sont pas supprimés lorsque vous
            fermez votre navigateur. Ils sont enregistrés définitivement sur
            votre ordinateur ou appareil.
          </p>

          <p>
            Les cookies nécessaires permettent le fonctionnement de notre Site.
            Ils sont indispensables pour vous permettre de naviguer et utiliser
            les fonctionnalités de notre Site, comme l’accès à des zones
            sécurisées. Il s’agit principalement de cookies de session ou de
            cookies de connexion.
          </p>

          <p>
            Les cookies strictement nécessaires ne sont pas enregistrés
            définitivement sur votre ordinateur ou votre appareil ; ils sont
            supprimés après la fermeture de votre navigateur.
          </p>

          <p>
            Lorsque les cookies que nous mettons en place traitent des Données
            Personnelles, ce traitement a lieu conformément à l’article 6 (1) du
            Règlement général de protection des données (RGPD) afin de pouvoir
            protéger notre intérêt légitime en ce qui concerne les
            fonctionnalités optimales du Site et de garantir que celui-ci soit
            efficace.
          </p>

          <p>
            Vous pouvez trouver davantage d’informations sur les cookies sur les
            sites suivants :
          </p>

          <ul>
            <li>
              § AboutCookies.org - http://www.aboutcookies.org (en anglais)
            </li>
            <li>§ Wikipédia - http://en.wikipedia.org/wiki/HTTP_cookie</li>
          </ul>

          <h4>Informations sur les cookies mis en œuvre sur notre Site</h4>

          <h4>Comment modifier vos paramètres de gestion des cookies ?</h4>

          <p>
            Vous pouvez modifier les paramètres de votre navigateur pour
            modifier votre gestion des cookies.
          </p>

          <p>
            Cliquez sur les liens ci-dessous si vous souhaitez savoir comment
            gérer les paramètres de cookies de votre navigateur. N’oubliez pas
            que si vous désactivez les cookies sur votre navigateur, ces
            paramètres s’appliqueront à tous les sites Internet, et non pas
            juste à celui-ci.
          </p>

          <p>Navigateurs</p>
          <ul>
            <li>
              § Internet Explorer :
              https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
            </li>

            <li>
              § Google Chrome :
              https://support.google.com/accounts/answer/61416?co=GENIE.Platform=Desktop&hl=fr
            </li>

            <li>
              § Safari :
              https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac
              ou, pour les versions mobiles :
              https://support.apple.com/en-gb/HT201265
            </li>

            <li>
              § Firefox :
              https://support.mozilla.org/en-GB/kb/enable-and-disable-cookies-website-preferences
            </li>
          </ul>
          <p>
            Sans les cookies strictement nécessaires et les cookies de
            fonctionnalité, nous ne serons toutefois pas en mesure de vous
            proposer pleinement nos Services via notre Site.
          </p>

          <h2>9. MODIFICATIONS DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
          <p>
            Nous pouvons modifier périodiquement cette Politique de
            confidentialité. Si nous décidons de modifier cette Politique de
            confidentialité, nous vous en informerons en publiant la Politique
            de confidentialité révisée sur le Site. Ces modifications entrent en
            vigueur dès leur publication sur le Site, dont la date est indiquée
            comme la date de « mise à jour » dans la Politique de
            confidentialité révisée. L'utilisation des informations que nous
            collectons maintenant est soumise à la Politique de confidentialité
            en vigueur au moment où ces informations sont collectées. En
            continuant à utiliser les Services, vous acceptez d'être lié par la
            politique de confidentialité révisée.
          </p>
          <h2>10. AUTRES</h2>
          <p></p>
        </Container>
      </div>
    </div>
  );
}
