import React from "react";
import { Container, Divider, Header, Icon, Table } from "semantic-ui-react";
import { HeaderLined } from "../generic/header-lined";

export function Reclamations() {
  return (
    <div>
      <Container className="py-10">
        <h1 css={{fontWeight:"bold",fontFamily:"Lato, Helvetica Neue, Arial, Helvetica, sans-serif",fontSize:"2 rem ",color:"#005870"}}>
           Réclamations
        </h1>
        <p className="text-lg" css={{fontSize :"1,125 rem",fontFamily : "Quicksand, sans-serif" ,color :"#424949",fontWeight :"normal"}}>
           Pour toutes réclamations, vous devez compléter le formulaire disponible ci-dessous:
        </p>
        <div css={{marginLeft:"50px",marginBottom:"15px"}}>
          <a href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/reclamation.docx"
           target="_blank"
           rel="noreferer"
          >
            <Icon css={{color:"#A6A6A6"}} size='big' name='file alternate outline'/> 
            <span css={{fontSize :"1,125 rem",fontFamily : "Quicksand, sans-serif",color :"#A6A6A6",fontWeight :"normal"}}>Formulaire de réclamation</span>
          </a>
        </div>
        <div css={{marginBottom:"15px"}}>
          <p className="text-lg"  css={{fontSize :"1,125 rem",fontFamily : "Quicksand, sans-serif",color :"#424949",fontWeight :"normal"}}>
            Une fois completé, ce formulaire est à envoyer par voix électronique à  <a href="mailto:investisseurs@villyz.fr"> <span css={{textDecoration:"underline"}}> investisseurs@villyz.fr </span></a> 
          </p>
        </div>
        <p className="text-lg" css={{fontSize :"1,125 rem",fontFamily : "Quicksand, sans-serif",color :"#424949",fontWeight :"normal"}}>
          Pour toutes questions relatives au traitement de vos réclamations vous référer à la procédure mise en place par Villyz,
          disponible dans les CGU au lien ci-dessous: 
        </p>
        <div css={{marginLeft:"50px"}}>
          <a href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/cgu.pdf#page=15"
           target="_blank"
           rel="noreferer"
          >
            <Icon css={{color:"#A6A6A6"}} size='big' name='file pdf outline'/> 
            <span css={{fontSize :"1,125 rem",fontFamily : "Quicksand, sans-serif", color :"#A6A6A6",fontWeight :"normal"}}>Conditions Générales d’Utilisation</span>
          </a>
        </div>
      </Container>
    </div>
  );
}
