import React, { useState } from "react";
import { projetService } from "../../services/projet-service";
import { ApplicationState } from "../../store";
import { AuthenticationState } from "../../model/state/authentication-state";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { MyGallery } from "../generic/image-gallery";
import { SubmitButton } from "../generic/submit-button";

type Props = {
  source?: number[];
  projetId: number;
  loginProps?: AuthenticationState;
  closeModal?: () => void;
  onRemoveSuccess?: (ressourceId: number) => void;
};

function FileDeletionForm({
  source,
  projetId,
  loginProps,
  closeModal,
  onRemoveSuccess,
}: Props) {

  const [ressourceId, setRessourceId] = useState<number>();

  const getCurrentRessourceId = (id: number)=>{
    setRessourceId(source?.[id]);
  }

  async function deleteImage(ressourceId: number) {
    try {

      const result = await projetService.deletePicture(
        projetId,
        ressourceId,
        loginProps?.oauth
      );
      
      if (!result.IsTraitementOk) {
        throw new Error("Erreur de traitement.");
      }

      if (closeModal) {
        closeModal();
      }
      if (onRemoveSuccess) {
        onRemoveSuccess(ressourceId);
      }
    } catch (err) {
      console.log("Erreur lors de la suppression.", err);
    }
  }

  return (
    <div>
      <h1>Supprimer une photo</h1>
      <MyGallery getRessourceId = {getCurrentRessourceId} projetId={projetId} source={source}/>
      <div css={{display:"flex", justifyContent:"center", marginTop: "10px"}}>
        <SubmitButton label = "Supprimer" onClick={!ressourceId ? closeModal : ()=>deleteImage(ressourceId)}/>
      </div>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedFileUploadForm = connect(mapStateToProps, null)(FileDeletionForm);
export { ConnectedFileUploadForm as FileDeletionForm };
