import React, { FormEvent, useEffect, useState } from "react"
import { Checkbox, CheckboxProps } from "semantic-ui-react"

export type CheckBoxElement = {
    label: string,
    key: string | number
}

type FieldData = {
    name?: string,
    value?: any[],
    isValid?: boolean
}

type Props = {
    elements: CheckBoxElement[],
    data: FieldData,
    label: string,
    submitted?: boolean,
    error?: string,
    multiple?: boolean,
    vertical?: boolean,
    spaceBetween?: boolean,
    
    // value with unit
    gap?: string,
    onChange?: (value: any, isChecked?: boolean) => void,
    validateField?: (key?: any) => boolean,
    validateFieldMultiple?: (keys: any[]) => boolean
}

export function CheckBoxListField({data, elements, error, submitted, label, multiple, vertical, spaceBetween, gap, onChange, validateField, validateFieldMultiple}: Props) {
    useEffect(() => {
        if(submitted && validateField ) {
            setIsValid(validateField(data.value))
        }
        if(submitted && validateFieldMultiple && data.value) {
            setIsValid(validateFieldMultiple(data.value))
        }
    }, [submitted])

    const [selectedKey, setSelectedKey] = useState<any[] | any>(data.value ?? [])
    const [isValid, setIsValid] = useState(true)
    const [errorMessage, setErrorMessage] = useState<string|undefined>(error) 

    const onCheckboxClick = (event: React.FormEvent<HTMLInputElement>, element: CheckBoxElement, data: CheckboxProps) => {
        if(multiple) {
            if(data.checked) {
                setSelectedKey([...selectedKey, element.key])
                if(validateFieldMultiple && !validateFieldMultiple([...selectedKey, element.key])) {
                    setIsValid(false)
                }
                else {
                    setIsValid(true)
                }
            }
            else {
                let index = selectedKey.indexOf(element.key)
                selectedKey.splice(index, 1)
                setSelectedKey(selectedKey)
                if(validateFieldMultiple && !validateFieldMultiple(selectedKey)) {
                    setIsValid(false)
                }
                else {
                    setIsValid(true)
                }
            }
            
            if(onChange) {
                onChange(element.key, data.checked)
            }
        }
        else {
            if(selectedKey && selectedKey === element.key) {
                setSelectedKey(undefined)
                if(validateField && !validateField(undefined)) {
                    setIsValid(false)
                    if(onChange) {
                        onChange(undefined, false)
                    }
                }
                else {
                    setIsValid(true)
                    if(onChange) {
                        onChange(undefined, true)
                    }
                }
            }
            else {
                setSelectedKey([element.key])
        
                if(validateField && !validateField(element.key)) {
                    setIsValid(false)
                    if(onChange) {
                        onChange(element.key, false)
                    }
                }
                else {
                    setIsValid(true)
                    if(onChange) {
                        onChange(element.key, true)
                    }
                }
            }
        }
    }
    
    return (
        <div 
            css={{
                marginBottom: "8px"
            }}
        >
            <div className="field">
                <label>
                    {label}
                </label>
            </div>
            <div css={{
                display: "flex",
                justifyContent: spaceBetween ? "space-between" : "left",
                gap: gap ?? "0px",
                flexWrap: 'wrap',
                minHeight: "22px",
                flexDirection: vertical ? 'column' : 'row'
            }}>
                {multiple && elements.map(element => {
                    return (
                        <Checkbox 
                            key={element.key}
                            checked={selectedKey?.includes(element.key)}
                            className="form-group field"
                            label={element.label}
                            onChange={(e: FormEvent<HTMLInputElement>, data: CheckboxProps) => onCheckboxClick(e, element, data)}
                            css={{
                                marginRight: '6px !important'
                            }}
                        />
                    )
                })}
                {!multiple && elements.map(element => {
                    return (
                        <Checkbox 
                            key={element.key}
                            checked={selectedKey == element.key}
                            className="form-group field"
                            label={element.label}
                            onChange={(e: FormEvent<HTMLInputElement>, data: CheckboxProps) => onCheckboxClick(e, element, data)}
                            css={{
                                marginRight: '6px !important'
                            }}
                        />
                    )
                })}
            </div>
        
            {!isValid && (
                <div css={{
                    color: "#F03E3E",
                    fontSize: "10px"
                }}>
                    {errorMessage}
                </div>
            )}
        </div>
    )
}