export interface TypeInvestisseur {
    Code: String,
    Denomination: String
}

const ASSOCIATION: string = "ASC";
const COLLECTIVITE: string = "COL";
const SOCIETE: string = "SOC";
const PARTICULIER: string = "PRT";

export const TypeInvestisseur = {
  ASSOCIATION,
  COLLECTIVITE,
  SOCIETE,
  PARTICULIER
};
