import React from "react"
import './horizontal-timeline.css'

export interface TimelineElement {
    key: string,
    dataMainInfo?: string,
    dataSecondaryInfo?: string,
    order: number
}

interface HorizontalTimelineProps {
    elements: TimelineElement[],
    activeElementKey?: string,
    lastElementKey?: string,
    backgroundColor?: string,
}

interface HorizontalTimelineState {
    activeElement?: string,
    widthBetweenDots?: number
}

export class HorizontalTimeline extends React.Component<HorizontalTimelineProps, HorizontalTimelineState> {
    constructor(props: HorizontalTimelineProps) {
        super(props)
        this.state = {activeElement: props.activeElementKey}
    }

    render() {
        return this.props.elements && (
            <div>
                <div className="input-flex-container" css={{marginTop:"35px"}}>
                    {this.props.elements.map(element => {
                        if (this.props.activeElementKey === element.key) {
                            return (
                                this.props.lastElementKey === element.key && (
                                <div key={element.key} css={{ ":after": {background: this.props.backgroundColor ?? "transparent !important"}}} className="timeline-input last-input active">
                                    <span data-year={element.dataSecondaryInfo} data-info={element.dataMainInfo}></span>
                                </div>
                                ) || (
                                <div key={element.key} className="timeline-input active">
                                    <span data-year={element.dataSecondaryInfo} data-info={element.dataMainInfo}></span>
                                </div>
                                )
                            )
                        }

                        if (this.props.lastElementKey === element.key) {
                            return (
                                <div key={element.key} css={{ ":after": {background: this.props.backgroundColor ?? "transparent !important"}}} className="timeline-input last-input">
                                    <span data-year={element.dataSecondaryInfo} data-info={element.dataMainInfo}></span>
                                </div>
                            )
                        }

                        return (
                            <div key={element.key} className="timeline-input">
                                <span data-year={element.dataSecondaryInfo} data-info={element.dataMainInfo}></span>
                            </div>
                        )
                    })}
                </div>
            </div>
        ) || (
            <div></div>
        )
    }
}