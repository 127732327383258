import { combineReducers, Reducer } from 'redux';

//import { routerReducer } from 'react-router-redux';

// Import your state types and reducers here.
import { AuthenticationState } from './authentication/types';
import authenticationReducer from './authentication/reducer';

// The top-level state object
export interface ApplicationState {
  authentication: AuthenticationState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  authentication: authenticationReducer,
});

export default reducers;

// export type RootState = ReturnType<typeof reducers>;

// const mapState = (state: RootState) => ({
//   authentication: state
// });

// const mapDispatch = {
//     loginRequest: () => ({type: 'LOGIN_REQUEST', user: nonConnectedUser()}),
//     loginSuccessful: (oauth: OAuthResponse) => ({type: 'LOGIN_SUCCESSFUL', authResponse: oauth}),
//     loginFailure: () => ({type: 'LOGIN_FAILURE', user: nonConnectedUser()}),
//     logOut: () => ({type: 'LOGOUT', user: nonConnectedUser()}),
// }

// const connector = connect(mapState, mapDispatch);

// export type PropsFromRedux = ConnectedProps<typeof connector>