import React from "react";
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { makeDownloadFromBase64 } from "../../../helpers/date-helper";
import { ReportingInfosResponse } from "../../../model/dto/response";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { userService } from "../../../services/authentication-redux-service";
import { ApplicationState } from "../../../store";
import { SubmitButton } from "../../generic/submit-button";

export function KYCReportingForm(){

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const onSubmitSuccess = (response: ReportingInfosResponse) => {
        makeDownloadFromBase64(response.File!,"KYCReport.csv");
    }

    return (
        <Form>
            <h4>Générer un fichier .CSV contenant des données sur les opérations KYC</h4>
            <SubmitButton
                label="Valider"
                action={()=>userService.GenerateKYCReportCSV(loginProps.oauth)}
                onActionSuccess={onSubmitSuccess}
            />
        </Form>
    )
}