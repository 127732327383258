import React from "react"
import css from "@emotion/core"
import { StatutProjet } from "../../model/entities/statut-projet"
import { format, parseISO } from "date-fns";
import { ProjetListItem } from "../../model/dto/projet-list-item"


interface Props {
    projet: ProjetListItem
    daysUntilEnd? : string
    isDon?: boolean
}

const statutsClotures = [
    StatutProjet.CLOTURE, 
    StatutProjet.DEBOUCLAGE_DEMANDE,
    StatutProjet.DEBOUCLAGE_POSSIBLE,
    StatutProjet.DEBOUCLAGE_VALIDE,
    StatutProjet.DEBOUCLAGE_FINALISE
]

const aVenirBgColor = '#FFF275'
const aVenirTextColor = '#836417'

export function DateFinCard(props: Props) {
    const closeDate = props.isDon ? (props.projet.DateClotureDon && format(parseISO(props.projet.DateClotureDon),"dd/MM/yy")) || undefined :
    (props.projet.DateCloture && format(parseISO(props.projet.DateCloture),"dd/MM/yy")) || undefined

    const financedDate = props.isDon ? (props.projet.DateDebouclageDon && format(parseISO(props.projet.DateDebouclageDon),"dd/MM/yy")) || undefined :
    (props.projet.DateDebouclageValide && format(parseISO(props.projet.DateDebouclageValide),"dd/MM/yy")) || undefined

    const soonDate = props.isDon ? (props.projet.DateDebutCollecteDon && format(parseISO(props.projet.DateDebutCollecteDon),"dd/MM/yy")) || undefined :
    (props.projet.DateDebutFinancementCitoyen && format(parseISO(props.projet.DateDebutFinancementCitoyen),"dd/MM/yy")) || undefined

    return (
        <div css={{height:"100%", fontSize:"1em", display:"flex", justifyContent:"center", alignItems:"center"}}>
                {(statutsClotures.find(status => props.isDon ? status === props.projet.StatutDonProjetCode : status ===  props.projet.StatutProjetCode)) && (<div css={{
                            background: "#C3E6CB",
                            color: "#155724",
                            padding: "5px 10px 5px 10px",
                            borderRadius: "5px",
                            fontWeight: "bold"
                        }}>
                            Clôturé le {closeDate}
                        </div>
                )}
            

                {(props.isDon ? props.projet.StatutDonProjetCode===StatutProjet.FINANCE : props.projet.StatutProjetCode===StatutProjet.FINANCE)  && (<div css={{
                            background: "#C3E6CB",
                            color: "#155724",
                            padding: "5px 10px 5px 10px",
                            borderRadius: "5px",
                            fontWeight: "bold"
                        }}>
                            Financé le {financedDate}
                        </div>
                )}

                {(props.isDon ? props.projet.StatutDonProjetCode === StatutProjet.BIENTOT_DISPONIBLE : props.projet.StatutProjetCode===StatutProjet.BIENTOT_DISPONIBLE) &&(
                        <div css={{
                            background: "#FFCDB2",
                            color: "#A43901",
                            padding: "5px 10px 5px 10px",
                            borderRadius: "5px",
                            fontWeight: "bold"
                        }}>
                            Débute le {soonDate}
                        </div>
                )} 
                {(props.isDon ? props.projet.StatutDonProjetCode === StatutProjet.A_VENIR : props.projet.StatutProjetCode === StatutProjet.A_VENIR) && 
                    <div style={{ 
                        background: aVenirBgColor, 
                        color: aVenirTextColor,
                        padding: "5px 10px 5px 10px",
                        borderRadius: "5px",
                        fontWeight: "bold"
                    }}>
                        Détails à venir
                    </div>
                }
                {(props.isDon ? props.projet.StatutDonProjetCode === StatutProjet.PUBLIE : props.projet.StatutProjetCode===StatutProjet.PUBLIE) && props.daysUntilEnd && (<div css={{
                            background: "#BEE4F2",
                            color: "#16586F",
                            padding: "5px 10px 5px 10px",
                            borderRadius: "5px",
                            fontWeight: "bold"
                        }}>
                            J{props.daysUntilEnd} 
                        </div>
                )} 
                 {(props.isDon ? props.projet.StatutDonProjetCode === StatutProjet.SUSPENDED : props.projet.StatutProjetCode===StatutProjet.SUSPENDED) && (<div css={{
                            background: "#E4E4E4",
                            color: "#5B6269 ",
                            padding: "5px 10px 5px 10px",
                            borderRadius: "5px",
                            fontWeight: "bold"
                        }}>
                            En attente d'informations
                        </div>
                )} 
        </div>
    )
}

