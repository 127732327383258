import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { KYCErrorMessageResponse } from "../../model/dto/response";
import { EtablissementPieceJustificative } from "../../model/entities/etablissement-piece-justificative";
import { StatutPieceJustificative } from "../../model/entities/statut-piece-justificative";
import { TypeCollectivite } from "../../model/entities/type-collectivite";
import { TypePieceByProfil } from "../../model/entities/type-piece-by-profil";
import { TypePieceJustificative } from "../../model/entities/type-piece-justificative";
import { UserPieceJustificative } from "../../model/entities/user-piece-justificative";
import { AuthenticationState } from "../../model/state/authentication-state";
import { referenceService } from "../../services/reference-service";
import { ApplicationState } from "../../store";
import { StatutPieceIcon } from "./statut-piece-icon";

type Props = {
  loginProps?: AuthenticationState;
  typePiece?: TypePieceByProfil;
  pieceInfo: UserPieceJustificative | EtablissementPieceJustificative;
  isReloading?: boolean | undefined;
};

function PieceJustifLine({ loginProps, typePiece, pieceInfo, isReloading }: Props) {
  const { StatutPieceCode } = pieceInfo;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const designationPieceJustif = useMemo(() => {
    const baseString = typePiece?.LibellePieceJustif
    if(typePiece && typePiece.TypePieceCode === TypePieceJustificative.ARTICLES_OF_ASSOCIATION) {
        return baseString?.replace('%type_representant%', loginProps?.oauth?.typeCollectiviteCode === TypeCollectivite.COMMUNE ? 'Maire' : 'Président')
    }
    return baseString
}, [])

  const dateTransmission = useMemo(() => {
    if(pieceInfo.DateTransmission) {
      return format(new Date(pieceInfo.DateTransmission), "dd/MM/yyyy")
    }

    return ""
  }, [pieceInfo])

  useEffect(() => {
    if (
      StatutPieceCode === StatutPieceJustificative.EN_ERREUR &&
      pieceInfo.ErrorMangoPay
    ) {
      referenceService
        .getKycErrorMessageByCode(pieceInfo.ErrorMangoPay, loginProps?.oauth)
        .then((response) => {
          return response.json() as Promise<KYCErrorMessageResponse>;
        })
        .then((response) => {
          if (response.ErrorList && response.ErrorList.length > 0) {
            setErrorMessage(response.ErrorList[0].Message);
          }
        });
    }
  }, []);

  const clickValidateButtonMessage = "Pensez à valider définitivement vos modifications pour que celles-ci soient prises en compte"

  return (
    <li
      css={{
        listStyle: "none",
        marginBottom: "10px",
      }}
    >
      <div
        css={{
          display: "block",
          borderTop: "1px solid rgba(85,99,127,0.2)",
          padding: "10px",
        }}
      >
        <div
          css={{
            display: "table-cell",
            verticalAlign: "middle",
            float: "right",
            paddingTop: "10px",
          }}
        >
          <StatutPieceIcon statutPieceCode={StatutPieceCode} />
        </div>
        <div
          css={{
            display: "table-cell",
            paddingTop: "10px",
            paddingRight: "20px",
          }}
        >
          <Icon name="file alternate outline" size="huge" />
        </div>
        <div
          css={{
            display: "table-cell",
            fontSize: "16px",
            verticalAlign: "top",
            paddingTop: "10px",
          }}
        >
          <h1>{designationPieceJustif}</h1>
          {(pieceInfo.DateTransmission && (
            <p>
              Transmise le {dateTransmission}
            </p>
          )) || <p>Non transmis</p>}
          {StatutPieceCode === StatutPieceJustificative.EN_ERREUR && (
            <p
              css={{
                color: "#F03E3E",
                fontSize: "10px",
              }}
            >
              {errorMessage}
            </p>
          )}
          {StatutPieceCode === StatutPieceJustificative.PERIME && (
            <p
              css={{
                color: "#F03E3E",
                fontSize: "10px",
              }}
            >
              Votre pièce a expiré suite à la mise à jour de vos données, veuillez la transmettre à nouveau
            </p>
          )}
          {StatutPieceCode === StatutPieceJustificative.TRANSMISE && isReloading && (
            <p
              style={{
                color: "#F03E3E",
                fontSize: "10px",
                fontStyle:'italic',
                paddingTop:'8px'

              }}
            >
              {clickValidateButtonMessage}
            </p>
          )}
        </div>
      </div>
    </li>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedPieceJustifLine = connect(
  mapStateToProps,
  null
)(PieceJustifLine);
export { ConnectedPieceJustifLine as PieceJustifLine };
