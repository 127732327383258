import React from "react"
import { SimpleButton } from "../generic/simple-button"
import { documentService } from "../../services/document-service"

type Props = {

}

export function TestPdfGenerator({}: Props) {
    const tryGenerate = () => {
        documentService.askForDocument()
        .then(response => {
            if(response.body) {
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                    // The following function handles each data chunk
                    function push() {
                        // "done" is a Boolean and value a "Uint8Array"
                        reader.read().then(({ done, value }) => {
                        // Is there no more data to read?
                        if (done) {
                            // Tell the browser that we have finished sending data
                            controller.close();
                            return;
                        }
    
                        // Get the data and send it to the browser via the controller
                        controller.enqueue(value);
                        push();
                        });
                    };
    
                    push();
                    }
                });
            }
        })
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            const aEle = document.createElement('a');     // Create a label
            const href = window.URL.createObjectURL(blob);       // Create downloaded link
            aEle.href = href;
            aEle.download = "test.pdf";  // File name after download
            document.body.appendChild(aEle);
            aEle.click();     // Click to download
            document.body.removeChild(aEle); // Download complete remove element
            window.URL.revokeObjectURL(href) 
        })
    }

    return (
        <div>
            <h1>Page de test téléchargement document PDF</h1>
            <SimpleButton 
                label="Test génération"
                onClick={tryGenerate}
            />
        </div>
    )
}