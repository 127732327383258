import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Form, Grid, Popup } from "semantic-ui-react"
import { BankBranch } from "../../model/entities/bank-branch"
import { BankProvider } from "../../model/entities/bank-provider"
import { AuthenticationState } from "../../model/state/authentication-state"
import { referenceService } from "../../services/reference-service"
import { ApplicationState } from "../../store"
import { InputField } from "../generic/input-field"
import { BranchProviderSelectorNew } from "./branch-provider-selector-new"

type Props = {
    montant?: number | string,
    projetId?: number,
    provider?: BankProvider,
    setSelectedBranch?: (branId?: string | undefined) => void,
    handleReturnClick?: () => void,
}

type FormData = {
    branchFilter: FieldData
}

type FieldData = {
    value?: any,
    name: string,
    isValid?: boolean
}

export function SelectBranchPane({ montant, projetId, provider, setSelectedBranch, handleReturnClick}: Props) {
    const [branchProviderList, setBranchProviderList] = useState<BankBranch[]>([])
    const [selectedBranchId, setSelectedBranchId] = useState<string | undefined>(undefined)
    const [form, setForm] = useState<FormData>({
        branchFilter: { name: 'branchFilter', value: undefined }
    })

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(() => {
        if (provider) {
            referenceService.getBranchProviderByProviderId(provider.ProviderId!)
                .then(response => {
                    setBranchProviderList(response.BranchList!)
                })
        }
    }, [provider])

    const onSelectBranch = (branchId: string | undefined) => {
        setSelectedBranchId(branchId)
        setSelectedBranch?.(branchId)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
        const fieldValue: string = e.target.value;
        setForm({ ...form, [fieldName!]: { value: fieldValue, name: fieldName, isValid: isValid } })
    }

    const filteredBranchList = useMemo(() => {
        if (form.branchFilter.value && form.branchFilter.value !== '') {
            const fieldValue = form.branchFilter.value as string
            const filterValue = fieldValue?.toLowerCase()
            const datasource = branchProviderList.map(branch => { return { ...branch, Designation: branch.Designation?.toLowerCase() } })
            const filteredList = datasource.filter(branch => branch.Designation?.includes(filterValue));
            return branchProviderList.filter((branch => filteredList.map(branch => branch.Id).includes(branch.Id)))
        }

        return branchProviderList
    }, [form.branchFilter.value, branchProviderList])

    return (
        <Grid.Row>
            <Grid.Column stretched>
                <Grid.Row>
                    <Grid.Row>
                        <div
                            css={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: "10px"
                            }}
                        >
                            <div
                                css={{
                                    cursor: 'pointer',
                                    marginRight: '5px'
                                }}
                                onClick={handleReturnClick}
                            >
                                <Popup
                                    content="Revenir à la sélection de votre banque"
                                    key="retourBanque"
                                    trigger={
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            width="18" 
                                            height="18" 
                                            viewBox="0 0 24 24"
                                            cursor="pointer"
                                            color="#2c3c54"
                                            onClick={handleReturnClick}
                                        >
                                            <path d="M18.885 3.515c-4.617-4.618-12.056-4.676-16.756-.195l-2.129-2.258v7.938h7.484l-2.066-2.191c2.82-2.706 7.297-2.676 10.073.1 4.341 4.341 1.737 12.291-5.491 12.291v4.8c3.708 0 6.614-1.244 8.885-3.515 4.686-4.686 4.686-12.284 0-16.97z"/>
                                        </svg>
                                    }
                                />
                            </div>
                            <div>
                                <h3>Sélectionnez la branche de votre banque</h3>
                            </div>
                        </div>
                    </Grid.Row>
                    <Grid.Row 
                        css={{
                            marginTop:"20px"
                        }}
                    >
                        <div>
                            <Form 
                                css={{
                                    marginBottom: "1em"
                                }}
                            >
                                <Form.Field width={16}>
                                    <InputField
                                        label="Rechercher votre banque"
                                        placeholder="Commencer à taper pour filtrer"
                                        data={form.branchFilter}
                                        onChange={handleInputChange}
                                    />
                                </Form.Field>
                            </Form>
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                        <div
                            css={{
                                textAlign: "center",
                                maxHeight: "150px",
                                overflowY: "scroll"
                            }}
                        >
                            {filteredBranchList?.map(branchProvider => {
                                return (
                                    <BranchProviderSelectorNew
                                        branch={branchProvider}
                                        selectedBranchId={selectedBranchId}
                                        onSelectBranch={onSelectBranch}
                                    />
                                )
                            })}
                        </div>
                    </Grid.Row>
                </Grid.Row>
            </Grid.Column>
        </Grid.Row>
    )
}