import React from "react";
import { useParams } from "react-router-dom";
import { CollectiviteDetail } from "../components/dashboard-admin/tab/detail-collectivite";

type Params = {
  userId?: string
}

export function CollectiviteDetailRouter() {
    let { userId } = useParams<Params>();
    let id: number = userId ? +userId : 0
    return (
        <CollectiviteDetail userId={id} />
    );
}