import React, { useMemo, useState } from "react"
import Projet from "../../../model/projet"
import { Form } from "semantic-ui-react"
import { InputField } from "../../generic/input-field"
import { SubmitButton } from "../../generic/submit-button"
import { investmentService } from "../../../services/investment-service"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../../store"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { CheckBoxElement, CheckBoxListField } from "../../generic/checkbox-list-field"
import { UnlockFundsBody } from "../../../model/dto/body/unlock-funds-body"
import { FormErrorHeader } from "../../generic/form-error-header"

type Props = {
    projet: Projet,
    loginProps?: AuthenticationState
    onSubmitSuccess: (response: Response) => void
}

type FieldData = {
    value?: any,
    name?: string,
    isValid?: boolean
}

type Form = {
    iban: FieldData
}

export function FinalisationForm({projet, onSubmitSuccess}: Props) {
    const [form, setForm] = useState<Form>({
        iban: {value: '', name: 'iban', isValid: true}
    })

    const [isFormError, setIsFormError] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName, isValid: isValid } };
      
        setForm({ ...form,  ...newField});
        setIsFormError(false)
    }

    const validateMandatory = (value: any): boolean => {
        const regex = /^([0-9]|[A-Z]){27}$/;
        return value && regex.test(value)
    }

    const isFormValid = (): boolean => {
        return validateMandatory(form.iban.value)
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
    }

    const onSubmitError = (): void => {

    }

    const body: UnlockFundsBody = {
        Iban: form.iban.value
    }

    return (
        <Form css={{display: "block"}}>
            <FormErrorHeader 
                message="Veuillez corriger les champs en erreur"
                displayed={isFormError}
            />
            <div>
            Afin de déclencher le débouclage des fonds, merci de renseigner l’IBAN du compte à créditer, puis de cliquer sur le bouton « confirmer » ci-dessous.​
            </div>
            <div css={{marginBottom: "20px"}}>
            Vous serez ensuite redirigé vers un formulaire de confirmation du mandat SEPA nécessaire afin de traiter les futurs remboursements aux citoyens investisseurs.
            </div>
            <Form.Field width={16}>
                <InputField 
                    data={form.iban}
                    label="IBAN du compte à débloquer"
                    submitted={formSubmitted}
                    onChange={handleInputChange}
                    error="Merci de renseigner votre IBAN sans espaces ni caractères spéciaux"
                    validateField={validateMandatory}
                />
            </Form.Field>
            <div css={{textAlign: "center"}}>
                <SubmitButton 
                    data={body}
                    label="Confirmer"
                    onPreSubmit={() => {setFormSubmitted(true)}}
                    action={() => investmentService.unlockFunds(projet.Id!, body, loginProps!.oauth)}
                    validateForm={isFormValid}
                    onFormNotValid={onFormNotValid}
                    onActionSuccess={onSubmitSuccess}
                    onActionFailure={onSubmitError}
                />
            </div>
        </Form>
    )
}
