export interface StatutInvestissement {
    Code?: string,
    Libelle?: string
}

const INITIE: string = "INI";
const EN_COURS: string = "ENC";
const REUSSI: string = "REU";
const EN_ERREUR: string = "ENE";
const REMBOURSEMENT_DEMANDE: string = "RED";
const REMBOURSE: string = "REM";
const EXPIRE: string = "EXP";
const ANNULE: string = "ANN";

export const StatutInvestissement = {
    INITIE,
    EN_COURS,
    REUSSI,
    EN_ERREUR,
    REMBOURSEMENT_DEMANDE,
    REMBOURSE,
    EXPIRE,
    ANNULE
}