export interface TypePieceJustificative {
    Code: string,
    Libelle: string
}

const PIECE_IDENTITE: string = "PID";
const GENERIC: string = "GEN"
const IDENTITY_PROOF: string = "IDP"
const REGISTRATION_PROOF: string = "REG"
const ARTICLES_OF_ASSOCIATION: string = "AOA"

export const TypePieceJustificative = {
    PIECE_IDENTITE,
    GENERIC,
    IDENTITY_PROOF,
    REGISTRATION_PROOF,
    ARTICLES_OF_ASSOCIATION
}