export interface StatutProjet {
    Code?: string,
    Libelle?: string
}

const INCOMPLET: string = "INC";
const COMPLET: string = "COM";
const EN_TRAITEMENT: string = "ETR";
const INFORMATION_MANQUANTE: string = "ICA";
const REFUSE: string = "REF";
const ANNULE: string = "ANN";
const BIENTOT_DISPONIBLE: string = "PAP";
const PUBLIE: string = "PUB";
const CLOTURE: string = "CLO";
const FINANCE: string = "FIN";
const DEBOUCLAGE_POSSIBLE: string = "DBP";
const DEBOUCLAGE_DEMANDE: string = "DBD";
const DEBOUCLAGE_VALIDE: string = "DBV";
const DEBOUCLAGE_FINALISE: string = "DBF";
const SUSPENDED: string = "SUS"
const A_VENIR = "AVE"


export const StatutProjet = {
    INCOMPLET,
    COMPLET,
    EN_TRAITEMENT,
    INFORMATION_MANQUANTE,
    REFUSE,
    ANNULE,
    BIENTOT_DISPONIBLE,
    PUBLIE,
    CLOTURE,
    DEBOUCLAGE_DEMANDE,
    DEBOUCLAGE_VALIDE,
    DEBOUCLAGE_POSSIBLE,
    DEBOUCLAGE_FINALISE,
    FINANCE,
    SUSPENDED,
    A_VENIR
}

