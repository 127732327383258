import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Form, Icon, TextAreaProps } from "semantic-ui-react";
import { CollectivitePostPublique } from "../../../model/entities/collectivite-post-publique";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { projetService } from "../../../services/projet-service";
import { ApplicationState } from "../../../store";
import { FormErrorHeader } from "../../generic/form-error-header";
import { SubmitButton } from "../../generic/submit-button";
import { LexicalInputArea } from "../../generic/lexical-input-area";
import { LexicalEnvContext } from "../../../contexts";

type FieldData = {
    name?: string;
    value?: any;
    isValid?: boolean;
};

type Form = {
    postDescription: FieldData
}

type Props = {
    projectId: number;
    postId?: number;
    data?: CollectivitePostPublique;
    reload: () => void;
    close: () => void;
    onCancel?: () => void
};

export function PostForm({projectId, postId, data, reload, close, onCancel}: Props) {

 const [wysiwygForm, setWysiwygForm] = useState<Form>({
    postDescription: {value: data ? data.DescriptionPost : '', name: 'postDescription', isValid: true},
})

 const [isFormError, setIsFormError] = useState(false)
 const [isFromSubmitted, setIsFormSubmitted] = useState(false)
 const [hasImageChanged, setHasImageChanged] = useState(false)
 const [editorState, setEditorState] = useState(data?.LexicalEditorState)

 const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
 
 const body: CollectivitePostPublique = {
    DescriptionPost: wysiwygForm.postDescription.value,
    LexicalEditorState: editorState
 }

 const validateMandatory = (value: any): boolean => {
    return value && value !== ""
 }

 const validateHTMLInput = (value: String): boolean => {
    return(!(value=="<p><br></p>"))
 } 

 const isFormValid = (): boolean => {
    return validateHTMLInput(wysiwygForm.postDescription.value) && !hasImageChanged
 }

 const onFormNotValid = (): void => {
    setIsFormError(true)
}

const onEditAnnuler = (): void => {
    if(onCancel) {
        onCancel()
    }
    close()
}

const handleInputAreaChange = (props: TextAreaProps, state: string, fieldName?: string, isValid?: boolean): void => {
    const fieldValue = props.value;
    const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
    setWysiwygForm({ ...wysiwygForm,  ...newField});
    setEditorState(state)
    setIsFormError(false)
}

const onPreSubmit = (): void => {
    setIsFormSubmitted(true)
}

const onSubmitError = (): void => {

}

return (
    <Form>
        <FormErrorHeader 
            message="Veuillez corriger les champs en erreur"
            displayed={isFormError}
        />
        <Form.Field width={16}>
            <LexicalEnvContext.Provider value={
                    {
                        uploadUrl: `${process.env.REACT_APP_BASE_URL}/api/${loginProps?.oauth?.userId}/projets/${projectId}/upload-rich-text-media`,
                        readUrl: `${process.env.REACT_APP_BASE_URL}/public/projets/${projectId}/rich-text-media`,
                        bearerToken: loginProps?.oauth?.access_token || ""
                    }}>
                <LexicalInputArea
                    data={wysiwygForm.postDescription}
                    initialEditor={editorState}
                    label="Description de la publication"
                    error="Merci de décrire votre publication"
                    submitted={isFromSubmitted}
                    onChange={handleInputAreaChange}
                    validateField={validateHTMLInput}
                />
            </LexicalEnvContext.Provider>
        </Form.Field>
        <Button css={{float:"right",top:"10px" }}color='red' onClick={onEditAnnuler}>
              <Icon name='remove' /> Annuler
        </Button>
        <SubmitButton 
            label="Publier"
            loaderArea="modal"
            action={()=>projetService.updatePost(projectId, postId ?? data?.Id!, body, loginProps.oauth)}
            data={body}
            validateForm={isFormValid}
            onActionSuccess={()=>{
                reload();
                close();
            }}
            onActionFailure={onSubmitError}
            onFormNotValid={onFormNotValid}
            onPreSubmit={onPreSubmit}
        />
    </Form>      
 )
}