import React  from "react"
import { ProjetListItem } from "../../model/dto/projet-list-item"
import { BankProvider } from "../../model/entities/bank-provider"
import { MontantPaiementPane } from "./montant-paiement-pane"
import { MoyenPaiementPane } from "./moyen-paiement-pane"
import { InvestResponse, UserInfoResponse } from "../../model/dto/response"
import VirementClassiqueSubmitedPane from "./virement-classique-submited-pane/virement-classique-submited-pane"
import { FlowStep } from "../../model/entities/flow-step"

interface Props {
    projet?: ProjetListItem,
    userInfo?: UserInfoResponse
    montant?: number | string,
    montantPercuValue?: string,
    isContratCheckedValue?: boolean,
    activeStepKey: string,
    selectedProvider?: BankProvider,
    numeroReponseTestList?: number[],
    score?: number,
    errorMessage?: string,
    isErrorDisplayed?: boolean,
    virementResponse?: InvestResponse
    isResumeVirement?:boolean
    showAttentePaiement: boolean;
    montantDisponibleVillyz?: number;
    userSumPrevisionnelForProject?: number;
    projectSumPrevisionnel?: number;
    hasUserInvestimentsByVirementEnCours: boolean;
    delaiReservationVirement?: string;
    isDon?: boolean;
    onValidateMontant?: (montant: number, montantPercu: string, isContratChecked: boolean, isNextStep?: boolean) => void
    onSelectBranch?: (provider: BankProvider) => void,
    onSubmitImpossible?: (message: string) => void,
    onClickAttentePaiement: () => void;
    onVirementSuccess: (response: InvestResponse) => void;
    disabledAttentePaiement: () => void;
}

export function InvestmentPaneSelection({
    projet, 
    userInfo,
    montant, 
    montantPercuValue,
    isContratCheckedValue,
    activeStepKey, 
    numeroReponseTestList, 
    score, 
    errorMessage,
    isErrorDisplayed,
    virementResponse,
    isResumeVirement,
    showAttentePaiement,
    montantDisponibleVillyz,
    userSumPrevisionnelForProject,
    projectSumPrevisionnel,
    hasUserInvestimentsByVirementEnCours,
    delaiReservationVirement,
    isDon,
    onValidateMontant, 
    onSelectBranch,
    onSubmitImpossible,
    onClickAttentePaiement,
    onVirementSuccess,
    disabledAttentePaiement
}: Props) {

    const onVirementClassiqueResponseClose = () => window.location.href = virementResponse?.RedirectURL!
         
    return(
        <>
            {activeStepKey === FlowStep.MONTANT && (
                <MontantPaiementPane
                    projet={projet}
                    montant={montant}
                    montantPercuValue={montantPercuValue}
                    isContratCheckedValue={isContratCheckedValue}
                    numeroReponseTestList={numeroReponseTestList}
                    score={score}
                    onValidateMontant={onValidateMontant}
                    montantDisponibleVillyz={montantDisponibleVillyz}
                    userSumPrevisionnelForProject={userSumPrevisionnelForProject}
                    projectSumPrevisionnel={projectSumPrevisionnel}
                    isDon={isDon}
                />
            )}
            {activeStepKey === FlowStep.PAIEMENT && (
                isResumeVirement ? 
                <VirementClassiqueSubmitedPane
                    iban={virementResponse?.Iban}
                    onClose={onVirementClassiqueResponseClose}
                    montantInitial={montant}
                    montantVirement={virementResponse?.MontantVirement}
                    bic={virementResponse?.Bic} 
                    delaiReservationVirement={delaiReservationVirement}
                    montantDisponibleVillyz={montantDisponibleVillyz}
                    isDon={isDon}
                />
                :
                <MoyenPaiementPane
                    montant={montant}
                    projetId={projet?.Id}
                    errorMessage={errorMessage}
                    isErrorDisplayed={isErrorDisplayed}
                    onSelectBranch={onSelectBranch}
                    onSubmitImpossible={onSubmitImpossible}
                    userInfo={userInfo}
                    onClickAttentePaiement={onClickAttentePaiement}
                    showAttentePaiement={showAttentePaiement}
                    montantDisponibleVillyz={montantDisponibleVillyz}
                    onVirementSuccess={onVirementSuccess}
                    disabledAttentePaiement={disabledAttentePaiement}
                    hasUserInvestimentsByVirementEnCours={hasUserInvestimentsByVirementEnCours}
                    isDon={isDon}
                />
            )}
        </>
    )
}