import React, { useState } from "react";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";
import { LexicalEnvContext } from "../../../contexts";
import { ProjetDescriptionBody } from "../../../model/dto/body/projet-description-body";
import Projet from "../../../model/projet";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { projetService } from "../../../services/projet-service";
import { ApplicationState } from "../../../store";
import { FormErrorHeader } from "../../generic/form-error-header";
import { LexicalInputArea } from "../../generic/lexical-input-area";
import { SimpleButton } from "../../generic/simple-button";
import { SubmitButton } from "../../generic/submit-button";
import { WorkflowType } from "../../../model/entities/workflow-type";
import { WorkflowProcessResultResponse } from "../../../model/dto/response";
import { FlowStep } from "../../../model/entities/flow-step";
import { referenceService } from "../../../services/reference-service";

type FieldData = {
  name?: string;
  value?: any;
  isValid?: boolean;
};

type Props = {
  loginProps?: AuthenticationState;
  projet?: Projet;
  isCreating?: boolean;
  intialEditorState?: string;
  workflowTypeCode?: string;
  onSubmitSuccess?: (response: Response) => void;
  sendEditorState?: (state: string) => void;
  onSkipStep?: (response: WorkflowProcessResultResponse) => void;
};

type Form = {
    descriptionLong: FieldData
}

function DescriptionProjetForm({loginProps, projet, isCreating, intialEditorState: initialEditorState, workflowTypeCode, onSubmitSuccess, sendEditorState, onSkipStep}: Props) {
    const [form, setForm] = useState<Form>({
        descriptionLong: {value: projet ? projet.DescriptionLong : '', name: 'descriptionLong', isValid: true},
    })

    const [isFormError, setIsFormError] = useState(false)

    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const [editorState, setEditorState] = useState(initialEditorState)

    const validateMandatory = (value: any): boolean => {
        return value && value !== ""
    }

    const validateHTMLInput = (value: String): boolean => {
        return(!(value=="<p><br></p>"))
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
      
        setForm({ ...form,  ...newField});
        setIsFormError(false)
    }

    const handleInputAreaChange = (props: any, state: string, fieldName?: string, isValid?: boolean): void => {
        const fieldValue = props.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
      
        setForm({ ...form,  ...newField});
        setEditorState(state)
        setIsFormError(false)
    }

    const isFormValid = (): boolean => {
        return validateHTMLInput(form.descriptionLong.value)
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
    }

    const byteSize = (str: string) => new Blob([str]).size;

    const onPreSubmit = (): void => {
        setIsFormSubmitted(true)
        if(sendEditorState) {
            sendEditorState(editorState ?? "")
        }
    }

    const onSubmitError = (): void => {

    }

    const body: ProjetDescriptionBody = {
        DescriptionLong: form.descriptionLong.value,
        LexicalEditorState: editorState,
         WorkflowTypeCode: workflowTypeCode
    }

    const nextDescriptionStepKey = () => {
        const body = {
            WorkflowTypeCode : workflowTypeCode ?? '',
            CurrentFlowStep : FlowStep.DESCRIPTION,
            RessourceId:  projet?.Id!,
            IsSkiped: true
        }
        return referenceService.getNextStep(body).then(response => {
            onSkipStep && onSkipStep(response)})
    }

    return (
        <Form>
            <FormErrorHeader 
                message="Veuillez corriger les champs en erreur"
                displayed={isFormError}
            />
            <Form.Field width={16}>
                <LexicalEnvContext.Provider value={
                    {
                        uploadUrl: `${process.env.REACT_APP_BASE_URL}/api/${loginProps?.oauth?.userId}/projets/${projet?.Id}/upload-rich-text-media`,
                        readUrl: `${process.env.REACT_APP_BASE_URL}/public/projets/${projet?.Id}/rich-text-media`,
                        bearerToken: loginProps?.oauth?.access_token || ""
                    }}>
                    <LexicalInputArea
                        data={form.descriptionLong}
                        initialEditor={initialEditorState}
                        label="Description détaillée du projet et de la collectivité"
                        error="Merci de décrire votre projet"
                        submitted={isFormSubmitted}
                        onChange={handleInputAreaChange}
                        validateField={validateHTMLInput}
                    />
                </LexicalEnvContext.Provider>
            </Form.Field>
            <div css={{
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }} 
                className="button-bar">
                <SubmitButton 
                    data={body}
                    label="Valider"
                    action={() => projetService.saveDescriptionProjet(body, projet?.Id, loginProps?.oauth)}
                    onActionSuccess={onSubmitSuccess}
                    onActionFailure={onSubmitError}
                    onFormNotValid={onFormNotValid}
                    validateForm={isFormValid}
                    onPreSubmit={onPreSubmit}
                />
                {isCreating && (
                    <SimpleButton 
                        label="Passer cette étape"
                        onClick={() => nextDescriptionStepKey()}
                    />
                )}
            </div>
        </Form>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedDescriptionProjetForm = connect(
  mapStateToProps,
  null
)(DescriptionProjetForm);
export { ConnectedDescriptionProjetForm as DescriptionProjetForm };
