import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {Button, Container, Grid, Header, Image, Icon, Card, Loader} from "semantic-ui-react";
import { CustomUnderlinedText } from "../../generic/custom-underlined-text";
import { ProjetListItem } from "../../../model/dto/projet-list-item";
import { publicService } from "../../../services/public-service";
import { ProjetLine } from "../../projet/projet-line";
import { css } from "@emotion/core";
import { section } from "../styles";
import { ProfilCode, ProjetsCardResponse } from "../../../model/dto/response";
import './collectivites-v2.css'
import { StatutProjet } from "../../../model/entities/statut-projet";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";

type Props = {}

export function CollectivitesV2({}: Props) {

  const [projetItem, setProjetItem] = useState<ProjetListItem>();
  const [projetsCard, setProjetsCard] = useState<ProjetListItem[]>();
  const navigate = useNavigate();
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  useEffect(() => {
    publicService.getProjetItemForPorteurProjet()
      .then((response: ProjetsCardResponse) => {
        setProjetItem(response.ProjetItem!)
        setProjetsCard(response.ProjetList!)
      });
  },[]);

  const onClick = (projetId?: number): void => {
    navigate(`/projets/${projetId}`);
  };

  const blueSection = {
    padding: "26px",
    maxWidth: "90%",
    margin: '0 auto',
    backgroundColor:'#005877',
    borderRadius: "4px",
    color: "#ffffff"
  };

  const demoButton = css ({
    fontWeight: 'bold',
    fontSize:'20px !important',
    padding: '10px 20px !important',
    backgroundColor: '#13B9A1 !important',
    fontFamily:'raleway-bold !important'
  });

  const title = css ({
    fontWeight:'bold', 
    fontSize: '18px', 
    color: '#000000',
    marginBottom: '18px',
    fontFamily:"raleway"
  });

  const description = css ({
    fontSize: "16px",
    color: '#000000'
  });

  const breakpoints = [576, 768, 992, 1200]
  const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`)
  const minMq= breakpoints.map(bp => `@media (min-width: ${bp}px)`)

  const quoteMarkStyle = css ({
    position: "absolute",
    top: "6px",
    left: "-26px",
    fontSize: "200px",
    color: "#ffffff",
    opacity : '0.4',
    [mq[0]]: {
      top: "-20px",
    }
  });

  const underlinedHeaderConatiner = {
    display: "flex", 
    justifyContent:"center",
    marginBottom: "15px",
    [mq[0]]: {
      marginBottom: "14px",
    }
  };

  const subTitle = css ({
    fontSize: "22px",
    color: '#000000',
    fontFamily:'raleway'
  });

  const sectionV2 = css({
    padding: "35px 0",
    marginTop:"30px"
  })
  
  const sectionConfiance = css({
    padding: "35px 0",
    marginTop:"40px",
    marginBottom:"50px"
  })

  const commentParagrah= css({
    fontSize: "16px", 
    position: "relative",
    [minMq[3]]: {
      paddingRight: "55px",
      paddingTop:"5px"
    }

  })

  const isCardClickable = (projet: ProjetListItem) => {
    if(projet.StatutProjetCode === StatutProjet.A_VENIR ){
        if(loginProps?.user?.ProfilCode === ProfilCode.PORTEUR_PROJET && projet.EtablissementId === loginProps?.user?.Etablissement?.Id){
            return true
        }
        else if(loginProps?.oauth?.profilCode === ProfilCode.VILLYZ){
            return true
        }
        return false
    }
    return true
}

  return (
    <div id="page-collectivites"  css={{color: '#424949', marginTop: '32px', fontFamily:"Raleway, sans-serif"}}>
      <Container>
        <Image 
          src="/img/colloc/Colloc_asset_1.png"
          css={{borderRadius: '10px', marginBottom:'20px'}}
          alt="illustration d'une place animée d'un centre ville rural"
        />
      </Container>

      {/*======================== HERO SECTION =======================*/}
      <div css={{paddingBottom: '40px'}}>
        <Container>
          <Grid doubling stackable>
            <Grid.Row>
              <Grid.Column width={8}>
                <h1 css={{
                  fontWeight:'bold',
                  fontSize: '38px',
                  lineHeight: '1',
                  marginBottom: '16px',
                  color: '#000000',
                  fontFamily:"Merriweather"
                }}> 
                  Engagez vos citoyens
                </h1>
                <Button
                  color="teal"
                  href="https://qekp7gberg2.typeform.com/to/ABjAqXGU"
                  target= "_blank"
                  css={demoButton}
                >
                  Nous contacter
                </Button>
              </Grid.Column>
              <Grid.Column width={8}>
                <p css={{fontSize: '20px', color: '#000000'}}>Impliquez les habitants dans le financement des projets d’investissement de leur collectivité.</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>        

      {/*======================== AVANTAGE SECTION =====================*/}
      <div css={sectionV2}>
        <Container >
          <Grid stackable={true}>
              <Grid.Row>
                  <Grid.Column
                      mobile={16}
                      tablet={4}
                      computer={3} 
                      className="avantage-section-illustration-column"                        
                  >
                    <div className="avantage-section-illustration-container-left">
                        <Image
                                 src="img/porteur-de-projet/Porteur_asset1.png"                                   
                                 alt="logo illustrant le titre Rendez les citoyens acteurs du financement de vos projets"                
                        />    
                    </div>                        
                  </Grid.Column>

                  <Grid.Column
                      mobile={12}
                      tablet={12}
                      computer={13} 
                      floated="right"
                      verticalAlign="middle"
                  >
                      <h2 css={{fontWeight:'bold', fontSize: '20px', color: '#000000', fontFamily:"raleway"}}>Rendez les citoyens acteurs du financement de vos projets</h2>
                      <p  css={{fontSize: '16px', color: '#000000'}}>Parce qu’œuvrer pour les habitants de votre territoire c’est également pouvoir compter sur eux. Nous avons développé l’emprunt citoyen pour donner à chacune et chacun les moyens de soutenir la politique locale et de participer à la vie démocratique de leur territoire.</p>
                  </Grid.Column>
              </Grid.Row>

              {/* inversement de l'ordre en version mobile */}                     
              <Grid.Row className="reversed-mobile-section">
                      <Grid.Column 
                      mobile={12}
                      tablet={12}
                      computer={13} 
                      verticalAlign="middle"
                      >
                          <h2 css={{fontWeight:'bold', fontSize: '20px', color: '#000000', fontFamily:"raleway"}}>Faites de vos projets d’investissement un marqueur démocratique</h2>
                          <p  css={{fontSize: '16px', color: '#000000', paddingRight:"2px"}}>Tous les citoyens n’ont pas la possibilité de participer aux conseils de quartier ou à leur nouveau budget participatif. Mais tous sont intéressés par l’impact des projet de leur collectivité sur leur vie de tous les jours. Leur donner la possibilité de s’engager via l’épargne c’est leur permettre d’être acteur de leur vie démocratique.</p>
                      </Grid.Column>
                      
                      <Grid.Column
                      mobile={16}
                      tablet={4}
                      computer={3}
                      floated="right"
                      className="avantage-section-illustration-column"
                      >
                        <div className="avantage-section-illustration-container-right">
                          <Image
                                  src="img/porteur-de-projet/Porteur_asset2.png"
                                  alt="logo illustrant un bulletin de vote qui va être inséré dans une urne"                             
                              />    
                        </div>
                      </Grid.Column>                              
              </Grid.Row>

              <Grid.Row>
                  <Grid.Column       
                    mobile={16}
                    tablet={4}
                    computer={3}
                    className="avantage-section-illustration-column"
                  >
                    <div className="avantage-section-illustration-container-left">
                      <Image
                              src="img/porteur-de-projet/Porteur_asset3.png"                                  
                              alt="logo d'un grand soleil levant derrière une ville et ses édifices"                       
                      />    
                    </div>
                  </Grid.Column>

                  <Grid.Column
                      mobile={12}
                      tablet={12}
                      computer={13} 
                      floated="right"
                      verticalAlign="middle"
                  >
                      <h2 css={{fontWeight:'bold', fontSize: '20px', color: '#000000', fontFamily:"raleway"}}>Valorisez votre action publique grâce à l’emprunt citoyen</h2>
                      <p  css={{fontSize: '16px', color: '#000000'}}>La vision des territoires est parfois ancrée dans une image d’Epinal. Or, c’est tout l’inverse et les élus se battent pour le faire savoir ! Nous avons à coeur de vous accompagner pour diffuser les projets par-delà votre collectivité. Pour que l’emprunt citoyen est une source de fierté locale autant que de rayonnement national. </p>
                  </Grid.Column>
              </Grid.Row>
          </Grid>
        </Container> 
      </div>  

      {/*======================== CONFIANCE SECTION =====================*/}
      <div css={sectionConfiance}>
        <Container>
          <Grid stackable>
          <Grid.Row centered>
            <CustomUnderlinedText 
                  startText="Ils nous"
                  underlinedText="ont fait confiance"
                  fontSize={20}
                  offset={6}
                  textColor="#000000"
                />
            </Grid.Row>
            <Grid.Row columns={5}>
              <Grid.Column ><Image centered src="./img/logos/Logo Rosoy2.png" alt="logo de la ville de Rosnay"/></Grid.Column>
              <Grid.Column ><Image centered src="./img/logos/Logo_glanges.png" alt="logo de la ville de Glanges"/></Grid.Column>
              <Grid.Column ><Image centered src="./img/logos/Logo_sans_fond_Brest.png" alt="logo de la ville de Brest"/></Grid.Column>
              <Grid.Column ><Image centered src="./img/logos/Logo_sereilhac.png" alt="logo de la ville de Sereilhac"/></Grid.Column>
              <Grid.Column ><Image centered src="./img/logos/Logo5.png" alt="logo de la ville d'Etigny"/></Grid.Column>
          </Grid.Row>
          </Grid>
      </Container>
      </div>  
      {/*========================  Témoignage SECTION =====================*/}
      <div css={sectionV2}>
        <Container>
          <div css={blueSection}>
            <Grid doubling stackable>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Image
                    src="/img/porteur-de-projet/emilie.png"
                    css={{width: "100%", maxWidth : "175px !important", borderRadius: '50%'}}
                    alt="photo de Emilie Gillet"
                  />
                </Grid.Column>
                <Grid.Column width={13}>
                  <p css={{fontSize: "20px", fontWeight: "bold"}}>Emilie Gillet, Maire de Glanges</p>
                  <p css={commentParagrah}>
                    <span css={quoteMarkStyle}>&ldquo;</span>
                    Nous souhaitions pouvoir impliquer les habitants dans la vie et la dynamique de la commune. Quoi de mieux que de leur permettre
                    d’investir dans un projet de territoire comme celui de notre Pôle Santé. Villyz a rendu cela possible et ce fut une grande réussite puisque 114 citoyens ont investi au total 65.000€ !
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Container>
      </div>

      {/*======================== Steps SECTION =====================*/}   
      <div css={sectionV2}>
        <Container>
          <div style={{ display:"flex", justifyContent:"center"}}>     
          <div style={{maxWidth:"90%"}}>
            <div css={underlinedHeaderConatiner}>
              <CustomUnderlinedText 
                startText="Valorisez"
                underlinedText="votre action territoriale"
                fontSize={20}
                offset={6}
                textColor="#000000"
              />
            </div>
            <Grid doubling stackable>
              <Grid.Row columns={3} equals css={{padding: "18px !important"}}>
                <Grid.Column >
                  <Image src="/img/porteur-de-projet/vector-1.svg" alt="logo représentant le chiffre 1 "/>
                  <h3 css={subTitle}>Choisissez un projet</h3>
                  <div css={description}>
                    <div css={{display:"flex", alignItems:"top", marginBottom:"16px" }}>
                      <Icon 
                          name='check circle' 
                          size='small' 
                          style={{color:"#005877", margin:"5px 7px 0 0"}}
                        />
                        <p>Nous vous accompagnons pour choisir le bon projet</p>
                    </div>
                    <div css={{display:"flex", alignItems:"top" }}>
                      <Icon 
                          name='check circle' 
                          size='small' 
                          style={{color:"#005877", margin:"5px 7px 0 0"}}
                        />
                        <p>Intégrez l'emprunt citoyen dans votre plan de financement</p>
                    </div>                  
                  </div>
                </Grid.Column>
                <Grid.Column >
                  <Image src="/img/porteur-de-projet/vector-2.svg" alt="logo représentant le chiffre 2"/>
                  <h3 css={subTitle}>Préparez votre campagne</h3>
                  <div css={description}>
                    <div css={{display:"flex", alignItems:"top", marginBottom:"16px" }}>
                      <Icon 
                          name='check circle' 
                          size='small' 
                          style={{color:"#005877", margin:"5px 7px 0 0"}}
                        />
                        <p>Estimez le potentiel de financement local de votre territoire</p>
                    </div>
                    <div css={{display:"flex", alignItems:"top" }}>
                      <Icon 
                          name='check circle' 
                          size='small' 
                          style={{color:"#005877", margin:"5px 7px 0 0"}}
                        />
                        <p>Préparez votre plan de communication avec nos experts</p>
                    </div>              
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <Image src="/img/porteur-de-projet/vector-3.svg" alt="logo représentant le chiffre 3 "/>
                  <h3 css={subTitle}>Financez et rayonnez</h3>
                  <div css={description}>
                  <div css={{display:"flex", alignItems:"top", marginBottom:"16px" }}>
                      <Icon 
                          name='check circle' 
                          size='small' 
                          style={{color:"#13BA91", margin:"5px 7px 0 0"}}
                        />
                        <p>Créez un engagement durable via l'emprunt citoyen</p>
                    </div>
                    <div css={{display:"flex", alignItems:"top" }}>
                      <Icon 
                          name='check circle' 
                          size='small' 
                          style={{color:"#13BA91", margin:"5px 7px 0 0"}}
                        />
                        <p>Nos experts pilotent les paiements et la relation investisseur</p>
                    </div>                   
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{display : "flex", justifyContent: 'flex-end', paddingRight:"18px"}}> 
                <Button
                  href="https://qekp7gberg2.typeform.com/to/ABjAqXGU"
                  target= "_blank"
                  color="teal"
                  css={demoButton}
                >
                  Nous contacter
                </Button>
              </Grid.Row>
            </Grid>
            </div>
          </div>
        </Container>
      </div>

      {/*========================  Social Proof SECTION =====================*/}
      <div css={sectionV2}>
        <Container>
          <div css={blueSection}>
            <Grid doubling stackable centered>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Image
                    src="/img/colloc/PFP.png"
                    css={{
                      width: "110px", height: "110px", maxWidth : "175px !important"
                    }}
                    alt="logo officiel de plate-forme de financement participatif agrée par l'état "
                  />
                </Grid.Column>

                <Grid.Column width={13}>
                  <p css={{fontSize: "20px", fontWeight: "bold"}}>Une plateforme agréée</p>
                  <p css={{fontSize: "18px"}}>Villyz est la <strong>première plateforme française</strong> agréée en tant que Prestataire de Service de Financement Participatif (PSFP) par l’<strong>Autorité des Marchés Financiers </strong>(n° FP-20221).</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Container>
      </div>

      {/*========================  SECTION =====================*/}
      <div css={sectionV2}>
        <Container>
          <Grid stackable doubling>
            <Grid.Row className="reversed-mobile-section">
              <Grid.Column verticalAlign="middle" className="text-left" width={9}>
                <div>
                  <p css={{color: "#000000", fontSize: "16px"}}><strong>L’emprunt citoyen est au service de tout le sercteur public.</strong> Les centres hospitaliers, entreprises publiques locales, syndicats d'énergie et sociétés de projet ont aussi l’opportunité de réaliser un emprunt citoyen pour leurs projets.</p>
                </div>
              </Grid.Column>

          
              <Grid.Column width={6} floated="right">
                <div css={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                  <Image
                    rounded
                    centered
                    src="img/porteur-de-projet/pic-7.png"
                    css={{objectFit: "cover"}}
                    alt="illustration d'un centre hospitalier"
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row css={{marginTop: "30px"}}>
              <Grid.Column width={6}>
                <Image
                  rounded
                  src="img/home/Home_asset_6.png"
                  css={{objectFit: "cover"}}
                  alt="illustration d'une vallée avec des éoliennes dans le paysage"
                  centered
                
                />
              </Grid.Column>
              <Grid.Column verticalAlign="middle" width={9} floated="right">
                <div>
                  <p css={{color: "#000000", fontSize: "16px"}}>
                  <strong>Valorisez vote action pour la transition écologique.</strong> Distinguez vos projets grâce au label d'excellence du ministère de la transition écologique
                  et impliquez vos habitants pour des territoires plus résilients.
                  </p>
                </div>  
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
                {/*======================== PROJECT SECTION =====================*/}   
      <div css={sectionV2}>
        <Container>
          <div css={underlinedHeaderConatiner}>
            <CustomUnderlinedText 
              startText="Vos"
              underlinedText="dernières réussites"
              fontSize={20}
              offset={6}
              textColor="#000000"
            />
          </div>
          <Grid doubling stackable>
            <Grid.Column width={6}>
              {projetItem? (
                <>
                  <Card.Group itemsPerRow={1} doubling stackable>
                    <ProjetLine
                      key={projetItem.Id}
                      projet={projetItem}
                      isCardClickable={isCardClickable(projetItem)}
                    />
                  </Card.Group>
                </>
              ) : (
                <Loader active inline="centered" />
              )}
            </Grid.Column>
            <Grid.Column width={9}>
              {projetsCard? (
                projetsCard.length > 0 &&
                  <div >
                    {projetsCard.map((projetsCard) => (
                      <div css={{[mq[0]]: {padding: '0 14px'}}}>
                        <Card doubling stackable {...{onClick: () => onClick(projetsCard.Id)}} css={{width: '100% !important', marginBottom: '35px !important'}}>
                          <Card.Content>
                            <div css={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                              <div css={{ flexBasis: "18%"}}>
                                {projetsCard.IsLogoUploaded ?
                                  <div css={{flexBasis: "15%"}}>
                                    <img 
                                      src={`${process.env.REACT_APP_BASE_URL}/public/get-logo/${projetsCard.EtablissementId!}`}
                                      css={{ maxWidth: "100%", maxHeight: "100%"}}
                                      alt={`logo de ${projetsCard.Titre}`} 
                                    /> 
                                  </div>
                                  :
                                  <div css={{flexBasis: "15%"}}>
                                    <img 
                                      src="img/porteur-de-projet/picto.png"
                                      css={{ maxWidth: "100%", maxHeight: "100%"}} 
                                      alt="un pictogramme par défaut" 
                                    /> 
                                  </div>
                                }
                              </div>
                              <div>
                                <Card.Header css={title}>{projetsCard.Titre}</Card.Header>
                                <Card.Description>
                                  <p css={{color: "#000"}}>{projetsCard.CollectiviteNom}</p>
                                  <p css={{color: "#000"}}>{(projetsCard.MontantCollecte ? projetsCard.MontantCollecte / 100 : 0) + " € collectés"}</p>                             
                                </Card.Description>
                            </div>
                          </div>
                          </Card.Content>
                        </Card>
                      </div>
                      ))
                    }
                  </div>   
                  ) : (
                    <Loader active inline="centered" />
                )}
            </Grid.Column>
          </Grid>
        </Container>
      </div>

      {/*========================  Call to Action Section =====================*/}
      <div css={{padding: "35px 0", marginTop:"35px", marginBottom:"40px"}}>
        <Container>
          <Grid doubling stackable centered>
            <Grid.Row>
              <div css={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: '90%',
                color: '#000000'
              }}>
                <div 
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    backgroundColor: "#E0E8EB",
                    borderRadius: "4px 0px 0px 4px",
                    padding: "30px",
                    textAlign: 'left',
                    width:'50%',
                    [mq[2]]: {
                      borderRadius: "4px 4px 0px 0px",
                      width: '100%'
                    }
                  }}
                >
                  <Header
                    as="h2"
                    css={{
                      fontWeight:'bold',
                      fontSize: '20px',
                      fontFamily:"merriweather !important"
                    }}
                  >
                    Envie d’engager vos citoyens ?
                  </Header>
                  <p css={{fontSize: "18px"}}>Rencontrez un expert pour parler de vos projets.</p>
                  <div 
                    css={{
                      display:'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    <Button
                      href="https://qekp7gberg2.typeform.com/to/ABjAqXGU"
                      target= "_blank"
                      color="blue"
                      css={{
                        fontSize:'17px !important',
                        marginRight: '30px !important',
                        fontFamily:"raleway-bold !important",
                        [mq[2]]: {
                          marginBottom: '14px !important'
                        }
                      }}

                    >
                      Parler à un expert
                    </Button>
                    <Button
                      as={NavLink}
                      to="/projets"
                      css={{
                        fontSize:'17px !important',
                        fontWeight: 'bold',
                        color: '#005877 !important',
                        backgroundColor: 'transparent !important',
                        border:"2px solid #005877 !important",
                        fontFamily:"raleway-bold !important",
                      }}>
                        Découvrir les projets
                    </Button>
                  </div>
                </div>
                <div 
                  css={{
                    backgroundColor: "#005877",
                    color: "#ffffff",
                    borderRadius: "0px 4px 4px 0px",
                    padding: "30px",
                    width:'50%',
                    textAlign: "left", 
                    fontSize: "18px",
                    [mq[2]]: {
                      borderRadius: "0px 0px 4px 4px",
                      width: '100%'
                    }
                  }}
                >
                  <div css={{display:"flex", alignItems: "baseline", marginBottom: "12px"}}>
                    <Icon 
                      name='check circle' 
                      size='small' 
                      css={{color:"#fff"}}
                    />
                    <p css={{marginLeft: "9px"}}>Soyez accompagné par une équipe d’experts de l’emprunt citoyen</p>
                  </div>
                  <div css={{display:"flex", alignItems: "baseline", marginBottom: "12px"}}>
                    <Icon 
                      name='check circle' 
                      size='small' 
                      css={{color:"#fff"}}
                    />
                    <p css={{marginLeft: "9px"}}>Découvrez votre potentiel d’emprunt citoyen et atteignez vos objectifs</p>
                  </div>
                  <div css={{display:"flex", alignItems: "baseline"}}>
                    <Icon 
                      name='check circle' 
                      size='small' 
                      css={{color:"#fff"}}
                    />
                    <p css={{marginLeft: "9px"}}>Valorisez vos projets en engageant les citoyens de votre collectivité, et bien au-delà</p>
                  </div>
                </div>
              </div>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </div>
  )
}