import { css } from "@emotion/core";
import React from "react";
import { Container, Grid, Header, Image, Table } from "semantic-ui-react";
import { HeaderLined } from "../generic/header-lined";
import { ReactComponent as LinkedInLogo } from "./home/linkedin.svg";
import { header, section, sectionHeader, sectionPrimary } from "./styles";

export function Risques() {
  return (
    <div>
      <div css={section}>
        <Container>
          <HeaderLined 
            title="Politique de risques"
          />
          <Header as="h3" color="blue">
            Comment nos projets sont-ils sélectionnés ? ​
          </Header>
          <p className="text-lg">
            Chaque projet est présenté au comité de sélection de Villyz qui
            valide ou non sa mise en ligne.​
          </p>
          <p className="text-lg">
            Cette validation repose sur deux étapes :
            <ul>
              <li>L’analyse du type de projet​</li>
              <li>L’analyse de la structure financière du porteur de projet​ et l’impact du projet sur cette dernière​</li>
            </ul>
          </p>
          <Header as="h3" color="blue">
            Analyse du type de projet​ ​
          </Header>
          <p className="text-lg">
            Le comité de sélection s’assure que le projet proposé a bien été validé par l’organe de direction du porteur 
            de projet et qu’il est éligible à un financement citoyen.
          </p>
          <p className="text-lg">
            De plus, le montant à financer auprès des citoyens ne doit pas être supérieur à 5 million d’euros par an et par porteur de projet.​
          </p>

          <Header as="h3" color="blue">
            Analyse de la structure financière
          </Header>
          <p className="text-lg">
            Le comité de sélection vérifie ensuite que le porteur de projet est en mesure 
            de supporter financièrement ce projet. Pour cela, l’analyse financière est 
            conduite grâce à des données publiques complétées par les documents fournis 
            par les porteurs de projet (ex : derniers bilans comptables).
          </p>
          <p className="text-lg">
            Ainsi, le comité analyse l'évolution de plusieurs indicateurs financiers, 
            notamment ceux présentés ci-dessous afin de produire une note d’analyse et 
            de valider le projet. 
          </p>
          <p className="text-lg">
            Dans le cas d’un projet porté par une collectivité : 
            <ul>
              <li>La capacité de désendettement</li>
              <li>La marge d’autofinancement courante</li>
              <li>Le coefficient de rigidité structurelle</li>
              <li>Le taux d’épargne brute</li>
              <li>L'encours total de dette</li>
            </ul>
            Dans le cas d’un projet porté par une société :
            <ul>
              <li>Le fonds de roulement net à court terme</li>
              <li>La part des réserves dans l’actif</li>
              <li>Le rapport entre les bénéfices avant intérêts et impôt et l’actif</li>
              <li>Le ratio de levier</li>
              <li>Le taux de rotation des actifs</li>
            </ul>
          </p>
        </Container>
      </div>
    </div>
  );
}
