import React from "react"

interface FieldProps {
    fieldName: string,
    label: string,
    value: any,
    className: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  }
    
  export class Field extends React.Component<FieldProps, {}> {
    render() {
      return (
        <div className={this.props.className}>
            <label htmlFor={this.props.fieldName}>{this.props.label}</label>
            <input id={this.props.fieldName} type={this.props.fieldName} className="form-control" name={this.props.fieldName} value={this.props.value} onChange={this.props.onChange}></input>
        </div>
      )
    }
  }