import React from "react";
import { Container, Header, Icon } from "semantic-ui-react";
import { section } from "./styles";

export function Stats() {
  
  return (
    <div css={[section]}>
      <Container 
        justified 
      >
        <Header as="h1" color="blue" css={{lineHeight:"10px !important"}}>Statistiques</Header>
        <p className="text-lg">Publications règlementaires</p>
        <div css={{margin: "30px 0"}}>
          <Header as="h3">Rapports d’activité</Header>
          <div css={{marginLeft:"30px",marginBottom:"15px"}}>
            <a href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/rapportActivite2023.pdf"
              target="_blank"
              rel="noreferrer"
              >
                <Icon css={{color:"#A6A6A6"}} size='big' name='file alternate outline'/> 
                <span css={{fontSize :"1,125 rem",fontFamily : "Quicksand, sans-serif",color :"#A6A6A6",fontWeight :"normal"}}>Rapport d'activité 2023</span>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}
