import React, { useDebugValue, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { SaveMontantPartVariableBody } from '../../../model/dto/body/save-montant-part-variable';
import { ProfilCode, TableauAmortissementResponse } from '../../../model/dto/response';
import { EcheancierProjet } from '../../../model/entities/echeancier-projet';
import { StatutProjet } from '../../../model/entities/statut-projet';
import Projet from '../../../model/projet';
import { AuthenticationState } from '../../../model/state/authentication-state';
import { investmentService } from '../../../services/investment-service';
import { projetService } from '../../../services/projet-service';
import { ApplicationState } from '../../../store';
import { EditableBlock } from '../../generic/editable-block/editable-block';
import { FormErrorHeader } from '../../generic/form-error-header';
import { InputField } from '../../generic/input-field';
import { SubmitButton } from '../../generic/submit-button';

export type PartVariableInputFormProps = {
   profile: ProfilCode;
   echeanciers: EcheancierProjet[]
}

type FieldData = {
    name?: any,
    value?: any,
    isValid?: boolean,
}

type FormData = {
    montantPartVariable: FieldData
}

export default function PartVariableInputForm({profile, echeanciers}: PartVariableInputFormProps){
    const [form, setForm]  = useState<FormData>({
        montantPartVariable: {name: 'montantPartVariable', value: '', isValid:true}
    });
    const [isFormError, setIsFormError] = useState<boolean>(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const validateField = (value: any): boolean => {
        return value && value !== ""
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName, isValid: isValid } };
      
        setForm({ ...form,  ...newField});
        setIsFormError(false)
    }

    const isFormValid = (): boolean => {
        return validateField(form.montantPartVariable.value)
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
    }

    const prochaineEcheance : EcheancierProjet | null = useMemo(()=>{
         return echeanciers.find(echeancier => echeancier?.DateEcheance?.getTime()! >= Date.now()) ?? null
    }, echeanciers)

    const body: SaveMontantPartVariableBody = {
        MontantPartVariable: form.montantPartVariable.value
    }

    return (
        <EditableBlock
            title={""} 
            editable={profile === ProfilCode.VILLYZ} 
            editor={(close) =>(
                <Form>
                    <FormErrorHeader 
                        message="Veuillez corriger les champs en erreur"
                        displayed={isFormError}
                    />
                    <Form.Field>
                        <InputField 
                            data={form.montantPartVariable}
                            label="Montant de la part variable"
                            submitted={isFormSubmitted}
                            onChange={handleInputChange}
                            error="Merci de renseigner votre adresse"
                            validateField={validateField}
                        />
                    </Form.Field>
                    <SubmitButton 
                        data={body}
                        label="Valider"
                        onPreSubmit={() => {setIsFormSubmitted(true)}}
                        action={()=>projetService.saveMontantPartVariable(body, loginProps.oauth, prochaineEcheance?.ProjetId)}
                        validateForm={isFormValid}
                        onFormNotValid={onFormNotValid}
                        onActionSuccess={close}
                    />
                </Form>
            )}     
        >
            <h3>Prochaine écheance : <span>{prochaineEcheance?.DateEcheance?.toLocaleDateString()}</span></h3>
            <h3>Montant de la part variable : <span>{form.montantPartVariable.value}</span></h3>
        </EditableBlock>
    );
}