import React, { useState } from "react"
import { OnboardingInvestisseurResponse } from "../../model/dto/response"
import { SubscriptionInvestisseurMoraleFormIdentification } from "./subscription-investisseur-morale-form-identification";
import { SwitchOnboardingTypeInvestisseur } from "./switch-onboarding-type-investisseur";

interface Props {
  onFinishOnboarding?: () => void,
  onSubmitIdentificationSuccess?: () => void
}

export function SubscriptionInvestisseurForm({onFinishOnboarding, onSubmitIdentificationSuccess}: Props) {
  const [isIdentiteDone, setIsIdentiteDone] = useState<boolean>(false)
  const [subscriptionStepResponse, setSubscriptionStepResponse] = useState<OnboardingInvestisseurResponse>()

  const onSubmitIdentification = (response: OnboardingInvestisseurResponse) => {
    if(onSubmitIdentificationSuccess) {
      onSubmitIdentificationSuccess()
    }
    setIsIdentiteDone(true)
    setSubscriptionStepResponse(response)
  }

  if(!isIdentiteDone) {
    return (
      <div>
        <SubscriptionInvestisseurMoraleFormIdentification 
          isOnboarding={true}
          onSubmitSuccess={onSubmitIdentification}
        />
      </div>
    )
  }
  else {
    return (
      <SwitchOnboardingTypeInvestisseur
        subscriptionStepResponse={subscriptionStepResponse!}
      />
    )
  }

}






