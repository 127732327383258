
import React, { useState } from "react"
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { DonneesImpotResponse } from "../../../model/dto/response";
import { dataService } from "../../../services/data-service";
import { ApplicationState } from "../../../store";
import { AuthenticationState } from "../../../store/authentication/types";
import { DropdownElement, DropdownListFieldNew } from "../../generic/dropdown-list-field"
import { EditableBlock } from "../../generic/editable-block/editable-block"
import { InputField } from "../../generic/input-field";
import { SubmitButton } from "../../generic/submit-button";
import * as isLeapYear from "dayjs/plugin/isLeapYear";
import { makeDownloadFromBase64 } from "../../../helpers/date-helper";
import { ExportPASMensuelBody } from "../../../model/dto/body/export-pas-mensuel-body";
import { ExportPASAnnuelBody } from "../../../model/dto/body/export-pas-annuel-body";

type Props = {

}

type FieldData = {
    value?: any,
    name: string,
    isValid?: boolean,
    key?: string
};

type Form = {
    mois: FieldData,
    annee: FieldData
}

type FormAnnuel = {
    annee: FieldData
}

var dayjs = require("dayjs");
dayjs.extend(isLeapYear); // use plugin
dayjs.locale("fr-FR");

export function DeclarationImpots({}: Props) {
    const months= [
        { number : 1, label : 'Janvier' },
        { number : 2, label : 'Février' },
        { number : 3, label : 'Mars' },
        { number : 4, label : 'Avril' },
        { number : 5, label : 'Mai' },
        { number : 6, label : 'Juin' },
        { number : 7, label : 'Juillet' },
        { number : 8, label : 'Août' },
        { number : 9, label : 'Septembre' },
        { number : 10, label : 'Octobre' },
        { number : 11, label : 'Novembre' },
        { number : 12, label : 'Décembre' }
    ]

    const loginProps: AuthenticationState = useSelector<
        ApplicationState,
        AuthenticationState
    >((state) => state.authentication);

    const [form, setForm] = useState<Form>({
        mois: {value: 0, name: 'mois', isValid: true},
        annee:  {value: '2021', name: 'annee', isValid: true}
    })

    const [formAnnuel, setFormAnnuel] = useState<FormAnnuel>({
        annee:  {value: '2021', name: 'annee', isValid: true}
    })

    const [submitted, setSubmitted] = useState(false)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean, key?: string): void => {
        const fieldValue: string = e.target.value;
        setForm({...form, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid, key: key}})
    }

    const handleInputAnnuelChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean, key?: string): void => {
        const fieldValue: string = e.target.value;
        setFormAnnuel({...formAnnuel, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid, key: key}})
    }

    const onSubmitMensuelleSuccess = (response: DonneesImpotResponse) => {
        makeDownloadFromBase64(response.File!,"declarationMensuelle.csv");
    }

    const onSubmitAnnuelleSuccess = (response: DonneesImpotResponse) => {
        makeDownloadFromBase64(response.File!,"declarationAnnuelle.csv");
    }

    const validateMonth = (value: any) => {
        return value >= 1 && value <= 12
    }

    const validateSubmitMonth = () => {
        return validateMonth(form.mois.value)
    }

    const bodyMensuel: ExportPASMensuelBody = {
        Month: form.mois.value,
        Year: form.annee.value
    }

    const bodyAnnuel: ExportPASAnnuelBody = {
        Year: formAnnuel.annee.value
    }

    return (
        <>
            <EditableBlock
                title="Déclaration mensuelle"
                editor={() => null}
                editable={false}
            >
                <Form>
                    <Form.Field>
                        <DropdownListFieldNew
                            datasource={months.map(month => {
                                return {
                                    text: month.label,
                                    value: month.number
                                } as DropdownElement
                            })}
                            field={form.mois}
                            label="Mois"
                            onChange={(value: any) => {
                                setForm({...form, mois: {name: 'mois', value: value, isValid: true}})
                            }}
                            placeholder="Choisir un mois"
                            maxWidth={200}
                            submitted={submitted}
                            validateField={validateMonth}
                            error="Veuillez choisir un mois"
                        />
                    </Form.Field>
                    
                    <Form.Field>
                        <InputField
                            data={form.annee}
                            label="Annee"
                            onChange={handleInputChange}
                            inputCss={{maxWidth:200}}
                        />
                    </Form.Field>       

                    <SubmitButton 
                        data={bodyMensuel}
                        label="Valider"
                        name="declarationMensuelleButton"
                        onPreSubmit={() => setSubmitted(true)}
                        action={() => dataService.getDeclarationMensuelle(bodyMensuel, loginProps?.oauth)}
                        onActionSuccess={onSubmitMensuelleSuccess}
                        validateForm={validateSubmitMonth}
                    />
                </Form>
            </EditableBlock>
            <EditableBlock
                title="Déclaration annuelle"
                editor={() => null}
                editable={false}
            >
                <Form>
                    <Form.Field>
                        <InputField
                            data={formAnnuel.annee}
                            label="Annee"
                            onChange={handleInputAnnuelChange}
                            inputCss={{maxWidth:200}}
                        />
                    </Form.Field>

                    <SubmitButton 
                        data={bodyAnnuel}
                        label="Valider"
                        name="declarationAnnuelleButton"
                        action={() => dataService.getDeclarationAnnuelle(bodyAnnuel, loginProps?.oauth)}
                        onActionSuccess={onSubmitAnnuelleSuccess}
                    />
                </Form>
            </EditableBlock>
        </>
    )
}