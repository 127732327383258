import React, { useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { useState } from "react";
import { AuthenticationState } from "../../model/state/authentication-state";
import { SubmitButton } from "../generic/submit-button";
import { SimpleButton } from "../generic/simple-button";
import { ApplicationState } from "../../store";
import { OnboardingInvestisseurResponse, OnboardingCitoyenResponse, QuestionListResponse, ReponseQuestionPropositionListResponse, UserInfoResponse, UserQuestionReponseListResponse, OnboardingBusinessResponse, OnboardingOrganisationResponse } from "../../model/dto/response";
import { Container } from "semantic-ui-react";
import { userService } from "../../services/authentication-redux-service";
import { RegisterExperienceInvestissementBody } from "../../model/dto/body/register-experience-investissement-body";
import { Question } from "../../model/entities/question";
import { referenceService } from "../../services/reference-service";
import { ReponseQuestionProposition } from "../../model/entities/reponse-question-proposition";
import { ReponsesQuestionList } from "./reponses-question-list";
import { AuthenticationAction, CHANGE_IDENTITY } from "../../actions/authentication-actions";
import InformationBandeau from "../generic/information-bandeau/information-bandeau";
import { FlowStep } from "../../model/entities/flow-step";
import { SubFlowStep } from "../../model/entities/sub-flow-step";

type Props = {
  userInfo?: UserInfoResponse;
  loginProps?: AuthenticationState;
  isOnboarding?: boolean;
  workflowTypeCode?: string;
  lastStepResponse?: OnboardingCitoyenResponse | OnboardingBusinessResponse | OnboardingOrganisationResponse;
  onSkipStep?: (response: Response) => void;
  onSubmitSuccess: (response: OnboardingCitoyenResponse) => void;
  onCancel?: () => void;
};

function ExperienceInvestissementForm({
  loginProps,
  isOnboarding,
  workflowTypeCode,
  lastStepResponse,
  onSkipStep,
  onSubmitSuccess,
  onCancel
}: Props) {
   
  const [selectedResponseList, setSelectedResponseList] = useState<number[]>([])
  const [questionList, setQuestionList] = useState<Question[]>([])
  const [step, setStep] = useState<string>(lastStepResponse?.NextFormStep || SubFlowStep.OBJECTIFS_INVESTISSEMENT ) 
  const [reponseQuestionPropositionList, setReponseQuestionPropositionList] = useState<ReponseQuestionProposition[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    referenceService.getAllQuestion(loginProps?.oauth)
    .then(response => {
      return response.json() as Promise<QuestionListResponse>
    })
    .then(response => {
      setQuestionList(response.QuestionList!)
    })
    referenceService.getAllReponseQuestionProposition(loginProps?.oauth)
    .then(response => {
      return response.json() as Promise<ReponseQuestionPropositionListResponse>
    })
    .then(response => {
      setReponseQuestionPropositionList(response.ReponsePropositionList!)  
    })
    userService.getUserQuestionReponseList(loginProps?.oauth?.userId, loginProps?.oauth)
    .then(response=>{
      response.UserQuestionReponsesList?.forEach(response=>{
        setSelectedResponseList(previousState => [...previousState, response.ReponseQuestionPropositionId!])
      })
    })
}, [])

  const onReponseClick = (newResponse: ReponseQuestionProposition | undefined, oldResponseList: ReponseQuestionProposition[] | undefined) => {
    let listWithAdded: number[] = selectedResponseList
    if(newResponse) {
        listWithAdded = [...listWithAdded, newResponse.Id]
    }

    let listWithSuppress: number[] = listWithAdded
    if(oldResponseList) {
      listWithSuppress = listWithSuppress.filter(element => !oldResponseList.map(r => r.Id).includes(element))
    }
    setSelectedResponseList(listWithSuppress)
  }

  // Indique si la question dont le numéro est passé en paramètre a une réponse correcte
  const isRight = (questionNumber: number) => {
    let question = questionList.find(q => q.Numero === questionNumber)
    if(question) {
      let responses = reponseQuestionPropositionList.filter(response => response.QuestionId === question!.Id)
      let selectedResponses = responses.filter(response => selectedResponseList.includes(response.Id))
      if(selectedResponses.length === 0) {
        return false
      }
      else {
        return selectedResponses.find(response => response.IsRight)
      }
    }
    
    return true
  }

  // Indique la liste des questions pour chaque étape du formulaire
  const questions = useMemo(() => {
    if(step === SubFlowStep.OBJECTIFS_INVESTISSEMENT || step === null) {
        return questionList.filter(question => question.Etape === SubFlowStep.OBJECTIFS_INVESTISSEMENT && isRight(question.HiddenBy))
    }
    else if(step === SubFlowStep.EXPERIENCE_INVESTISSEMENT) {
        return questionList.filter(question => question.Etape === SubFlowStep.EXPERIENCE_INVESTISSEMENT && isRight(question.HiddenBy))
    }
    else {
        return questionList.filter(question => question.Etape === SubFlowStep.CONNAISSANCE_RISQUES && isRight(question.HiddenBy))
    }
  }, [questionList, step, selectedResponseList])

  const body: RegisterExperienceInvestissementBody = {
    ReponseQuestionIdList: selectedResponseList,
    CurrentFormStep: step!,
    WorkflowTypeCode: workflowTypeCode,
  }

  const onSubmitExpInvSuccess = (response: OnboardingInvestisseurResponse) => {
    if(response.UserInfo) {
      const action: AuthenticationAction = { type: CHANGE_IDENTITY, user: response.UserInfo }
      localStorage.setItem("user_info", JSON.stringify(response.UserInfo))
      dispatch(action)
    }
    onSubmitSuccess(response)
  }

  const skipToNextStepKey = () => {
    const body = {
        WorkflowTypeCode : workflowTypeCode!,
        CurrentFlowStep : FlowStep.EXPERIENCE_INVESTISSEMENT,
        RessourceId:  loginProps?.user?.UserId!,
        SubFlowStep : step,
        IsSkiped: true
    }
    return referenceService.getNextStep(body).then(response => {
        onSkipStep && onSkipStep(response)})
  }

  const message = (
    <div>
      <p css={{marginBottom: '4px !important'}}>Pour personnaliser votre expérience et vous offrir des conseils adaptés, nous aimerions en savoir plus sur vos antécédents en matière d’investissements et vos objectifs financiers.</p>
      <p>Veuillez fournir des informations exactes et à jour.</p>
    </div>
  );

  return (
    <Container>
      <InformationBandeau 
        type="information"
        icon={{name:'info circle', size:"big", color:"blue"}}
        message={message}
      />
        <div css={{margin: "20px 0px"}}>
          {
            questions?.map(question =>
              <>
                <label 
                  css={{
                    color:"#005870",
                    fontWeight:"bold",
                    fontSize:"16px"
                  }}
                >
                  {question.Libelle}*
                </label> 
                <div 
                  css={{
                    display:"flex",
                    flexDirection:"row",
                    flexWrap:"wrap",
                    width:"auto",
                    marginTop:"10px",
                    justifyContent: "center"
                  }}
                >
                  <ReponsesQuestionList 
                    key={question.Id}
                    responses={reponseQuestionPropositionList.filter(responseQuestionProposition => responseQuestionProposition.QuestionId === question.Id)}
                    onResponseClick={onReponseClick}
                    multiple={question.IsMultiple}
                    userResponses={reponseQuestionPropositionList.filter(response => selectedResponseList.includes(response.Id) && response.QuestionId === question.Id)}
                  />
                </div>
              </>
            )
          }
        </div>
        <div css={{paddingBottom : "10px"}}>*champ obligatoire</div>
        <div className="button-bar">
          <SubmitButton 
            label="Continuer"
            action={()=>userService.registerExperienceInvestissement(body,loginProps?.user?.UserId,loginProps!.oauth)}
            data={body}
            onActionSuccess={(response: OnboardingCitoyenResponse)=>{
              if(response.NextFormStep != null){
                setStep(response.NextFormStep);
                return;
              }
              onSubmitExpInvSuccess(response)
            }}
            loaderArea="modal"
          />
          {!isOnboarding && <SubmitButton 
            label="Quitter"
            onClick={onCancel}
          />}
          {isOnboarding && (
            <SimpleButton label="Enregistrer et passer"
            onClick={() =>skipToNextStepKey()}
            />
          )}
        </div>
    </Container>
 );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedExperienceInvestissementForm = connect(
  mapStateToProps,
  null
)(ExperienceInvestissementForm);
export { ConnectedExperienceInvestissementForm as ExperienceInvestissementForm };
