import React from "react";
import { User } from "../../model/user";
// import { Login } from "./login"
import { Login } from "./newLogin"
import { GlobalState } from "../../model/state/global-state"
import { connect } from "react-redux"
import {AuthenticationAction, REQUEST_IDENTITY} from "../../actions/authentication-actions"
import {
    REQUEST_LOGIN,
    LOGIN_SUCCESSFUL,
    LOGIN_FAILURE,
    LOGOUT
} from "../../actions/authentication-actions"
import { AuthenticationState } from "../../model/state/authentication-state";
import { OAuthResponse } from "../../model/authentication/oauth-response";
import { ApplicationState } from "../../store";
import { UserInfoResponse } from "../../model/dto/response";

interface LoginContainerProps { 
    loginProps: AuthenticationState,
    dispatchProps: any
   }

// class LoginContainer extends React.Component<LoginContainerProps, {}> {
//     constructor(props: LoginContainerProps) {
//         super(props);
//     }

//     loginRequest() {
//         console.log(this.props);
//         this.props.loginRequest();
//     }

//     loginSuccessful(user: User) {
//         this.props.loginProps.loginSuccessful(user);
//     }

//     loginFailure() {
//         this.props.loginProps.loginFailure();
//     }

//     logOut() {
//         this.props.loginProps.logOut();
//     }

//     render() {
//         return (
//             this.props.loginProps && (
//                 <div className="container">
//                     <Login 
//                         loginRequestionAction={this.loginRequest}
//                         loginSuccessfulAction={this.loginSuccessful}
//                         loginFailureAction={this.loginFailure}
//                         logOutAction={this.logOut}
//                     />
//                 </div>
//             ) || (
//                 <div>On a un soucis</div>
//             )
//         )
//     }
// }

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const mapDispatchToProps = (dispatch: any) => ({
    dispatchProps :{
        loginRequest: () => dispatch( {type: REQUEST_LOGIN} ),
        loginSuccessful: (authResponse: OAuthResponse) => dispatch( {type: LOGIN_SUCCESSFUL, authResponse: authResponse} ),
        loginFailure: () => dispatch( {type: LOGIN_FAILURE} ),
        logOut: () => dispatch( {type: LOGOUT} ),
        requestIdentity: (userInfo: UserInfoResponse) => dispatch( {type: REQUEST_IDENTITY, user: userInfo} ),
    }
});

function LoginContainerFunction(props: LoginContainerProps) {
    function loginRequest() {
        props.dispatchProps.loginRequest();
    }

    function loginSuccessful(authResponse: OAuthResponse) {
        props.dispatchProps.loginSuccessful(authResponse);
    }

    function loginFailure() {
        props.dispatchProps.loginFailure();
    }

    function logOut() {
        props.dispatchProps.logOut();
    }

    function requestIdentity(userInfo: UserInfoResponse) {
        props.dispatchProps.requestIdentity(userInfo)
    }

    return (
        <div>
            <Login 
            />
        </div>
    ) 
}

const ConnectedLoginContainerFunction = connect(mapStateToProps, mapDispatchToProps)(LoginContainerFunction);
export { ConnectedLoginContainerFunction as LoginContainerFunction };