import React from "react";
import {
    Container,
    Grid,
    GridColumn,
    GridRow,
    Image,
} from "semantic-ui-react";
import "./engagementsV2.css"
import SectionObjectifDevDurable from "../pages-components/objectifs-dev-durable/section-objectif-dev-durable";
import RejoignezUneCommuEngagee from "../pages-components/call-to-action/rejoignez-une-commu-engagee";
import { CustomUnderlinedText } from "../../generic/custom-underlined-text";


export function EngagementsV2() {
    return (
        <div id="engagements-page-container">
            <Container className="engagements-main-image-container">
                    <Image
                        fluid
                        className="engagements-main-image"
                        src="/img/Engagement_asset_1.png"
                        alt="illustration de présentation de la page montrant des gens assis en haut d'une colline contemplant une vallée en contrebas située entre plusieurs montagnes visibles "
                    />      
            </Container>   

            {/*======================== HERO SECTION =======================*/}    
            <div id="main-title-container">
                <Container>
                    <Grid doubling stackable>
                        <Grid.Row >
                            <Grid.Column >
                                <h1 className="main-title-container-title" > 
                                    Agissons pour nos territoires
                                </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row >    
                            <Grid.Column 
                            >
                                <p className="main-title-container-paragraph">
                                Investir son épargne dans un territoire c'est participer à son développement dans l'intérêt de toutes et tous.</p>                     
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>

            {/*======================== ENGAGEMENTS SECTION =======================*/}
            <div className="engagements-list-container">
                <Container >              
                    <Grid stackable={true}>
                        <Grid.Row>
                            <Grid.Column
                             width={6}                           
                            >
                                <Image
                                    rounded
                                    centered
                                    src="/img/nos-engagements/EngagementsV2_asset1.jpg"                                   
                                    alt="image illustrant de jeunes footballeuses en train d'échanger, assises par terre "
                                />                            
                            </Grid.Column>
                            <Grid.Column
                                width={9}
                                floated="right"
                                verticalAlign="middle"
                            >
                                <p className="standard-paragraph">
                                <strong>Nous imaginons un avenir où les citoyens ont le pouvoir de participer aux projets de leur territoire</strong> : transition écologique, lutte contre la désertification médicale, développement de transports durables ou création de logements accessibles à tous, Le tout en leur faisant profiter d'un rendement financier attractif.  
                                </p>
                            </Grid.Column>
                        </Grid.Row>

                        {/* inversement de l'ordre en version mobile */}                     
                        <Grid.Row className="engagements-list-middle-section">
                                <Grid.Column width={9} verticalAlign="middle">
                                    <p className="standard-paragraph">
                                    <strong>Notre mission est de permettre aux citoyens de prendre en main l'avenir de leurs territoires en investissant leur épargne</strong> directement dans des projets locaux qui comptent pour eux. Ils savent que 100% de l’argent investi est consacré au projet de leur choix. Et ça change tout.
                                    </p>
                                </Grid.Column>  
                                <Grid.Column
                                mobile={16}
                                tablet={6}
                                computer={6}
                                floated="right"
                                >
                                    <Image
                                        rounded
                                        centered
                                        src="/img/nos-engagements/EngagementsV2_asset2.1.jpg"
                                        alt="image d'un champ cultivé, avec une ferme en contrebas et des éoliennes à l'horizon"                            
                                    />      
                                </Grid.Column>                              
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column       
                             mobile={16}
                             tablet={6}
                             computer={6}
                            >
                                <Image
                                    rounded
                                    src="/img/nos-engagements/EngagementsV2_asset3.jpg"
                                    centered
                                    alt="illustration d'un groupe échangeant lors de ce qui semble être une réunion"
                                />
                            </Grid.Column>

                            <Grid.Column
                                width={9}
                                floated="right"
                                verticalAlign="middle"
                            >
                                <p className="standard-paragraph">
                                    <strong>Nos valeurs fondamentales sont l'engagement citoyen, la transparence totale, l'innovation constante et le soutien aux transitions</strong> écologiques et sociales. Le cap est clair et nous sommes déterminés à devenir un vecteur de la transformation de nos territoires.
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
            
            {/*========================  Nous c'est vous =====================*/}
            <div id="itsyou">
                <Container >
                    <CustomUnderlinedText 
                        startText="Devenez artisans de la fabrique de"
                        underlinedText="votre territoire"
                        fontSize={24}
                        offset={2}
                        textColor="#000000"
                    />
                    <Grid stackable={true} className="itsyou-row">
                        <GridRow columns={3}>
                            <GridColumn
                                className="itsyou-column"
                                computer={5}
                            >
                                <Image
                                    rounded
                                    src="/img/nos-engagements/EngagementsV2_asset4.png"
                                    centered
                                    alt="image représentant des gens qui font des sondages dans la rue"
                                />
                                <h3 className="h3-as-H2style">Les élus locaux</h3>
                                <p className="standard-paragraph">
                                Ils se battent chaque jour pour améliorer le quotidien des citoyens et les représenter. Afin d’engager les habitants de leurs collectivités dans leurs projets, ils savent qu’ils peuvent compter sur Villyz pour les accompagner.
                                </p>
                            </GridColumn>
                            <GridColumn
                                width={5}
                                className="itsyou-column itsyou-column-middle"
                            >
                                <Image
                                    rounded
                                    src="/img/nos-engagements/EngagementsV2_asset5.png"
                                    centered
                                    alt="image représentant des artisans boulangers devant leur boutique"
                                />
                                <h3 className="h3-as-H2style">Les entreprises</h3>
                                <p className="standard-paragraph">
                                Elles font battre le coeur économique du pays et souhaitent avoir un impact positif sur la société. Heureusement, elles ont désormais la possibilité d’investir simplement dans les projets de leur territoire.
                                </p>
                            </GridColumn>
                            <GridColumn computer={5} className="itsyou-column">
                                <Image
                                    rounded
                                    src="/img/nos-engagements/EngagementsV2_asset6.png"
                                    centered
                                    alt="illustartion de citoyens regroupés pour une photo dans une rue"
                                />
                                <h3 className="h3-as-H2style">Les citoyens</h3>
                                <p className="standard-paragraph">
                                Ils s’intéressent à l’avenir de leur territoire et veulent soutenir les projets qui leur tiennent à coeur. Les citoyens répondent toujours présent pour participer aux projets de leur collectivité et en sont les premiers ambassadeurs.
                                </p>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Container>
            </div>
            
            {/*========================  Fondateurs =====================*/}
            <div id="fondateurs">
                <Container>
                    <CustomUnderlinedText startText="Des fondateurs engagés"
                        underlinedText="à vos côtés"
                        fontSize={24}
                        offset={2}
                        textColor="#000000"
                    />
                    <Image
                        src="/img/nos-engagements/fondateurs.png"
                        rounded
                        className="fondateurs-img"
                        alt="photo des 3 fondateurs de villyz : Sylvain, Arthur et Quentin"
                    />
                    <Grid>
                        <GridRow
                            columns={3}
                            className="fondateurs-row"
                            reversed="phone"
                        >
                            <GridColumn
                                className="fondateurs-column"
                                computer={5}
                                tablet={5}
                                mobile={16}
                            >
                                <h3 className="h3-as-H2style">Sylvain</h3>
                                <p className="standard-paragraph">
                                La finance, il la connait du bout des doigts. Sylvain a passé presque 10 ans à différentier les bons et les mauvais emprunts pour permettre à chaque citoyen d’investir dans des projets vertueux et solides financièrement.
                                </p>
                            </GridColumn>
                            <GridColumn
                                className="fondateurs-column"
                                computer={5}
                                tablet={5}
                                mobile={16}
                            >
                                <h3 className="h3-as-H2style">Arthur</h3>
                                <p className="standard-paragraph">
                                Touche à tout qu’y s’interesse aussi bien à l’action politique qu’au pouvoir des citoyens de faconner la société en oeuvrant ensemble. Arthur met toute son énergie et ses convictions pour vous trouver les projets avec le plus d’impact.
                                </p>
                            </GridColumn>
                            <GridColumn
                                className="fondateurs-column"
                                computer={5}
                                tablet={5}
                                mobile={16}
                            >
                                <h3 className="h3-as-H2style">Quentin</h3>
                                <p className="standard-paragraph">
                                Parce qu’il est méticuleux et attentionné, Quentin oeuvre chaque jour à la tête de l’équipe tech pour rendre la plateforme Villyz toujours plus intuitive, sécurisée et à votre image. La sécurité de vos données est sa priorité.
                                </p>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Container>
            </div>

            {/*========================  OBJECTIFS ONU =====================*/}
            <SectionObjectifDevDurable />

            {/*========================  Call to action =====================*/}
            <div className="communaute-block">
                <RejoignezUneCommuEngagee width={100}/>
            </div>
        </div>
    );
}
