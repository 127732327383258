import queryString from 'query-string';
import React from "react";
import { RemerciementDebouclage } from "../components/investment/remerciement-debouclage";

// type Params = {
//   transactionId?: string,
//   MandateId?: string
// }

export function RemerciementDebouclageRouter() {
    // let { transactionId, MandateId } = useParams<Params>();
    // let idTransaction: number = transactionId ? +transactionId : 0
    // let mandateId: number | undefined = MandateId ? +MandateId : undefined
    // let isDirectDebit: boolean = false
    // if(mandateId) {
    //     isDirectDebit = true
    // }

    const values = queryString.parse(window.location.search)
    let idTransaction: number = values.transactionId ? +values.transactionId : 0
    let mandateId: number | undefined = values.MandateId ? +values.MandateId : undefined
    let isDirectDebit: boolean = false
    if(mandateId) {
        isDirectDebit = true
    }

    return (
        <RemerciementDebouclage 
            transactionId={idTransaction} 
            mandateId={mandateId} 
            isDirectDebit={isDirectDebit}
        />
    );
}
