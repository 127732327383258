export interface FlowStep{
    Code?:string,
    Libelle?:string
}

const ENREGISTREMENT = "ENR";
const ETAT_CIVIL = "ECV";
const ADRESSE_POSTALE = "ADP";
const EXPERIENCE_INVESTISSEMENT = "EXI";
const PIECES_JUSTIFICATIVES = "PJS";
const REMERCIEMENT = "REM";
const ADRESSES: string = "ADS";    
const IDENTITE_REPRESENTANT_LEGAL: string = "IRL";
const BENEFICIAIRES_EFFECTIFS: string = "BEF";
const COMPLET: string = "CPL";
const PROJET: string = "PRJ";
const MONTANT: string = "MON";
const PAIEMENT: string = "PAI";
const DESCRIPTION: string = "DES";
const INFORMATIONS_GENERALES: string = "INF";
const FINANCEMENT_PARTICIPATIF: string = "FIP";
const FINALISATION: string = "FIN";
const FINANCEMENT_GLOBAL: string = "FIG";
const PERIODE_DE_COLLECTE: string = "PDC";
const PERIODE_DE_DON: string = "PDD";
const FINANCEMENT_DES_DONS: string = "FID";

export const FlowStep = {
    ENREGISTREMENT,
    ETAT_CIVIL,
    ADRESSE_POSTALE,
    EXPERIENCE_INVESTISSEMENT,
    PIECES_JUSTIFICATIVES,
    REMERCIEMENT,
    ADRESSES,
    IDENTITE_REPRESENTANT_LEGAL,
    BENEFICIAIRES_EFFECTIFS,
    COMPLET,
    PROJET,
    MONTANT,
    PAIEMENT,
    DESCRIPTION,
    INFORMATIONS_GENERALES,
    FINANCEMENT_PARTICIPATIF,
    FINALISATION,
    FINANCEMENT_GLOBAL,
    PERIODE_DE_COLLECTE,
    PERIODE_DE_DON,
    FINANCEMENT_DES_DONS
}