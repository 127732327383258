import React from "react"
import { css } from "@emotion/core";
import {Image} from "semantic-ui-react"

type Props = {
    title?: string,
    subtitle?: string,
    subtitle2? : string
}

export function PourBientotScreen({title}: Props) {
    const couleurBleu = "#005870"
    const styletitre = css({
        marginBottom : "-80px", 
        textAlign : "center", 
        zIndex : 4,
        color : couleurBleu,
        fontSize : "3rem",
        position : "relative"
    })

  return(
    <>
        <p css={styletitre}>
         {title ?? "Découvrez les premiers projets en janvier 2022 !"}
        </p>
        <Image
          size = "large"
          centered
          src="img/FrontPage_Cest-pour-bientot.svg"
          css={{zIndex : 3}}
        />
    </>

  )
}