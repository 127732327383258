import React, {FormEvent, useState } from "react"
import { useEffect } from "react";
import { Checkbox,CheckboxProps } from "semantic-ui-react";
import { CheckBoxElement } from "./checkbox-list-field";


type Props = {
    libelle?: string
    isLimited?: boolean,
    verso?: boolean,
    submitted?: boolean,
    name?: string,
    centered?: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>, isVerso? : boolean, isAlreadyUploaded?: boolean) => void,
    onClickCheckBox?: (isVersoAsked : boolean) => void,
    validateField?: (file?: File) => boolean
}

export function FileUpload({libelle, isLimited, verso, submitted, name, centered, onChange, onClickCheckBox, validateField}: Props) {
    const [filePrincipal, setFilePrincipal] = useState<File | undefined>(undefined);
    const [fileSecondaire, setFileSecondaire] = useState<File | undefined>(undefined);
    const [isVersoAsked, setIsVersoAsked] = useState<boolean>(false);
    const [isValid, setIsValid] = useState(true)
    const [isSecondaryValid, setIsSecondaryValid] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")
    const [errorMessageSecondaire, setErrorMessageSecondaire] = useState("")

    const onChangePiecePrincipale = (e : React.ChangeEvent<HTMLInputElement>) => {
        const element = e.target;
        const isAlreadyUploaded = filePrincipal ? true : false;
        onChange(e, false, isAlreadyUploaded);
        if (element.files != null) {
            if(validateField) {
                if(validateField(element.files[0])) {
                    setFilePrincipal(element.files[0]);
                    setIsValid(true)
                    setErrorMessage("")
                }
                else {
                    setIsValid(false)
                    setErrorMessage("Veuillez importer ce document en respectant les conditions de format")
                }
            }
            else {
                setFilePrincipal(element.files[0]);
            }
        }
    }

    useEffect( () => {
        if (!submitted) 
            return;

        if(validateField && !validateField(filePrincipal)) {
            setIsValid(false);
            setErrorMessage("Veuillez importer ce document en respectant les conditions de format")
        }
        
        if(verso && validateField && !validateField(fileSecondaire)) {
            setIsSecondaryValid(false);
            setErrorMessageSecondaire("Veuillez importer ce document en respectant les conditions de format")
        }

    }, [submitted])

    const onChangePieceSecondaire = (e : React.ChangeEvent<HTMLInputElement>) => {
        const element = e.target;
        const isAlreadyUploaded = fileSecondaire ? true : false; 
        onChange(e, true, isAlreadyUploaded);
        if (element.files != null) {
            if(validateField) {
                if(validateField(element.files[0])) {
                    setFileSecondaire(element.files[0]);
                    setIsSecondaryValid(true)
                    setErrorMessageSecondaire("")
                }
                else {
                    setIsSecondaryValid(false)
                    setErrorMessage("Veuillez importer ce document en respectant les conditions de format")
                }
            }
            else {
                setFileSecondaire(element.files[0]);
            }
        }
    }

    const onCheckboxClick = (e : FormEvent<HTMLInputElement> , data: CheckboxProps) => {
        if (onClickCheckBox) {
            onClickCheckBox(isVersoAsked);
        }
        setIsVersoAsked(data.checked?? false);
    }

    const checkbox : CheckBoxElement = {
        label : "soumettre un verso : Carte d’identité uniquement (obligatoire)",
        key: "IRA"
    }

    return(
        <>
        <div className="form-group" css={{textAlign: centered ? "center" : "left"}}>
            {libelle && libelle !== "" && (<h1>{libelle}</h1>)}
            <div className="container-app">
                <input type="file" name={name} onChange={onChangePiecePrincipale} required/>
            </div>
            {!isValid && (
                <div
                    css={{
                        color: "#F03E3E",
                        fontSize: "10px",
                        marginTop: "4px",
                    }}
                >
                    {errorMessage}
                </div>
            )}
            {isLimited && (<div>
                Votre fichier doit faire entre 32Ko et 7Mo et respecter l'un des formats suivants : .pdf, .jpeg, .jpg, .gif, .png
            </div>)
            }
            {verso && (
                <>
                <div css={{marginTop:"20px"}}>
                    <Checkbox
                        key={checkbox.key}
                        checked={isVersoAsked}
                        className="form-group field"
                        label={checkbox.label}
                        onChange={(e: FormEvent<HTMLInputElement>, data: CheckboxProps) => onCheckboxClick(e, data)}
                        css={{
                            marginRight: '6px !important'
                        }}
                    />
                </div>
                </>
            )}
            {isVersoAsked && (
                <>
                <div className="container-app">
                    <input type="file" name="Verso" onChange={onChangePieceSecondaire}/>
                </div>
                {!isSecondaryValid && (
                    <div
                        css={{
                            color: "#F03E3E",
                            fontSize: "10px",
                            marginTop: "4px",
                        }}
                    >
                        {errorMessageSecondaire}
                    </div>
                )}
                {isLimited && (<div>
                    Votre fichier doit faire entre 32Ko et 7Mo et respecter l'un des formats suivants : .pdf, .jpeg, .jpg, .gif, .png
                </div>) }
                </>
            )}
        </div>
        </>
    )
}