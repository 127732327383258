import React, {useEffect, useState } from "react";
import {useSelector} from "react-redux";  
import {Form } from "semantic-ui-react";
import { HistoInvestInformationsBody } from "../../../model/dto/body/histo-invest-informations-body";
import { InvestissementHistoryResponse, StatutInvestissementListResponse } from "../../../model/dto/response";
import { MangoPayEventReference } from "../../../model/entities/mangopay-event-reference";
import { StatutInvestissement } from "../../../model/entities/statut-investissement";
import { TrueLayerPaymentStatus } from "../../../model/entities/truelayer-payment-status";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { userService } from "../../../services/authentication-redux-service";
import { referenceService } from "../../../services/reference-service";
import { ApplicationState } from "../../../store";
import { DropdownListFieldNew } from "../../generic/dropdown-list-field";
import { InputField } from "../../generic/input-field";
import { SubmitButton } from "../../generic/submit-button";
 

interface Props {
    userId?: number;
    currentHistoInvest?: InvestissementHistoryResponse;
    onSubmitSuccess: () => void;
  }
type FieldData = {
  value?: any,
  name?: string,
  isValid?: boolean
};

type FormData = {
  StatutMangoPay: FieldData,
  IdMangoPay: FieldData,
  StatutTrueLayer: FieldData,
  IdTrueLayer: FieldData,
  StatutInvestissement: FieldData
}

export function ChangeHistoInvestInformations({
  userId,
  currentHistoInvest,
  onSubmitSuccess
} : Props) {
  const [form, setForm] = useState<FormData>({
    StatutMangoPay : { name : "StatutMangoPay", value : currentHistoInvest?.MangoPayStatus, isValid: true},
    IdMangoPay : { name : "IdMangoPay", value : currentHistoInvest?.MangoPayId, isValid: true},
    StatutTrueLayer : { name : "StatutTrueLayer", value : currentHistoInvest?.TrueLayerTrueLayerPaymentStatus, isValid: true},
    IdTrueLayer : { name : "IdTrueLayer", value : currentHistoInvest?.TrueLayerId, isValid: true},
    StatutInvestissement: { name: "StatutInvestissement", value: currentHistoInvest?.StatutInvestissement?.Code, isValid: true}
  })
  const [mangoPayEventList, setMangoPayEventList] = useState<MangoPayEventReference[]>([])
  const [trueLayerEventList, setTrueLayerEventList] = useState<TrueLayerPaymentStatus[]>([])
  const [statutInvestissementList, setStatutInvestissementList] = useState<StatutInvestissement[]>([])

  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  useEffect(() => {
    referenceService.getAllMangoPayEvent()
    .then((response) => {
      if (response.IsTraitementOk) {
        setMangoPayEventList(response.MangoPayEventList!)
      }
    });

    referenceService.getAllTrueLayerPaymentStatus()
    .then((response) => {
      if (response.IsTraitementOk) {
        setTrueLayerEventList(response.TrueLayerPaymentStatusList!)
      }
    });

    referenceService.getAllStatutInvestissement()
    .then((response: StatutInvestissementListResponse) => {
      setStatutInvestissementList(response.StatutInvestissementList!)
    })
}, [])
  
const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
    const fieldValue: string = e.target.value;
    setForm({...form, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid}})
} 

const body: HistoInvestInformationsBody = {
    StatutMangoPay: form.StatutMangoPay.value,
    IdMangoPay : form.IdMangoPay.value,
    StatutTrueLayer : form.StatutTrueLayer.value,
    IdTrueLayer : form.IdTrueLayer.value,
    UserId :  userId!,
    StatutInvestissement: form.StatutInvestissement.value
}

  return (
    <Form>
      <Form.Field width={8}>
        <DropdownListFieldNew
          label="Statut investissement"
          field={form.StatutInvestissement}
          placeholder="Statut investissement"
          datasource={statutInvestissementList.map((c) => ({
            text: c.Code!,
            value: c.Code!,
          }))}
          onChange={(value) =>
            handleInputChange(
              { target: { value } } as any,
              form.StatutInvestissement.name
            )
          }                        
        />
      </Form.Field>
      <h3>MangoPay</h3>
      <Form.Group>
        <Form.Field width={8}>
          <DropdownListFieldNew
            label="Statut MangoPay"
            field={form.StatutMangoPay}
            placeholder="Statut MangoPay"
            datasource={mangoPayEventList.map((c) => ({
              text: c.Code!,
              value: c.Code!,
            }))}
            onChange={(value) =>
              handleInputChange(
                { target: { value } } as any,
                form.StatutMangoPay.name
              )
            }                        
          />
        </Form.Field>
        <Form.Field width={8}>
            <InputField 
                data={form.IdMangoPay}
                label="IdMangoPay"
                onChange={handleInputChange}
            />
        </Form.Field>
      </Form.Group>
      <h3>TrueLayer</h3>
      <Form.Group>
        <Form.Field width={8}>
          <DropdownListFieldNew
            label="Statut TrueLayer"
            field={form.StatutTrueLayer}
            placeholder="Statut TrueLayer"
            datasource={trueLayerEventList.map((c) => ({
                text: c.Code!,
                value: c.Code!,
            }))}
            onChange={(value) =>
              handleInputChange(
                { target: { value } } as any,
                form.StatutTrueLayer.name
              )
            }                        
          />
        </Form.Field>
        <Form.Field width={8}>
            <InputField 
                data={form.IdTrueLayer}
                label="IdTrueLayer"
                onChange={handleInputChange}
            />
        </Form.Field>
      </Form.Group>

    <SubmitButton 
        data={body} 
        label="Valider"
        action={() => userService.updateHistoInvestInfoByAdmin(body, loginProps?.oauth?.userId, currentHistoInvest?.Id, loginProps?.oauth)}
        
        onActionSuccess={() => {
            onSubmitSuccess && onSubmitSuccess()
        }} 
    />
</Form>
  );
}

 