import React from "react";
import { section } from "./styles";
import { Container } from "semantic-ui-react";

export function MentionsLegales() {
    return (
        <div>
            <div css={[section]}>
                <Container>
                    <h1>Mentions légales​</h1>
                    <p>
                        L’Editeur du site : Villyz SAS, société au capital de 4
                        202 € enregistrée au RCS de Paris sous le n° 884826520,
                        domiciliée 35 avenue Reille, 75014 Paris.
                    </p>
                    <p>
                        Villyz est agréé en tant que Prestataire de service de
                        financement participatif par l’Autorité des Marchés
                        Financiers sous le numéro FP-20221.
                    </p>
                    <p>Numéro de TVA : FR76884826520</p>
                    <p>Hébergeur du site : OVH</p>
                    <p>Directeur de publication : Arthur Moraglia</p>
                    <p>
                        Adresse mail :{" "}
                        <a href="mailto:contact@villyz.fr">contact@villyz.fr</a>
                    </p>
                    <p>Téléphone : 01 81 69 83 37</p>
                    <p>
                        Attention, prêter de l’argent présente un risque de
                        perte en capital et nécessite une immobilisation de
                        votre épargne.
                    </p>
                    <p>
                        <a href="javascript:openAxeptioCookies()">
                            Cliquez-ici
                        </a>
                        &nbsp;pour modifier vos préférences en matière de
                        cookies
                    </p>
                </Container>
            </div>
        </div>
    );
}
