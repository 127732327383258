import React, { useMemo, useState } from "react"
import { Button, Container, Grid, Header, Icon, Modal, Popup } from "semantic-ui-react"
import { StatutProjet } from "../../../../model/entities/statut-projet";
import { investmentService } from "../../../../services/investment-service";
import { NavLink } from "react-router-dom";
import { PaginatedTable } from "../../../generic/paginated-table/paginated-table"
import { SubmitButton } from "../../../generic/submit-button";
import { AuthenticationState } from "../../../../model/state/authentication-state";
import { ApplicationState } from "../../../../store";
import { useSelector } from "react-redux";
import { ContratDePretResponse, InvestissementHistoryResponse, TableauAmortissementResponse } from "../../../../model/dto/response";
import { format } from "date-fns";
import { Profil } from "../../../../model/entities/profil";
import { LoadingSpinner } from "../../../generic/loading-spinner";
import { SimpleButton } from "../../../generic/simple-button";
import { TypeCollectivite } from "../../../../model/entities/type-collectivite";
import { StatutInvestissement } from "../../../../model/entities/statut-investissement";
import { ModePaiement } from "../../../../model/entities/mode-paiement";
import { CancelInvestementByVirementReservationBody } from "../../../../model/dto/body/cancel-investement-by-virementReservation-body";
import { TypeCollecte } from "../../../../model/entities/type-collecte";

type Props = {
    isLoading?: boolean,
    reload?: ()=>void;
    histoInvestissement?: InvestissementHistoryResponse[],
    handleRetourRemboursement?: ()=>void,
    handleCancelReservation?: ()=> void
    handleCancelReservationError?: ()=> void
}

export function InvestissementsPane({isLoading, histoInvestissement, handleRetourRemboursement, handleCancelReservation, handleCancelReservationError}: Props) {
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [currentInvestissement, setCurrentInvestissement]  = useState<InvestissementHistoryResponse>();
    const [openAnnulationVirementModal, setOpenAnnulationVirementModal] = useState(false);
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const mappingStatutProjet = [
        {
        code: StatutProjet.PUBLIE,
        message: 'En cours'
        },
        {
        code: StatutProjet.CLOTURE,
        message: 'Clôturée'
        },
        {
        code: StatutProjet.DEBOUCLAGE_POSSIBLE,
        message: 'Clôturée'
        },
        {
        code: StatutProjet.DEBOUCLAGE_DEMANDE,
        message: 'Clôturée'
        },
        {
        code: StatutProjet.DEBOUCLAGE_VALIDE,
        message: 'Clôturée'
        },
        {
        code: StatutProjet.DEBOUCLAGE_FINALISE,
        message: 'Clôturée'
        },
        {
        code: StatutProjet.FINANCE,
        message: 'Terminée'
        }
    ]

    const mappingTypeCollecte = [
        {
            code: TypeCollecte.EMPRUNT_CITOYEN,
            libelle: 'Emprunt'
        },
        {
            code: TypeCollecte.DON,
            libelle: 'Don'
        },
        {
            code: TypeCollecte.COMBINAISON_EMPRUNT_DON,
            libelle: 'Combinaison emprunt don'
        }
    ]

    const buttonLabel = useMemo(() => {
        if(loginProps?.oauth?.profilCode === Profil.INVESTISSEUR) {
            return "Découvrir les projets"
        }

        if(loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET) {
            return "Publier un projet"
        }

        return ""
    }, [])

    const tabLabel = useMemo(() => {
        if(loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET) {
            return "Mes projets emprunt"
        }

        return ""
    }, [])

    const textModal = useMemo(() => {
        if(currentInvestissement?.StatutProjet === StatutProjet.SUSPENDED) {
            return " À la suite d'une erreur importante dans la fiche d'informations clés relevée par Villyz, vous avez la possibilité de retirer votre investissement."

        }
        if(currentInvestissement?.RaisonModification !== null) {
            return " À la suite d'une modification significative dans la fiche d'informations clés, vous avez la possibilité de retirer votre investissement."
        }

        return "Vous disposez d'un délai de reflexion de quatre jours vous permettant d'etre remboursé sans frais de la totalité de votre investissement."
    }, [currentInvestissement])

    const onDownloadSuccess = (response: TableauAmortissementResponse ) => {
        const byteCharacters = atob(response.File!);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: ''});
        
        const aEle = document.createElement('a');     // Create a label
        const href = window.URL.createObjectURL(blob);       // Create downloaded link
        aEle.href = href;
        aEle.download = "echeancier.xlsx";  // File name after download
        document.body.appendChild(aEle);
        aEle.click();     // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href)
    }

    const onDownloadSuccessContrat = (response: ContratDePretResponse ) => {
        const byteCharacters = atob(response.File!);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: ''});
        
        const aEle = document.createElement('a');     // Create a label
        const href = window.URL.createObjectURL(blob);       // Create downloaded link
        aEle.href = href;
        aEle.download = "Contrat_Pret.pdf";  // File name after download
        document.body.appendChild(aEle);
        aEle.click();     // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href)
    }

    const authorizedStatusDownloadEcheancier: string[] = [
        StatutProjet.COMPLET,
        StatutProjet.EN_TRAITEMENT,
        StatutProjet.BIENTOT_DISPONIBLE,
        StatutProjet.PUBLIE,
        StatutProjet.INFORMATION_MANQUANTE,
        StatutProjet.DEBOUCLAGE_DEMANDE,
        StatutProjet.CLOTURE,
        StatutProjet.DEBOUCLAGE_POSSIBLE,
        StatutProjet.DEBOUCLAGE_VALIDE,
        StatutProjet.FINANCE
    ]

    const authorizedStatusDownloadContrat: string[] = [
        StatutProjet.DEBOUCLAGE_VALIDE,
        StatutProjet.FINANCE,
        StatutProjet.DEBOUCLAGE_FINALISE
    ]

    const displayConditionForEcheancierButton = (element: InvestissementHistoryResponse) : boolean =>{
        return element.StatutInvestissement?.Code === StatutInvestissement.REUSSI
    }
    
    const displayConditionForRemboursementButton = (element: InvestissementHistoryResponse) : boolean =>{
        if(element.DateValidated && element.StatutInvestissement?.Code === StatutInvestissement.REUSSI){
            const delaiRemboursement : number =  345600000
            const dateInvestissementValidated : number = new Date(element.DateValidated).getTime()
            const dateSuspension : number = new Date(element.DateSuspension!).getTime()
            const DateModification : number = new Date(element.DateModification!).getTime()
            const investissementAvantModification: boolean = (element.StatutProjet === StatutProjet.SUSPENDED && element.DateSuspension != null && dateInvestissementValidated < dateSuspension) ||
            (element.DateModification != null && dateInvestissementValidated < DateModification)
            
            if(investissementAvantModification) return true

            return (Math.abs(Date.now() - dateInvestissementValidated) < delaiRemboursement)        
        }
        return false;
    }

    const displayConditionForReservedInfo =  (element: InvestissementHistoryResponse) => {
        if(element && (element.StatutInvestissement?.Code === StatutInvestissement.INITIE || element.StatutInvestissement?.Code === StatutInvestissement.EN_COURS) && element.ModePaiementCode === ModePaiement.VIREMENT_CLASSIQUE ){
            return element.IsReserve ? (
               <Popup 
                    content={popupMessage}
                    position='top right'
                    size='mini'
                    on='hover'
                    pinned
                    mouseEnterDelay={200}
                    mouseLeaveDelay={200}
                    hideOnScroll
                    trigger={
                        <span>{element.StatutInvestissement?.Libelle} - Réservé 
                        <Icon
                            name="info circle"
                            size="large"
                            color="blue"
                            css={{paddingLeft: "5px"}}
                        />
                        </span>
                    }
               />
            ) :(
                <Popup 
                    content={popupMessage}
                    position='top right'
                    size='mini'
                    on='hover'
                    pinned
                    mouseEnterDelay={200}
                    mouseLeaveDelay={200}
                    hideOnScroll
                    trigger={
                        <span>{element.StatutInvestissement?.Libelle} - Non réservé 
                        <Icon
                            name="info circle"
                            size="large"
                            color="blue"
                            css={{paddingLeft: "5px"}}
                        />
                        </span>
                    }
               />
            )
        }
        return element.StatutInvestissement?.Libelle
    }
    
    const displayCancelOrRefundButton = (element: InvestissementHistoryResponse) => {
        if((element.StatutInvestissement?.Code === StatutInvestissement.INITIE || element.StatutInvestissement?.Code ===    StatutInvestissement.EN_COURS) 
            && element.ModePaiementCode === ModePaiement.VIREMENT_CLASSIQUE){
                return <SimpleButton
                            label={element.IsReserve ? "Annuler ma réservation" : "Annuler mon intention" }
                            onClick={() => {
                                setCurrentInvestissement(element)
                                setOpenAnnulationVirementModal(true)
                            }} 
                        /> 
        }
        else if(displayConditionForRemboursementButton(element)){
            return <SimpleButton
                        label="Annuler mon investissement"
                        onClick={() => {
                            setCurrentInvestissement(element)
                            setOpen(true)
                        }}
                    /> 
        }
        else {
            return ''
        }
    }

    const cancelReservationBody: CancelInvestementByVirementReservationBody = {
        IdInvestissement: currentInvestissement?.Id
    }

    const popupMessage = `Vous avez effectué une opération via virement, votre place est réservée pendant 10 jours. Nous vous avons envoyé les informations pour effectuer votre virement par email. Si nous ne recevons pas les fonds dans ce délai, nous ne pourrons plus vous assurer la possibilité de participer à la collecte.`

    return (
        <>
            <Container>
                {tabLabel &&
                    <h1 className="py-4 text-3xl">{tabLabel}</h1> 
                }
                <LoadingSpinner height={100}>
                {!isLoading && (histoInvestissement?.length ? (loginProps.oauth?.profilCode === Profil.INVESTISSEUR && (
                    <PaginatedTable 
                        elements={histoInvestissement}
                        elementsPerPage={10}
                        headers={
                            [
                                'Date',
                                'Projet', 
                                'Statut du paiement',
                                'Etat de la collecte', 
                                'Taux d\'intérêt', 
                                'Début des remboursements', 
                                'Montant investi', 
                                'Echéancier (.xlsx)',
                                'Remboursement',
                                'Détail'
                            ]
                        }
                        columnSelector={(element: InvestissementHistoryResponse) => {
                        return {
                            values: [
                                element.DateInvestissement ? format(new Date(element.DateInvestissement), "dd/MM/yyyy") : '',
                                <a href={`/projets/${element.ProjetId}`} target="_blank">{element.Titre}</a>,
                                displayConditionForReservedInfo(element),
                                mappingStatutProjet.find((c) => c.code === element.StatutCollecte)?.message,
                                element.TauxInteretCitoyen + '%',
                                element.DatePremiereEcheance ? format(new Date(element.DatePremiereEcheance), "dd/MM/yyyy") : '',
                                element.MontantInvesti ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(element.MontantInvesti / 100) : '',
                                displayConditionForEcheancierButton(element) && (
                                    <SubmitButton
                                    label="Télécharger échéancier"
                                    name={"downloadCitButton" + element.Id}
                                    action={() => investmentService.getEcheancier(element.Id!, loginProps!.oauth)}
                                    onActionSuccess={onDownloadSuccess}
                                    />
                                ) || (
                                    <></>
                                ),
                                displayCancelOrRefundButton(element)                               
                                ,
                                <button css={{
                                        border:"none",
                                        backgroundColor: "white",
                                        textDecoration :"underline",
                                        color:" #005870",
                                        fontWeight:"bold",
                                        cursor:"pointer"
                                        
                                }} onClick={()=>{
                                    setOpenModal(true)
                                    setCurrentInvestissement(element)
                                    }}>
                                    Voir le détail
                                </button>,                                
                            ]
                        }
                    }}
                    />
                    ) || (
                        <PaginatedTable 
                        elements={histoInvestissement}
                        elementsPerPage={5}
                        headers={
                            [
                                'Projet', 
                                'Etat de la collecte', 
                                'Type de la collecte',
                                'Montant collecté',
                                'Taux d\'intérêt', 
                                'Début des échéances', 
                                'Montant collecté de l\'emprunt',
                                'Echéancier (.xlsx)',
                                'Contrat de prêt (.pdf)'
                            ]
                        }
                        columnSelector={(element: InvestissementHistoryResponse) => {
                        return {
                            values: [
                                element.Titre,
                                mappingStatutProjet.find((c) => c.code === element.StatutCollecte)?.message,
                                mappingTypeCollecte.find((c) => c.code === element.TypeCollecteCode)?.libelle,
                                element.MontantCollecte ? new Intl.NumberFormat().format(element.MontantCollecte / 100) + '€' : '',
                                element.TauxInteretCitoyen + '%',
                                element.DatePremiereEcheance ? format(new Date(element.DatePremiereEcheance), "dd/MM/yyyy") : '',
                                element.MontantInvesti ? new Intl.NumberFormat().format(element.MontantInvesti / 100) + '€' : '',
                                element.TypeCollecteCode != TypeCollecte.DON && authorizedStatusDownloadEcheancier.find(s => element.StatutCollecte === s) && (
                                    <SubmitButton
                                    label="Télécharger échéancier"
                                    name={"downloadColButton" + element.Id}
                                    action={() => investmentService.getEcheancierProjet(element.Id!, loginProps!.oauth)}
                                    onActionSuccess={onDownloadSuccess}
                                    />
                                )  || (
                                    <></>
                                ),
                                authorizedStatusDownloadContrat.find(s => element.StatutCollecte === s) && (
                                    <SubmitButton
                                    label="Télécharger contrat"
                                    name={"downloadColContratButton" + element.Id}
                                    action={() => investmentService.getContratPretCol(element.Id!, loginProps!.oauth)}
                                    onActionSuccess={onDownloadSuccessContrat}
                                    />
                                )  || (
                                    <></>
                                )
                            ]
                        }
                    }}
                    />
                    )
                ) 
                : 
                (loginProps?.oauth?.profilCode === Profil.INVESTISSEUR && (
                    <div className="text-center p-8 text-xl">
                        <span className="font-bold">
                            Vous n'avez encore investi dans aucun projet
                        </span>
                        <p>
                            Effectuez une première contribution pour suivre l'évolution de
                            vos investissements
                        </p>
                    </div>
                ) || (loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET && (
                    <div className="text-center p-8 text-xl">
                        <span className="font-bold">
                            Vous n'avez encore publié aucun projet
                        </span>
                        <p>
                            Effectuez une première contribution pour suivre l'évolution de vos
                            projets
                        </p>
                    </div>
                ))))}
                </LoadingSpinner>

                <div className="text-center py-8">
                    <Button as={NavLink} to="/projets" size="big" primary>
                        {buttonLabel}
                    </Button>
                </div>

                <Modal
                    onClose={() => setOpenModal(false)}
                    onOpen={() => setOpenModal(true)}
                    open={openModal}
                >
                    <Modal.Content>
                        <Modal.Description>
                        <Grid stackable={true}>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }}
                                    >
                                        Nom du Projet
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                        {currentInvestissement?.Titre}
                                    </div>
                                    {currentInvestissement?.TypeCollectiviteCode === TypeCollectivite.REGION && (
                                        <div css={{fontSize:"12px"}}>
                                            {currentInvestissement?.NomCollectivite}
                                        </div>
                                    )}
                                    {currentInvestissement?.TypeCollectiviteCode === TypeCollectivite.DEPARTEMENT && (
                                        <div css={{fontSize:"12px"}}>
                                            {currentInvestissement?.NomCollectivite}
                                            ({currentInvestissement?.CodeDepartement})
                                            -{currentInvestissement?.NomRegion}
                                        </div>
                                    )}
                                     {currentInvestissement?.TypeCollectiviteCode === TypeCollectivite.COMMUNE && (
                                        <div css={{fontSize:"12px"}}>
                                            {currentInvestissement?.NomCollectivite}
                                            ({currentInvestissement?.CodeDepartement})
                                            -{currentInvestissement?.NomRegion}
                                        </div>
                                    )}
                                     {currentInvestissement?.TypeCollectiviteCode === TypeCollectivite.EPCI && (
                                        <div css={{fontSize:"12px"}}>
                                            {currentInvestissement?.NomCollectivite}
                                        </div>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={5}>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }} 
                                    >
                                        Date de l'investissement
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                        {currentInvestissement?.DateInvestissement ? format(new Date(currentInvestissement.DateInvestissement), "dd/MM/yyyy") : ''}
                                    </div>
                
                                </Grid.Column>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }}
                                    >
                                        Montant
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                        {currentInvestissement?.MontantInvesti! / 100} €
                                    </div>
                
                                </Grid.Column>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }}
                                    >
                                        Identifiant de l'opération
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                        {currentInvestissement?.Id}
                                    </div>
                
                                </Grid.Column>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }}
                                    >
                                        Statut du Paiement
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                        {currentInvestissement?.StatutInvestissement?.Libelle}
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }}
                                    >
                                        Etat de la collecte
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                     {mappingStatutProjet.find((c) => c.code === currentInvestissement?.StatutCollecte)?.message}
                                    </div>
                
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={6}>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }}
                                    >
                                        Taux
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                        {currentInvestissement?.TauxInteretCitoyen} %
                                    </div>
                
                                </Grid.Column>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }}
                                    >
                                        Durée
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                        {currentInvestissement?.DureeFinancement} mois
                                    </div>
                
                                </Grid.Column>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }}
                                    >
                                        Fréquence des remboursements
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                        {currentInvestissement?.PeriodiciteEcheance?.Libelle}
                                    </div>
                
                                </Grid.Column>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }}
                                    >
                                        Première échéance
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                        {currentInvestissement?.DatePremiereEcheance ? format(new Date(currentInvestissement.DatePremiereEcheance), "dd/MM/yyyy") : ''}
                                    </div>
                
                                </Grid.Column>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }}
                                    >
                                        Dernière échéance
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                        {currentInvestissement?.DateDerniereEcheance ? format(new Date(currentInvestissement.DateDerniereEcheance), "dd/MM/yyyy") : ''}
                                    </div>
                
                                </Grid.Column>
                                <Grid.Column>
                                    <label css={{
                                            color:" #005870",
                                            fontWeight:"bold",
                                            fontSize:"14px"
                                            }}
                                    >
                                        Type Emprunt
                                     </label>
                                    <div css={{fontSize:"12px"}}>
                                        {currentInvestissement?.TypeEmprunt?.Libelle}
                                    </div>
                
                                </Grid.Column>
                            </Grid.Row>
                            
                            <div css={{margin:"0 auto"}}>
                                <div css={{display:"inline-block"}}>
                                {currentInvestissement?.StatutInvestissement?.Code === StatutInvestissement.REUSSI && (
                                    <SubmitButton
                                    label="Télécharger échéancier"
                                    name={"downloadColButton" + currentInvestissement?.Id}
                                    action={() => investmentService.getEcheancier(currentInvestissement?.Id!, loginProps!.oauth)}
                                    onActionSuccess={onDownloadSuccess}
                                    />
                                ) || (
                                    <></>
                                )}     
                                </div>
                                <div css={{display:"inline-block"}}>
                                    {currentInvestissement?.StatutInvestissement?.Code === StatutInvestissement.REUSSI && (
                                        <SubmitButton
                                        label="Télécharger contrat"
                                        name={"downloadCitContratButton" + currentInvestissement?.Id}
                                        action={() => investmentService.getContratPretCit(currentInvestissement?.Id!, loginProps!.oauth)}
                                        onActionSuccess={onDownloadSuccessContrat}
                                        />
                                    ) || (
                                        <></>
                                    )}    
                                </div>
                            </div>
                        </Grid>
                        
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                {
				<Modal
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                >
                    <Modal.Header>Remboursement</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                        <Header>Vous avez investi {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(currentInvestissement?.MontantInvesti! / 100)} EUR dans le projet {currentInvestissement?.Titre} </Header>
                        <p>
                            {textModal}
                        </p>
                        <p style={{marginBottom:"35px"}}>
                            Souhaitez-vous être remboursé ?
                        </p>
                        </Modal.Description>
                        <Modal.Actions> 
                            <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                                <SubmitButton
                                label="Confirmer le remboursement"
                                action={()=> investmentService.askForRemboursement(currentInvestissement?.Id!, loginProps.oauth)}
                                onActionSuccess={()=>{
                                    handleRetourRemboursement?.()
                                    setOpen(false)
                                }}
                                onActionFailure={()=>setOpen(false)}
                                />
                                <SubmitButton
                                    label="Annuler"
                                    onClick={()=>setOpen(false)}
                                />
                            </div>
                        </Modal.Actions>
                    </Modal.Content>
                </Modal>
                }
                {
                <Modal
                    onClose={() => setOpenAnnulationVirementModal(false)}
                    onOpen={() => setOpenAnnulationVirementModal(true)}
                    open={openAnnulationVirementModal}
                >
                    <Modal.Header>Annulation de réservation</Modal.Header>
                    <Modal.Content css={{marginBottom:"45px"}}>
                        <Modal.Description>
                        <Header>Confirmez-vous votre volonté d'annuler votre intention d'investissement par virement de {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(currentInvestissement?.MontantInvesti! / 100)} EUR dans le projet {currentInvestissement?.Titre} ?</Header>
                        </Modal.Description>
                        <Modal.Actions>
                        <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between", backgroundColor:"#ffffff !important", borderTop:"none !important"}}>
                            <SubmitButton
                                label="Je confirme l'annulation"
                                action={()=> investmentService.cancelInvestementByVirementClassiqueReservation(cancelReservationBody, loginProps.oauth)} 
                                onActionSuccess={()=>{
                                    handleCancelReservation?.()
                                    setOpenAnnulationVirementModal(false)
                                }}
                                onActionFailure={()=> {
                                    handleCancelReservationError?.()
                                    setOpenAnnulationVirementModal(false)
                                }}
                            />
                            <SubmitButton
                                label="Retour"
                                onClick={()=>setOpenAnnulationVirementModal(false)}
                            />
                        </div>
                        </Modal.Actions>
                    </Modal.Content>
                </Modal>
                }
            </Container>
        </>
    )
}