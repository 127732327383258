export interface Profil {
  ProfilCode: string;
  ProfilDenomination: string;
}


const PORTEUR_PROJET: string = "POP";
const INVESTISSEUR: string = "INV";
const BANQUE: string = "BAN";
const VILLYZ_USER: string = "VIL";

export const Profil = {
  PORTEUR_PROJET,
  INVESTISSEUR,
  BANQUE,
  VILLYZ_USER
};

export enum Collectivite {
  COMMUNE = "Commune",
  REGION = "Region",
  DEPARTEMENT = "Departement",
  EPCI = "EPCI",
  CHU = "CHU",
}
