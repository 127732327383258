import { User } from "../model/user";
import { OAuthResponse } from "../model/authentication/oauth-response";
import { AnyAction } from 'redux'
import { Action } from 'redux'
import { AuthenticationState } from "../model/state/authentication-state";
import { UserInfoResponse } from "../model/dto/response";

export const REQUEST_LOGIN = 'REQUEST_LOGIN'

interface RequestLoginAction {
  type: typeof REQUEST_LOGIN
  user: UserInfoResponse,
  authResponse: OAuthResponse
}

export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL'

interface LoginSuccessfulAction {
  type: typeof LOGIN_SUCCESSFUL
  user: UserInfoResponse,
  authResponse: OAuthResponse
}

export const LOGIN_FAILURE = 'LOGIN_FAILURE'

interface LoginFailureAction {
  type: typeof LOGIN_FAILURE
  user: UserInfoResponse,
  authResponse: OAuthResponse
}

export const LOGOUT = 'LOGOUT'

interface LogoutAction {
  type: typeof LOGOUT
  user: UserInfoResponse,
  authResponse: OAuthResponse
}

export const CHANGE_IDENTITY = 'CHANGE_IDENTITY'

interface ChangeIdentityAction {
  type: typeof CHANGE_IDENTITY
  user: UserInfoResponse
}

export const REQUEST_IDENTITY = 'REQUEST_IDENTITY'

interface RequestIdentityAction {
  type: typeof REQUEST_IDENTITY,
  user: UserInfoResponse
}

export type AuthenticationAction = RequestLoginAction | LoginFailureAction | LoginSuccessfulAction | LogoutAction | ChangeIdentityAction | RequestIdentityAction;

// export interface AuthenticationAction extends Action {
//   type: string,
//   user: User,
//   authResponse: OAuthResponse
// }

// type ActionHandler<TState, Action extends AnyAction> = (state: TState, action: Action) => TState

// const authenticateActionHandler: ActionHandler<AuthenticationState, AuthenticationAction> = (state: AuthenticationState, action: AuthenticationAction): AuthenticationState => {
//   return {
//       ...state,
//       user: action.user,
//       oauth: action.authResponse
//   }
// }