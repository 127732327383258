import React, { useEffect, useMemo } from "react"
import { useState } from "react"
import { referenceService } from "../../services/reference-service"
import { ApplicationState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { AuthenticationState } from "../../model/state/authentication-state";
import { UserInfoResponse, TypeCollectiviteListResponse, TypeInvestisseurMoralListResponse, DefaultResponse, OnboardingInvestisseurResponse, FormeJuridiqueEntrepriseListResponse } from "../../model/dto/response";
import { TypeCollectivite } from "../../model/entities/type-collectivite"
import { CollectiviteSelector } from "../generic/collectivite-selector/collectivite-selector";
import { Collectivite } from "../../model/entities/profil";
import { FormErrorHeader } from "../generic/form-error-header";
import { CheckBoxElement, CheckBoxListField } from "../generic/checkbox-list-field";
import { InputField } from "../generic/input-field";
import { Form } from "semantic-ui-react";
import { SubmitButton } from "../generic/submit-button";
import { RegisterIdentificationTypeInvestisseurBody } from "../../model/dto/body/register-identification-type-investisseur-body";
import { userService } from "../../services/authentication-redux-service";
import { AuthenticationAction, CHANGE_IDENTITY } from "../../actions/authentication-actions";
import { TypeInvestisseur } from "../../model/entities/types-investisseur";
import InformationBandeau from "../generic/information-bandeau/information-bandeau";
import { WorkflowType } from "../../model/entities/workflow-type";
import { FormeJuridiqueEntreprise } from "../../model/entities/forme-juridique-entreprise";
import { DropdownListFieldNew } from "../generic/dropdown-list-field";
import { LoadingSpinner } from "../generic/loading-spinner";

type FieldData = {
    value?: any,
    name?: string,
    error?: string,
    isValid?: boolean
};
    
type Form = {
    selectedTypeInvestisseur: FieldData,
    siretCode: FieldData,
    nomSociete: FieldData, 
    nomAssociation: FieldData,
    selectedTypeCollectivite: FieldData, 
    communeCode?: FieldData,
    epciCode?: FieldData,
    departementCode?: FieldData,
    regionCode?: FieldData,
    typeSociete: FieldData,

}

interface Props {
    isOnboarding?: boolean,
    userInfo?: UserInfoResponse,
    onSubmitSuccess: (response: Response) => void
}

export function SubscriptionInvestisseurMoraleFormIdentification({isOnboarding, onSubmitSuccess}: Props) {
    
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    const dispatch = useDispatch()

    const [submitted, setSubmitted] = useState<boolean>(false)
    const [isFormError, setIsFormError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [typeCollectiviteList, setTypeCollectiviteList] = useState<TypeCollectivite[]>([])
    const [typesInvestisseursList, setTypesInvestisseursList] = useState<TypeInvestisseur[]>([])
    const [formeJuridiqueEntrepriseList, setFormeJuridiqueEntrepriseList] = useState<FormeJuridiqueEntreprise[]>([])

    const [form, setForm] = useState<Form>({
        selectedTypeInvestisseur: {value: undefined, name: "selectedTypeInvestisseur"},
        selectedTypeCollectivite: {value: undefined, name: "selectedTypeCollectivite"},
        communeCode: {value: undefined, name: "communeCode"},
        departementCode: {value: undefined, name: "departementCode"},
        regionCode: {value: undefined, name: "regionCode"},
        epciCode: {value: undefined, name: "epciCode"},
        siretCode: {value: undefined, name: "siretCode"},
        nomSociete: {value: undefined, name: "nomSociete"},
        nomAssociation: {value: undefined, name: "nomAssociation"},
        typeSociete: {value: undefined, name: 'typeSociete'},

    })

    useEffect(() => {
        referenceService.getTypeInvestisseurMoral()
        .then((response: TypeInvestisseurMoralListResponse) => {
            const filteredTypesInvestisseurs = response.TypeInvestisseurMoralList!.filter(investisseur => investisseur.Code === "SOC"); 
            const typeParticulier: TypeInvestisseur = {Code: "PRT", Denomination: "Particulier"}
            const listTypesInvestisseurs: TypeInvestisseur[] = [...filteredTypesInvestisseurs, typeParticulier]
            setTypesInvestisseursList(listTypesInvestisseurs)
        })
    
        referenceService.getAllTypeCollectivite()
        .then((response: TypeCollectiviteListResponse) => {
            setTypeCollectiviteList(response.TypeCollectiviteList!)
        })

        referenceService.getAllFormeJuridiqueEntreprise()
        .then((response: FormeJuridiqueEntrepriseListResponse) => {
            setFormeJuridiqueEntrepriseList(response.FormeJuridiqueEntrepriseList!)
        })
    }, [])
    

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean, key?: string) => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid, key: key} };
      
        setIsFormError(false)
        setForm({...form, ...newField})
    }

    const onTypeCollectiviteChange = (value: any, field: string) => {
        setIsFormError(false)
        setForm({...form, [field]: {value: value, name: field}})
    }

    const validateMandatory = (value: any) => {
        return value && value !== "" 
    }

    const isFormValid = () => {
        const {selectedTypeCollectivite, selectedTypeInvestisseur, communeCode, departementCode, epciCode, regionCode, siretCode, nomSociete, nomAssociation} = form;

        if (selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && selectedTypeCollectivite.value === TypeCollectivite.COMMUNE && communeCode ){
            if (!validateMandatory(communeCode.value)) {
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner une commune")
            }
            return validateMandatory(communeCode.value)
        }
        
        if (selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && selectedTypeCollectivite.value === TypeCollectivite.DEPARTEMENT && departementCode){
            if (!validateMandatory(departementCode.value)) {
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner un département")
            }
            return validateMandatory(departementCode.value)
        }

        if (selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && selectedTypeCollectivite.value === TypeCollectivite.EPCI && epciCode){
            if (!validateMandatory(epciCode.value)) {
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner une EPCI")
            return validateMandatory(epciCode.value)
            }
        }

        if (selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && selectedTypeCollectivite.value === TypeCollectivite.REGION && regionCode){
            if (!validateMandatory(regionCode.value)) {
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner une région")
            }
            return validateMandatory(regionCode.value)
        }

        if (selectedTypeInvestisseur.value === TypeInvestisseur.SOCIETE && siretCode){
            if (!validateMandatory(siretCode.value) || !validateMandatory(nomSociete.value)){
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner les informations de votre société")
            }
            return validateMandatory(siretCode.value) && validateMandatory(nomSociete.value)
        }

        if (selectedTypeInvestisseur.value === TypeInvestisseur.ASSOCIATION){
            if (!validateMandatory(nomAssociation.value) || !validateMandatory(nomAssociation.value)){
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner le nom de votre association")
            }
            return validateMandatory(nomAssociation.value)
        }

        if(selectedTypeInvestisseur.value === TypeInvestisseur.PARTICULIER) {
            return true
        }

        setIsFormError(true)
        setErrorMessage("Veuillez renseigner vos informations")
        return false
    }

    const onPreSubmit = () => {
        setSubmitted(true)
    }

    const onFormNotValid = () => {
        
    }

    const onSubmitError = (response: DefaultResponse) => {
        if(!response.IsTraitementOk && response.Error) {
            setIsFormError(true)
            setErrorMessage(response.Error)
        }
    }

    const onSubmitIdentiteSuccess = (response: OnboardingInvestisseurResponse) => {
        if(response.UserInfo) {
            const action: AuthenticationAction = { type: CHANGE_IDENTITY, user: response.UserInfo }
            localStorage.setItem("user_info", JSON.stringify(response.UserInfo))
            dispatch(action)
        }
        onSubmitSuccess(response)
    }

    
    const renderCollectiviteCode = () => {

        const {selectedTypeCollectivite, selectedTypeInvestisseur, communeCode, departementCode, epciCode, regionCode} = form

        if(selectedTypeInvestisseur?.value === TypeInvestisseur.COLLECTIVITE && selectedTypeCollectivite?.value === TypeCollectivite.COMMUNE) {
            return communeCode?.value;
        }

        if(selectedTypeInvestisseur?.value === TypeInvestisseur.COLLECTIVITE && selectedTypeCollectivite?.value === TypeCollectivite.DEPARTEMENT) {
            return departementCode?.value;
        }

        if(selectedTypeInvestisseur?.value === TypeInvestisseur.COLLECTIVITE && selectedTypeCollectivite?.value === TypeCollectivite.EPCI) {
            return epciCode?.value;
        }

        if(selectedTypeInvestisseur?.value === TypeInvestisseur.COLLECTIVITE && selectedTypeCollectivite?.value === TypeCollectivite.REGION) {
            return regionCode?.value;
        }
        
        return undefined
    }

    const collectiviteCode = useMemo(() => {
        return renderCollectiviteCode()
    }, [form]);

    const returnWorflowTypeCode = () => {
        if(form.selectedTypeInvestisseur.value){
            if(form.selectedTypeInvestisseur.value === TypeInvestisseur.PARTICULIER){
                return WorkflowType.ONBOARDING_CITOYEN
            }
            else if (form.selectedTypeInvestisseur.value === TypeInvestisseur.SOCIETE){
                return WorkflowType.ONBOARDING_INVESTISSEUR_SOCIETE
            }
            else{
                return WorkflowType.ONBOARDING_INVESTISSEUR_MORAL
            }
        }
        
    }

    const body: RegisterIdentificationTypeInvestisseurBody = {
        IsSociete: form.selectedTypeInvestisseur.value === TypeInvestisseur.SOCIETE,
        SiretCode: form.siretCode.value,
        NomSociete: form.nomSociete.value,
        IsAssociation: form.selectedTypeInvestisseur.value === TypeInvestisseur.ASSOCIATION,
        NomAssociation: form.nomAssociation.value,
        CodeCollectivite: collectiviteCode,
        IsCommune: form.selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.COMMUNE,
        IsDepartement: form.selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.DEPARTEMENT,
        IsRegion: form.selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.REGION,
        IsEPCI: form.selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.EPCI,
        IsParticulier: form.selectedTypeInvestisseur.value === TypeInvestisseur.PARTICULIER,
        WorkflowTypeCode: returnWorflowTypeCode(),
        TypeSociete: form.typeSociete.value
    }
    
    return (
        <LoadingSpinner area="typeInvestisseurForm">
            <div className="form-group">
                <FormErrorHeader
                    message={errorMessage}
                    displayed={isFormError}
                />
                {!isOnboarding}
                <InformationBandeau 
                    type="information"
                    icon={{name:'info circle', size:"big", color:"blue"}}
                    message="Veuillez sélectionner le type d'investisseur que vous représentez."
                />
                <div className="form-group">
                    Je représente un(e): *
                </div>
                <CheckBoxListField
                    data={form.selectedTypeInvestisseur}
                    elements={typesInvestisseursList ? typesInvestisseursList.map(element => {
                        return {
                            key: element.Code,
                            label: element.Denomination
                        } as CheckBoxElement
                    }) : []}
                    label=""
                    vertical
                    onChange={(newKey: any, isValid?: boolean) => {
                        setForm({...form, selectedTypeInvestisseur: {value:newKey, name: "selectedTypeInvestisseurMoral"}})
                    }}
                />

                {form.selectedTypeInvestisseur.value === TypeInvestisseur.ASSOCIATION &&
                    <>
                        <Form>
                            <Form.Field>
                                <InputField 
                                    data={form.nomAssociation}
                                    label="Nom de l’association*"
                                    error="Veuillez renseigner ce champ"
                                    submitted={submitted}
                                    onChange={handleInputChange}
                                    validateField={validateMandatory}
                                />
                            </Form.Field>
                        </Form>
                    </>
                }

                {form.selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE &&
                    <>
                        <div className="form-group">
                            Type de collectivité*
                        </div>
                        <CheckBoxListField 
                            data={form.selectedTypeCollectivite}
                            elements={typeCollectiviteList ? typeCollectiviteList.map(element => {
                                return {
                                    key: element.Code,
                                    label: element.Designation
                                } as CheckBoxElement
                            }) : []}
                            label=""
                            vertical
                            onChange={(newKey: any, isValid?: boolean) => {
                                setForm({...form, selectedTypeCollectivite: {value:newKey, name: "selectedTypeCollectivite"}})
                            }}
                        />
                
                        {form.selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.COMMUNE && (
                            <CollectiviteSelector
                                type={Collectivite.COMMUNE}
                                useCodeUnique={false}
                                onChange={(value: any) => {
                                    onTypeCollectiviteChange(value, "communeCode");
                                }}
                            />
                        )}
        
                        {form.selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && 
                        form.selectedTypeCollectivite.value === TypeCollectivite.EPCI && (
                            <CollectiviteSelector
                                type={Collectivite.EPCI}
                                useCodeUnique={false}
                                onChange={(value: any) => {
                                    onTypeCollectiviteChange(value, "epciCode");
                                }}
                            />
                        )}

                        {form.selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && 
                        form.selectedTypeCollectivite.value === TypeCollectivite.DEPARTEMENT && (
                            <CollectiviteSelector
                                type={Collectivite.DEPARTEMENT}
                                useCodeUnique={false}
                                onChange={(value: any) => {
                                    onTypeCollectiviteChange(value, "departementCode");
                                }}
                            />
                        )}

                        {form.selectedTypeInvestisseur.value === TypeInvestisseur.COLLECTIVITE && 
                        form.selectedTypeCollectivite.value === TypeCollectivite.REGION && (
                            <CollectiviteSelector
                                type={Collectivite.REGION}
                                useCodeUnique={false}
                                onChange={(value: any) => {
                                    onTypeCollectiviteChange(value, "regionCode");
                                }} 
                            />
                        )} 
                    </> 
                }

{form.selectedTypeInvestisseur.value === TypeInvestisseur.SOCIETE &&
                    <>
                        <Form>
                            <Form.Field>
                                <DropdownListFieldNew
                                    label="Type de société*"
                                    field={form.typeSociete}
                                    placeholder="Type de société"
                                    error="Merci de renseigner votre type de société"
                                    datasource={formeJuridiqueEntrepriseList.map((c) => ({
                                        text: c.Denomination!,
                                        value: c.Code!,
                                    }))}
                                    onChange={(value) =>
                                        handleInputChange(
                                        { target: { value } } as any,
                                        form.typeSociete.name
                                        )
                                    }
                                    validateField={validateMandatory}
                                />
                            </Form.Field>
                       
                            <Form.Field>
                                <InputField 
                                    data={form.siretCode}
                                    label="Numéro SIRET*"
                                    type="tel"
                                    error="Veuillez renseigner ce champ"
                                    submitted={submitted}
                                    onChange={handleInputChange}
                                    validateField={validateMandatory}
                                />
                            </Form.Field>
                            <Form.Field>
                                <InputField 
                                    data={form.nomSociete}
                                    label="Nom de la société*"
                                    error="Veuillez renseigner ce champ"
                                    submitted={submitted}
                                    onChange={handleInputChange}
                                    validateField={validateMandatory}
                                />
                            </Form.Field>
                        </Form>
                    </> 
                }
                <p css={{paddingBottom : "10px"}}>*champ obligatoire</p>
                <div css={{ marginTop: "20px" }}>
                    <div className="button-bar">
                    <SubmitButton 
                            data={body}
                            label="Valider"
                            action={() => userService.registerIdentificationTypeInvestisseur(body, loginProps?.oauth?.userId, loginProps?.oauth)}
                            onPreSubmit={onPreSubmit}
                            validateForm={isFormValid}
                            onFormNotValid={onFormNotValid}
                            onActionSuccess={onSubmitIdentiteSuccess}
                            onActionFailure={onSubmitError}
                            loaderArea="typeInvestisseurForm"
                        />
                    </div>
                </div>
            </div>
       </LoadingSpinner>
    )
}
