import React from "react";
import { ExperienceInvestissementForm } from "../ecsp/experience-investissement-form";
import { OnboardingInvestisseurParticulierDocumentListForm } from "./onboarding-investisseur-particulier-document-list-form";
import { RemerciementOnboardingCitoyen } from "./remerciement-onboarding-citoyen";
import { SubscriptionAdressePostaleForm } from "./subscription-adresse-postale-form";
import { SubscriptionFormEtatCivilNew } from "./subscription-form-etat-civil";
import { WorkflowType } from "../../model/entities/workflow-type";
import { FlowStep } from "../../model/entities/flow-step";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { AuthenticationState } from "../../model/state/authentication-state";
import { OnboardingCitoyenResponse, WorkflowProcessResultResponse } from "../../model/dto/response";


type Props = {
  activeStepKey?: string;
  lastStepResponse?: OnboardingCitoyenResponse;
  onSubmitEtatCivil: (response: Response) => void,
  onSubmitAdressePostal: (response: Response) => void,
  onSubmitExperienceInvestissement: (response: Response) => void,
  onSubmitPiecesJustificative: (response: Response) => void,
  onNextStep: (response: WorkflowProcessResultResponse) => void,
  onFinishOnboarding?: () => void,
}

export function SubscriptionInvestisseurParticulierForm ({
  activeStepKey, 
  lastStepResponse: lastStepresponse,
  onSubmitEtatCivil,
  onSubmitAdressePostal, 
  onSubmitExperienceInvestissement, 
  onSubmitPiecesJustificative, 
  onNextStep
}: Props) {

  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  if(activeStepKey === FlowStep.ETAT_CIVIL) {
    return (
      <div>
        <SubscriptionFormEtatCivilNew
          isOnboarding={true}
          onSubmitSuccess={onSubmitEtatCivil}
          onSkipStep={onNextStep}
        />
      </div>
    )
  }

  if(activeStepKey === FlowStep.ADRESSE_POSTALE) {
    return (
      <div>
        <SubscriptionAdressePostaleForm
          isOnboarding={true}
          onSubmitSuccess={onSubmitAdressePostal}
          onSkipStep={onNextStep}
        />
      </div>
    )
  } 
  
  if(activeStepKey === FlowStep.EXPERIENCE_INVESTISSEMENT) {
    return (
      <div>
        <ExperienceInvestissementForm
          isOnboarding={true}
          onSubmitSuccess={onSubmitExperienceInvestissement}
          onSkipStep={onNextStep}
          workflowTypeCode={WorkflowType.ONBOARDING_CITOYEN}
          lastStepResponse={lastStepresponse}
        />
      </div>
    )
  }

  if(activeStepKey === FlowStep.PIECES_JUSTIFICATIVES) {
    return (
      <div>
        <OnboardingInvestisseurParticulierDocumentListForm
          isOnboarding={true}
          onActionSuccess={onSubmitPiecesJustificative}
          onSkipStep={onNextStep}
        />
      </div>
    )
  }

  if(activeStepKey === FlowStep.REMERCIEMENT) {
    return (
      <div>
        <RemerciementOnboardingCitoyen />
      </div>
    )
  }

  return (
    <div></div>
  )
}