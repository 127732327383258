
import { trackPromise } from "react-promise-tracker";
import { OAuthResponse } from "../model/authentication/oauth-response";
import { GetRepresentantTypeListBody } from "../model/dto/body/get-representant-type-list-body";
import { ActionVillyzProjetListResponse, AdminParamValueResponse, BranchProviderListResponse, CHUListResponse, CategorieEtablissementListResponse, CommuneListResponse, DefaultResponse, DepartementListResponse, EpciListResponse, WorkflowProcessResultResponse,  KYCErrorMessageResponse, LabelListResponse, MangoPayEventListResponse, PaysListResponse, PeriodiciteListResponse, QualiteRepresentantListResponse, QuestionListResponse, RegionListResponse, RemboursementStatutsListResponse, ReponseQuestionPropositionListResponse, SecteurListResponse, StatutInvestissementListResponse, StatutPieceJustificativeListResponse, StatutProjetListResponse, TrueLayerPaymentStatusListResponse, TypeCollectiviteListResponse, TypeEmpruntListResponse, TypeEvenementWithPalierResponse, TypeFinancementListResponse, TypeInvestisseurMoralListResponse, TypologieRemboursementListResponse,  WorkflowStepListByWorkflowTypeCodeResponse, TypeCollecteListResponse, FormeJuridiqueEntrepriseListResponse } from "../model/dto/response";
import { FichierReference } from "../model/entities/fichier-reference";
import { treatHttpResponseCode } from "./http-helper";
import { GetWorkflowStepListByWorkflowTypeCodeBody } from "../model/dto/body/get-workflow-step-list-by-workflow-code-body";
import { GetNextStepBody } from "../model/dto/body/get-next-step-body";

export const referenceService = {
    getPeriodiciteList,
    getStatutProjetList,
    getCommuneList,
    getCommuneByCode,
    getDepartementList,
    getDepartementByCode,
    getRegionList,
    getRegionByCode,
    getQualiteRepresentantList,
    getQualiteRepresentantListForTypeCollectivite,
    getAllKycErrorMessage,
    getKycErrorMessageByCode,
    getAllStatutProjet,
    getAllActionVillyzProjet,
    getAllTypologieRemboursement,
    getAllTypeFinancement,
    getAllSecteur,
    getAllLabel,
    getAllPays,
    getEpciList,
    getCHUList,
    uploadFichierReferentiel,
    getEpciByCode,
    getCHUByCode,
    getTypeEvenementWithPalier,
    getBranchProviderByProviderId,
    getAllMangoPayEvent,
    getAllStatutPieceJustificative,
    getAllQuestion,
    getAllReponseQuestionProposition,
    getRemboursementStatutsList,
    getTypeEmpruntList,
    getAllTrueLayerPaymentStatus,
    getAdminParam,
    getAllStatutInvestissement,
    getAllCategorieEtablissement,
    getAllTypeCollectivite,
    getAllDepartementList,
    getTypeInvestisseurMoral,
    getWorkflowStepListByWorkflowTypeCode,
    getNextStep,
    getAllTypeOfCollecte,
    getAllFormeJuridiqueEntreprise
};

function defaultOptions(oauth: OAuthResponse | undefined) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json'
        }
    };

    return requestOptions
}

function defaultPostOptions(oauth: OAuthResponse | undefined, body?: any) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return requestOptions
}

function getPeriodiciteList(oauth?: OAuthResponse): Promise<PeriodiciteListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(process.env.REACT_APP_BASE_URL + "/api/reference/periodicite-list", requestOptions);
}

function getStatutProjetList(oauth: OAuthResponse | undefined): Promise<StatutProjetListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(process.env.REACT_APP_BASE_URL + "/api/reference/status-projet", requestOptions);
}

function getCommuneList(clue: string, oauth?: OAuthResponse): Promise<CommuneListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/commune-list?clue=${clue}`, requestOptions);
}

function getDepartementList(clue: string, oauth?: OAuthResponse): Promise<DepartementListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/departement-list?clue=${clue}`, requestOptions);
}

function getAllDepartementList(oauth?: OAuthResponse): Promise<DepartementListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/all-departements-list`, requestOptions);
}

function getRegionList(clue: string, oauth?: OAuthResponse): Promise<RegionListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/region-list?clue=${clue}`, requestOptions);
}

function getCHUList(clue: string, oauth?: OAuthResponse): Promise<CHUListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/chu-list?clue=${clue}`, requestOptions);
}


function getQualiteRepresentantList(oauth: OAuthResponse | undefined): Promise<QualiteRepresentantListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(process.env.REACT_APP_BASE_URL + "/api/reference/qualite-representant-list", requestOptions)
}

function getQualiteRepresentantListForTypeCollectivite(oauth: OAuthResponse | undefined, body: GetRepresentantTypeListBody): Promise<QualiteRepresentantListResponse> {
    const requestOptions = defaultPostOptions(oauth, body)
    return fetch(process.env.REACT_APP_BASE_URL + "/api/reference/qualite-representant-list-for-type-collectivite", requestOptions)
}

function getCommuneByCode(code: string,  oauth?: OAuthResponse): Promise<CommuneListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/commune?id=${code}`, requestOptions)
}

function getDepartementByCode(code: string, oauth?: OAuthResponse): Promise<DepartementListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/departement?id=${code}`, requestOptions)
}

function getRegionByCode(code: string, oauth?: OAuthResponse): Promise<RegionListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/region?id=${code}`, requestOptions)
}

function getAllKycErrorMessage(oauth?: OAuthResponse) : Promise<KYCErrorMessageResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/kyc-errors`, requestOptions)
}

function getKycErrorMessageByCode(code: string, oauth?: OAuthResponse) : Promise<KYCErrorMessageResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/kyc-errors?code=${code}`, requestOptions)
}

function getAllStatutProjet(oauth?: OAuthResponse) : Promise<StatutProjetListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/statut-projet-list`, requestOptions)
}

function getAllActionVillyzProjet(oauth?: OAuthResponse) : Promise<ActionVillyzProjetListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/action-villyz-projet-list`, requestOptions)
}

function getAllTypologieRemboursement(oauth?: OAuthResponse) : Promise<TypologieRemboursementListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/typologie-remboursement-list`, requestOptions)
}

function getAllTypeFinancement(oauth?: OAuthResponse) : Promise<TypeFinancementListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/type-financement-list`, requestOptions)
}

function getAllSecteur(oauth?: OAuthResponse) : Promise<SecteurListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/secteur-list`, requestOptions)
}

function getAllLabel(oauth?: OAuthResponse) : Promise<LabelListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/label-list`, requestOptions)
}

function getAllPays() : Promise<PaysListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/pays-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<PaysListResponse>
    })
}

function getEpciList(clue: string): Promise<EpciListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/epci-list?clue=${clue}`, requestOptions)
}

function uploadFichierReferentiel(fichierRef : FichierReference, oauth: OAuthResponse | undefined ) : Promise<DefaultResponse> {
    let formData = new FormData();

    const file = fichierRef;

    formData.append("file" + 0, file.Document!);
    formData.append("codePiece" + 0, file.Code);


    formData.append("Libelle" + 0, file.Libelle);


    const requestOptions = {
    method: 'POST',
    headers: {
        'Authorization': 'Bearer ' + oauth?.access_token 
    },
    body: formData
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/${oauth!.userId}/submit-fichier-reference`, requestOptions)
    .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
    }))
}

function getEpciByCode(code: string): Promise<EpciListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/epci?id=${code}`, requestOptions)
}

function getCHUByCode(code: string): Promise<CHUListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/chu?id=${code}`, requestOptions)
}

function getTypeEvenementWithPalier(Id: number): Promise<TypeEvenementWithPalierResponse> {
    const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      };
    
      return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/evenement-with-palier-by-id/${Id}`, requestOptions)
      .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TypeEvenementWithPalierResponse>
      })
}

function getBranchProviderByProviderId(providerId: string): Promise<BranchProviderListResponse> {
    const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      };
    
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/branch-provider/${providerId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<BranchProviderListResponse>
    })
}


function getAllMangoPayEvent() : Promise<MangoPayEventListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/mango-pay-event-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<MangoPayEventListResponse>
    })
}

function getAllStatutPieceJustificative(): Promise<StatutPieceJustificativeListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/statut-piece-justif-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<StatutPieceJustificativeListResponse>
    })
}

function getAllQuestion(oauth?: OAuthResponse) : Promise<QuestionListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/question-list`, requestOptions)
}

function getAllReponseQuestionProposition(oauth?: OAuthResponse) : Promise<ReponseQuestionPropositionListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/reponse-list`, requestOptions)
}

function getRemboursementStatutsList(oauth?: OAuthResponse) : Promise<RemboursementStatutsListResponse> {
    const requestOptions = defaultOptions(oauth)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/statut-remboursement-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<RemboursementStatutsListResponse>
    })
}

function getTypeEmpruntList(etablissementId?: number) : Promise<TypeEmpruntListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/type-emprunt-list/${etablissementId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TypeEmpruntListResponse>
    })
}

function getAllTrueLayerPaymentStatus() : Promise<TrueLayerPaymentStatusListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/truelayer-payment-status`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TrueLayerPaymentStatusListResponse>
    })
}

function getAdminParam(paramKey: string) : Promise<AdminParamValueResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/admin-param/${paramKey}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<AdminParamValueResponse>
    })
}

function getAllStatutInvestissement(): Promise<StatutInvestissementListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/statut-investissement-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<StatutInvestissementListResponse>
    })
}

function getAllCategorieEtablissement(): Promise<CategorieEtablissementListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/categorie-etablissement-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<CategorieEtablissementListResponse>
    })
}

function getAllTypeCollectivite(): Promise<TypeCollectiviteListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/type-collectivite-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TypeCollectiviteListResponse>
    })
}

function getTypeInvestisseurMoral(): Promise<TypeInvestisseurMoralListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/type-investisseur-moral-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TypeInvestisseurMoralListResponse>
    })
}

function getWorkflowStepListByWorkflowTypeCode(body : GetWorkflowStepListByWorkflowTypeCodeBody): Promise<WorkflowStepListByWorkflowTypeCodeResponse>{
    const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      };
    
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/workflow-step-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<WorkflowStepListByWorkflowTypeCodeResponse>
    })
}

function getNextStep(body : GetNextStepBody): Promise<WorkflowProcessResultResponse>{
    const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      };
    
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/get-next-step`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<WorkflowProcessResultResponse>
    })
}

function getAllTypeOfCollecte(): Promise<TypeCollecteListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/type-collecte-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TypeCollecteListResponse>
    })
}

function getAllFormeJuridiqueEntreprise(): Promise<FormeJuridiqueEntrepriseListResponse> {
    const requestOptions = defaultOptions(undefined)
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/reference/forme-juridique-entreprise-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<FormeJuridiqueEntrepriseListResponse>
    })
}

