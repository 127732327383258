import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
    Button,
    Container,
    Grid,
    Header,
    Image,
    Loader,
    Card,
    Icon,
    GridColumn,
} from "semantic-ui-react";
import { ProjetListItem } from "../../../model/dto/projet-list-item";
import { PourBientotScreen } from "../../generic/c-est-pour-bientot";
import { ProjetLine } from "../../projet/projet-line";
import { section, stepper } from "../styles";
import { publicService } from "../../../services/public-service";
import { CustomUnderlinedText } from "../../generic/custom-underlined-text";
import { ProfilCode, SocialProofResponse } from "../../../model/dto/response";
import { css } from "@emotion/core";
import "./home.css";
import FeedbackSignature from "../pages-components/feedback/feedback-signature";
import RejoignezUneCommuEngagee from "../pages-components/call-to-action/rejoignez-une-commu-engagee";
import { StatutProjet } from "../../../model/entities/statut-projet";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";

const breakpoints = [576, 768, 992, 1200];
const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

type Props = {};

export function HomeV3({}: Props) {
    const [projetList, setProjetList] = useState<ProjetListItem[] | undefined>(
        undefined
    );
    const [socialProof, setSocialProof] = useState<SocialProofResponse>();
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    const navigate = useNavigate();

    useEffect(() => {
        publicService
            .getHomeProjects()
            .then((projetList) => setProjetList(projetList));
    }, []);

    useEffect(() => {
        publicService
            .getSocialProof()
            .then((socialProof) => setSocialProof(socialProof));
    }, []);

    const breakpoints = [576, 768, 992, 1200];
    const mq = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

    const investButton = css({
        fontSize: "20px !important",
        fontWeight: "bold",
        backgroundColor: "#13B9A1 !important",
        padding: "10px 34px 10px 34px !important",
        fontFamily: "raleway-bold !important",
    });

    const socialProofHeading = css({
        fontWeight: "bold",
        fontSize: "48px",
        marginBottom: "0",
    });

    const blueSection = {
        padding: "30px 28px",
        maxWidth: "90%",
        margin: "0 auto",
        backgroundColor: "#005877",
        borderRadius: "4px",
        color: "#ffffff",
    };

    const mediaDescription = {
        color: "#ffffff",
        fontSize: "14px",
        marginTop: "26px",
    };

    const underlinedHeaderConatiner = {
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
    };

    const partnersImages = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    };

    const flexContainer = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const avisContent = {
        borderTop: "none !important",
        display: "flex",
        alignItems: "flex-end",
    };

    const sectionV2 = css({
        padding: "35px 0",
        marginTop: "30px",
    });

    const isCardClickable = (projet: ProjetListItem) => {
        if(projet.StatutProjetCode === StatutProjet.A_VENIR ){
            if(loginProps?.user?.ProfilCode === ProfilCode.PORTEUR_PROJET && projet.EtablissementId === loginProps?.user?.Etablissement?.Id){
                return true
            }
            else if(loginProps?.oauth?.profilCode === ProfilCode.VILLYZ){
                return true
            }
            return false
        }
        return true
    }

    return (
        <div id="page-home" css={{ color: "#424949" }}>
            <Container css={{ marginTop: "32px" }}>
                <Image
                    src="img/home/Home_asset_1.png"
                    alt="image principale, représentant une illustration type tableau d'un village en bas d'une montagne avec un cours d'eau au milieu, et des gens sur la place et le long des berges"
                    css={{
                        borderRadius: "10px",
                        marginBottom: "20px",
                    }}
                />
            </Container>

            {/*======================== HERO SECTION =======================*/}
            <div css={{ paddingBottom: "40px" }}>
                <Container>
                    <Grid doubling stackable>
                        <Grid.Row>
                            <Grid.Column width={7}>
                                <h1
                                    css={{
                                        fontWeight: "bold",
                                        fontSize: "38px",
                                        lineHeight: "1",
                                        marginBottom: "16px",
                                        color: "#000000",
                                        fontFamily: "merriweather",
                                    }}
                                >
                                    {/* Engagez <br/>votre épargne */}
                                    Investissez votre territoire
                                </h1>
                                <Button
                                    as={NavLink}
                                    to="/projets"
                                    color="teal"
                                    css={investButton}
                                >
                                    Découvrir les projets
                                </Button>
                            </Grid.Column>
                            <Grid.Column
                                width={9}
                                css={{
                                    fontSize: "20px",
                                    color: "#000000",
                                }}
                            >
                                <p>
                                    Investissez dans les projets des
                                    collectivités, des établissements de santé
                                    et de tous ceux qui font vivre votre
                                    territoire.
                                </p>
                                <p>
                                    Valorisez votre épargne tout en contribuant
                                    au développement de nouveaux services
                                    publics.
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>

            {/*========================  Social Proof 1  SECTION =====================*/}
            <div css={section}>
                <Container>
                    <div css={blueSection}>
                        <Grid doubling stackable>
                            <Grid.Row
                                css={{ display: "flex", alignItems: "center" }}
                            >
                                <Grid.Column width={6}>
                                    <div
                                        css={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                        }}
                                    >
                                        <CustomUnderlinedText
                                            startText="Rejoignez une"
                                            underlinedText="communauté engagée"
                                            fontSize={24}
                                            offset={9}
                                            textColor="#ffffff"
                                        />
                                    </div>
                                </Grid.Column>

                                <Grid.Column width={3}>
                                    <h3 css={socialProofHeading}>
                                        +{socialProof?.TotalMembres}
                                    </h3>
                                    <p css={{ fontSize: "20px" }}>membres</p>
                                </Grid.Column>

                                <Grid.Column width={4}>
                                    <div
                                        css={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h3 css={socialProofHeading}>
                                            +
                                            {
                                                socialProof?.TotalInvestissementReussi
                                            }
                                        </h3>
                                        <p
                                            css={{
                                                fontWeight: "bold",
                                                fontSize: "40px",
                                                marginLeft: "9px",
                                            }}
                                        >
                                            M €
                                        </p>
                                    </div>
                                    <p css={{ fontSize: "20px" }}>
                                        investis pour l’avenir
                                    </p>
                                </Grid.Column>

                                <Grid.Column width={3}>
                                    <div
                                        css={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h3 css={socialProofHeading}>0</h3>
                                        <p
                                            css={{
                                                fontWeight: "bold",
                                                fontSize: "40px",
                                                marginLeft: "9px",
                                            }}
                                        >
                                            %
                                        </p>
                                    </div>
                                    <p css={{ fontSize: "20px" }}>
                                        taux de défaut*
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                    <div
                        css={{
                            color: "#005877",
                            fontSize: "14px",
                            textAlign: "right",
                            maxWidth: "90%",
                            margin: "6px auto",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            navigate("/statistiques");
                        }}
                    >
                        <p>*pour consulter nos statisques c’est par ici !</p>
                    </div>
                </Container>
            </div>

            {/*======================== AVANTAGE SECTION =====================*/}
            <div css={sectionV2}>
                <Container>
                    <Grid stackable={true}>
                        <Grid.Row>
                            <Grid.Column
                                mobile={16}
                                tablet={4}
                                computer={3}
                                className="avantage-section-illustration-column"
                            >
                                <div className="avantage-section-illustration-container-left">
                                    <Image
                                        src="img/home/Home_asset1.png"
                                        alt="logo illustrant le titre impact, montrant une main en qui des feuilles poussent en son centre "
                                    />
                                </div>
                            </Grid.Column>

                            <Grid.Column
                                mobile={12}
                                tablet={12}
                                computer={13}
                                floated="right"
                                verticalAlign="middle"
                            >
                                <h3
                                    css={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        color: "#000000",
                                        fontFamily: "raleway",
                                    }}
                                >
                                    Donner de l'impact à votre épargne
                                </h3>
                                <p css={{ fontSize: "16px", color: "#000000" }}>
                                    Investir son épargne, c’est bien. Pouvoir
                                    lui donner un impact positif, c’est mieux.
                                    Tous nos projets répondent à un besoin de
                                    territoire, dans la santé, la transition
                                    écologique ou encore l’égalité. C’est
                                    pourquoi chaque euro investi sur notre
                                    plateforme est une brique de plus pour
                                    construire les territoires de demain.
                                </p>
                            </Grid.Column>
                        </Grid.Row>

                        {/* inversement de l'ordre en version mobile */}
                        <Grid.Row className="reversed-mobile-section">
                            <Grid.Column
                                mobile={12}
                                tablet={12}
                                computer={13}
                                verticalAlign="middle"
                            >
                                <h3
                                    css={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        color: "#000000",
                                        fontFamily: "raleway",
                                    }}
                                >
                                    Reprenez le contrôle
                                </h3>
                                <p
                                    css={{
                                        fontSize: "16px",
                                        color: "#000000",
                                        paddingRight: "2px",
                                    }}
                                >
                                    Lorsqu’on décide de soutenir un projet, on a
                                    besoin d'avoir toutes les clés en main.
                                    Retrouvez toutes les informations aussi bien
                                    financières que d’impact pour prendre la
                                    bonne décision d’investissement. Puis
                                    investissez sereinement : 100% de votre
                                    paiement sera fléché vers le projet de votre
                                    choix.
                                </p>
                            </Grid.Column>

                            <Grid.Column
                                mobile={16}
                                tablet={4}
                                computer={3}
                                floated="right"
                                className="avantage-section-illustration-column"
                            >
                                <div className="avantage-section-illustration-container-right">
                                    <Image
                                        src="img/home/Home_asset2.png"
                                        alt="logo représentant un point serré levé"
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column
                                mobile={16}
                                tablet={4}
                                computer={3}
                                className="avantage-section-illustration-column"
                            >
                                <div className="avantage-section-illustration-container-left">
                                    <Image
                                        src="img/home/Home_asset3.png"
                                        alt="logo représentant un rouleau d'une vague"
                                    />
                                </div>
                            </Grid.Column>

                            <Grid.Column
                                mobile={12}
                                tablet={12}
                                computer={13}
                                floated="right"
                                verticalAlign="middle"
                            >
                                <h3
                                    css={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        color: "#000000",
                                        fontFamily: "raleway",
                                    }}
                                >
                                    Faites converger les intérêts
                                </h3>
                                <p css={{ fontSize: "16px", color: "#000000" }}>
                                    Investir dans des projets d’intérêt général,
                                    c’est aussi participer à construire des
                                    services publics efficaces, fiables et
                                    accessibles à tous et toutes. C’est pour
                                    cela que nous permettons à chaque cioyen
                                    d’agir dès 1€, selon ses moyens et ses
                                    objectifs d’épargne.{" "}
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>

            {/*======================== PROJECT SECTION =====================*/}
            <div css={sectionV2}>
                <Container>
                    <div>
                        <div css={underlinedHeaderConatiner}>
                            <CustomUnderlinedText
                                startText="Découvrez les projets de"
                                underlinedText="votre territoire"
                                fontSize={20}
                                offset={2}
                                textColor="#000000"
                            />
                        </div>
                        {projetList ? (
                            projetList.length > 0 ? (
                                <>
                                    <Card.Group
                                        centered
                                        itemsPerRow={3}
                                        doubling
                                        stackable
                                    >
                                        {projetList.map((projetItem) => (
                                            <ProjetLine
                                                key={projetItem.Id}
                                                projet={projetItem}
                                                isCardClickable={isCardClickable(projetItem)}
                                            />
                                        ))}
                                    </Card.Group>
                                </>
                            ) : (
                                <>
                                    <PourBientotScreen />
                                </>
                            )
                        ) : (
                            <Loader active inline="centered" />
                        )}
                    </div>
                    <div
                        onClick={() => {
                            navigate("/projets");
                        }}
                        css={{
                            cursor: "pointer",
                        }}
                    >
                        <div
                            css={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                marginTop: "6px",
                            }}
                        >
                            <div
                                css={{
                                    color: "#005877",
                                    fontSize: "16px",
                                    textAlign: "right",
                                    fontWeight: "bold",
                                }}
                            >
                                Découvrir toutes les opportunités
                            </div>
                            <Icon
                                name="arrow alternate circle right outline"
                                size="large"
                                css={{
                                    color: "#005877",
                                    marginLeft: "14px",
                                }}
                            />
                        </div>
                    </div>
                </Container>
            </div>

            {/*========================  Social Proof 2  SECTION =====================*/}
            <div css={sectionV2}>
                <Container>
                    <div css={blueSection}>
                        <Grid doubling stackable centered>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <div css={flexContainer}>
                                        <Image
                                            src="/img/colloc/PFP.png"
                                            alt="logo officiel de plate-forme de financement participatif agrée par l'état "
                                            css={{
                                                width: "110px",
                                                height: "110px",
                                            }}
                                        />
                                    </div>
                                </Grid.Column>

                                <Grid.Column width={13}>
                                    <p
                                        css={{
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Une plateforme agréée
                                    </p>
                                    <p css={{ fontSize: "16px" }}>
                                        Villyz est la{" "}
                                        <strong>
                                            première plateforme française
                                        </strong>{" "}
                                        agréée en tant que Prestataire de
                                        Service de Financement Participatif
                                        (PSFP) par l’
                                        <strong>
                                            Autorité des Marchés Financiers{" "}
                                        </strong>
                                        (n° FP-20221).
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Container>
            </div>

            {/*========================  Video Section =====================*/}
            <div css={sectionV2}>
                <Container>
                    <div css={underlinedHeaderConatiner}>
                        <CustomUnderlinedText
                            startText="Réconciliez votre épargne avec"
                            underlinedText="l’intérêt général"
                            fontSize={20}
                            offset={5}
                            textColor="#000000"
                        />
                    </div>
                    <Grid centered doubling stackable>
                        <Grid.Row>
                            <Grid.Column
                                width={10}
                                css={{ minHeight: "300px" }}
                            >
                                <iframe
                                    css={{ borderRadius: "4px" }}
                                    width="100%"
                                    height="100%"
                                    src="https://www.youtube.com/embed/juIrl7EyAOU"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <div css={{ marginLeft: "10px" }}>
                                    <p
                                        css={{
                                            fontSize: "16px",
                                            color: "#000",
                                        }}
                                    >
                                        Engagez votre épargne dès 1€ en quelques
                                        clics.
                                    </p>
                                    <div css={stepper}>
                                        <ul
                                            css={{
                                                color: "#000000",
                                                fontSize: "16px",
                                            }}
                                        >
                                            <li>Choisissez votre projet​</li>
                                            <li>
                                                Investissez le montant de votre
                                                choix
                                            </li>
                                            <li>
                                                Récoltez les fruits de votre
                                                épargne​
                                            </li>
                                        </ul>
                                    </div>
                                    <div css={flexContainer}>
                                        <Button
                                            as={NavLink}
                                            to="/projets"
                                            color="teal"
                                            css={investButton}
                                        >
                                            Investir
                                        </Button>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>

            {/*========================  Avis Section =====================*/}
            <div css={sectionV2}>
                <Container>
                    <div>
                        <div css={underlinedHeaderConatiner}>
                            <CustomUnderlinedText
                                startText="Vous êtes nos meilleurs"
                                underlinedText="ambassadeurs"
                                fontSize={20}
                                offset={6}
                                textColor="#000000"
                            />
                        </div>
                    </div>
                    <Grid centered doubling stackable>
                        <Grid.Row columns={3}>
                            <GridColumn>
                                <div css={flexContainer}>
                                    <Card
                                        css={{
                                            height: "353px !important",
                                            [mq[2]]: {
                                                height: "370px !important",
                                            },
                                            padding: "5px !important",
                                        }}
                                    >
                                        <Card.Content>
                                            <Card.Description>
                                                <p
                                                    css={{
                                                        color: "#000000",
                                                        fontSize: "16px",
                                                        fontStyle: "italic",
                                                        marginBottom:
                                                            "25px !important",
                                                        [mq[2]]: {
                                                            marginBottom:
                                                                "auto !important",
                                                        },
                                                    }}
                                                >
                                                    «&nbsp;J'ai découvert le
                                                    concept de l'investissement
                                                    citoyen par la presse. Le
                                                    site est bien clair et le
                                                    paiement facile et sécurisé.{" "}
                                                    <br /> J'ai eu rapidement
                                                    quelqu'un au téléphone pour
                                                    répondre à toutes mes
                                                    questions.&nbsp;»
                                                </p>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content css={avisContent}>
                                            <FeedbackSignature
                                                name="Ronan"
                                                genre="homme"
                                                src="/img/avatars/Témoignage1.png"
                                            />
                                        </Card.Content>
                                    </Card>
                                </div>
                            </GridColumn>
                            <GridColumn>
                                <div css={flexContainer}>
                                    <Card
                                        css={{
                                            height: "353px !important",
                                            [mq[2]]: {
                                                height: "370px !important",
                                            },
                                            padding: "5px !important",
                                        }}
                                    >
                                        <Card.Content>
                                            <Card.Description>
                                                <p
                                                    css={{
                                                        color: "#000000",
                                                        fontSize: "16px",
                                                        fontStyle: "italic",
                                                    }}
                                                >
                                                    «&nbsp;Nos investissements
                                                    ont un impact sur notre
                                                    avenir, aussi le concept
                                                    d’investissement citoyen est
                                                    un excellent moyen de
                                                    s’impliquer. La plateforme
                                                    Villyz est très réactive,
                                                    répond rapidement aux
                                                    questions qu’on peut se
                                                    poser.&nbsp;»
                                                </p>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content css={avisContent}>
                                            <FeedbackSignature
                                                name="Laurence"
                                                genre="femme"
                                                src="/img/avatars/Témoignage2.png"
                                            />
                                        </Card.Content>
                                    </Card>
                                </div>
                            </GridColumn>
                            <GridColumn>
                                <div css={flexContainer}>
                                    <Card
                                        css={{
                                            height: "353px !important",
                                            [mq[2]]: {
                                                height: "370px !important",
                                            },
                                            padding: "5px !important",
                                        }}
                                    >
                                        <Card.Content>
                                            <Card.Description>
                                                <p
                                                    css={{
                                                        color: "#000000",
                                                        fontSize: "16px",
                                                        fontStyle: "italic",
                                                    }}
                                                >
                                                    «&nbsp;C'est notre volonté
                                                    d'aller dans le sens d'un
                                                    meilleur environnement et de
                                                    la protection de notre
                                                    planète. Je suis
                                                    reconnaissant pour l'accueil
                                                    et la disponibilité dont
                                                    Villyz a fait preuve lors de
                                                    la finalisation de notre
                                                    investissement. &nbsp;»
                                                </p>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content css={avisContent}>
                                            <FeedbackSignature
                                                name="Michel"
                                                genre="homme"
                                                src="/img/avatars/Témoignage3.png"
                                            />
                                        </Card.Content>
                                    </Card>
                                </div>
                            </GridColumn>
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>

            {/*======================== MEDIA SECTION =====================*/}
            <div css={sectionV2}>
                <Container>
                    <div css={blueSection}>
                        <Header
                            as="h2"
                            css={{
                                fontWeight: "bold",
                                color: "#ffffff !important",
                                marginBottom: "30px",
                                fontSize: "20px",
                                textAlign: "center",
                                fontFamily: "raleway !important",
                            }}
                        >
                            Ils parlent de nous
                        </Header>
                        <Grid doubling stackable centered>
                            <Grid.Row columns={4}>
                                <Grid.Column>
                                    <div>
                                        <a
                                            href="https://www.radiofrance.fr/franceinter/podcasts/le-zoom-de-la-redaction/le-zoom-de-la-redaction-du-mardi-24-mai-2022-1166400"
                                            target="_blank"
                                            aria-label="lien vers france inter"
                                        >
                                            <Image
                                                className="left"
                                                src="/img/colloc/Colloc_asset_13.png"
                                                alt="logo de france inter"
                                                css={{
                                                    height: "56px",
                                                    width: "auto",
                                                }}
                                            />
                                        </a>
                                        <p css={mediaDescription}>
                                            "Villyz propose différents projets
                                            d’intérêt général à des petites
                                            collectivités et sécurise les
                                            placements. Ou comment mieux
                                            impliquer dans la vie citoyenne
                                            locale les habitants."
                                        </p>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div>
                                        <div
                                            css={{
                                                display: "flex",
                                                alignItems: "center",
                                                height: "56px",
                                                width: "auto",
                                            }}
                                        >
                                            <a
                                                href="https://www.leparisien.fr/haute-vienne-87/deserts-medicaux-pour-financer-le-pole-sante-des-citoyens-deviennent-banquiers-de-la-commune-11-05-2022-KNBK27QG6JHFLAYWLHROMZ3YLE.php"
                                                target="_blank"
                                                aria-label="lien vers Le Parisien"
                                            >
                                                <Image
                                                    className="left"
                                                    src="/img/colloc/parisien.png"
                                                    alt="logo du journal le Parisien"
                                                    css={{
                                                        width: "135px",
                                                    }}
                                                />
                                            </a>
                                        </div>
                                        <p css={mediaDescription}>
                                            "Une démarche différente des autres
                                            campagnes de financement
                                            participatif : il s’agit là non pas
                                            de dons mais bien d’un prêt."
                                        </p>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div>
                                        <div
                                            css={{
                                                display: "flex",
                                                alignItems: "center",
                                                height: "56px",
                                                width: "auto",
                                            }}
                                        >
                                            <a
                                                href="https://www.finmag.fr/blog/villyz-interview/"
                                                target="_blank"
                                                aria-label="lien vers Finmag"
                                            >
                                                <Image
                                                    className="left"
                                                    src="/img/home/Home_asset_14.svg"
                                                    alt="logo du journal Finmag"
                                                    css={{
                                                        width: "152px",
                                                    }}
                                                />
                                            </a>
                                        </div>
                                        <p css={mediaDescription}>
                                            "Villyz permet aux citoyens de
                                            s’impliquer directement et de savoir
                                            exactement où va l’argent et comment
                                            il est utilisé."
                                        </p>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div>
                                        <div
                                            css={{
                                                display: "flex",
                                                alignItems: "center",
                                                height: "56px",
                                                width: "auto",
                                            }}
                                        >
                                            <a
                                                href="https://www.lefigaro.fr/social/haute-vienne-une-commune-sollicite-ses-habitants-pour-financer-la-renovation-d-une-maison-de-sante-20220610"
                                                target="_blank"
                                                aria-label="lien vers Le Figaro"
                                            >
                                                <Image
                                                    className="left"
                                                    src="/img/colloc/figaro.png"
                                                    alt="logo du journal le Figaro"
                                                    css={{
                                                        width: "155px",
                                                    }}
                                                />
                                            </a>
                                        </div>
                                        <p css={mediaDescription}>
                                            "46% des investisseurs pour la
                                            maison de santé de Glanges sont des
                                            femmes, un chiffre inédit puisque
                                            seuls 29% des femmes investissent en
                                            général."
                                        </p>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Container>
            </div>

            {/*========================  Partners Section =====================*/}
            <div css={sectionV2}>
                <Container>
                    <div
                        css={{
                            maxWidth: "90%",
                            padding: "0px 32px",
                            margin: "0 auto",
                        }}
                    >
                        <div css={underlinedHeaderConatiner}>
                            <CustomUnderlinedText
                                startText="Nos partenaires"
                                underlinedText="au service des territoires"
                                fontSize={20}
                                offset={6}
                                textColor="#000000"
                            />
                        </div>

                        <p
                            className="text-left"
                            css={{ fontSize: "16px", color: "#000000" }}
                        >
                            Être la première plateforme prestataire de services
                            de financement participatif dédiée aux collectivités
                            et aux projets de territoires, c’est être référencée
                            par des acteurs bancaires reconnus partout en France
                            qui peuvent vous parler de l’investissement citoyen.
                        </p>
                        <Grid doubling stackable centered>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <div css={partnersImages}>
                                        <div
                                            style={{
                                                width: "85px",
                                                height: "74px",
                                            }}
                                        >
                                            <Image
                                                src="/img/colloc/banque-territoires.png"
                                                alt="logo de la banque des territoires"
                                                css={{
                                                    objectFit: "contain",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div css={partnersImages}>
                                        <Image
                                            src="/img/colloc/caisse-epargne.png"
                                            alt="logo de la caisse d'épargne de Midi-Pyrénées"
                                            css={{
                                                width: "198px",
                                                height: "68px",
                                            }}
                                        />
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div css={partnersImages}>
                                        <Image
                                            src="/img/colloc/credit-agricole.png"
                                            alt="logo du crédit agricole des savoie"
                                            css={{
                                                width: "83px",
                                                height: "78px",
                                            }}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Container>
            </div>

            {/*========================  Call to Action Section =====================*/}
            <div css={{ marginTop: "50px", marginBottom: "80px" }}>
                <RejoignezUneCommuEngagee width={90} />
            </div>
        </div>
    );
}
