import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Form } from "semantic-ui-react"
import { LabelListResponse, SecteurListResponse, WorkflowProcessResultResponse } from "../../../model/dto/response"
import { Label } from "../../../model/entities/label"
import { Secteur } from "../../../model/entities/secteur"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { referenceService } from "../../../services/reference-service"
import { ApplicationState } from "../../../store"
import { CheckBoxElement, CheckBoxListField } from "../../generic/checkbox-list-field"
import { InputField } from "../../generic/input-field"
import { SubmitButton } from "../../generic/submit-button"
import { projetService } from "../../../services/projet-service"
import { LabelProjet } from "../../../model/entities/label-projet"
import { SecteurProjet } from "../../../model/entities/secteur-projet"
import Projet from "../../../model/projet"
import { FormErrorHeader } from "../../generic/form-error-header"
import { SimpleButton } from "../../generic/simple-button"
import { ProjetCreationWorkflow } from "../../../model/entities/projet-creation-workflow"
import { ProjetGeneralInfosBody } from "../../../model/dto/body/projet-general-infos-body"
import { WorkflowType } from "../../../model/entities/workflow-type"
import { FlowStep } from "../../../model/entities/flow-step"
import { TypeCollecte } from "../../../model/entities/type-collecte"

type Props = {
    loginProps?: AuthenticationState,
    projet?: Projet,
    labelProjetList?: LabelProjet[],
    secteurProjetList?: SecteurProjet[],
    isCreating?: boolean,
    workflowTypeCode?: string,
    isPageDon?: boolean,
    onSubmitSuccess: (response: Response) => void,
    onSkipStep?: (response: WorkflowProcessResultResponse) => void,
}

type FieldData = {
    value?: any,
    name?: string,
    isValid?: boolean
}

type Form = {
    titre: FieldData,
    descriptionCourt: FieldData,
    secteurCodeList: FieldData,
    labelCodeList: FieldData,
    labelAutre: FieldData
}

function InfosGeneralesProjetForm({loginProps, projet, labelProjetList, secteurProjetList, isCreating, workflowTypeCode, isPageDon, onSubmitSuccess, onSkipStep}: Props) {
    const [form, setForm] = useState<Form>({
        titre: {value: projet ? projet.Titre : '', name:'titre', isValid: true},
        descriptionCourt: {value: projet ? projet.DescriptionCourt : '', name: 'descriptionCourt', isValid: true},
        secteurCodeList: {value: secteurProjetList ? secteurProjetList.map(secteur => {return secteur.SecteurCode}) : [], name: 'secteurCodeList', isValid: true},
        labelCodeList: {value: labelProjetList ? labelProjetList.map(label => {return label.LabelCode}) : [], name: 'labelCodeList', isValid: true},
        labelAutre: {value: projet ? projet.LabelAutre : '', name: 'labelAutre', isValid: true}
    })

    const [secteurList, setSecteurList] = useState<Secteur[]>([])
    const [labelList, setLabelList] = useState<Label[]>([])

    const [isFormError, setIsFormError] = useState(false)

    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const validateMandatory = (value: any): boolean => {
        return value && value !== ""
    }

    const validateTitre = (value: string): boolean => {
        return validateMandatory(value) && value.length <= 50
    }

    const validateDescriptionCourt = (value: any): boolean => {
        return validateMandatory(value) && value.length <= 100
    }

    const validateCheckboxList = (keys: any[]): boolean => {
        return keys.length !== 0
    }

    useEffect(() => {
        referenceService.getAllSecteur(loginProps?.oauth)
        .then(response => {
            return response.json() as Promise<SecteurListResponse>
        })
        .then(response => {
            if(response.IsTraitementOk) {
                setSecteurList(response.SecteurList!)
            }
        })

        referenceService.getAllLabel(loginProps?.oauth)
        .then(response => {
            return response.json() as Promise<LabelListResponse>
        })
        .then(response => {
            if(response.IsTraitementOk) {
                setLabelList(response.LabelList!)
            }
        })
    }, [])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
      
        setForm({ ...form,  ...newField});
        setIsFormError(false)
    }

    const isFormValid = (): boolean => {
        return validateTitre(form.titre.value)
            && validateDescriptionCourt(form.descriptionCourt.value)
            && validateCheckboxList(form.secteurCodeList.value)
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
    }

    const onPreSubmit = () : void => {
        setIsFormSubmitted(true)
    }

    const onSubmitError = (): void => {

    }

    const body: ProjetGeneralInfosBody = {
        Titre: form.titre.value,
        DescriptionCourt: form.descriptionCourt.value,
        LabelCodeList: form.labelCodeList.value.map((label: any) => label),
        SecteurCodeList: form.secteurCodeList.value.map((secteur: any) => secteur),
        WorkflowTypeCode: workflowTypeCode,
        IsPageDonCombine: isPageDon && projet?.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON
    }

    const nextInfosGeneralStepKey = () => {
        const body = {
            WorkflowTypeCode : workflowTypeCode ?? '',
            CurrentFlowStep : FlowStep.INFORMATIONS_GENERALES,
            RessourceId:  projet?.Id!,
            IsSkiped: true
        }
        return referenceService.getNextStep(body).then(response => {
            onSkipStep && onSkipStep(response)})
    }

    return (
        <Form>
            <FormErrorHeader 
                message="Veuillez corriger les champs en erreur"
                displayed={isFormError}
            />
            <Form.Field width={16}>
                <InputField 
                    data={form.titre}
                    label="Titre du projet"
                    submitted={isFormSubmitted}
                    error="Merci de renseigner le titre du projet en 50 caractères maximum"
                    onChange={(e) => handleInputChange(e, form.titre.name)}
                    validateField={validateTitre}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.descriptionCourt}
                    label="Description de votre projet en une phrase"
                    submitted={isFormSubmitted}
                    error="Merci de renseigner une description de votre projet en 100 caractères maximum"
                    onChange={(e) => handleInputChange(e, form.descriptionCourt.name)}
                    validateField={validateDescriptionCourt}
                />
            </Form.Field>
            <Form.Field width={16}>
                <CheckBoxListField 
                    data={form.secteurCodeList}
                    label="Domaine(s) concerné(s)"
                    submitted={isFormSubmitted}
                    elements={secteurList && secteurList.map(secteur => {
                        return {
                            key: secteur.Code,
                            label: secteur.Libelle
                        } as CheckBoxElement
                    }) || []}
                    multiple
                    onChange={(key: string, isChecked?: boolean) => {
                        if(isChecked) {
                            setForm({...form, secteurCodeList: {value:[...form.secteurCodeList.value, key], name: 'secteurCodeList', isValid: true}})
                        }
                        else {
                            const { value } = form.secteurCodeList
                            const index = value.indexOf(key)
                            if(index !== -1) {
                                value.splice(index, 1)
                            }
                            setForm({...form, secteurCodeList: {value: value, name: 'secteurCodeList', isValid: value.length !== 0}})
                        }
                    }}
                    validateFieldMultiple={validateCheckboxList}
                    error="Merci de renseigner la typologie de votre projet"
                />
                <CheckBoxListField 
                    data={form.labelCodeList}
                    label="Labels que vous souhaitez afficher lors de votre levée de fonds"
                    elements={labelList && labelList.map(label => {
                        return {
                            key: label.Code,
                            label: label.Libelle
                        } as CheckBoxElement
                    }) || []}
                    multiple
                    vertical
                    onChange={(key: string, isChecked?: boolean) => {
                        if(isChecked) {
                            setForm({...form, labelCodeList: {value:[...form.labelCodeList.value, key], name: 'labelCodeList', isValid: true}})
                        }
                        else {
                            const { value } = form.labelCodeList
                            const index = value.indexOf(key)
                            if(index !== -1) {
                                value.splice(index, 1)
                            }
                            setForm({...form, labelCodeList: {value: value, name: 'labelCodeList', isValid: value.length !== 0}})
                        }
                    }}
                />
                {form.labelCodeList.value.includes(Label.AUTRE) && (
                    <Form.Field>
                        <InputField
                            data={form.labelAutre}
                            label="Désignation label"
                            onChange={handleInputChange}
                        />
                    </Form.Field>
                )}
                {form.labelCodeList.value.length !== 0 && (
                    <div css={{
                        marginTop: '20px',
                        fontSize: '13px'
                    }}>
                        Notre équipe se rapprochera de vous pour la documentation des labels
                    </div>
                )}
                <div css={{
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }} 
                className="button-bar">
                    <SubmitButton 
                        data={body}
                        label="Valider"
                        action={() => projetService.saveProjetInfosGenerales(body, projet?.Id, loginProps?.oauth)}
                        onActionSuccess={onSubmitSuccess}
                        onActionFailure={onSubmitError}
                        onFormNotValid={onFormNotValid}
                        validateForm={isFormValid}
                        onPreSubmit={onPreSubmit}
                    />
                    {isCreating && (
                        <SimpleButton 
                            label="Passer cette étape"
                            onClick={() => nextInfosGeneralStepKey()}
                        />
                    )}
                </div>
            </Form.Field>
        </Form>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedInfosGeneralesProjetForm = connect(mapStateToProps, null)(InfosGeneralesProjetForm);
export { ConnectedInfosGeneralesProjetForm as InfosGeneralesProjetForm };