import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import { Grid, Icon } from "semantic-ui-react";
import { InvestResponse } from "../../model/dto/response";
import { AuthenticationState } from "../../model/state/authentication-state";
import { investmentService } from "../../services/investment-service";
import { ApplicationState } from "../../store";
import { SocialMediaShareBar } from "../projet/social-media-share-bar";

type Props = {
    loginProps?: AuthenticationState,
    transactionId: number,
    mandateId?: number,
    isDirectDebit: boolean
}

function RemerciementDebouclage({loginProps, transactionId, mandateId, isDirectDebit}: Props) {
    const [investResponse, setInvestResponse] = useState({})

    return (
        <Grid stackable>
            <Grid.Row centered>
                <Grid.Column width={10}>
                <div css={{
                    display: "block",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginTop: "40px"
                }}
                >
                    <div css={{marginBottom: "20px"}}>
                        Votre projet est désormais financé et les remboursements par prélèvement automatiques programmés : toutes nos félicitations pour cette réussite !
                    </div>
                    <div
                        css={{marginBottom: "20px"}}
                    >
                        Vous pouvez consulter à tout moment les informations concernant les remboursements de ce projet sur votre <a href="/dashboard/collectivite">Tableau de bord</a>. 
                    </div>
                    <div css={{marginBottom: "20px"}}>
                        Nos équipes continuent d'être à votre écoute via <a href="mailto:collectivites@villyz.fr">collectivites@villyz.fr</a> pour répondre à vos questions et vous accompagner dans l’utilisation de la place publique dédiée au projet.
                    </div>
                    {/* <div>
                        <SocialMediaShareBar
                            url={`http://villyz.fr/`}
                            text={`Projet Villyz`}
                        />
                    </div> */}
                </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedRemerciementDebouclage = connect(mapStateToProps, null)(RemerciementDebouclage);
export { ConnectedRemerciementDebouclage as RemerciementDebouclage };