import React, { useEffect, useMemo, useRef } from "react";
import { AuthenticationState } from "../../model/state/authentication-state";
import { SimpleButton } from "../generic/simple-button";
import {
  UserInfoResponse,
  OnboardingCitoyenResponse,
  MultipleFileRegistrationResponse,
} from "../../model/dto/response";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { referenceService } from "../../services/reference-service";
import { Container, Grid, Header, Icon, Modal, Segment } from "semantic-ui-react";
import { EditableBlock } from "../generic/editable-block/editable-block";
import { SubscriptionAdressePostaleForm } from "../login/subscription-adresse-postale-form";
import { SubscriptionFormEtatCivilNew } from "../login/subscription-form-etat-civil";
import { ProfilCompletionHeader } from "./profil-completion-header";
import { userService } from "../../services/authentication-redux-service";
import { TypePieceByProfil } from "../../model/entities/type-piece-by-profil";
import { PieceJustifLine } from "./piece-justif-line";
import { UserPieceJustificative } from "../../model/entities/user-piece-justificative";
import { Pays } from "../../model/entities/pays";
import { LoadingSpinner } from "../generic/loading-spinner";
import { OnboardingStatus } from "../../model/entities/onboarding-status";
import * as isLeapYear from "dayjs/plugin/isLeapYear"; // import plugin
import { trackPromise } from "react-promise-tracker";
import { useState } from "react";
import { SubmitButton } from "../generic/submit-button";
import { ExperienceInvestissementForm } from "../ecsp/experience-investissement-form";
import { CapaciteInvestissementForm } from "../ecsp/capacite-investissement-form";
import { AuthenticationAction } from "../../actions/authentication-actions";
import { CHANGE_IDENTITY } from "../../actions/authentication-actions";
import { StatutPieceJustificative } from "../../model/entities/statut-piece-justificative";
import { OnboardingInvestisseurParticulierDocumentListForm } from "../login/onboarding-investisseur-particulier-document-list-form";
import InformationBandeau from "../generic/information-bandeau/information-bandeau";
import { ResendValidationEmailBody } from "../../model/dto/body/resend-validation-email-body";


var dayjs = require("dayjs");
dayjs.extend(isLeapYear); // use plugin
dayjs.locale("fr-ch"); // use locale

interface Props {
}

export function ProfilPersonnePhysiqueDetail({}: Props) {
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
  const dispatch = useDispatch()

  const [userInfo, setUserInfo] = useState<UserInfoResponse>()
  const [typePiecesJustifList, setTypePiecesJustifList] = useState<TypePieceByProfil[]>([])
  const [userPieceJustifList, setUserPieceJustifList] = useState<UserPieceJustificative[]>([])
  const [paysList, setPaysList] = useState<Pays[]>([])
  const [sent, setSent] = useState(false)
  const [reload, setReload] = useState(true)
  const [score, setScore] = useState(0)
  const [isValidateModalOpen, setIsValidateModalOpen] = useState(false)
  const [validateErrorMessage, setValidateErrorMessage] = useState(false)
  const [openPopUpMessage, setOpenPopUpMessage] = useState(false)

  // action de scroll vers le bouton valider les modifications
  const validateModificationBannerRef = useRef<HTMLDivElement>(null)
  const scrollToValidateBanner =  () => {
    if(validateModificationBannerRef.current){
      validateModificationBannerRef.current.scrollIntoView({behavior: 'smooth', block: 'center'}) 
    }
  } 

  useEffect(() => {
    if(reload) {
      trackPromise(userService.getUserInfo(loginProps?.oauth?.userId, loginProps?.oauth)
      .then(response => {
        setUserInfo(response)
      }))
  
      trackPromise(referenceService.getAllPays()
      .then(response => {
        if(response.IsTraitementOk) {
          setPaysList(response.PaysList!)
        }
      }))
  
      trackPromise(userService.getTypePieceForUser(loginProps?.oauth?.userId, loginProps?.oauth)
      .then(response => {
          if(response.IsTraitementOk) {
              setTypePiecesJustifList(response.TypePieceList!)
          }
      }))
  
      trackPromise(userService.getUserPiecesJustifList(loginProps?.oauth?.userId, loginProps?.oauth)
      .then(response => {
          if(response.IsTraitementOk) {
              setUserPieceJustifList(response.UserPieceJustifList ?? response.EtablissementPieceJustifList ?? [])
          }
      }))

      trackPromise(userService.getResultatTestConnaissance(loginProps?.oauth?.userId, loginProps?.oauth)
      .then(response => {
        setScore(response.Score!);
      }))

      setReload(false)
    }
  }, [reload])
  
  const daysLeft = (date: Date) =>{
    let difference = new Date(date.getFullYear() + 2, date.getMonth(), date.getDate() - 1).getTime() - Date.now();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  }

  const body : ResendValidationEmailBody = {
    IsClassique : true
  }

  const adresseMail = () => {
    return (
      <div>
        <div className="form-group">
          {userInfo?.Mail} : {!userInfo?.IsMailValid && "non"} validée
        </div>
        <div className="button-bar">
          {!userInfo?.IsMailValid && !sent &&  (
            <>
              <SimpleButton label="Renvoyer un email de validation" onClick={() => 
                userService.authenticatedAction(loginProps?.oauth).resendValidationEmail(body, loginProps?.user?.UserId).then(
                  (response) => {
                    if (response.IsTraitementOk) {
                      setSent(true)
                      setOpenPopUpMessage(true)
                    }
                  }
                )
              } />
            </>
          )}
          {openPopUpMessage && sent? 
            <div 
              css={{
                position:"relative", 
                backgroundColor: '#DFF0D8', 
                color: '#3C763D', 
                fontWeight: 'bold', 
                borderRadius: '4px',
                padding: '1rem 1.45rem',
                display:"flex", 
                flexDirection:"row", 
                alignItems:"center",
              }}
            >
              <div 
                onClick={() => {
                  setOpenPopUpMessage(false)
                  setSent(false)
                }}
                css={{
                  position: "absolute", 
                  right:"3px", 
                  top:"2px", 
                  cursor:"pointer"
                }}>
                  <Icon name="times" css={{color: '#3C763D !important', "&:hover": {color: "#71797E !important"}}} size="small"></Icon>
              </div>

              <div css={{flexBasis: "4%", alignSelf:"center", marginLeft:"-8px"}}>
                <Icon name='check' size='large' css={{color: '#3C763D'}}/>
              </div>
                
              <div css={{flexBasis: "96%"}}>
                <p css={{color: '#3C763D', fontWeight:"bold"}}>
                  Un nouvel email vient de vous être envoyé (pensez à vérifier vos spams)
                </p>
              </div>
            </div>
            :
            <div></div>
          }
        </div>
      </div>
    );
  }

  const etatCivil = () => {
    return (
      <div>
        <div>Prénom : {userInfo?.Prenom}</div>
        <div>Nom : {userInfo?.Nom}</div>
        <div>
          Date de naissance :{" "}
          {userInfo?.DateNaissance && userInfo?.DateNaissance !== null &&
            dayjs(userInfo?.DateNaissance).format("DD/MM/YYYY")}
        </div>
        <div>Résidence fiscale : {userInfo?.IsResidentFrancais ? "Française" : "Non renseignée"}</div>
        <div>Nationalité : {paysList ? paysList.find(p => p.Code === userInfo?.Nationalite)?.Nom : ""}</div>
        <div>Civilité : {userInfo?.Civilite}</div>
        <div>Lieu de naissance : {userInfo?.CommuneDesignationNaissance !== "" && userInfo?.CommuneDesignationNaissance !== undefined
         ? 
         userInfo?.CommuneDesignationNaissance 
         : userInfo?.NomDeCommuneEtrangere}
        </div>
        <div>Pays de naissance : {userInfo?.PaysDeNaissance}</div>
      </div>
    );
  }

  const adressePostale = () => {
    return (
      <div>
        <div
          css={{
            marginBottom: "20px",
          }}
        >
          <div>
            {userInfo?.LibelleVoie && userInfo?.LibelleVoie  || "Non renseignée"}
          </div>
          {userInfo?.ComplementAdresse && (
            <div>{userInfo.ComplementAdresse}</div>
          )}
          <div
            css={{
              display: "inline-block",
            }}
          >
            {userInfo?.CodePostal}
          </div>
          <div
            css={{
              display: "inline-block",
              marginLeft: "30px",
            }}
          >
            {userInfo?.CommuneDesignation}
          </div>
        </div>
      </div>
    );
  }

  const piecesJustificatives = () => {
    return (
      <div>
        <ul
          css={{
            padding: "0",
            fontFamily: "Helvetica",
          }}
        >
          {userPieceJustifList &&
            userPieceJustifList?.map((userPiece) => {
              return (
                <PieceJustifLine
                  typePiece={typePiecesJustifList?.find(
                    (type) => type.Id === userPiece.TypePieceByProfilId
                  )}
                  pieceInfo={userPiece}
                  isReloading = {userInfo?.OnboardingStatus?.Code === OnboardingStatus.ERREUR && userPieceJustifList[0]?.StatutPieceCode === StatutPieceJustificative.TRANSMISE}
                />
              );
            })}
        </ul>
      </div>
    );
  }

  const experienceInvestissement = () => {
    return (
      <div
          css={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center",
            marginTop:"15px",
            borderTop: "1px solid rgba(85,99,127,0.2)",
            padding: "10px"
          }}
        >
          {
            !userInfo?.IsExperienceInvestissement || daysLeft(new Date(userInfo.DateExperienceInvestissement!)) < 0
            ?
            <>
                <p css={{width:"70%"}}>
                  Répondre aux questions nous permettra de mieux vous guider dans vos investissements.
                </p>
                <Icon
                    name="question circle"
                    className="last-input"
                    size="huge"
                    color="blue"
                    css={{float: "right"}}
                />
            </>
            :
            <>
              <p>
                A renouveler dans {daysLeft(new Date(userInfo?.DateExperienceInvestissement!))} Jours
              </p>
              <Icon
                  name="check circle"
                  className="last-input"
                  size="huge"
                  color="green"
              />
            </>
          }
      </div>
    );
  }

  const capaciteInvestissement = () => {
    return (
     <div
          css={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center",
            marginTop:"15px",
            borderTop: "1px solid rgba(85,99,127,0.2)",
            padding: "10px"
          }}
        >
          {
            userInfo?.ValeurPatrimonialeNette 
            ?
              <>
                <p>
                  Votre capacité d'investisement est de : {userInfo.ValeurPatrimonialeNette / 10} €.
                </p>
                <Icon
                    name="check circle"
                    className="last-input"
                    size="huge"
                    color="green"
                />
              </>
            :
              <>
                <p>
                  Simuler et nous transmettre votre capacité d'investissement nous permettra de mieux nous guider.
                </p>
                <Icon
                    name="question circle"
                    className="last-input"
                    size="huge"
                    color="blue"
                />
              </>
          }
      </div>
    );
  }

  const updateUserInfo = (userInfo: UserInfoResponse) => {
    setUserInfo(userInfo)
    const action: AuthenticationAction = { type: CHANGE_IDENTITY, user: userInfo }
    dispatch(action)
    localStorage.setItem("user_info", JSON.stringify(userInfo));
  }

  const onValidateCorrectionRefused = () => {
    setIsValidateModalOpen(false)
    setValidateErrorMessage(true)
  }

  // déclenchement du scroll
  const triggeringScroll = () => {if(userInfo?.OnboardingStatus?.Code === OnboardingStatus.ERREUR && userPieceJustifList[0]?.StatutPieceCode === StatutPieceJustificative.TRANSMISE){
    setTimeout(() => {
      scrollToValidateBanner()
    }, 1000)
  }}

  return (
    <Container css={{
      marginTop: '30px'
    }}>
      <LoadingSpinner>
        <>
          <ProfilCompletionHeader
            status={userInfo?.OnboardingStatus}
            kycErrorCount={Math.max(...userPieceJustifList?.map(userPiece => userPiece.ErrorCount || 0))}
          />

          {(!userInfo?.IsExperienceInvestissement || daysLeft(new Date(userInfo?.DateExperienceInvestissement!)) < 0) && <div
            css={{
              display: "flex",
              flexDirection:"row",
              alignItems:"center",
              background: "#fcf8e3",
              color: "#8a6d3b",
              padding: "40px",
              borderRadius: "0.28571429rem !important",
              marginBottom: "30px",
              boxShadow: "0px 1px 3px 0px #D4D4D5, 0px 0px 0px 1px #D4D4D5",
            }}
          >
            <div css={{marginRight:"20px"}}>
              <Icon name="exclamation triangle" size="huge"/>
            </div>
            <div>
              <div
                css={{
                  display: "inline-block",
                  fontWeight: "bold",
                  lineHeight:"30px",
                  fontSize:"25px"
                }}
              >
                Quel type d’investisseur êtes-vous?
              </div>
              <div
                css={{
                  display: "block",
                  fontSize: "16px",
                  marginTop: "10px",
                }}
              >
                Afin de vous accompagner au mieux dans vos investissements nous aurions besoin de quelques informations sur votre expérience d’investissement.
              </div>
            </div>
          </div>}
          {validateErrorMessage && (
            <Segment css={{position:"relative", backgroundColor:"#f2dede !important", display:"flex", flexDirection:"row", alignItems:"center"}}>
              <div>
                <p 
                  css={{
                    color:"#a94442", 
                    fontSize:"16px", 
                    fontWeight:"bold"}}
                >
                  Vous avez une pièce justificative en erreur, veuillez en soumettre une nouvelle avant de valider vos modifications
                </p>
              </div>
            </Segment>
          )}

          {
          userInfo?.OnboardingStatus?.Code === OnboardingStatus.ERREUR && userPieceJustifList[0]?.StatutPieceCode === StatutPieceJustificative.TRANSMISE && (
          <div ref={validateModificationBannerRef}>
              <InformationBandeau 
                type="information"
                icon={{name:'info circle', size:"huge", color:"blue"}}
                header="Modifications à valider"
                message="Vous venez d'effectuer des modifications. Afin que celles-ci soient définitivement prises en compte, vous devez les valider en cliquant sur le bouton ci-après"
              >
                <Modal
                  open={isValidateModalOpen}
                  trigger={
                    <SimpleButton 
                      label="Valider mes modifications" 
                      onClick={() => setIsValidateModalOpen(true)}
                    />}
                >
                  <Header icon='edit outline' content='Terminer mes modifications ' />
                  <Modal.Content>
                    <p style={{marginBottom:40}}>
                      Voulez-vous vraiment soumettre vos corrections ? Elles seront définitives et vous n'aurez plus la possibilité de les modifier.
                    </p>
                    <Modal.Actions>
                      <SubmitButton 
                        label="Confirmer quand même"
                        validateForm={() => {
                          for(let i = 0; i < userPieceJustifList.length; i++) {
                            var element = userPieceJustifList[i]
                            if(element.StatutPieceCode !== StatutPieceJustificative.VALIDE && element.StatutPieceCode !== StatutPieceJustificative.TRANSMISE) {
                              return false
                            }
                          }
                          return true
                        }}
                        action={() => userService.validateModifications(loginProps?.oauth, loginProps?.oauth?.userId)}
                        onActionSuccess={() => setReload(true)}
                        onFormNotValid={onValidateCorrectionRefused}
                      />
                    </Modal.Actions>
                  </Modal.Content>
                </Modal>  
            </InformationBandeau>
            </div>
          )}  
          
          <Grid stackable doubling>
            <Grid.Column width={8}>
              <EditableBlock 
                title="Adresse mail" 
                editor={(close) => null} 
                editable={false}
              >
                {adresseMail()}
              </EditableBlock>

              <div css={{border: !userInfo?.IsPiecesJustifDone ? "solid 2px #B80000 !important" : "", borderRadius: "5px !important"}}>
                <EditableBlock
                  title="Pièces justificatives"
                  editButtonColor={!userInfo?.IsPiecesJustifDone ? "#B80000" : "#005877"}
                  editable={!userInfo?.IsPiecesJustifDone}
                  editor={(close) => (
                    <OnboardingInvestisseurParticulierDocumentListForm
                      onActionSuccess={(
                        response: MultipleFileRegistrationResponse
                      ) => {
                        setUserPieceJustifList(response.UserPieceList!)
                        setValidateErrorMessage(false)
                        updateUserInfo(response.UserInfo!)
                        triggeringScroll()
                        close();
                      }}
                    />
                  )}
                >
                  {piecesJustificatives()}
                </EditableBlock>
              </div>

              <EditableBlock
                title="Expérience en matière d'investissement"
                editButtonColor="#005877"
                editable={!userInfo?.IsExperienceInvestissement || daysLeft(new Date(userInfo?.DateExperienceInvestissement!)) < 0}
                editor={(close) => (
                  <ExperienceInvestissementForm 
                    isOnboarding = {false}
                    onSubmitSuccess={() => { 
                      setReload(true)
                      close();
                    }} 
                    onCancel={close}
                  />
                )}
              >
                {experienceInvestissement()}
              </EditableBlock>
              
            </Grid.Column>
            <Grid.Column width={8}>
              <div css={{border: !userInfo?.IsIdentiteDone ? "solid 2px #B80000 !important" : "", borderRadius: "5px !important", marginBottom:"0.76rem"}}>
                <EditableBlock
                  title="Etat civil"
                  editable={!userInfo?.IsIdentiteDone}
                  editButtonColor={!userInfo?.IsIdentiteDone ? "#B80000" : "#005877"}
                  editor={(close) => (
                    <SubscriptionFormEtatCivilNew
                      userInfo={userInfo}
                      onSubmitSuccess={(response: OnboardingCitoyenResponse) => {
                        updateUserInfo(response.UserInfo!)
                        close();
                      }}
                    />
                  )}
                >
                  {etatCivil()}
                </EditableBlock>
              </div>
              <div css={{border: !userInfo?.IsAdressePostaleDone ? "solid 2px #B80000 !important" : "", borderRadius: "5px !important"}}>
                <EditableBlock
                  title="Adresse postale"
                  editButtonColor={!userInfo?.IsAdressePostaleDone ? "#B80000" : "#005877"}
                  editable={!userInfo?.IsAdressePostaleDone}
                  editor={(close) => (
                    <SubscriptionAdressePostaleForm
                      userInfo={userInfo}
                      onSubmitSuccess={(response: OnboardingCitoyenResponse) => {
                        updateUserInfo(response.UserInfo!)
                        close();
                      }}
                    />
                  )}
                >
                  {adressePostale()}
                </EditableBlock>
              </div>         

              <EditableBlock
                title="Capacité d'investissement"
                editButtonColor="#005877"
                editable={!userInfo?.ValeurPatrimonialeNette}
                editor={(close) => (
                  <CapaciteInvestissementForm 
                    userInfo={userInfo}
                    onSubmitSuccess={(response: OnboardingCitoyenResponse) => {
                      updateUserInfo(response.UserInfo!)
                      setReload(true)
                      close();
                    }}
                    onCancel={close}
                  />
                )}
              >
                {capaciteInvestissement()}
              </EditableBlock>
         
            </Grid.Column>
          </Grid>
        </>
      </LoadingSpinner>
    </Container>
  );
}
