import React from "react"
import { Container } from "semantic-ui-react";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { useSelector } from "react-redux";
import { ProfilCode } from "../../model/dto/response";

type Props = {
};

export function ProjetListHeader({}: Props) {

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)     

    return (
        <>
            <div 
                css={{  
                    backgroundImage: "url('/img/projets/projet_headerV2.png')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    minHeight: "300px",
                    marginBottom: '60px',
                    display: 'flex',
                    alignItems: 'center', 
                    justifyContent: 'center'
                }}
            >
                {!loginProps?.oauth || loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR ?
                    <Container>
                        <div 
                            css={{ 
                                display: "flex", 
                                justifyContent: "center", 
                                marginBottom: "50px"
                            }}
                        >
                            <h1 css={{fontSize:"30px", color:"#ffffff", fontFamily:"merriweather",  fontWeight:"bold"}}>Investissez dans vos valeurs</h1>
                        </div>
                    </Container>
                :
                    <Container>
                        <div 
                            css={{ 
                                display: "flex", 
                                justifyContent: "center", 
                                marginBottom: "50px"
                            }}
                        >
                            <h1 css={{fontSize:"30px", color:"#ffffff", fontFamily:"merriweather",  fontWeight:"bold"}}>Engagez vos citoyens</h1>
                        </div>
                    </Container>
                }
            </div>
        </>
    )         
}