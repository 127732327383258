import React from "react";
import { EditableBlock } from "../../generic/editable-block/editable-block";
import { InvestisseursReportingForm } from "../form/investisseurs-reporting-form";

export function ReportingInvestisseurs() {
    
    return (
        <> 
            <>
                <EditableBlock
                    title="Reporting projet"
                    editor={() => null}
                    editable={false}
                >
                    <InvestisseursReportingForm/>
                </EditableBlock>
            </>
        </>
    )
}