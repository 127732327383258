import React, { useEffect, useMemo, useState } from "react"
import { AuthenticationState } from "../../../../../store/authentication/types";
import { ApplicationState } from "../../../../../store";
import { useSelector } from "react-redux";
import Projet from "../../../../../model/projet";
import { Button, Image, Modal, Progress } from "semantic-ui-react";
import { StatutProjet } from "../../../../../model/entities/statut-projet";
import { DefaultResponse,  ProfilCode } from "../../../../../model/dto/response";
import { projetService } from "../../../../../services/projet-service";
import { SubmitButton } from "../../../../generic/submit-button";
import { OnboardingStatus } from "../../../../../model/entities/onboarding-status";
import { useNavigate } from "react-router-dom";
import { ValidateDebouclageForm } from "../../../forms/validate-debouclage-form";
import { DemandeDebouclageForm } from "../../../forms/demande-debouclage-form";
import { ProlongationForm } from "../../../forms/prolongation-form";
import { addDays, parseISO } from "date-fns";
import { EcheancierProjet } from "../../../../../model/entities/echeancier-projet";
import { investmentService } from "../../../../../services/investment-service";
import { ProcessProjectToAVenirStatutBody } from "../../../../../model/dto/body/process-to-avenir-statut-body";
import { PublishBody } from "../../../../../model/dto/body/publish-body";
import { TypeCollecte } from "../../../../../model/entities/type-collecte";
import { LinkButton } from "../../../../generic/link-button";
import './information-don.css'

type Props = {
    project: Projet;
    endDate: Date;
    nombreDonateur: number;
    reload: () => void;
    onPublishSuccess: (response: Response) => void;
}

export function InformationsDon({project, endDate, nombreDonateur, reload, onPublishSuccess}: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    const navigate = useNavigate()
    const [isEdited, setIsEdited] = useState(false)
    const [echeanciersProjet, setEcheanciersProjet] = useState<EcheancierProjet[]>([])
    const [windowWidth, setWindowWith] = useState(window.innerWidth)

    useEffect(()=>{
        if(project && loginProps?.oauth?.profilCode === ProfilCode.VILLYZ) {
            investmentService.getEcheanciersProjetByProjetId(project.Id!, loginProps?.oauth)
            .then(response => setEcheanciersProjet(response.EcheanciersProjet!))
        }
    }, [])

    // On gère les cas de redimensionnement de la fenêtre
    useEffect(() => {
        const handleSize = () => setWindowWith(window.innerWidth)
        window.addEventListener('resize', handleSize);

        // Nettoyage de l'écouteur d'événements quand le composant est démonté
        return () => window.removeEventListener('resize', handleSize);
    })

    const pourcentageCollecte = useMemo(() => {
        if(project.MontantCollecteDon && project.MontantRechercheDon) {
            const pourcentage = project.MontantCollecteDon / project.MontantRechercheDon * 100
            return Math.round(pourcentage * 100) / 100
        }
        else {
            return 0
        }
    }, [])

    const statutTermines = [
        StatutProjet.CLOTURE, 
        StatutProjet.DEBOUCLAGE_DEMANDE, 
        StatutProjet.DEBOUCLAGE_POSSIBLE,
        StatutProjet.DEBOUCLAGE_VALIDE,
        StatutProjet.DEBOUCLAGE_FINALISE,
        StatutProjet.FINANCE
    ]

    const statutPrePublies = [
        StatutProjet.INCOMPLET,
        StatutProjet.COMPLET,
        StatutProjet.EN_TRAITEMENT,
        StatutProjet.A_VENIR,
        StatutProjet.BIENTOT_DISPONIBLE,
        StatutProjet.INFORMATION_MANQUANTE,
        StatutProjet.ANNULE,
        StatutProjet.REFUSE
    ]

    const statutsClotures = [
        StatutProjet.CLOTURE, 
        StatutProjet.DEBOUCLAGE_DEMANDE,
        StatutProjet.DEBOUCLAGE_POSSIBLE,
        StatutProjet.DEBOUCLAGE_VALIDE,
        StatutProjet.DEBOUCLAGE_FINALISE
    ]

    const isProjetEnTraitementEligible = () => {
        if(
            project.StatutDonProjetCode === StatutProjet.INCOMPLET || 
            project.StatutDonProjetCode === StatutProjet.COMPLET
        ) {
            return project.Titre !== null && project.DescriptionCourt !== null && project.MontantRechercheDon ? true : false
        } 
    }

    const processProjectToAvenirBody: ProcessProjectToAVenirStatutBody = {
        IsEmprunt: false
    }

    const publishBody : PublishBody = {
        IsEmprunt: false
    }

    const percentExemptTax: number = 60

    const statutNotAvailableToSwicth = [
        StatutProjet.INCOMPLET,
        StatutProjet.COMPLET,
        StatutProjet.EN_TRAITEMENT,
        StatutProjet.A_VENIR,
        StatutProjet.INFORMATION_MANQUANTE,
        StatutProjet.ANNULE,
        StatutProjet.REFUSE
    ]
    const isSwitchAvailable = (): boolean => {
        if(loginProps.oauth?.profilCode ===  ProfilCode.PORTEUR_PROJET || loginProps.oauth?.profilCode === ProfilCode.VILLYZ){
          return false
        }
  
        return !statutNotAvailableToSwicth.includes(project.StatutProjetCode!)
    }
    
    return (
        <div className="don-main-container">
            <div>
                <div
                className="don-collected-money-block"
                 >
                    {(statutPrePublies.find(statut => statut === project.StatutDonProjetCode)) && (
                        <>
                            {project.MontantRechercheDon ? (project.MontantRechercheDon / 100).toLocaleString("fr-FR") + "€ " : " 0€ "}
                            <span className="abstract-color">{project.MontantRechercheDon! >= 1 ? "recherchés" : "recherché"}</span>
                        </>
                    )}
                    {(statutTermines.find(statut => statut === project.StatutDonProjetCode) || project.StatutDonProjetCode === StatutProjet.PUBLIE) && (
                        <>
                            {project.MontantCollecteDon ? (project.MontantCollecteDon / 100).toLocaleString("fr-FR") + "€ " : " 0€ "}
                            <span className="abstract-color">{project.MontantCollecteDon! >= 1 ? "collectés" : "collecté"}</span>
                        </>
                    )}
                </div>
                {!statutTermines.find(statut => statut === project.StatutDonProjetCode) && (
                    <Progress 
                        percent={pourcentageCollecte} 
                        size="small" 
                        color="blue" 
                        className="don-progress-bar"
                    >
                        {!statutPrePublies.find(status => status === project.StatutDonProjetCode) && (
                            <div className="flex-center">
                                <div 
                                style={{fontSize:"14px", color:"#404040"}}
                                >
                                {(project.MontantRechercheDon! / 100).toLocaleString("fr-FR")}€{" "}
                                <span
                                style={{ color:"#7F7F7F" }}
                                >recherchés</span>
                                </div>
                            </div>
                        )}
                        {project.StatutDonProjetCode === StatutProjet.BIENTOT_DISPONIBLE && (
                            <div 
                            style={{textAlign: "center", marginTop:"10px"}}
                            >
                                <span 
                                style={{ fontSize:"14px", color:"#7F7F7F" }}
                                >Pour ne pas manquer l'opportunité :</span>
                            </div>
                        )}
                    </Progress>
                )}
                {statutTermines.find(statut => statut === project.StatutDonProjetCode) && (
                    <Progress percent={100} size="small" color="blue" css={{marginTop:"10px !important"}}>
                        {project.StatutDonProjetCode === StatutProjet.FINANCE &&
                        <div className="flex-center">
                            <div 
                            style={{fontSize:"14px", color:"#404040"}}
                            >
                                {nombreDonateur || ""}
                                <span 
                                style={{ color:"#7F7F7F" }}
                                >{nombreDonateur > 1 ? " donateurs" : nombreDonateur > 0 ? " donateur" : "aucun donateur"}</span>
                            </div>
                        </div>
                        }
                    </Progress>
                )}
            </div>
            <div 
                css={{
                    marginTop: '1em',
                    marginBottom: '1em'
                }}
            >
                {loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET && isProjetEnTraitementEligible() && (
                    <SubmitButton 
                        label="Publier à Villyz"
                        action={() => projetService.publishProjet(project.Id!, publishBody, loginProps?.oauth)}
                        onActionSuccess={onPublishSuccess}
                        style={{
                            padding: '10px 20px',
                            width: '100%',
                            fontSize: '16px',
                            fontWeight: 600
                        }}
                    />
                )}
                {loginProps?.oauth?.profilCode === ProfilCode.VILLYZ && project.StatutDonProjetCode === StatutProjet.EN_TRAITEMENT && (
                    <SubmitButton 
                        label="Rendre public"
                        action={() => projetService.ProcessProjectToAVenirStatut(project.Id!, processProjectToAvenirBody, loginProps?.oauth)}
                        onActionSuccess={onPublishSuccess}
                        style={{
                            padding: '10px 20px',
                            width: '100%',
                            fontSize: '16px',
                            fontWeight: 600
                        }}
                    />
                )}
                {loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET && project.StatutDonProjetCode === StatutProjet.PUBLIE && endDate && addDays(endDate, -5) <= new Date() && (
                <>
                    <Button 
                        size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                        color="blue" 
                        fluid
                        onClick={() => setIsEdited(true)}
                    >
                        Prolonger la cagnotte
                    </Button>
                    <Modal onClose={() => setIsEdited(false)} open={isEdited}>
                    <Modal.Header>Prolonger la cagnotte</Modal.Header>
                    <Modal.Content>
                        <ProlongationForm 
                            projet={project}
                            onSubmitSuccess={() => {
                                setIsEdited(false); 
                                reload();
                            }}
                        />
                    </Modal.Content>
                    </Modal>
                </>
                )}
                {loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET && project.StatutDonProjetCode === StatutProjet.DEBOUCLAGE_POSSIBLE && endDate && (
                <>
                    <Button 
                    size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" } 
                    color="blue" 
                    fluid
                    onClick={() => setIsEdited(true)}
                    >
                    Demander le débouclage
                    </Button>
                    <Modal onClose={() => setIsEdited(false)} open={isEdited}>
                    <Modal.Header>Demande de débouclage</Modal.Header>
                    <Modal.Content>
                        <DemandeDebouclageForm 
                            projet={project}
                            onSubmitSuccess={(response: DefaultResponse) => {
                                setIsEdited(false);
                                reload();
                            }}
                            isDon
                        />
                    </Modal.Content>
                    </Modal>
                </>
                )}
                {loginProps?.oauth?.profilCode === ProfilCode.VILLYZ && project.StatutDonProjetCode === StatutProjet.DEBOUCLAGE_DEMANDE && (
                <>
                <Button
                    size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                    color="blue" 
                    fluid
                    onClick={() => setIsEdited(true)}
                    >
                    Valider le débouclage
                </Button>
                <Modal onClose={() => setIsEdited(false)} open={isEdited}>
                    <Modal.Header>Validation du débouclage</Modal.Header>
                    <Modal.Content>
                        <ValidateDebouclageForm
                            projetId={project.Id}
                            onActionSuccess={() => { reload() }}
                            onActionFailure ={() => { reload() }}
                            echeanciersProjet={echeanciersProjet}
                            isPageDon={true}
                        />
                    </Modal.Content>
                </Modal>
                </>
                )}
                {loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR && 
                loginProps.user?.IsMailValid && 
                loginProps.user?.Etablissement && 
                project.StatutDonProjetCode === StatutProjet.PUBLIE && (
                    <div>
                        <Button 
                            size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                            color="blue" 
                            fluid
                            onClick={() => navigate(`/projets/${project.Id}/investissement/don`)}
                        >
                            Soutenir
                        </Button>
                    </div>
                )}
            </div>

            {loginProps.isAuthenticated 
            && loginProps.oauth?.profilCode === ProfilCode.INVESTISSEUR
            && (loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.COMPLET || loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.VAL_EMAIL_A_FAIRE)
            && !loginProps.user?.IsMailValid
            && project.StatutDonProjetCode === StatutProjet.PUBLIE
            && (
                <div
                style={{
                        fontSize: "1.2rem",
                        fontWeight:"bold",
                        textAlign: "center",
                        color: "#085c74"
                    }}
                >
                    <Button 
                        className="don-button-main-action" 
                        size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                        color="blue" 
                        fluid
                        onClick={() => navigate(`/profil`)}
                    >
                        Valider mon adresse mail
                    </Button>
                </div>
            )}

            {loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR && 
            (loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.PRESQUE_COMPLET ||
            loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.ERREUR ||
            loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.COMPLET ||
            loginProps.user?.OnboardingStatus?.Code === OnboardingStatus.VAL_EMAIL_A_FAIRE) && 
            !loginProps.user?.IsMailValid && 
                <Button 
                    className="don-button-mail-action" 
                    size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" }
                    color="blue" 
                    fluid
                    onClick={reload}
                >
                    J’ai validé mon adresse mail
                </Button>
            }

            {(project.StatutDonProjetCode === StatutProjet.FINANCE || (statutsClotures.find(status => status === project.StatutDonProjetCode))) && (
                <div
                style={{
                        fontSize: "1.2rem",
                        fontWeight:"bold",
                        textAlign: "center",
                        color: "#085c74"
                    }}
                >
                    Cette collecte est terminée ! L'objectif a été atteint en {Math.floor((endDate.getTime() - parseISO(project.DateDebutCollecteDon!.toString()).getTime()) / (1000 * 3600 * 24)) + 1} jours
                </div>
            )}
            {!loginProps.oauth && project.StatutDonProjetCode === StatutProjet.PUBLIE && (
                <div className={project.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON ? "don-main-button-container-when-switch" : "don-main-button-container-when-no-switch"}>
                    <Button 
                        size={windowWidth < 768 || windowWidth > 1199 ? "big" : "medium" } 
                        color="blue" 
                        fluid
                        onClick={() => navigate(`/connexion`)}
                    >
                        Soutenir
                    </Button>
                </div>
            )}
             {project.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON && isSwitchAvailable() && 
                <div className="don-switch-link">
                    {!loginProps.user?.Etablissement &&
                        <p className="don-paragraph-info-mecenat">Le mécénat est réservé aux personnes morales</p>
                    }
                    <LinkButton 
                        label="Je suis un particulier"
                        onlyBlack={true}
                        onClick={() => navigate(`/projets/${project.UrlSlug}`)}
                    />
                </div>
            }
            <div className="don-caracteristiques-container">
                <div className="don-caracteristiques-bloc">
                    <p className="don-caracteristiques-bloc-title">
                        <Image src='/img/icons/donation.svg'/>
                        <span className="don-caracteristiques-bloc-title-span">Montant maximal de don</span>
                    </p>
                    <p>{project.MontantMaxPersonneDon ? `${project.MontantMaxPersonneDon / 100} € ` : '' }</p>
                </div>
                <div className="don-caracteristiques-bloc">
                <p className="don-caracteristiques-bloc-title">
                        <Image src='/img/icons/defiscalisation.svg'/>
                        <span className="don-caracteristiques-bloc-title-span">Défiscalisation</span>
                    </p>
                    <p>Vos dons ponctuels ou réguliers, sont déductibles à {percentExemptTax} % de l'impôt sur les sociétés</p>
                </div>
            </div>
            <div className="don-informations-links">
                <div>
                    <a href="/risques" target="_blank" style={{fontWeight: "bold"}}>Risques</a>
                </div>
                <div>
                    <a href="/faq-page" target="_blank" style={{fontWeight: "bold"}}>FAQ</a>
                </div>
                <div>
                    <a href="/faq-page#fiscalite-mecene" target="_blank" style={{fontWeight: "bold"}}>Fiscalité</a>
                </div>
            </div>
        </div>
    )
}