import React from 'react'
import { Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react'
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon'
import './information-bandeau.css'

type Props = {
  icon?:{name:SemanticICONS, size?:IconSizeProp, color?:SemanticCOLORS},
  header?:string,
  message:string | React.ReactNode,
  type:string,
  children?:React.ReactNode,
  alignChildren?:string
}

export default function InformationBandeau( { icon, header, message, type, children, alignChildren = 'center'} : Props) {
  return (
    <div id='bandeau-container' className={`bandeau-container ${type}`}>
        {
        icon ? (
            <div className='informations-bloc items-center'>
                <div className='informations-bloc-icon-container'>
                    <Icon name={icon.name} size={icon?.size} color={icon?.color}/>
                </div>               
                <div className='informations-bloc-text-container'>
                    <h2 className='informations-bloc-text-title-withIcon'>{header}</h2>
                    <p className='informations-bloc-text-paragraph'>{message}</p>
                </div>
          </div>
        ) : (<div className='informations-bloc'>       
        <div className='informations-bloc-text-container'>
          <h2 className='informations-bloc-text-title-withoutIcon'>{header}</h2>
          <p className='informations-bloc-text-paragraph'>{message}</p>
        </div>        
      </div>)
      }
        { children && 
        <div className={`children-container self-${alignChildren}`}>
            {children}
        </div>
        }
    </div>
  )
}
