export interface WorkflowType {
    Code : string,
    Libelle : string
}

const ONBOARDING_CITOYEN: string = "OBC";
const ONBOARDING_INVESTISSEUR_MORAL: string = "OIM";
const ONBOARDING_INVESTISSEUR_SOCIETE: string = "OIS";
const ONBOARDING_PORTEUR_ORGANISATION: string = "OPO";
const ONBOARDING_PORTEUR_SOCIETE: string = "OPS";
const PAIEMENT_INVESTISSEUR_SIMPLE: string = "PIS";
const CREATION_PROJET_COMBINE: string = "CPC";
const CREATION_PROJET_DON: string = "CPD";
const CREATION_PROJET_EMPRUNT: string = "CPE";

export const WorkflowType = {
    ONBOARDING_CITOYEN,
    ONBOARDING_INVESTISSEUR_MORAL,
    ONBOARDING_INVESTISSEUR_SOCIETE,
    ONBOARDING_PORTEUR_ORGANISATION,
    ONBOARDING_PORTEUR_SOCIETE,
    PAIEMENT_INVESTISSEUR_SIMPLE,
    CREATION_PROJET_COMBINE,
    CREATION_PROJET_DON,
    CREATION_PROJET_EMPRUNT
}