import React, { useEffect } from "react"
import { Accordion, Container, Header } from 'semantic-ui-react'
import { section } from "./styles"
import { citoyenPanelGeneral, citoyenPanelPretParticipatifCitoyen, citoyenPanelRisques, projetsPanel,mecenatEntreprisePanel} from "./faq-questions";

export function FaqPage(){
    const anchor = window.location.hash
    const fiscaliteCitoyenAnchor = '#fiscalite-citoyen' 
    const fiscaliteMeceneAnchor = '#fiscalite-mecene' 

    useEffect(() => {
        if(anchor === fiscaliteCitoyenAnchor){
            const targetAnchor = document.getElementById(fiscaliteCitoyenAnchor)
            if(targetAnchor){
                targetAnchor.scrollIntoView({behavior:'smooth'})
            }
        }
        if(anchor === fiscaliteMeceneAnchor){
            const targetAnchor = document.getElementById(fiscaliteMeceneAnchor)
            if(targetAnchor){
                targetAnchor.scrollIntoView({behavior:'smooth'})
            }
        }
    }, [])

    
    return (
        <div css={[section]}>
            <Container>
                <Header as="h1" color="blue" css={{textAlign : "center", marginTop : "-50px"}}>
                    FOIRE AUX QUESTIONS​
                </Header>
        
                <Header as="h2" color="blue" css={{textAlign : "center"}}>
                    Général
                </Header>
                <Accordion defaultActiveIndex={0} fluid styled panels={citoyenPanelGeneral}/>
                <Header as="h2" color="blue" css={{textAlign : "center"}}>
                    Investissement citoyen
                </Header>
                <div id="#fiscalite-citoyen">
                    <Accordion defaultActiveIndex={anchor === fiscaliteCitoyenAnchor  ? 4 : 0} fluid styled panels={citoyenPanelPretParticipatifCitoyen}/>
                </div>
            
                <Header as="h2" color="blue" css={{textAlign : "center"}}>
                    Risques
                </Header>
                
                <Accordion defaultActiveIndex={0} fluid styled panels={citoyenPanelRisques}/>
                <Header as="h2" color="blue" css={{textAlign : "center"}}>
                    Projets 
                </Header>
                <Accordion defaultActiveIndex={0} fluid styled panels={projetsPanel}/>
                <Header as="h2" color="blue" css={{textAlign : "center"}}>
                    Mécénat d'entreprise
                </Header>
                <div id={fiscaliteMeceneAnchor}>
                    <Accordion defaultActiveIndex={anchor === fiscaliteMeceneAnchor  ? 2 : 0} fluid styled panels={mecenatEntreprisePanel}/>
                </div>
            </Container>
        </div>
    )
}