import React, { FormEvent, useEffect, useState } from "react";
import { Radio } from "semantic-ui-react";

export type RadioElement = {
  label: string;
  key: string | number;
};

type FieldData = {
  name?: string;
  value?: any;
  isValid?: boolean;
};

type Props = {
  elements: RadioElement[];
  data: FieldData;
  label: string;
  submitted?: boolean;
  error?: string;
  vertical?: boolean;
  gap?: string;
  onChange?: (value: any) => void;
  validateField?: (key?: any) => boolean;
};

export function RadioListField({
  data,
  elements,
  error,
  submitted,
  label,
  vertical,
  gap,
  onChange,
  validateField,
}: Props) {
  useEffect(() => {
    if (submitted && validateField) {
      setIsValid(validateField(data.value));
    }
  }, [submitted]);

  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(error);

  const onRadioClick = (value: any) => {
    if (validateField && !validateField(value)) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div
      css={{
        marginBottom: "8px",
      }}
    >
      <div className="field">
        <label>{label}</label>
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: vertical ? "column" : "row",
          gap: gap ?? "0px",
        }}
      >
        {elements.map((element) => (
          <Radio
            key={element.key}
            label={element.label}
            name={label}
            value={element.key}
            checked={data.value === element.key}
            onClick={() => onRadioClick(element.key)}
            css={{
              marginRight: vertical ? "0px" : "10px !important",
            }}
          />
        ))}
      </div>

      {!isValid && (
        <div
          css={{
            color: "#F03E3E",
            fontSize: "10px",
          }}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
}
