import React from "react"
import { SimpleButton } from "../../generic/simple-button"
import Projet from "../../../model/projet"
import { useNavigate } from "react-router-dom"
import { TypeCollecte } from "../../../model/entities/type-collecte"

type Props = {
    projet?: Projet,
}

export function FinalisationProjetForm({projet}: Props) {
    const navigate = useNavigate()

    return (
        <>
            <div css={{marginBottom: "10px"}}>
                Merci pour la création de votre projet !
            </div>
            <div css={{marginBottom: "10px"}}>
                Pour le moment, seule votre collectivité peut visualiser le projet.
            </div>
            <div css={{marginBottom: "10px"}}>
                Vous pouvez encore modifier ou compléter les caractéristiques de votre projet avant de le publier à Villyz pour validation. Une page projet dédiée est à votre disposition pour visualiser le rendu final lorsque votre projet sera publié aux citoyens.
            </div>
            <div css={{marginBottom: "15px"}}>
                En cas de question, n'hésitez pas à nous contacter à l'adresse collectivites@villyz.fr
            </div>
            <SimpleButton 
                label="Accéder à ma page projet"
                onClick={() => {
                    if(projet) {
                        if(projet.TypeCollecteCode === TypeCollecte.EMPRUNT_CITOYEN || projet.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON) {
                            navigate("/projets/" + projet.UrlSlug);
                        } else {
                            navigate("/projets/" + projet.UrlSlug + "/don");
                        }
                    }
                }}
            />
        </>
    )
}
