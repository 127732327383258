import isLeapYear from "dayjs/plugin/isLeapYear";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useSelector } from "react-redux";
import {
    Container,
    Grid,
    List,
    ListItem,
} from "semantic-ui-react";
import {
    InvestissementHistoryResponse,
    MailUpdateResponse,
    MultipleFileRegistrationResponse,
    OnboardingCitoyenResponse,
    RemboursementHistoryResponse,
    RetraitHistoryResponse,
    UserInfoResponse,
} from "../../../model/dto/response";
import { Pays } from "../../../model/entities/pays";
import { TypePieceByProfil } from "../../../model/entities/type-piece-by-profil";
import { UserPieceJustificative } from "../../../model/entities/user-piece-justificative";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { userService } from "../../../services/authentication-redux-service";
import { investmentService } from "../../../services/investment-service";
import { referenceService } from "../../../services/reference-service";
import { ApplicationState } from "../../../store";
import { EditableBlock } from "../../generic/editable-block/editable-block";
import { LightEditableBlock } from "../../generic/editable-block/light-editable-block";
import { ProfilCode } from "../../../model/dto/response";
import { LoadingSpinner } from "../../generic/loading-spinner";
import { ChangeMailFormAdmin } from "../../login/change-mail-form-admin";
import { SubscriptionAdressePostaleForm } from "../../login/subscription-adresse-postale-form";
import { SubscriptionFormEtatCivilNew } from "../../login/subscription-form-etat-civil";
import { PieceJustifLine } from "../../profil/piece-justif-line";
import { ProfilCompletionHeader } from "../../profil/profil-completion-header";
import { UpdateProfilStatusForm } from "../form/update-profil-status-form";
import { DetailOperation } from "./detail-operations";
import { OnboardingInvestisseurParticulierDocumentListForm } from "../../login/onboarding-investisseur-particulier-document-list-form";
import InformationsPASform from "../../login/informations-PAS-form-admin";
import { format, parseISO } from "date-fns";
import { OnboardingStatus } from "../../../model/entities/onboarding-status";
import { SubmitButton } from "../../generic/submit-button";
import { BlockAccountModal } from "../form/block-account-modal";

var dayjs = require("dayjs");
dayjs.extend(isLeapYear); // use plugin
dayjs.locale("fr-ch"); // use locale

interface Props {
    userId?: number;
}

export function CitoyenDetail({ userId }: Props) {
    const [userInfo, setUserInfo] = useState<UserInfoResponse>();
    const [typePiecesJustifList, setTypePiecesJustifList] = useState<
        TypePieceByProfil[]
    >([]);
    const [userPieceJustifList, setUserPieceJustifList] = useState<
        UserPieceJustificative[]
    >([]);
    const [paysList, setPaysList] = useState<Pays[]>([]);
    const [investHistoryList, setInvestHistoryList] = useState<
        InvestissementHistoryResponse[]
    >([]);
    const [remboursementHistoryList, setRemboursementHistoryList] = useState<
        RemboursementHistoryResponse[]
    >([]);
    const [retraitHistoryList, setRetraitHistoryList] = useState<
        RetraitHistoryResponse[]
    >([]);
    const [reload, setReload] = useState(true);

    const loginProps: AuthenticationState = useSelector<
        ApplicationState,
        AuthenticationState
    >((state) => state.authentication);

    useEffect(() => {
        if (reload) {
            trackPromise(
                userService
                    .getUserInfoForAdmin(userId, loginProps?.oauth)
                    .then((response) => {
                        setUserInfo(response);
                    })
            );

            trackPromise(
                userService
                    .getTypePieceUserForAdmin(userId, loginProps?.oauth)
                    .then((response) => {
                        if (response.IsTraitementOk) {
                            setTypePiecesJustifList(response.TypePieceList!);
                        }
                    })
            );

            trackPromise(
                userService
                    .getUserPiecesJustifListForAdmin(userId!, loginProps?.oauth)
                    .then((response) => {
                        if (response.IsTraitementOk) {
                            setUserPieceJustifList(
                                response.UserPieceJustifList!
                            );
                        }
                    })
            );

            trackPromise(
                investmentService
                    .getInvestmentHistoryByAdmin(userId, loginProps?.oauth)
                    .then((response) => {
                        setInvestHistoryList(
                            response.InvestissementHistoryResponseList
                        );
                    })
            );

            trackPromise(
                investmentService
                    .getRemboursementHistoryByAdmin(userId, loginProps?.oauth)
                    .then((response) => {
                        setRemboursementHistoryList(
                            response.RemboursementHistoryResponseList
                        );
                    })
            );

            trackPromise(
                investmentService
                    .getRetraitHistoryByAdmin(userId, loginProps?.oauth)
                    .then((response) => {
                        setRetraitHistoryList(
                            response.RetraitHistoryResponseList
                        );
                    })
            );

            trackPromise(
                referenceService.getAllPays().then((response) => {
                    if (response.IsTraitementOk) {
                        setPaysList(response.PaysList!);
                    }
                })
            );

            setReload(false);
        }
    }, [reload]);

    const adresseMail = () => {
        return (
            <div className="form-group">
                <p>Adresse mail: {userInfo?.Mail}</p>
                <p>Statut: {!userInfo?.IsMailValid && "non"} validée</p>
            </div>
        );
    };

    const etatCivil = () => {
        return (
            <div>
                <div>
                    Date update user : {userInfo?.DateUpdated?.toString()}
                </div>
                <div>Prénom : {userInfo?.Prenom}</div>
                <div>Nom : {userInfo?.Nom}</div>
                <div>
                    Date de naissance :{" "}
                    {userInfo?.DateNaissance &&
                        userInfo?.DateNaissance !== null &&
                        dayjs(userInfo?.DateNaissance).format("DD/MM/YYYY")}
                </div>
                <div>
                    Résidence fiscale :{" "}
                    {userInfo?.IsResidentFrancais
                        ? "Française"
                        : "Non renseignée"}
                </div>
                <div>
                    Nationalité :{" "}
                    {paysList
                        ? paysList.find((p) => p.Code === userInfo?.Nationalite)
                              ?.Nom
                        : ""}
                </div>
                <div>Civilité : {userInfo?.Civilite}</div>
                <div>
                    Lieu de naissance :{" "}
                    {userInfo?.CommuneDesignationNaissance !== "" &&
                    userInfo?.CommuneDesignationNaissance != undefined
                        ? userInfo?.CommuneDesignationNaissance
                        : userInfo?.NomDeCommuneEtrangere}
                </div>
                <div>Pays de naissance : {userInfo?.PaysDeNaissance}</div>
            </div>
        );
    };

    const adressePostale = () => {
        return (
            <div>
                <div
                    css={{
                        marginBottom: "20px",
                    }}
                >
                    <div>
                        {(userInfo?.LibelleVoie &&
                        userInfo?.LibelleVoie) || "Non renseignée" }
                    </div>
                    {userInfo?.ComplementAdresse && (
                        <div>{userInfo.ComplementAdresse}</div>
                    )}
                    <div
                        css={{
                            display: "inline-block",
                        }}
                    >
                        {userInfo?.CodePostal}
                    </div>
                    <div
                        css={{
                            display: "inline-block",
                            marginLeft: "30px",
                        }}
                    >
                        {userInfo?.CommuneDesignation}
                    </div>
                </div>
            </div>
        );
    };

    const piecesJustificatives = () => {
        return (
            <div>
                <ul
                    css={{
                        padding: "0",
                        fontFamily: "Helvetica",
                    }}
                >
                    {userPieceJustifList &&
                        userPieceJustifList?.map((userPiece) => {
                            return (
                                <PieceJustifLine
                                    typePiece={typePiecesJustifList?.find(
                                        (type) =>
                                            type.Id ===
                                            userPiece.TypePieceByProfilId
                                    )}
                                    pieceInfo={userPiece}
                                />
                            );
                        })}
                </ul>
            </div>
        );
    };

    const spanTauxCss = {
        minWidth: "30%",
        display: "inline-block",
    };
    const datePASElement = (date?: Date) => {
        if(!date) {
            return "jamais"
        } 
        return format(parseISO(date.toString()),"dd/MM/yy")
    } 

    const informationsPAS = () => {
        return (
            <List>
                <ListItem>
                    <span style={spanTauxCss}>

                        CSG : {userInfo?.IsCSGExonere ? "exonérée" : "due"}
                        
                    </span>
                    <span css={{ marginLeft: "20px" }}>
                        Dernière mise à jour le {datePASElement(userInfo?.DateCSGUpdated) }
                        
                    </span>
                </ListItem>
                <ListItem>
                    <span style={spanTauxCss}>
                        CRDS : {userInfo?.IsCRDSExonere ? "exonérée" : "due"}
                    </span>
                    <span css={{ marginLeft: "20px" }}>
                        Dernière mise à jour le {datePASElement(userInfo?.DateCRDSUpdated)}
                    </span>
                </ListItem>
                <ListItem>
                    <span style={spanTauxCss}>
                        IR : {userInfo?.IsIRExonere ? "exonérée" : "due"}
                       
                    </span>
                    <span css={{ marginLeft: "20px" }}>
                        Dernière mise à jour le {datePASElement(userInfo?.DateIRUpdated)}
                    </span>
                </ListItem>
                <ListItem>
                    <span style={spanTauxCss}>
                        PS : {userInfo?.IsPSExonere ? "exonérée" : "due"}
                        
                    </span>
                    <span css={{ marginLeft: "20px" }}>
                        Dernière mise à jour le {datePASElement(userInfo?.DatePSUpdated)}
                    </span>
                </ListItem>
            </List>
        );
    };

    return (
        <Container
            css={{
                marginTop: "30px",
            }}
        >
            <LoadingSpinner>
                <LightEditableBlock
                    title="Description"
                    editable={
                        loginProps.oauth?.profilCode === ProfilCode.VILLYZ
                    }
                    editor={(close) => (
                        <UpdateProfilStatusForm
                            userInfo={userInfo}
                            close={() => {
                                setReload(true);
                                close();
                            }}
                        />
                    )}
                >
                    <ProfilCompletionHeader
                        status={userInfo?.OnboardingStatus}
                        kycErrorCount={Math.max(
                            ...userPieceJustifList?.map(
                                (userPiece) => userPiece.ErrorCount || 0
                            )
                        )}
                    />
                </LightEditableBlock>
                <EditableBlock
                    title="Blocage du compte"
                    editable
                    editor={(close) => (
                    <BlockAccountModal 
                        userId={userId ?? 0}
                        isBloque={userInfo?.IsBloque ?? false}
                        onSubmitSuccess={() => {
                            close()
                            setReload(true)
                        }}
                    />
                    )}
                >
                    <p>L'utilisateur est actuellement {userInfo?.IsBloque ? "bloqué" : "actif"}</p>
                </EditableBlock>
                <Grid stackable doubling>
                    <Grid.Column width={8}>
                        <EditableBlock
                            title="Adresse mail"
                            editable={true}
                            editor={(close) => (
                                <ChangeMailFormAdmin
                                    userId={userId}
                                    onSubmitSuccess={(
                                        response: MailUpdateResponse
                                    ) => {
                                        setReload(true);
                                        close();
                                    }}
                                />
                            )}
                        >
                            {adresseMail()}
                        </EditableBlock>
                        <EditableBlock
                            title="Pièces justificatives"
                            editable={true}
                            editor={(close) => (
                                <OnboardingInvestisseurParticulierDocumentListForm
                                    userId={userId}
                                    onActionSuccess={(
                                        response: MultipleFileRegistrationResponse
                                    ) => {
                                        setUserPieceJustifList(
                                            response.UserPieceList!
                                        );
                                        setUserInfo(response.UserInfo);
                                        close();
                                    }}
                                />
                            )}
                        >
                            {piecesJustificatives()}
                        </EditableBlock>
                        {userInfo?.OnboardingStatus?.Code ===OnboardingStatus.ERREUR && (
                            <div>
                                <SubmitButton 
                                    label="Valider les modifications" 
                                    action={() => userService.validateModificationsByAdmin({UserId: userId}, loginProps?.oauth, loginProps?.oauth?.userId)}
                                    onActionSuccess={() => setReload(true)}
                                    />
                            </div>
                        )} 
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <EditableBlock
                            title="Etat civil"
                            editable={true}
                            editor={(close) => (
                                <SubscriptionFormEtatCivilNew
                                    userId={userId}
                                    userInfo={userInfo}
                                    onSubmitSuccess={(
                                        response: OnboardingCitoyenResponse
                                    ) => {
                                        setUserInfo(response.UserInfo);
                                        close();
                                    }}
                                />
                            )}
                        >
                            {etatCivil()}
                        </EditableBlock>
                        <EditableBlock
                            title="Adresse postale"
                            editable={true}
                            editor={(close) => (
                                <SubscriptionAdressePostaleForm
                                    userId={userId}
                                    userInfo={userInfo}
                                    onSubmitSuccess={(
                                        response: OnboardingCitoyenResponse
                                    ) => {
                                        setUserInfo(response.UserInfo);
                                        close();
                                    }}
                                />
                            )}
                        >
                            {adressePostale()}
                        </EditableBlock>
                        {/************** ici le bloc PAS ********************/}
                        <EditableBlock
                            title="Informations PAS"
                            editable={true}
                            editor={(close) => 
                                <InformationsPASform 
                                    userInfo={userInfo!}
                                    onSubmitSuccess={() => {
                                        setReload(true) 
                                        close()
                                    }}/>
                        }
                        >
                            {informationsPAS()}
                        </EditableBlock>
                        {/********** ici le bloc PAS *********************/}
                    </Grid.Column>
                </Grid>

                <DetailOperation
                    userId={userId}
                    investHistoryList={investHistoryList}
                    remboursementHistoryList={remboursementHistoryList}
                    retraitHistoryList={retraitHistoryList}
                    userPieceJustifList={userPieceJustifList}
                    askReload={(value: boolean) => setReload(value)}
                />
            </LoadingSpinner>
        </Container>
    );
}
