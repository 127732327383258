export interface Label {
    Code?: string,
    Libelle?: string
}

const HQE: string = "HQE"
const BBC: string = "BBC"
const AUTRE: string = "AUT"

export const Label = {
    HQE,
    BBC,
    AUTRE
}