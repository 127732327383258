import React, { FormEvent, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  CheckboxProps,
  Container,
  Form,
  Grid,
  Modal,
  Radio,
} from "semantic-ui-react";
import { Profil } from "../../model/entities/profil";
import passwordValidator from "password-validator";
import { userService } from "../../services/authentication-redux-service";
import { ApplicationState } from "../../store";
import {
  LOGIN_FAILURE,
  LOGIN_SUCCESSFUL,
  LOGOUT,
  REQUEST_IDENTITY,
  REQUEST_LOGIN,
} from "../../store/authentication/types";
import { OAuthResponse } from "../../model/authentication/oauth-response";
import { UserInfoResponse } from "../../model/dto/response";
import {RegisterBody} from "../../model/dto/body/register-body";
import { SubscriptionInvestisseurForm } from "./subscription-Investisseur-form";
import { SubscriptionCollectiviteForm } from "./subscription-collectivite-form";
import { NavLink } from "react-router-dom";
import { SubmitButton } from "../generic/submit-button";
import { InputField } from "../generic/input-field";

type FieldData = {
  value?: any,
  name?: string,
  isValid?: boolean
};

type FormData = {
  username: FieldData,
  password: FieldData,
  newAdresseMail: FieldData,
  newPassword: FieldData,
  confirmationPsw: FieldData,
  cgu:FieldData,
  newType: FieldData,
  isMarketingMailsAllowed?: FieldData
}
 
function Login({ dispatchProps }: any) {
  const [form, setForm] = useState<FormData>({
    username : { name : "username", value : "", isValid: true},
    password : { name : "password", value : "", isValid: true},
    newAdresseMail : { name : "newAdresseMail", value : "", isValid: true},
    newPassword : { name : "newPassword", value : "", isValid: true},
    confirmationPsw : { name : "confirmationPsw", value : "", isValid: true},
    cgu : { name : "cgu", value : "", isValid: false},
    newType : {name : "newType", value : undefined, isValid: true},
    isMarketingMailsAllowed: {name: "isMarketingMailsAllowed", value: "false", isValid: true}
  })

  const [displayDisclaimer, setDisplayDisclaimer] = useState(false);

  const [loginError, setLoginError] = useState("");
  const [signupError, setSignupError] = useState("");

  const [newUsername, setNewUsername] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [isFormError, setIsFormError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [mailError, setMailError] = useState(false)

  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);

  function onLoginSubmit(e: React.FormEvent<HTMLFormElement>) {
    console.log('e', e)
    e.preventDefault();
    const data = new FormData(e.target as HTMLFormElement);
    const formData = Object.fromEntries(data) as { [key: string]: string };
    console.log('formData', formData)
    loginUser(formData.username.trim(), formData.password, false, true);
  }

  function loginUser(username: string, password: string, verifyIdentityStep = false, directLogin = false) {
    userService
      .login2(username, password)
      .then((response) => {
        if (response.status !== 200) {
          dispatchProps.loginFailure?.();
          throw new Error();
        }
        return response.json();
      })
      .then((user: OAuthResponse) => {
        localStorage.setItem("oauth_user", JSON.stringify(user));
        if (user.error) {
          dispatchProps.loginFailure?.();
          throw new Error();
        }
        dispatchProps.loginSuccessful?.(user);
        if(!verifyIdentityStep) {
          userService
          .getUserInfo(user.userId, user)
          .then((response) => {
            localStorage.setItem("user_info", JSON.stringify(response));
            dispatchProps.requestIdentity?.(response);
            if(directLogin) {
              if(user.profilCode === Profil.INVESTISSEUR) {
                window.location.href = "/dashboard/citoyen"
              }
              else if (user.profilCode === Profil.PORTEUR_PROJET) {
                window.location.href = "dashboard/collectivite"
              }
              else if (user.profilCode === Profil.VILLYZ_USER) {
                window.location.href = "dashboard/admin"
              }
            }
          });
        }
        else {
          if(directLogin) {
            if(user.profilCode === Profil.INVESTISSEUR) {
              window.location.href = "/dashboard/citoyen"
            }
            else if (user.profilCode === Profil.PORTEUR_PROJET) {
              window.location.href = "dashboard/collectivite"
            }
            else if (user.profilCode === Profil.VILLYZ_USER) {
              window.location.href = "dashboard/admin"
            }
          }
        }
      })
    .catch(() =>
      setLoginError(
        "Impossible de se connecter. Vérifiez votre adresse mail et mot de passe"
      )
    );
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fieldValue: string = e.target.value;
    const fieldName = e.target.name;
    const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName, isValid: true } };
  
    setForm({ ...form,  ...newField});
    setIsFormError(false)
    setSignupError("")
}

  const handleMailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setMailError(false)
    handleInputChange(e)
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    const isValid = validatePassword(value) as boolean
    setPasswordError(!isValid)
    handleInputChange(e)
  }

  function isFormValid() : boolean {
    const regex = /^[\w.+-]{1,64}@([a-zA-Z\d-]{2,252}\.[a-zA-Z\.]{2,7})$/
    if(!regex.test(form.newAdresseMail.value)) {
      setMailError(true)
      return false
    }

    if (!validatePassword(form.newPassword.value)) {
      setSignupError(
        "Votre mot de passe doit faire au moins 8 caractères et contenir au moins  une majuscule, une minuscule et un chiffre.​"
      );
      return false;
    }

    if (!form.cgu.isValid) {
      setSignupError("Vous devez accepter les conditions d'utilisation");
      return false
    }

    if (!form.newType.value) {
      setSignupError("Vous devez saisir un profil d'utilisateur");
      return false
    }

    if (form.newPassword.value !== form.confirmationPsw.value) {
      setSignupError("Les mots de passe ne correspondent pas");
      return false
    }
    
    return true;
  }

  function handleCheckBoxChange(event: FormEvent<HTMLInputElement>, data: CheckboxProps){
    const fieldName = data.name
    let value = false
    if(data.value === "ok") {
      value = true
    }
    const newField: FieldData = { [fieldName!]: { value: value, name: fieldName, isValid: data.checked } };
  
    setForm({ ...form,  ...newField});
    setIsFormError(false);
    setSignupError("");
  }

  function onSubmitSuccess(response:Response){
    setNewUsername(form.newAdresseMail.value)
    setNewPassword(form.newPassword.value)
    loginUser(form.newAdresseMail.value, form.newPassword.value, false, false)
    setIsOnboardingModalOpen(true)
  }

  function onSubmitFailed(response : any){
    if(response.Error){
      setSignupError(response.Error);
    } else {
      setSignupError(
        "Erreur lors de l'inscription. Vérifiez que vous avez correctement saisi tous les champs."
      );
    }
  }

  const body : RegisterBody = {
    Email : form.newAdresseMail.value,
    Password : form.newPassword.value,
    ConfirmPassword : form.confirmationPsw.value,
    ProfilCode : form.newType.value,
    IsMarketingMailsAllowed: form.isMarketingMailsAllowed?.value
  }

  return (
    <div className="pt-12">
      <Container>
        <Grid stackable>
          <Grid.Column width={8}>
            <h1 css={{color: "#085c74", marginBottom: "15px !important"}}>Connexion</h1>
            <Card fluid>
              <Card.Content>
                <Form onSubmit={onLoginSubmit}>
                  <Form.Field>
                    <InputField 
                      data={form.username}
                      type="email"
                      label="Adresse mail"
                      required={true}  
                      autocomplete="email"
                      onChange={handleInputChange}               
                    />
                  </Form.Field>
                  <Form.Field>
                    <InputField 
                      data={form.password}
                      type="password"
                      label="Mot de passe"
                      required={true}  
                      autocomplete="current-password"
                      onChange={handleInputChange}                
                    />
                  </Form.Field>
                  <div className="card-action center">
                    <button 
                      type="submit" 
                      className="btn btn-primary" 
                      css={{
                        marginRight: "5px",
                        height : "35px", 
                        marginBottom:"2px"
                      }}
                    >
                      Connexion
                    </button>
                    <Button as={NavLink} to="/forgotPassword">
                      J'ai oublié mon mot de passe
                    </Button>
                  </div>
                </Form>
                {loginError && <p className="text-red-600">{loginError}</p>}
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={8}>
            <h1 css={{color: "#085c74", marginBottom: "15px !important"}}>Inscription</h1>
            <Card fluid>
              <Card.Content>
                <Form>
                  <Form.Field>
                    <Radio
                      label="Je suis un investisseur"
                      name="type"
                      value={Profil.INVESTISSEUR}
                      checked={form.newType.value === Profil.INVESTISSEUR}
                      onClick={() => setForm({...form, newType : {name : "newType", value : Profil.INVESTISSEUR, isValid : true}})}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Je suis un porteur de projet"
                      name="type"
                      value={Profil.PORTEUR_PROJET}
                      checked={form.newType.value === Profil.PORTEUR_PROJET}
                      onClick={() => setForm({...form, newType : {name : "newType", value : Profil.PORTEUR_PROJET, isValid : true}})}
                    />
                  </Form.Field>
                  <Form.Field>
                    <InputField 
                      data={form.newAdresseMail}
                      required={true}   
                      type="email"
                      label="Adresse mail"
                      autocomplete="email"      
                      onChange={handleMailChange}                
                    />
                    {mailError && <p className="text-red-600">Votre adresse mail présente une erreur de format.</p>}
                  </Form.Field>
                  <Form.Field>
                    <InputField 
                      data={form.newPassword}
                      required={true}   
                      type="password"
                      label="Mot de passe"   
                      autocomplete="new-password"   
                      onChange={handlePasswordChange}             
                    />
                    {passwordError && <p className="text-red-600">Votre mot de passe doit faire au moins 8 caractères et contenir au moins  une majuscule, une minuscule et un chiffre.</p>}
                  </Form.Field>
                  <Form.Field>
                    <InputField 
                      data={form.confirmationPsw}
                      required={true}  
                      type="password"
                      autocomplete="new-password"
                      label="Confirmer le mot de passe"  
                      onChange={handleInputChange}             
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      required
                      name="cgu"
                      value="ok"
                      label= 
                        {<label>
                          <div
                            onClick={(event)=>{
                              // Cette méthode va empêcher la propagation de l'événement 'Click'
                              event.stopPropagation()
                            }}
                          >
                            <p>Je reconnais avoir pris connaissance et accepté les :</p>
                            <p>
                              <a 
                                href="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/cgu.pdf" 
                                target="_blank"
                              >
                                Conditions d’utilisation de Villyz 
                              </a>
                            </p>
                            <p>
                              <a
                                href="https://www.mangopay.com/terms/PSP/PSP_MANGOPAY_FR.pdf"
                                target="_blank"
                              >
                                Conditions d’utilisation de Mangopay
                              </a>
                            </p>
                          </div>
                        </label>}
                      onChange={handleCheckBoxChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      name="isMarketingMailsAllowed"
                      value="ok"
                      label={
                        <label><span
                          onClick={(event)=>{
                            // Cette méthode va empêcher la propagation de l'événement 'Click'
                            event.stopPropagation()
                          }}
                        >
                          J’accepte de recevoir des informations liées aux projets publiés ainsi qu’à l’activité de la plateforme.
                        </span></label>
                        }
                      onChange={handleCheckBoxChange}
                    />
                  </Form.Field>

                  {displayDisclaimer && (
                    <p className="text-sm">
                      Vous pouvez exercer vos droits d’accès, de rectification
                      et de suppression à l’adresse postale et/ou électronique
                      suivantes : rgpd@villyz.fr DPO 35 avenue
                      Reille, 75014 Paris
                    </p>
                  )}
                    <SubmitButton 
                      label = "Inscription"
                      action = {() => userService.registerNewAccount(body)}
                      onActionSuccess = {onSubmitSuccess}
                      validateForm = {isFormValid}
                      onActionFailure = {onSubmitFailed}
                    />
                  {signupError && <p className="text-red-600">{signupError}</p>}
                </Form>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>

        <Modal
          onClose={() => setIsOnboardingModalOpen(false)}
          open={isOnboardingModalOpen}
          closeOnDocumentClick={false}
          closeOnEscape={false}
          closeOnPortalMouseLeave={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Inscription</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <div css={{
                overflowX: 'hidden'
              }}>
                {form.newType.value === Profil.INVESTISSEUR ? (
                  <SubscriptionInvestisseurForm
                    onFinishOnboarding={() => loginUser(newUsername, newPassword)}
                  />
                ) : form.newType.value === Profil.PORTEUR_PROJET ? (
                    <SubscriptionCollectiviteForm
                      onSubmitIdentificationSuccess={() => loginUser(newUsername, newPassword, false)}
                      onFinishOnboarding={() => loginUser(newUsername, newPassword)}
                    />
                ) : (
                  <div></div>
                )}
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchProps: {
    loginRequest: () => dispatch({ type: REQUEST_LOGIN }),
    loginSuccessful: (authResponse: OAuthResponse) =>
      dispatch({ type: LOGIN_SUCCESSFUL, authResponse: authResponse }),
    loginFailure: () => dispatch({ type: LOGIN_FAILURE }),
    logOut: () => dispatch({ type: LOGOUT }),
    requestIdentity: (userInfo: UserInfoResponse) =>
      dispatch({ type: REQUEST_IDENTITY, user: userInfo }),
  },
});

const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(Login);
export { ConnectedLogin as Login };

const pwdSchema = new passwordValidator();
pwdSchema
  .is()
  .min(8)
  .is()
  .max(50)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(1)
  .has()
  .not()
  .spaces();

function validatePassword(password: string) {
  return pwdSchema.validate(password)
}
