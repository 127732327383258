import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { TypeCollectivite } from "../../model/entities/type-collectivite"
import { TypePieceByProfil } from "../../model/entities/type-piece-by-profil"
import { TypePieceJustificative } from "../../model/entities/type-piece-justificative"
import { AuthenticationState } from "../../model/state/authentication-state"
import { ApplicationState } from "../../store"
import {FileUpload} from "../generic/file-upload"

type Props = {
    typePiece: TypePieceByProfil,
    verso?: boolean,
    obligatoire?: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>, isVerso?:boolean, isAlreadyUploaded?: boolean) => void,
    onClickCheckBox?: (isVersoAsked : boolean) => void,
    validateDocument?: (file?: File) => boolean
}

export function OnboardingDocumentLineForm({typePiece,verso,obligatoire, onChange,onClickCheckBox, validateDocument }: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    // Pour un article of association collectivité on précise, la qualité du représentant dans le libellé de la pièce
    const designationPieceJustif = useMemo(() => {
        const baseString = obligatoire ? typePiece.LibellePieceJustif + "*" :  typePiece.LibellePieceJustif
        if(typePiece.TypePieceCode === TypePieceJustificative.ARTICLES_OF_ASSOCIATION) {
            return baseString?.replace('%type_representant%', loginProps.oauth?.typeCollectiviteCode === TypeCollectivite.COMMUNE ? 'Maire' : 'Président')
        }
        return baseString
    }, [])
    
    return( <>
        <FileUpload
            libelle={designationPieceJustif}
            isLimited={true} 
            onChange={onChange} 
            verso={verso}
            onClickCheckBox = {onClickCheckBox}
            validateField={validateDocument}
        />
        </>
    )
}