import React, { useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useState } from "react";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { OnboardingCitoyenResponse, UserInfoResponse } from "../../model/dto/response";
 
import { Container, Form, Icon} from "semantic-ui-react";
 import { InputField } from "../generic/input-field";
import { SaveValeurPatrimonialeBody } from "../../model/dto/body/save-valeur-patrimoniale-body";
import { SubmitButton } from "../generic/submit-button";
import { userService } from "../../services/authentication-redux-service";
import { FormErrorHeader } from "../generic/form-error-header";
import { SimulateurForm } from "./simulateur-form";

type Props = {
  userInfo?: UserInfoResponse;
  isOnboarding?: boolean;
  onSubmitSuccess?: (response: OnboardingCitoyenResponse) => void;
  onCancel?: () => void;
};
type FieldData = {
  value?: any,
  name?: string,
  isValid?: boolean
};

type Form = {
    valeurPatrimoniale: FieldData,
}
function CapaciteInvestissementForm({
  isOnboarding,
  userInfo,
  onSubmitSuccess,
  onCancel
}: Props) {

    const [form, setForm] = useState<Form>({
      valeurPatrimoniale: {value: userInfo?.ValeurPatrimonialeNette ?? 0, name: 'valeurPatrimoniale', isValid: true},
    })
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [isFormError, setIsFormError] = useState(false)

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
      const fieldValue: string = e.target.value;
      const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName, isValid: isValid } };
    
      setForm({ ...form,  ...newField});
      setIsFormError(false)
    }

    const validateMandatory = (value: any): boolean => {
      if(!value || value === "") {
          return false
      }
      return true
    }

    const isFormValid = (): boolean => {
      return validateMandatory(form.valeurPatrimoniale.value);
    };

    const onFormNotValid = (): void => {
      setIsFormError(true);
    };

    const estimatedValue = useMemo(() => {
      return (form.valeurPatrimoniale.value * 0.1).toFixed(2);
    }, [form.valeurPatrimoniale]);
   
    const body: SaveValeurPatrimonialeBody = {
      ValeurPatrimoniale: form.valeurPatrimoniale.value
    }

  return (
    <Container>
        <FormErrorHeader
          message="Merci de renseigner votre valeur patrimoniale nette"
          displayed={isFormError}
        />
        <div css={{paddingBottom : "10px", 
                   color:"#424949",
                   fontSize:"14px"
        }}>
            Afin de vous guider au mieux dans les investissements que vous allez effectuer sur la plateforme, si vous le souhaitez, vous pouvez nous transmettre votre capacité d’investissement.
        </div>
        <div 
          css=
            {{ 
              color:"#424949",
              fontSize:"14px"
            }}
        >
            Pour cela veuillez utiliser le simulateur ci-dessous.
        </div>
        <div css={{height:"50px", display:"flex" , flexDirection:"row", justifyContent:"space-evenly", alignItems:"center"}}>
          <div css={{height:"50%", display:"flex" , flexDirection: "row", alignItems:"center"}}>
            <strong css={{display:"inline-block", marginRight:"10px"}}>
              Valeur Patrimoniale nette
            </strong>
            <Form.Field>
              <InputField
                data={form.valeurPatrimoniale}
                submitted={formSubmitted}
                editable={false}
                validateField={validateMandatory}
                onChange={handleInputChange}
                inputCss={{borderRadius:"5px ", margin:"0 5px 0 0", maxWidth:80}}
              />
            </Form.Field> 
            <strong>€</strong>
          </div> 
          <Icon 
            name="arrow alternate circle right" 
            size="big"
            css={{
              color: "#008080"
            }}
          />
          <p 
            css={{
              display:"inline-block"
              }}
            >
              Capacité d'investissement estimée : <strong> {estimatedValue} € </strong>
          </p>
        </div>

        <div css={{paddingBottom : "10px",
                   fontStyle:"italic", 
                   color:"#424949",
                   fontSize:"14px"
        }}>
            Votre valeur patrimoniale nette s'entend comme la somme de votre <strong>revenu net annuel</strong> et de vos <strong>actifs liquides</strong> diminuée de vos <strong>engagements financiers</strong>.
        </div>

        <SimulateurForm 
          onValidateSimulation={(valeurNette: number) => {
            const newField: FieldData = { 
              value: valeurNette, 
              name: 'valeurPatrimoniale', 
              isValid: true 
            };
            setForm({ ...form,  valeurPatrimoniale: newField});
          }}
        />
        
        <div style={{display:"flex", flexDirection:"row", marginBottom: "20px ", float:"right"}}>
          <SubmitButton 
              label="Quitter"
              onClick={onCancel}
          />
          <SubmitButton  
              label="Transmettre"
              data={body}
              onPreSubmit={() => {
                setFormSubmitted(true);
              } }
              action={() => userService.saveValeurPatrimoniale(body, loginProps?.oauth?.userId!, loginProps!.oauth)}
              validateForm={isFormValid}
              onFormNotValid={onFormNotValid}
              onActionSuccess={onSubmitSuccess}
            /> 
        </div>
    </Container>
 );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedCapaciteInvestissementForm = connect(
  mapStateToProps,
  null
)(CapaciteInvestissementForm);
export { ConnectedCapaciteInvestissementForm as CapaciteInvestissementForm };
