export interface TypeCollectivite {
    Code: string,
    Designation: string
}

const COMMUNE: string = "COM";
const DEPARTEMENT: string = "DPT";
const REGION: string = "REG";
const EPCI = "EPC"


export const TypeCollectivite = {
    COMMUNE,
    DEPARTEMENT,
    REGION,
    EPCI
}