export interface TypePieceFinance {
    Code?: string,
    Libelle?: string
}

const ANALYSE_VILLYZ: string = "ANV";
const INFORMATIONS_CLE: string = "INC";
const CONVENTION_MANDAT: string = "COM";
const EXTRAIT_JUDICIAIRE: string = "EXJ";
const TEMPLATE_HTML_CONTRAT: string = "THC"

export const TypePieceFinance = {
    ANALYSE_VILLYZ,
    INFORMATIONS_CLE,
    CONVENTION_MANDAT,
    EXTRAIT_JUDICIAIRE,
    TEMPLATE_HTML_CONTRAT
}