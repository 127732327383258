
import React, { useState } from "react"
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { DonneesImpotResponse, ReportingNewsletterMailListResponse, ReportingOnboardingUtilisateursResponse, ReportingStatusDesPaiementsResponse } from "../../../model/dto/response";
import { dataService } from "../../../services/data-service";
import { ApplicationState } from "../../../store";
import { AuthenticationState } from "../../../store/authentication/types";
import { EditableBlock } from "../../generic/editable-block/editable-block"
import { InputField } from "../../generic/input-field";
import { SubmitButton } from "../../generic/submit-button";
import * as isLeapYear from "dayjs/plugin/isLeapYear";
import { makeDownloadFromBase64 } from "../../../helpers/date-helper";
import { ExportReportingAnnuelBody } from "../../../model/dto/body/export-reporting-annuel-body";

type Props = {
}

type FieldData = {
    value?: any,
    name: string,
    isValid?: boolean,
    key?: string
};

type Form = {
    mois: FieldData,
    annee: FieldData
}

type FormAnnuel = {
    annee: FieldData
}

var dayjs = require("dayjs");
dayjs.extend(isLeapYear); // use plugin
dayjs.locale("fr-FR");

export function Reporting({}: Props) {
    const months= [
        { number : 1, label : 'Janvier' },
        { number : 2, label : 'Février' },
        { number : 3, label : 'Mars' },
        { number : 4, label : 'Avril' },
        { number : 5, label : 'Mai' },
        { number : 6, label : 'Juin' },
        { number : 7, label : 'Juillet' },
        { number : 8, label : 'Août' },
        { number : 9, label : 'Septembre' },
        { number : 10, label : 'Octobre' },
        { number : 11, label : 'Novembre' },
        { number : 12, label : 'Décembre' }
    ]

    const loginProps: AuthenticationState = useSelector<
        ApplicationState,
        AuthenticationState
    >((state) => state.authentication);

    const [form, setForm] = useState<Form>({
        mois: {value: 0, name: 'mois', isValid: true},
        annee:  {value: '2021', name: 'annee', isValid: true}
    })

    const [formAnnuel, setFormAnnuel] = useState<FormAnnuel>({
        annee:  {value: '2021', name: 'annee', isValid: true}
    })

    const handleInputAnnuelChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean, key?: string): void => {
        const fieldValue: string = e.target.value;
        setFormAnnuel({...formAnnuel, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid, key: key}})
    }

    const onSubmitAnnuelleSuccess = (response: DonneesImpotResponse) => {
        makeDownloadFromBase64(response.File!,"reportingAnnuel.csv");
    }
 
    const bodyAnnuel: ExportReportingAnnuelBody = {
        Year: formAnnuel.annee.value
    }

    const onSubmitNewsletterMailListSuccess = (response : ReportingNewsletterMailListResponse) => {
        makeDownloadFromBase64(response.File!, "ListeDeDiffusion.csv")
    }

    const onSubmitReportingOnboardingUtilisateursListSuccess = (response : ReportingOnboardingUtilisateursResponse) => {
        makeDownloadFromBase64(response.File!, "ReportingOnboarding.csv")
    }

    const onSubmitStatutsDesPaiementsListSuccess = (response : ReportingStatusDesPaiementsResponse) => {
        makeDownloadFromBase64(response.File!, "ReportingParcoursInvestisseurs.csv")
    }

    return (
        <>
            <EditableBlock
                title="Rapport AMF annuel"
                editor={() => null}
                editable={false}
            >
                <Form>
                    <Form.Field>
                        <InputField
                            data={formAnnuel.annee}
                            label="Annee"
                            onChange={handleInputAnnuelChange}
                            inputCss={{maxWidth:200}}
                        />
                    </Form.Field>

                    <SubmitButton 
                        data={bodyAnnuel}
                        label="Valider"
                        name="declarationAnnuelleButton"
                        action={() => dataService.getReportingAnnuel(bodyAnnuel, loginProps?.oauth)}
                        onActionSuccess={onSubmitAnnuelleSuccess}
                    />
                </Form>
            </EditableBlock>
            <EditableBlock
                title="Liste de diffusion"
                editor={() => null}
                editable={false}
            >
                <Form>
                    <Form.Field>
                        <p>Générer un fichier .csv contenant les utilisateurs inscrits sur la liste de diffusion</p>
                    </Form.Field>

                    <SubmitButton 
                        label="Générer"
                        name="listeDiffusionButton"
                        action={() => dataService.GetAllMailNewsletterInscrits(loginProps?.oauth)}
                        onActionSuccess={onSubmitNewsletterMailListSuccess}
                    />
                </Form>
            </EditableBlock>
            <EditableBlock
                title="Reporting Onboarding"
                editor={() => null}
                editable={false}
            >
                <Form>
                    <Form.Field>
                        <p>Générer un fichier .csv contenant l'état des comptes utilisateurs</p>
                    </Form.Field>

                    <SubmitButton 
                        label="Générer"
                        name="onboardingUtilisateursButton"
                        action={() => dataService.GetReportingOnboardingUtilisateurs(loginProps?.oauth)}
                        onActionSuccess={onSubmitReportingOnboardingUtilisateursListSuccess}
                    />
                </Form>
            </EditableBlock>
            <EditableBlock
                title="Reporting Statuts Des Paiements"
                editor={() => null}
                editable={false}
            >
                <Form>
                    <Form.Field>
                        <p>Générer un fichier .csv contenant les statuts des paiements investisseurs</p>
                    </Form.Field>

                    <SubmitButton 
                        label="Générer"
                        name="StatutsDesPaiementsButton"
                        action={() => dataService.GetReportingStatutsDesPaiements(loginProps?.oauth)}
                        onActionSuccess={onSubmitStatutsDesPaiementsListSuccess}
                    />
                </Form>
            </EditableBlock>
        </>
    )
}