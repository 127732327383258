import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { AuthenticationState } from "../../../store/authentication/types"
import { ApplicationState } from "../../../store"
import { AskEcheanceForProjetResponse, NextEcheanceProjetResponse } from "../../../model/dto/response"
import { SubmitButton } from "../../generic/submit-button"
import { investmentService } from "../../../services/investment-service"
import { LoadingSpinner } from "../../generic/loading-spinner"
import dayjs from "dayjs"

type Props = {
    projetId?: number
}

export function ForceEcheanceForm({ projetId }: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const [nextEcheance, setNextEcheance] = useState<NextEcheanceProjetResponse>()
    const [isResponseDisplayed, setIsResponseDisplayed] = useState(false)
    const [virementInfo, setVirementInfo] = useState<AskEcheanceForProjetResponse>()

    useEffect(() => {
        investmentService.getNextEcheanceForProjet(projetId!, loginProps?.oauth)
        .then(response => {
            setNextEcheance(response)
        })
    }, [])

    const onSubmitSuccess = (response: AskEcheanceForProjetResponse) => {
        setIsResponseDisplayed(true)
        setVirementInfo(response)
    }

    return (
        <LoadingSpinner area="modal">
            {!isResponseDisplayed && (
                <>
                    <p>Souhaitez-vous réellement forcer le déclenchement de la prochaine échéance ?</p>
                    <p>Cette action va initier la création du virement pour l'échéance prévue le {dayjs(nextEcheance?.DateEcheance).format("DD/MM/YYYY")} d'un montant de {(nextEcheance?.Montant ?? 0 )/ 100}€</p>
                    <p>Vous devrez communiquer les informations suivantes au porteur de projet et vous assurer qu'il réalise son paiement effectif le plus proche de la date réelle d'échéance</p>
                    <SubmitButton 
                        label="Confirmer l'opération"
                        action={() => investmentService.askEcheanceForProjet(projetId!, loginProps.oauth)}
                        onActionSuccess={onSubmitSuccess}
                        loaderArea="modal"
                    />
                </>
            ) || (
                <>
                    <p>Une prévision de virement a été correctement initiée pour la résolution de l'échéance du {nextEcheance?.DateEcheance?.toString()}</p>
                    <p>Le montant attendu est de {(nextEcheance?.Montant ?? 0 )/ 100}€</p>
                    <p>Les caractéristiques du virement à déclencher sont les suivantes :</p>
                    <li>
                        <ul>IBAN : {virementInfo?.IBAN}</ul>
                        <ul>BIC : {virementInfo?.BIC}</ul>
                        <ul>Référence : {virementInfo?.WireReference}</ul>
                        <ul>Identifiant opération MangoPay : {virementInfo?.MangoPayLongPayinId}</ul>
                    </li>
                    <p>Veuillez noter précieusement ces informations. Si vous les oubliez, vous pourrez les retrouver dans le dashboard MangoPay</p>
                </>
            )}
            
        </LoadingSpinner>
    )
}