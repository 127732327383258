import React, { useState } from "react"
import { Form } from "semantic-ui-react"
import { OnboardingInvestisseurResponse, ProfilCode, UserInfoResponse } from "../../model/dto/response";
import { SubmitButton } from "../generic/submit-button"
import { SimpleButton } from "../generic/simple-button"
import { Collectivite } from "../../model/entities/profil";
import { CollectiviteSelector } from "../generic/collectivite-selector/collectivite-selector"
import { userService } from "../../services/authentication-redux-service"
import { RegisterAdresseCollectiviteBody } from "../../model/dto/body/register-adresse-collectivite-body";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { connect, useDispatch } from "react-redux";
import { InputField } from "../generic/input-field";
import { FormErrorHeader } from "../generic/form-error-header";
import { RegisterAdresseCollectiviteAdminBody } from "../../model/dto/body/register-adresse-collectivite-admin-body";
import { AuthenticationAction, CHANGE_IDENTITY } from "../../actions/authentication-actions";
import { SubFlowStep } from "../../model/entities/sub-flow-step";
import { FlowStep } from "../../model/entities/flow-step";
import { referenceService } from "../../services/reference-service";

type Props = {
    loginProps?: AuthenticationState,
    userInfo?: UserInfoResponse,
    isOnboarding?: boolean,
    workflowTypeCode?:string,
    onSkipOnboarding?: (response: Response) => void,
    onSubmitSuccess: (response: Response) => void
}

type FieldData = {
    value?: any,
    name?: string,
    isValid?: boolean
};

type FormData = {
    libelleVoie: FieldData,
    adresseLigne2: FieldData,
    codePostal: FieldData,
    communeId: FieldData
}

function SubscriptionAdresseRepresentant({loginProps, userInfo, isOnboarding,workflowTypeCode, onSkipOnboarding, onSubmitSuccess}: Props) {

    const dispatch = useDispatch()
    
    const validateMandatory = (value: any): boolean => {
        return value !== undefined && value !== ""
    }

    const validatePostalCode = (value: any): boolean => {
        const regex = /^[0-9]{5}$/g;
        return regex.test(value);
      };
    
    const [formRepresentantLegal, setFormRepresentantLegal] = useState<FormData>({
        libelleVoie: {value: userInfo?.Etablissement?.LibelleVoieRepresentant || '', name: 'libelleVoie', isValid: validateMandatory(userInfo?.Etablissement?.LibelleVoieRepresentant || '')},
        adresseLigne2: {value: userInfo?.Etablissement?.ComplementAdresseRepresentant || '', name: 'adresseLigne2', isValid: true},
        codePostal: {value: userInfo?.Etablissement?.CodePostalRepresentant || '', name: 'codePostal', isValid: validateMandatory(userInfo?.Etablissement?.CodePostalRepresentant || '')},
        communeId: {value: userInfo?.Etablissement?.CommuneIdRepresentant || '', name: 'communeId', isValid: validateMandatory(userInfo?.Etablissement?.CommuneIdRepresentant || '')}
    })

    const [isFormError, setIsFormError] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName, isValid: isValid } };
      
        setFormRepresentantLegal({ ...formRepresentantLegal,  ...newField});
        setIsFormError(false)
    }

    const isFormValid = (): boolean => {
        return formRepresentantLegal.libelleVoie.isValid! && 
            formRepresentantLegal.adresseLigne2.isValid! && 
            formRepresentantLegal.codePostal.isValid! &&
            formRepresentantLegal.communeId.isValid!
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
    }

    const onSubmitRepresentantLegalSuccess = (response: OnboardingInvestisseurResponse) => {
        if(response.UserInfo) {
            const action: AuthenticationAction = { type: CHANGE_IDENTITY, user: response.UserInfo }
            localStorage.setItem("user_info", JSON.stringify(response.UserInfo))
            dispatch(action)
        }
        onSubmitSuccess(response)
    }

    const bodyAdresseRepresentant: RegisterAdresseCollectiviteBody = {
        LibelleVoie: formRepresentantLegal.libelleVoie.value,
        CodePostal: formRepresentantLegal.codePostal.value,
        ComplementAdresse: formRepresentantLegal.adresseLigne2.value,
        CommuneId: formRepresentantLegal.communeId.value,
        CurrentFormStep: SubFlowStep.ADRESSE_REPRESENTANT_LEGAL,
        WorkflowTypeCode: workflowTypeCode
    }

    const bodyAdmin: RegisterAdresseCollectiviteAdminBody = {
        UserId: userInfo?.UserId,
        LibelleVoie: formRepresentantLegal.libelleVoie.value,
        ComplementAdresse: formRepresentantLegal.adresseLigne2.value,
        CodePostal: formRepresentantLegal.codePostal.value,
        CommuneId: formRepresentantLegal.communeId.value
    }

    const skipToNextStepKey = () => {
        const body = {
            WorkflowTypeCode : workflowTypeCode!,
            CurrentFlowStep : FlowStep.ADRESSES,
            RessourceId:  loginProps?.user?.UserId!,
            SubFlowStep: SubFlowStep.ADRESSE_REPRESENTANT_LEGAL,
            IsSkiped: true
        }
        return referenceService.getNextStep(body).then(response => {
            onSkipOnboarding && onSkipOnboarding(response)})
      }


    return (
        <Form>
            {isOnboarding && (<h1>Adresse du représentant légal</h1>)}
            <FormErrorHeader 
                message="Veuillez corriger les champs en erreur"
                displayed={isFormError}
            />
            <Form.Group>
                <Form.Field width={16}>
                    <InputField 
                        data={formRepresentantLegal.libelleVoie}
                        label="Adresse*"
                        submitted={formSubmitted}
                        onChange={handleInputChange}
                        autocomplete="address-line1"
                        error="Merci de renseigner votre adresse"
                        validateField={validateMandatory}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Field width={16}>
                <InputField 
                    data={formRepresentantLegal.adresseLigne2}
                    label="Complément d'adresse"
                    autocomplete="address-line2"
                    onChange={handleInputChange}
                />
            </Form.Field>
            <Form.Group>
                <Form.Field width={8}>
                    <InputField 
                        data={formRepresentantLegal.codePostal}
                        label="Code postal*"
                        autocomplete="postal-code"
                        submitted={formSubmitted}
                        onChange={handleInputChange}
                        error="Merci de renseigner votre code postal à 5 chiffres"
                        validateField={validatePostalCode}
                    />
                </Form.Field>
                <Form.Field width={8}>
                    <label>Commune*</label>
                    <CollectiviteSelector
                        type={Collectivite.COMMUNE}
                        useCodeUnique={false}
                        submitted={formSubmitted}
                        initialCode={formRepresentantLegal.communeId.value}
                        error="Merci de renseigner votre ville"
                        onChange={(value: any, isValid: boolean) => {
                            setFormRepresentantLegal({...formRepresentantLegal, communeId: {value: value, name: "communeId", isValid: isValid}});
                            setIsFormError(false)
                        }}
                        validateField={validateMandatory}
                    />
                </Form.Field>
            </Form.Group>

            <div css={{paddingBottom : "10px"}}>*champ obligatoire</div>

            <div className="button-bar">
                <SubmitButton 
                    data={bodyAdresseRepresentant}
                    label="Valider"
                    onPreSubmit={() => {setFormSubmitted(true)}}
                    action={() => {
                        if (loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET || loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR)
                        {
                            return userService.registerAdresseRepresentant(bodyAdresseRepresentant, loginProps?.user?.UserId, loginProps?.user?.Etablissement?.Id, loginProps?.oauth)
                        }
                        else
                        {
                            return userService.registerAdresseRepresentantByAdmin(bodyAdmin, loginProps?.oauth?.userId, userInfo?.Etablissement?.Id, loginProps?.oauth)
                        }
                    }}
                    onActionSuccess={onSubmitRepresentantLegalSuccess}
                    validateForm={isFormValid}
                    onFormNotValid={onFormNotValid}
                />
                {isOnboarding && (
                    <SimpleButton
                        label="Passer"
                        onClick={() => skipToNextStepKey()}
                    />
                )}
            </div>
        </Form>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedSubscriptionAdresseRepresentant = connect(mapStateToProps, null)(SubscriptionAdresseRepresentant);
export { ConnectedSubscriptionAdresseRepresentant as SubscriptionAdresseRepresentant };