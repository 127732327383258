export interface CategorieEtablissement {
    Code?: string,
    Libelle?: string
}

const COLLECTIVITE: string = "COL"
const SOCIETE: string = "SOC"
const CHU: string = "CHU"

export const CategorieEtablissement = {
    COLLECTIVITE,
    SOCIETE,
    CHU
}