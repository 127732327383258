export interface TypeEmprunt {
    Code?: string,
    Libelle?: string
}

const PRET: string = "PRT"
const TITRES: string = "TTR"
const OBLIGATIONS: string = "OBL"


export const TypeEmprunt = {
    PRET,
    TITRES,
    OBLIGATIONS
}