export interface StatutPieceJustificative {
    Code?: string,
    Libelle?: string
}

const NON_ENVOYE: string = "NEN"
const TRANSMISE: string = "TRA"
const EN_TRAITEMENT: string = "ETR"
const VALIDE: string = "VAL"
const EN_ERREUR: string = "ERR"
const PERIME: string = "PER"

export const StatutPieceJustificative = {
    NON_ENVOYE,
    TRANSMISE,
    EN_TRAITEMENT,
    VALIDE,
    EN_ERREUR,
    PERIME
}