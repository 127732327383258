import React, { useEffect, useMemo, useState } from "react"
import { Pagination, PaginationProps, Table } from 'semantic-ui-react'

type Props = {
    elements: any[]
    elementsPerPage: number,
    headers?: string[],
    columnSelector: (element: any) => ElementDescriptor
}

export type ElementDescriptor = {
    values: any[]
}

export function PaginatedTable({elements, elementsPerPage, headers, columnSelector}: Props) {
    const [activePage, setActivePage] = useState(1)
    const [activeElements, setActiveElements] = useState<any[]>([])

    useEffect(() => {
        if(elements) {
            const lastIndex = elementsPerPage * activePage <= elements.length ? elementsPerPage * activePage : elements.length
            const activeElements = elements.slice(elementsPerPage * (activePage - 1), lastIndex)
            setActiveElements(activeElements)
        }
    }, [elements, activePage])

    const totalPages = useMemo(() => {
        if(elements) {
            const result = elements.length % elementsPerPage !== 0 ? Math.trunc(elements.length / elementsPerPage) + 1 : elements.length / elementsPerPage
            return result
        }
        return 0
    }, [elements])

    return (
        <>
            <Table padded stackable compact>
                {headers && (
                    <Table.Header>
                        <Table.Row>
                        {headers.map(header => {
                            return (
                                <Table.HeaderCell key={header} css={{
                                    fontSize: '13px'
                                }}>{header}</Table.HeaderCell>
                            )
                        })}
                        </Table.Row>
                    </Table.Header>
                )}
                {activeElements && (
                    <Table.Body>
                        {activeElements.map((element, index) => {
                        return (
                            <Table.Row key={index}>
                                {columnSelector(element).values.map(columnValue => {
                                    return (
                                        <Table.Cell css={{
                                            fontSize: '13px'
                                        }}>
                                            {columnValue}
                                        </Table.Cell>
                                    )
                                })}
                            </Table.Row>
                        )
                    })}
                    </Table.Body>
                )}
            </Table>
            <Pagination 
                defaultActivePage={activePage} 
                totalPages={totalPages} 
                onPageChange={(event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
                    setActivePage(data.activePage as number)
                }} />
        </>
        
    )
}