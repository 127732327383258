import React, { useState } from "react"
import { SubmitButton } from "../generic/submit-button"

type Props = {

}

export function TestMangoPay({}: Props) {
    const [nbAppels, setNbAppels] = useState(0)

    const onAuthentifierAction = (): void => {
        setNbAppels(nbAppels + 1)
    }

    return (
        <div>
            <div className="form-group">Nombre de tests effectués : {nbAppels}</div>
            <div className="form-group">
                <SubmitButton 
                    label="Authentifier"
                    onActionSuccess={onAuthentifierAction}
                    onActionFailure={onAuthentifierAction}
                />
            </div>
            <div className="form-group">
                <SubmitButton 
                    label="Créer natural user"
                    onActionSuccess={onAuthentifierAction}
                    onActionFailure={onAuthentifierAction}
                />
            </div>
            <div className="form-group">
                <SubmitButton 
                    label="Créer legal user"
                    onActionSuccess={onAuthentifierAction}
                    onActionFailure={onAuthentifierAction}
                />
            </div>
            <div className="form-group">
                <SubmitButton 
                    label="Créer wallet"
                    onActionSuccess={onAuthentifierAction}
                    onActionFailure={onAuthentifierAction}
                />
            </div>
            <div className="form-group">
                <SubmitButton 
                    label="Créer compte bancaire"
                    onActionSuccess={onAuthentifierAction}
                    onActionFailure={onAuthentifierAction}
                />
            </div>
            <div className="form-group">
                <SubmitButton 
                    label="Créer mandat"
                    onActionSuccess={onAuthentifierAction}
                    onActionFailure={onAuthentifierAction}
                />
            </div>
            <div className="form-group">
                <SubmitButton 
                    label="Annuler mandat"
                    onActionSuccess={onAuthentifierAction}
                    onActionFailure={onAuthentifierAction}
                />
            </div>
        </div>
    )
}