import React from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button, Container, Icon } from "semantic-ui-react"
import { LOGOUT } from "../../actions/authentication-actions"
import { ApplicationState } from "../../store"

type Props = {
    dispatchProps: any
}

function Forbidden({dispatchProps}: Props) {
    const navigate = useNavigate();

    return (
        <Container css={{
            marginTop: '80px'
        }}>
            <h1>
                Contenu privé
            </h1>
            <div css={{
                marginTop: '30px',
                textAlign: 'center'
            }}>
                <Icon
                    name="exclamation triangle"
                    color="purple"
                    size="massive"
                />
                <div css={{
                    fontSize: '15px',
                    display: 'inline-block'
                }}>
                    <div>
                        Vous n'avez pas le droit d'accéder au contenu demandé.
                    </div>
                </div>
            </div>
            <div css={{
                marginTop: '20px',
                textAlign: 'center'
            }}>
                <div>
                <Button color="blue" size="big" onClick={() => navigate(`/`)}>
                        Retour à l'accueil
                    </Button>
                </div>
            </div>
        </Container>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication,
  });

const mapDispatchToProps = (dispatch: any) => ({
    dispatchProps: {
      logOut: () => dispatch({ type: LOGOUT })
    }
  });

  const ConnectedForbidden = connect(mapStateToProps, mapDispatchToProps)(Forbidden);
  export { ConnectedForbidden as Forbidden }
