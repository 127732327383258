import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { makeDownloadFromBase64 } from "../../../helpers/date-helper";
import { ReportingInfosResponse } from "../../../model/dto/response";
import { StatutProjet } from "../../../model/entities/statut-projet";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { publicService } from "../../../services/public-service";
import { ApplicationState } from "../../../store";
import { DropdownListFieldNew } from "../../generic/dropdown-list-field";
import { SubmitButton } from "../../generic/submit-button";
import { dataService } from "../../../services/data-service";
import { ProjetListItem } from "../../../model/dto/projet-list-item";

type FieldData = {
    value?: any,
    name?: string,
    isValid?: boolean
};

type FormData = {
    idProjet: FieldData
    nomProjet: FieldData
};
export function InvestisseursReportingForm(){
    const [form, setForm] = useState<FormData>({
        idProjet: {value: undefined, name: "idProjet", isValid: true},
        nomProjet: {value: "", name: "nomProjet", isValid: true}
    })
    
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false)

    const [projetList, setProjetList] = useState<ProjetListItem[]>([]);

    const statut = [
        StatutProjet.PUBLIE,
        StatutProjet.CLOTURE,
        StatutProjet.FINANCE
    ]

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(()=>{
        publicService.getProjetList(true).then(
            (response)=>{
                const projets = response.ProjetList?.filter(projet => statut.includes(projet.StatutProjetCode!))
                setProjetList(projets!)
            }
        )
    }, [])


    const validateMandatory = (value: any)=>{
         return value && value != ""
    }

    const onSubmitSuccess = (response: ReportingInfosResponse) => {
        makeDownloadFromBase64(response.File!,"InvestisseursReport.csv");
    }

    return (
        <Form>
            <Form.Field>
                <DropdownListFieldNew
                    datasource={projetList.map(projet =>({text: projet.Titre! , value: projet.Titre!}))}
                    field={form.nomProjet}
                    label={
                            "Générer un fichier .CSV contenant des données sur les investisseurs d'un projet"
                    }
                    onChange={(value: any) => {
                        let id: number = projetList.find(projet => projet.Titre == value)?.Id!;    
                        setForm({
                            ...form, 
                            idProjet: {value: id, name:'idProjet', isValid: true}, 
                            nomProjet: {value: value, name: 'nomProjet', isValid: true}
                        })
                    }}
                    placeholder="Séléctionner un projet"
                    maxWidth={250}
                    submitted={formSubmitted}
                    error="Veuillez choisir un projet parmi la liste des projets"
                    validateField={validateMandatory}
                />
            </Form.Field>

            <SubmitButton
                label="Valider"
                onPreSubmit={() => setFormSubmitted(true)}
                action={()=>dataService.GenerateInvestisseursReportCSV(form.idProjet.value, loginProps.oauth)}
                onActionSuccess={onSubmitSuccess}
            />
        </Form>
    )
}