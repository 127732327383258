import React from 'react'
import { Card } from "semantic-ui-react";
import { LightEditableBlock } from "../../../generic/editable-block/light-editable-block";
import Projet from "../../../../model/projet";
import { LinkButton } from '../../../generic/link-button';
import { TypeCollecte } from '../../../../model/entities/type-collecte';
import { useNavigate } from 'react-router-dom';
import { ProfilCode, ProjetDetailResponse } from '../../../../model/dto/response';
import { AuthenticationState } from '../../../../model/state/authentication-state';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import SelectTypeCollecteForm from '../../forms/select-type-collecte-form';
import { StatutProjet } from '../../../../model/entities/statut-projet';


type Props = {
    project: Projet,
    isPageDon: boolean,
    nonEditListCode: string[];
    reload: () => void
}

export function TypeFinancementInfos({project, isPageDon, nonEditListCode, reload}: Props) {
    const navigate = useNavigate()
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    
    const link = isPageDon ? `/projets/${project.UrlSlug}` : `/projets/${project.UrlSlug}/don`
    const linkLabel = isPageDon ? "Accéder à la page emprunt citoyen" : "Accéder à la page mécénat" 

    const typeFinancementText = project.TypeCollecteCode === TypeCollecte.EMPRUNT_CITOYEN ? 'emprunt citoyen' : 
    project.TypeCollecteCode === TypeCollecte.DON ? 'mécénat citoyen' :
    project.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON ? 'combiné emprunt et mécénat citoyen' :
    ''

    const onSubmitEditingTypeFinancementSuccess = (response: ProjetDetailResponse) => {
      if(response.IsTraitementOk){
        if(response.HasToRedirect){
          const page = response.Projet?.TypeCollecteCode === TypeCollecte.DON ?  `/projets/${project.UrlSlug}/don` : `/projets/${project.UrlSlug}`
          return navigate(page)
        }
        return reload()
      }
      return reload()
    }

    const isEditable = (loginProps.oauth?.profilCode ===  ProfilCode.PORTEUR_PROJET && !(nonEditListCode.includes(project.StatutProjetCode!)) && !(nonEditListCode.includes(project.StatutDonProjetCode!))) || loginProps.oauth?.profilCode===ProfilCode.VILLYZ

    const nonAvailableCodeToSwitchForAdmin = [
      StatutProjet.COMPLET,
      StatutProjet.INCOMPLET
    ]

    const isSwitchAvailable = (): boolean => {
      if(loginProps.oauth?.profilCode ===  ProfilCode.PORTEUR_PROJET){
        return true
      }

      if(loginProps.oauth?.profilCode === ProfilCode.VILLYZ){
        if(isPageDon){
          return !nonAvailableCodeToSwitchForAdmin.includes(project.StatutProjetCode!) 
        }else{
          return !nonAvailableCodeToSwitchForAdmin.includes(project.StatutDonProjetCode!)
        }
      }
      return false
    }

  return (
    <div style={{minWidth:'50%', marginBottom: '1em'}}>
      <Card >
        <div style={{padding:10}}>

            <h3 style={{marginBottom:'1em'}}>Type de financement</h3>

            <LightEditableBlock
              title="Type de financement"
              editable={isEditable}
              editor={(close) =>
                <SelectTypeCollecteForm
                  projet={project}
                  loginProps={loginProps}
                  onSubmitSuccess={
                    onSubmitEditingTypeFinancementSuccess
                  }
                  isEditing={true}
                  isPageDon={isPageDon}
                />
              }
              iconCss={{
                position: "absolute",
                right:"5px",
                top:"5px"
              }}
            >

            </LightEditableBlock>

              <p style={{marginBottom:'1em'}}>Financement : {typeFinancementText}</p>

            {project.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON && isSwitchAvailable() &&
            <LinkButton 
            label={linkLabel}
            onClick={() => navigate(link)}    
            />}
        </div>
      </Card>
    </div>         
  )
}
