import React, { useEffect, useState } from "react";
import { ApplicationState } from "../../../store";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { connect } from "react-redux";
import { userService } from "../../../services/authentication-redux-service";
import { UploadForm } from "../../login/upload-form";
import { SubmitButton } from "../../generic/submit-button";
import { Dropzone } from "../../generic/image-upload/dropzone";
import { ProfilCode, UserInfoResponse } from "../../../model/dto/response";

type Props = {
  loginProps?: AuthenticationState;
  closeModal?: () => void;
  onUploadSuccess?: () => void;
};

function LogoUploadForm({loginProps, closeModal, onUploadSuccess}: Props) {
  
  const [file, setFile] = useState<File>();
  return (
    <div style={{ height: "280px", paddingBottom: "30px", display:"flex", flexDirection:"column", justifyContent: "space-between", alignItems:"center"}}>
      <Dropzone onFileAdded = {(f)=>setFile(f)}/>
      <SubmitButton 
           label = "Valider" 
           action={()=> userService.uploadLogo(file!, loginProps?.oauth)} 
           onActionSuccess = {()=>{
                if (onUploadSuccess) {
                    onUploadSuccess();
                }

                if (closeModal) {
                    closeModal();
                }
           }}
      />
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedLogoUploadForm = connect(mapStateToProps, null)(LogoUploadForm);
export { ConnectedLogoUploadForm as LogoUploadForm };
