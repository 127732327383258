import React, {useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { TypePieceByProfil } from "../../model/entities/type-piece-by-profil";
import { userService } from "../../services/authentication-redux-service";
import { ApplicationState } from "../../store";
import { AuthenticationState } from "../../model/state/authentication-state";
import { SubmitButton } from "../generic/submit-button";
import { UserPieceJustificative } from "../../model/entities/user-piece-justificative";
import { SimpleButton } from "../generic/simple-button";
import { trackPromise } from "react-promise-tracker";
import { LoadingSpinner } from "../generic/loading-spinner";
import { ProfilCode } from "../../model/dto/response";
import { Grid, Icon } from "semantic-ui-react";
import { FileUpload } from "../generic/file-upload";
import { Profil } from "../../model/entities/profil";
import InformationBandeau from "../generic/information-bandeau/information-bandeau";
import { WorkflowType } from "../../model/entities/workflow-type";
import { FlowStep } from "../../model/entities/flow-step";
import { referenceService } from "../../services/reference-service";


type Props = {
  loginProps?: AuthenticationState;
  onActionSuccess: (response: Response) => void;
  onActionFailure?: () => void;
  onSkipStep?: (response: Response) => void;
  onQuitEdit?: () => void;
  isOnboarding?: boolean;
  userId?: number
};

enum TypePieceCitoyen {
  Passeport,
  Carte_Identite,
}

function OnboardingInvestisseurParticulierDocumentListForm({
  loginProps,
  isOnboarding,
  userId,
  onActionFailure,
  onActionSuccess,
  onSkipStep
}: Props) {

  const borderSelected = "medium solid #00B99E"
  const borderUnselected = "medium solid #ccc"

  const [selectedTypePiece, setSelectedTypePiece] = useState<TypePieceCitoyen>(TypePieceCitoyen.Carte_Identite)
  const [typePieceList, setTypePieceList] = useState<TypePieceByProfil[]>([]);
  const [userPieceList, setUserPieceList] = useState<(UserPieceJustificative)[]>([]);
  const [isFormDocumentSubmitted, setIsFormDocumentSubmitted] = useState(false)
  const [isFormError, setIsFormError] = useState(false)

  useEffect(() => {
    if (loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR || loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET)
    {
      trackPromise(userService.getTypePieceForUser(loginProps?.oauth?.userId, loginProps?.oauth)
        .then(response => {
          if(response.IsTraitementOk) {
            setTypePieceList(response.TypePieceList!)
          }
        }), "modal")
    }
    else
    {
      trackPromise(userService.getTypePieceUserForAdmin(userId, loginProps?.oauth)
      .then(response => {
        if(response.IsTraitementOk) {
          setTypePieceList(response.TypePieceList!)
        }
      }), "modal")

    }
  }, []);

  const validateFile = (file: File | undefined) => {
    if (file){
      const acceptedExtensions = ["pdf", "jpeg", "jpg", "gif", "png"];
      const fileSize = file.size;
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop()?.toLowerCase();
      if (fileSize < 32768 || fileSize > 7340032 || !acceptedExtensions.includes(fileExtension!)) {
        return false
      }
      return true
    } else {
      return false
    }
  }
  
  const onFormNotValid = () => {
    setIsFormError(true)
  }

  const validateForm = () => {
    const acceptedExtensions = ["pdf", "jpeg", "jpg", "gif", "png"];

    if(loginProps?.oauth?.profilCode === Profil.INVESTISSEUR && !loginProps.user?.Etablissement) {
      if(selectedTypePiece === TypePieceCitoyen.Carte_Identite) {
        if (userPieceList.length > 0) {
          for (var i = 0 ; i < userPieceList.length ; i++) {
            if(userPieceList[i].Document){
              const fileSize = userPieceList[i].Document!.size;
              const fileName = userPieceList[i].Document!.name;
              const fileExtension = fileName.split('.').pop()?.toLowerCase();
    
              //On verifie la taille et l'extension de chaque fichier
              if (fileSize < 32768 || fileSize > 7340032 || !acceptedExtensions.includes(fileExtension!)) {
                return false
              }
            } else {
              return false
            }
            
            if(userPieceList[i].DocumentVerso){
              const fileSizeVerso = userPieceList[i].DocumentVerso!.size;
              const fileNameVerso = userPieceList[i].DocumentVerso!.name;
              const fileExtensionVerso = fileNameVerso.split('.').pop()?.toLowerCase();
              if (fileSizeVerso < 32768 || fileSizeVerso > 7340032 || !acceptedExtensions.includes(fileExtensionVerso!)) {
                return false
              }
            } else {
              return false
            }
          }
          return(true)
        }
        return false
      }
      else if(selectedTypePiece === TypePieceCitoyen.Passeport) {
        if (userPieceList.length > 0) {
          for (var i = 0 ; i < userPieceList.length ; i++) {
            if(userPieceList[i].Document){
              const fileSize = userPieceList[i].Document!.size;
              const fileName = userPieceList[i].Document!.name;
              const fileExtension = fileName.split('.').pop()?.toLowerCase();
    
              //On verifie la taille et l'extension de chaque fichier
              if (fileSize < 32768 || fileSize > 7340032 || !acceptedExtensions.includes(fileExtension!)) {
                return false
              }
            } else {
              return false
            }
          }
          return(true)
        }
        return false
      }
    }
    return false
  }

  const isInvestisseurParticulier = useMemo(() => {
    if(loginProps?.user?.ProfilCode === Profil.INVESTISSEUR && !loginProps?.user?.Etablissement) {
      return true
    }
    return false
  }, [loginProps])
 
  const message = (
    <div>
      <Grid doubling stackable>
        <Grid.Row>
          La vérification de votre identité est essentielle pour maintenir un environnement sûr et fiable pour tous nos utilisateurs. En fournissant une pièce d'identité valide, vous aidez à renforcer la confiance au sein de notre communauté et à protéger vos propres intérêts financiers.
        </Grid.Row>
        {isInvestisseurParticulier &&
        <>
          Veuillez télécharger un document :
          <Grid.Row columns={2}>
            <Grid.Column>
              <Icon
                css={{color: "#16586F"}}
                name="check circle"
                size="small"
              />
                En cours de validité
            </Grid.Column>
            <Grid.Column>
              <Icon 
                css={{color: "#16586F"}}
                name="check circle"
                size="small"
              />
                Facilement lisible
            </Grid.Column>
            <Grid.Column>
              <Icon
                css={{color: "#16586F"}}
                name="check circle"
                size="small"
              />
                Avec les bords visibles
            </Grid.Column>
            <Grid.Column>
              <Icon
                css={{color: "#16586F"}}
                name="check circle"
                size="small"
              />
                En couleur
            </Grid.Column>
          </Grid.Row>
        </>
      }
      </Grid>
    </div>
  );

  const skipToNextStepKey = () => {
    const body = {
        WorkflowTypeCode : WorkflowType.ONBOARDING_CITOYEN,
        CurrentFlowStep : FlowStep.PIECES_JUSTIFICATIVES,
        RessourceId:  loginProps?.user?.UserId!,
        IsSkiped: true

    }
    return referenceService.getNextStep(body).then(response => {
        onSkipStep && onSkipStep(response)})
  }

  return (
    <div css={{padding: "0rem 3.78rem !important"}}>
      <LoadingSpinner>
        {loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR && !loginProps.user?.Etablissement &&
        <h1 
          css={{
            marginTop: "0 !important", 
            fontSize: "20px !important",
            marginBottom: "16px !important"
          }}
        >
          Pièces justificatives</h1>
        }
        <InformationBandeau 
          type="information"
          icon={{name:'info circle', size:"big", color:"blue"}}
          message={message}
        />
       
          {loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR && !loginProps.user?.Etablissement &&
          <Grid doubling stackable>
            <div 
              css={{
                fontSize: "18px", 
                fontWeight: "bold",
                marginLeft: "13px",
                marginTop: "16px"
              }}
            >
              <p css={{marginBottom:"12px !important"}}>Veuillez choisir une pièce d'identité à transmettre :</p>
            </div>
            
            <Grid.Row
              css={{
                padding: "0 2.50rem !important",
                paddingTop:"0rem !important"
              }}
            >
              <Grid.Column width={7}
                css={{
                  borderRadius: "0.5rem",
                  border: selectedTypePiece === TypePieceCitoyen.Carte_Identite ? borderSelected : borderUnselected,
                  padding: "0.67rem 0 !important",
                  borderWidth: selectedTypePiece === TypePieceCitoyen.Carte_Identite ? "2px" : "1px",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "18px",
                  cursor: "pointer"
                }}
                
              >
                <div 
                  css={{display: "flex", alignItems:"center", justifyContent: "center"}}
                  onClick={() => setSelectedTypePiece(TypePieceCitoyen.Carte_Identite)}
                >
                  <div>
                    <svg
                    css={{
                      fill: selectedTypePiece === TypePieceCitoyen.Carte_Identite ? "#000" : "grey"
                    }}
                    xmlns="http://www.w3.org/2000/svg" width="auto" height="30" viewBox="0 0 24 24"><path d="M22 8v12h-16v-12h16zm2-2h-20v16h20v-16zm-8 11.677v.323h-8v-.333c-.003-.89.035-1.398 1.059-1.634 1.122-.259 2.229-.491 1.697-1.473-1.577-2.911-.45-4.56 1.244-4.56 1.662 0 2.816 1.588 1.244 4.56-.517.976.551 1.208 1.697 1.473 1.028.237 1.063.748 1.059 1.644zm4-7.677h-3v2h3v-2zm0 3h-3v2h3v-2zm0 3h-3v2h3v-2zm1-14h-21v17h2v-15h19v-2z"/></svg>
                  </div>
                  <div 
                    css={{
                      marginLeft: "0.65rem",  
                      color: selectedTypePiece === TypePieceCitoyen.Carte_Identite ? "#000" : "grey"
                    }}
                  >
                    Carte d'identité
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={2}>
              </Grid.Column>
              <Grid.Column width={7}
                css={{
                  borderRadius: "0.5rem",
                  border: selectedTypePiece === TypePieceCitoyen.Passeport ? borderSelected : borderUnselected ,
                  padding: "0.67rem 0 !important",
                  borderWidth: selectedTypePiece === TypePieceCitoyen.Passeport ? "2px" : "1px",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "18px",
                  cursor: "pointer"
                }}
              >
                <div 
                  css={{display: "flex", alignItems:"center", justifyContent: "center"}}
                  onClick={() => setSelectedTypePiece(TypePieceCitoyen.Passeport)}
                >
                  <div> 
                    <svg 
                      css={{
                        fill: selectedTypePiece === TypePieceCitoyen.Passeport ? "#000" : "grey"
                      }}
                      xmlns="http://www.w3.org/2000/svg" width="auto" height="30" viewBox="0 0 24 24" fill-rule="evenodd" clip-rule="evenodd"><path d="M23 8c0-.552-.448-1-1-1h-12c-.552 0-1 .448-1 1v15c0 .552.448 1 1 1h12c.552 0 1-.448 1-1v-15zm-3 12h-8v1h8v-1zm-10-15c-1.657 0-3 1.343-3 3v11.583l-5.906-12.666c-.067-.143-.097-.295-.094-.443.008-.369.22-.719.578-.886l11.782-5.494c.136-.064.279-.094.421-.094.377 0 .738.213.908.578l2.062 4.422h-6.751zm7.082 10.667h-2.164c.309 1.234.849 2 1.082 2.333.233-.333.773-1.1 1.082-2.333zm2.555 0h-1.87c-.178.79-.487 1.555-.926 2.244 1.246-.267 2.276-1.114 2.796-2.244zm-5.404 0h-1.87c.52 1.13 1.55 1.977 2.796 2.244-.436-.685-.746-1.449-.926-2.244zm2.981-2.667h-2.427c-.097.662-.097 1.338 0 2h2.426c.097-.662.097-1.338.001-2zm-3.1 0h-1.988c-.082.32-.126.655-.126 1s.044.681.126 1h1.988c-.088-.663-.088-1.337 0-2zm5.76 0h-1.988c.088.663.088 1.337 0 2h1.988c.082-.319.126-.655.126-1s-.044-.68-.126-1zm-2.792-.667c-.267-1.067-.684-1.764-1.082-2.333-.233.333-.774 1.099-1.082 2.333h2.164zm-2.849 0c.188-.832.513-1.598.926-2.244-1.245.267-2.276 1.114-2.796 2.244h1.87zm5.404 0c-.52-1.13-1.551-1.977-2.796-2.244.422.661.74 1.423.926 2.244h1.87z"/>
                    </svg>
                  </div>
                  <div 
                    css={{
                      marginLeft: "0.65rem", 
                      marginBottom: "-0.5rem",
                      color: selectedTypePiece === TypePieceCitoyen.Passeport ? "#000" : "grey"
                    }}
                  >
                    Passeport
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>       
            {selectedTypePiece === TypePieceCitoyen.Carte_Identite && (
            <>
              <div 
                css={{
                  fontSize: "18px", 
                  fontWeight: "bold",
                  marginLeft: "0.86rem"
                }}
              >
                <p css={{marginTop:"2.34rem !important"}}>Veuillez transmettre le recto et le verso séparemment :</p>
              </div>
              <Grid.Row columns={2} css={{marginTop: "-0.88rem !important"}}>
                <Grid.Column
                  css={{
                    textAlign: "center",
                  }}
                >
                  <p css={{fontSize: "18px", margin: "0 !important"}}>Recto uniquement</p>
                  <div css={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                    <img 
                      css={{
                        width: "150px",
                        height: "110px",
                        padding: "0.59rem",
                      }}
                      src="img/identite.png"  
                    />
                  </div>
                  <FileUpload 
                    libelle=""
                    isLimited={false} 
                    verso={false}
                    submitted={isFormDocumentSubmitted}
                    centered
                    validateField={validateFile}
                    onChange=
                    {(e: React.ChangeEvent<HTMLInputElement>, isVerso?: boolean, isAlreadyUploaded?: boolean) => {
                        const element = e.target as HTMLInputElement;
                        const alreadyUploaded = userPieceList.find( //On récupère l'ancienne pièce jointe correspondante si elle existe
                          (up) =>
                            up.TypePieceByProfilId === typePieceList[0].Id
                        );
                        if (element.files) { // Le onChange correspond à un ajout de fichier
                          const userPiece: UserPieceJustificative = {
                            TypePieceByProfilId: typePieceList[0].Id,
                            Document: element.files[0],
                            DocumentVerso : alreadyUploaded?.DocumentVerso // On récupère le verso déjà uploadé si il existe
                          };
                        
                          if (alreadyUploaded) { // On met à jour la Pièce jointe dans le state
                            var array = [...userPieceList]; // On fait une copie du tableau actuel
                            var index = array.indexOf(alreadyUploaded);
                            if (index !== -1) {
                              array.splice(index, 1);
                              setUserPieceList([...array, userPiece]);
                            }
                          } else {
                            setUserPieceList([...userPieceList, userPiece]);
                          }
                        }
                      }
                    }
                  />
                </Grid.Column>
                <Grid.Column
                  css={{
                    textAlign: "center",
                  }}
                >
                  <p css={{fontSize: "18px", margin: "0 !important"}}>Verso uniquement</p>
                  <div css={{
                    display:'flex',
                    justifyContent: 'center'
                  }}>
                    <img 
                      css={{
                        width: "150px",
                        height: "110px",
                        padding: "0.59rem",
                      }}
                      src="img/idVerso.png"
                    />
                  </div>
                
                  <FileUpload 
                    libelle=""
                    isLimited={false} 
                    verso={false}
                    submitted={isFormDocumentSubmitted}
                    validateField={validateFile}
                    centered
                    onChange=
                    {(e: React.ChangeEvent<HTMLInputElement>, isVerso?: boolean, isAlreadyUploaded?: boolean) => {
                        const element = e.target as HTMLInputElement;
                        const alreadyUploaded = userPieceList.find( //On récupère l'ancienne pièce jointe correspondante si elle existe
                          (up) =>
                            up.TypePieceByProfilId === typePieceList[0].Id
                        );
                        if (element.files) { // Le onChange correspond à un ajout de fichier
                          const userPiece: UserPieceJustificative = {
                            TypePieceByProfilId: typePieceList[0].Id,
                            Document: alreadyUploaded?.Document, // On récupère le verso déjà uploadé si il existe
                            DocumentVerso : element.files[0] 
                          };
                        
                          if (alreadyUploaded) { // On met à jour la Pièce jointe dans le state
                            var array = [...userPieceList]; // On fait une copie du tableau actuel
                            var index = array.indexOf(alreadyUploaded);
                            if (index !== -1) {
                              array.splice(index, 1);
                              setUserPieceList([...array, userPiece]);
                            }
                          } else {
                            setUserPieceList([...userPieceList, userPiece]);
                          }
                        }
                      }
                    }
                  />
                </Grid.Column>
                <Grid.Column width={16}
                  css={{
                    fontWeight: "bold", 
                    fontStyle: "italic",
                    textAlign: "center",
                    marginBottom: "0.50rem !important"
                  }}
                >
                  Vos fichiers doivent faire entre 32Ko et 7Mo et respecter l'un des formats suivants : .pdf, .jpeg, .jpg, .gif, .png
                </Grid.Column>
              </Grid.Row>
            </>
            ) || (
              <>
                <Grid.Row css={{textAlign: "center", paddingBottom: "0px !important"}}>
                  <Grid.Column width={16}>
                    <p css={{fontWeight:"bold", fontSize:"18px", marginTop: "1rem !important"}}>Veuillez transmettre la double page de votre passeport : </p>
                    <p css={{ fontSize: "18px" }}>Double page</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div
                      css={{
                        display: "flex",
                        justifyContent: "center",
                      }}>
                        <div>
                          <img
                            css={{
                              width: "120px",
                              height: "90px",
                              borderRadius: "5px",
                          }}
                            src="img/idVerso.png" /> <br />
                          <img
                            css={{
                              width: "120px",
                              height: "90px",
                              borderRadius: "5px",
                              marginTop: "-0.5rem !important",
                            }}
                            src="img/identite.png" />
                        </div>
                    </div>
                    <FileUpload
                      libelle=""
                      isLimited={false}
                      verso={false}
                      submitted={isFormDocumentSubmitted}
                      centered
                      validateField={validateFile}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>, isVerso?: boolean, isAlreadyUploaded?: boolean) => {
                        const element = e.target as HTMLInputElement;
                        const alreadyUploaded = userPieceList.find(
                          (up) => up.TypePieceByProfilId === typePieceList[0].Id
                        );
                        if (element.files) { // Le onChange correspond à un ajout de fichier
                          const userPiece: UserPieceJustificative = {
                            TypePieceByProfilId: typePieceList[0].Id,
                            Document: element.files[0],
                          };

                          if (alreadyUploaded) { // On met à jour la Pièce jointe dans le state
                            var array = [...userPieceList]; // On fait une copie du tableau actuel
                            var index = array.indexOf(alreadyUploaded);
                            if (index !== -1) {
                              array.splice(index, 1);
                              setUserPieceList([...array, userPiece]);
                            }
                          } else {
                            setUserPieceList([...userPieceList, userPiece]);
                          }
                        }
                      }}
                    />
                    <div
                      css={{
                        fontWeight: "bold",
                        fontStyle: "italic",
                        textAlign: "center",
                        margin: "0.8rem 0 !important",
                      }}
                    >
                      Votre fichier doit faire entre 32Ko et 7Mo et respecter <br /> l'un des formats suivants : .pdf, .jpeg, .jpg, .gif, .png
                    </div>
                  </Grid.Column>
                </Grid.Row>
               
              </>
            )}
          </Grid>
        }

        <div className="button-bar">
          <SubmitButton
            data={userPieceList}
            action={() => {
              if (loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR || loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET)
              {
                return userService.registerMultipleTypedFiles(userPieceList, loginProps?.user?.UserId, loginProps?.oauth, WorkflowType.ONBOARDING_CITOYEN)
              }
              else
              {
                return userService.registerMultipleTypedFilesAdmin(userPieceList, loginProps?.oauth?.userId, userId, loginProps?.oauth)
              }
            }}
            label="Valider"
            onActionFailure={onActionFailure}
            onActionSuccess={onActionSuccess}
            validateForm={validateForm}
            onFormNotValid={onFormNotValid}
            onPreSubmit={() => setIsFormDocumentSubmitted(true)}
          />
          {isOnboarding && (
            <SimpleButton
              label="Je n’ai pas ma pièce d’identité"
              onClick={()=> skipToNextStepKey()} 
            />
          )}
        </div>
      </LoadingSpinner>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedOnboardingInvestisseurParticulierDocumentListForm = connect(
  mapStateToProps,
  null
)(OnboardingInvestisseurParticulierDocumentListForm);
export { ConnectedOnboardingInvestisseurParticulierDocumentListForm as OnboardingInvestisseurParticulierDocumentListForm };
