import React, { useState } from "react";
import { Card, Container, Form } from "semantic-ui-react";

export function ForgotPassword() {
  const [isSent, setIsSent] = useState(false);

  async function onForgotPasswordSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const data = new FormData(e.target as HTMLFormElement);

    await fetch(`${process.env.REACT_APP_BASE_URL}/public/user/resetQuery`, {
      method: "POST",
      body: JSON.stringify(Object.fromEntries(data)),
      headers: {
        "Content-Type": "application/json",
      },
    });
    setIsSent(true);
  }
  return (
    <div className="pt-12">
      <Container>
        <p>
          Saisissez votre adresse email. Vous recevrez un email vous permettant
          de redéfinir votre mot de passe. Pensez à vérifier vos spams si vous ne le trouvez pas.
        </p>
        <Card fluid>
          <Card.Content>
            <Form onSubmit={onForgotPasswordSubmit}>
              <Form.Field>
                <label>Email</label>
                <input
                  required
                  type="text"
                  name="email"
                  className="form-group"
                />
              </Form.Field>
              <div className="card-action center">
                <button className="btn btn-primary" type="submit">
                  Valider
                </button>
              </div>
            </Form>
            {isSent && (
              <p className="text-green-500">
                Un email avec un lien de connexion vient de vous être envoyé.
              </p>
            )}
          </Card.Content>
        </Card>
      </Container>
    </div>
  );
}
