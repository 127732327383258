import React, { useState } from "react"
import { Form } from "semantic-ui-react"
import { DescriptionDocumentsBody } from "../../../model/dto/body/description-documents-body";
import { DescriptionDocumentDto } from "../../../model/dto/description-document-dto";
import Projet from "../../../model/projet";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { projetService } from "../../../services/projet-service";
import { FileUpload } from "../../generic/file-upload";
import { FormErrorHeader } from "../../generic/form-error-header";
import { InputField } from "../../generic/input-field"
import { SimpleButton } from "../../generic/simple-button";
import { SubmitButton } from "../../generic/submit-button";

type Props = {
    loginProps?: AuthenticationState,
    projet: Projet,
    documents: string[],
    close: () => void
    reload: () => void
}

type FieldData = {
    value?: any,
    name?: string,
    isValid?: boolean
};

type FormData = {
    nomDocument: FieldData,
    document: FieldData
}

export function DocumentsUploadForm({loginProps, projet, documents, close, reload}: Props){
    const initialValue: FormData = {
        nomDocument: {name: "nomDocument", value: "", isValid: true},
        document: {name: "document", value: undefined, isValid: true},
    }

    const [form, setForm] = useState<FormData>(initialValue)
    const [documentList, setDocumentList] = useState<DescriptionDocumentDto[]>([])
    const [isFormError, setIsFormError] = useState(false)
    const [isDocumentUploadError, setIsDocumentUploadError] = useState(false)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName, isValid: isValid } };
        
        setForm({ ...form,  ...newField});
        setIsFormError(false)
        setIsDocumentUploadError(false)
    }

    const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const element = e.target as HTMLInputElement;
        if (element.files) {
            const document: File = element.files[0];
            setForm({...form, document : { name: "document", value: document, isValid: true }});
            setIsFormError(false)
            setIsDocumentUploadError(false)
        }
    }

    const onAddClick = ()=>{
        if(!isFormValid()) {
            onFormNotValid()
            return 
        }
        const documentInfo: DescriptionDocumentDto = {
            Name: form.nomDocument.value,
            Document: form.document.value
        }
        setDocumentList([...documentList, documentInfo])
        setForm({...form, ...initialValue})
    }

    const validateMandatory = (value: any)=>{
        return value && value != ""
    }

    const validateDocument = (file?: File) => {
        if(!file) return false

        const acceptedExtensions = ["pdf"];
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        if ( !acceptedExtensions.includes(fileExtension!)) {
            return false
        }
        return true
    }

    const isFormValid = ()=>{
        return validateMandatory(form.nomDocument.value) && validateDocument(form.document.value)
    }

    const onFormNotValid = ()=>{
        setIsFormError(true)
    }

    const isDocumentUploadValid = ()=>{
        return documentList && documentList.length > 0
    }

    const onDocumentUploadNotValid = ()=>{
        setIsDocumentUploadError(true)
    }

    const onSubmitSuccess= () => {
      close();
      reload();
    }

    const body: DescriptionDocumentsBody = {
        Documents: documentList
    }

    return (
        <div>
            <Form>
                <FormErrorHeader 
                    message="Veuillez corriger les champs en erreur"
                    displayed={isFormError}
                />
                <FormErrorHeader 
                    message="Veuillez importer des documents avant de valider"
                    displayed={isDocumentUploadError}
                />
                <Form.Field>
                    <InputField 
                        label="Nom du document"
                        onChange={handleInputChange}
                        data={form.nomDocument}  
                        validateField={validateMandatory}                 
                    />
                </Form.Field>
                <Form.Field
                    css={{
                        marginLeft:"-15px !important",
                        marginTop:"-20px !important"
                    }}
                >
                    <FileUpload
                        isLimited
                        onChange={handleDocumentChange} 
                        validateField={validateDocument}
                    />
                </Form.Field>
            
                <SimpleButton 
                    className="form-group"
                    label="Ajouter"
                    onClick={onAddClick}
                />

                <div className="form-group">
                    <div className="form-group">
                        Liste des documents
                    </div>
                    {documents.map(document => {
                        return (
                            <div>
                                {document}
                            </div>
                        )
                    })
                    }
                    {documentList.map(document => {
                        return (
                            <div>
                                {document.Name}
                            </div>
                        )
                    })
                    }
                </div>

                <div className="button-bar">
                    <SubmitButton 
                        data={body}
                        label="Valider"
                        action={() => projetService.registerDescriptionDocuments(body, projet.Id!, loginProps?.oauth)}
                        validateForm={isDocumentUploadValid}
                        onFormNotValid={onDocumentUploadNotValid}
                        onActionSuccess={onSubmitSuccess}
                    />
                    <SimpleButton 
                        label="Annuler"
                        onClick={close}
                    />
                </div>
            </Form>
        </div>
    )
}