import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Grid, Image } from "semantic-ui-react"
import { Investissementbody } from "../../model/dto/body/investissement-body"
import { BankProvider, Realbanks } from "../../model/entities/bank-provider"
import { AuthenticationState } from "../../model/state/authentication-state"
import { ApplicationState } from "../../store"
import { SelectBranchPane } from "../investment/select-branch-pane"
import { SelectBankPane } from "./select-bank-pane"
import { FormErrorHeader } from "../generic/form-error-header"
import { SimpleButton } from "../generic/simple-button"
import { ModePaiement } from "../../model/entities/mode-paiement"

type Props = {
    montant?: number | string,
    projetId?: number,
    errorMessage?: string,
    isErrorDisplayed?: boolean,
    isDon?: boolean,
    onSelectBranch?: (provider: BankProvider) => void,
    onSubmitImpossible?: (message: string) => void
    onInvestVCLink?: () => void;
    onContinueInitiation?: (body: Investissementbody, selectedProvider?: BankProvider) => void
}

export function VirementPane({ 
    montant, 
    projetId, 
    errorMessage, 
    isErrorDisplayed, 
    isDon,
    onSelectBranch, 
    onSubmitImpossible, 
    onInvestVCLink,
    onContinueInitiation,
}: Props) {
    const [selectedProvider, setSelectedProvider] = useState<BankProvider | undefined>()
    const [selectedBranch, setSelectedBranch] = useState<string | undefined>()
    const [hasProviderBeenSelected, setHasProviderBeenSelected] = useState<boolean>(false)
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [isSelectingProvider, setIsSelectingProvider] = useState(true)


    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const handleReturnClick = () => {
        setIsSelectingProvider(true)
        setSelectedProvider(undefined)
        setHasProviderBeenSelected(false)
    }

    const validateMandatory = (value: any)=>{
        return value && value != ""
    }

    const validateBranch = () => {
        if(selectedProvider?.HasBranches) {
            return validateMandatory(selectedBranch)
        } 
        return true
    }

    const isFormValid = (): any => {
        return validateMandatory(selectedProvider)  && validateBranch()
    };


    const body: Investissementbody = {
        Montant: montant?.toString(),
        ProviderId: selectedProvider?.ProviderId,
        BranchId: selectedBranch,
        ModePaiementCode: ModePaiement.INITIATION_VIREMENT,
        IsDon: isDon
    }

    return (
        <Grid>
            <>
                <Grid.Row>
                    <Grid.Column>
                        <FormErrorHeader message={errorMessage ?? ""} displayed={isErrorDisplayed ?? false} />
                        <h3 css={{margin: "0px"}}>Montant : {montant} €</h3>
                    </Grid.Column>
                </Grid.Row>
                {(isSubmitted && !hasProviderBeenSelected) && (
                    <Grid.Row>
                        <div
                            css={{
                                color: "#F03E3E",
                                fontSize: "14px",
                                marginLeft: "1rem",
                            }}
                        >
                            Veuillez sélectionner une banque
                        </div>
                    </Grid.Row>
                )}
                {isSubmitted && hasProviderBeenSelected && !selectedBranch && selectedProvider?.HasBranches &&
                    <Grid.Row>
                        <div
                            css={{
                                color: "#F03E3E",
                                fontSize: "14px",
                                marginLeft: "1rem",
                            }}
                        >
                            Veuillez sélectionner une branche
                        </div>
                
                    </Grid.Row>
                }
                <Grid.Row css={{
                    paddingTop: "0 !important"
                }}>
                    <Grid.Column>
                        {isSelectingProvider && selectedProvider?.HasBranches &&
                            <SelectBranchPane
                                montant={montant}
                                projetId={projetId}
                                provider={selectedProvider}
                                setSelectedBranch={setSelectedBranch}
                                handleReturnClick={handleReturnClick}
                            />
                            ||
                            <SelectBankPane
                                selectedProvider={selectedProvider}
                                setSelectedProvider={(bankProvider : BankProvider | undefined) => {
                                    setSelectedProvider(bankProvider)
                                    if(bankProvider) {
                                        setHasProviderBeenSelected(true)
                                    } else {
                                        setHasProviderBeenSelected(false)
                                    }
                                }}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <div
                        css={{
                            float: "right",
                            marginTop: "10px"
                        }}
                    >
                        <SimpleButton
                            label="Continuer vers votre banque"
                            onClick={() => {
                                if(onContinueInitiation) {
                                    onContinueInitiation(body, selectedProvider)
                                }
                            }}
                        />
                    </div>
                </Grid.Column>
            </Grid.Row>
            </> 
            <>
                <Grid.Row 
                    centered
                    css={{
                        padding: "0px !important"
                    }}
                >
                    <div 
                    css={{
                        display : "flex", 
                        justifyContent: "space-around", 
                        flexDirection: "row", 
                        marginTop:"10px"
                    }}>
                            <Image
                            size="tiny"
                            src="/img/logos/truelayer-horizontal.jpg"
                            css={{
                                padding:"0px 5px"
                            }}
                            />
                            <div 
                                css={{
                                    display: 'block', 
                                    textAlign: 'center', 
                                    maxWidth : "200px"
                            }}>
                                <Image
                                    size="tiny"
                                    src="/img/logos/powered-mangopay-2.png"
                                    css={{
                                        margin: 'auto',
                                        padding:"0px 5px"
                                    }}
                                />
                            </div>
                    </div>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div css={{
                            fontSize: "0.7em",
                            color: "#ccc",
                            lineHeight: "initial"
                        }}>
                            En continuant vous permettez à TrueLayer d'initier votre paiement depuis votre compte bancaire.
                            Vous acceptez également les conditions de vente et la politique de confidentialité de TrueLayer.
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </>
        </Grid >
    )
}