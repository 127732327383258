import React, { createRef } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Container, Menu, Sticky } from "semantic-ui-react";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { UserConnectionInformation } from "../user-connection-information";
import {UserConnectionInformationMobile} from "../user-connection-information-mobile"
import {UserConnectionUnAuthMobile} from "../user-connect-info-mobile-unauth"
import { ReactComponent as VillyLogo } from "./logo.svg";
import { useScroll } from "./useScroll";
import { bigApplicationHeader, smallApplicationHeader, secondaryColorVillyz} from "./styles"


type Props = { loginProps?: AuthenticationState };

function ApplicationHeader({ loginProps }: Props) {
  const { scrollY } = useScroll();

  const isFloatingMenu = scrollY > 70;

  const contextRef: React.RefObject<any> = createRef()

  return ( <>
    <div ref={contextRef} css={bigApplicationHeader}>
      <Sticky 
        ref={contextRef}>
        <Menu
          borderless
          secondary
          color={isFloatingMenu ? "purple" : "blue"}
          {...(!isFloatingMenu && { inverted: true })}
          attached="top"
          css={{
            "&.ui.menu": {
              height: "70px",
              transition: "background-color 0.3s ease",
              ...(isFloatingMenu && { backgroundColor: "white", boxShadow: "0px 5px 11px 0px rgba(0,0,0,0.41) !important"}),
            },

          }}
        >
          <Container css={{ width: "100% ", fontFamily:"raleway" }}>
            <Menu.Item
              as={NavLink}
              to="/"
              css={{
                transition: "fill 0.3s ease",
                fill: isFloatingMenu ? secondaryColorVillyz : "white",
              }}
              activeclassname=""
              aria-label="lien vers l'accueil de villyz"
            >
              <VillyLogo/>
            </Menu.Item>
            <Menu.Item as={NavLink} to="/engagements" activeclassname="active">
              Nos engagements
            </Menu.Item>
            <Menu.Item as={NavLink} to="/projets" activeclassname="active">
              Les projets
            </Menu.Item>
            <Menu.Item as={NavLink} to="/collectivites" activeclassname="active">
              Porteur de projet
            </Menu.Item>
            {!loginProps?.isAuthenticated && (
              <>
                <Menu.Item
                  as={NavLink} 
                  to="/connexion" 
                  activeclassname="active"
                  position="right"
                  css={{ padding:"8px 2px !important"}}
                >
                  <Button
                    css={{
                      background: 'none !important',
                      fontWeight:"bold",
                      padding:"9px 25px !important",
                      textAlign:"center",
                      border:"2px solid white !important",
                      borderRadius:"5px !important",
                      transition: "background-color 0.3s ease",
                      fontFamily:"raleway-bold !important",
                      ...(isFloatingMenu && { background: "white !important", border: "2px solid #692244 !important"})
                    }}
                  >
                    <span 
                      css={{
                        fontWeight:"bold",
                        fontSize:"18px",
                        color:" white", 
                        ...(isFloatingMenu && { color: "#692244 !important"})
                      }}
                    >
                      Connexion
                    </span>
                  </Button>
                </Menu.Item>
                <Menu.Item
                  as={NavLink} 
                  to="/inscription" 
                  activeclassname="active"
                  css={{ padding:"8px 2px !important"}}
                >
                  <Button
                    css={{
                      background:"white !important",
                      fontWeight:"bold",
                      padding:"9px 25px !important",
                      textAlign:"center",
                      border:"2px solid white !important",
                      borderRadius:"5px !important",
                      transition: "background-color 0.3s ease",
                      fontFamily:"raleway-bold !important", 
                      ...(isFloatingMenu && { backgroundColor: "#692244!important"}),
                    }}
                  >
                    <span 
                      css={{
                        fontWeight:"bold",
                        fontSize:"18px",
                        color:" #005870", 
                        ...(isFloatingMenu && { color: "white !important"})
                      }}
                    >
                      Inscription
                    </span>
                  </Button>
                </Menu.Item>
              </>
            )}
            {loginProps?.isAuthenticated && (
              <Menu.Menu
                position="right"
                css={{ marginRight: "10px", alignItems: "center" }}
              >
                <UserConnectionInformation />
              </Menu.Menu>
            )}
          </Container>
        </Menu>
      </Sticky>
    </div>
    <div ref={contextRef} css={smallApplicationHeader}>
      <Sticky 
        ref={contextRef}>
        <Menu
          borderless
          secondary
          color={isFloatingMenu ? "purple" : "blue"}
          {...(!isFloatingMenu && { inverted: true })}
          attached="top"
          css={{
            "&.ui.menu": {
              height: "70px",
              transition: "background-color 0.3s ease",
              fontFamily:"raleway-bold" ,
              ...(isFloatingMenu && { backgroundColor: "white", boxShadow: "0px 5px 11px 0px rgba(0,0,0,0.41) !important"}),
            },

          }}
        >
          <Container css={{ width: "100% " }}>
            <Menu.Item
              as={NavLink}
              to="/"
              css={{
                transition: "fill 0.3s ease",
                fill: isFloatingMenu ? "#692244" : "white",
              }}
              activeclassname=""
              aria-label="lien vers l'accueil de villyz"
            >
              <VillyLogo/>
            </Menu.Item>
            {!loginProps?.isAuthenticated && (
              <Menu.Menu
              position="right"
              css={{ marginRight: "10px", alignItems: "center" }}
              aria-labelledby="bouton d'ouverture et fermeture du menu déroulant"
              role="button" 
              >
                <UserConnectionUnAuthMobile />
              </Menu.Menu>
            )}
            {loginProps?.isAuthenticated && (
              <Menu.Menu
                position="right"
                css={{ marginRight: "10px", alignItems: "center" }}
                aria-label="bouton d'ouverture et fermeture du menu déroulant"
              >
                <UserConnectionInformationMobile />
              </Menu.Menu>
            )}
            
          </Container>
        </Menu>
      </Sticky>
      
  </div>
  </>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedApplicationHeader = connect(
  mapStateToProps,
  null
)(ApplicationHeader);
export { ConnectedApplicationHeader as ApplicationHeader };
