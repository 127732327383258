import React from "react"
import { ButtonLink } from "../generic/button-link"

interface Props {
}

export function SubscriptionCollectiviteFormEnding({}: Props) {
  return (
    <div>
      <div className="form-group">
        Merci pour votre inscription ! En attendant la vérification de votre identité, vous pouvez dès à présent parcourir votre espace client et remplir le formulaire de financement de projet
      </div>
      <div style={{ textAlign: "center" }}>
        <ButtonLink 
          label="Accéder à Villyz"
          to="/"
        />
      </div>
    </div>
  );
};

