import { OAuthResponse } from "../model/authentication/oauth-response";
import { GetDonneesPubliquesCollectivitesCurrentYearResponse, GetDonneesPubliquesCollectivitesResponse, ReportingAnnuelResponse, ReportingInfosResponse, ReportingNewsletterMailListResponse, ReportingOnboardingUtilisateursResponse, ReportingStatusDesPaiementsResponse } from "../model/dto/response";
import { ExportPASAnnuelBody } from "../model/dto/body/export-pas-annuel-body";
import { ExportPASMensuelBody } from "../model/dto/body/export-pas-mensuel-body";
import { ExportReportingAnnuelBody } from "../model/dto/body/export-reporting-annuel-body";
import { DonneesImpotResponse } from "../model/dto/response";
import { treatHttpResponseCode } from "./http-helper";

export const dataService = {
    getDonneesPubliquesCollectivitesByCodeUnique,
    getDonneesPubliquesCollectivitesCurrentYearByCodeUnique,
    getDeclarationMensuelle,
    getDeclarationAnnuelle,
    getReportingAnnuel,
    GenerateInvestisseursReportCSV,
    GetAllMailNewsletterInscrits,
    GetReportingOnboardingUtilisateurs,
    GetReportingStatutsDesPaiements
};

function getDonneesPubliquesCollectivitesByCodeUnique(code: string, oauth: OAuthResponse | undefined)
: Promise<GetDonneesPubliquesCollectivitesResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/data/donnees-publiques-collectivite?code=${code}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<GetDonneesPubliquesCollectivitesResponse>
    })
}

function getDonneesPubliquesCollectivitesCurrentYearByCodeUnique(code: string, oauth: OAuthResponse | undefined)
: Promise<GetDonneesPubliquesCollectivitesCurrentYearResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/data/donnees-publiques-collectivite-current-year?code=${code}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<GetDonneesPubliquesCollectivitesCurrentYearResponse>
    })
}

function getDeclarationMensuelle(body: ExportPASMensuelBody, oauth: OAuthResponse | undefined) : Promise<DonneesImpotResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/data/declaration-pas-mensuel`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DonneesImpotResponse>
    })
}

function getDeclarationAnnuelle(body: ExportPASAnnuelBody, oauth: OAuthResponse | undefined) : Promise<DonneesImpotResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/data/declaration-pas-annuel`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DonneesImpotResponse>
    })
}

function getReportingAnnuel(body: ExportReportingAnnuelBody, oauth: OAuthResponse | undefined) : Promise<ReportingAnnuelResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/data/reporting-amf-annuel`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<ReportingAnnuelResponse>
    })
}

function  GenerateInvestisseursReportCSV(projectId: number, oauth: OAuthResponse | undefined): Promise<ReportingInfosResponse> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + oauth?.access_token
      },
    };
  
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/data/projets/${projectId}/investisseurs-reporting`, requestOptions)
    .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<ReportingInfosResponse>
    })
  }

function GetAllMailNewsletterInscrits(oauth: OAuthResponse | undefined) : Promise<ReportingNewsletterMailListResponse> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + oauth?.access_token
          },
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/data/mail-newsletter-inscrits`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<ReportingNewsletterMailListResponse>
    })
}

function GetReportingOnboardingUtilisateurs(oauth: OAuthResponse | undefined) : Promise<ReportingOnboardingUtilisateursResponse> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + oauth?.access_token
          },
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/data/reporting-onboarding-utilisateurs`, requestOptions)
    .then(response => {
        return response.json() as Promise<ReportingOnboardingUtilisateursResponse>
    })
}

function GetReportingStatutsDesPaiements(oauth: OAuthResponse | undefined) : Promise<ReportingStatusDesPaiementsResponse> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + oauth?.access_token
          },
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/data/reporting-statuts-des-paiements`, requestOptions)
    .then(response => {
        return response.json() as Promise<ReportingStatusDesPaiementsResponse>
    })
}