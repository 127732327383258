import React, { useMemo } from "react"
import Projet from "../../../model/projet"
import { Form } from "semantic-ui-react"
import { SubmitButton } from "../../generic/submit-button"
import { investmentService } from "../../../services/investment-service"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../../store"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { DemandeDebouclageBody } from "../../../model/dto/body/demande-debouclage-body"

type Props = {
    projet: Projet,
    isDon: boolean,
    onSubmitSuccess: (response: Response) => void
}

export function DemandeDebouclageForm({projet, isDon, onSubmitSuccess}: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const montant = useMemo(() => {
        if(isDon){
            return projet.MontantCollecteDon! / 100
        }
        return projet.MontantCagnotteActuel! / 100
    }, [])

    const pourcentage = useMemo(() => {
        if(isDon){
            return Math.round((projet.MontantCollecteDon! / projet.MontantRechercheDon!) * 10000) / 100
        }
        return Math.round(montant / projet.MontantFinancementCitoyenDemande! * 10000) /100
    }, [])

    const body: DemandeDebouclageBody = {
        IsDon: isDon
    }

    return (
        <Form css={{display: "block"}}>
            <div>
                Votre période de collecte est maintenant terminée.
            </div>
            <div css={{marginBottom: "20px"}}>
                Vous avez collecté {montant}€, soit {pourcentage}% du montant recherché <br/>
            </div>
            <div>
                Comme défini dans la convention de mandat signée avec Villyz, merci de procéder aux démarches de débouclage en cliquant sur le bouton ci-dessous.
            </div>
            <div css={{textAlign: "center"}}>
                <SubmitButton 
                    data={body}
                    label="Demander le débouclage"
                    action={() => investmentService.askDebouclage(projet.Id!, body, loginProps!.oauth)}
                    onActionSuccess={onSubmitSuccess}
                />
            </div>
        </Form>
    )
}
