import React from "react";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";
import { AuthenticationState, LOGOUT } from "../store/authentication/types";
import { userService } from "../services/authentication-redux-service";
import { Profil } from "../model/entities/profil";
import { useScroll } from "./pages/useScroll";
import {secondaryColorVillyz, pageBackground } from "./pages/styles"

type Props = {
  loginProps?: AuthenticationState;
  logout: () => void;
};

function UserConnectionInformation({ loginProps, logout }: Props) {
  const navigate = useNavigate()
  const {scrollY} = useScroll()
  const isFloatingMenu = scrollY > 70;
  function onLogout() {
    userService.logout();
    logout();
    navigate('/')
  }

  if (!loginProps?.oauth) {
    return null;
  }

  const dashboardRedirect = () => {
    if(loginProps.oauth?.profilCode === Profil.INVESTISSEUR) {
      return "/dashboard/citoyen"
    }
    if(loginProps.oauth?.profilCode === Profil.PORTEUR_PROJET) {
      return "/dashboard/collectivite"
    }
    if(loginProps.oauth?.profilCode === Profil.VILLYZ_USER) {
      return "/dashboard/admin"
    }

    return ""
  }

  return (
    <Dropdown 
      className="right"
      icon={null}
      floating
      trigger={
      <>
        
        <div css={{color: isFloatingMenu ? secondaryColorVillyz : pageBackground , flexDirection : "row", display : "flex"}} >
          <div css={{display : "flex", flex : 3, marginTop : "4px", color: isFloatingMenu ? "black" : "rgba(255, 255, 255, 0.7)"}} >
            Mon Compte
          </div>
          <div css={{display : "flex", flex : 1, paddingLeft : "3px"}}>
            <Icon  name="user circle" size="big"/>
          </div>
        </div>
      </>}
      direction="left"
    >
      <Dropdown.Menu>
        <div css={{ padding: "10px" }} onClick={(e) => e.stopPropagation()}>
          <div
            css={{
              display: "flex",
              alignItems: "center",
              color: "black",
            }}
          >
            <div css={{color: isFloatingMenu ? secondaryColorVillyz : pageBackground }} >
              <Icon name="user circle" size="big"/>
            </div>
            <div css={{ paddingLeft: "5px" }}>
              <div css={{ fontWeight: "bold" }}>
                {loginProps.oauth.userPrenom} {loginProps.oauth.userNom}
              </div>
              <span>{loginProps.oauth.userMail}</span>
            </div>
          </div>
        </div>
        <Dropdown.Divider />
        <Dropdown.Item text="Profil" as={Link} to="/profil" />
        <Dropdown.Item
          text="Tableau de bord"
          as={Link}
          to={dashboardRedirect()}
        />
        <Dropdown.Item text="Se déconnecter" onClick={onLogout} />
      </Dropdown.Menu>
    </Dropdown>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedUserConnectionInformation = connect(
  mapStateToProps,
  (dispatch) => ({
    logout: () => dispatch({ type: LOGOUT }),
  })
)(UserConnectionInformation);
export { ConnectedUserConnectionInformation as UserConnectionInformation };
