import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Checkbox, Divider, Grid, Icon, Popup } from "semantic-ui-react"
import { makeDownloadFromBlob } from "../../helpers/date-helper"
import { Investissementbody } from "../../model/dto/body/investissement-body"
import { SimulationBody } from "../../model/dto/body/simulation-body"
import { ProjetListItem } from "../../model/dto/projet-list-item"
import { SimulationResponse} from "../../model/dto/response"
import { CategorieEtablissement } from "../../model/entities/categorie-etablissement"
import { TypeEmprunt } from "../../model/entities/type-emprunt"
import { AuthenticationState } from "../../model/state/authentication-state"
import { investmentService } from "../../services/investment-service"
import { projetService } from "../../services/projet-service"
import { ApplicationState } from "../../store"
import { EditableBlock } from "../generic/editable-block/editable-block"
import { FormErrorHeader } from "../generic/form-error-header"
import { InputField } from "../generic/input-field"
import { SimpleButton } from "../generic/simple-button"

interface Props {
    projet?: ProjetListItem,
    montant?: number | string,
    montantPercuValue?: string,
    isContratCheckedValue?: boolean,
    numeroReponseTestList?: number[],
    score?: number,
    montantDisponibleVillyz?:number,
    userSumPrevisionnelForProject?:number,
    projectSumPrevisionnel?:number,
    isDon?: boolean,
    onValidateMontant?: (montant: number,  montantPercu: string, isContratChecked: boolean, isNextStep?: boolean) => void
}

interface FieldData {
    value?: any,
    name?: string,
    isValid?: boolean,
    key?: string
}

export function MontantPaiementPane({ 
    projet, 
    montant,
    montantPercuValue,
    isContratCheckedValue,
    numeroReponseTestList, 
    score,
    montantDisponibleVillyz,
    projectSumPrevisionnel,
    userSumPrevisionnelForProject,
    isDon,
    onValidateMontant }: Props) {

    const [montantField, setMontantField] = useState<FieldData>({name: "montantField", value: montant, isValid: true})
    const [montantPercu, setMontantPercu] = useState<FieldData>({ value: montantPercuValue, isValid: true })
    const [isContratChecked, setIsContratChecked] = useState(isContratCheckedValue)
    const [isAvertissementChecked, setIsAvertissementChecked] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [errorGenerationMessage, setErrorGenerationMessage] = useState<string>("")
    const [isNotSameHorizon, setIsNotSameHorizon] = useState(false)
    const [isBloque, setIsBloque] = useState(false)
    const [isErrorMaxLimitMessageDisplay, setIsErrorMaxLimitMessageDisplay] = useState(false)

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(() => {
        if(projet) {
            const reponseCinq: boolean = numeroReponseTestList?.find(reponseNumero => reponseNumero === 5) !== undefined;
            const reponseSix: boolean = numeroReponseTestList?.find(reponseNumero => reponseNumero === 6) !== undefined;
            const reponseSept: boolean = numeroReponseTestList?.find(reponseNumero => reponseNumero === 7) !== undefined;
               
            if(reponseSept || reponseSix && projet.MaturiteCreditCitoyen && projet.MaturiteCreditCitoyen >= 84 || reponseCinq && projet.MaturiteCreditCitoyen && projet.MaturiteCreditCitoyen <= 84) {
                setIsNotSameHorizon(false)
            }
            else {
                setIsNotSameHorizon(true)
            }
        }
    
    }, [numeroReponseTestList, projet])

    const scoreSeuil = useMemo(() => {
        if(projet?.CategorieEtablissement === CategorieEtablissement.COLLECTIVITE) {
            return 60
        }
        else {
            return 90
        }
    }, [projet])

    const investmentExceedingError = useMemo(() => {
        if(userSumPrevisionnelForProject !== undefined) {

            let totalPrevisonnel: number = userSumPrevisionnelForProject + montantField.value

            if (!isDon && totalPrevisonnel > projet?.MontantMaximalInvestissement! / 100) {
                return "Votre opération dépasserait les " + projet?.MontantMaximalInvestissement! / 100 + " euros réglementaires."
            }

            if (isDon && totalPrevisonnel > projet?.MontantMaxPersonneDon! / 100) {
                return "Votre opération dépasserait les " + projet?.MontantMaxPersonneDon! / 100 + " euros réglementaires."
            }
    
            return ""
        }
    }, [montantField.value, projet?.MontantMaximalInvestissement, projet?.MontantMaxPersonneDon, userSumPrevisionnelForProject])

    const maxLimitCollecteMessage = "Désolé, des opérations viennent d'être réalisées et ont rempli la collecte."
    const maxToInvestMessage = (montant: number) => {return `Désolé des opérations viennent d'être réalisées et vous ne pouvez plus que investir ${montant} euros car la collecte sera complète.`}

    const calculateLimitEmpruntProjectResult = () => {
        if(projectSumPrevisionnel!== undefined){
            if((projet?.MontantMaximalFinancement! / 100 - projectSumPrevisionnel) <= 0 ){
                return maxLimitCollecteMessage
            }
            if(projet?.MontantMaximalFinancement! / 100 - projectSumPrevisionnel  - montantField.value < 0){
                return maxToInvestMessage(projet?.MontantMaximalFinancement! / 100 - projectSumPrevisionnel)
            }
            return "" 
        }
    }

    const calculateLimitDonProjectResult = () => {
        if(projectSumPrevisionnel!== undefined){
            if((projet?.MontantMaxCollecteDon! / 100 - projectSumPrevisionnel) <= 0 ){
                return maxLimitCollecteMessage
            }
            if(projet?.MontantMaxCollecteDon! / 100 - projectSumPrevisionnel  - montantField.value < 0){
                return maxToInvestMessage(projet?.MontantMaxCollecteDon! / 100 - projectSumPrevisionnel)
            }
            return "" 
        }
    }

    const investmentLimitError = useMemo(() => {
        return isDon ? calculateLimitDonProjectResult() : calculateLimitEmpruntProjectResult()
    }, [montantField.value, projectSumPrevisionnel, projet?.MontantMaxCollecteDon, projet?.MontantMaximalFinancement])

    const displayError = useMemo(() => {
        if ((investmentExceedingError !== "" && investmentExceedingError !== undefined ) || (investmentLimitError !== "" && investmentLimitError !== undefined)) {
            let message = investmentExceedingError + "\n" + investmentLimitError
            setIsErrorMaxLimitMessageDisplay(true)
            return message
        }
        else {
            setIsErrorMaxLimitMessageDisplay(false)
            return ""
        }
    }, [investmentExceedingError, investmentLimitError])

    const maxNoWarning: number = useMemo(() => {
        const valeurPatrimoniale = loginProps?.user?.ValeurPatrimonialeNette ?? 0
        return Math.max(1000, valeurPatrimoniale / 10)
    }, [])

    const isUtilisateurNovice = useMemo(() => {
        return (score && score < scoreSeuil || isNotSameHorizon)
    }, [score, projet, isNotSameHorizon])

    const calculateMontantResteAPayer = useMemo(() => {
        if (montantDisponibleVillyz && !isDon && montantField.value > montantDisponibleVillyz && montantField.value > 0) {
          return montantField.value - montantDisponibleVillyz;
        } else { 
            return 0;
        }
    }, [montantField.value, montantDisponibleVillyz]);

    async function simulateInvestment(e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean, key?: string) {
        setErrorGenerationMessage("")

        if (e.target.value !== "") {
            var investedAmount = parseInt(e.target.value, 10);
        } else {
            var investedAmount = 0
        }

        setMontantField({ value: investedAmount, name: fieldName, isValid: isValid, key: key })

        if(montantField.value < maxNoWarning && isAvertissementChecked){
            setIsAvertissementChecked(false)
        }

        setFormSubmitted(false)

        const body: SimulationBody = {
            MontantSimulation: investedAmount * 100
        }

        if (!isDon && investedAmount) {
            const response: SimulationResponse = await projetService.getSimulatedInvestment(
                body,
                projet?.Id!
            );
            const montantPercu: number = response.MontantPrevisionnelPercu!;
            setMontantPercu({ value: montantPercu.toString() + "€" })
            if(onValidateMontant) {
                onValidateMontant(investedAmount, montantPercu.toString() + "€",isContratChecked!, false)
            }
        } else {
            const montantPercu: number = 0;
            setMontantPercu({ value: montantPercu.toString() + "€" })
        }
    }

    const validateMandatory = (value: any): boolean => {
        if (!value || value === "") {
            return false
        }
        else {
            if (!isDon && (value < 1 || value > projet?.MontantMaximalInvestissement! / 100)) {
                return (false)
            }
            if (isDon && (value < 1 || value > projet?.MontantMaxPersonneDon! / 100)) {
                return (false)
            }
            return true
        }
        
    }

    const isFormValid = () => {
        if(montantField.value && montantField.value > maxNoWarning && score && score >= scoreSeuil && !isNotSameHorizon) {
            return validateMandatory(montantField.value) && isContratChecked && isAvertissementChecked && displayError === ""
        }
        return validateMandatory(montantField.value) && isContratChecked && displayError === ""
    }

    const body: Investissementbody = {
        Montant: montantField.value.toString(),    
        IsDon: isDon   
    }

    const coutDon = useMemo(() => {
        if (montantField.value > 0 && isDon) {
            return parseFloat((montantField.value * 0.40).toFixed(2)) + " €";
        }
    }, [montantField.value, isDon]);

    return (
        <EditableBlock
            title=""
            editor={(close) => null}
            editable={false}
        >
            <div style={{ padding: "10px" }}>
                <FormErrorHeader 
                    message="Vous ne pouvez pas investir actuellement car votre compte a été bloqué, veuillez vous rapprocher du support Villyz" 
                    displayed={isBloque} 
                />
                <FormErrorHeader message={displayError} displayed={isErrorMaxLimitMessageDisplay} />
                <FormErrorHeader message={errorGenerationMessage} displayed={errorGenerationMessage !== ""} />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            {isDon &&
                                <h3 css={{color: "#000"}}>Montant du Don</h3>
                            }
                            {montantDisponibleVillyz! > 0 && !isDon &&
                                <h3 css={{color: "#000"}}>Montant de l’investissement</h3>
                            }
                            {!montantDisponibleVillyz && !isDon &&
                                <h3 css={{color: "#000"}}>Montant</h3>
                            }
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <InputField
                                data={montantField}
                                onChange={simulateInvestment}
                                placeholder="€"
                                noStyle
                                inputCss={{
                                    width: "130px",
                                    height: "30px",
                                    border: "1px solid grey",
                                    borderRadius: "5px",
                                    float: "right"
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {!validateMandatory(montantField.value) && formSubmitted && (
                        <div
                            css={{
                                color: "#F03E3E",
                                fontSize: "10px",
                                marginTop: "4px",
                            }}
                        >
                            Veuillez renseigner le montant que vous souhaitez investir
                        </div>
                    )}
                    {montantDisponibleVillyz !== 0 && !isDon &&
                        <>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <div
                                        css={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: "-14px 0px"
                                        }}
                                    >
                                        <div>
                                            <span>Montant disponible pour réinvestissement</span>
                                            <span css={{fontWeight: "bold", float: "right", color: "#72CA75"}}>{montantDisponibleVillyz} €</span>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Divider  css={{marginTop: '-4px !important', marginBottom: '-16px !important'}}/>
                        </>
                    }
                    <Grid.Row css={{paddingTop: '0px !important'}}>
                        {montantDisponibleVillyz !== undefined &&  montantDisponibleVillyz > 0 && !isDon &&
                            <>
                                <Grid.Column width={10}>
                                    <div
                                        css={{
                                            display: "flex",
                                            flexDirection: "row"
                                        }}
                                    >
                                        <h3 css={{marginRight: "10px", color: "#000"}}>Montant restant à payer</h3>
                                        <Popup
                                            content='Représente le montant que vous devez payer pour atteindre le montant que vous souhaitez investir compte tenu du montant de réinvestissement disponible affiché ci-dessus'
                                            trigger={
                                                <Icon
                                                    name="info circle"
                                                    size="large"
                                                    color="blue"
                                                />
                                            }
                                        />
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <h3 css={{ float: "right" }}>{calculateMontantResteAPayer} €</h3>
                                </Grid.Column>
                            </>
                        }
                        <Grid.Column width={16}>
                            <div
                                css={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                <div>
                                    <span>Frais supportés</span>
                                    <span css={{ float: "right" }}>0€</span>
                                </div>
                                {!isDon ?
                                    <div>
                                        <span>Taux d'intérêt</span>
                                        <span css={{ float: "right" }}>{projet && projet.TauxInteretCitoyen ? `${projet.TauxInteretCitoyen} %` : ""}</span>
                                    </div>
                                :
                                    <div>
                                        <span>Défiscalisation</span>
                                        <span css={{ float: "right" }}>60%</span>
                                    </div>
                                }
                                {!isDon &&
                                    <div>
                                        <span>Durée</span>
                                        <span css={{ float: "right" }}>{projet && projet.MaturiteCreditCitoyen ? `${projet.MaturiteCreditCitoyen} mois` : ""}</span>
                                    </div>
                                }
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider css={{marginTop: '5px !important', marginBottom: '5px !important'}}/>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            {!isDon ?
                                <div css={{display: "flex", flexDirection: "row"}}>
                                    <h3 css={{marginRight: "10px", color: "#000"}}>Montant à percevoir au total</h3>
                                    <Popup
                                        content='Le montant correspond à la somme du capital investi et des intérêts.'
                                        trigger={
                                            <Icon
                                                name="info circle"
                                                size="large"
                                                color="blue"
                                            />
                                        }
                                    />
                                </div>
                                :
                                <div css={{display: "flex", flexDirection: "row"}}>
                                    <h3 css={{marginRight: "10px", color: "#000"}}>Coût du don</h3>
                                    <Popup
                                        content="L’ensemble des versements permet de bénéficier d’une réduction d’impôt sur les sociétés de 60% du montant de ces versements. La réduction est plafonnée à 20 000€ ou 5&#8240; (5 pour mille) du chiffre d’affaires annuel hors taxe de l’entreprise. En cas de dépassement du plafond. L’excédent est reportable sur les 5 prochains exercices"
                                        trigger={
                                            <Icon
                                                name="info circle"
                                                size="large"
                                                color="blue"
                                            />
                                        }
                                    />
                                </div>
                            }
                        </Grid.Column>
                        <Grid.Column width={6}>
                            {!isDon ?
                                <h3 css={{ float: "right" }}>{montantPercu.value}</h3>
                            :
                                <h3 css={{ float: "right" }}>{coutDon}</h3>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    {projet?.TypeEmpruntCode === TypeEmprunt.TITRES &&
                        <Grid.Row centered>
                            <div
                                css={{
                                    fontSize: "1rem",
                                    background: "#D1E9F7",
                                    padding: "0.5em",
                                    borderRadius: "0.3rem",
                                    borderStyle: "solid",
                                    borderWidth: "thin",
                                    color: "#517FB1",
                                    marginBottom: "1.2em",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <Icon
                                    name="info circle"
                                    size="big"
                                />
                                Si vous êtes imposable vous pouvez bénéficier de XX€ de réduction fiscale.
                            </div>
                        </Grid.Row>
                    }
                    {!isDon &&
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div
                                    css={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                >
                                    <Checkbox
                                        required
                                        name="contratPret"
                                        value="ok"
                                        checked={isContratChecked}
                                        onClick={(event, props) => {
                                            setFormSubmitted(false)
                                            setIsContratChecked(props.checked!)
                                            if(onValidateMontant) {
                                                onValidateMontant(montantField.value, montantPercu.value.toString(), props.checked!, false)
                                            }
                                        }}
                                        label={
                                            <label>
                                            <span 
                                                onClick={(event)=>{
                                                    // Cette méthode va empêcher la propagation de l'événement 'Click'
                                                    event.stopPropagation()
                                                }}
                                            >
                                                J'accepte les termes du {" "}
                                                <div
                                                    css={{
                                                        display: "inline-block",
                                                        textDecoration: "underline"
                                                    }}
                                                    onClick={() => {
                                                        if (montantField.value) {
                                                            investmentService.simulateContract(projet?.Id!, body, loginProps!.oauth)
                                                            .then(response => {
                                                                makeDownloadFromBlob(response, "contrat.pdf");
                                                            })
                                                        }
                                                        else {
                                                            setErrorGenerationMessage("Veuillez saisir un montant pour votre investissement afin de prévisualiser votre contrat de prêt")
                                                        }
                                                    }}
                                                >
                                                    contrat de prêt
                                                </div>
                                            </span>
                                            </label>
                                        }
                                    />
                                    {!isContratChecked && formSubmitted && (
                                        <div
                                            css={{
                                                color: "#F03E3E",
                                                fontSize: "10px",
                                                marginTop: "4px",
                                            }}
                                        >
                                            Veuillez cocher cette case
                                        </div>
                                    )}
                                    {montantField.value !== "" && !isUtilisateurNovice && montantField.value > maxNoWarning && (
                                        <div
                                            css={{marginTop: "4px"}}>
                                            <Checkbox
                                                required
                                                name="avertissement"
                                                value="ok"
                                                onClick={(event, props) => {
                                                    setFormSubmitted(false)
                                                    setIsAvertissementChecked(props.checked!)
                                                }}
                                                label={
                                                    <label>
                                                        <span>
                                                            Un investissement dans un projet de financement participatif présente des risques de perte totale du capital.
                                                        </span>
                                                    </label>
                                                }
                                            />
                                            {!isAvertissementChecked && formSubmitted && (
                                                <div
                                                    css={{
                                                        color: "#F03E3E",
                                                        fontSize: "10px",
                                                        marginTop: "4px",
                                                    }}
                                                >
                                                    Veuillez cocher cette case
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    }
                    {isDon &&
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div
                                    css={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                >
                                    <Checkbox
                                        required
                                        name="contratDon"
                                        value="ok"
                                        checked={isContratChecked}
                                        onClick={(event, props) => {
                                            setFormSubmitted(false)
                                            setIsContratChecked(props.checked!)
                                            if(onValidateMontant) {
                                                onValidateMontant(montantField.value, montantPercu.value.toString(), props.checked!, false)
                                            }
                                        }}
                                        label={
                                            <label>
                                                <p>J'accepte les termes du {" "}
                                                    <span
                                                    onClick={() => {
                                                        if (montantField.value) {
                                                            investmentService.simulateContract(projet?.Id!, body, loginProps!.oauth)
                                                            .then(response => {
                                                                makeDownloadFromBlob(response, "contratDon.pdf");
                                                            })
                                                        }
                                                        else {
                                                            setErrorGenerationMessage("Veuillez saisir un montant pour votre donation afin de prévisualiser votre contrat de don")
                                                        }
                                                    }}
                                                    css={{textDecoration: 'underline'}}
                                                    >contrat de don</span>
                                                </p>
                                            </label>
                                        }
                                    />
                                    {!isContratChecked && formSubmitted && (
                                        <div
                                            css={{
                                                color: "#F03E3E",
                                                fontSize: "10px",
                                                marginTop: "4px",
                                            }}
                                        >
                                            Veuillez cocher cette case
                                        </div>
                                    )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    }
                    <Grid.Row>
                        <Grid.Column>
                            <div css={{float: "right"}}>
                            <SimpleButton
                                label="Procéder au paiement"
                                onClick={() => {
                                    setFormSubmitted(true);
                                    if(loginProps.user?.IsBloque) {
                                        setIsBloque(true)
                                        return
                                    }

                                    if (isFormValid()) {
                                        onValidateMontant && onValidateMontant(montantField.value, montantPercu.value, isContratChecked!, true);
                                    } else {
                                        return
                                    }   
                                }}
                            />

                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </EditableBlock>
    )
}