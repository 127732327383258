import React, { useEffect, useMemo, useState } from "react"
import { BankProvider } from "../../model/entities/bank-provider"

type Props = {
    provider: BankProvider,
    selectedProvider?: BankProvider,
    onSelectProvider?: (provider?: BankProvider) => void
}

export function BankProviderSelector({provider, selectedProvider, onSelectProvider}: Props) {
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        if(provider.ProviderId === selectedProvider?.ProviderId) {
            setIsSelected(true)
        }
        else {
            setIsSelected(false)
        }
    }, [selectedProvider])

    const onClick = () => {
        if(!isSelected) {
            setIsSelected(true)
            if(onSelectProvider) {
                onSelectProvider(provider)
            }
        }
        else {
            setIsSelected(false)
            if(onSelectProvider) {
                onSelectProvider(undefined)
            }
        }
    }

    const cssUnselected = {
        padding: "1px 1px",
        border: "1px solid #ccc",
        borderRadius: "2px",
        margin: "3px 0px 3px 0px",
        cursor: "pointer"
    }

    const cssSelected = {
        padding: "1px 1px",
        border: "1px solid #005870",
        borderRadius: "2px",
        borderWidth: "2px",
        margin: "3px 0px 3px 0px",
        cursor: "pointer"
    }

    const css = useMemo(() => {
        if(isSelected) {
            return cssSelected
        }
        else {
            return cssUnselected
        }
    }, [isSelected])

    return(
        <div 
            css={css}
            onClick={onClick}
        >
            <div css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                lineHeight: "initial",
                minWidth: "100px",
                fontSize: "16px"
            }}>
                <span css={{
                    display: "inline-block",
                    marginRight: "15px",
                    marginLeft: "30px"
                }}>
                    <img 
                        css={{
                            width: "40px",
                            height: "40px",
                            objectFit: "cover",
                            borderRadius: "50%"
                        }}
                        src={provider.IconUrl} 
                    />
                </span>
                <span css={{
                    fontSize: "0.8em",
                    fontWeight: "bold",
                    textAlign: "center",
                    lineHeight: "initial",
                    display: "inline-block"
                }}>
                    <div css={{
                        display: "block"
                    }}>
                        {provider.DisplayName}
                    </div>
                </span>
            </div>
        </div>
    )
}