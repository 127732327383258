import React, {useState} from 'react'
import { Popup } from 'semantic-ui-react'

interface Props {
    value: string | number | undefined
}

export default function ClipboardCopier({value}:Props) {
    const [isClicked, setIsClicked] = useState(false)

    const showIsClicked = () => {
        setIsClicked(true)
        setTimeout(() => {
            setIsClicked(false)    
            }, 600)
    }
    
    const copyToClipboard = () => {
        if(value){
            navigator.clipboard.writeText(value.toString())
            .then(()=>  showIsClicked(),
            error => console.log('erreur pour copier ', error))
        }
    }
    const popupMessage = isClicked ? <i>Copiée !</i> : 'Copier la valeur' 
   
  return (
        <Popup 
         content={popupMessage}
         position='top left'
         size='small'
         on='hover'
         pinned
         mouseEnterDelay={200}
         mouseLeaveDelay={200}
         hideOnScroll
         trigger={
            <button disabled={isClicked} onClick={copyToClipboard}>
                <img src='/img/icones/copier.svg' alt='icone de bouton de copie' />
            </button>
         }/>
  )
}
