import React from 'react'
import { NavLink } from 'react-router-dom'

export const citoyenPanelGeneral = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Qu'est-ce que l'investissement citoyen  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    L'investissement citoyen est un prêt rémunéré à taux fixe qui permet de placer son épargne dans des projets d'intérêt général porté par des acteurs publics : collectivités, établissements de santé ou établissements publics locaux. 
                </p>
                <p>
                    L'investissement citoyen permet de disposer d'un retour sur investissement (les intérêts du prêt) selon un échéancier connu à l'avance, ainsi que du remboursement de la somme prêtée (appelée le principal).
                </p>
              </div>
            ),
        }
    },
    {
        key: '1 bis',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Qu'est-ce que le mécénat d'entreprise ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    C'est la possibilité donnée à toutes les entreprises de participer au développement de projets d'intérêt général en réalisant un don financier.
                </p>
                <p>
                    Ce don peut être défiscalisé à 60% sous certaines conditions (voir la question « Quelle fiscalité pour le mécénat d'entreprise ? »).
                </p>
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Qui peut proposer un projet ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les porteurs de projet acceptés sur la plateforme sont les organismes suivants :
                </p>
                <ul style={{marginLeft:"20px"}}>
                    <li>Collectivités territoriales : communes,  établissements publics de coopération intercommunale (EPCI) à fiscalité propre, départements et régions ;</li>
                    <li>Établissements de santé : centres hospitaliers,  centres de santé, EPHAD ;</li>
                    <li>Entreprises Publiques Locales (EPL) : Sociétés Publiques Locales (SPL), Sociétés d'Economie Mixte (SEM) ;</li>
                    <li>Syndicats Mixtes (EPCL) ;</li>
                    <li>Établissement Public à caractère Administratif (EPA) ;</li>
                    <li>Services départementaux d'incendie et de secours (SDIS) ;</li>
                    <li>Autres sociétés lorsque leur structuration et activité répond à une problématique d'intérêt général.</li>
                </ul>
              </div>
            ),
        }
    },
    
    {
        key: '4',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Qui peut financer les projets ?</span>)},
        content: {
            content: (
                <div>
                    <p>
                        Pour les projets en prêt rémunéré, toute personne majeure fiscalement domiciliés en France peut participer au développement de son territoire en investissant son épargne.
                    </p>
                    <p>
                    Pour les projets de mécénat, toute entreprise, quelle que soit sa taille ou sa forme juridique, peut soutenir les projets via le mécénat d'entreprise.
                    </p>
                </div>
            ),
        }
    },
    {
        key: '10',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Comment s'inscrire ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    L’inscription des personnes est simple et se fait via la <NavLink to={"/inscription"}> page d'inscription</NavLink>.</p>
                <p>
                    Il vous sera demandé de renseigner une adresse email, des informations personnelles liés à votre identité et à la réglementation financière, puis de télécharger une pièce d'identité (passeport ou carte nationale d'identité) pour valider votre profil. 
                </p>
                <p>
                    Pour les entreprises souhaitant financer des projets via du mécénat, le numéro SIREN et la forme juridique de la société sera également à renseigner.
                </p>
                <p>
                    Pour les porteurs de projet, un parcours dédié nécessitant le téléchargement de quelques documents administratifs vous sera proposé.
                </p>
              </div>
            ),
        }
    },
    
    {
        key: '9',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quelle utilisation est faite de mes données ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Afin de vérifier l’identité des utilisateurs et de fournir un service fonctionnel de financement participatif, la plateforme récolte des données personnelles auprès de ses utilisateurs. Celles-ci ne sont en aucun cas revendues à des tiers.
                </p>
                <p>
                    Elle protège la confidentialité et la sécurité des données personnelles en conformité avec la réglementation applicable à la protection des données à caractère personnel, et notamment le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (« RGPD »).
                </p>
                <p>
                    Pour plus d'informations, nous vous invitons à consulter notre <NavLink to="https://storage.gra.cloud.ovh.net/v1/AUTH_36f6e4b864bd4af78a6b90757af4090b/villyz_assets/rgbd.pdf"  target="_blank"
                    >politique de confidentialité</NavLink>.
                </p>
              </div>
            ),
        }
    },
    {
        key: '11',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Qui puis-je contacter pour avoir plus d'informations ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les financeurs de projets, investisseurs particuliers et entreprises mécènes, peuvent poser toutes leurs questions à l’adresse investisseurs@villyz.fr
                </p>
                <p>
                    Les porteurs de projet peuvent se renseigner grâce à l’adresse projets@villyz.fr
                </p>
              </div>
            ),
        }
    },
]

export const citoyenPanelPretParticipatifCitoyen = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quel montant minimum d'investissement ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les particuliers peuvent investir dans les projets dès le premier euro.
                </p>
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quels sont les frais pour investisseurs ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Il n'y a aucun frais pour les investisseurs.
                </p>
                <p>
                    Pour assurer la vérification de l'identité des investisseurs, la gestion des flux de paiement et l'évaluation du risque de chaque projet, une commission est prélevée au porteur de projet.
                </p>
              </div>
            ),
        }
    },
    {
        key: '3',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Où trouver les caractéristiques financières de l'investissement citoyen ?</span>)},
        content: {
            content: (
              <div>
                <p>
                Sur la page de présentation de chaque projet ainsi que dans les contrats d'investissement, vous trouverez toutes les caractéristiques des prêts : 
                </p>
                <ul style={{marginLeft:"20px", marginBottom:"5px"}}>
                    <li>Montant de l'investissement</li>
                    <li>Taux d'intérêt brut</li>
                    <li>Durée d'amortissement</li>
                    <li>Modalités de remboursement (ex : trimestriel, annuel)</li>
                    <li>Type d'amortissement  (ex : échéance constante, amortissement constant)</li>
                </ul>
                <p>
                    Vous pouvez également retrouver toutes les informations sur les risques dans la section « Analyse financière ».
                </p>
              </div>
            ),
        }
    },
    {
        key: '4',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>À quel moment récupérer son argent investi ?</span>)},
        content: {
            content: (
              <div>
                <p id="fiscalite-citoyen">
                    Les remboursements ont lieu régulièrement, selon le calendrier prévisionnel présent sur chaque page projet, ainsi que sur les contrats d’investissement.
                </p>
              </div>
            ),
        }
    },
    {
        key: '5',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quelle fiscalité pour l'investissement citoyen ?</span>)},
        content: {
            content: (
              <div >
                <p>
                    Selon le régime fiscal français, Villyz prélèvera automatiquement sur les intérêts de vos investissements une retenue à la source forfaitaire de 30% au titre du prélèvement forfaitaire unique.
                </p>
                <p>
                    Il s'agit d'un acompte sur votre impôt sur le revenu (12,8%), auquel s'ajoutent les prélèvements sociaux (17,2%).
                </p>
                <p>
                    Pour vous aider à déclarer vos revenus provenant des prêts réalisés, Villyz met à disposition un Imprimé Fiscal Unique que vous pouvez demander à l'adresse investisseurs@villyz.fr 
                </p>
                <p>
                    Les particuliers dont le revenu fiscal de référence de l'année précédente est inférieur à 25 000€ pour une personne seule ou inférieur à 50 000€ pour un couple peuvent être dispensés de régler les 12,8% d'acompte sur l'Impôt sur le Revenu, en adressant un mail à cette même adresse.
                </p>
              </div>
            ),
        }
    },
    {
        key: '6',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Peut-on être dispensé du prélèvement forfaitaire unique ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les particuliers dont le revenu fiscal de référence de l'année précédente est inférieur à 25 000€ pour une personne seule ou inférieur à 50 000€ pour un couple peuvent être dispensés de régler les 12,8% d'acompte sur l'Impôt sur le Revenu, en réalisant une demande à l'adresse investisseurs@villyz.fr
                </p>
              </div>
            ),
        }
    }
]

export const citoyenPanelRisques = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>L'investissement citoyen est-il risqué ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Comme tout investissement, il présente un risque de perte en capital car tout porteur de projet peut faire défaut ou avoir du retard dans ses remboursements. 
                </p>
                <p>
                    Il est toujours conseillé de diversifier vos placements pour minimiser le risque. Les sommes investies sont indisponibles pendant toute la durée de l'investissement.
                </p>

                <p>
                    Grâce à notre simulateur sur votre page profil, vous pouvez également déterminer votre capacité d'investissement. Ceux que vous envisagez d'octroyer ne doivent pas représenter plus de 10% de votre patrimoine total.
                </p>

                <p>
                    Avant d'investir, prenez en compte la durée de votre investissement, le profil de risque renseigné ainsi que le montant.
                </p>
                <p>
                    Pour plus d'information, voir notre <NavLink to="/risques">politique de risques</NavLink> .
                </p>
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Puis-je modifier un investissement ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Tous les particuliers disposent de quatre jours, une fois leur investissement citoyen réalisé, pour demander l'annulation d'un paiement et son remboursement.
                </p>
                <p>
                    Tous les remboursements ont lieu sans frais pour les investisseurs.
                </p>
              </div>
            ),
        }
    },
    {
        key: '3',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Que se passe-t-il en cas de retard de paiement ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Vous en serez immédiatement informé et nos équipes prendrons contact avec le porteur de projet pour rétablir la situation.
                </p>

                <p>
                    Au bout de 8 jours calendaires et en l'absence de réponse, nous enverrons une mise en demeure au porteur de projet et engagerons par la suite une procédure de recouvrement des fonds. 
                </p>
              </div>
            ),
        }
    },
    {
        key: '4',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Que se passe-t-il si Villyz cesse son activité ?</span>)},
        content: {
            content: (
              <div>
                <p>
                Villyz a conclu un contrat de gestion extinctive avec un partenaire afin de permettre à la plateforme cesser son activité de manière ordonnée.
                </p>

                <p>
                En cas de gestion extinctive, les nouveaux projets seront suspendus, l'argent renvoyé sur les portefeuilles électroniques de financeurs et la plateforme fonctionnera avec quelques fonctionnalités réduites en vue de sa fermeture : maintien des remboursements jusqu'à la dernière échéance prévue et retrait de l'argent placé sur les portefeuilles électroniques des utilisateurs.
                </p>
              </div>
            ),
        }
    }
]

export const projetsPanel = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Comment sont sélectionnés les projets ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les projets proposés doivent correspondre à des investissements d'intérêt général : création de nouvelles mobilités durables, lutte contre les déserts médicaux, accès à l'éducation, etc.
                </p>
                <p>
                    Chaque projet peut ainsi répondre à un ou plusieurs Objectifs de Développement Durable de l'ONU.    
                </p>
                <p>
                    Pour voir leurs projets publiés, les porteurs de projets doivent présenter une solidité financière importante qui sera évaluée par notre comité de sélection avant publication (voir notre <NavLink to={"/risques"}>politique de risques</NavLink>).
                </p>
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quel montant maximum peut représenter un projet ?</span>)},
        content: {
            content: (
                <div>
                    <p>
                    Les projets présents sur Villyz peuvent représenter au maximum 5 millions d'euros par porteur de projet et par an pour l'investissement citoyen (prêt rémunéré), et 1 million d'euros par projet en don.
                    </p>
                </div>
            ),
        }
    },
    {
        key: '3',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Comment est évaluée la solidité financière d'un porteur de projet ?
            </span>)},
        content: {
            content: (
              <div>
                <p>
                    Le comité de sélection de Villyz analyse l'état général de ses finances ainsi que ainsi que l'impact financier du projet.
                </p>
                <p>
                    Dans le cas d'une collectivité, plusieurs critères financiers sont analysés, notamment :
                </p>
                <ul style={{marginLeft:"20px", marginBottom:20}}>
                    <li>
                        La capacité de désendettement 
                    </li>

                    <li>
                        La marge d'autofinancement courante
                    </li>

                    <li>
                        Le coefficient de rigidité structurelle
                    </li>

                    <li>
                        Le taux d'épargne brute
                    </li>

                    <li>
                        L'encours total de dette
                    </li>
                </ul>
                <p>
                    Dans le cas d'un établissement de santé, d'un établissement publique local ou de tout autre porteur de projet, plusieurs critères financiers sont analysés le cas échéant, notamment :
                </p>
                <ul style={{marginLeft:"20px", marginBottom:20}}>
                    <li>
                       Le fonds de roulement net à court terme
                    </li>

                    <li>
                       La part des réserves dans l'actif 
                    </li>

                    <li>
                        Le rapport entre les bénéfices avant intérêts et impôt et l'actif 
                    </li>

                    <li>
                        Le ratio de levier
                    </li>
                    <li>
                        Le taux de rotation des actifs
                    </li>
                </ul>
                <p>
                    L'analyse du porteur de projet est accessible à tous sur chaque page projet où une note d'analyse financière est publiée. Pour plus d'informations, voir notre <NavLink to="/risques">politique de risques</NavLink>.
                </p>
              </div>
            ),
        }
    },
    {
        key: '4',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quels sont les frais pour les porteurs de projets ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les frais pour les porteurs de projets ne peuvent dépasser 4% H.T du Montant collecté pour des Projets d'Investissement et 6% H.T. du Montant collecté pour des Projets de Dons.
                </p>
                <p>
                    Ils peuvent varier en fonction des conditions particulières et spécifiques, comme les catastrophes naturelles pour permettre à Villyz de soutenir particulièrement certaines causes.
                </p>
              </div>
            ),
        }
    },
    {
        key: '5',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>L'objectif de collecte peut-il être dépassé ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Pour les projets en prêt comme en don, les objectifs affichés peuvent être dépassés jusqu'à atteindre l'objectif maximal. Celui-ci est fixé par le porteur de projet et présenté dans la page projet
                </p>
                <p>
                    Le Porteur de projet s'engage à accepter et à conserver tous les investissements et tous les dons réalisés sur la plateforme. 
                </p>
              </div>
            ),
        }
    },
    {
        key: '6',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Que se passe-t-il si l'objectif de financement d'une collecte n'est pas atteint ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Chaque porteur de projet s'engage à accepter tous les investissements ou dons réalisés pour soutenir un projet, même si l'objectif de financement n'est pas atteint.
                </p>
                <p>
                    Celui-ci complètera le financement de son projet via d'autres sources de financement.  
                </p>
              </div>
            ),
        }
    }
]

export const mecenatEntreprisePanel = [
    {
        key: '1',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quel montant minimum de mécénat d’entreprise ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Les entreprises peuvent participer aux projets via du mécénat défiscalisé dès le premier euro.
                </p>
              </div>
            ),
        }
    },
    {
        key: '2',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quels sont les frais pour les mécènes ?</span>)},
        content: {
            content: (
              <div>
                <p>
                    Il n'y a aucun frais pour les mécènes et la défiscalisation porte sur 100% du montant donné.
                </p>
                <p>
                    Pour assurer la connaissance client des entreprises mécènes, la gestion des flux de paiement et la gestion des reçus fiscaux, une commission est prélevée au porteur de projet.
                </p>
              </div>
            ),
        }
    },
    {
        key: '3',
        title: {content: (<span css={{fontSize:"1.2rem", color:"#085c74"}}>Quelle fiscalité pour le mécénat d'entreprise  ?</span>)},
        content: {
            content: (
              <div>
                <p>
                Les entreprises qui réalisent des dons en faveur de projet d'intérêt général ayant reçu un rescrit fiscal ouvrant droit à une défiscalisation peuvent réduire leur imposition de l'année du don.
                </p>
                <p>
                La réduction d'impôts est de 60% s'applique quel que soit le régime fiscal de la société : impôt sur les sociétés (IS) ou impôt sur les revenus (IR).
                </p>
                <p>
                Le montant des dons retenus pour le calcul de la réduction ne peut pas dépasser, sur un même exercice, le maximum entre 20 000€ et 0,5% du chiffre d'affaires annuel hors taxe de l'entreprise mécène.
                </p>
              </div>
            ),
        }
    }
]