import React from "react"
import Projet from "../../../../model/projet"
import { Icon } from "semantic-ui-react"

type Props = {
    project: Projet,
    onDownloadCritereDocument: () => void
}

export function CritereSelectionTab({project, onDownloadCritereDocument}: Props) {
    return (
        <>
            <div css={{
                  fontWeight:"bolder",
                  fontSize:"17px",
                  color:"#16586F",
                  marginBottom: "1em"
                }}
            >
                Critères de sélection
                </div>
                  <div css={{marginLeft:"5px",marginBottom:"15px"}}>
                    {project.CritereSelectionDon ? 
                      <div dangerouslySetInnerHTML={{__html: project.CritereSelectionDon!}}>
                      </div> :
                      <p css={{fontFamily:"Source Sans Pro" }}>
                        Cette partie est réservée à l'analyse crédit que Villyz effectue
                        avant la publication du projet aux citoyens. Elle vous sera
                        communiquée en amont.
                      </p>
                    }
                  </div>
                  {project.CritereSelectionDon ? 
                    <div css={{marginLeft:"50px",marginBottom:"15px", cursor: "pointer"}} onClick={() => onDownloadCritereDocument()}>
                       <Icon css={{color:"#A6A6A6"}} size='big' name='file pdf outline'/> 
                       <span css={{fontSize :"1,125 rem",fontFamily : "Source Sans Pro ",color :"#A6A6A6",fontWeight :"normal"}}>Critère de sélection</span>
                    </div> : <div></div>}
        </>
    )
}