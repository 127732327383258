import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { mangopayService } from "../../../services/mangopay-service"
import { ApplicationState } from "../../../store"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { PaginatedTable } from "../../generic/paginated-table/paginated-table"
import { TrueLayerSimpleHookMessage } from "../../../model/entities/truelayer-simple-hook-message"
import { TrueLayerEventHistory } from "../../../model/entities/truelayer-event-history"

type Props = {

}

export function TraitementTrueLayerDashboard({}: Props) {
    const[eventHistory, setEventHistory] = useState<TrueLayerEventHistory[]>([])

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(() => {
        mangopayService.getTruelayerTreatedEventsHistory(loginProps?.oauth)
        .then(response => {
            setEventHistory(response.History!)
        })
    }, [])

    return (
        <>
            <PaginatedTable
                elements={eventHistory}
                elementsPerPage={10}
                headers={
                    [
                        'Id',
                        'Date de réception', 
                        'Evènement reçu', 
                        'Id de la ressource TrueLayer',
                        'Statut de l\'opération',
                        'Message'
                    ]
                }
                columnSelector={(element: TrueLayerEventHistory) => {
                    return {
                        values: [
                            element.TrueLayerSimpleHookMessageId,
                            element.DateReceived,
                            element.Status,
                            element.PaymentId,
                            element.TreatmentStatusCode,
                            element.ErrorMessage
                        ]
                    }
                }}
            />
        </>
    )
}