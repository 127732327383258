import React, { useEffect, useState } from "react"
import { Button, Container } from "semantic-ui-react"
import { PaginatedTable } from "../../../generic/paginated-table/paginated-table"
import { HistoRetraitCitoyen } from "../../../../model/entities/histo-retrait-citoyen"
import { format } from "date-fns"
import { investmentService } from "../../../../services/investment-service"
import { useSelector } from "react-redux"
import { AuthenticationState } from "../../../../model/state/authentication-state"
import { ApplicationState } from "../../../../store"
import { RecentPayoutListResponse } from "../../../../model/dto/response"
import { NavLink } from "react-router-dom"
import { MangoPayEventReference } from "../../../../model/entities/mangopay-event-reference"
import { LoadingSpinner } from "../../../generic/loading-spinner"
import { trackPromise } from "react-promise-tracker"

type Props = {
    histoRetrait?: HistoRetraitCitoyen[]
}

export function RetraitPane({histoRetrait}: Props) {

    const [isLoading, setIsLoading] = useState(false)

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const mappingStatut = [
        {
            code: MangoPayEventReference.PAYOUT_NORMAL_CREATED,
            message: 'Initié'
        },
        {
            code: MangoPayEventReference.PAYOUT_NORMAL_FAILED,
            message: 'En erreur'
        },
        {
            code: MangoPayEventReference.PAYOUT_NORMAL_SUCCEEDED,
            message: 'Validé'
        }
    ]

    return (
        <Container>
            <h1 className="py-8 text-3xl">Mes retraits</h1>
            <LoadingSpinner height={100} />
            {!isLoading && histoRetrait?.length && (
                <PaginatedTable 
                    elements={histoRetrait}
                    elementsPerPage={5}
                    headers={
                        [
                            'Date demande',
                            'Date validation',
                            'Montant',
                            'Identifiant opération',
                            'Statut retrait'
                        ]
                    }
                    columnSelector={(element: HistoRetraitCitoyen) => {
                        return {
                            values: [
                                element.DateDemande ? format(new Date(element.DateDemande), "dd/MM/yyyy") : '',
                                element.DateValidation ? format(new Date(element.DateValidation), "dd/MM/yyyy") : '',
                                element.Montant ? element.Montant / 100 + '€' : '',
                                element.MangoPayLongPayoutId,
                                mappingStatut.find(s => s.code === element.MangoPayStatus)?.message
                            ]
                        }
                    }}
                />
            ) || (
                <div className="text-center p-8 text-xl">
                    <span className="font-bold">
                        Vous n'avez encore effectué aucun retrait
                    </span>
                    <p>
                        Effectuez vos retraits lorsque vous recevez vos remboursements et consultez leur évolution ici
                    </p>
                </div>
            )}
            <div className="text-center py-8">
                <Button as={NavLink} to="/projets" size="big" primary>
                    Découvrir les projets
                </Button>
            </div>
        </Container>
        
    )
}