import React, { useState } from "react";
import { connect } from "react-redux";
import { Card, Container, Form, Grid } from "semantic-ui-react";
import { Profil } from "../../../model/entities/profil";
import { userService } from "../../../services/authentication-redux-service";
import { ApplicationState } from "../../../store";
import { LOGIN_FAILURE, LOGIN_SUCCESSFUL, LOGOUT, REQUEST_IDENTITY, REQUEST_LOGIN } from "../../../store/authentication/types";
import { OAuthResponse } from "../../../model/authentication/oauth-response";
import { UserInfoResponse } from "../../../model/dto/response";
import { InputField } from "../../generic/input-field";
import { useNavigate } from "react-router-dom";
import './LoginV2.css'

type FieldData = {
  value?: any,
  name?: string,
  isValid?: boolean
};

type FormData = {
  username: FieldData,
  password: FieldData,
}

function LoginV2({ dispatchProps }: any) {

  const [loginError, setLoginError] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()

  const [form, setForm] = useState<FormData>({
    username : { name : "username", value : "", isValid: true},
    password : { name : "password", value : "", isValid: true}
  })

  function onLoginSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const data = new FormData(e.target as HTMLFormElement);
    const formData = Object.fromEntries(data) as { [key: string]: string };
    loginUser(formData.username.trim(), formData.password, false, true);
  }

  function loginUser(username: string, password: string, verifyIdentityStep = false, directLogin = false) {
    userService.login2(username, password)
      .then((response) => {
        if (response.status !== 200) {
          dispatchProps.loginFailure?.();
          throw new Error();
        }
        return response.json();
      })
      .then((user: OAuthResponse) => {
        localStorage.setItem("oauth_user", JSON.stringify(user));
        if (user.error) {
          dispatchProps.loginFailure?.();
          throw new Error();
        }
        dispatchProps.loginSuccessful?.(user);
        if(!verifyIdentityStep) {
          userService
          .getUserInfo(user.userId, user)
          .then((response) => {
            localStorage.setItem("user_info", JSON.stringify(response));
            dispatchProps.requestIdentity?.(response);
            if(directLogin) {
              if(user.profilCode === Profil.INVESTISSEUR) {
                window.location.href = "/dashboard/citoyen"
              }
              else if (user.profilCode === Profil.PORTEUR_PROJET) {
                window.location.href = "dashboard/collectivite"
              }
              else if (user.profilCode === Profil.VILLYZ_USER) {
                window.location.href = "dashboard/admin"
              }
            }
          });
        }
        else {
          if(directLogin) {
            if(user.profilCode === Profil.INVESTISSEUR) {
              window.location.href = "/dashboard/citoyen"
            }
            else if (user.profilCode === Profil.PORTEUR_PROJET) {
              window.location.href = "dashboard/collectivite"
            }
            else if (user.profilCode === Profil.VILLYZ_USER) {
              window.location.href = "dashboard/admin"
            }
          }
        }
      })
      .catch(() =>
        setLoginError(
          "Impossible de se connecter. Vérifiez votre adresse mail et mot de passe"
        )
    );
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fieldValue: string = e.target.value;
    const fieldName = e.target.name;
    const newField: FieldData = {[fieldName!]: { value: fieldValue, name: fieldName, isValid: true }};
    setForm({ ...form,  ...newField});
  }

  const hideShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="pt-12" style={{fontFamily: "raleway"}}>
      <Container>
        <Grid stackable columns={2}>
          <Grid.Column>
            <div style={{padding: "10px 55px"}}>
              <h1 style={{color: "#085c74", marginBottom: "15px !important", fontFamily:"merriweather", fontWeight: 400, fontSize: '38px'}}>Connexion</h1>
              <Card fluid>
                <Card.Content>
                  <Form onSubmit={onLoginSubmit}>
                    <Form.Field>
                      <InputField 
                        data={form.username}
                        type="email"
                        label="Adresse mail"
                        required={true}  
                        autocomplete="email"
                        onChange={handleInputChange}               
                      />
                    </Form.Field>
                    <Form.Field>
                      <InputField 
                        data={form.password}
                        label="Mot de passe"
                        required={true}  
                        autocomplete="current-password"
                        onChange={handleInputChange} 
                        type={showPassword ? "text" : "password"}   
                        icon={ 
                          <div onClick={hideShowPassword}>
                            {showPassword ? (
                              <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10 11.5C9.10998 11.5 8.23995 11.2361 7.49993 10.7416C6.75991 10.2471 6.18314 9.54434 5.84254 8.72208C5.50195 7.89981 5.41283 6.99501 5.58647 6.12209C5.7601 5.24918 6.18868 4.44736 6.81802 3.81802C7.44736 3.18868 8.24918 2.7601 9.12209 2.58647C9.99501 2.41283 10.8998 2.50195 11.7221 2.84254C12.5443 3.18314 13.2471 3.75991 13.7416 4.49993C14.2361 5.23996 14.5 6.10998 14.5 7C14.5 8.19348 14.0259 9.33807 13.182 10.182C12.3381 11.0259 11.1935 11.5 10 11.5ZM10 0C3 0 0 7 0 7C0 7 3 14 10 14C17 14 20 7 20 7C20 7 17 0 10 0Z" fill="#979797"/>
                              </svg>
                            ) : (
                              <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.24891 0.280712C3.06031 0.0985537 2.80771 -0.00224062 2.54551 3.78026e-05C2.28331 0.00231622 2.0325 0.107485 1.84709 0.292893C1.66168 0.478301 1.55652 0.729114 1.55424 0.991311C1.55196 1.25351 1.65275 1.50611 1.83491 1.69471L15.8349 15.6947C16.0235 15.8769 16.2761 15.9777 16.5383 15.9754C16.8005 15.9731 17.0513 15.8679 17.2367 15.6825C17.4221 15.4971 17.5273 15.2463 17.5296 14.9841C17.5319 14.7219 17.4311 14.4693 17.2489 14.2807L15.7759 12.8077C17.3334 11.5658 18.4852 9.88764 19.0839 7.98771C17.8099 3.93071 14.0199 0.987712 9.54191 0.987712C7.97434 0.985586 6.42841 1.35357 5.02991 2.06171L3.24891 0.280712ZM7.50991 4.54071L9.02391 6.05571C9.363 5.96566 9.7198 5.96626 10.0586 6.05744C10.3974 6.14862 10.7063 6.32719 10.9543 6.57528C11.2024 6.82336 11.381 7.13226 11.4722 7.47104C11.5634 7.80983 11.564 8.16662 11.4739 8.50571L12.9879 10.0197C13.4389 9.25573 13.6231 8.36351 13.5116 7.48339C13.4 6.60327 12.9991 5.78521 12.3717 5.15789C11.7444 4.53057 10.9264 4.12961 10.0462 4.01806C9.16612 3.90652 8.27389 4.08973 7.50991 4.54071Z" fill="#979797"/>
                              <path d="M11.996 14.6849L9.292 11.9799C8.31969 11.919 7.40303 11.5053 6.71406 10.8165C6.02509 10.1278 5.61117 9.21122 5.55 8.23892L1.877 4.56592C1.03221 5.57193 0.394349 6.73483 0 7.98792C1.274 12.0449 5.065 14.9879 9.542 14.9879C10.389 14.9879 11.211 14.8829 11.996 14.6849Z" fill="#979797"/>
                              </svg>
                            )}
                          </div>
                        }                   
                      />
                    </Form.Field>
                    <div 
                      style={{
                        cursor: 'pointer', 
                        textDecoration: 'underline', 
                        textAlign: 'right',
                        marginBottom: '16px',
                        marginTop: '-8px',
                        color: '#000000'
                      }}
                      onClick={() => { navigate("/forgotPassword")}}
                    >
                      Mot de passe oublié ?
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <button 
                        type="submit" 
                        className="btn btn-primary"
                        style={{
                            marginRight: "5px",
                            height : "35px", 
                            marginBottom:"2px"
                        }}
                      >
                        Se connecter
                      </button>
                    </div>
                    <div 
                      style={{
                        cursor: 'pointer', 
                        textDecoration: 'underline', 
                        textAlign: 'center', 
                        marginTop:'8px',
                        fontSize: '16px',
                        color: '#000'
                      }}
                      onClick={() => { navigate("/inscription")}}
                    >
                      S’inscrire
                    </div>
                  </Form>
                  {loginError && <p className="text-red-600">{loginError}</p>}
                </Card.Content>
              </Card>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div style={{padding: "12px 55px", color: '#000'}}>
              <h1 style={{fontWeight: 700, fontSize: '38px', fontFamily:"merriweather"}}>On est ravi de vous revoir</h1>
              <p style={{fontSize: '18px'}}>Ensemble, continuons à construire nos territoires et avoir un impact sur l’avenir.</p>
            </div>
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
        </Grid>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatchProps: {
    loginRequest: () => dispatch({ type: REQUEST_LOGIN }),
    loginSuccessful: (authResponse: OAuthResponse) =>
      dispatch({ type: LOGIN_SUCCESSFUL, authResponse: authResponse }),
    loginFailure: () => dispatch({ type: LOGIN_FAILURE }),
    logOut: () => dispatch({ type: LOGOUT }),
    requestIdentity: (userInfo: UserInfoResponse) =>
      dispatch({ type: REQUEST_IDENTITY, user: userInfo }),
  }
});

const ConnectedLogin = connect(mapStateToProps, mapDispatchToProps)(LoginV2);
export { ConnectedLogin as LoginV2 };

