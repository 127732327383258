import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button, Container, Icon } from "semantic-ui-react"
import { userService } from "../../services/authentication-redux-service"
import { ApplicationState } from "../../store"
import { LOGOUT } from "../../store/authentication/types"

type Props = {
    dispatchProps: any
}

function Disconnected({dispatchProps}: Props) {
    const navigate = useNavigate();

    useEffect(() => {
        const aEle = document.createElement('button');     // Create a label
        aEle.onclick = onEleClick;
        document.body.appendChild(aEle);
        aEle.click();     // Click to download
        document.body.removeChild(aEle);
    }, [])

    const onEleClick = () => {
        setTimeout(logout, 100)
        
    }

    const logout = () => {
        userService.logout();
        if (dispatchProps.logOut) {
            dispatchProps.logOut();
        }
    }
    
    return (
        <Container css={{
            marginTop: '80px'
        }}> 
            <h1>
                Déconnecté
            </h1>
            <div css={{
                marginTop: '30px',
                textAlign: 'center'
            }}>
                <Icon
                    name="exclamation triangle"
                    color="purple"
                    size="massive"
                />
                <div css={{
                    fontSize: '15px',
                    display: 'inline-block'
                }}>
                    Vous avez été déconnecté, veuillez vous reconnecter pour continuer vos actions.
                </div>
            </div>
            <div css={{
                marginTop: '20px',
                textAlign: 'center'
            }}>
                <div className="text-center py-8">
                   <Button color="blue" size="big" onClick={() => navigate(`/login`)}>
                        Se reconnecter
                    </Button>
                </div>
            </div>
        </Container>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication,
  });

const mapDispatchToProps = (dispatch: any) => ({
    dispatchProps: {
      logOut: () => dispatch({ type: LOGOUT })
    }
  });

  const ConnectedDisconnected = connect(mapStateToProps, mapDispatchToProps)(Disconnected);
  export { ConnectedDisconnected as Disconnected }
