export const documentService = {
    askForDocument
}

function askForDocument(): Promise<Response> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };
  
    return fetch(
      `${process.env.REACT_APP_BASE_URL}/api/document/send`,
      requestOptions
    );
  }