import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Card, Container, Form } from "semantic-ui-react";

export function ResetPassword() {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  var getParams = new URLSearchParams(location.search);

  const showWelcome = getParams.get("welcome") === "true";

  let newPasswordLabel = "Nouveau mot de passe";
  let confirmNewPasswordLabel = "Confirmer le nouveau mot de passe";

  if(showWelcome){
    newPasswordLabel = "Définissez votre mot de passe";
    confirmNewPasswordLabel = "Confirmez votre mot de passe";
  }

  async function onResetPasswordSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const data = new FormData(e.target as HTMLFormElement);

    await fetch(`${process.env.REACT_APP_BASE_URL}/public/user/resetQueryWithNewPassword`, {
      method: "POST",
      body: JSON.stringify({ ...Object.fromEntries(data), token }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    navigate("/login");
  }

  return (
    <div className="pt-12">
      <Container>
        <Card fluid>
          <Card.Content>
            <Form onSubmit={onResetPasswordSubmit}>
              <Form.Field>
                <label>{newPasswordLabel}</label>
                <input
                  required
                  type="password"
                  name="newPassword"
                  className="form-group"
                />
              </Form.Field>
              <Form.Field>
                <label>{confirmNewPasswordLabel}</label>
                <input
                  required
                  type="password"
                  name="newPasswordConfirmation"
                  className="form-group"
                />
              </Form.Field>
              <div className="card-action center">
                <button className="btn btn-primary" type="submit">
                  Valider
                </button>
              </div>
            </Form>
          </Card.Content>
        </Card>
      </Container>
    </div>
  );
}
