import React from "react"
import { useNavigate } from "react-router-dom"

interface ButtonLinkProps {
    to: string,
    label: string
}

export function ButtonLink(props: ButtonLinkProps) {
    const navigate = useNavigate();

    const onClickButton = (e: React.MouseEvent) => {
        navigate(props.to);
    }

    return (
        <div className="card-action center">
            <button className="btn btn-primary" onClick={onClickButton}>{props.label}</button>
        </div>
    )
}