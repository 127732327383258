import React, { useState } from "react";
import { projetService } from "../../services/projet-service";
import { ApplicationState } from "../../store";
import { AuthenticationState } from "../../model/state/authentication-state";
import { connect } from "react-redux";
import {
  ImageToUpload,
  ImageUpload,
} from "../generic/image-upload/image-upload";

type Props = {
  projetId: number;
  loginProps?: AuthenticationState;
  closeModal?: () => void;
  onUploadSuccess?: (fileUrl: string) => void;
};

function FileUploadForm({
  projetId,
  loginProps,
  closeModal,
  onUploadSuccess,
}: Props) {
  async function uploadImage({ name, type, dataUrl }: ImageToUpload) {
    try {
      const res: Response = await fetch(dataUrl);
      const blob: Blob = await res.blob();
      const file = new File([blob], name, { type });

      const result = await projetService.uploadPicture(
        projetId,
        file,
        loginProps?.oauth
      );
      
      if (!result.IsTraitementOk) {
        throw new Error("Erreur de traitement.");
      }

      if (closeModal) {
        closeModal();
      }
      if (onUploadSuccess) {
        onUploadSuccess(dataUrl);
      }
    } catch (err) {
      console.log("Erreur lors de l'upload.", err);
    }
  }

  return (
    <div css={{ paddingBottom: "30px" }}>
      <h1>Ajouter une photo</h1>
      <ImageUpload
        onFileUpload={uploadImage}
        aspect={794 / 480}
        minimumResolution={500}
      />
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedFileUploadForm = connect(mapStateToProps, null)(FileUploadForm);
export { ConnectedFileUploadForm as FileUploadForm };
