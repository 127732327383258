import React, { useEffect, useState } from "react"
import Projet from "../../../model/projet"
import { ApplicationState } from "../../../store"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { connect } from "react-redux"
import { Form } from "semantic-ui-react"
import { InputField } from "../../generic/input-field"
import { TypeFinancement } from "../../../model/entities/type-financement"
import { referenceService } from "../../../services/reference-service"
import { TypeFinancementListResponse, WorkflowProcessResultResponse } from "../../../model/dto/response"
import { projetService } from "../../../services/projet-service"
import { SubmitButton } from "../../generic/submit-button"
import { FormErrorHeader } from "../../generic/form-error-header"
import { SimpleButton } from "../../generic/simple-button"
import { ProjetFinancementGlobalBody } from "../../../model/dto/body/projet-financement-global-body"
import { WorkflowType } from "../../../model/entities/workflow-type"
import { FlowStep } from "../../../model/entities/flow-step"

type Props = {
    loginProps?: AuthenticationState,
    projet?: Projet,
    isCreating?: boolean,
    workflowTypeCode?: string,
    onSubmitSuccess: (response: Response) => void
    onSkipStep?: (response: WorkflowProcessResultResponse) => void;
}

type FieldData = {
    name?: string,
    value?: any,
    isValid?: boolean
}

type Form = {
    montantAutofinancement: FieldData,
    montantSubventions: FieldData,
    montantCreditExistant: FieldData,
    montantFinancementAutre: FieldData
}

function FinancementGlobalForm({loginProps, projet, isCreating, workflowTypeCode, onSubmitSuccess, onSkipStep}: Props) {
    const [form, setForm] = useState<Form>({
        montantAutofinancement: {value: projet ? projet.MontantAutofinancement : '', name: 'montantAutofinancement', isValid: true},
        montantSubventions: {value: projet ? projet.MontantFinancementSubvention : '', name: 'montantSubventions', isValid: true},
        montantCreditExistant: {value: projet ? projet.MontantFinancementBancaire : '', name: 'montantCreditExistant', isValid: true},
        montantFinancementAutre: {value: projet ? projet.MontantFinancementAutre : '', name: 'montantFinancementAutre', isValid: true}
    })

    const [isFormError, setIsFormError] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const [typeFinancementList, setTypeFinancementList] = useState<TypeFinancement[]>([])

    useEffect(() => {
        referenceService.getAllTypeFinancement(loginProps?.oauth)
        .then(response => {
            return response.json() as Promise<TypeFinancementListResponse>
        })
        .then(response => {
            if(response.IsTraitementOk) {
                setTypeFinancementList(response.TypeFinancementList!)
            }
        })
    }, [])

    const validateMandatory = (value: any): boolean => {
        return value ===  0 || (value !== "" && value !== null && value !== undefined)
    }

    const validateMontant = (value: any): boolean => {
        const regex = /^[0-9]/g;
        return validateMandatory(value) && regex.test(value)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
      
        setForm({ ...form,  ...newField});
        setIsFormError(false)
    }

    const validateFinancementAutre = (value : any): boolean => {
        const regex = /^[0-9]/g;
        return  !value || value == "" || regex.test(value)
    }

    const isFormValid = (): boolean => {
        return  validateMontant(form.montantAutofinancement.value)
            && validateMontant(form.montantSubventions.value)
            && validateMontant(form.montantCreditExistant.value)
            && validateFinancementAutre(form.montantFinancementAutre.value)
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
    }

    const onSubmitError = (): void => {

    }

    const onPreSubmit = (): void => {
        setIsFormSubmitted(true)
    }

    const body: ProjetFinancementGlobalBody = {
        MontantAutofinancement: form.montantAutofinancement.value,
        MontantCreditBancaire: form.montantCreditExistant.value,
        MontantSubvention: form.montantSubventions.value,
        MontantAutreFinancement: form.montantFinancementAutre.value,
        WorkflowTypeCode: workflowTypeCode
    }

    const nextFincementGlobalStepKey = () => {
        const body = {
            WorkflowTypeCode : workflowTypeCode ?? '',
            CurrentFlowStep : FlowStep.FINANCEMENT_GLOBAL,
            RessourceId:  projet?.Id!,
            IsSkiped: true
        }
        return referenceService.getNextStep(body).then(response => {
            onSkipStep && onSkipStep(response)})
    }

    return(
        <Form>
            <FormErrorHeader 
                message="Veuillez corriger les champs en erreur"
                displayed={isFormError}
            />
            <Form.Group>
                <Form.Field width={8}>
                    <InputField 
                        data={form.montantAutofinancement}
                        submitted={isFormSubmitted}
                        label="Montant d'autofinancement"
                        error="Merci de renseigner le montant d'autofinancement sans espace ou 0 s'il n'y en a pas"
                        onChange={(e) => handleInputChange(e, form.montantAutofinancement.name)}
                        validateField={validateMontant}
                    />
                </Form.Field>
                <Form.Field width={8}>
                    <InputField 
                        data={form.montantSubventions}
                        submitted={isFormSubmitted}
                        label="Montant financé par des subventions"
                        error="Merci de renseigner le montant de subventions sans espace ou 0 s'il n'y en a pas"
                        onChange={(e) => handleInputChange(e, form.montantSubventions.name)}
                        validateField={validateMontant}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={8}>
                    <InputField 
                        data={form.montantCreditExistant}
                        submitted={isFormSubmitted}
                        label="Montant financé par un crédit bancaire existant"
                        error="Merci de renseigner le montant financé par un crédit bancaire sans espace ou 0 s'il n'y en a pas"
                        onChange={(e) => handleInputChange(e, form.montantCreditExistant.name)}
                        validateField={validateMontant}
                    />
                </Form.Field> 
                <Form.Field width={8}>
                    <InputField 
                        data={form.montantFinancementAutre}
                        label="Autre financement"
                        onChange={(e) => handleInputChange(e, form.montantFinancementAutre.name)}
                        validateField = {validateFinancementAutre}
                        error="Merci de renseigner un nombre sans espace ou de laisser le champs vide"
                    />
                </Form.Field>
            </Form.Group>
            <div css={{
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }} 
                className="button-bar">
                <SubmitButton 
                    data={body}
                    label="Valider"
                    action={() => projetService.saveFinancementGlobalProjet(body, projet?.Id, loginProps?.oauth)}
                    onActionSuccess={onSubmitSuccess}
                    onActionFailure={onSubmitError}
                    onFormNotValid={onFormNotValid}
                    validateForm={isFormValid}
                    onPreSubmit={onPreSubmit}
                />
                {isCreating && (
                    <SimpleButton 
                        label="Passer cette étape"
                        onClick={() => nextFincementGlobalStepKey()}
                    />
                )}
            </div>
        </Form>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedFinancementGlobalForm = connect(mapStateToProps, null)(FinancementGlobalForm);
export { ConnectedFinancementGlobalForm as FinancementGlobalForm };