import React, { useRef, useState } from "react";
import { Icon } from 'semantic-ui-react'

type Props = {
  onFileAdded: (file: File) => void;
  isDisabled?: boolean;
};

export function Dropzone({ onFileAdded, isDisabled = false }: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [fileUploaded, setFileUploaded] = useState<File | undefined>(undefined);

  function openFileDialog() {
    if (isDisabled || !fileInputRef.current) return;
    fileInputRef.current.click();
  }

  function onDrop(evt: React.DragEvent) {
    evt.preventDefault();
    if (isDisabled) return;

    const file = evt.dataTransfer.files[0];

    // Fichier uploadé par Drag&Drop
    onFileAdded(file);
    setFileUploaded(file);
  }

  function onDragOver(evt: React.DragEvent) {
    evt.preventDefault();

    if (isDisabled) return;
    setIsHighlighted(true);
  }

  function onDragLeave() {
    setIsHighlighted(false);
  }

  function onUpload(evt: React.ChangeEvent<HTMLInputElement>) {
    if (evt.target.files && evt.target.files.length > 0) {
      const file = evt.target.files[0];

      // Fichier uploadé avec l'explorateur
      onFileAdded(file);
      setIsHighlighted(true);
      setFileUploaded(file);
    }
  }

  return (
    <div
      onClick={openFileDialog}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      css={{
        cursor: isDisabled ? "default" : "pointer",
        height: "200px",
        width: "100%",
        border: "2px dashed",
        borderRadius: "20px",
        borderColor: "#a7a7a7",
        ...(isHighlighted && { borderColor: "green", color: "green" }),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <input
        ref={fileInputRef}
        css={{ display: "none" }}
        type="file"
        accept="image/*"
        onChange={onUpload}
      />
      <div css={{ textAlign: "center" }}>
        {
          !fileUploaded ?
          <> 
            <p css={{ fontSize: "large", fontWeight: "bold" }}>
              Uploadez une image
            </p>
            <p>Glissez-déposez ou cliquez pour sélectionner</p>
            <Icon size="huge" name='upload' />
          </>
          :
          <>
            <p css={{ fontSize: "large", fontWeight: "bold" }}>
              Le fichier {fileUploaded?.name} a été importé avec succès
            </p>
            <Icon size="huge" name='check' />
          </>
        }
      </div>
    </div>
  );
}
