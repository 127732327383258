import React, { useEffect, useMemo, useState } from "react"
import { Grid, Icon, Segment } from "semantic-ui-react"
import { CarteBancairePane } from "./carte-bancaire-pane"
import { BankProvider } from "../../model/entities/bank-provider"
import { referenceService } from "../../services/reference-service"
import { AdminParams } from "../../model/entities/admin-params"
import { AdminParamValueResponse, InvestResponse, UserInfoResponse } from "../../model/dto/response"
import { AuthenticationState } from "../../model/state/authentication-state"
import { ApplicationState } from "../../store"
import { useSelector } from "react-redux"
import { SwitchVirementStep } from "./switch-virement-step"
import { WalletPaiementPane } from "./wallet-paiement-pane"
import { VirementClassiquePane } from "./virement-classique-pane"

interface Props {
    montant?: number | string,
    userInfo?: UserInfoResponse,
    projetId?: number,
    errorMessage?: string,
    isErrorDisplayed?: boolean,
    montantDisponibleVillyz?: number,
    isResumeVirement?: boolean,
    showAttentePaiement: boolean;
    hasUserInvestimentsByVirementEnCours: boolean;
    isDon?: boolean;
    onSelectBranch?: (provider: BankProvider) => void,
    onClickAttentePaiement?: () => void;
    onSubmitImpossible?: (message: string) => void,
    onVirementSuccess?: (response: InvestResponse) => void;
    disabledAttentePaiement: () => void;
}

enum MoyenPaiement {
    Virement,
    Carte_Bancaire
}

export function MoyenPaiementPane({
    montant,
    projetId,
    errorMessage,
    isErrorDisplayed,
    montantDisponibleVillyz,
    showAttentePaiement,
    hasUserInvestimentsByVirementEnCours,
    isDon,
    onSelectBranch,
    onClickAttentePaiement,
    onSubmitImpossible,
    onVirementSuccess,
    disabledAttentePaiement
}: Props) {


    const borderSelected = "1px solid #30BF71"
    const borderUnselected = "1px solid #ccc"

    const [selectedMoyenPaiement, setSelectedMoyenPaiement] = useState<MoyenPaiement>()
    const [isTrueLayerActive, setIsTrueLayerActive] = useState<boolean>(false);
    const [isVirementRecommanded, setVirementRecommanded] = useState<boolean>(true);

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    const convertMontantInStringToNumber = (montant: string | number | undefined) => {
        if(typeof(montant) === "string"){
            return parseInt(montant)
        }
        else if(typeof(montant) === "undefined"){
            return 500
        }
        return montant 
    }   

    const TrueLayerLimit: number = 75

    useEffect(()=>{
        let montantCBEnAvant : string | undefined
        referenceService.getAdminParam(AdminParams.MONTANT_CB_EN_AVANT).then((response) => {
            montantCBEnAvant = response.AdminParamValue?.Value
            referenceService.getAdminParam(AdminParams.IS_TRUELAYER_ACTIVE)
            .then((response: AdminParamValueResponse)=>{
                let adminParam: AdminParams | undefined = response.AdminParamValue;
                const isTrueLayerActive = adminParam?.Value === "true" ?? false
                if(isTrueLayerActive) {
                    if(montant && +montant >= TrueLayerLimit && !loginProps.user?.Etablissement){
                        setSelectedMoyenPaiement(MoyenPaiement.Virement)
                        setVirementRecommanded(true)
                    }
                    else{
                        setSelectedMoyenPaiement(MoyenPaiement.Carte_Bancaire)
                        setVirementRecommanded(false)
                    }
                }
                else{
                    if(montant && +montant >= convertMontantInStringToNumber(montantCBEnAvant) && !loginProps.user?.Etablissement){
                        setSelectedMoyenPaiement(MoyenPaiement.Virement)
                        setVirementRecommanded(true)
                    }
                    else{
                        setSelectedMoyenPaiement(MoyenPaiement.Carte_Bancaire)
                        setVirementRecommanded(false)
                    }
                }
                setIsTrueLayerActive(isTrueLayerActive)
            })
        })
    }, [])

    const isMontantWalletPositif = useMemo(() => {
        if(!isDon && montantDisponibleVillyz) {
            if(typeof(montant) === "string"){
                return parseInt(montant!) <= montantDisponibleVillyz
            }
            return montant! <= montantDisponibleVillyz
        }
        return false
    }, [montant, montantDisponibleVillyz, isDon])

    const calculateMontantWhenMontantWallet = (montantDemande: number | string | undefined) => {
        if(!montantDemande){
            return '0'
        }
        else{
            if(loginProps.user?.Etablissement !== null){
                return montantDemande.toString()
            } else {
                if(typeof(montantDemande) === "string")(
                    montantDemande = parseInt(montantDemande)
                )
                if(montantDisponibleVillyz && montantDisponibleVillyz > 0){
                    return (montantDemande - montantDisponibleVillyz).toString()
                }
                return montantDemande.toString()
            }
        } 
    }

    return(
        <Grid stackable stretched padded>
            {!isMontantWalletPositif &&
                <Grid.Row css={{paddingTop: "0rem !important"}}>
                    <Grid.Column width={8} css={{
                        paddingLeft: "0.1rem !important",
                        paddingRight: "0.1rem !important"
                    }}>
                        <div
                            css={{
                                padding:"5px",
                                background: "#fff",
                                border: selectedMoyenPaiement === MoyenPaiement.Virement ? borderSelected : borderUnselected,
                                borderRadius: "3px",
                                borderWidth: selectedMoyenPaiement === MoyenPaiement.Virement ? "2px" : "1px",
                                cursor: "pointer",
                                display:"flex",
                                flexDirection:"row",
                                alignItems:"center",
                                position:"relative"
                            }}
                            onClick={() => {
                                disabledAttentePaiement()
                                setSelectedMoyenPaiement(MoyenPaiement.Virement)
                            }}
                        >
                            {isTrueLayerActive && isVirementRecommanded && 
                                <div
                                    css={{
                                        backgroundColor: selectedMoyenPaiement === MoyenPaiement.Virement ? "#30BF71" : "#ccc",
                                        height:"15px",
                                        width:"80px",
                                        paddingBottom:"5px",
                                        position:"absolute",
                                        textAlign:"center",
                                        fontSize:"10px",
                                        fontWeight:"bold",
                                        color:"#fff",
                                        borderRadius:"10px",
                                        top:"-8px",
                                        right:"5px"
                                    }} 
                                >
                                    <p>Recommandé</p>
                                </div>
                            }
                            <div 
                                css={{margin:"0 15px"}}
                            >
                                <Icon 
                                    name="university" 
                                    size="big" 
                                    css={{
                                        color: selectedMoyenPaiement === MoyenPaiement.Virement ? "#30BF71" : "#ccc"
                                    }}
                                />
                            </div>
                            <div>
                                <h4>Payer par virement</h4>
                                <p 
                                    css={{
                                        marginTop:"-10px", 
                                        color:"grey"
                                    }}
                                >
                                    rapide, simple et sécurisé
                                </p>
                            </div>
                        </div>
                    </Grid.Column>
                  
                    <Grid.Column width={8} css={{
                        paddingLeft: "0.1rem !important",
                        paddingRight: "0.1rem !important"
                    }}>
                        <div
                            css={{
                                padding:"5px",
                                background: "#fff",
                                border: selectedMoyenPaiement === MoyenPaiement.Carte_Bancaire ? borderSelected : borderUnselected ,
                                borderRadius: "3px",
                                borderWidth: selectedMoyenPaiement === MoyenPaiement.Carte_Bancaire ? "2px" : "1px",
                                cursor: "pointer",
                                display:"flex",
                                flexDirection:"row",
                                alignItems:"center"
                            }}
                            onClick={() => {
                                disabledAttentePaiement()
                                setSelectedMoyenPaiement(MoyenPaiement.Carte_Bancaire)
                            }}
                        >
                            <div 
                                css={{margin:"0 15px"}}
                            >
                                <Icon 
                                    name="credit card outline" 
                                    size="big" 
                                    css={{
                                        color: selectedMoyenPaiement === MoyenPaiement.Carte_Bancaire && loginProps.user?.Etablissement ? "#30BF71" : "#ccc"
                                    }}
                                />
                            </div>
                            <div>
                                <h4>Payer par carte bancaire</h4>
                                <p 
                                    css={{
                                        marginTop:"-10px", 
                                        color:"grey"
                                    }}
                                >
                                    visa, mastercard
                                </p>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                }
                <Grid.Row css={{paddingTop: "0rem !important"}}>
                    <Grid.Column width={16} css={{
                        paddingLeft: "0.1rem !important",
                        paddingRight: "0.1rem !important"
                    }}>
                        <Segment css={{borderRadius: "3px"}}>
                            {isMontantWalletPositif ?
                                <WalletPaiementPane 
                                    montant={montant}
                                    projetId={projetId}
                                />
                                :
                                (
                                    <>
                                        {selectedMoyenPaiement === MoyenPaiement.Virement && (
                                            (
                                                isTrueLayerActive ? 
                                                    <>
                                                        <SwitchVirementStep 
                                                            montant={montant}
                                                            projetId={projetId}
                                                            errorMessage={errorMessage}
                                                            isErrorDisplayed={isErrorDisplayed}
                                                            onSelectBranch={onSelectBranch}
                                                            onSubmitImpossible={onSubmitImpossible}
                                                            showAttentePaiement={showAttentePaiement}
                                                            onClickAttentePaiement={onClickAttentePaiement}
                                                            hasUserInvestimentsByVirementEnCours={hasUserInvestimentsByVirementEnCours}
                                                            isDon={isDon}
                                                        />
                                                    </>
                                                :
                                                <VirementClassiquePane 
                                                    montant={montant?.toString()} 
                                                    projetId={projetId}
                                                    onSubmitImpossible={onSubmitImpossible}
                                                    onClickAttentePaiement={onClickAttentePaiement}
                                                    showAttentePaiement={showAttentePaiement}
                                                    montantAVirer={calculateMontantWhenMontantWallet(montant)}
                                                    onVirementSuccess={onVirementSuccess}
                                                    hasUserInvestimentsByVirementEnCours={hasUserInvestimentsByVirementEnCours}
                                                    isDon={isDon}
                                                />
                                            )
                                        ) || (
                                            <CarteBancairePane 
                                                montant={montant}
                                                projetId={projetId}
                                                errorMessage={errorMessage}
                                                isErrorDisplayed={isErrorDisplayed}
                                                onSubmitImpossible={onSubmitImpossible}
                                                onClickAttentePaiement={onClickAttentePaiement}
                                                showAttentePaiement={showAttentePaiement}
                                                montantDisponibleVillyz={montantDisponibleVillyz}
                                                isDon={isDon}
                                            />
                                        )}
                                    </>
                                )
                            }
                        </Segment>

                    </Grid.Column>
                </Grid.Row>
        </Grid>
    )
}