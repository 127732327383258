import React, { useEffect, useState } from "react";
import { Container, Tab, TabProps } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { InvestissementHistoryResponse, InvestissementHistoryResponseListResponse } from "../../../model/dto/response";
import { investmentService } from "../../../services/investment-service";
import { InvestissementsPane } from "./tab/investissements-pane";
import { EcheancesPane } from "./tab/echeances-pane";
import { trackPromise } from "react-promise-tracker";
import { Profil } from "../../../model/entities/profil";
import { DonPane } from "./tab/don-pane";

type Props = {}

export function DashboardCollectivite({}: Props) {

  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  const [reload, setReload] = useState(true)
  const [histoCollecte, setHistoCollecte] = useState<InvestissementHistoryResponse[]>()
  const [index, setIndex] = useState<number>(loginProps?.user?.Etablissement?.Id !== undefined && loginProps?.user?.Etablissement?.Id.toString() !== "" ? 1 : 0);
  const [isDonTabClicked, setIsDonTabClicked] = useState(loginProps?.user?.Etablissement?.Id !== undefined && loginProps?.user?.Etablissement?.Id.toString() !== "" ? true : false);

  useEffect(() => {
    if(reload) {
      if(loginProps?.oauth?.profilCode === Profil.PORTEUR_PROJET) {
        trackPromise(investmentService.getInvestmentCollectiviteHistory(loginProps?.oauth?.etablissementId, loginProps?.oauth, isDonTabClicked)
        .then((container: InvestissementHistoryResponseListResponse) => {
          setHistoCollecte(container.InvestissementHistoryResponseList!)
        }));
      }
      setReload(false)
    }
  }, [reload]);

  const handleTabChange = (e: any, data: TabProps) => {
    const newIndex = data.activeIndex as number;
    setIndex(newIndex);
    setIsDonTabClicked(newIndex === 1);
    setReload(true);
  };

  const panes = [
    {
      menuItem: 'Mes projets emprunt',
      render: () => {
        return (
          <Tab.Pane attached={false} css={{background: 'transparent !important', border: 'none !important'}}>
            <InvestissementsPane 
              histoInvestissement={histoCollecte}
            />
          </Tab.Pane>
        )
      },
    },
    {
      menuItem: 'Mes projets don',
      render: () => {
        return (
          <Tab.Pane attached={false} css={{background: 'transparent !important', border: 'none !important'}}>
            <DonPane
              userDonHistory={histoCollecte}
            />
          </Tab.Pane>
        )
      },
    },
    {
      menuItem: 'Mes échéances',
      render: () => {
        return (
          <Tab.Pane attached={false} css={{
            background: 'transparent !important',
            border: 'none !important'
          }}>
            <EcheancesPane />
          </Tab.Pane>
        )
      },
    },
  ]

  return (
    <Container>
      <Tab 
        menu={{ secondary: true, pointing: true }} 
        panes={panes} 
        onTabChange={handleTabChange}
        activeIndex={index}
      />
    </Container>
  );
}
