import React, { useState } from "react";
import { Form, FormField } from "semantic-ui-react";
import { CheckBoxSimpleField } from "../generic/checkbox-simple-field/checkbox-simple-field";
import { SubmitButton } from "../generic/submit-button";
import { css } from "@emotion/core";
import { investmentService } from "../../services/investment-service";
import { SaveExonerationPASForUserBody } from "../../model/dto/body/save-exoneration-PAS-for-user-body";
import { UserInfoResponse } from "../../model/dto/response"
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";

type FieldData = {
    value?: any;
    name?: string;
    isValid?: boolean;
};

type FormData = {
    exonerationCSG: FieldData;
    exonerationCRDS: FieldData;
    exonerationIR: FieldData;
    exonerationPS: FieldData;
};

type Props = {
    userInfo : UserInfoResponse,
    onSubmitSuccess: () => void
}

export default function InformationsPASForm({ userInfo, onSubmitSuccess }: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    const [formData, setFormData] = useState<FormData>({
        exonerationCRDS: {
            value: userInfo?.IsCRDSExonere,
            name: "exonerationCRDS",
        },
        exonerationCSG: {
            value: userInfo?.IsCSGExonere,
            name: "exonerationCSG",
        },
        exonerationIR: {
            value:  userInfo?.IsIRExonere,
            name: "exonerationIR",
        },
        exonerationPS: {
            value:  userInfo?.IsPSExonere,
            name: "exonerationPS",
        },
    });

    const handleInputChangeNew = (
        e: React.ChangeEvent<HTMLInputElement>,
        fieldName?: string,
        isValid?: boolean
    ): void => {
        const fieldValue: string = e.target.value;

        if (fieldName) {
            const newField: FieldData = {
                [fieldName]: {
                    value: fieldValue,
                    name: fieldName,
                    isValid: isValid,
                },
            };
            setFormData({ ...formData, ...newField });
        }
    };

    const body: SaveExonerationPASForUserBody = {
        UserId: userInfo.UserId!,
        IsCRDSExonere: formData.exonerationCRDS.value,
        IsCSGExonere: formData.exonerationCSG.value,
        IsIRExonere: formData.exonerationIR.value,
        IsPSExonere: formData.exonerationPS.value,
    }

    const divFlex = {
        display: "flex",
        alignItems: "center",
        maxWidth: "50%",
    };

    const spanTauxCss = {
        width: "30%",
    };

    const datePASElement = (date?: Date) => {
        if(!date) {
            return "jamais"
        } 
        return format(parseISO(date.toString()),"dd/MM/yy")
    } 

    return (
        <>
            <Form>
                <FormField>
                    <div style={divFlex}>
                        <span style={spanTauxCss}>CSG : 9,2%</span>

                        <CheckBoxSimpleField
                            label=""
                            data={formData.exonerationCSG}
                            onChange={(value) =>
                                handleInputChangeNew(
                                    { target: { value } } as any,
                                    formData.exonerationCSG.name
                                )
                            }
                            inlineFlex
                            toggle
                            isInfoToggle
                        />

                        <span>Dernière mise à jour le {datePASElement(userInfo?.DateCSGUpdated)}</span>
                    </div>
                </FormField>
                <FormField>
                    <div style={divFlex}>
                        <span style={spanTauxCss}>CRDS : 0,5 %</span>

                        <CheckBoxSimpleField
                            label=""
                            data={formData.exonerationCRDS}
                            onChange={(value) =>
                                handleInputChangeNew(
                                    { target: { value } } as any,
                                    formData.exonerationCRDS.name
                                )
                            }
                            inlineFlex
                            toggle
                            isInfoToggle
                        />

                        <span>Dernière mise à jour le {datePASElement(userInfo?.DateCRDSUpdated)}</span>
                    </div>
                </FormField>
                <FormField>
                    <div style={divFlex}>
                        <span style={spanTauxCss}>
                            IR : 12,8 %
                        </span>

                        <CheckBoxSimpleField
                            label=""
                            data={formData.exonerationIR}
                            onChange={(value) =>
                                handleInputChangeNew(
                                    { target: { value } } as any,
                                    formData.exonerationIR.name
                                )
                            }
                            inlineFlex
                            toggle
                            isInfoToggle
                        />

                        <span>Dernière mise à jour le {datePASElement(userInfo?.DateIRUpdated)}</span>
                    </div>
                </FormField>
                <FormField>
                    <div style={divFlex}>
                        <span style={spanTauxCss}>
                            PS : 7,5 %
                        </span>

                        <CheckBoxSimpleField
                            label=""
                            data={formData.exonerationPS}
                            onChange={(value) =>
                                handleInputChangeNew(
                                    { target: { value } } as any,
                                    formData.exonerationPS.name
                                )
                            }
                            inlineFlex
                            toggle
                            isInfoToggle
                        />

                        <span>Dernière mise à jour le {datePASElement(userInfo?.DatePSUpdated)}</span>
                    </div>
                </FormField>
            </Form>
            <SubmitButton
                label="Valider"
                action={() => investmentService.saveExonerationPASForUser(body, loginProps.oauth)}
                onActionSuccess={onSubmitSuccess}
                style={{marginTop:15}}
            />
        </>
    );
}
