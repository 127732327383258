import React, {useEffect, useState } from "react";
import { connect } from "react-redux";
import { TypePieceByProfil } from "../../model/entities/type-piece-by-profil";
import { userService } from "../../services/authentication-redux-service";
import { ApplicationState } from "../../store";
import { OnboardingDocumentLineForm } from "./onboarding-document-line-form";
import { AuthenticationState } from "../../model/state/authentication-state";
import { SubmitButton } from "../generic/submit-button";
import { UserPieceJustificative } from "../../model/entities/user-piece-justificative";
import { EtablissementPieceJustificative } from "../../model/entities/etablissement-piece-justificative";
import { SimpleButton } from "../generic/simple-button";
import { trackPromise } from "react-promise-tracker";
import { LoadingSpinner } from "../generic/loading-spinner";
import { FormErrorHeader } from "../generic/form-error-header";
import { TypePieceJustificative } from "../../model/entities/type-piece-justificative";
import { ProfilCode } from "../../model/dto/response";
import { CategorieEtablissement } from "../../model/entities/categorie-etablissement";
import { FlowStep } from "../../model/entities/flow-step";
import { referenceService } from "../../services/reference-service";


type Props = {
  loginProps?: AuthenticationState;
  onActionSuccess: (response: Response) => void;
  onActionFailure?: () => void;
  onSkipStep?: (response: Response) => void;
  onQuitEdit?: () => void;
  workflowTypeCode?:string
  isOnboarding?: boolean;
  userId?: number
};

function OnboardingInvestisseurMoralPorteurProjetDocumentListForm ({
  loginProps,
  isOnboarding,
  userId,
  workflowTypeCode,
  onActionFailure,
  onActionSuccess,
  onSkipStep
}: Props) {

  const [typePieceList, setTypePieceList] = useState<TypePieceByProfil[]>([]);
  const [userPieceList, setUserPieceList] = useState<(EtablissementPieceJustificative)[]>([]);
  const [isFormDocumentSubmitted, setIsFormDocumentSubmitted] = useState(false)
  const [isFormError, setIsFormError] = useState(false)

  useEffect(() => {
    if (loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR || loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET)
    {
      trackPromise(userService.getTypePieceForUser(loginProps?.oauth?.userId, loginProps?.oauth)
        .then(response => {
            if(response.IsTraitementOk) {
              setTypePieceList(response.TypePieceList!)
            }
        }), "modal")
    }
    else
    {
      trackPromise(userService.getTypePieceUserForAdmin(userId, loginProps?.oauth)
      .then(response => {
          if(response.IsTraitementOk) {
              setTypePieceList(response.TypePieceList!)
          }
      }), "modal")

    }
  }, []);

  const validateFile = (file: File | undefined) => {
    if (file){
      const acceptedExtensions = ["pdf", "jpeg", "jpg", "gif", "png"];
      const fileSize = file.size;
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop()?.toLowerCase();
      if (fileSize < 32768 || fileSize > 7340032 || !acceptedExtensions.includes(fileExtension!)) {
        return false
      }
      return true
    } else {
      return false
    }
  }
  
  const onFormNotValid = () => {
    setIsFormError(true)
  }

  const validateForm = () => {
    const acceptedExtensions = ["pdf", "jpeg", "jpg", "gif", "png"];

      if (userPieceList.length > 0) {
        for (var i = 0 ; i < userPieceList.length ; i++ ) {
          if(userPieceList[i].Document){
            const fileSize = userPieceList[i].Document!.size;
            const fileName = userPieceList[i].Document!.name;
            const fileExtension = fileName.split('.').pop()?.toLowerCase();
  
            //On verifie la taille et l'extension de chaque fichier
            if (fileSize < 32768 || fileSize > 7340032 || !acceptedExtensions.includes(fileExtension!)) {
              return false
            }
          } else {
            return false
          }
          
          if(userPieceList[i].DocumentVerso){
            const fileSizeVerso = userPieceList[i].DocumentVerso!.size;
            const fileNameVerso = userPieceList[i].DocumentVerso!.name;
            const fileExtensionVerso = fileNameVerso.split('.').pop()?.toLowerCase();
            if (fileSizeVerso < 32768 || fileSizeVerso > 7340032 || !acceptedExtensions.includes(fileExtensionVerso!)) {
              return false
            }
          }
        }
        return(true)
      }
    return false
  }

  const skipToNextStepKey = () => {
    const body = {
        WorkflowTypeCode : workflowTypeCode!,
        CurrentFlowStep : FlowStep.PIECES_JUSTIFICATIVES,
        RessourceId:  loginProps?.user?.UserId!,
        IsSkiped: true
    }
    return referenceService.getNextStep(body).then(response => {
      onSkipStep && onSkipStep(response)})
  }

  return (
    <div
      css={{
        padding: "0rem 3.78rem !important",
      }}
    >
      <LoadingSpinner>
        {loginProps?.user?.Etablissement &&
          <FormErrorHeader
            message="Veuillez transmettre au moins une pièce justificative et respecter les contraintes de format"
            displayed={isFormError}
          />
        }
        {typePieceList.length > 0  && ((loginProps?.oauth?.profilCode == ProfilCode.PORTEUR_PROJET) || (loginProps?.oauth?.profilCode == ProfilCode.INVESTISSEUR && loginProps.user?.Etablissement)) &&
          typePieceList.map((typePiece) => {
            return (
              <OnboardingDocumentLineForm
                key={typePiece.Id}
                typePiece={typePiece}
                verso = {typePiece.TypePieceCode === TypePieceJustificative.IDENTITY_PROOF}
                obligatoire
                validateDocument={(file?: File) => {
                  if (file){
                    const acceptedExtensions = ["pdf", "jpeg", "jpg", "gif", "png"];
                    const fileSize = file.size;
                    const fileName = file.name;
                    const fileExtension = fileName.split('.').pop()?.toLowerCase();
                    if (fileSize < 32768 || fileSize > 7340032 || !acceptedExtensions.includes(fileExtension!)) {
                      return false
                    }
                    return true
                  } else {
                    return false
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>, isVerso?: boolean, isAlreadyUploaded?: boolean) => {
                  const element = e.target as HTMLInputElement;
                  const alreadyUploaded = userPieceList.find( //On récupère l'ancienne pièce jointe correspondante si elle existe
                    (up) =>
                      up.TypePieceByProfilId === typePiece.Id
                  );
                  if (element.files) { // Le onChange correspond à un ajout de fichier

                    if (!isVerso) { // Recto d'une PJ

                      const userPiece:
                        | UserPieceJustificative
                        | EtablissementPieceJustificative = {
                        TypePieceByProfilId: typePiece.Id,
                        Document: element.files[0],
                        DocumentVerso : alreadyUploaded?.DocumentVerso // On récupère le verso déjà uploadé si il existe
                      };
    
                      
                      if (alreadyUploaded) { // On met à jour la Pièce jointe dans le state
                        var array = [...userPieceList]; // On fait une copie du tableau actuel
                        var index = array.indexOf(alreadyUploaded);
                        if (index !== -1) {
                          array.splice(index, 1);
                          setUserPieceList([...array, userPiece]);
                        }
                      } else {
                        setUserPieceList([...userPieceList, userPiece]);
                      }
                    } else { // On traite le verso d'une PJ 
                      const userPiece: UserPieceJustificative | EtablissementPieceJustificative = {
                        TypePieceByProfilId: typePiece.Id,
                        Document: alreadyUploaded?.Document,
                        DocumentVerso: element.files[0]
                      }

                      if (alreadyUploaded) { // On met à jour la Pièce jointe dans le state
                        var array = [...userPieceList]; 
                        var index = array.indexOf(alreadyUploaded);
                        if (index !== -1) {
                          array.splice(index, 1);
                          setUserPieceList([...array, userPiece]);
                        }
                      } else {
                        setUserPieceList([...userPieceList, userPiece]);
                      }
                    }
                  }
                  setIsFormError(false);
                }}
                onClickCheckBox = {(isVersoAsked : boolean) => {
                  if (isVersoAsked) {
                      const alreadyUploaded = userPieceList.find( //On récupère l'ancienne pièce jointe correspondante si elle existe
                      (up) =>
                        up.TypePieceByProfilId === typePiece.Id
                      );
                      if(alreadyUploaded) {
                        const userPiece:
                          | UserPieceJustificative
                          | EtablissementPieceJustificative = {
                          TypePieceByProfilId: alreadyUploaded.TypePieceByProfilId,
                          Document: alreadyUploaded.Document,
                          DocumentVerso : undefined // On enlève la pièce verso
                        };
                        var array = [...userPieceList]; // On fait une copie du tableau actuel
                        var index = array.indexOf(alreadyUploaded);
                        if (index !== -1) {
                          array.splice(index, 1);
                          setUserPieceList([...array, userPiece]); // On met à jour le state
                        }

                      }
                    }
                  }}
              />
            );
          })}

      {loginProps?.user?.ProfilCode === ProfilCode.PORTEUR_PROJET && loginProps?.user.Categorie === CategorieEtablissement.COLLECTIVITE && (<div css={{marginBottom : "5px"}}>
        Vous pouvez télécharger facilement votre avis de situation SIREN en cliquant sur le lien&nbsp;
          <a href="https://avis-situation-sirene.insee.fr/" target="_blank">suivant</a>
      </div>)}
      {loginProps?.user?.ProfilCode === ProfilCode.PORTEUR_PROJET && <div css={{paddingBottom : "10px"}}>*champ obligatoire</div>}
      <div className="button-bar">
        <SubmitButton
          data={userPieceList}
          action={() => {
            if (loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR || loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET)
            {
              return userService.registerMultipleTypedFiles(userPieceList, loginProps?.user?.UserId, loginProps?.oauth, workflowTypeCode!)
            }
            else
            {
              return userService.registerMultipleTypedFilesAdmin(userPieceList, loginProps?.oauth?.userId, userId, loginProps?.oauth)
            }
          }}
          label="Valider"
          onActionFailure={onActionFailure}
          onActionSuccess={onActionSuccess}
          validateForm={validateForm}
          onFormNotValid={onFormNotValid}
          onPreSubmit={() => setIsFormDocumentSubmitted(true)}
        />
         {isOnboarding && (
          <SimpleButton
            label="Passer"
            onClick={() => skipToNextStepKey()} 
          />
        )}
      </div>
      </LoadingSpinner>
    </div>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedOnboardingInvestisseurMoralPorteurProjetDocumentListForm  = connect(
  mapStateToProps,
  null
)(OnboardingInvestisseurMoralPorteurProjetDocumentListForm );
export { ConnectedOnboardingInvestisseurMoralPorteurProjetDocumentListForm  as OnboardingInvestisseurMoralPorteurProjetDocumentListForm  };
