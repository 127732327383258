import React from "react"
import { Grid, Image } from "semantic-ui-react"
import { SubmitButton } from "../generic/submit-button"
import { InvestResponse } from "../../model/dto/response"
import { investmentService } from "../../services/investment-service"
import { AuthenticationState } from "../../model/state/authentication-state"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../store"
import { Investissementbody } from "../../model/dto/body/investissement-body"
import { ModePaiement } from "../../model/entities/mode-paiement"

interface Props {
    montant?: number | string,
    projetId?: number
}

export function WalletPaiementPane({
    montant,
    projetId
}: Props) {

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)


    const onSubmitSuccess = (response: InvestResponse): void => {
        if (response.IsTraitementOk) {
            window.location.href = response.RedirectURL!
        }
    }

    const body: Investissementbody = {
        Montant: montant?.toString(),
        ModePaiementCode: ModePaiement.REINVESTISSEMENT_WALLET,
        IsDon: false
    }

    return(
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <h2 css={{fontWeight: 'bold', fontSize: '16px', marginBottom: '10px'}}>Montant : {montant} €</h2>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <p css={{fontSize: '14px'}}>L'argent est déjà disponible sur votre compte Villyz. Vous n'avez pas besoin de faire un paiement supplémentaire.</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <div 
                    css={{
                        display : "flex", 
                        justifyContent: "space-around", 
                        flexDirection: "row", 
                        marginTop:"10px"
                    }}>
                        <Image
                          size="tiny"
                          src="/img/logos/powered-mangopay-2.png"
                          css={{marginInline : "8px"}}
                        />
                </div>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column textAlign="center">
                    <SubmitButton
                        label="Confirmer l’investissement"
                        action={() => investmentService.invest(projetId!, body, loginProps?.oauth)}
                        onActionSuccess={onSubmitSuccess}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}