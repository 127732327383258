import React from "react"
import { connect } from "react-redux"
import { Form } from "semantic-ui-react"
import { UpdateProfilStatusBody } from "../../../model/dto/body/update-profil-status-body"
import { UserInfoResponse } from "../../../model/dto/response"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { userService } from "../../../services/authentication-redux-service"
import { ApplicationState } from "../../../store"
import { DropdownListFieldNew } from "../../generic/dropdown-list-field"
import { InputField } from "../../generic/input-field"
import { SubmitButton } from "../../generic/submit-button"

type FormData = {
    nom: FieldData,
    prenom: FieldData,
    mail: FieldData,
    dateNaissance: FieldData,
    profilStatus: FieldData
}

type FieldData = {
    value?: any,
    name?: string,
    isValid?: boolean
};

interface UpdateProfilStatusFormProps {
    loginProps?: AuthenticationState,
    userInfo?: UserInfoResponse
    close: () => void
}

interface UpdateProfilStatusFormState {
    form : FormData
}

const profilStatus = [
    {text: "Complet", value: "COM"},
    {text: "Incomplet", value: "INC"},
    {text: "En erreur", value: "ERR"},
    {text: "En attente", value: "EAT"},
    {text: "Validation adresse mail à faire", value: "VAA"},
    {text: "Validé", value: "VAL"},
]

class UpdateProfilStatusForm extends React.Component<UpdateProfilStatusFormProps, UpdateProfilStatusFormState> {
    constructor(props: UpdateProfilStatusFormProps) {
        super(props)

        this.state = {
            form : {
                nom: {value: props.userInfo?.Nom!, name: "nom", isValid: true},
                prenom: {value: props.userInfo?.Prenom!, name: "prenom", isValid: true},
                mail: {value: props.userInfo?.Mail!, name: "mail", isValid: true},
                dateNaissance: {value: props.userInfo?.DateNaissance!, name: "dateNaissance", isValid: true},
                profilStatus: {value: props.userInfo?.OnboardingStatus?.Code!, name: "profilStatus", isValid: true}
            }
        }

        this.handleInputChangeNew = this.handleInputChangeNew.bind(this)
    }

    handleInputChangeNew(e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void{
        const fieldValue: string = e.target.value;
    
        if (fieldName) {
          const newField: FieldData = {
            [fieldName]: { value: fieldValue, name: fieldName, isValid: isValid },
          };
          this.setState({...this.state, form : {...this.state.form, ...newField}})
        }
      };

    render() {
        const { loginProps, userInfo, close } = this.props
        const { form } = this.state

        const body: UpdateProfilStatusBody = {
             ProfilStatus: form.profilStatus.value
        }

        return (
            <div>
                <Form>
                    <Form.Group>
                        <Form.Field width={8}>
                            <InputField 
                                data={form.nom} 
                                label="Nom" 
                                disabled                         
                            />
                        </Form.Field>
                        <Form.Field width={8}>
                            <InputField 
                                data={form.prenom} 
                                label="Prénom"
                                disabled                           
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={16}>
                            <InputField 
                                data={form.mail} 
                                label="E-mail"
                                type="email"
                                disabled                           
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field width={8}>
                            <InputField 
                                data={form.dateNaissance} 
                                label="Date de naissance"
                                disabled                           
                            />
                        </Form.Field>
                        <Form.Field width={8}>
                            <DropdownListFieldNew
                                label="Status du profil*"
                                field={form.profilStatus}
                                placeholder="Status du profil"
                                error="Merci de renseigner votre civilité"
                                datasource={profilStatus}
                                onChange={(value) =>
                                    this.handleInputChangeNew(
                                        { target: { value } } as any,
                                        form.profilStatus.name
                                    )
                                }
                            />
                        </Form.Field>
                    </Form.Group>
                    <div className="button-bar">
                        <SubmitButton 
                            data={body}
                            label="Valider"
                            action={()=>userService.updateEventHistory(body, userInfo?.UserId!, loginProps?.oauth)}
                            onActionSuccess={close}
                            loaderArea='modal'
                        />
                    </div>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedUpdateProfilStatusForm = connect(mapStateToProps, null)(UpdateProfilStatusForm);
export { ConnectedUpdateProfilStatusForm as UpdateProfilStatusForm };