import React from "react";
import { EditableBlock } from "../../generic/editable-block/editable-block";
import { UtilisateursReportingForm } from "../form/utilisateurs-reporting-form";

export function ReportingUtilisateurs() {
    
    return (
        <> 
            <>
                <EditableBlock
                    title="Reporting utilisateurs"
                    editor={() => null}
                    editable={false}
                >
                    <UtilisateursReportingForm />
                </EditableBlock>
            </>
        </>
    )
}