import React from "react"
import { SubscriptionIdentiteRepresentantForm } from "../subscription-identite-representant-form"
import { SubscriptionCollectiviteAdresses } from "../subscription-collectivite-adresses"
import { OnboardingInvestisseurMoralPorteurProjetDocumentListForm } from "../onboarding-investisseur-moral-porteur-projet-document-list-form"
import { SubscriptionSEMFormBeneficiaires } from "../subscription-sem-form-beneficiaires"
import { SubscriptionCollectiviteFormEnding } from "../subscription-collectivite-form-ending"
import { WorkflowType } from "../../../model/entities/workflow-type"
import { FlowStep } from "../../../model/entities/flow-step"
import { OnboardingSEMResponse } from "../../../model/dto/response"

interface OnboardingSEMProps {
    activeElementKey: string,
    lastStepResponse?: OnboardingSEMResponse
    onNextStep: (response: Response) => void,
    onSubmitVerification: (response: Response) => void,
    onSubmitPiecesJustificatives: (response: Response) => void
}

export function OnboardingSEM({activeElementKey, lastStepResponse, onNextStep, onSubmitVerification, onSubmitPiecesJustificatives}: OnboardingSEMProps) {   
    if(activeElementKey === FlowStep.IDENTITE_REPRESENTANT_LEGAL) {
        return (
            <div>
                <SubscriptionIdentiteRepresentantForm
                    isOnboarding={true}
                    onSubmitSuccess={onSubmitVerification}
                    onSkipStep={onNextStep}
                    workflowTypeCode={WorkflowType.ONBOARDING_PORTEUR_SOCIETE} 
                />
            </div>
        )
    }

    if(activeElementKey === FlowStep.ADRESSES) {
        return (
            <div>
                <SubscriptionCollectiviteAdresses
                    onSubmitSuccess={onSubmitVerification}
                    onSkipStep={onNextStep}
                    workflowTypeCode={WorkflowType.ONBOARDING_PORTEUR_SOCIETE}
                    lastStepResponse={lastStepResponse}
                />
            </div>
        )
    }

    if(activeElementKey === FlowStep.PIECES_JUSTIFICATIVES) {
        return (
            <div>
                <OnboardingInvestisseurMoralPorteurProjetDocumentListForm 
                    onActionSuccess={onSubmitPiecesJustificatives}
                    onSkipStep={onNextStep}
                    isOnboarding={true}
                    workflowTypeCode={WorkflowType.ONBOARDING_PORTEUR_SOCIETE}
                />
            </div>
        )
    }

    if(activeElementKey === FlowStep.BENEFICIAIRES_EFFECTIFS) {
        return (
            <div>
                <SubscriptionSEMFormBeneficiaires 
                    onSubmitSuccess={onSubmitPiecesJustificatives}
                    onSkipStep={onNextStep}
                    isOnboarding={true}
                    workflowTypeCode={WorkflowType.ONBOARDING_PORTEUR_SOCIETE}
                />
            </div>
        )
    }

    if(activeElementKey === FlowStep.COMPLET) {
        return (
            <div>
                <SubscriptionCollectiviteFormEnding />
            </div>
        )
    }

    return (
        <div></div>
    )
}