import React, { useEffect, useState } from "react"
import { Question } from "../../model/entities/question";
import { ReponseQuestionProposition } from "../../model/entities/reponse-question-proposition"
import { ReponseBox } from "./reponse-box"

type Props = { 
    // Liste des réponses possibles pour une question
    responses: ReponseQuestionProposition[];

    // Question à choix multiples
    multiple?: boolean;

    // Réponses déjà sélectionnées par l'utilisateur
    userResponses: ReponseQuestionProposition[];

    // Déclencheur au clic sur une des réponses
    onResponseClick?: (newResponse: ReponseQuestionProposition | undefined, oldResponseList: ReponseQuestionProposition[] | undefined) => void;
  };

  // Composant qui représente la liste des propositions de réponses à une question du test de connaissances 
export function ReponsesQuestionList({
    multiple,
    responses,
    userResponses,
    onResponseClick
  }: Props) {
  
    const [selectedResponses, setSelectedResponses] = useState<ReponseQuestionProposition[]>(responses.filter(response => userResponses.map(r => r.Id).includes(response.Id)))

    useEffect(() => {
      setSelectedResponses(responses.filter(response => userResponses.map(r => r.Id).includes(response.Id)))
    }, [responses, userResponses])

    // Handler au clic sur une réponse, gère les nouvelles réponses sélectionnées et celles qui doivent être désélectionnées
    const onReponseClick =  (response: ReponseQuestionProposition, isSelected: boolean) => {
      if(multiple) {
        if(isSelected) {
          let list: ReponseQuestionProposition[] = []
          if(response.IsExclusive) {
            list = [response]
            if (onResponseClick)
            {
                onResponseClick(response, selectedResponses)
            }
          }
          else {
            list = [...selectedResponses.filter(r => !r.IsExclusive), response]
            const exclusiveResponse =  selectedResponses.find(r => r.IsExclusive)
            if (onResponseClick)
            {
                onResponseClick(response, exclusiveResponse ? [exclusiveResponse] : undefined)
            }
          }
          
          setSelectedResponses(list)
        }
        else {
          const listWithSuppress = selectedResponses.filter(element => element.Id !== response.Id)
          if (onResponseClick)
          {
              onResponseClick(undefined, [response])
          }
          setSelectedResponses(listWithSuppress)
        }
      }
      else {
        if(isSelected) {
          const list = [response]
          const oldValue = selectedResponses.pop()
          if (onResponseClick)
          {
              onResponseClick(response, oldValue ? [oldValue] : undefined)
          }
          setSelectedResponses(list)
        }
        else {
          const listWithSuppress = selectedResponses.filter(element => element.Id !== response.Id)
          const oldValue = selectedResponses.pop()
          if (onResponseClick)
          {
              onResponseClick(undefined, oldValue ? [oldValue] : undefined)
          }
          setSelectedResponses(listWithSuppress)
        }
     }
    }
    
    return (
        <>
          {responses && responses.map((reponse: ReponseQuestionProposition)  =>
              <ReponseBox 
                key={reponse.Id} 
                reponse={reponse} 
                idSelectedResponseList={selectedResponses.map(response => response.Id)} 
                onBoxClick={onReponseClick} 
              />      
          )}    
        </>
    )
   
}

