import React, {useEffect, useState } from "react";
import { connect, useSelector} from "react-redux";  
import {Form } from "semantic-ui-react";
import { HistoRemboursementBody } from "../../../model/dto/body/histo-remboursement-body";
import { RemboursementHistoryResponse } from "../../../model/dto/response";
import { MangoPayEventReference } from "../../../model/entities/mangopay-event-reference";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { userService } from "../../../services/authentication-redux-service";
import { referenceService } from "../../../services/reference-service";
import { ApplicationState } from "../../../store";
import { DropdownListFieldNew } from "../../generic/dropdown-list-field";
import { InputField } from "../../generic/input-field";
import { SubmitButton } from "../../generic/submit-button";
 

interface Props {
  userId?: number;
  currentHistoRemboursement?: RemboursementHistoryResponse;
  onSubmitSuccess: () => void;
}

type FieldData = {
  value?: any,
  name?: string,
  isValid?: boolean
};

type FormData = {
  Statut: FieldData,
  IdMangoPay: FieldData
}

export function ChangeHistoRemboursementInformations({
  userId,
  currentHistoRemboursement,
  onSubmitSuccess
} : Props) {
  const [form, setForm] = useState<FormData>({
    Statut : { name : "Statut", value : currentHistoRemboursement?.Statut, isValid: true},
    IdMangoPay : { name : "IdMangoPay", value : currentHistoRemboursement?.MangoPayId, isValid: true}
  })
  const [isFormError, setIsFormError] = useState(false)
  const [mangoPayEventList, setMangoPayEventList] = useState<MangoPayEventReference[]>([])

  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  useEffect(() => {
    referenceService.getAllMangoPayEvent()
        .then((response) => {
          if (response.IsTraitementOk) {
            setMangoPayEventList(response.MangoPayEventList!)
          }
        });
}, [])
  
const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
    const fieldValue: string = e.target.value;
    setIsFormError(false)
    setForm({...form, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid}})
}
 
  const body: HistoRemboursementBody = {
    Statut : form.Statut.value,
    IdMangoPay : form.IdMangoPay.value,
    UserId : userId!
}
   
  return (
    <Form>
    <DropdownListFieldNew
        label="Statut"
        field={form.Statut}
        placeholder="Statut"
        datasource={mangoPayEventList.map((c) => ({
                    text: c.Code!,
                    value: c.Code!,
                    }))}
        onChange={(value) =>
                    handleInputChange(
                    { target: { value } } as any,
                    form.Statut.name
                    )
                }                        
    />
    <Form.Field width={16}>
        <InputField 
            data={form.IdMangoPay}
            label="IdMangoPay"
            onChange={handleInputChange}
        />
    </Form.Field>

    <SubmitButton 
        data={body} 
        label="Valider"
        action={() =>userService.updateHistoRemboursementInfoByAdmin(body, loginProps?.oauth?.userId, currentHistoRemboursement?.Id, loginProps?.oauth)} 
        onActionSuccess={() => {
            onSubmitSuccess && onSubmitSuccess()
        }}
    />
</Form>
  );
}


 