import React, { useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import{CollectivitePostPublique} from "../../model/entities/collectivite-post-publique"
import { format } from "date-fns";
import { css } from "@emotion/core";
import { referenceService } from "../../services/reference-service";


interface Props {
    post: CollectivitePostPublique;
}

const DateStyle = css({
    margin: 0,
    fontWeight: "bold",
    opacity: 0.5,
    fontSize: ".6em",
    color:"grey",
    textAlign:"center",
});

 export function EventPostBox({post}: Props) {
    const [labelEvent, setLabelEvent] = useState('Test')

    useEffect(()=>{
        if(post.TypeEvenementWithPalierId) {
            referenceService.getTypeEvenementWithPalier(post.TypeEvenementWithPalierId)
            .then(
              (response)=>{
                if(response) {
                    const labelInit = response.TypeEvenement?.Designation
                    const labelFin = labelInit?.replace('%var%', response.EventWithPalier?.Palier?.toString() ?? '')
                    setLabelEvent(labelFin ?? '')
                }
              }
            );
        }
     }, [])

    return (
        <div css={{ display: 'flex', flexDirection: "column", alignItems: "center"}}>
            <Segment 
                inverted 
                color='teal' 
                raised 
                css={{
                    width: "200px",
                    height: "70px",
                    border: "0.1rem solid rgb(20 18 67 / 60%) !important",
                    marginBottom: "0px !important",
                    display: "flex",
                    flexDirection:"column",
                    justifyContent:"center",
                    alignItems:"center"
                }}>
                    <div css={DateStyle}> 
                            {format(new Date(post.DateCreation!), "dd/MM/yyyy")}
                    </div>
                    <div css={{color:"black",fontSize: ".8em",textAlign:"center",fontWeight:"bold"}}>
                        {labelEvent}
                    </div> 
             </Segment>
        </div>
    );
}

