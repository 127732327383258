import React, { useState } from "react"
import Projet from "../../../model/projet"
import { SubmitButton } from "../../generic/submit-button"
import { projetService } from "../../../services/projet-service"
import { ApplicationState } from "../../../store"
import { AuthenticationState } from "../../../store/authentication/types"
import { connect } from "react-redux"
import { Form } from "semantic-ui-react";
import { InputField } from "../../generic/input-field"
import { ProlongationDureeCollecteAdminBody } from "../../../model/dto/body/prolongation-duree-collecte-admin-body"

type Props = {
    projet: Projet,
    loginProps : AuthenticationState,
    onSubmitSuccess: (response: Response) => void
}

type FieldData = {
    value?: any,
    name?: string,
    isValid?: boolean
};

type FormData = {
    DureeProlongation: FieldData,
}
  
function ProlongationForm({projet, loginProps, onSubmitSuccess}: Props) {
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [isError, setIsError] = useState(false)
    const [form, setForm] = useState<FormData>({
        DureeProlongation : { name : "DureeProlongation", value : '', isValid: true}
    })

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
        const fieldValue: string = e.target.value;
        setForm({...form, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid}})
    } 

    const onSubmitError = (response : any) => {
        if (response.Error) {
            setIsError(true)
            setErrorMessage(response.Error);
        } else {
            setIsError(true)
        }
    }

    const validateMandatory = (value: any): boolean => {
        if (!value || value === "") {
            return false;
        }
        return true;
    };

    const isFormValid = (): boolean => {
        return (
            validateMandatory(form.DureeProlongation.value) &&
            form.DureeProlongation.value
        )
    };

    const body:  ProlongationDureeCollecteAdminBody = {
        DureeProlongation : form.DureeProlongation.value
    }

    return (
        <>
            <Form>
                <Form.Field width={16}>
                    <InputField 
                        data={form.DureeProlongation}
                        label="Durée de prolongation (en jours)"
                        onChange={handleInputChange}
                    />
                </Form.Field>
 
                <SubmitButton 
                    data={body} 
                    label="Confirmer"
                    action={() => projetService.prolongationProjet(body, projet.Id!, loginProps.oauth)}
                    onPreSubmit= {() => {
                        setIsError(false)
                    }}
                    validateForm={isFormValid}
                    onActionSuccess={onSubmitSuccess}
                    onActionFailure={onSubmitError}
                />
            </Form>
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication,
  });

const ConnectedProlongationForm = connect(mapStateToProps)(ProlongationForm);
export {ConnectedProlongationForm as ProlongationForm}