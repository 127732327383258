import { Reducer } from 'redux';
import { 
    AuthenticationState, 
    AuthenticationAction,
    REQUEST_LOGIN,
    LOGIN_SUCCESSFUL,
    LOGIN_FAILURE,
    LOGOUT, REQUEST_IDENTITY
} from './types';
import { OAuthResponse } from '../../model/authentication/oauth-response';
import { CHANGE_IDENTITY } from '../../actions/authentication-actions';
import { UserInfoResponse } from '../../model/dto/response';

// Type-safe initialState!
var userStorage = localStorage.getItem('oauth_user');
if (!userStorage) {
    userStorage = '';
}

var userInfoStorage = localStorage.getItem('user_info');
let user: OAuthResponse | undefined = userStorage ? JSON.parse(userStorage) : undefined;
let userInfo: UserInfoResponse | undefined = userInfoStorage ? JSON.parse(userInfoStorage) : undefined
const initialState: AuthenticationState = user ? 
{ 
  isAuthenticated: true, 
  isLoggingIn: false, 
  isAuthenticationFailed: false, 
  oauth: user, 
  user: userInfo 
} : 
{
  isAuthenticated: false,
   isLoggingIn: false, 
   isAuthenticationFailed: false, 
   oauth: undefined, 
   user: userInfo};



// Unfortunately, typing of the `action` parameter seems to be broken at the moment.
// This should be fixed in Redux 4.x, but for now, just augment your types.

const reducer: Reducer<AuthenticationState> = (state: AuthenticationState = initialState, action) => {
  // We'll augment the action type on the switch case to make sure we have
  // all the cases handled.
  switch ((action as AuthenticationAction).type) {
    case REQUEST_LOGIN:
      return {
        isAuthenticated: false,
        isLoggingIn: true,
        isAuthenticationFailed: false,
        oauth: undefined,
        user: action.user
      };
    case LOGIN_SUCCESSFUL:
      return {
        isAuthenticated: true,
        isLoggingIn: false,
        isAuthenticationFailed: false,
        oauth: action.authResponse,
        user: action.user
      };
    case LOGIN_FAILURE:
      return {
          isAuthenticated: false,
          isLoggingIn: false,
          isAuthenticationFailed: true,
          oauth: action.authResponse,
          user: action.user
      };
    case LOGOUT:
      return {
          isAuthenticated: false,
          isLoggingIn: false,
          isAuthenticationFailed: false,
          oauth: undefined,
          user: undefined
      };
    case CHANGE_IDENTITY:
      return {
        ...state,
        user: action.user
      };
    case REQUEST_IDENTITY:
      return {
        ...state,
        user: action.user
      }
    default:
      return state
  }
};

export default reducer;