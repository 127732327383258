import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { userService } from "../../services/authentication-redux-service";
import { Loader } from "semantic-ui-react";


export const ValidateEmail = () => {
  const { encodedValidationCode } = useParams<{encodedValidationCode: string}>();
  const navigate = useNavigate();

  useEffect(() => {
      if(!encodedValidationCode){
        return navigate("/");
      }
      const validationCode = decodeURIComponent(encodedValidationCode);
      if(!validationCode){
        return navigate("/");
      }
      try{
        userService.validateEmail(validationCode);
      }catch(e){
      }
      return navigate("/");
  },[])
  
  return <Loader active inline="centered" />;
};
