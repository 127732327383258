import { trackPromise } from "react-promise-tracker";
import { OAuthResponse } from "../model/authentication/oauth-response";

export function treatHttpResponseCode(httpCode: number) {
    if(httpCode === 403) {
        window.location.href = "/interdit"
    }

    if(httpCode === 401) {
        window.location.href = "/deconnexion"
    }

    if(httpCode === 204) {
        window.location.href = "/vide"
    }

    if(httpCode === 500) {
        window.location.href = "/erreur"
    }
}

export function trackedJsonFetch<T>(suffix: string, requestOptions: RequestInit){
    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}${suffix}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<T>
    }))
}

export function trackedBlobFetch(suffix: string, requestOptions: RequestInit){
    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}${suffix}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.blob()
    }))
}

//TODO: make http method parametrable?
function getPostBodyOptions(body: object, oauth?: OAuthResponse | undefined){
    const accessToken = oauth?.access_token;
    let authHeader: any = {};
    if(accessToken){
        authHeader = {Authorization: 'Bearer ' + accessToken}
    }
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader
        },
        body: JSON.stringify(body)
    };
    return requestOptions;
}

export function jsonPostTrackedJsonFetch<T>(suffix: string, body: object, oauth?: OAuthResponse | undefined){
    const requestOptions = getPostBodyOptions(body, oauth);

    return trackedJsonFetch<T>(suffix, requestOptions);
}
export function blobPostTrackedJsonFetch(suffix: string, body: object, oauth?: OAuthResponse | undefined){
    const requestOptions = getPostBodyOptions(body, oauth);

    return trackedBlobFetch(suffix, requestOptions);
}