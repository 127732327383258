import React from "react";
import { connect, useSelector } from "react-redux";
import { Route, Navigate, RouteProps, useLocation } from "react-router-dom";
import { LOGOUT } from "../actions/authentication-actions";
import AuthenticationService from "../services/authentication-service";
import { AuthenticationState } from "../model/state/authentication-state";
import { ApplicationState } from "../store";

const PrivateRoute = (routeProps: RouteProps) => {
  const isAuthenticated = AuthenticationService.isAuthenticated;

  if (!isAuthenticated) {
    return <Navigate to={{ pathname: "/login" }} />
  }

  return <Route {...routeProps}/>
}

export default PrivateRoute;

interface PrivateRouteStorageProps {
  logOut: () => void;
  component: React.ComponentType;
}

const PrivateRouteStorage = ({
  logOut,
  component: Component,
}: PrivateRouteStorageProps) => {
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
  const isConnected = loginProps.isAuthenticated;

  const location = useLocation();

  if (!isConnected) {
    logOut();

    return <Navigate
      to={{
        pathname: "/login"
      }}
      state={{ from: location }}
    />
  }

  return <Component/>;
};

const mapDispatchToProps = (dispatch: any) => ({
  logOut: () => dispatch({ type: LOGOUT }),
});

const ConnectedPrivateRouteStorage = connect(
  null,
  mapDispatchToProps
)(PrivateRouteStorage);
export { ConnectedPrivateRouteStorage as PrivateRouteStorage };

function isOauthTokenValid(token: string | null) {
  if (!token) {
    return false;
  }

  const parsedToken = JSON.parse(token);
  const expiresAt = new Date(parsedToken[".expires"]);
  const isTokenValid = expiresAt > new Date();

  return isTokenValid;
}
