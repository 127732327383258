
import React, { useMemo } from "react"
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { ProfilPersonneMoraleDetail } from "./profil-personne-morale-detail";
import { ProfilPersonnePhysiqueDetail } from "./profil-personne-physique-detail";

interface Props {
}

export function SwitchProfilContainer({}: Props) {

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>((state) => state.authentication);

    const isInvestisseurMoral = useMemo(() => {
        if(loginProps.user?.Etablissement) {
            return true
        }
        return false
    }, [loginProps])



    return(
        <>
            {isInvestisseurMoral &&
               <ProfilPersonneMoraleDetail />
            }

            {!isInvestisseurMoral &&
                <ProfilPersonnePhysiqueDetail />
            }
        
        </>  
    )
}