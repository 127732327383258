export interface ProjetPiece {
    Id?: number,
    TypePiece?: string,
    projetId?: number,
    Document?: File
}

const CONTRAT_DE_PRET: string = "CTP";
const MANDAT_SEPA: string = "MDS";
const CONTRAT_DE_DON: string= "CTD"

export const TypePieceValidation = {
    CONTRAT_DE_PRET,
    MANDAT_SEPA,
    CONTRAT_DE_DON
}