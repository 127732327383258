import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useState } from "react";
import { ApplicationState } from "../../store";
 
import { Accordion, Form, Icon} from "semantic-ui-react";
 import { InputField } from "../generic/input-field";
import { SimpleButton } from "../generic/simple-button";

type FieldData = {
  value?: any,
  name?: string,
  isValid?: boolean
};

type Form = {
    resultatNetAnnuel1: FieldData,
    resultatNetAnnuel2: FieldData,
    resultatNetAnnuel3: FieldData,
    resultatNetAnnuel4: FieldData,
    liquidites1: FieldData,
    liquidites2: FieldData,
    liquidites3: FieldData,
    engagementsFinanciersAnnuels1: FieldData,
    engagementsFinanciersAnnuels2: FieldData,
    engagementsFinanciersAnnuels3: FieldData,
    engagementsFinanciersAnnuels4: FieldData,
    engagementsFinanciersAnnuels5: FieldData,
    engagementsFinanciersAnnuels6: FieldData,
    engagementsFinanciersAnnuels7: FieldData
}

type Props = {
  onValidateSimulation?: (valeurNette: number) => void
}

function SimulateurForm({ onValidateSimulation }: Props) {

    const [form, setForm] = useState<Form>({
      resultatNetAnnuel1: {value: '', name: 'resultatNetAnnuel1', isValid: true},
      resultatNetAnnuel2: {value: '', name: 'resultatNetAnnuel2', isValid: true},
      resultatNetAnnuel3: {value: '', name: 'resultatNetAnnuel3', isValid: true},
      resultatNetAnnuel4: {value: '', name: 'resultatNetAnnuel4', isValid: true},
      liquidites1: {value: '', name: 'liquidites1', isValid: true},
      liquidites2: {value: '', name: 'liquidites2', isValid: true},
      liquidites3: {value: '', name: 'liquidites3', isValid: true},
      engagementsFinanciersAnnuels1: {value: '', name: 'engagementsFinanciersAnnuels1', isValid: true},
      engagementsFinanciersAnnuels2: {value: '', name: 'engagementsFinanciersAnnuels2', isValid: true},
      engagementsFinanciersAnnuels3: {value: '', name: 'engagementsFinanciersAnnuels3', isValid: true},
      engagementsFinanciersAnnuels4: {value: '', name: 'engagementsFinanciersAnnuels4', isValid: true},
      engagementsFinanciersAnnuels5: {value: '', name: 'engagementsFinanciersAnnuels5', isValid: true},
      engagementsFinanciersAnnuels6: {value: '', name: 'engagementsFinanciersAnnuels6', isValid: true},
      engagementsFinanciersAnnuels7: {value: '', name: 'engagementsFinanciersAnnuels7', isValid: true}

    })
    
    const [active, setActive] = useState(true)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean): void => {
      const fieldValue: string = e.target.value;
      const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName, isValid: isValid } };
    
      setForm({ ...form,  ...newField});
    }

    const valeurNette = useMemo(() => {
      const resultatNetAnnuel = +form.resultatNetAnnuel1.value + +form.resultatNetAnnuel2.value + +form.resultatNetAnnuel3.value + +form.resultatNetAnnuel4.value;
      const liquidites = +form.liquidites1.value + +form.liquidites2.value + +form.liquidites3.value;
      const engagementsFinanciersAnnuels = +form.engagementsFinanciersAnnuels1.value + +form.engagementsFinanciersAnnuels2.value + +form.engagementsFinanciersAnnuels3.value + +form.engagementsFinanciersAnnuels4.value + +form.engagementsFinanciersAnnuels5.value + +form.engagementsFinanciersAnnuels6.value + +form.engagementsFinanciersAnnuels7.value;
      
      const valeurNette = resultatNetAnnuel + liquidites - engagementsFinanciersAnnuels;
      return valeurNette;
    }, [
      form.resultatNetAnnuel1, 
      form.resultatNetAnnuel2, 
      form.resultatNetAnnuel3, 
      form.resultatNetAnnuel4,
      form.liquidites1,
      form.liquidites2,
      form.liquidites3,
      form.engagementsFinanciersAnnuels1,
      form.engagementsFinanciersAnnuels2,
      form.engagementsFinanciersAnnuels3,
      form.engagementsFinanciersAnnuels4,
      form.engagementsFinanciersAnnuels5,
      form.engagementsFinanciersAnnuels6,
      form.engagementsFinanciersAnnuels7
  ]);

  return (
        <Accordion>
          <Accordion.Title
            active={active}
            onClick={()=>setActive(!active)}
            css={{margin: "5px 0 0 10px"}}
          >
            <Icon name='dropdown' />
            Simulateur
          </Accordion.Title>
          <Accordion.Content active={active}>
            <div>
              <h4>Revenu annuel net</h4>
              <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between", alignItems:"center"}}>
                <p 
                  css={{
                    display: "inline-block", 
                    width:"70%"
                  }}
                >
                  Revenu du travail
                  salaires, indemnités de chômage et pensions, hors versements exceptionnels
                </p>
                <div style={{display:'flex'}}>
                  <InputField
                      data={form.resultatNetAnnuel1}
                      placeholder="0"
                      onChange={handleInputChange}
                      inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                  /> 
                  <strong>€</strong>
                </div>
              </div>
              <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                <p 
                  css={{
                    display: "inline-block", 
                    width:"70%"
                  }}
                >
                  Intérêts sur dépôts bancaires ou autres titres de créance hors versements exceptionnels
                </p>
                <div style={{display:'flex'}}>
                  <InputField
                      data={form.resultatNetAnnuel2}
                      placeholder="0"
                      onChange={handleInputChange}
                      inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                  /> 
                  <strong>€</strong>
                </div>
              </div>
              <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                <p 
                  css={{
                    display: "inline-block", 
                    width:"70%"
                  }}
                >
                  Paiements de dividendes reçus au titre de la détention d'actions ou de parts d'un organisme de placement 
                  collectif ou d'autres instruments de capitaux propres émis par une société, à l'exclusion de toute plus-value 
                  réalisée en cédant tout ou partie de cette participation
                </p>
                <div style={{display:'flex'}}>
                  <InputField
                      data={form.resultatNetAnnuel3}
                      placeholder="0"
                      onChange={handleInputChange}
                      inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                  /> 
                  <strong>€</strong>
                </div>
              </div>
              <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                <p 
                  css={{
                    display: "inline-block", 
                    width:"70%"
                  }}
                >
                  Paiements de revenus immobiliers reçus en relation avec la location d'un bien immobilier à l'exclusion de 
                  toute plus-value réalisée par la vente de tout ou partie de ce bien immobilier
                </p>
                <div style={{display:'flex'}}>
                  <InputField
                      data={form.resultatNetAnnuel4}
                      placeholder="0"
                      onChange={handleInputChange}
                      inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                  /> 
                  <strong>€</strong>
                </div> 
              </div>
            </div> 

            <div>
              <h4>Actifs liquides</h4>
              <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                <p 
                  css={{
                    display: "inline-block", 
                    width:"70%"
                  }}
                >
                  Épargner des produits pouvant être transformés en espèces dans un délai maximum de 30 jours calendaires
                </p>
                <div style={{display:'flex'}}>
                  <InputField
                      data={form.liquidites1}
                      placeholder="0"
                      onChange={handleInputChange}
                      inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                  /> 
                  <strong>€</strong>
                </div>
              </div>
              <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                <p 
                  css={{
                    display: "inline-block", 
                    width:"70%"
                  }}
                >
                  Instruments financiers négociés sur un marché réglementé
                </p>
                <div style={{display:'flex'}}>
                  <InputField
                      data={form.liquidites2}
                      placeholder="0"
                      onChange={handleInputChange}
                      inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                  /> 
                  <strong>€</strong>
                </div>
              </div>
              <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                <p 
                  css={{
                    display: "inline-block", 
                    width:"70%"
                  }}
                >
                  Actions et parts d'organismes de placement collectif offrant des droits de rachat au moins une fois par semaine
                </p>
                <div style={{display:'flex'}}>
                  <InputField
                      data={form.liquidites3}
                      placeholder="0"
                      onChange={handleInputChange}
                      inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                  /> 
                  <strong>€</strong>
                </div> 
              </div>
            </div> 

            <div>
                <h4>Engagements financiers annuels</h4>
                <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                  <p 
                    css={{
                      display: "inline-block", 
                      width:"70%"
                    }}
                  >
                    Pensions alimentaires et pensions alimentaires pour enfants
                  </p>
                  <div style={{display:'flex'}}>
                    <InputField
                        data={form.engagementsFinanciersAnnuels1}
                        placeholder="0"
                        onChange={handleInputChange}
                        inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                    /> 
                    <strong>€</strong>
                  </div>
                </div>
                <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                  <p 
                    css={{
                      display: "inline-block", 
                      width:"70%"
                    }}
                  >
                    Paiements de loyer et d'hypothèque
                  </p>
                  <div style={{display:'flex'}}>
                    <InputField
                        data={form.engagementsFinanciersAnnuels2}
                        placeholder="0"
                        onChange={handleInputChange}
                        inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                    /> 
                    <strong>€</strong>
                  </div> 
                </div>

                <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                  <p 
                    css={{
                      display: "inline-block", 
                      width:"70%"
                    }}
                  >
                    Remboursements de prêts
                  </p>
                  <div style={{display:'flex'}}>
                    <InputField
                        data={form.engagementsFinanciersAnnuels3}
                        placeholder="0"
                        onChange={handleInputChange}
                        inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                    /> 
                    <strong>€</strong>
                  </div>
                </div>

                <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                  <p 
                    css={{
                      display: "inline-block", 
                      width:"70%"
                    }}
                  >
                    Paiements des primes d'assurance
                  </p>
                  <div style={{display:'flex'}}>
                    <InputField
                        data={form.engagementsFinanciersAnnuels4}
                        placeholder="0"
                        onChange={handleInputChange}
                        inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                    /> 
                    <strong>€</strong>
                  </div> 
                </div>

                <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                  <p 
                    css={{
                      display: "inline-block", 
                      width:"70%"
                    }}
                  >
                    Paiements des frais de services publics, y compris ceux effectués pour couvrir les dépenses d'électricité, de chauffage et d'eau
                  </p>
                  <div style={{display:'flex'}}>
                    <InputField
                        data={form.engagementsFinanciersAnnuels5}
                        placeholder="0"
                        onChange={handleInputChange}
                        inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                    /> 
                    <strong>€</strong>
                  </div> 
                </div>

                <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                  <p 
                    css={{
                      display: "inline-block", 
                      width:"70%"
                    }}
                  >
                    Paiements d'abonnement au service
                  </p>
                  <div style={{display:'flex'}}>
                    <InputField
                        data={form.engagementsFinanciersAnnuels6}
                        placeholder="0"
                        onChange={handleInputChange}
                        inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                    /> 
                    <strong>€</strong>
                  </div>
                </div>

                <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                  <p 
                    css={{
                      display: "inline-block", 
                      width:"70%"
                    }}
                  >
                    Impôt sur le revenu et taxes foncières payés
                  </p>
                  <div style={{display:'flex'}}>
                    <InputField
                        data={form.engagementsFinanciersAnnuels7}
                        placeholder="0"
                        onChange={handleInputChange}
                        inputCss={{borderRadius:"5px", display: "inline-block", marginRight:"5px", maxWidth:200}}
                    /> 
                    <strong>€</strong>
                  </div>
                </div>
            </div>

            <div css={{display: "flex", flexDirection: "row", justifyContent:"space-between", alignItems:"center"}}>
              <div 
                css={{
                  display: "inline-block", 
                  width:"70%"
                }}
              >
                <strong css={{fontSize: "18px"}}>Valeur Nette</strong>
                <p>Résultat net annuel + Liquidités - Engagements financiers annuels</p>
              </div>
              <div style={{display:'flex', alignItems:'center'}}>
                <input 
                  value={valeurNette}
                  css={{
                    borderRadius:"5px !important", 
                    color: "white", 
                    display: "inline-block", 
                    marginRight:"5px", 
                    height:"40px", 
                    backgroundColor:"#085c74", 
                    borderColor:"transparent"
                  }}
                /> 
                <strong>€</strong>
              </div>
            </div>
            <SimpleButton 
              label="Reporter la valeur simulée"
              onClick={() => {
                setActive(false)
                if(onValidateSimulation) {
                  onValidateSimulation(valeurNette)
                }
              }}
            />
          </Accordion.Content>
        </Accordion>
 );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedSimulateurForm = connect(
  mapStateToProps,
  null
)(SimulateurForm);
export { ConnectedSimulateurForm as SimulateurForm };
