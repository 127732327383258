import { OAuthResponse } from "../model/authentication/oauth-response"
import { Investissementbody } from "../model/dto/body/investissement-body"
import { AllPayoutsListResponse, AskEcheanceForProjetResponse, ContratDePretResponse, DefaultResponse, EcheanceListResponse, EcheanciersProjetResponse, GenerateVirementClassiqueResponse, HistoRemboursementProjetListResponse, HistoRetraitProjetListResponse, InvestissementHistoryListForUserAndProjectResponse, InvestissementHistoryResponseListResponse, InvestResponse, NextEcheanceProjetResponse, RecentPayoutListResponse, RemboursementHistoryListResponse, TableauAmortissementResponse, TrueLayerProviderListResponse, UserDonHistoryListResponse } from "../model/dto/response"
import { UnlockFundsBody } from "../model/dto/body/unlock-funds-body"
import { DemandeDebouclageBody } from "../model/dto/body/demande-debouclage-body"
import { PayoutCitoyenbody } from "../model/dto/body/payout-citoyen-body"
import { treatHttpResponseCode, blobPostTrackedJsonFetch } from "./http-helper"
import { trackPromise } from "react-promise-tracker"
import { TrueLayerConfigBody } from "../model/dto/body/truelayer-config-body"
import { GenerateVirementClassiqueBody } from "../model/dto/body/GenerateVirementClassiqueBody"
import { SaveExonerationPASForUserBody } from "../model/dto/body/save-exoneration-PAS-for-user-body"
import { CancelInvestementByVirementReservationBody } from "../model/dto/body/cancel-investement-by-virementReservation-body"

export const investmentService = {
    invest,
    unlockFunds,
    askDebouclage,
    getEcheancier,
    getNearFutureEcheance,
    getRecentPastEcheance,
    getEcheancierProjet,
    askForPayoutCitoyen,
    getInvestmentHistory,
    getInvestmentCollectiviteHistory,
    simulateContract,
    getContratPretCit,
    getContratPretCol,
    getInvestmentHistoryByAdmin,
    getRemboursementHistoryByAdmin,
    getRetraitHistoryByAdmin,
    askForRemboursement,
    askForRemboursementAdmin,
    getRemboursementList,
    getEcheanciersProjetByProjetId,
    getInvestissementHistoryForUserAndProjet,
    getAllTruelayerProviders,
    saveTruelayerConfigForProvider,
    generateVirementClassique,
    saveExonerationPASForUser,
    getAllPayouts,
    askEcheanceForProjet,
    getNextEcheanceForProjet,
    cancelInvestementByVirementClassiqueReservation,
    cancelInvestementByVirementClassiqueReservationByAdmin,
    getUserDonHistory
}

function saveTruelayerConfigForProvider(body: TrueLayerConfigBody, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    const userId: number = oauth?.userId!;
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/invest/truelayer-config`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<InvestResponse>
    })
}

function invest(projetId: number, body: Investissementbody, oauth: OAuthResponse | undefined): Promise<InvestResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    const userId: number = oauth?.userId!;
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/invest/${projetId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<InvestResponse>
    })
}

function simulateContract(projetId: number, body: Investissementbody, oauth: OAuthResponse | undefined){
    const userId: number = oauth?.userId!;
    return blobPostTrackedJsonFetch(`/api/${userId}/invest/${projetId}/simulate-contract`,body, oauth);
}

function unlockFunds(projetId: number, body: UnlockFundsBody, oauth: OAuthResponse | undefined): Promise<InvestResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/${projetId}/unlock-funds`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<InvestResponse>
    })
}

function askDebouclage(projetId: number, body: DemandeDebouclageBody, oauth: OAuthResponse | undefined): Promise<InvestResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/${projetId}/ask-debouclage`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status);
        return response.json() as Promise<InvestResponse>
    })
}

function getEcheancier(idInvestissement: number, oauth: OAuthResponse | undefined): Promise<TableauAmortissementResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/echeancier/user/${idInvestissement}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TableauAmortissementResponse>
    })
}

function getContratPretCit(idInvestissement: number, oauth: OAuthResponse | undefined): Promise<ContratDePretResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/contrat-de-pret-citoyen/${idInvestissement}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<ContratDePretResponse>
    })
}

function getContratPretCol(projetId: number, oauth: OAuthResponse | undefined): Promise<ContratDePretResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/contrat-de-pret-collectivite/${projetId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<ContratDePretResponse>
    })
}

function getEcheancierProjet(projetId: number, oauth: OAuthResponse | undefined): Promise<TableauAmortissementResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/echeancier/projet/${projetId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TableauAmortissementResponse>
    })
}

function getNearFutureEcheance(oauth: OAuthResponse | undefined): Promise<EcheanceListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/echeance/histo/future`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<EcheanceListResponse>
    }))
}

function getRecentPastEcheance(oauth: OAuthResponse | undefined): Promise<EcheanceListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/echeance/histo/past`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<EcheanceListResponse>
    }))
}

function askForPayoutCitoyen(body: PayoutCitoyenbody, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/payout/citoyen`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    })
}

function getAllPayouts(oauth: OAuthResponse | undefined): Promise<AllPayoutsListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/payout`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<AllPayoutsListResponse>
    }))
}

function getInvestmentHistory(userId: number | undefined, oauth: OAuthResponse | undefined): Promise<InvestissementHistoryResponseListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/user/history`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<InvestissementHistoryResponseListResponse>
    }))
}

function getInvestmentCollectiviteHistory(etablissementId: number | undefined, oauth: OAuthResponse | undefined, isDonTabClicked: boolean): Promise<InvestissementHistoryResponseListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/collectivite/${etablissementId}/history?isDon=${isDonTabClicked}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<InvestissementHistoryResponseListResponse>
    })
}

function getInvestmentHistoryByAdmin(currentUserId: number | undefined, oauth: OAuthResponse | undefined): Promise<InvestissementHistoryResponseListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/user/history/` + currentUserId, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<InvestissementHistoryResponseListResponse>
    }))
}

function getRemboursementHistoryByAdmin(currentUserId: number | undefined, oauth: OAuthResponse | undefined): Promise<HistoRemboursementProjetListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/remboursement/user/history/` + currentUserId, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<HistoRemboursementProjetListResponse>
    }))
}

function getRetraitHistoryByAdmin(currentUserId: number | undefined, oauth: OAuthResponse | undefined): Promise<HistoRetraitProjetListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/retrait/user/history/` + currentUserId, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<HistoRetraitProjetListResponse>
    }))
}

function askForRemboursement(idInvestissement: number, oauth: OAuthResponse | undefined){
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/investement/${idInvestissement}/remboursement`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    })
}

function askForRemboursementAdmin(idInvestissement: number, oauth: OAuthResponse | undefined){
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/investement/admin/${idInvestissement}/remboursement`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    })
}

function getRemboursementList(oauth: OAuthResponse | undefined){
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/allRemboursement`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<RemboursementHistoryListResponse>
    }))
}

function getEcheanciersProjetByProjetId(projetId: number, oauth: OAuthResponse | undefined): Promise<EcheanciersProjetResponse>{
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/echeanciers/projet/${projetId}`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<EcheanciersProjetResponse>
    }))
}

function getInvestissementHistoryForUserAndProjet(projetId: number, oauth: OAuthResponse | undefined): Promise<InvestissementHistoryListForUserAndProjectResponse>{
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/user/projet/${projetId}/investissementHistory`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<InvestissementHistoryListForUserAndProjectResponse>
    }))
}

function getAllTruelayerProviders(oauth: OAuthResponse | undefined): Promise<TrueLayerProviderListResponse>{
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/invest/all-banks-list`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<TrueLayerProviderListResponse>
    }))
}

function generateVirementClassique(oauth: OAuthResponse | undefined, projetId: number, body: GenerateVirementClassiqueBody): Promise<GenerateVirementClassiqueResponse> {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    };

    const userId: number = oauth?.userId!;
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/invest/virement-classique`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<GenerateVirementClassiqueResponse>
    })
}

function saveExonerationPASForUser(body: SaveExonerationPASForUserBody, oauth: OAuthResponse | undefined):Promise<DefaultResponse> {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    }
    
    const userId: number = oauth?.userId!;
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/user/save-exoneration-pas`, requestOptions)
    .then(( response ) => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<DefaultResponse>
    })
}

function askEcheanceForProjet(projetId: number, oauth: OAuthResponse | undefined):Promise<AskEcheanceForProjetResponse> {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
    }
    
    const userId: number = oauth?.userId!;
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/invest/${projetId}/ask-echeance-projet`, requestOptions)
    .then(( response ) => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<AskEcheanceForProjetResponse>
    })
}

function getNextEcheanceForProjet(projetId: number, oauth: OAuthResponse | undefined):Promise<NextEcheanceProjetResponse> {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
    }
    
    const userId: number = oauth?.userId!;
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/invest/${projetId}/next-echeance`, requestOptions)
    .then(( response ) => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<NextEcheanceProjetResponse>
    })
}

function cancelInvestementByVirementClassiqueReservation(body: CancelInvestementByVirementReservationBody, oauth: OAuthResponse | undefined): Promise<DefaultResponse>{
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
        body: JSON.stringify(body)
    }
    const userId: number = oauth?.userId!;
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/invest/user/cancel-virement-reservation`, requestOptions)
    .then(( response ) => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<NextEcheanceProjetResponse>
    })
}

function cancelInvestementByVirementClassiqueReservationByAdmin(oauth: OAuthResponse | undefined, idInvestissement: number): Promise<DefaultResponse>{
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        },
    }
    const userId: number = oauth?.userId!;
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${userId}/invest/user/cancel-virement-reservation-by-admin/${idInvestissement}`, requestOptions)
    .then(( response ) => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<NextEcheanceProjetResponse>
    })
}

function getUserDonHistory(etablissementId: number | undefined, oauth: OAuthResponse | undefined): Promise<UserDonHistoryListResponse> {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + oauth?.access_token
        }
    };

    return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${etablissementId}/invest/user/don-history`, requestOptions)
    .then(response => {
        treatHttpResponseCode(response.status)
        return response.json() as Promise<UserDonHistoryListResponse>
    }))
}
