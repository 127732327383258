import React, { useEffect, useMemo, useState } from "react"
import { Divider } from "semantic-ui-react"
import { BankBranch } from "../../model/entities/bank-branch"
import { BankProvider } from "../../model/entities/bank-provider"

type Props = {
    branch: BankBranch,
    selectedBranchId?: string
    onSelectBranch?: (branchId: string | undefined) => void
}

export function BranchProviderSelectorNew({branch, selectedBranchId, onSelectBranch}: Props) {
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        if(branch.Id === selectedBranchId) {
            setIsSelected(true)
        }
        else {
            setIsSelected(false)
        }
    }, [selectedBranchId])

    const onClick = () => {
        if(!isSelected) {
            setIsSelected(true)
            if(onSelectBranch) {
                onSelectBranch(branch.Id)
            }
        }
        else {
            setIsSelected(false)
            if(onSelectBranch) {
                onSelectBranch(undefined)
            }
        }
    }

    const cssUnselected = {
        border: "1px solid #ccc"
    }

    const cssSelected = {
        color: "black",
        border: "2px solid #30BF71"
    }

    const css = useMemo(() => {
        if(isSelected) {
            return cssSelected
        }
        else {
            return cssUnselected
        }
    }, [isSelected])

    return(
        <div 
            css={{
                ...css, 
                padding: "10px 0px",
                cursor: "pointer",
                margin:"5px 0px"
            }}
            onClick={onClick}
        >
            <h4>{branch.Designation}</h4>
        </div>
    )
}