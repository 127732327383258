import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Application from "./Application";
import * as serviceWorker from "./serviceWorker";
import "semantic-ui-less/semantic.less";
import "@emotion/core";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<Application />);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
