import React, { useCallback, useMemo, useState } from "react";
import Cropper from "react-easy-crop";

import { Dropzone } from "./dropzone";
import { createImage, getCroppedImg } from "./utils";

export type ImageToUpload = { name: string; type: string; dataUrl: string };

type Props = {
  onFileUpload: (image: ImageToUpload) => void;
  onCropChanged?:() => void,
  aspect?: number;
  minimumResolution?: number;
  directUpload?: boolean
};

const EMPTY_IMAGE = { name: "", type: "", dataUrl: "" };
const MIN_ZOOM = 1.5;
const MAX_ZOOM = 3;

export function ImageUpload({
  onFileUpload,
  onCropChanged,
  minimumResolution,
  aspect = 4 / 3,
  directUpload = true
}: Props) {
  const [image, setImage] = useState(EMPTY_IMAGE);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [maxZoom, setMaxZoom] = useState(MAX_ZOOM);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isButtonVisible, setIsButtonVisible] = useState('block')

  const onCropComplete = useCallback((_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
    setIsButtonVisible('block')
    if(onCropChanged) {
      onCropChanged()
    } 
  }, []);

  const buttonLabel = useMemo(() => {
    if(directUpload) {
      return 'Upload'
    }
    else {
      return 'Cliquez ici pour valider l\'image dans cet état'
    }
  }, [])

  const cssButton = useMemo(() => {
    if(!directUpload) {
      return {
        display: 'flex',
        justifyContent: 'center'
      }
    }
    else {
      return {}
    }
  }, [])

  async function onFileChange(file: File) {
    const imageDataUrl = URL.createObjectURL(file);

    setImage({ name: file.name, type: file.type, dataUrl: imageDataUrl });
    setZoom(1);

    if (minimumResolution) {
      const image = await createImage(imageDataUrl);
      const smallestImageResolution = Math.min(image.width, image.height);
      setMaxZoom(
        Math.max(
          MIN_ZOOM,
          Math.min(MAX_ZOOM, smallestImageResolution / minimumResolution)
        )
      );
    }
  }

  async function onUploadClick() {
    if (!image.dataUrl) {
      return;
    }

    setIsButtonVisible('none')

    const croppedImage = await getCroppedImg(image.dataUrl, croppedAreaPixels);
    onFileUpload({ name: image.name, type: image.type, dataUrl: croppedImage });
  }

  return image.dataUrl ? (
    <div>
      <div css={{ position: "relative", width: "100%", height: 600 }}>
        <Cropper
          image={image.dataUrl}
          crop={crop}
          zoom={zoom}
          maxZoom={maxZoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid={true}
          style={{ mediaStyle: { height: "auto", width: "auto" } }}
        />
      </div>
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          background: "#7f7f7f",
          padding: "10px",
          color: "white",
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
          }}
        >
          <label>Zoom:</label>
          <input
            type="range"
            value={zoom}
            min="1"
            max={maxZoom}
            step={0.1}
            onChange={(evt) => setZoom(Number(evt.target.value) || 1)}
          />
        </div>
        <div css={{ cursor: "pointer" }} onClick={() => setImage(EMPTY_IMAGE)}>
          Uploader une autre image...
        </div>
      </div>
      <div className="container-app button-action" css={cssButton}>
        <button
          type="button"
          className="btn btn-primary"
          onClick={onUploadClick}
          css={{
            display: isButtonVisible
          }}
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  ) : (
    <div css={{ display: "flex", justifyContent: "center" }}>
      <Dropzone onFileAdded={onFileChange} />
    </div>
  );
}
