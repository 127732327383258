
import React from "react";

interface SimpleButtonProps {
    label: string;
    className?: string;
    onClick?: () => void;
}

export function SimpleButton({ label, className, onClick }: SimpleButtonProps) {

    const handleButtonClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className={"button-action center " + className}>
            <button 
                className={"btn btn-primary"} 
                onClick={handleButtonClick}
                type="button"
            >
                {label}
            </button>
        </div>
    );
}
