import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { mangopayService } from "../../../services/mangopay-service"
import { ApplicationState } from "../../../store"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { PaginatedTable } from "../../generic/paginated-table/paginated-table"
import { MangoPayEventHistory } from "../../../model/entities/mangopay-event-history"

type Props = {

}

export function TraitementMangoPayDashboard({}: Props) {
    const[eventHistory, setEventHistory] = useState<MangoPayEventHistory[]>([])

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

    useEffect(() => {
        mangopayService.getMangoPayTreatedEventsHistory(loginProps?.oauth)
        .then(response => {
            if(response) {
                setEventHistory(response.History!)
            }
        })
    }, [])

    return (
        <>
            <PaginatedTable 
                elements={eventHistory}
                elementsPerPage={10}
                headers={
                    [
                        'Id',
                        'Date de traitement', 
                        'Evènement reçu', 
                        'Id de la ressource MangoPay', 
                        'Statut de l\'opération', 
                        'Message'
                    ]
                }
                columnSelector={(element: MangoPayEventHistory) => {
                    return {
                        values: [
                            element.MangoPaySimpleHookMessageId,
                            element.DateEvent,
                            element.EventCode,
                            element.LongResourceId,
                            element.StatutMangoPayEventCode,
                            element.ErrorMessage
                        ]
                    }
                }}
            />
        </>
    )
}