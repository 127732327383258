export interface MangoPayEventReference {
    Code?: string,
    Message?: string
}

const PAYIN_NORMAL_CREATED = "PAYIN_NORMAL_CREATED";
const PAYIN_NORMAL_SUCCEEDED = "PAYIN_NORMAL_SUCCEEDED";
const PAYIN_NORMAL_FAILED = "PAYIN_NORMAL_FAILED";
const TRANSFER_NORMAL_CREATED = "TRANSFER_NORMAL_CREATED";
const TRANSFER_NORMAL_SUCCEEDED = "TRANSFER_NORMAL_SUCCEEDED";
const TRANSFER_NORMAL_FAILED = "TRANSFER_NORMAL_FAILED";
const PAYOUT_NORMAL_CREATED = "PAYOUT_NORMAL_CREATED";
const PAYOUT_NORMAL_SUCCEEDED = "PAYOUT_NORMAL_SUCCEEDED";
const PAYOUT_NORMAL_FAILED = "PAYOUT_NORMAL_FAILED";
const KYC_CREATED = "KYC_CREATED";
const KYC_SUCCEEDED = "KYC_SUCCEEDED";
const KYC_FAILED = "KYC_FAILED";
const KYC_VALIDATION_ASKED = "KYC_VALIDATION_ASKED";
const KYC_OUTDATED = "KYC_OUTDATED";
const MANDATE_CREATED = "MANDATE_CREATED";
const MANDATE_FAILED = "MANDATE_FAILED";
const MANDATE_ACTIVATED = "MANDATE_ACTIVATED";
const MANDATE_SUBMITTED = "MANDATE_SUBMITTED";
const MANDATE_EXPIRED = "MANDATE_EXPIRED";
const USER_KYC_REGULAR = "USER_KYC_REGULAR";
const USER_KYC_LIGHT = "USER_KYC_LIGHT";
const PAYOUT_REFUND_CREATED = "PAYOUT_REFUND_CREATED";
const PAYOUT_REFUND_SUCCEEDED = "PAYOUT_REFUND_SUCCEEDED";
const PAYOUT_REFUND_FAILED = "PAYOUT_REFUND_FAILED";

export const MangoPayEventReference = {
    PAYIN_NORMAL_CREATED,
    PAYIN_NORMAL_SUCCEEDED,
    PAYIN_NORMAL_FAILED,
    TRANSFER_NORMAL_CREATED,
    TRANSFER_NORMAL_SUCCEEDED,
    TRANSFER_NORMAL_FAILED,
    PAYOUT_NORMAL_CREATED,
    PAYOUT_NORMAL_SUCCEEDED,
    PAYOUT_NORMAL_FAILED,
    KYC_CREATED,
    KYC_SUCCEEDED,
    KYC_FAILED,
    KYC_VALIDATION_ASKED,
    KYC_OUTDATED,
    MANDATE_CREATED,
    MANDATE_FAILED,
    MANDATE_ACTIVATED,
    MANDATE_SUBMITTED,
    MANDATE_EXPIRED,
    USER_KYC_REGULAR,
    USER_KYC_LIGHT,
    PAYOUT_REFUND_CREATED,
    PAYOUT_REFUND_SUCCEEDED,
    PAYOUT_REFUND_FAILED
}