import React from "react";
import { connect, useDispatch } from "react-redux";
import { useState } from "react";
import { Form } from "semantic-ui-react";
import { AuthenticationState } from "../../model/state/authentication-state";
import { userService } from "../../services/authentication-redux-service";
import { SubmitButton } from "../generic/submit-button";
import { RegisterAdressePostaleBody } from "../../model/dto/body/register-adresse-postale-body";
import { SimpleButton } from "../generic/simple-button";
import { ApplicationState } from "../../store";
import { OnboardingCitoyenResponse, ProfilCode, UserInfoResponse } from "../../model/dto/response";
import { CollectiviteSelector } from "../generic/collectivite-selector/collectivite-selector";
import { Collectivite } from "../../model/entities/profil";
import { FormErrorHeader } from "../generic/form-error-header";
import { InputField } from "../generic/input-field";
import { RegisterAdressePostaleAdminBody } from "../../model/dto/body/register-adresse-postale-admin-body";
import { AuthenticationAction, CHANGE_IDENTITY } from "../../actions/authentication-actions";
import InformationBandeau from "../generic/information-bandeau/information-bandeau";
import { WorkflowType } from "../../model/entities/workflow-type";
import { referenceService } from "../../services/reference-service";
import { FlowStep } from "../../model/entities/flow-step";

type FieldData = {
  value?: any;
  name?: string;
  isValid?: boolean;
};

type FormData = {
  libelleVoie: FieldData;
  adresseLigne2: FieldData;
  codePostal: FieldData;
  communeId: FieldData;
};

type Props = {
  userInfo?: UserInfoResponse;
  loginProps?: AuthenticationState;
  isOnboarding?: boolean;
  userId?: number
  onSubmitSuccess: (response: Response) => void;
  onsubmitError?: () => void;
  onSkipStep?: (response: Response) => void;
};

type State = {
  form: FormData;
};

function SubscriptionAdressePostaleForm({
  userInfo,
  loginProps,
  isOnboarding,
  userId,
  onSubmitSuccess,
  onsubmitError,
  onSkipStep,
}: Props) {
  const validateMandatory = (value: any): boolean => {
    return value && value !== "";
  };

  const validatePostalCode = (value: any): boolean => {
    const regex = /^[0-9]{5}$/g;
    return regex.test(value);
  };

  const [form, setForm] = useState<FormData>({
    libelleVoie: {
      value: userInfo?.LibelleVoie || "",
      name: "libelleVoie",
      isValid: validateMandatory(userInfo?.LibelleVoie),
    },
    adresseLigne2: {
      value: userInfo?.ComplementAdresse || "",
      name: "adresseLigne2",
      isValid: true,
    },
    codePostal: {
      value: userInfo?.CodePostal || "",
      name: "codePostal",
      isValid: validateMandatory(userInfo?.CodePostal),
    },
    communeId: {
      value: userInfo?.CommuneId || "",
      name: "communeId",
      isValid: validateMandatory(userInfo?.CommuneId),
    },
  });

  const [isFormError, setIsFormError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useDispatch()

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName?: string,
    isValid?: boolean
  ): void => {
    const fieldValue: string = e.target.value;
    const newField: FieldData = {
      [fieldName!]: { value: fieldValue, name: fieldName, isValid: isValid },
    };

    setForm({ ...form, ...newField });
    setIsFormError(false);
  };

  const isFormValid = (): boolean => {
    return (
      form.libelleVoie.isValid! &&
      form.adresseLigne2.isValid! &&
      form.codePostal.isValid! &&
      form.communeId.isValid!
    );
  };

  const onFormNotValid = (): void => {
    setIsFormError(true);
  };

  const onSubmitError = (): void => {};


  const onSubmitAdressePostaleSuccess = (response: OnboardingCitoyenResponse) => {
    if(response.UserInfo) {
      const action: AuthenticationAction = { type: CHANGE_IDENTITY, user: response.UserInfo }
      localStorage.setItem("user_info", JSON.stringify(response.UserInfo))
      dispatch(action)
    }
    onSubmitSuccess(response)
  }

  const body: RegisterAdressePostaleBody = {
    LibelleVoie: form.libelleVoie.value,
    ComplementAdresse: form.adresseLigne2.value,
    CodePostal: form.codePostal.value,
    CommuneId: form.communeId.value,
    WorkflowTypeCode: WorkflowType.ONBOARDING_CITOYEN
  }

  const bodyAdmin: RegisterAdressePostaleAdminBody = {
    UserId: userId,
    LibelleVoie: form.libelleVoie.value,
    ComplementAdresse: form.adresseLigne2.value,
    CodePostal: form.codePostal.value,
    CommuneId: form.communeId.value,
  }

  const nextAdressePostaleStepKey = () => {
    const body = {
        WorkflowTypeCode : WorkflowType.ONBOARDING_CITOYEN,
        CurrentFlowStep : FlowStep.ADRESSE_POSTALE,
        RessourceId:  loginProps?.user?.UserId!,
        IsSkiped: true
    }
    return referenceService.getNextStep(body).then(response => {
        onSkipStep && onSkipStep(response)})
  }
  return (
    <Form>
      <FormErrorHeader
        message="Veuillez corriger les champs en erreur"
        displayed={isFormError}
      />
      <InformationBandeau 
        type="information"
        icon={{name:'info circle', size:"big", color:"blue"}}
        message="Votre adresse est nécessaire pour permettre de préremplir votre déclaration fiscale, vous épargnant ainsi du temps et des tracas. Ces informations ne sont pas partagées avec des annonceurs."
      />
      <Form.Group>
        <Form.Field width={16}>
          <InputField
            data={form.libelleVoie}
            label="Adresse*"
            autocomplete="address-line1"
            submitted={formSubmitted}
            onChange={handleInputChange}
            error="Merci de renseigner votre adresse"
            validateField={validateMandatory}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field width={16}>
        <InputField
          data={form.adresseLigne2}
          label="Complément d'adresse"    
          autocomplete="address-line2"
          submitted={formSubmitted}
          onChange={handleInputChange}
        />
      </Form.Field>
      <Form.Group>
        <Form.Field width={8}>
          <InputField
            data={form.codePostal}
            label="Code postal*"
            submitted={formSubmitted}
            onChange={handleInputChange}
            error="Merci de renseigner votre code postal à 5 chiffres"
            validateField={validatePostalCode}
          />
        </Form.Field>
        <Form.Field width={8}>
          <CollectiviteSelector
            type={Collectivite.COMMUNE}
            label="Ville*"
            useCodeUnique={false}
            submitted={formSubmitted}
            initialCode={form.communeId.value}
            error="Merci de renseigner votre ville"
            onChange={(value: any, isValid: boolean) => {
              setForm({
                ...form,
                communeId: {
                  value: value,
                  name: "communeId",
                  isValid: isValid,
                },
              });
              setIsFormError(false);
            }}
            validateField={validateMandatory}
            autocomplete="postal-code"
          />
        </Form.Field>
      </Form.Group>
      <div css={{paddingBottom : "10px"}}>*champ obligatoire</div>

      <div className="button-bar">
        <SubmitButton
          data={body}
          label="Valider"
          onPreSubmit={() => {
            setFormSubmitted(true);
          }}
          action={() => {
            if (loginProps?.oauth?.profilCode === ProfilCode.INVESTISSEUR || loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET)
            {
              return userService.registerAdressePostale(body, loginProps?.oauth?.userId, loginProps?.oauth)
            }
            else
            {
              return userService.registerAdressePostaleByAdmin(bodyAdmin, loginProps?.oauth?.userId, loginProps?.oauth)
            }
          }}
          validateForm={isFormValid}
          onFormNotValid={onFormNotValid}
          onActionSuccess={onSubmitAdressePostaleSuccess}
          onActionFailure={onSubmitError}
        />
        {isOnboarding && (
          <SimpleButton
            label="Passer"
            onClick={() => nextAdressePostaleStepKey()}
          />
        )}
      </div>
    </Form>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedSubscriptionAdressePostaleForm = connect(
  mapStateToProps,
  null
)(SubscriptionAdressePostaleForm);
export { ConnectedSubscriptionAdressePostaleForm as SubscriptionAdressePostaleForm };
