import React from "react"

type Props = {
    message: string
    displayed: boolean
    withoutMarginBottom?: boolean
}

export function FormErrorHeader({message, displayed, withoutMarginBottom}: Props) {
    return (
        <>
        {
            displayed ? (
            <div 
                css={{
                    color: "red",
                    border: "solid red 1px",
                    padding: "15px",
                    marginBottom: withoutMarginBottom ? "0" : "30px"
                }}
            >{message}</div>
            ) : (
                <div></div>
            )
        }
        </>
        
    )
}