import ImageGallery from 'react-image-gallery';
import React, { useEffect, useRef, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactImageGallery from 'react-image-gallery';

interface MyGalleryProps {
    getRessourceId: ((id: number) => void)
    source?: number[];
    projetId?: number;
}

export function MyGallery({getRessourceId,source, projetId }: MyGalleryProps){
  let imageGallery = useRef() as React.MutableRefObject<ReactImageGallery>

  useEffect(()=>{
      getRessourceId(imageGallery.current.getCurrentIndex());
  })
  
  if (!source) {
        return null;
  }

  const onSlideChange = ()=>{
      getRessourceId(imageGallery.current.getCurrentIndex());
  }
  
  const images = source.filter((e) => e !== null).map((e) => ({
       original: `${process.env.REACT_APP_BASE_URL}/public/projets/${projetId}/picture/${e}`,
       thumbnail: `${process.env.REACT_APP_BASE_URL}/public/projets/${projetId}/picture/${e}`
  }))

  return (
        <ImageGallery ref = {imageGallery} items={images} thumbnailPosition="left" disableThumbnailScroll={true} showPlayButton ={false} onSlide={onSlideChange}/>
  )

}