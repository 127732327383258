import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Checkbox, Form, Modal } from "semantic-ui-react";
import { AnnulationLeveeBody } from "../../../model/dto/body/annulation-levee-body";
import { ModificationLeveeBody } from "../../../model/dto/body/modification-levee-body";
import { ReouvertureLeveeBody } from "../../../model/dto/body/reouverture-levee-body";
import { SuspendreLeveeBody } from "../../../model/dto/body/suspendre-levee-body";
import { ProfilCode, TableauAmortissementResponse } from "../../../model/dto/response";
import { StatutProjet } from "../../../model/entities/statut-projet";
import Projet from "../../../model/projet";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { projetService } from "../../../services/projet-service";
import { ApplicationState } from "../../../store";
import { FormErrorHeader } from "../../generic/form-error-header";
import { SubmitButton } from "../../generic/submit-button";
import { investmentService } from "../../../services/investment-service";
import { ProlongationForm } from "../forms/prolongation-form";

type FieldData = {
    name?: string;
    value?: any;
    isValid?: boolean;
};

type Form = {
    raisonSuspension: FieldData;
    raisonReouverture: FieldData;
    raisonModification: FieldData;
    raisonAnnulation: FieldData;
};

type Props = {
    profile: ProfilCode;
    project: Projet;
    isPageDon?: boolean;
    reload: () => void;
};

export default function ActionAdminModificationFiche({ profile, project, isPageDon, reload }: Props) {
    const [firstOpen, setFirstOpen] = useState(false);
    const [isSuspensionChecked, setIsSuspensionChecked] = useState(false);
    const [secondOpen, setSecondOpen] = useState(false);
    const [isModificationChecked, setIsModificationChecked] = useState(false);
    const [thirdOpen, setThirdOpen] = useState(false);
    const [isCanceledChecked, setIsCanceledChecked] = useState(false);
    const [lastOpen, setLastOpen] = useState(false);
    const [isEndSuspensionChecked, setIsEndSuspensionChecked] = useState(false);
    const [isEdited, setIsEdited] = useState(false)
    const [form, setForm] = useState<Form>({
        raisonSuspension: {
            value: project ? project.RaisonSuspension : "",
            name: "raisonSuspension",
            isValid: true,
        },
        raisonReouverture: {
            value: project ? project.RaisonReouverture : "",
            name: "raisonReouverture",
            isValid: true,
        },
        raisonModification: {
            value: project ? project.RaisonModification : "",
            name: "raisonModification",
            isValid: true,
        },
        raisonAnnulation: {
            value: project ? project.RaisonAnnulation : "",
            name: "raisonAnnulation",
            isValid: true,
        },
    });
    const loginProps: AuthenticationState = useSelector<
        ApplicationState,
        AuthenticationState
    >((state) => state.authentication);
    const [suspensionFormSubmitted, setSuspensionFormSubmitted] =
        useState(false);
    const [reouvertureFormSubmitted, setReouvertureFormSubmitted] =
        useState(false);
    const [modificationFormSubmitted, setModificationFormSubmitted] =
        useState(false);
    const [annulationFormSubmitted, setAnnulationFormSubmitted] =
        useState(false);

    const [isFormError, setIsFormError] = useState(false);

    const bodySuspension: SuspendreLeveeBody = {
        RaisonSuspension: form.raisonSuspension.value,
    };

    const bodyReouverture: ReouvertureLeveeBody = {
        RaisonReouverture: form.raisonReouverture.value,
    };

    const bodyModification: ModificationLeveeBody = {
        RaisonModification: form.raisonModification.value,
    };

    const bodyAnnulation: AnnulationLeveeBody = {
        RaisonAnnulation: form.raisonAnnulation.value,
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
        fieldName?: string
    ): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = {
            [fieldName!]: { value: fieldValue, name: fieldName },
        };

        setForm({ ...form, ...newField });
        setIsFormError(false);
    };
    const validateMandatory = (value: any): boolean => {
        if (!value || value === "") {
            return false;
        }
        return true;
    };

    const isFormModificationValid = (): boolean => {
        return (
            validateMandatory(form.raisonModification.value) &&
            form.raisonModification.value &&
            isModificationChecked
        );
    };

    const isFormSuspensionValid = (): boolean => {
        return (
            validateMandatory(form.raisonSuspension.value) &&
            form.raisonSuspension.value &&
            isSuspensionChecked
        );
    };

    const isFormEndSuspensionValid = (): boolean => {
        return (
            validateMandatory(form.raisonReouverture.value) &&
            form.raisonReouverture.value &&
            isEndSuspensionChecked
        );
    };

    const isFormAnnulationValid = (): boolean => {
        return (
            validateMandatory(form.raisonAnnulation.value) &&
            form.raisonAnnulation.value &&
            isCanceledChecked
        );
    };

    const onFormNotValid = (): void => {
        setIsFormError(true);
    };

    const onDownloadSuccess = (response: TableauAmortissementResponse) => {
        const byteCharacters = atob(response.File!);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "" });

        const aEle = document.createElement("a"); // Create a label
        const href = window.URL.createObjectURL(blob); // Create downloaded link
        aEle.href = href;
        aEle.download = "echeancier.xlsx"; // File name after download
        document.body.appendChild(aEle);
        aEle.click(); // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href);
    };

    const authorizedStatusDownloadEcheancier = (code: string) => {
        const authorizedStatus = [
            StatutProjet.DEBOUCLAGE_POSSIBLE,
            StatutProjet.DEBOUCLAGE_DEMANDE,
            StatutProjet.DEBOUCLAGE_FINALISE,
            StatutProjet.DEBOUCLAGE_VALIDE,
            StatutProjet.FINANCE,
        ];
        return authorizedStatus.find((statut) => statut === code);
    };

    return (
        <div>
            {profile === ProfilCode.VILLYZ &&
            (isPageDon ? project.StatutDonProjetCode : project.StatutProjetCode) === (StatutProjet.PUBLIE || StatutProjet.SUSPENDED)
                 && (
                    <div
                        css={{
                            padding: "20px",
                            marginBottom: "5px",
                            fontSize: "1.3em",
                        }}
                    >
                        <Button
                            css={{
                                backgroundColor: "#D1E9F7 !important",
                                borderColor: "#D9D286 !important",
                                fontSize: "16px !important",
                                fontStyle: "bold !important",
                                color: "#517FB1 !important",
                                width: "185px",
                            }}
                            onClick={() => setSecondOpen(true)}
                        >
                            Mise à jour du KIIS
                        </Button>
                        <Modal
                            onClose={() => {
                                setSecondOpen(false);
                                setIsFormError(false);
                                setModificationFormSubmitted(false);
                            }}
                            onOpen={() => setSecondOpen(true)}
                            open={secondOpen}
                        >
                            <Modal.Header>
                                Modification substantielle
                            </Modal.Header>
                            <Modal.Content>
                                <FormErrorHeader
                                    message="Veuillez corriger les champs en erreur"
                                    displayed={isFormError}
                                />
                                <p>
                                    Quelle est l'objet de la modification
                                    substantielle ?
                                </p>

                                <textarea
                                    css={{
                                        width: "100%",
                                        borderRadius: "4px",
                                        height: "60px",
                                        borderColor: "lightgrey",
                                    }}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            form.raisonModification.name
                                        )
                                    }
                                />
                                {!form.raisonModification.value &&
                                    modificationFormSubmitted && (
                                        <div
                                            css={{
                                                color: "#F03E3E",
                                                fontSize: "10px",
                                                marginTop: "4px",
                                            }}
                                        >
                                            Vous devez préciser la raison de la
                                            fin de la modification
                                        </div>
                                    )}
                                <Checkbox
                                    required
                                    name="modification"
                                    value="ok"
                                    onClick={(event, props) => {
                                        setIsModificationChecked(
                                            props.checked!
                                        );
                                    }}
                                    label={{
                                        children: () => (
                                            <span>
                                                Je confirme la modification
                                                substantielle
                                            </span>
                                        ),
                                    }}
                                />
                                {!isModificationChecked &&
                                    modificationFormSubmitted && (
                                        <div
                                            css={{
                                                color: "#F03E3E",
                                                fontSize: "10px",
                                                marginTop: "4px",
                                            }}
                                        >
                                            Veuillez renseigner ce champ
                                        </div>
                                    )}
                                <Modal.Actions>
                                    <div
                                        css={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "15px",
                                        }}
                                    >
                                        <SubmitButton
                                            label="Confirmer la modification"
                                            onPreSubmit={() =>
                                                setModificationFormSubmitted(
                                                    true
                                                )
                                            }
                                            action={() =>
                                                projetService.modificationLevee(
                                                    bodyModification,
                                                    project.Id,
                                                    loginProps.oauth
                                                )
                                            }
                                            data={bodyModification}
                                            validateForm={
                                                isFormModificationValid
                                            }
                                            onFormNotValid={onFormNotValid}
                                            style={{ border: "none" }}
                                        />
                                    </div>
                                </Modal.Actions>
                            </Modal.Content>
                        </Modal>
                        {(isPageDon ? project.StatutDonProjetCode : project.StatutProjetCode) ===
                            (StatutProjet.PUBLIE || StatutProjet.SUSPENDED) && (
                            <Button
                                css={{
                                    backgroundColor: "#F7F4D4 !important",
                                    borderColor: "#D9D286 !important",
                                    fontSize: "16px !important",
                                    fontStyle: "bold !important",
                                    color: "#917027 !important",
                                    width: "185px",
                                }}
                                onClick={() => setFirstOpen(true)}
                            >
                                Suspendre
                            </Button>
                        )}

                        {(isPageDon ? project.StatutDonProjetCode : project.StatutProjetCode) ===
                            StatutProjet.SUSPENDED && (
                            <Button
                                css={{
                                    backgroundColor: "#F7F4D4 !important",
                                    borderColor: "#D9D286 !important",
                                    fontSize: "16px !important",
                                    fontStyle: "bold !important",
                                    color: "#517FB1 !important",
                                }}
                                onClick={() => setLastOpen(true)}
                            >
                                Réouvrir la levée
                            </Button>
                        )}

                        <Modal
                            onClose={() => {
                                setLastOpen(false);
                                setIsFormError(false);
                                setReouvertureFormSubmitted(false);
                            }}
                            onOpen={() => setLastOpen(true)}
                            open={lastOpen}
                        >
                            <Modal.Header>Fin du Suspension</Modal.Header>
                            <Modal.Content>
                                <FormErrorHeader
                                    message="Veuillez corriger les champs en erreur"
                                    displayed={isFormError}
                                />
                                <p>
                                    Pour quelle raison la suspension est
                                    terminée ?
                                </p>
                                <textarea
                                    css={{
                                        width: "100%",
                                        borderRadius: "4px",
                                        height: "60px",
                                        borderColor: "lightgrey",
                                    }}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            form.raisonReouverture.name
                                        )
                                    }
                                />
                                {!form.raisonReouverture.value &&
                                    reouvertureFormSubmitted && (
                                        <div
                                            css={{
                                                color: "#F03E3E",
                                                fontSize: "10px",
                                                marginTop: "4px",
                                            }}
                                        >
                                            Vous devez préciser la raison de la
                                            fin de la réouverture
                                        </div>
                                    )}
                                <Checkbox
                                    required
                                    name="finSuspension"
                                    value="ok"
                                    onClick={(event, props) => {
                                        setIsEndSuspensionChecked(
                                            props.checked!
                                        );
                                    }}
                                    label={{
                                        children: () => (
                                            <span>
                                                Je confirme la fin de la
                                                suspension du projet, les
                                                investissements seront de
                                                nouveau possibles
                                            </span>
                                        ),
                                    }}
                                />
                                {!isEndSuspensionChecked &&
                                    reouvertureFormSubmitted && (
                                        <div
                                            css={{
                                                color: "#F03E3E",
                                                fontSize: "10px",
                                                marginTop: "4px",
                                            }}
                                        >
                                            Veuillez renseigner ce champ
                                        </div>
                                    )}
                                <Modal.Actions>
                                    <div
                                        css={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "15px",
                                        }}
                                    >
                                        <SubmitButton
                                            label="Fin suspension"
                                            onPreSubmit={() =>
                                                setReouvertureFormSubmitted(
                                                    true
                                                )
                                            }
                                            action={() =>
                                                projetService.reouvertureLevee(
                                                    bodyReouverture,
                                                    project.Id,
                                                    loginProps.oauth
                                                )
                                            }
                                            data={bodyReouverture}
                                            validateForm={
                                                isFormEndSuspensionValid
                                            }
                                            onFormNotValid={onFormNotValid}
                                            style={{ border: "none" }}
                                        />
                                    </div>
                                </Modal.Actions>
                            </Modal.Content>
                        </Modal>

                        <Modal
                            onClose={() => {
                                setFirstOpen(false);
                                setIsFormError(false);
                                setSuspensionFormSubmitted(false);
                            }}
                            onOpen={() => setFirstOpen(true)}
                            open={firstOpen}
                        >
                            <Modal.Header>Suspension du projet</Modal.Header>
                            <Modal.Content>
                                <FormErrorHeader
                                    message="Veuillez corriger les champs en erreur"
                                    displayed={isFormError}
                                />
                                <p>
                                    Pour quelle raison ce projet doit il etre
                                    suspend ?
                                </p>
                                <textarea
                                    css={{
                                        width: "100%",
                                        borderRadius: "4px",
                                        height: "60px",
                                        borderColor: "lightgrey",
                                    }}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            form.raisonSuspension.name
                                        )
                                    }
                                />
                                {!form.raisonSuspension.value &&
                                    suspensionFormSubmitted && (
                                        <div
                                            css={{
                                                color: "#F03E3E",
                                                fontSize: "10px",
                                                marginTop: "4px",
                                            }}
                                        >
                                            Vous devez préciser la raison de la
                                            fin de la suspension
                                        </div>
                                    )}
                                <Checkbox
                                    required
                                    name="suspension"
                                    value="ok"
                                    onClick={(event, props) => {
                                        setIsSuspensionChecked(props.checked!);
                                    }}
                                    label={{
                                        children: () => (
                                            <span>
                                                Je confirme la suspension du
                                                projet, les investissements ne
                                                seront plus possibles
                                            </span>
                                        ),
                                    }}
                                />
                                {!isSuspensionChecked &&
                                    suspensionFormSubmitted && (
                                        <div
                                            css={{
                                                color: "#F03E3E",
                                                fontSize: "10px",
                                                marginTop: "4px",
                                            }}
                                        >
                                            Veuillez renseigner ce champ
                                        </div>
                                    )}
                                <Modal.Actions>
                                    <div
                                        css={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "15px",
                                        }}
                                    >
                                        <SubmitButton
                                            label=" Confirmer la suspension"
                                            onPreSubmit={() =>
                                                setSuspensionFormSubmitted(true)
                                            }
                                            action={() =>
                                                projetService.suspendreLevee(
                                                    bodySuspension,
                                                    project.Id,
                                                    loginProps.oauth
                                                )
                                            }
                                            data={bodySuspension}
                                            validateForm={isFormSuspensionValid}
                                            onFormNotValid={onFormNotValid}
                                            style={{ border: "none" }}
                                        />
                                    </div>
                                </Modal.Actions>
                            </Modal.Content>
                        </Modal>
                        {(isPageDon ? project.StatutDonProjetCode : project.StatutProjetCode ) !== StatutProjet.CLOTURE &&
                            <>
                                <Button 
                                    css={{
                                        fontSize: "16px !important",
                                        fontStyle: "bold !important",
                                        width: "205px !important",
                                    }}
                                    color="blue" 
                                    onClick={() => setIsEdited(true)}
                                >
                                    Prolonger la cagnotte
                                </Button>
                                <Modal 
                                    onClose={() => setIsEdited(false)} 
                                    open={isEdited}
                                >
                                <Modal.Header>Prolonger la cagnotte</Modal.Header>
                                <Modal.Content>
                                    <ProlongationForm 
                                        projet={project}
                                        onSubmitSuccess={() => {
                                            setIsEdited(false); 
                                            reload()
                                        }}
                                    />
                                </Modal.Content>
                                </Modal>
                            </>
                        }
                        {((isPageDon ? project.StatutDonProjetCode : project.StatutProjetCode) === (StatutProjet.PUBLIE ||
                            StatutProjet.SUSPENDED)) && (
                            <Button
                                css={{
                                    backgroundColor: "#E8C7C5 !important",
                                    borderColor: "#BE8989 !important",
                                    fontSize: "16px !important",
                                    fontStyle: "bold !important",
                                    color: " #A92928 !important",
                                    width: "185px",
                                }}
                                onClick={() => setThirdOpen(true)}
                            >
                                Annuler
                            </Button>
                        )}
                        <Modal
                            onClose={() => {
                                setThirdOpen(false);
                                setIsFormError(false);
                                setAnnulationFormSubmitted(false);
                            }}
                            onOpen={() => setThirdOpen(true)}
                            open={thirdOpen}
                        >
                            <Modal.Header>Annulation</Modal.Header>
                            <Modal.Content>
                                <FormErrorHeader
                                    message="Veuillez corriger les champs en erreur"
                                    displayed={isFormError}
                                />
                                <p>
                                    Quelle est la raison de l'annulation de la
                                    levée ?
                                </p>
                                <textarea
                                    css={{
                                        width: "100%",
                                        borderRadius: "4px",
                                        height: "60px",
                                        borderColor: "lightgrey",
                                    }}
                                    onChange={(e) =>
                                        handleInputChange(
                                            e,
                                            form.raisonAnnulation.name
                                        )
                                    }
                                />
                                {!form.raisonAnnulation.value &&
                                    annulationFormSubmitted && (
                                        <div
                                            css={{
                                                color: "#F03E3E",
                                                fontSize: "10px",
                                                marginTop: "4px",
                                            }}
                                        >
                                            Vous devez préciser la raison de la
                                            fin de l'annulation
                                        </div>
                                    )}
                                <Checkbox
                                    required
                                    name="annulation"
                                    value="ok"
                                    onClick={(event, props) => {
                                        setIsCanceledChecked(props.checked!);
                                    }}
                                    label={{
                                        children: () => (
                                            <span>
                                                Je confirme l'annulation, la
                                                levée sera terminée et les
                                                investisseurs remboursés
                                                automatiquement
                                            </span>
                                        ),
                                    }}
                                />
                                {!isCanceledChecked &&
                                    annulationFormSubmitted && (
                                        <div
                                            css={{
                                                color: "#F03E3E",
                                                fontSize: "10px",
                                                marginTop: "4px",
                                            }}
                                        >
                                            Veuillez renseigner ce champ
                                        </div>
                                    )}
                                <Modal.Actions>
                                    <div
                                        css={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "15px",
                                        }}
                                    >
                                        <SubmitButton
                                            label="Confirmer l'annulation"
                                            onPreSubmit={() =>
                                                setAnnulationFormSubmitted(true)
                                            }
                                            action={() =>
                                                projetService.annulationLevee(
                                                    bodyAnnulation,
                                                    project.Id,
                                                    loginProps.oauth
                                                )
                                            }
                                            data={bodyAnnulation}
                                            validateForm={isFormAnnulationValid}
                                            onFormNotValid={onFormNotValid}
                                            style={{ border: "none" }}
                                        />
                                    </div>
                                </Modal.Actions>
                            </Modal.Content>
                        </Modal>
                    </div>
                )}
            {profile === ProfilCode.VILLYZ && !isPageDon &&  authorizedStatusDownloadEcheancier(
                    project.StatutProjetCode!
                )
              && (
                    <SubmitButton
                        action={() =>
                            investmentService.getEcheancierProjet(
                                project.Id!,
                                loginProps!.oauth
                            )
                        }
                        onActionSuccess={onDownloadSuccess}
                        label="Télécharger l'échéancier du projet"
                    />
                )}
        </div>
    );
}
