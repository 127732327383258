import React from "react"
import { Button, Container } from "semantic-ui-react"
import { NavLink } from "react-router-dom";
import { AuthenticationState } from "../../../../model/state/authentication-state";
import { ApplicationState } from "../../../../store";
import { useSelector } from "react-redux";
import { Profil } from "../../../../model/entities/profil"
import { EcheanceCitoyenTable } from "./echeance-citoyen-table";
import { EcheanceCollectiviteTable } from "./echeance-collectivite-table";

type Props = {

}

export function EcheancesPane({}: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
  
    return (
        <>
            <Container>
                {loginProps?.oauth?.profilCode == Profil.INVESTISSEUR && (
                    <EcheanceCitoyenTable />
                )}
                {loginProps?.oauth?.profilCode == Profil.PORTEUR_PROJET && (
                    <EcheanceCollectiviteTable />
                )}
            </Container>
            <div className="text-center py-8">
                <Button as={NavLink} to="/projets" size="big" primary>
                Découvrir les projets
                </Button>
            </div>
        </>
    )
}