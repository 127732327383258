import React from "react"
import { css } from "@emotion/core";

type Props = {
    title: string
    right?: boolean
    left? : boolean
    espace? : string
    wrap?: boolean
}

export function HeaderLined({title, right, left, espace, wrap}: Props) {

    const couleurVillyz = "#005870";

    const margin = espace ?? "8px";
    const whiteSpace = wrap ? "normal" : "nowrap";
    const headerFAQ = css({
        textAlign : "center",
        color : couleurVillyz,
        whiteSpace : whiteSpace,
      })

    const line = css({
        background: couleurVillyz,
        content : "' '",
        width : "100%",
        height : "4px",
        marginTop : "1rem",
        flex: 1
    })

    const blank = css({
        content : "' '",
        width : "100%",
        flex : 1
    })

    return(
        <>
            <div css={{display : "flex", flexDirection : "row", alignItems : "center", marginBottom : "10px"}}>
                {right ? <div css={[blank, {marginRight : margin}]}></div> : 
                    <div css={[line, {marginRight : margin}]}>
                    </div>
                }
                
                <h2 css={[headerFAQ]}>
                    {title}
                </h2>

                {left ? 
                    <div css={[blank, {marginLeft : margin}]}></div>
                 : 
                 <div css={[line, {marginLeft : margin}]}></div>
                }
                
            </div>
        </>
    )
}